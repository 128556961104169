import { useState, useContext } from 'react';
import {
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { Context } from '@root/Context';
import { useSnackbar } from '@root/hooks';
import Dog from '@root/assets/icons/Dog';
import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
import CreatePetDialog from '@root/components/dialog/CreatePetDialog';
import styles from './pets.module.scss';

const petSizes = ['Pequeño', 'Mediano', 'Grande'];

const Pets = (props) => {
  const { pets, setPets } = props;
  const snackbar = useSnackbar();
  const { cookies, client } = useContext(Context);
  const [showAddPet, setShowAddPet] = useState(false);

  const handleChangePet = (idx, value) => {
    setPets((prev) => prev.map((item, i) => (idx === i ? { ...item, selected: value } : item)));
  };

  const handleCreatePet = (pet) => {
    // var year = pet?.birth_date?.getFullYear();
    // var month = pet?.birth_date?.getMonth();
    // var day = pet?.birth_date?.getDate();
    // var customDate = year + '-' + (month + 1) + '-' + (day + 1);

    if (client) {
      fetchCreatePet({ ...pet });
    } else {
      setPets((prev) => [...prev, { ...pet, selected: true }]);
      setShowAddPet(false);
    }
  };

  const fetchCreatePet = async (arg) => {
    const postData = { id: cookies.id, ...arg };
    const data = createPetAPI(postData);
    data
      .then((res) => {
        if (res.status === 200) {
          refreshPets([]);
          setShowAddPet(false);
          snackbar.success('Mascota añadida.');
          return;
        }
        snackbar.warning('Error: ' + res.message);
      })
      .catch((e) => {
        snackbar.error('Error al crear la mascota.');
      });
  };

  const createPetAPI = async (postData) => {
    const response = await fetch(SERVICES.REGISTER_PET, OPTIONS.POST(postData));
    const data = await response.json();
    return data;
  };

  const refreshPets = async (initPets) => {
    const responsePets = await fetch(
      SERVICES.PETS_BY_USER,
      OPTIONS.POST({
        id: cookies.id,
      }),
    );
    const dataPets = await responsePets.json();
    if (responsePets.status === 200) {
      setPets(
        dataPets.pets.map((p) => ({
          ...p,
          selected:
            initPets.findIndex(
              (ip) => ip.name === p.name && ip.feeding === p.feeding && ip.selected,
            ) >= 0,
        })),
      );
    }
  };

  return (
    <div>
      <div className={styles.title}>
        <h3>Mascota</h3>
        <IconButton
          aria-label="Show Add Pet"
          component="span"
          style={{ padding: 0, marginLeft: 10 }}
          onClick={() => setShowAddPet((prev) => !prev)}>
          <AddCircleOutlineIcon />
        </IconButton>
      </div>
      <div>
        <FormControl component="fieldset">
          <FormGroup>
            {pets.map((item, idx) => (
              <div className={styles.item} key={`pet-wrapper-${idx}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={item.selected}
                      onChange={(e) => handleChangePet(idx, e.target.checked)}
                      name={item.name}
                    />
                  }
                  label={item.name}
                />
                <div className={styles.size}>
                  <Dog />
                  <span>{petSizes[item.pet_size]}</span>
                </div>
              </div>
            ))}
          </FormGroup>
        </FormControl>
      </div>
      <Divider style={{ marginTop: 10, marginBottom: 20 }} />
      <CreatePetDialog
        open={showAddPet}
        onClose={() => setShowAddPet(false)}
        handleCreatePet={handleCreatePet}
      />
    </div>
  );
};

export default Pets;
