import React, { useState, useEffect, useContext } from 'react';
import { Context } from '@root/Context';
import './dashboard.scss';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// components
import Notification from 'components/notification';
import { PublicationSummary, ListNotification, TestSection } from './components';
// Material-UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

function Dashboard() {
  const { cookies } = useContext(Context);

  const [loading, setLoading] = useState(true);
  const notification = useNotificationValue();

  const [user, setUser] = useState({
    id: null,
    email_confirmed: 0,
    confirmation: 0,
    houser: {
      type_delivery: '',
      type_exclusive: '',
      type_share: '',
      galleries: [],
      publication: {
        title: '',
      },
    },
    location: {
      district: '',
    },
  });
  const [notifications, setNotifications] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    await Promise.all([fetchCuidador(), fetchNotificaciones()]);
  };

  const fetchCuidador = async () => {
    try {
      const response = await fetch(
        SERVICES.GET_CUIDADOR_BY_ID,
        OPTIONS.POST({ id: cookies.id }),
      );
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setUser(data.user);
        setLoading(false);
      } else {
        notification.handleSnackbar(data.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  const comparar = (a, b) => {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    // a must be equal to b
    return 0;
  };

  const fetchNotificaciones = async () => {
    try {
      const response = await fetch(
        SERVICES.ALL_NOTIFICATIONS_BY_HOUSER + cookies.houserId,
        OPTIONS.GET(),
      );
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (data.notificaciones) {
          setNotifications(data.notificaciones.sort(comparar));
        }
        setLoading(false);
      } else {
        notification.handleSnackbar(data.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.id]);

  return (
    <div className="dashboard-cuidador">
      <Container maxWidth="lg">
        <h1 className="dashboard-cuidador__title">Panel de control</h1>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <h2 className="dashboard-cuidador__sub-title">Resumen del anuncio</h2>
            <div className="dh-simple-border" style={{ marginBottom: '2.5rem' }}>
              <PublicationSummary user={user} loading={loading} />
            </div>
            <h2 className="dashboard-cuidador__sub-title">Notificaciones</h2>
            <div className="notification-container">
              <ListNotification user={user} notifications={notifications} loading={loading} />
            </div>
            <p>*Datos Obligatorios</p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <h2 className="dashboard-cuidador__sub-title">Manuales</h2>
            {!loading && (
              <TestSection
                houserId={cookies.houserId}
                houserConfirmed={user.houser.exam_confirmed}
                examValid={user.houser.exam_valid}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default Dashboard;
