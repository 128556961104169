import React, { useState, useEffect } from 'react';
import PetsIcon from '@material-ui/icons/Pets';
import Rating from '@material-ui/lab/Rating';
import './adminReservationDetail.scss';
// Services
import * as VARIABLES from 'constants/variables';
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// Components
import Notification from 'components/notification';
import TitleArrowBack from '@root/components/TitleBack';
import { ReservationInfo, ClientInfo, HouserInfo } from './components';
// Material-UI
import Grid from '@material-ui/core/Grid';
// Chat
import { StreamChat } from 'stream-chat';
import StreamMessage from 'components/StreamMessage';

function AdminReservationDetail(props) {
  const id = props.match.params.id;

  const [loading, setLoading] = useState(true);
  const notification = useNotificationValue();
  const [reload, setReload] = useState(false);

  const [housePhoto, setHousePhoto] = useState('');
  const [booking, setBooking] = useState(undefined);

  // eslint-disable-next-line no-unused-vars
  const [services, setServices] = useState();

  const [chatClient] = useState(new StreamChat(VARIABLES.GET_STREAM_KEY));
  const [channel, setChannel] = useState();

  const fetchReservationData = async () => {
    setLoading(true);
    try {
      const response = await fetch(SERVICES.GET_BOOKING_BY_ID + id, OPTIONS.GET());
      const data = await response.json();
      console.log(data);
      if (response.status === 200) {
        console.log(data);
        setBooking(data.booking);
        await getGallery(data.booking.houser.user.id);

        const resToken = await fetch(
          SERVICES.GET_MESSENGER_TOKEN,
          OPTIONS.POST({ me: VARIABLES.GET_STREAM_ADMIN, target: data.booking.houser.user.id }),
        );
        const dataToken = await resToken.json();
        await chatClient.connectUser({ id: VARIABLES.GET_STREAM_ADMIN }, dataToken.token);
        // create grouped channel
        const members = [
          `ms-${data.booking.user_id}`,
          `ms-${data.booking.houser.user.id}`,
          VARIABLES.GET_STREAM_ADMIN,
        ];
        const newChannel = chatClient.channel('messaging', {
          members: members,
        });
        await newChannel.create();
        setChannel(newChannel);

        setLoading(false);
      } else {
        notification.handleSnackbar('Error al obtener los datos', 'error');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  const getGallery = async (user_id) => {
    try {
      const response = await fetch(SERVICES.GET_GALLERY_BY_USER, OPTIONS.POST({ id: user_id }));
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setHousePhoto(data.gallery[0].photo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateReservation = async (status) => {
    let body = {
      booking_id: id,
      status: status,
    };
    try {
      const response = await fetch(SERVICES.UPDATE_BOOKING_STATUS, OPTIONS.POST(body));
      // const data = await response.json()
      // console.log(data)
      if (response.status === 200) {
        notification.handleSnackbar('Validando...', 'success');
        setReload(true);
      } else {
        notification.handleSnackbar('Error al actualizar el estado...', 'error');
      }
    } catch (error) {
      notification.handleSnackbar('Error: ' + error.message, 'error');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchReservationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Grid container spacing={3} className="admin-res-detail">
      {booking && (
        <>
          <Grid item xs={12}>
            <TitleArrowBack handleClick={() => props.history.goBack()}>
              <h2 className="admin-title">{id}</h2>
            </TitleArrowBack>
          </Grid>
          {booking.state === 6 && (
            <Grid item xs={12}>
              <div className="dh-card-border">
                <div className="dh-flex content-space">
                  <div className="client-reservation-detail__section-title">
                    <h3>Comentario</h3>
                    <div className="border-space" />
                  </div>
                </div>
                <div className="res-detail__body">
                  <div className="res-detail__body--grid-1">
                    <div className="res-detail__body--item">
                      <p>tasa de servicio</p>
                      <Rating
                        name="customized-empty"
                        defaultValue={booking.feedback_level}
                        precision={0.5}
                        icon={<PetsIcon fontSize="inherit" />}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="res-detail__body--grid-1">
                    <div className="res-detail__body--item">
                      <p>reacción</p>
                      <h4>{booking.feedback}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <ReservationInfo booking={booking} services={services} />
          </Grid>
          <Grid item xs={12}>
            <ClientInfo reservation={booking} pets={booking.booking_has_pets} />
          </Grid>
          <Grid item xs={12}>
            <HouserInfo loading={loading} reservation={booking} housePhoto={housePhoto} />
          </Grid>
          <Grid item xs={12}>
            <div className="dh-card-elevation">
              <div className="client-reservation-detail__section-title">
                <h3>Acciones</h3>
                <div className="border-space" />
              </div>
              <div className="actions-detail">
                {booking.state === 1 && (
                  <div className="actions-detail__container">
                    <div className="actions-detail__btn-action">
                      <button className="btn-success" onClick={() => updateReservation(2)}>
                        Confirmar Reserva
                      </button>
                    </div>
                  </div>
                )}
                {/* {(booking.state === 2) &&
              <div className="actions-detail__container">
                <div className="actions-detail__btn-action">
                  <button className="btn-accent" onClick={() => updateReservation(3)}>Confirmar pago de Reserva</button>
                </div>
              </div>
            } */}
                {(booking.state === 1 || booking.state === 2 || booking.state === 3) && (
                  <div className="actions-detail__container">
                    <div className="actions-detail__btn-action">
                      <button className="btn-danger" onClick={() => updateReservation(4)}>
                        Rechazar Reserva
                      </button>
                      {booking.state === 2 && (
                        <button
                          type="submit"
                          className="btn-primary"
                          style={{ marginLeft: 10 }}
                          onClick={() => updateReservation(3)}>
                          Pagar ahora
                        </button>
                      )}
                      {booking.state === 3 && (
                        <button
                          style={{ marginLeft: 20 }}
                          onClick={() => updateReservation(5)}
                          className="btn-primary medium btn-step">
                          Reserva completa
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="container">
            {channel && <StreamMessage chatClient={chatClient} channel={channel} />}
          </Grid>
        </>
      )}
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </Grid>
  );
}

export default AdminReservationDetail;
