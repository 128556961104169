import React from 'react'
// Hooks
import { usePoppoverValue } from 'hooks/usePoppoverValue'
// Material-UI
import Popover from '@material-ui/core/Popover'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
// Material Icons
import FilterListIcon from '@material-ui/icons/FilterList'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

function FilterAdminPopover(props) {
  const { id, open, anchorEl, handlePopoverClose, title } = props
  return (
    <Popover
      id={id}
      open={open}
      className="dh-filter-selection__popover-filter"
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <div className="dh-filter-selection__popover-filter--title">
        <h3>{title}</h3>
      </div>
      <FormControl component="fieldset" className="dh-filter-selection__popover-filter--formcontrol">
        <FormGroup>
          {props.children}
        </FormGroup>
      </FormControl>
      <div className="dh-filter-selection__popover-filter--actions">
        <button className="btn-primary" onClick={handlePopoverClose}>Guardar</button>
      </div>
    </Popover>
  )
}

function FilterSelection(props) {
  const { values, handleValuesChange } = props
  const country = usePoppoverValue('country-popover')
  const etiquetas = usePoppoverValue('etiquetas-popover')
  const extra = usePoppoverValue('extra-popover')

  return (
    <div className="dh-filter-selection">
      <div className="dh-filter-selection__item">
        <div className="dh-filter-selection__style">
          <FilterListIcon/>
        </div>
      </div>
      <div className="dh-filter-selection__item">
        <div className="dh-filter-selection__style">
          <span>Filtrar por</span>
        </div>
      </div>
      <div className="dh-filter-selection__item item-button" onClick={country.handlePopoverOpen}>
        <div className="dh-filter-selection__style">
          <span>País</span>
          <ExpandMoreIcon/>
        </div>
      </div>
      <div className="dh-filter-selection__item item-button" onClick={etiquetas.handlePopoverOpen}>
        <div className="dh-filter-selection__style">
          <span>Estados</span>
          <ExpandMoreIcon/>
        </div>
      </div>
      <div className="dh-filter-selection__item item-button" onClick={extra.handlePopoverOpen}>
        <div className="dh-filter-selection__style">
          <span>Extra</span>
          <ExpandMoreIcon/>
        </div>
      </div>
      {/* POPOVER para filtro de Ubicacion */}
      <FilterAdminPopover
        id={country.id}
        open={country.open}
        anchorEl={country.anchorEl}
        handlePopoverClose={country.handlePopoverClose}
        title="País">
        <FormControlLabel
          control={<Checkbox checked={values.country_peru} onChange={handleValuesChange('country_peru')} color="primary"/>}
          label="Perú"
        />
        <FormControlLabel
          control={<Checkbox checked={values.country_colombia} onChange={handleValuesChange('country_colombia')} color="primary"/>}
          label="Colombia"
        />
        <FormControlLabel
          control={<Checkbox checked={values.country_espana} onChange={handleValuesChange('country_espana')} color="primary"/>}
          label="España"
        />
      </FilterAdminPopover>
      {/* POPOVER para filtro de Etiquetas */}
      <FilterAdminPopover
        id={etiquetas.id}
        open={etiquetas.open}
        anchorEl={etiquetas.anchorEl}
        handlePopoverClose={etiquetas.handlePopoverClose}
        title="Etiquetas de Usuarios">
        <FormControlLabel
          control={<Checkbox checked={values.houser_active} onChange={handleValuesChange('houser_active')} color="primary"/>}
          label="Activos/Aprobados"
        />
        <FormControlLabel
          control={<Checkbox checked={values.postulant_complete} onChange={handleValuesChange('postulant_complete')} color="primary"/>}
          label="Postulantes(Datos completos)"
        />
        <FormControlLabel
          control={<Checkbox checked={values.postulant_no_complete} onChange={handleValuesChange('postulant_no_complete')} color="primary"/>}
          label="Postulantes(Datos incompletos)"
        />
      </FilterAdminPopover>
      {/* POPOVER para filtro de Extra data */}
      <FilterAdminPopover
        id={extra.id}
        open={extra.open}
        anchorEl={extra.anchorEl}
        handlePopoverClose={extra.handlePopoverClose}
        title="Extra">
        <FormControlLabel
          control={<Checkbox checked={values.email_true} onChange={handleValuesChange('email_true')} color="primary"/>}
          label="Email verificado"
        />
        <FormControlLabel
          control={<Checkbox checked={values.email_false} onChange={handleValuesChange('email_false')} color="primary"/>}
          label="Email No verificado"
        />
        <FormControlLabel
          control={<Checkbox checked={values.phone_true} onChange={handleValuesChange('phone_true')} color="primary"/>}
          label="Teléfono verificado"
        />
        <FormControlLabel
          control={<Checkbox checked={values.phone_false} onChange={handleValuesChange('phone_false')} color="primary"/>}
          label="Teléfono No verificado"
        />
      </FilterAdminPopover>
    </div>
  )
}

export default FilterSelection
