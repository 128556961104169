import { useEffect, useState, useContext, useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WhatsApp } from '@material-ui/icons';
import moment from 'moment';

import { convertToWhatsAppEncodedText } from '@root/utils/textEncoder';
import { Context } from '@root/Context';
import { useSnackbar } from '@root/hooks';
import { useInputValue } from '@root/hooks/useInputValue';
import { petService } from '@root/services';
import mutateHouserServices from '@root/utils/mutateHouserServices';
import Services from './Services';
import Options from './Options';
import Pets from './Pets';
import * as SERVICES from '@root/services/config';
import * as VARIABLES from '@root/constants/variables';
import * as OPTIONS from '@root/services/options';
import * as ROUTES from '@root/constants/routes';
import './bookingRequest.scss';

const WhatsAppButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#25D366'),
    backgroundColor: '#25D366',
    '&:hover': {
      backgroundColor: '#1e9549',
    },
  },
}))(Button);

function BookingRequest(props) {
  const { id, houser } = props;
  const { cookies, client, tempRequest, setRedirect, storeTempRequest } = useContext(Context);
  const snackbar = useSnackbar();

  const [serviceOptions, setServiceOptions] = useState([]);
  const [houserServices, setHouserServices] = useState([]);
  const [typeServiceActive, setTypeServiceActive] = useState(null);
  const [serviceActive, setServiceActive] = useState(-1);

  const [dropDate, setDropDate] = useState(new Date());
  const [pickupDate, setPickupDate] = useState(new Date());

  const [pets, setPets] = useState([]);
  // const [selectedPets, setSelectedPets] = useState([]);

  const [error, setError] = useState(false);

  const petsDescription = useInputValue('');

  useEffect(() => {
    let newDate = new Date();
    newDate.setHours(8);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    setDropDate(newDate);
    setPickupDate(newDate);
  }, []);

  const serviceDays = useMemo(() => {
    return Math.floor((pickupDate - dropDate) / (1000 * 64 * 60 * 24)) + 1;
  }, [dropDate, pickupDate]);

  const serviceTime = useMemo(() => {
    if (pickupDate > dropDate) {
      return Number(Math.abs(pickupDate - dropDate) / 36e5).toFixed(1);
    }
    return '-';
  }, [dropDate, pickupDate]);

  const petCount = useMemo(() => {
    return pets.filter((v) => v.selected).length;
  }, [pets]);

  const discounts = useMemo(() => {
    let result = [];
    if (serviceDays >= 20) {
      result.push({ reason: 'Hospedaje +20', percent: 15 });
    } else if (serviceDays >= 7) {
      result.push({ reason: 'Hospedaje +7', percent: 10 });
    }
    if (petCount >= 2) {
      result.push({ reason: '2 perros', percent: 10 });
    }
    return result;
  }, [petCount, serviceDays]);

  const currentService = useMemo(() => {
    if (serviceActive > 0 && houserServices.length) {
      let serviceData = houserServices.find((s) => s.id === serviceActive);
      serviceData.total_price =
        Number(serviceData.gross_comission) + Number(serviceData.houser_comission);
      return serviceData;
    } else {
      return undefined;
    }
  }, [serviceActive, houserServices]);

  const currentCurrency = useMemo(() => {
    if (currentService) return VARIABLES.CURRENCY_BY_COUNTRY[currentService.country];
    else return '-';
  }, [currentService]);

  const totalPrice = useMemo(() => {
    if (!currentService || serviceDays <= 0 || petCount <= 0) return undefined;
    return currentService.total_price * serviceDays * petCount;
  }, [currentService, serviceDays, petCount]);

  const totalPriceFee = useMemo(() => {
    if (!currentService || serviceDays <= 0 || petCount <= 0) return undefined;

    return currentService.gross_comission * petCount * serviceDays;
  }, [currentService, serviceDays, petCount]);

  const totalDiscounts = useMemo(() => {
    if (!currentService || serviceDays <= 0 || petCount <= 0) return 0;
    let percent = 0;
    if (discounts.length > 0) {
      percent = discounts.reduce((a, b) => a + b.percent, 0);
    }
    return (percent / 100) * currentService.houser_comission * serviceDays * petCount;
  }, [discounts, currentService, serviceDays, petCount]);

  const message = useInputValue('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const serviceOptions = houserServices.filter((s) => s.type_id === typeServiceActive);
    if (serviceOptions.length) setServiceActive(serviceOptions[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeServiceActive]);

  useEffect(() => {
    initServices(houser.houser.houser_services);
    if (client) {
      fetchPetsByClient();
    } else {
      setPets([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.id, client]);

  const fetchPetsByClient = async () => {
    try {
      const res = await petService.listByUser(cookies.id);
      if (res.status === 200) {
        const newPets = res.pets.map((pet) => ({ ...pet, selected: false }));
        setPets(newPets);
      }
    } catch (error) {
      snackbar.error(error.message);
    }
  };

  const initServices = async (services) => {
    if (!services) return;

    const newServices = services.map((item) => item.service);

    const { serviceTypeIds, serviceOptions } = await mutateHouserServices(services);

    setHouserServices(newServices);
    setServiceOptions(serviceOptions);
    setTypeServiceActive(serviceTypeIds[0]);

    if (tempRequest && client) {
      let tempData;
      if (typeof tempRequest == 'string') {
        tempData = JSON.parse(tempRequest);
      } else if (typeof tempRequest == 'object') {
        tempData = tempRequest;
      }
      if (!tempData) return;
      setTypeServiceActive(tempData.serviceType);
      setServiceActive(tempData.optionType);
      setDropDate(new Date(tempData.dropDate));
      setPickupDate(new Date(tempData.pickupDate));
      petsDescription.update(tempData.petsDescription);
      message.update(tempData.message);

      await Promise.all(
        tempData.pets.map(async (pet) => {
          const postData = { id: cookies.id, ...pet };
          await createPetAPI(postData);
        }),
      );
      refreshPets(tempData.pets);

      storeTempRequest(null);
    }
  };

  const makeBooking = async () => {
    const body = {
      houser_id: houser.houser.id,
      user_id: cookies.id,
      drop_date: moment(dropDate).format('YYYY-MM-DD HH:mm:ss'),
      pickup_date: moment(pickupDate).format('YYYY-MM-DD HH:mm:ss'),
      price_total: totalPrice - totalDiscounts,
      price_tax: totalPriceFee,
      price_discount: totalDiscounts,
      pet_id: pets.filter((p) => p.selected).map((p) => p.id),
      pets_desc: petsDescription.value,
      service_type: serviceActive,
      message: message.value,
    };
    // console.log(body);
    // return;
    try {
      const response = await fetch(SERVICES.CREATE_BOOKING, OPTIONS.POST(body));
      const data = await response.json();
      if (data.booking_id) {
        props.history.push(`/reserva/${data.booking_id}`);
      }
      if (data.error) {
        snackbar.error(error.message);
      }
    } catch (error) {
      setError(true);
      snackbar.error(error.toString());
    }
  };

  const storeInfoToCookie = async () => {
    const data = {
      serviceType: typeServiceActive,
      optionType: serviceActive,
      dropDate: dropDate.toString(),
      pickupDate: pickupDate.toString(),
      pets: pets,
      petsDescription: petsDescription.value,
      message: message.value,
    };
    storeTempRequest(data);
  };

  const handleWhatsAppRequest = () => {
    if (dropDate < new Date() || dropDate >= pickupDate) {
      snackbar.error('Seleccione el intervalo de fechas correcto.');
      return;
    }
    const strDropDate = moment(dropDate).format('DD-MM-YYYY HH:mm');
    const strPickupDate = moment(pickupDate).format('DD-MM-YYYY HH:mm');
    const countPets = pets?.filter((p) => p.selected)?.map((p) => p.id)?.length;
    const strName = `${houser.name} ${houser.last_name}`;
    const strDate = `del ${strDropDate} al ${strPickupDate}`;
    const strPets = countPets > 0 ? `, Mascotas: ${countPets}` : '';
    const message = `Quisiera reservar con ${strName} ${strDate}${strPets}`;
    // console.debug(message);
    window.open(ROUTES.WHATSAPP + '?text=' + convertToWhatsAppEncodedText(message), '_blank');
  };

  // eslint-disable-next-line no-unused-vars
  const handleRequest = () => {
    if (pets.filter((p) => p.selected).length <= 0) {
      snackbar.error('Seleccione al menos una mascota.');
      return;
    }
    if (message.value === '') {
      snackbar.error('Por favor deje un mensaje para solicitar.');
      return;
    }
    if (dropDate < new Date() || dropDate >= pickupDate) {
      snackbar.error('Seleccione el intervalo de fechas correcto.');
      return;
    }
    if (client) {
      makeBooking();
    } else {
      storeInfoToCookie();
      setRedirect(`/cuidador/${id}`);
      props.history.push('/login');
    }
  };

  const refreshPets = async (initPets) => {
    const responsePets = await fetch(
      SERVICES.PETS_BY_USER,
      OPTIONS.POST({
        id: cookies.id,
      }),
    );
    const dataPets = await responsePets.json();
    if (responsePets.status === 200) {
      setPets(
        dataPets.pets.map((p) => ({
          ...p,
          selected:
            initPets.findIndex(
              (ip) => ip.name === p.name && ip.feeding === p.feeding && ip.selected,
            ) >= 0,
        })),
      );
    }
  };

  const createPetAPI = async (postData) => {
    const response = await fetch(SERVICES.REGISTER_PET, OPTIONS.POST(postData));
    const data = await response.json();
    return data;
  };

  const petSizes = ['Pequeño', 'Mediano', 'Grande'];

  const renderSummary = () => {
    return (
      <div className="summary-wrapper">
        <h3 className="summary-h">Resumen de reserva</h3>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="summary-content"
            id="summary-header">
            <div className="summary-header-wrapper">
              <div className="summary-title-row">
                <label>{currentService?.name}</label>
                <label>
                  {currentCurrency}
                  {totalPrice ? (totalPrice - totalDiscounts).toFixed(2) : '-'}
                </label>
              </div>
              <div className="summary-title-desc">
                {typeServiceActive === 3 ? (
                  <span>
                    {serviceTime} horas, {petCount} mascotas
                  </span>
                ) : (
                  <span>
                    {serviceDays} días, {petCount} mascotas
                  </span>
                )}
                <span>Total</span>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="summary-detail-wrapper">
              <Divider />
              {currentService &&
                pets
                  .filter((v) => v.selected)
                  .map((v, i) => (
                    <div key={`detail-summary-${i}`}>
                      <div className="detail-row">
                        <span>{v.name}</span>
                        <span>
                          {currentCurrency}
                          {(currentService.total_price * serviceDays).toFixed(2)}
                        </span>
                      </div>
                      <div className="detail-desc">
                        <span>{petSizes[v.pet_size]}</span>
                        <br />
                        <span>Tarifa estándar</span> <br />
                        <span>
                          {currentCurrency}
                          {Number(currentService.total_price).toFixed(2)} por día x{' '}
                          {serviceDays}
                        </span>
                      </div>
                    </div>
                  ))}
              <Divider />
              <div className="detail-row">
                <span>Descuento</span>
                <span>
                  - {currentCurrency}
                  {totalDiscounts.toFixed(2)}
                </span>
              </div>
              {discounts.map((d, i) => (
                <div className="detail-desc" key={`discount-row-${i}`}>
                  <span>
                    {d.percent}% ({d.reason})
                  </span>
                </div>
              ))}
              {/* <Divider/>
              <div className="detail-row">
                <span>Tarifa de servicio</span>
                <span>
                  {currentCurrency}{totalPriceFee ? totalPriceFee.toFixed(2) : '-'}
                </span>
              </div> */}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  return (
    <div className="houser-request margin-top-x1">
      <div className="houser-request__container">
        <h3 style={{ marginBottom: 15 }}>Contactar a {houser.name}</h3>
        <Services
          serviceOptions={serviceOptions}
          typeServiceActive={typeServiceActive}
          setTypeServiceActive={setTypeServiceActive}
          serviceActive={serviceActive}
          setServiceActive={setServiceActive}
          setDropDate={setDropDate}
          setPickupDate={setPickupDate}
        />
        <Options
          serviceActive={serviceActive}
          typeServiceActive={typeServiceActive}
          dropDate={dropDate}
          setDropDate={setDropDate}
          pickupDate={pickupDate}
          setPickupDate={setPickupDate}
        />
        <Pets pets={pets} setPets={setPets} />
        {renderSummary()}
        <div>
          {/*
          <h3 style={{ marginBottom: 15 }}>Añade un mensaje</h3>
          <TextField
            id="message"
            name="message"
            required
            fullWidth
            variant="outlined"
            type="text"
            multiline
            minRows={3}
            value={message.value}
            onChange={message.onChange}
          />
          <button
            style={{ marginTop: 20 }}
            type="submit"
            className="btn-primary medium btn-step"
            onClick={() => handleRequest()}>
            Contactar
          </button>
          */}
          <WhatsAppButton
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            startIcon={<WhatsApp />}
            style={{ marginTop: 24 }}
            onClick={() => handleWhatsAppRequest()}>
            Contactar por WhatsApp
          </WhatsAppButton>
        </div>
      </div>
    </div>
  );
}

export default BookingRequest;
