import React, { useContext, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Context } from '@root/Context';
import './header.scss';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as ROUTES from 'constants/routes';
import * as VARIABLES from 'constants/variables';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// Componets
import Notification from 'components/notification';
import UserDropdownMenu from 'components/userDropdownMenu';
import UserProfileSideList from 'components/userProfileSideList/UserProfileSideList';
import DialogHouserRequirements from 'components/dialogHouserRequirements';
// Images
import dhLogo from 'assets/dg_logo_text.png';
// Material-UI
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Material Icons
import HomeIcon from '@material-ui/icons/Home';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BookIcon from '@material-ui/icons/Book';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentIndSharpIcon from '@material-ui/icons/AssignmentIndSharp';
import PlaceIcon from '@material-ui/icons/Place';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

function Header(props) {
  const classes = useStyles();
  const {
    isAuth,
    cookies,
    client,
    houser,
    admin,
    userName,
    userImage,
    activateAuth,
    removeAuth,
    setUserData,
  } = useContext(Context);
  // Variables del componente "UserDropdownMenu"
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({ left: false });
  const anchorRef = React.useRef(null);

  // Variables de "Notification"
  const notification = useNotificationValue();

  // Variables del Componente "DialogHouserRequirements"
  const [openDialog, setOpenDialog] = useState(false);

  // iOS has a "swipe to go back" feature that interferes with the discovery feature, so discovery has to be disabled.
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    if ((isAuth && client) || (isAuth && houser)) {
      if (cookies.id) {
        fetchUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, client, houser, cookies.id]);

  // Se obtiene el ID del Usuario y se prepara para enviarlo
  const fetchUser = async () => {
    let body = {
      id: cookies.id,
    };
    sendUserData(SERVICES.USER_BY_ID, OPTIONS.POST(body));
  };

  // Se envia los datos del Usuario al API y se espera la respuesta
  const sendUserData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      let houserId = '';
      // console.log(data)
      if (response.status === 200) {
        if (data.houser) {
          houserId = data.houser.id;
        }
        // Gestión del usuario
        activateAuth(
          data.user.id, // Token
          data.user.id, // Id del Usuario
          houserId, // Id del Perfil Cuidador - Houser
          data.user.confirmation, // Estado del Cliente
          data.user.terms_conditions, // Estado del Cuidador
        );
        setUserData(data.user.name, data.user.photo);
        // setLocation(data.location.country, data.location.city)
      } else {
        notification.handleSnackbar(data.message, 'error');
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
    }
  };

  // Abrir el Dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Cerrar el Dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleGoToRegisterCuidador = () => {
    props.props.children.props.history.push(ROUTES.REGISTER_CUIDADOR);
  };

  const toggleDrawer = (side, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Cerrar el Drop-down Menu
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // Cerrar la sesion del Usuario
  const logout = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    removeAuth();
  };

  const sideListItem = (path, icon, text) => (
    <RouterLink to={path} className="cuidador-list-item">
      <ListItem button>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </RouterLink>
  );

  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}>
      {((isAuth && client) || (isAuth && houser)) && (
        <React.Fragment>
          <UserProfileSideList
            userPhoto={userImage}
            userName={userName}
            client={client}
            houser={houser}
          />
          <Divider />
        </React.Fragment>
      )}
      <List>
        {sideListItem(ROUTES.HOME, <HomeIcon />, 'Inicio')}
        {isAuth &&
          admin &&
          sideListItem(ROUTES.ADMIN_DASHBOAR, <ViewModuleIcon />, 'Panel Admin')}
        {isAuth &&
          houser &&
          sideListItem(ROUTES.CUIDADOR_DASHBOARD, <ViewModuleIcon />, 'Panel de control')}
        {!isAuth && (
          <React.Fragment>
            {sideListItem(ROUTES.LOGIN, <AccountCircleIcon />, 'Iniciar Sesión')}
            {sideListItem(ROUTES.REGISTER_CLIENTE, <AccountCircleIcon />, 'Registro Cliente')}
            <ListItem button onClick={handleOpenDialog}>
              <ListItemIcon>
                <AssignmentIndSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Quiero ser cuidador" />
            </ListItem>
          </React.Fragment>
        )}
        {sideListItem(ROUTES.SERVICIOS_PETFRIENDLY, <PlaceIcon />, 'Lugares PetFriendly')}
        <a
          href={ROUTES.PRODUCTOS}
          target="_blank"
          rel="noopener noreferrer"
          className="cuidador-list-item">
          <ListItem button>
            <ListItemIcon>
              <ShoppingCartRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Tienda" />
          </ListItem>
        </a>
        <a
          href="http://blog.doghouser.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="cuidador-list-item">
          <ListItem button>
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText primary="Blog" />
          </ListItem>
        </a>
      </List>
      {((isAuth && client) || (isAuth && houser)) && (
        <React.Fragment>
          <Divider />
          <List>
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Cerrar Sesión" />
            </ListItem>
          </List>
        </React.Fragment>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <header className="header">
        <Container maxWidth="lg">
          <div className="wrap">
            <div className="menu-icon">
              <div className="text-lines" onClick={toggleDrawer('left', true)}>
                <span></span>
              </div>
              <SwipeableDrawer
                open={state.left}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}>
                {sideList('left')}
              </SwipeableDrawer>
            </div>
            <RouterLink to={ROUTES.HOME} className="logo">
              <img className="main-logo" src={dhLogo} alt="Logo doghouser" />
            </RouterLink>
            <div className="space" />
            <ul className="menu">
              <li>
                {/*<RouterLink to={ROUTES.BLOG}>Blog</RouterLink>*/}
                <a href="http://blog.doghouser.com/" target="_blank" rel="noopener noreferrer">
                  Blog
                </a>
              </li>
              <li>
                <RouterLink to={ROUTES.SERVICIOS_PETFRIENDLY}>Lugares PetFriendly</RouterLink>
              </li>
              <li>
                <a href={ROUTES.PRODUCTOS} target="_blank" rel="noopener noreferrer">
                  Tienda
                </a>
              </li>
              {isAuth && admin && (
                <li>
                  <RouterLink to={ROUTES.ADMIN_DASHBOAR} className="btn-header">
                    Panel Admin
                  </RouterLink>
                </li>
              )}
              {isAuth && houser && (
                <li>
                  <RouterLink to={ROUTES.CUIDADOR_DASHBOARD} className="btn-header">
                    Panel de control
                  </RouterLink>
                </li>
              )}
              {((isAuth && client) || (isAuth && houser)) && (
                <li>
                  <div
                    className="dh-user-logged"
                    ref={anchorRef}
                    aria-controls="menu-list-grow"
                    aria-haspopup="true"
                    onClick={handleToggle}>
                    <div className="dh-user-logged__name">
                      {userName ? (
                        <p>¡Hola {userName}!</p>
                      ) : (
                        <Skeleton className="dh-user-logged__name__loading" />
                      )}
                    </div>
                    <div className="dh-user-logged__ico">
                      {userImage ? (
                        <Avatar
                          className="profile-image"
                          alt="Usuario"
                          src={`${VARIABLES.IMAGE_URL}${userImage}`}
                        />
                      ) : (
                        <Skeleton className="profile-image" variant="circle" />
                      )}
                    </div>
                  </div>
                </li>
              )}
              {!isAuth && (
                <React.Fragment>
                  <li>
                    <a href="#dialog" onClick={handleOpenDialog}>
                      Quiero ser cuidador
                    </a>
                  </li>
                  <li>
                    <RouterLink to={ROUTES.LOGIN}>Iniciar Sesión</RouterLink>
                  </li>
                  {/*<li><RouterLink to={ROUTES.REGISTER_CLIENTE}>Nuevo cliente</RouterLink></li>*/}
                </React.Fragment>
              )}
              <li>
                <Button
                  component={'a'}
                  href={ROUTES.WHATSAPP}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="contained"
                  color="primary"
                  className="whatsapp-link"
                  startIcon={
                    <svg
                      width={20}
                      height={20}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 448 512"
                      className="fill-white">
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                  }>
                  <span>WhatsApp</span>
                </Button>
              </li>
            </ul>
          </div>
        </Container>
      </header>
      <UserDropdownMenu
        open={open}
        anchorRef={anchorRef}
        handleClose={handleClose}
        logout={logout}
        client={client}
        houser={houser}
      />
      <DialogHouserRequirements
        open={openDialog}
        handleClose={handleCloseDialog}
        handleNext={handleGoToRegisterCuidador}
      />
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </React.Fragment>
  );
}

export default Header;
