import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function SofaIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 555.6 555.6"
      style={{enableBackground: 'new 0 0 555.6 555.6'}}
      xmlSpace="preserve">
      <circle cx="277.8" cy="277.8" r="277.3" fill={color} />
      <path d="M79.7,384.2H109c8.1,0,14.7-6.6,14.7-14.7c0,0,0,0,0,0h308.2c0,8.1,6.6,14.7,14.7,14.7h29.4
        c8.1,0,14.7-6.6,14.7-14.7c0,0,0,0,0,0V244.8c0-8.1-6.6-14.7-14.7-14.7l0,0h-29.4c-8.1,0-14.7,6.6-14.7,14.7c0,0,0,0,0,0v80.7H123.7
        v-80.7c0-8.1-6.6-14.7-14.7-14.7H79.7c-8.1,0-14.7,6.6-14.7,14.7c0,0,0,0,0,0v124.7C65,377.6,71.6,384.2,79.7,384.2
        C79.7,384.2,79.7,384.2,79.7,384.2z" fill="#FFFFFF" />
      <path d="M255.9,252.1c5.1,0,10.1,1.4,14.5,4v-70c0-8.1-6.6-14.7-14.7-14.7H153.1c-8.1,0-14.7,6.6-14.7,14.7v70.2
        c4.5-2.7,9.7-4.1,15-4.2H255.9z" fill="#FFFFFF"/>
      <path d="M270.5,281.5c0-8.1-6.5-14.6-14.5-14.7c0,0,0,0-0.1,0H153.4c-8.2,0-14.9,6.5-15,14.7v29.4h132.1V281.5z" fill="#FFFFFF"/>
      <path d="M402.7,252.1c5.1,0,10.1,1.4,14.5,4v-70c0-8.1-6.6-14.7-14.7-14.7c0,0,0,0,0,0H299.8c-8.1,0-14.7,6.6-14.7,14.7
        c0,0,0,0,0,0v70.2c4.5-2.7,9.7-4.1,15-4.2H402.7z" fill="#FFFFFF"/>
      <path d="M417.2,281.5c0-8.1-6.5-14.6-14.5-14.7c0,0,0,0-0.1,0H300.1c-8.2,0-14.9,6.5-15,14.7v29.4h132.1V281.5z" fill="#FFFFFF"/>
    </svg>
  )
}

export default SofaIcon
