import React, { useState, useEffect, useContext } from 'react';
import { Context } from '@root/Context';
import { StreamChat } from 'stream-chat';
import PetsIcon from '@material-ui/icons/Pets';
import Rating from '@material-ui/lab/Rating';
import './cuidadorReservasDetalle.scss';
// Services
import * as VARIABLES from 'constants/variables';
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// Components
import TitleArrowBack from '@root/components/TitleBack';
import Notification from 'components/notification';
import StreamMessage from 'components/StreamMessage';
import { ReservationInfo, ClientInfo } from './components';
// Material-UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const apiKey = VARIABLES.GET_STREAM_KEY;

function CuidadorReservasDetalle(props) {
  // ID de la Reserva
  const id = props.match.params.id;

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const notification = useNotificationValue();
  const [reload, setReload] = useState(false);

  // const [reservation, setReservations] = useState(undefined)
  const [booking, setBooking] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [services, setServices] = useState([
    {
      service: {
        id: null,
        name: '',
        image: null,
        money: {
          id: null,
          abreviated: '',
        },
      },
    },
  ]);

  const { cookies, userName, userImage } = useContext(Context);
  const mUserObj = {
    id: `ms-${cookies.id}`,
    name: userName,
    image: `${VARIABLES.IMAGE_URL}${userImage}`,
  };
  const [chatClient] = useState(new StreamChat(apiKey));
  const [channel, setChannel] = useState();

  const updateBooking = async (status) => {
    let body = {
      booking_id: id,
      status: status,
    };
    try {
      const response = await fetch(SERVICES.UPDATE_BOOKING_STATUS, OPTIONS.POST(body));
      const data = await response.json();
      if (response.status === 200) {
        if (data.error) {
          notification.handleSnackbar(data.error, 'error');
        } else {
          notification.handleSnackbar('Validando...', 'success');
        }
        setReload(true);
      } else {
        console.log('Actualizar Error -> ' + data);
      }
    } catch (error) {
      console.log('Actualizar Error -> ' + error);
    }
  };

  const fetchReservationData = async () => {
    setLoading(true);
    try {
      const response = await fetch(SERVICES.GET_BOOKING_BY_ID + id, OPTIONS.GET());
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setBooking(data.booking);
        // setServices(data.services)
        // console.log(data.services[0].service.money.abreviated)

        const resToken = await fetch(
          SERVICES.GET_MESSENGER_TOKEN,
          OPTIONS.POST({ me: cookies.id, target: data.booking.user_id }),
        );
        const dataToken = await resToken.json();
        await chatClient.connectUser(mUserObj, dataToken.token);
        // create grouped channel
        const members = [mUserObj.id, `ms-${data.booking.user_id}`, VARIABLES.GET_STREAM_ADMIN];
        const newChannel = chatClient.channel('messaging', {
          members: members,
        });
        await newChannel.create();
        setChannel(newChannel);

        setLoading(false);
      } else {
        notification.handleSnackbar('Error al obtener los datos', 'error');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchReservationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <div className="cuidador-res-detail">
      <Container maxWidth="lg">
        <div className="cuidador-res-detail__title">
          <TitleArrowBack handleClick={() => props.history.goBack()}>
            <h3>Volver</h3>
          </TitleArrowBack>
        </div>
        {booking && (
          <Grid container spacing={3}>
            {booking.state === 1 && (
              <Grid item xs={12}>
                <div className="dh-card-border">
                  <div className="client-reservation-detail__section-title">
                    <h3>Acciones</h3>
                    <div className="border-space" />
                  </div>
                  <h4>Necesitas confirmar la reserva</h4>
                  <div className="dh-flex dh-button-container">
                    <div style={{ paddingRight: '0.5rem' }}>
                      <div className="client-reservation-detail__btn-action">
                        <button className="btn-success" onClick={() => updateBooking(2)}>
                          Confirmar Reserva
                        </button>
                      </div>
                    </div>
                    <div style={{ paddingLeft: '0.5rem' }}>
                      <div className="client-reservation-detail__btn-action">
                        <button className="btn-danger" onClick={() => updateBooking(4)}>
                          Rechazar Reserva
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
            {booking.state === 2 && (
              <Grid item xs={12}>
                <div className="dh-card-border">
                  <div className="client-reservation-detail__section-title">
                    <h3>Acciones</h3>
                    <div className="border-space" />
                  </div>
                  <div>
                    <h4>Pendiente de pago</h4>
                    <div className="client-reservation-detail__btn-action">
                      <div className="wait-holder">
                        <p>
                          La reserva ha sido aceptada, pero el cliente aun no realiza el pago,
                          este dispone de un plazo de 4 horas para hacerlo antes de que caduque
                          la solicitud.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
            {booking.state === 6 && (
              <Grid item xs={12}>
                <div className="dh-card-border">
                  <div className="dh-flex content-space">
                    <div className="client-reservation-detail__section-title">
                      <h3>Comentario</h3>
                      <div className="border-space" />
                    </div>
                  </div>
                  <div className="res-detail__body">
                    <div className="res-detail__body--grid-1">
                      <div className="res-detail__body--item">
                        <p>tasa de servicio</p>
                        <Rating
                          name="customized-empty"
                          defaultValue={booking.feedback_level}
                          precision={0.5}
                          icon={<PetsIcon fontSize="inherit" />}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="res-detail__body--grid-1">
                      <div className="res-detail__body--item">
                        <p>reacción</p>
                        <h4>{booking.feedback}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <ReservationInfo booking={booking} services={services} />
            </Grid>
            {booking.state === 3 && (
              <Grid item xs={12}>
                <div className="dh-card-border">
                  <h4>Esta reserva está lista para completar</h4>
                  <button
                    style={{ marginTop: 20 }}
                    onClick={() => updateBooking(5)}
                    className="btn-primary medium btn-step">
                    Reserva completa
                  </button>
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <ClientInfo
                reservation={booking}
                pets={booking.booking_has_pets}
                showContact={booking.state === 2 || booking.state === 3}
              />
            </Grid>

            <Grid item xs={12} className="container">
              {!loading && (
                <StreamMessage chatClient={chatClient} channel={channel} booking={booking} />
              )}
            </Grid>
          </Grid>
        )}
      </Container>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default CuidadorReservasDetalle;
