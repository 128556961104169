import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function PillIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg 
      id="Capa_1"
      data-name="Capa 1"
      width="44.776px"
      height="44.776px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512">
      <circle cx={256} cy={256} r={256} fill={color}/>
      <path 
        d="M204.73,322.83,208.24,305c6.78-34.66,24.52-81.86,70.52-109.87l17.72-10.78L187.57,117.68a83,83,0,1,0-86.64,141.65ZM95,158.39a57.7,57.7,0,0,1,79.34-19.12L249.1,185c-36,28.67-53.27,67.78-61.58,97.61l-73.39-44.87A57.77,57.77,0,0,1,95,158.39Z" 
        transform="translate(0)"
        fill="#fff"/>
      <path 
        d="M412.82,259.41l-97.37-59.57-1.8.77a132.52,132.52,0,0,0-29.72,17.65L377.7,275.5a7.54,7.54,0,1,1-7.87,12.87S273.66,229,273.05,227.71c-34.39,33.57-44,79.34-46.62,101.24l-.29,2.42L330,394.86a79.39,79.39,0,0,0,82.86-135.45Z" 
        transform="translate(0)"
        fill="#fff"/>
    </svg>
  )
}

export default PillIcon
