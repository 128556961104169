import React from 'react'
import './badgePublication.scss'

const variant = {
  0: 'error',
  1: 'success',
};

const variantText = {
  0: 'No Publicado',
  1: 'Publicado',
};

function BadgePublication(props) {
  const { status } = props
  const type = variant[status]
  const typeText = variantText[status]
  
  return (
    <div className="dh-badge-publication">
      <div className={`circulo ${type}`}/>
      <h4>{typeText}</h4>
    </div>
  )
}

export default BadgePublication
