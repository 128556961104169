import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';

import { DogLayout, Space } from 'ui-component';
import * as ROUTES from 'constants/routes';
import styles from './terminos.module.scss';

const Svg = () => (
  <svg
    className={styles.svg}
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1920 100.1">
    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
  </svg>
);

const Terminos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DogLayout containerMaxWidth="md">
      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.title}>
            <Container>
              <h1>Términos y condiciones</h1>
            </Container>
          </div>
          <Svg />
        </div>
        <Container className={styles.container}>
          <Space direction="vertical" size={32}>
            <div className={styles.box}>
              <h3 className={styles.name}>1. Servicios</h3>
              <Space direction="vertical">
                <p>
                  Nos obligamos – en los términos siguientes- a brindarle a su mascota el
                  servicio de alojamiento, a través de los cuidadores registrados en nuestra web
                  o aplicación, quienes son amantes de los animales y con disponibilidad para
                  cuidar a su mascota. Somos un intermediario entre usted y el resto de nuestros
                  clientes dichos cuidadores. Corporación Dog Houser S.A. no presta directamente
                  el servicio de cuidado de su mascota.
                </p>
                <p>
                  Incluimos en nuestra red de cuidadores a personas naturales que se registran
                  en nuestra web y respecto de las cuales hemos verificado domicilio, documento
                  de identidad y número de celular. Solo se aceptan cuidadores que manifiestan
                  tener experiencia cuidando animales, sean propios o de terceros. Además, les
                  hemos provisto información acerca de cómo debe ser el cuidado de mascotas y
                  evitar posibles riesgos en el hogar. El cuidador ha confirmado haber revisado
                  tanto el “Manual del Houser” como el “Mapa de Riesgos”.
                </p>
                <p>
                  Los cuidadores no son veterinarios ni expertos en atención de enfermedades ni
                  cura de animales. Son personas interesadas en tener un huésped temporal para
                  alimentarlo, pasearlo y darle mucho cariño. En este sentido usted declara
                  conocer y aceptar que el servicio prestado por los cuidadores de la red de Dog
                  Houser no es un servicio veterinario. Dog Houser, sin embargo, presta asesoría
                  y un servicio de emergencias veterinarias a través de sus clínicas aliadas.
                </p>
                <p>
                  Usted acepta y conoce que 48 horas antes del inicio del servicio se le podrá
                  requerir que opte por otro cuidador al previamente elegido. Asimismo, en el
                  caso que la mascota presente signos visibles de debilidad, alergia,
                  enfermedad, Dog Houser se reserva el derecho a no prestar el servicio en cuyo
                  caso, devolverá el pago realizado por el dueño, de manera inmediata.
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>2. Pago</h4>
              <Space direction="vertical">
                <p>
                  Usted se obliga a pagar, por adelantado, a Dog Houser la contraprestación del
                  servicio descrito en el numeral 1, anterior, la que oscilará en el siguiente
                  rango de S/50.00 a S/.60.00, según la casa que el cliente escoja. La tarifa es
                  por cada 24 horas. En caso el servicio sea o se extienda por menos de 24
                  horas, se cobrará la fracción respectiva a 12 horas, calculada en base a los
                  precios antes mencionados. Dog Houser podrá variar el rango de precios
                  anterior de manera unilateral, sin que para ello requiera de autorización o
                  aceptación previa de usted o de alguno de sus clientes. En el caso que, con
                  posterioridad al pago de los servicios, usted requiera servicios adicionales o
                  extender su estadía, deberá solicitarlos con no menos de 24 horas de
                  anticipación y pagarlos por adelantado, para que dichos servicios adicionales
                  empiecen a ser prestados. El pago se realiza por transferencia bancaria a la
                  cuenta de Corporación Dog Houser S.A.. El servicio no será prestado hasta que
                  Dog Houser cuente con evidencia bancaria o similar, a su satisfacción, del
                  pago realizado.
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>3. Inicio del servicio</h4>
              <Space direction="vertical">
                <p>
                  Para garantizar la prestación del servicio, se requiere la verificación – a
                  satisfacción de Dog Houser, del pago por adelantado y la copia del carnet de
                  vacunación de la mascota. Si no se ha recibido evidencia aceptable para Dog
                  Houser del pago y del carnet de vacunación, el cuidador tiene el derecho no
                  recibir a su mascota.{' '}
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>4. Cancelación del servicio</h4>
              <Space direction="vertical">
                <p>
                  Si luego del pago, usted ya no requiere el servicio y lo comunica hasta 4 días
                  antes de la fecha pactada para el inicio del servicio, se le retendrá el 20%
                  del monto pagado por gastos administrativos. Si la cancelación se produce
                  entre 3 y 1 día antes de la fecha pactada para el inicio del servicio, se
                  retendrá el 50 % del monto pagado por cada día agendado originalmente para la
                  prestación del servicio. Si la cancelación es el mismo día previsto para el
                  inicio de la prestación del servicio, se retendrá el 100% del pago realizado
                  por usted. El dinero se devolverá en la cuenta que usted indique, por escrito
                  con un máximo de 15 días después de realizada la cancelación. Dog Houser no se
                  responsabiliza por revisar que el número de cuenta que usted haya indicado,
                  que sea correcto o que sea de su titularidad. Usted también tiene la opción de
                  no pedir la devolución y utilizar el pago, como saldo a favor para futuras
                  contrataciones de servicio de Dog Houser, que usted realice.
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>5. Contacto de emergencia</h4>
              <Space direction="vertical">
                <p>
                  Usted declara, autoriza y acepta que en el supuesto que su mascota se enferme,
                  se lastime, o tuviera una condición preexistente que se agrave durante el
                  alojamiento con alguno de los cuidadores registrados, lo llamaremos o
                  enviaremos un mensaje en los números de celular y teléfono indicados en los
                  datos proporcionados por usted en la aplicación o página web de Dog Houser. Si
                  no lográramos contactarlo inmediatamente, usted autoriza expresamente a que el
                  cuidador asista la veterinaria asignada por el seguro (referido en el numeral
                  siguiente), administre medicamentos o brinde otra atención que a criterio del
                  cuidador o por indicación del veterinario consultado sea necesaria para la
                  atención de su mascota. En ese sentido, si no logramos ubicarlo, usted
                  autoriza expresamente al cuidador a tomar las decisiones que a su criterio o
                  por indicación del veterinario asignado por el seguro (referido en el numeral
                  siguiente) sean las adecuadas para la atención y cuidado de su mascota.
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>6. Seguro</h4>
              <Space direction="vertical">
                <p>
                  El seguro aplica únicamente para la mascota registrada alojada con alguno de
                  nuestros cuidadores a través de Dog Houser y durante el tiempo de alojamiento.
                  Usted autoriza a Dog Houser a activar el seguro veterinario de emergencias en
                  la clínica afiliada que se encuentre más cerca al domicilio del cuidador
                  respectivo. El seguro está incluido en la tarifa descrita en el numeral 2
                  anterior, y cubre hasta una suma de S/.600.00. En el caso que los gastos por
                  la atención de su mascota por emergencia sobrepasen dicha cantidad, usted
                  acepta asumirlos, a fin de asegurar la atención que, en opinión de Dog Houser,
                  es la adecuada para salvar a su mascota. Si usted desea atender a su mascota
                  en otra veterinaria que no sea afiliada al seguro mencionado, puede hacerlo
                  asumiendo la obligación de pago de todos los gastos correspondientes y no
                  aplicará el seguro veterinario de emergencias. Usted declara conocer los
                  términos y condiciones de dicho seguro, que aparecen descritas en{' '}
                  <RouterLink to={ROUTES.EMERGENCY_INSURANCE}>
                    https://www.doghouser.com/seguro-de-emergencia
                  </RouterLink>
                  .
                </p>
                <p>
                  Dog Houser no asume responsabilidad alguna por los accidentes que pudieran
                  ocurrir en casa de los cuidadores, el tratamiento, proceso de curación o
                  cirugía que aplique el veterinario del seguro referido ni de ningún otro
                  veterinario.
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>
                7. Horarios ingreso y salida de las mascotas del locación del cuidador
              </h4>
              <Space direction="vertical">
                <p>
                  La hora de ingreso deberá acordarse con cada cuidador afiliado a Dog Houser.
                  El horario de entrada y salida de su mascota deberá ser entre las 7am y las
                  9pm. Si el servicio de hospedaje incluye algún periodo menor a 24 horas, se
                  cobrará la fracción de precio equivalente a 12 horas, del precio pactado. Ello
                  aplica a demoras en el recojo de su mascota.{' '}
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>
                8. Artículos requeridos para prestación del servicio
              </h4>
              <Space direction="vertical">
                <p>
                  Las mascotas deben ser entregadas al cuidador con su respectiva comida, cama y
                  plato de agua y de comida. Todas las mascotas que sean entregadas a los
                  cuidadores afiliados, deben, necesariamente contar con una placa de
                  identificación con su nombre y número de teléfono del propietario de la
                  mascota. Dog Houser no se responsabiliza por cualquier otro objeto distinto a
                  los mencionados que dejen al cuidador de su mascota.{' '}
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>9. Salud y Comportamiento</h4>
              <Space direction="vertical">
                <p>
                  Usted declara que su mascota no está enferma, herida ni tiene problemas de
                  comportamiento (incluyendo comportamiento agresivo, es altamente destructivo o
                  muerde), ni tiene comportamiento alguno que no haya sido registrado en su
                  ficha de cliente o comunicado, por escrito, a Dog Houser. Usted declara que su
                  mascota no ha sido expuesta a rabia, distémper ni parvovirus en los 30 días
                  anteriores a la fecha del inicio de los servicios a ser prestados por el
                  cuidador de su elección. Usted declara conocer que los cuidadores afiliados a
                  Dog Houser no aceptan mascotas que puedan representar un peligro para los
                  cuidadores, para la locación donde se aloja la mascota, para terceros o para
                  otras mascotas. En el caso que su mascota muerda o, de alguna manera, ataque
                  al cuidador a su cargo, a un tercero o a otra mascota, el cuidador,
                  directamente o algún representante de Dog Houser contactará con usted y si las
                  heridas fueran de gravedad, a las autoridades correspondientes. En este
                  supuesto, se le comunicará inmediatamente.{' '}
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>10. Accidentes</h4>
              <Space direction="vertical">
                <p>
                  Si ha solicitado alojamiento compartido, declara conocer que su mascota estará
                  en contacto con otras mascotas. Por ello, al solicitar este servicio usted
                  asegura que su mascota no presenta comportamientos agresivos ni antecedentes
                  que puedan significar un peligro para el cuidador o para las otras mascotas.
                  Usted reconoce y acepta que en el caso que su mascota resulte herida por la
                  interacción con las otras mascotas o se produzca algún daño derivado de dicha
                  interacción, USTED asumirá el costo de dichos daños y LIBERA AL CUIDADOR Y A
                  DOG HOUSER DE TODA RESPONSABILIDAD Y OBLIGACIÓN DE PAGO RELACIONADA A TALES
                  HECHOS. Si su mascota hiere a otra(s) mascota(s) a alguna otra persona, o
                  algún bien de propiedad de un tercero, usted será el único responsable de
                  cualquier daño y asume la obligación de reparar el daño.
                </p>
                <p>
                  LA EMPRESA SE HACE RESPONSABLE DE BRINDAR AL CUIDADOR LA ORIENTACIÓN PARA QUE
                  LA MASCOTA HERIDA SEA ATENDIDA RÁPIDAMENTE POR LAS CLÍNICAS VETERINARIAS
                  AFILIADAS O LA MÁS CERCANA SEGÚN CORRESPONDA, APENAS TENGA CONOCIMIENTO DEL
                  INCIDENTE. ASIMISMO, SE ENCARGARÁ DE REALIZAR LOS PAGOS CORRESPONDIENTES COMO
                  PARTE DEL SEGURO DE EMERGENCIAS CON EL FIN DE PRIORIZAR LA VIDA DE SU MASCOTA
                  Y GARANTIZAR SU BIENESTAR, MÁS NO ES RESPONSABLE DE LAS CONSECUENCIAS A CAUSA
                  DE ACCIDENTES ORIGINADOS POR CASO FORTUITO O DE FUERZA MAYOR O POR HECHOS
                  IMPUTABLES A TERCEROS.{' '}
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>11. Mascotas abandonadas</h4>
              <Space direction="vertical">
                <p>
                  Si usted no recoge a su mascota el día y a la hora pactada, y no es posible
                  comunicarse con usted dentro de las 48 horas de dicha fecha; o al habernos
                  comunicado con usted no recoge a su mascota dentro del nuevo horario
                  proporcionado por usted, entendemos que ha abandonado a su mascota y la
                  entregaremos a un tercero para que se encargue de su adopción. Asimismo,
                  reportaremos el hecho ante las autoridades municipales. En este supuesto y
                  transcurridos los plazos señalados en el presente numeral, USTED LIBERA AL
                  CUIDADOR Y A DOGHOUSER DE TODA RESPONSABILIDAD SOBRE EL CUIDADO Y ATENCIÓN DE
                  SU MASCOTA.
                </p>
                <p>
                  En todo caso, usted se obliga a pagar todos los gastos incurridos por el
                  cuidador y por Dog Houser en el supuesto referido en el párrafo anterior, así
                  como el costo del servicio de alojamiento de todos los días adicionales que,
                  debido a dichas circunstancias, su mascota deba quedarse con el cuidador o con
                  la persona o albergue que Dog Houser haya dispuesto. Asimismo, y de ser el
                  caso, usted se obliga a pagar todos los gastos judiciales o extrajudiciales de
                  cobro de la suma que usted adeude a Dog Houser, así como los intereses
                  moratorios, respectivos, sin que para ello sea necesario la intimación en
                  mora.
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>12. Pérdida o robo</h4>
              <Space direction="vertical">
                <p>
                  Los paseos se realizarán obligatoriamente con correa (y bozal en caso usted lo
                  solicite expresamente por escrito al redactar las instrucciones de cuidado en
                  su reserva vía nuestro formulario). En caso usted no quiera que su mascota sea
                  paseada en la vía pública y se quede en el domicilio o locación del cuidador
                  elegido, deberá solicitarlo por escrito al contratar el servicio al redactar
                  las instrucciones de cuidado en su reserva vía nuestro formulario. En caso no
                  lo indique, se entenderá que usted ha autorizado los paseos de su mascota en
                  la vía pública.{' '}
                </p>
                <p>
                  En caso de pérdida o robo de su mascota, el cuidador se comunicará
                  inmediatamente con Dog Houser. Nosotros, apenas tengamos conocimiento del
                  hecho nos comunicaremos con las autoridades para el rastreo de la zona.
                  Además, ponemos a su disposición nuestros medios de comunicación y redes
                  sociales. También haremos una comunicación masiva a nuestra red de cuidadores
                  y paseadores en la ciudad para que apoyen la búsqueda. Salvo que la pérdida o
                  robo de la mascota sea por culpa grave o dolo del cuidador, usted se obliga a
                  asumir todos los costos incurridos en la búsqueda y/o rescate de la mascota.
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>13. Propietario autorizado</h4>
              <Space direction="vertical">
                <p>
                  Usted declara y garantiza ser el dueño de la mascota para la cual solicita el
                  servicio de alojamiento y declara y garantiza estar plenamente autorizado para
                  aceptar los términos y condiciones del presente acuerdo. Asimismo, declara y
                  garantiza que toda la información proporcionada a Dog Houser sobre su persona
                  y su mascota es verdadera, precisa y completa. Usted libera expresamente a Dog
                  Houser y a los cuidadores afiliados de toda responsabilidad derivada de
                  cualquier pérdida, daño o gasto, incluyendo honorarios de abogados, derivadas
                  de la falsedad o imprecisión de la información brindada a Dog Houser y, en
                  consecuencia, se obliga a reembolsar cualquier gasto ocasionado por ello e
                  indemnizar a Dog Houser, al cuidador o a cualquier tercero que resulte
                  afectado por la falsedad o imprecisión de la información otorgada a Dog
                  Houser, incluyendo de forma enunciativa más no limitativa, cualquier reclamo
                  de un tercero sobre la propiedad de su mascota. Asimismo, usted se obliga a
                  pagar los daños materiales ocasionados por su mascota durante la prestación
                  del servicio de alojamiento.
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>14. Uso de imágenes de su mascota</h4>
              <Space direction="vertical">
                <p>
                  Usted autoriza expresamente a Dog Houser y a los cuidadores afiliados a tomar
                  fotos de su mascota y que dichas fotos se publiquen en la página web y en la
                  aplicación de Dog Houser o en cualquier medio digital o escrito con fines
                  informativos, como publicidad y con cualquier finalidad que Dog Houser
                  considere conveniente para los fines de su objeto social.{' '}
                </p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>
                15. Autorización de uso de datos personales de los usuarios
              </h4>
              <Space direction="vertical">
                <p>
                  Usted autoriza, expresamente, a Dog Houser a recopilar y almacenar sus datos
                  personales (nombre, número de documento de identidad, número de teléfono y
                  correo electrónico) única y exclusivamente para la prestación del servicio de
                  alojamiento de su mascota; para que nos podamos comunicar con usted para temas
                  relacionados, directa o indirectamente, con la prestación de dicho servicio de
                  alojamiento de su mascota y para qué Dog Houser le envíe links o herramientas
                  para la actualización de su página web, y de su aplicación para smartphones y
                  iphones; así como para remitirle publicidad vinculada a Dog Houser. Dog Houser
                  se obliga a mantener confidencialidad de sus datos personales y se obliga a
                  usarlos, única y exclusivamente, para lo descrito en el presente numeral.
                  Usted declara conocer que en caso no complete los datos personales (nombre,
                  número de documento de identidad, número de teléfono y correo electrónico)
                  requeridos para registrarse en la web o en la aplicación de Dog Houser, no
                  será posible conectarlo con un cuidador para que preste el servicio de
                  alojamiento de su mascota. Usted se obliga a actualizar sus datos personales
                  en caso éstos cambien o en caso detecte alguna imprecisión en los mismos. Sin
                  perjuicio de ello, usted declara que los datos proporcionados a DogHouser han
                  sido brindados voluntariamente por usted y qué Dog Houser los ha obtenido del
                  registro que usted ha hecho en nuestra web y/o en nuestra aplicación.{' '}
                </p>
                <p>
                  Usted declara conocer que tiene derecho a solicitar a Dog Houser la
                  actualización, inclusión, rectificación y supresión de sus datos personales
                  cuando estos sean parcial o totalmente inexactos, incompletos, cuando se
                  hubiere advertido omisión, error o falsedad, cuando hayan dejado de ser
                  necesarios o pertinentes a la finalidad para la cual hayan sido recopilados o
                  cuando hubiera vencido el plazo referido en el siguiente párrafo. Para tal
                  efecto, bastará ingresar al registro de usuario de Dog Houser y presionar la
                  opción “Editar Datos Personales”.
                </p>
                <p>Dog Houser almacenará sus datos personales por un máximo de 4 años.</p>
              </Space>
            </div>
            <div className={styles.box}>
              <h4 className={styles.name}>16. Disposiciones generales</h4>
              <Space direction="vertical">
                <p>
                  Su aceptación de los presentes términos y condiciones mediante la presión del
                  cursor en la casilla de “Aceptar”, constituye el único y exclusivo acuerdo
                  contrato con Dog Houser, por lo que usted se obliga a cumplir con las
                  obligaciones derivadas del presente documento.
                </p>
              </Space>
            </div>
          </Space>
        </Container>
      </div>
    </DogLayout>
  );
};

export default Terminos;
