import React, { useState } from 'react'
// Utils
import normalize from '../utils/normalize'

function useSearchUsers(users) {
  const [ query, setQuery ] = useState('');
  const [ filteredUsers, setFilteredUsers ] = useState(users);

  React.useMemo(() => {
    const result = users.filter(user => {
      return (
          `${normalize(user.name)} ${normalize(user.last_name)} ${user.email}`
          .toLowerCase()
          .includes(normalize(query.toLowerCase()))
      )
    });
    setFilteredUsers(result)
  }, [ users, query ]);

  return { query, setQuery, filteredUsers }
}

export default useSearchUsers
