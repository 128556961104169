import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
  white: 'var(--iconColorWhite)',
  black: 'var(--iconColorBlack)',
}

function ArrowIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      {/* Generator: Sketch 53.1 (72631) - https://sketchapp.com */}
      <title>baseline-arrow_forward-24px (3)</title>
      <desc>Created with Sketch.</desc>
      <g id="深知" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="消息中心-copy-4" transform="translate(-494.000000, -136.000000)">
          <g id="baseline-arrow_forward-24px-(3)" transform="translate(490.000000, 132.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24" />
            <polygon id="Path" fill={color} fillRule="nonzero" points="12 4 10.59 5.41 16.17 11 4 11 4 13 16.17 13 10.59 18.59 12 20 20 12" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ArrowIcon
