import React from 'react';
import { Box } from '@material-ui/core';

import styles from './adminTitle.module.scss';

const AdminTitle = (props: any) => {
  const { title, actions } = props;
  return (
    <Box marginBottom={3}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.actions}>{actions}</div>
      </div>
    </Box>
  );
};

export default AdminTitle;
