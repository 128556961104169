import React, { useState } from 'react'

function useFilterServices(services) {
  const [values, setValues] = useState({
    country_peru: false,
    country_colombia: false,
    country_espana: false,
    type_id_1: false,
    type_id_2: false,
    type_id_3: false,
    type_id_4: false,
    type_id_22: false,
    houser_junior: false,
    houser_senior: false,
    houser_super: false
  })
  const [ filteredServices, setFilteredServices ] = useState(services)

  const handleValuesChange = name => event => {
    setValues({ ...values, [name]: event.target.checked })
  }

  const filterLevel = (service) => {
    return (
      (
        values.houser_junior && `${service.houser_level_id}`.includes(1)
      ) || (
        values.houser_senior && `${service.houser_level_id}`.includes(2)
      ) || (
        values.houser_super && `${service.houser_level_id}`.includes(3)
      ) || (
        (!values.houser_junior && !values.houser_senior && !values.houser_super) && service
      )
    )
  }

  const filterCountry = (service) => {
    return (
      (
        values.country_peru && `${service.country}`.includes('Perú')
      ) || (
        values.country_colombia && `${service.country}`.includes('Colombia')
      ) || (
        values.country_espana && `${service.country}`.includes('España')
      ) || (
        (!values.country_peru && !values.country_colombia && !values.country_espana) && service
      )
    )
  }

  const filterType = (service) => {
    return (
      (
        values.type_id_1 && `${service.type_id}`.includes(1)
      ) || (
        values.type_id_2 && `${service.type_id}`.includes(2)
      ) || (
        values.type_id_3 && `${service.type_id}`.includes(3)
      ) || (
        values.type_id_4 && `${service.type_id}`.includes(4)
      ) || (
        (!values.type_id_1 && !values.type_id_2 && !values.type_id_3 && !values.type_id_4) && service
      )
    )
  }

  React.useMemo(() => {
    const result = services.filter(filterLevel).filter(filterType).filter(filterCountry)
    setFilteredServices(result)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ services, values ]);

  return { values, handleValuesChange, filteredServices }
}

export default useFilterServices
