import { ReactNode, CSSProperties, forwardRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, CardActions, Divider } from '@material-ui/core';

interface MainCardProps {
  children: ReactNode;
  border?: boolean;
  borderHeader?: boolean;
  boxShadow?: boolean;
  elevation?: number;
  content?: boolean;
  className?: string;
  contentClass?: string;
  contentStyle?: CSSProperties;
  secondary?: ReactNode | string;
  style?: CSSProperties;
  title?: ReactNode | string;
  subheader?: ReactNode | string;
  actions?: ReactNode | string;
}

const MainCard = forwardRef<HTMLDivElement, MainCardProps>((props, ref) => {
  const {
    border = false,
    borderHeader = false,
    boxShadow = true,
    children,
    content = true,
    contentClass,
    contentStyle,
    secondary,
    style,
    title,
    subheader,
    actions,
    ...others
  } = props;
  const theme = useTheme();

  return (
    <Card
      ref={ref}
      style={{
        border: border ? '1px solid' : 'none',
        borderColor: theme?.palette.grey[300],
        boxShadow: boxShadow
          ? '0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%)'
          : 'none',
        ...style,
      }}
      {...others}>
      {title && (
        <CardHeader
          title={title}
          subheader={subheader}
          action={secondary}
          style={{ paddingBottom: 0 }}
        />
      )}
      {title && borderHeader && <Divider style={{ marginTop: 16 }} />}
      {content && (
        <CardContent className={contentClass} style={contentStyle}>
          {children}
        </CardContent>
      )}
      {!content && children}
      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  );
});

export default MainCard;
