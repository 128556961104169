import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { Room as RoomIcon, Pets as PetsIcon } from '@material-ui/icons';

import { useResponsive } from '@root/hooks';
import * as VARIABLES from '@root/constants/variables';
import styles from './houserCard.module.scss';

const USER_EMPTY = 'https://cdn2.thecatapi.com/images/888.jpg';

const HouserCard = (props) => {
  const { user, handleHover } = props;
  const [avgRate, setAvgRate] = useState(0);
  const { isMobile } = useResponsive();

  const serviceTitle = user.houser.type_exclusive
    ? 'Casa Exclusiva'
    : VARIABLES.BOOLEAN_VALUES[user.houser.type_share] && 'Casa Compartida';
  const coverImage = VARIABLES.IMAGE_URL + user.houser.galleries[0]?.photo;
  const houserImage = user?.photo ? VARIABLES.IMAGE_URL + user.photo : USER_EMPTY;

  useEffect(() => {
    if (!user) return;
    const avg = user?.houser?.bookings
      ?.filter((booking) => booking.state === 5)
      .map((item) => item.feedback_level)
      .reduce((a, b) => a + b, 0);
    setAvgRate(avg);
  }, [user]);

  return (
    <RouterLink
      to={`/cuidador/${user.id}`}
      className={styles.root}
      onMouseEnter={() => handleHover(user.id)}
      onMouseLeave={() => handleHover(0)}>
      <div className={styles.coverImage}>
        <img src={coverImage} alt={user.name} />
      </div>
      <div className={styles.body}>
        <div className={styles.header}>
          <h4 className={styles.subHeader}>{serviceTitle}</h4>
          <h3 className={styles.title}>{user.houser?.publication?.title}</h3>
          <div className={styles.location}>
            <RoomIcon />
            <span>{user.location?.district}</span>
          </div>
          {user.houser?.houser_services?.map((hs, index) => {
            const invalidServices =
              hs.service_id === 21 ||
              hs.service_id === 22 ||
              hs.service_id === 22 ||
              hs.service_id === 23 ||
              hs.service_id === 24 ||
              hs.service_id === 25
                ? true
                : false;

            if (invalidServices) {
              return null;
            }

            const price =
              Number(hs.service.gross_comission) + Number(hs.service.houser_comission);
            const priceStr = hs.service?.money?.symbol + price;

            return (
              <p className={styles.price} key={index}>
                {hs.service.name}: <strong>{priceStr}</strong> por día
              </p>
            );
          })}
        </div>
        <div className={styles.bottom}>
          <div className={styles.wrap}>
            {!isMobile && (
              <Rating
                name="customized-empty"
                value={avgRate}
                precision={0.5}
                icon={<PetsIcon fontSize="inherit" />}
                readOnly
              />
            )}
            <div className={styles.user}>
              <div className={styles.name}>{user.name}</div>
              <Avatar className={styles.cover} alt={user.name} src={houserImage} />
            </div>
          </div>
        </div>
      </div>
    </RouterLink>
  );
};

export default HouserCard;
