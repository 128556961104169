import React from 'react';
import './floatingButton.scss';
// Images
import shop from 'assets/images/icon-shop-2.png';

const FloatingButton = (props) => {
  return (
    <a
      href="https://www.doghouser.pe/"
      target="_blank"
      rel="noopener noreferrer"
      className="float">
      <img src={shop} alt="Tienda Doghouser" />
    </a>
  );
};

export default FloatingButton;
