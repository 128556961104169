import React from 'react'
import PropTypes from 'prop-types'
// Material - UI
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const FilterPortrait = props => {
  const { values, handleValuesChange, handleUpdateData } = props

  return (
    <div className="houser-list__tab-filters houser-list__popover-filter">
      <div className="popover-title">
        <h2>Tipo de hospedaje</h2>
      </div>
      <FormControl component="fieldset" className="popover-formcontrol">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={values.type_share} onChange={handleValuesChange('type_share')} color="primary"/>}
            label="Hospedaje Compartido"
          />
          <FormControlLabel
            control={<Checkbox checked={values.type_exclusive} onChange={handleValuesChange('type_exclusive')} color="primary"/>}
            label="Hospedaje Exclusivo"
          />
          <FormControlLabel
            control={<Checkbox checked={values.type_delivery} onChange={handleValuesChange('type_delivery')} color="primary"/>}
            label="Delivery Houser"
          />
        </FormGroup>
      </FormControl>
      <div className="popover-title">
        <h2>Servicios adicionales</h2>
      </div>
      <FormControl component="fieldset" className="popover-formcontrol">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={values.pills} onChange={handleValuesChange('pills')} color="primary"/>}
            label="Puede dar pastillas"
          />
          <FormControlLabel
            control={<Checkbox checked={values.injectables} onChange={handleValuesChange('injectables')} color="primary"/>}
            label="Puede aplicar inyectables"
          />
          <FormControlLabel
            control={<Checkbox checked={values.allowed_cats} onChange={handleValuesChange('allowed_cats')} color="primary"/>}
            label="Permite gatos"
          />
        </FormGroup>
      </FormControl>
      <div className="popover-title">
        <h2>Condiciones de la casa</h2>
      </div>
      <FormControl component="fieldset" className="popover-formcontrol">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={values.allowed_sofa} onChange={handleValuesChange('allowed_sofa')} color="primary"/>}
            label="Se admiten mascotas en los muebles"
          />
          <FormControlLabel
            control={<Checkbox checked={values.allowed_bed} onChange={handleValuesChange('allowed_sofa')} color="primary"/>}
            label="Se admiten mascotas en la cama"
          />
          <FormControlLabel
            control={<Checkbox checked={values.allowed_house} onChange={handleValuesChange('allowed_sofa')} color="primary"/>}
            label="Se permite a las mascotas toda la casa"
          />
        </FormGroup>
      </FormControl>
      <div className="popover-title">
        <h2>Tamaño de perro permitido</h2>
      </div>
      <FormControl component="fieldset" className="popover-formcontrol">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={values.dog_small} onChange={handleValuesChange('dog_small')} color="primary"/>}
            label="Pequeño"
          />
          <FormControlLabel
            control={<Checkbox checked={values.dog_medium} onChange={handleValuesChange('dog_medium')} color="primary"/>}
            label="Mediano"
          />
          <FormControlLabel
            control={<Checkbox checked={values.dog_big} onChange={handleValuesChange('dog_big')} color="primary"/>}
            label="Grande"
          />
        </FormGroup>
      </FormControl>
      <div className="popover-title">
        <h2>Características del servico</h2>
      </div>
      <FormControl component="fieldset" className="popover-formcontrol">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={values.has_terrace} onChange={handleValuesChange('has_terrace')} color="primary"/>}
            label="Tiene terraza"
          />
          <FormControlLabel
            control={<Checkbox checked={values.has_garden} onChange={handleValuesChange('has_garden')} color="primary"/>}
            label="Tiene jardín"
          />
        </FormGroup>
      </FormControl>
      <div className="popover-actions">
        <button className="btn-default small no-hover">Borrar</button>
        <button className="btn-primary" onClick={handleUpdateData}>Guardar</button>
      </div>
    </div>
  )
}

FilterPortrait.propTypes = {
  values: PropTypes.object,
  handleValuesChange: PropTypes.func,
  handleUpdateData: PropTypes.func
}

export default FilterPortrait
