import { Link as RouterLink } from 'react-router-dom';
import CreateIcon from '@material-ui/icons/Create';

import LocationDTO from '@root/models/LocationDTO';

interface CardUserLocationType extends LocationDTO {
  loading: boolean;
  isHouser: boolean;
  address?: string;
  adress_detail?: string;
  type_road?: string;
  property_type?: string;
}

const CardUserLocation = (props: CardUserLocationType) => {
  const {
    loading,
    isHouser,
    user_id,
    latitude,
    longitude,
    city,
    country,
    district,
    address,
    adress_detail,
    type_road,
    property_type,
  } = props;

  const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
  const showMap = latitude && longitude ? true : false;
  const zoom = 14;

  return (
    <div className="card-user-location dh-card-elevation">
      <div className="card-user-location__header dh-flex content-space items-center">
        <div>
          <span>Ubicación del usuario</span>
        </div>
        <div>
          <RouterLink to={`/dh-admin/cuidadores/${user_id}/ubicacion`}>
            <button className="btn-primary border">
              <CreateIcon />
              Editar
            </button>
          </RouterLink>
        </div>
      </div>
      <div className="card-user-location__body">
        <div className="card-map">
          {!loading && (
            <>
              {showMap ? (
                <img
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=500x500&maptype=roadmap&markers=color:orange%7Clabel:H%7C${latitude},${longitude}&key=${GOOGLE_MAP_KEY}`}
                  alt="map"
                />
              ) : (
                <h4>No hay Ubicación</h4>
              )}
            </>
          )}
        </div>
        <div className="card-stats card-stats-1">
          <p>Ubicación</p>
          <h5>
            {city}, {country}
          </h5>
        </div>
        <div className="card-stats card-stats-2">
          <p>Distrito</p>
          <h5>{district}</h5>
        </div>
        <div className="card-stats card-stats-3">
          <p>Dirección</p>
          <h5>{address}</h5>
        </div>
        {isHouser && (
          <>
            <div className="card-stats card-stats-4">
              <p>Tipo de via</p>
              <h5>{type_road}</h5>
            </div>
            <div className="card-stats card-stats-5">
              <p>Tipo de propiedad</p>
              <h5>{property_type}</h5>
            </div>
            <div className="card-stats card-stats-6">
              <p>Detalle de la casa</p>
              <h5>{adress_detail}</h5>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CardUserLocation;
