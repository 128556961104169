import { SERVICE_TYPE } from '@root/constants/services';
import HouserServicesDTO from '@root/models/HouserServicesDTO';
import ServiceDTO, { TypeServiceType } from '@root/models/ServiceDTO';

export interface ServiceOptionType {
  id: TypeServiceType;
  name: string;
  helper: string;
  services?: ServiceDTO[];
}

export interface ResultType {
  serviceTypeIds: TypeServiceType[];
  serviceOptions: ServiceOptionType[];
}

const mutateHouserServices = async (
  houserServices: Array<HouserServicesDTO>,
): Promise<ResultType> => {
  const cleanServices: Array<ServiceDTO> = houserServices.map((item) => item.service);
  const serviceTypeIds = Array.from(
    new Set(cleanServices.map((item) => item.type_id).sort((a, b) => a - b)),
  );

  const serviceOptions = serviceTypeIds.map((typeId) => {
    const { label, helper } = SERVICE_TYPE.find(({ value }) => value === typeId);
    const services = cleanServices
      .filter((service) => service.type_id === typeId)
      .map((service) => {
        const total_price = Number(service.gross_comission) + Number(service.houser_comission);
        return { ...service, total_price };
      });

    return {
      id: typeId,
      name: label,
      helper: helper,
      services: services,
    };
  });

  return { serviceTypeIds, serviceOptions };
};

export default mutateHouserServices;
