import React, { CSSProperties, ReactNode, forwardRef } from 'react';
import { Container } from '@material-ui/core';
import clsx from 'clsx';

import styles from './layout.module.scss';

type maxWidthType = false | 'lg' | 'xs' | 'sm' | 'md' | 'xl';

interface LayoutProps {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  disableGutters?: boolean;
  container?: boolean;
  containerMaxWidth?: maxWidthType;
}

const Layout = forwardRef<HTMLDivElement, LayoutProps>((props, ref) => {
  const {
    className,
    style,
    children,
    disableGutters = false,
    container = true,
    containerMaxWidth = 'lg',
  } = props;

  const cn = clsx(styles.root, { [styles.disableGutters]: disableGutters }, className);

  return (
    <div
      ref={ref}
      className={cn}
      style={{
        ...style,
      }}>
      {container && <Container maxWidth={containerMaxWidth}>{children}</Container>}
      {!container && children}
    </div>
  );
});

export default Layout;
