export const SERVICE_TYPES = [
  'Hospedaje de mascotas por noche',
  'Guardería solo de día',
  'Guardería por mes',
  'Visitas a domicilio',
  'Paseos',
];

export const SERVICE_TYPE = [
  {
    value: 1,
    label: 'Hospedaje Compartido',
    helper: '',
  },
  {
    value: 2,
    label: 'Hospedaje Exclusivo',
    helper: '',
  },
  {
    value: 3,
    label: 'Guardería',
    helper: 'Max 10hr. diurnas',
  },
  {
    value: 4,
    label: 'Delivery Houser',
    helper: '',
  },
  {
    value: 22,
    label: 'Hotel',
    helper: '',
  },
];
