import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Tab as MuiTab } from '@material-ui/core';
import { TabProps } from '@material-ui/core/Tab';

interface StyledTabProps extends TabProps {
  label: string | React.ReactNode;
  disabled?: boolean;
  component?: string | React.ReactNode;
  to?: string;
}

const Tab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      marginRight: theme.spacing(4),
      fontSize: '1rem',
      fontFamily: 'Montserrat-Medium',
      '&:hover': {
        color: 'var(--primaryColor)',
        opacity: 1,
      },
      '&$selected': {
        color: 'var(--primaryColor)',
      },
      '&:focus': {
        color: 'var(--primaryColor)',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <MuiTab disableRipple {...props} />);

export default Tab;
