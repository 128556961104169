import { useEffect, useState, useMemo } from 'react';
import { Container, Grid, Paper, Divider, TextField } from '@material-ui/core';
// import { FormControl, FormControlLabel, RadioGroup, Radio} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { green } from '@material-ui/core/colors';
// import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';

import { useSnackbar } from '@root/hooks';
import { useInputValue } from '@root/hooks/useInputValue';
import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
import * as VARIABLES from '@root/constants/variables';
import './bookingCheckout.scss';

// eslint-disable-next-line no-unused-vars
const stripePromise = loadStripe(
  'pk_test_51Jym9PGzCRa1ws81amWWXu4yL7y2bVVMupbJLLMT6s8TGkpFdfrOw1tvlqkpLchIt6Wy27zfebQ4rOXkmPTUQttl00zAIkUUxT',
);
function BookingCheckout(props) {
  const snackbar = useSnackbar();
  // eslint-disable-next-line no-unused-vars
  const serviceTypes = [
    'Pets accomodation by night',
    'Dog daycare',
    'Dog nursery by month',
    'Drop in visits',
    'Pet walks',
  ];
  const id = props.match.params.id;

  const promo_code = useInputValue('');
  const [checkPromo, setCheckPromo] = useState(false);
  const [booking, setBooking] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [optionType, setOptionType] = useState(2);

  // Stripe
  // eslint-disable-next-line no-unused-vars
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBooking(id);
    fetchPaymentIntent(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBooking = async (id) => {
    const response = await fetch(SERVICES.GET_BOOKING_BY_ID + id, OPTIONS.GET());
    const data = await response.json();
    console.log(data.booking);
    setBooking(data.booking);
  };

  const fetchPaymentIntent = async (id) => {
    const response = await fetch(SERVICES.GET_PAYMENT_BY_ID + id, OPTIONS.GET());
    const data = await response.json();
    setClientSecret(data.paymentIntent.client_secret);
  };

  const serviceDays = useMemo(() => {
    if (booking) {
      const pickupDate = new Date(booking.pickup_date);
      const dropDate = new Date(booking.drop_date);
      return Math.floor((pickupDate - dropDate) / (1000 * 64 * 60 * 24)) + 1;
    } else {
      return '-';
    }
  }, [booking]);

  const currencyCode = useMemo(() => {
    if (booking) {
      return VARIABLES.CURRENCY_BY_COUNTRY[booking.service.country];
    } else {
      return '-';
    }
  }, [booking]);

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = (e) => {
    if (checkPromo) {
      // update payment intent
    } else {
      document.getElementById('submit').click();
    }
  };

  const handlePromo = async (e) => {
    try {
      const response = await fetch(
        SERVICES.USE_PROMO,
        OPTIONS.POST({
          booking: booking.id,
          code: promo_code.value,
        }),
      );
      const data = await response.json();
      if (data.promo) {
        setCheckPromo(true);
        fetchBooking(id);
      } else if (data.error) {
        snackbar.error(data.error);
      }
    } catch (error) {
      snackbar.error(error.message);
    }
  };

  const serivce_total_price = useMemo(() => {
    if (booking) {
      return Number(booking.service.houser_comission) + Number(booking.service.gross_comission);
    } else {
      return 0;
    }
  }, [booking]);

  return (
    <div className="checkout-wrapper">
      <Container maxWidth="md" className="container">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Paper className="checkout-grid">
              <h3 style={{ marginBottom: 16 }}>Transferencia Bancaria</h3>
              <div>
                {/* <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="payment-option-type"
                    name="payment-option-type"
                    value={optionType}
                    onChange={(e) => setOptionType(parseInt(e.target.value))}>
                    <FormControlLabel value={1} control={<Radio />} label="Credit and Debit Cards" className="radio-label" />
                  </RadioGroup>
                </FormControl> */}
                {/* {optionType == 1 && clientSecret !== '' && (
                  <>
                    <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                      <CheckoutForm booking={id} />
                    </Elements>
                  </>
                )} */}
                {/* Promo Code */}
                {/* {optionType == 1 && (<><div style={{ marginTop: 20 }}>
                  <p>Promo Code</p>
                  <div>
                    <Grid container>
                      <Grid item xs={4}>
                        <TextField 
                          id="name"
                          name="name"
                          required
                          fullWidth
                          variant="outlined"
                          type="text"
                          value={promo_code.value}
                          onChange={promo_code.onChange}
                          placeholder="Code"
                          aria-readonly
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {checkPromo && (
                          <div style={{ marginTop:8, marginLeft: 6 }}>
                            <DoneIcon style={{ color: green[500] }} />
                          </div>
                        )}
                        {!checkPromo && (
                          <button onClick={handlePromo} style={{marginLeft: 20}} type="button" className="btn-primary medium btn-step">
                            Redeem
                          </button>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <button
                  style={{marginTop: 20}}
                  type="button"
                  className="btn-primary medium btn-step"
                  onClick={handleSubmit} >
                  Request to Book
                </button></>)} */}
              </div>
              <div>
                {/* <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="payment-option-type"
                    name="payment-option-type"
                    value={optionType}
                    onChange={(e) => setOptionType(parseInt(e.target.value))}>
                    <FormControlLabel value={2} control={<Radio />} label="Blurb" />
                  </RadioGroup>
                </FormControl> */}

                {optionType === 2 && (
                  <div style={{ textAlign: 'center' }}>
                    <h5 style={{ lineHeight: 1.5, marginTop: 10 }}>
                      ¡Tu reserva casi ha sido confirmada! Para completarla debes realizar el
                      pago por las siguientes vías y enviarlo a Carmen 995759034 indicando tu
                      número de reserva.
                    </h5>
                    <h5 style={{ lineHeight: 1.5, marginTop: 10 }}>
                      Transferencia bancaria BCP: (Cuenta corriente soles)
                    </h5>
                    <p style={{ fontSize: 18, fontWeight: 600 }}>19490171758082</p>
                    <h5 style={{ lineHeight: 1.5, marginTop: 10 }}>
                      Transferencia interbancaria: (CCI)
                    </h5>
                    <p style={{ fontSize: 18, fontWeight: 600 }}>00219419017175808291</p>
                    <h5 style={{ lineHeight: 1.5, marginTop: 10 }}>
                      YAPE: <span style={{ fontSize: 16, fontWeight: 600 }}>959880207</span>{' '}
                      (Octavio Fernández Dávila)
                    </h5>
                    <h5 style={{ lineHeight: 1.5, marginTop: 10 }}>
                      (Para pagos con tarjeta de crédito comunicarse con Carmen)
                    </h5>
                    {!(booking && booking.promotion) && (
                      <div style={{ marginTop: 20, textAlign: 'left' }}>
                        <p>Promo Code</p>
                        <div>
                          <Grid container>
                            <Grid item xs={4}>
                              <TextField
                                id="name"
                                name="name"
                                required
                                fullWidth
                                variant="outlined"
                                type="text"
                                value={promo_code.value}
                                onChange={promo_code.onChange}
                                placeholder="Code"
                                aria-readonly
                              />
                            </Grid>
                            <Grid item xs={4}>
                              {checkPromo && (
                                <div style={{ marginTop: 8, marginLeft: 6 }}>
                                  <DoneIcon style={{ color: green[500] }} />
                                </div>
                              )}
                              {!checkPromo && (
                                <button
                                  onClick={handlePromo}
                                  style={{ marginLeft: 20 }}
                                  type="button"
                                  className="btn-primary medium btn-step">
                                  Redeem
                                </button>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4}>
            {booking && (
              <Paper className="summery-grid">
                <h3 className="summery-title">Resumen de reserva</h3>
                <h4 className="summary-section-title">{booking.service.name}</h4>
                <Divider />
                <div className="summary-text">
                  {/* In their home <br/> */}
                  {moment(booking.drop_date).format('DD MMM hh:mm A')} ~{' '}
                  {moment(booking.pickup_date).format('DD MMM hh:mm A')}
                  <br />
                  {booking.booking_has_pets.length} mascotas <br />
                </div>
                <h4 className="summary-section-title">Cargos &amp; Servicios</h4>
                <Divider />
                <div className="invoice-text">
                  {booking.booking_has_pets.map((p, i) => (
                    <div key={`invoice-wrapper-${i}`}>
                      <div className="invoice-row">
                        <span>{p.pet.name}</span>
                        <span>
                          {currencyCode}
                          {(serivce_total_price * serviceDays).toFixed(2)}
                        </span>
                      </div>
                      <div className="row-description">
                        <span>Tarifa estándar</span>
                        <br />
                        <span>
                          {serviceDays} días @ {currencyCode}
                          {serivce_total_price} por día
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <h4 className="summary-section-title">Complementos &amp; Ajustes</h4>
                <Divider />
                <div className="invoice-text">
                  {booking.price_discount > 0 && (
                    <div className="invoice-row">
                      <span>Descuento</span>
                      <span>
                        - {currencyCode}
                        {booking.price_discount.toFixed(2)}
                      </span>
                    </div>
                  )}
                  {/* <div className="invoice-row">
                    <span>Tarifa de servicio</span>
                    <span>{currencyCode}{(booking.price_tax).toFixed(2)}</span>
                  </div> */}
                  {booking.price_promo > 0 && (
                    <div className="invoice-row">
                      <span>Promoción</span>
                      <span>
                        - {currencyCode}
                        {booking.price_promo.toFixed(2)}
                      </span>
                    </div>
                  )}
                  {/* <div className="row-description">
                    <span>1 @ ${booking.price_tax.toFixed(2)}</span><br/>
                  </div> */}
                </div>
                <Divider style={{ marginTop: 10 }} />
                <div className="subtotal-text">
                  <span>
                    Total: {currencyCode}
                    {booking.price_total.toFixed(2)}
                  </span>
                </div>
                {/* <button style={{marginTop: 20}} type="submit" className="btn-primary medium btn-step">
                  Add Complmentary
                </button> */}
              </Paper>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
export default BookingCheckout;
