import { ChangeEvent, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Context } from '@root/Context';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';

import { ADMIN_DASHBOAR, HOME } from '@root/constants/routes';
import { authServices } from '@root/services';
import { useSnackbar, useInputValue } from '@root/hooks';
import { Copyright } from '@root/components';
import dhLogo from '@root/assets/dh_logo.png';
import styles from './login.module.scss';

interface FetchLoginType {
  email: string;
  password: string;
}

const Login = (props) => {
  const { isAuth, admin, client, houser, activateAuth, setRole } = useContext(Context);
  const snackbar = useSnackbar();
  const email = useInputValue('');
  const password = useInputValue('');

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchLogin({ email: email.value, password: password.value });
  };

  const fetchLogin = async (body: FetchLoginType) => {
    await authServices.loginUser(body).then((data: any) => {
      // console.debug('[LOGIN DATA]', data);
      const { user, houser, message } = data;
      if (user?.rol === 64) {
        activateAuth(
          user?.id,
          user?.id,
          houser ? houser?.id : '',
          user?.confirmation,
          user?.terms_conditions,
        );
        // setLocation(data.location.country, data.location.city)
        setRole(user?.rol);
        snackbar.success(message);
      } else {
        snackbar.error('No tienes suficientes permisos para acceder a esta página');
      }
    });
  };

  let { from } = props.location.state || {
    from: { pathname: ADMIN_DASHBOAR },
  };

  if (isAuth && admin) return <Redirect to={from} />;

  if ((isAuth && client) || (isAuth && houser)) return <Redirect to={HOME} />;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Container component="main" maxWidth="xs">
          <Card>
            <CardContent>
              <div className={styles.content}>
                <img src={dhLogo} alt="Logo DogHouser" className={styles.logo} />
                <Typography component="h1" variant="h4" className={styles.title}>
                  Inicio de sesion
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Usuario"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email.value}
                    onChange={email.onChange}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    value={password.value}
                    onChange={password.onChange}
                    autoComplete="current-password"
                  />
                  <Box mt={2}>
                    <Button
                      fullWidth
                      disableElevation
                      color="primary"
                      variant="contained"
                      size="large"
                      type="submit">
                      Ingresar
                    </Button>
                  </Box>
                </form>
              </div>
            </CardContent>
          </Card>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default Login;
