const getFileName = function(userId, fileSize, filePrefix) {
  let fecha = new Date();
  let minutes = fecha.getMinutes();
  let seconds = fecha.getMinutes();
  let year = fecha.getFullYear();
  let month = fecha.getMonth() + 1;
  let day = fecha.getDate();

  const fileName = userId + '_' + filePrefix + '_' + fileSize + '_' + year + month + day + minutes + seconds + '.jpeg';
  return fileName;
}

export default getFileName;
