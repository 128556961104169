import React from 'react'
import PropTypes from 'prop-types'
import './SectionTitle.scss'

const  SectionTitle = props => {
  const { title, text} = props
  return (
    <div className="dh-section-title">
      <h3>{title}</h3>
      <p className="small">{text}</p>
    </div>
  )
}

SectionTitle.defaultProps = {
  title: 'Error en titulo',
  text: ''
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string
}

export default SectionTitle
