import { useState, useEffect, useContext } from 'react';
import { Button } from '@material-ui/core';

import { Context } from '@root/Context';
import { userService } from '@root/services';
import { useSearchUsers, useFilterClients } from '@root/hooks';
import { SearchInput } from '@root/components';
import { AdminTitle } from '@root/ui-component';
import DialogSendMessage from './components/DialogSendMessage';
import { FilterSelection, Workbook } from './components';
import TableClients from './TableClients';
import styles from './clientList.module.scss';

const ClientList = (props) => {
  const { cookies } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const { values, handleValuesChange, filteredClients } = useFilterClients(users);
  const { query, setQuery, filteredUsers } = useSearchUsers(filteredClients);

  const handleButtonSendMessage = () => {
    setOpenDialog(true);
  };

  const handleCloseDialogSendMessage = () => {
    setOpenDialog(false);
  };

  const fetchUsers = async () => {
    setLoading(true);
    setError(null);
    await userService
      .getAll()
      .then((data: any) => setUsers(data?.users))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="dh-admin-clients">
      <AdminTitle title="Clientes" actions={<Workbook data={filteredUsers} />} />
      <div className={styles.options}>
        <FilterSelection values={values} handleValuesChange={handleValuesChange} />
        <div className={styles.right}>
          <SearchInput
            text="Busca un Cliente"
            query={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button
            className={styles.action}
            size="large"
            color="primary"
            variant="contained"
            onClick={handleButtonSendMessage}>
            Envoyer le message
          </Button>
        </div>
      </div>
      {error ? (
        <div className="margin-bottom-x1">
          <h4 className="admin-title">Error: {error}</h4>
        </div>
      ) : (
        <div>
          <p className={styles.count}>{filteredUsers.length} Usuarios encontrados</p>
          <TableClients clients={filteredUsers} loading={loading} />
        </div>
      )}
      <DialogSendMessage
        cookies={cookies}
        users={filteredUsers}
        open={openDialog}
        handleClose={handleCloseDialogSendMessage}
      />
    </div>
  );
};

export default ClientList;
