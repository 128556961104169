/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from '@root/Context';
import { useInputValue } from 'hooks/useInputValue';

import Dog from 'assets/icons/Dog';
import DogIcon from 'assets/icons/DogIcon';
import CatIcon from 'assets/icons/CatIcon';
import RabbitIcon from 'assets/icons/RabbitIcon';
import ExoIcon from 'assets/icons/ExoIcon';

import { Container, Grid } from '@material-ui/core';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import HomeIcon from '@material-ui/icons/Home';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
import * as VARIABLES from '@root/constants/variables';
import { SERVICE_TYPE } from '@root/constants/services';

import moment from 'moment';
import TimePicker from 'components/TimePicker';

import useNotificationValue from 'hooks/useNotificationValue';
import Notification from 'components/notification';

import './bookingRequest.scss';

const dateOptions = { timeZone: 'UTC' };

function BookingRequest(props) {
  const rid = props.match.params.rid;
  const history = useHistory();
  const { cookies } = useContext(Context);

  const [services, setServices] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceType, setServiceType] = useState(0);
  const [optionType, setOptionType] = useState(-1);
  const [dropDate, setDropDate] = useState(new Date());
  const [pickupDate, setPickupDate] = useState(new Date());

  const [showAddPet, setShowAddPet] = useState(false);

  const name = useInputValue('');
  const breed = useInputValue('');
  const birthdate = useInputValue('');
  const description = useInputValue('');
  const [size, setSize] = React.useState({
    dog_small: false,
    dog_medium: false,
    dog_big: false,
  });
  const [typePet, setTypePet] = React.useState({
    has_dogs: false,
    has_cats: false,
    has_rabbits: false,
    has_exo: false,
  });
  const [breeds, setBreeds] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [pets, setPets] = useState([]);

  const [houser, setHouser] = useState(undefined);
  const [booking, setBooking] = useState(undefined);

  const serviceDays = useMemo(() => {
    if (serviceType === 0) {
      return Math.floor((pickupDate - dropDate) / (1000 * 64 * 60 * 24)) + 1;
    } else {
      return 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDate, pickupDate]);

  const petCount = useMemo(() => {
    return pets.filter((v) => v.selected).length;
  }, [pets]);

  const discounts = useMemo(() => {
    let result = [];
    if (serviceDays >= 20) {
      result.push({ reason: 'Hospedaje +20', percent: 15 });
    } else if (serviceDays >= 7) {
      result.push({ reason: 'Hospedaje +7', percent: 10 });
    }
    if (petCount >= 2) {
      result.push({ reason: '2 perros', percent: 10 });
    }
    return result;
  }, [petCount, serviceDays]);

  const currentService = useMemo(() => {
    if (optionType > 0 && services.length) {
      let serviceData = services.find((s) => s.id == optionType);
      serviceData.total_price =
        Number(serviceData.gross_comission) + Number(serviceData.houser_comission);
      return serviceData;
    } else {
      return undefined;
    }
  }, [optionType, services]);

  const currentCurrency = useMemo(() => {
    if (currentService) return VARIABLES.CURRENCY_BY_COUNTRY[currentService.country];
    else return '-';
  }, [currentService]);

  const totalPrice = useMemo(() => {
    if (!currentService || serviceDays <= 0 || petCount <= 0) return undefined;
    return currentService.total_price * serviceDays * petCount;
  }, [currentService, serviceDays, petCount]);

  const totalPriceFee = useMemo(() => {
    if (!currentService || serviceDays <= 0 || petCount <= 0) return undefined;

    return currentService.gross_comission * petCount * serviceDays;
  }, [currentService, serviceDays, petCount]);

  const totalDiscounts = useMemo(() => {
    if (!currentService || serviceDays <= 0 || petCount <= 0) return 0;
    let percent = 0;
    if (discounts.length > 0) {
      percent = discounts.reduce((a, b) => a + b.percent, 0);
    }
    return (percent / 100) * currentService.houser_comission * serviceDays * petCount;
  }, [discounts, currentService, serviceDays, petCount]);

  const isChanged = useMemo(() => {
    if (booking && pets) {
      const orgDropDate = new Date(
        new Date(booking.drop_date).toLocaleString('en', dateOptions),
      );
      const orgPickupDate = new Date(
        new Date(booking.pickup_date).toLocaleString('en', dateOptions),
      );
      const dateDiff =
        // eslint-disable-next-line eqeqeq
        orgDropDate.getTime() != dropDate.getTime() ||
        // eslint-disable-next-line eqeqeq
        orgPickupDate.getTime() != pickupDate.getTime();

      const orgPets = booking.booking_has_pets.map((bh) => bh.pet).map((p) => p.id);
      const currentPets = pets.filter((p) => p.selected).map((p) => p.id);
      const petDiff =
        orgPets.filter((p) => !currentPets.includes(p)).length > 0 ||
        currentPets.filter((p) => !orgPets.includes(p)).length > 0;
      // eslint-disable-next-line eqeqeq
      const serviceDiff = booking.service == optionType;

      return dateDiff || petDiff || serviceDiff;
    }
    return false;
  }, [booking, dropDate, pickupDate, pets, optionType]);

  // eslint-disable-next-line no-unused-vars
  const message = useInputValue('');
  const notification = useNotificationValue();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setOptionType(-1);
  }, [serviceType]);

  useEffect(() => {
    fetchInitial(rid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rid]);

  useEffect(() => {
    if (houser) initServices(houser.houser.houser_services);
  }, [houser]);

  useEffect(() => {
    if (booking && serviceTypes) {
      setServiceType(booking.service.type_id);
      setOptionType(booking.service.id);
    }
  }, [booking, serviceTypes]);

  const initServices = async (services) => {
    if (!services) return;
    const extractServices = services.map((item) => item.service);
    const uniqueServices = extractServices.filter(
      (item, index) =>
        // eslint-disable-next-line eqeqeq
        extractServices.findIndex((v) => item.id == v.id) == index,
    );
    const serviceTypeIds = [...new Set(uniqueServices.map((item) => item.type_id))].sort(
      (a, b) => a - b,
    );

    setServices(uniqueServices);
    setServiceTypes(serviceTypeIds);
  };

  const fetchInitial = async (rid) => {
    setLoading(true);
    if (rid) {
      try {
        const response = await fetch(SERVICES.GET_BOOKING_BY_ID + rid, OPTIONS.GET());
        const data = await response.json();
        // eslint-disable-next-line eqeqeq
        if (data.booking.status != 1) {
          history.replace('/reserva');
        }
        setBooking(data.booking);
        setServiceType(data.booking.service_type);
        setDropDate(
          new Date(new Date(data.booking.drop_date).toLocaleString('en', dateOptions)),
        );
        setPickupDate(
          new Date(new Date(data.booking.pickup_date).toLocaleString('en', dateOptions)),
        );

        const responseHouser = await fetch(
          SERVICES.CUIDADOR_BY_ID,
          OPTIONS.POST({ id: data.booking.houser.user.id }),
        );
        const dataHouser = await responseHouser.json();
        if (responseHouser.status === 200) {
          setHouser(dataHouser.user);
        }

        const responsePet = await fetch(
          SERVICES.PETS_BY_USER,
          OPTIONS.POST({ id: data.booking.user_id }),
        );
        const dataPet = await responsePet.json();
        if (responsePet.status === 200) {
          const savedPets = data.booking.booking_has_pets.map((bh) => bh.pet);
          setPets(
            dataPet.pets.map((p) => ({
              ...p,
              // eslint-disable-next-line eqeqeq
              selected: savedPets.findIndex((sp) => sp.id == p.id) >= 0,
            })),
          );
        }

        setLoading(false);
      } catch (err) {
        notification.handleSnackbar(err.toString(), 'error');
        setLoading(false);
      }
    }
  };

  const updateBooking = async () => {
    setLoading(true);
    const body = {
      booking_id: rid,
      houser_id: booking.houser.id,
      user_id: cookies.id,
      drop_date: moment(dropDate).format('YYYY-MM-DD HH:mm:ss'),
      pickup_date: moment(pickupDate).format('YYYY-MM-DD HH:mm:ss'),
      price_total: totalPrice - totalDiscounts,
      price_tax: totalPriceFee,
      price_discount: totalDiscounts,
      pet_id: pets.filter((p) => p.selected).map((p) => p.id),
      service_type: optionType,
    };
    try {
      const response = await fetch(SERVICES.UPDATE_BOOKING, OPTIONS.POST(body));
      if (response.status === 200) {
        window.location.href = `/reserva/${rid}`;
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      notification.handleSnackbar(error.toString(), 'error');
    }
  };

  const handleRequest = () => {
    if (pets.filter((p) => p.selected).length <= 0) {
      notification.handleSnackbar('Seleccione al menos una mascota.', 'error');
      return;
    }
    if (dropDate > pickupDate) {
      notification.handleSnackbar('Seleccione el intervalo de fechas correcto.', 'error');
      return;
    }
    if (rid) updateBooking();
  };

  const handleDropChange = (date) => {
    setDropDate(date);
  };
  const handleDropTime = (v) => {
    const newDate = moment(
      moment(dropDate).format('MM/DD/YYYY') + ' ' + v,
      'MM/DD/YYYY hh:mm A',
    ).toDate();
    setDropDate(newDate);
  };
  const handlePickupChange = (date) => {
    setPickupDate(date);
  };
  const handlePickupTime = (v) => {
    const newDate = moment(
      moment(pickupDate).format('MM/DD/YYYY') + ' ' + v,
      'MM/DD/YYYY hh:mm A',
    ).toDate();
    setPickupDate(newDate);
  };
  const handleCheckboxChange = (name) => (event) => {
    setSize({
      dog_small: false,
      dog_medium: false,
      dog_big: false,
      [name]: event.target.checked,
    });
  };

  const handleTypePetChange = (name) => (event) => {
    setTypePet({
      has_dogs: false,
      has_cats: false,
      has_rabbits: false,
      has_exo: false,
      [name]: event.target.checked,
    });
    fetchBreeds(VARIABLES.PET_TYPE_VALUE[name]);
  };

  const handleServiceOptionChanged = (e) => {
    setOptionType(Number(e.target.value));
  };

  const fetchBreeds = async (petTypeId) => {
    try {
      const response = await fetch(SERVICES.GET_BREEDS + petTypeId, OPTIONS.GET());
      const data = await response.json();
      if (data.status === 200) {
        setBreeds(data.breeds);
      }
    } catch (error) {}
  };

  const handleNewPet = () => {
    var mydate = new Date(birthdate.value);
    var year = mydate.getFullYear();
    var month = mydate.getMonth();
    var day = mydate.getDate();
    var customDate = year + '-' + (month + 1) + '-' + (day + 1);

    let pet_size = 1;
    if (size.dog_big) {
      pet_size = 2;
    } else if (size.dog_medium) {
      pet_size = 1;
    } else {
      pet_size = 0;
    }
    let type_pet = 1;
    if (typePet.has_dogs) {
      type_pet = 1;
    } else if (typePet.has_cats) {
      type_pet = 2;
    } else if (typePet.has_rabbits) {
      type_pet = 3;
    } else if (typePet.has_exo) {
      type_pet = 4;
    }
    fetchCreatePet(customDate, pet_size, type_pet);
  };

  const fetchCreatePet = async (customDate, pet_size, type_pet) => {
    const postData = {
      id: booking.user_id,
      name: name.value,
      breed_id: breed.value,
      pet_type: type_pet,
      birth_date: customDate,
      pet_size: pet_size,
      description: description.value,
    };
    const data = createPetAPI(postData);
    if (data.status === 200) {
      clearNewPetInputs();
      refreshPets([]);
    }
  };

  const refreshPets = async (initPets) => {
    const responsePets = await fetch(
      SERVICES.PETS_BY_USER,
      OPTIONS.POST({
        id: cookies.id,
      }),
    );
    const dataPets = await responsePets.json();
    if (responsePets.status === 200) {
      setPets(
        dataPets.pets.map((p) => ({
          ...p,
          selected:
            initPets.findIndex(
              (ip) =>
                // eslint-disable-next-line eqeqeq
                ip.name == p.name && ip.feeding == p.feeding && ip.selected,
            ) >= 0,
        })),
      );
    }
  };

  const clearNewPetInputs = () => {
    name.update('');
    breed.update('');
    birthdate.update('');
    description.update('');
    setSize({
      dog_small: false,
      dog_medium: false,
      dog_big: false,
    });
    setTypePet({
      has_dogs: false,
      has_cats: false,
      has_rabbits: false,
      has_exo: false,
    });
    setShowAddPet(false);
  };

  const createPetAPI = async (postData) => {
    const response = await fetch(SERVICES.REGISTER_PET, OPTIONS.POST(postData));
    const data = await response.json();
    return data;
  };

  const renderOption = (type) => {
    const serviceOptions = services.filter((s) => s.type_id == type);
    return (
      <div className="option-wrapper">
        <div className="option-grid">
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="service-option-type"
              name="service-option-type"
              value={optionType}
              onChange={handleServiceOptionChanged}>
              {serviceOptions.map((item) => (
                <FormControlLabel
                  value={item.id}
                  control={<Radio />}
                  label={item.name}
                  key={`service-option-type${item.id}`}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <Grid container spacing={3}>
          <Grid item xs>
            <div>Fecha de entrada</div>
            <div className="datetime-container">
              <KeyboardDatePicker
                autoOk
                className="datetime-half-picker"
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                value={dropDate}
                minDate={new Date()}
                onChange={handleDropChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <TimePicker
                defaultValue={moment(dropDate).format('HH:mm A')}
                onChange={handleDropTime}
              />
            </div>
          </Grid>
          <Grid item xs>
            <div>Fecha de salida</div>
            <div className="datetime-container">
              <KeyboardDatePicker
                autoOk
                className="datetime-half-picker"
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                value={pickupDate}
                minDate={dropDate}
                onChange={handlePickupChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <TimePicker
                defaultValue={moment(pickupDate).format('HH:mm A')}
                onChange={handlePickupTime}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const petSizes = ['Pequeño', 'Mediano', 'Grande'];
  const renderPets = () => {
    const handleChangePet = (idx, value) => {
      setPets((prev) =>
        prev.map((item, i) => (idx === i ? { ...item, selected: value } : item)),
      );
    };
    return (
      <div className="pets-wrapper">
        <div>
          <h3>
            Mascota
            <IconButton
              aria-label="Show Add Pet"
              component="span"
              style={{ padding: 0, marginLeft: 10 }}
              onClick={() => setShowAddPet((prev) => !prev)}>
              <AddCircleOutlineIcon />
            </IconButton>
          </h3>
        </div>
        <div>
          <FormControl component="fieldset" className="pets-check-wrapper">
            <FormGroup>
              {pets.map((item, idx) => (
                <div className="booking-pet-item-wrapper" key={`pet-wrapper-${idx}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={item.selected}
                        onChange={(e) => handleChangePet(idx, e.target.checked)}
                        name={item.name}
                      />
                    }
                    label={item.name}
                    className="booking-pet-item-check"
                  />
                  <div className="booking-pet-item-size">
                    <Dog />
                    <span>{petSizes[item.pet_size]}</span>
                  </div>
                </div>
              ))}
            </FormGroup>
          </FormControl>
        </div>
        {showAddPet && (
          <>
            <Divider style={{ marginTop: 10, marginBottom: 20 }} />
            <div>
              <span>Name</span>
              <TextField
                id="name"
                name="name"
                required
                fullWidth
                variant="outlined"
                type="text"
                value={name.value}
                onChange={name.onChange}
              />
              <div className="new-pet-info-form__type-pet">
                <span>Type</span>
                <FormGroup row className="form-has-pets">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<DogIcon />}
                        checkedIcon={<DogIcon setColor="accent" />}
                        checked={typePet.has_dogs}
                        onChange={handleTypePetChange('has_dogs')}
                        value="has_dogs"
                        color="primary"
                      />
                    }
                    label="Perros"
                    labelPlacement="bottom"
                    className="pet-type"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CatIcon />}
                        checkedIcon={<CatIcon setColor="accent" />}
                        checked={typePet.has_cats}
                        onChange={handleTypePetChange('has_cats')}
                        value="has_cats"
                        color="primary"
                      />
                    }
                    label="Gatos"
                    labelPlacement="bottom"
                    className="pet-type"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RabbitIcon />}
                        checkedIcon={<RabbitIcon setColor="accent" />}
                        checked={typePet.has_rabbits}
                        onChange={handleTypePetChange('has_rabbits')}
                        value="has_rabbits"
                        color="primary"
                      />
                    }
                    label="Conejos"
                    labelPlacement="bottom"
                    className="pet-type"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<ExoIcon />}
                        checkedIcon={<ExoIcon setColor="accent" />}
                        checked={typePet.has_exo}
                        onChange={handleTypePetChange('has_exo')}
                        value="has_exo"
                        color="primary"
                      />
                    }
                    label="Exóticos"
                    labelPlacement="bottom"
                    className="pet-type"
                  />
                </FormGroup>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <span>Raza</span>
                  <TextField
                    select
                    required
                    fullWidth
                    variant="outlined"
                    id="breed"
                    value={breed.value}
                    onChange={breed.onChange}>
                    {breeds.map((breed) => (
                      <MenuItem key={breed.id} value={breed.id}>
                        {breed.breed}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <span className="cortar">Fecha de nacimiento</span>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    id="birth_date"
                    name="birth_date"
                    type="date"
                    value={birthdate.value}
                    onChange={birthdate.onChange}
                  />
                </Grid>
              </Grid>
              <span>Tamaño</span>
              <div className="new-pet-info-form__size-list">
                <FormGroup row className="form-size-pet">
                  <FormControlLabel
                    className="dog-small"
                    control={
                      <Checkbox
                        checked={size.dog_small}
                        onChange={handleCheckboxChange('dog_small')}
                        icon={<Dog />}
                        checkedIcon={<Dog setColor="accent" />}
                        name="dog_small"
                        color="primary"
                      />
                    }
                    label="Pequeño"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    className="dog-medium"
                    control={
                      <Checkbox
                        checked={size.dog_medium}
                        onChange={handleCheckboxChange('dog_medium')}
                        icon={<Dog />}
                        checkedIcon={<Dog setColor="accent" />}
                        name="dog_medium"
                        color="primary"
                      />
                    }
                    label="Mediano"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    className="dog-big"
                    control={
                      <Checkbox
                        checked={size.dog_big}
                        onChange={handleCheckboxChange('dog_big')}
                        icon={<Dog />}
                        checkedIcon={<Dog setColor="accent" />}
                        name="dog_big"
                        color="primary"
                      />
                    }
                    label="Grande"
                    labelPlacement="bottom"
                  />
                </FormGroup>
              </div>
              <span>Descripción</span>
              <TextField
                id="description"
                name="description"
                required
                fullWidth
                variant="outlined"
                type="text"
                multiline
                rows="3"
                value={description.value}
                onChange={description.onChange}
              />
              <button
                style={{ marginTop: 20 }}
                onClick={() => handleNewPet()}
                className="btn-primary medium btn-step">
                Guardar y Continuar
              </button>
            </div>
          </>
        )}
        <Divider style={{ marginTop: 10, marginBottom: 20 }} />
      </div>
    );
  };

  const renderSummary = () => {
    return (
      <div className="summary-wrapper">
        <h3 className="summary-h">Resumen de reserva</h3>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="summary-content"
            id="summary-header">
            <div className="summary-header-wrapper">
              <div className="summary-title-row">
                <label>{currentService?.name}</label>
                <label>
                  {currentCurrency}
                  {totalPrice ? (totalPrice - totalDiscounts).toFixed(2) : '-'}
                </label>
              </div>
              <div className="summary-title-desc">
                <span>
                  {serviceDays} noches, {petCount} mascotas
                </span>
                <span>Total</span>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="summary-detail-wrapper">
              <Divider />
              {currentService &&
                pets
                  .filter((v) => v.selected)
                  .map((v, i) => (
                    <div key={`detail-summary-${i}`}>
                      <div className="detail-row">
                        <span>{v.name}</span>
                        <span>
                          {currentCurrency}
                          {(currentService.total_price * serviceDays).toFixed(2)}
                        </span>
                      </div>
                      <div className="detail-desc">
                        <span>{petSizes[v.pet_size]}</span>
                        <br />
                        <span>Tarifa estándar</span> <br />
                        <span>
                          {currentCurrency}
                          {Number(currentService.total_price).toFixed(2)} por noche x{' '}
                          {serviceDays}
                        </span>
                      </div>
                    </div>
                  ))}
              <Divider />
              <div className="detail-row">
                <span>Descuento</span>
                <span>
                  - {currentCurrency}
                  {totalDiscounts.toFixed(2)}
                </span>
              </div>
              {discounts.map((d, i) => (
                <div className="detail-desc" key={`discount-row-${i}`}>
                  <span>
                    {d.percent}% ({d.reason})
                  </span>
                </div>
              ))}
              {/* <Divider/>
              <div className="detail-row">
                <span>Tarifa de servicio</span>
                <span>
                  {currentCurrency}{totalPriceFee ? totalPriceFee.toFixed(2) : '-'}
                </span>
              </div> */}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const renderServices = (type) => {
    const typeObj = SERVICE_TYPE.find((st) => st.value == type);
    return (
      <Grid item xs key={`service-type-${type}`} className="modify-service-type">
        <div
          className="service-type"
          style={{
            borderColor: serviceType === type ? 'darkslategrey' : 'lightgrey',
          }}
          onClick={() => setServiceType(type)}>
          {type === 1 && <Brightness2Icon />}
          {type === 2 && <Brightness2Icon />}
          {type === 3 && <HomeIcon />}
          <label>{typeObj.label}</label>
        </div>
      </Grid>
    );
  };

  return (
    <div className="houser-request">
      <Container maxWidth="md" className="houser-request__container">
        <h1 className="title">Modificar Reserva</h1>
        <Grid container spacing={3}>
          {serviceTypes.map((st) => renderServices(st))}
        </Grid>
        {renderOption(serviceType)}
        {renderPets()}
        {renderSummary()}
        <div>
          {isChanged ? (
            <button
              style={{ marginTop: 20 }}
              type="submit"
              className="btn-primary medium btn-step"
              onClick={() => handleRequest()}>
              Modificar
            </button>
          ) : (
            <button
              style={{ marginTop: 20 }}
              type="submit"
              className="btn-primary disabled medium btn-step">
              Modificar
            </button>
          )}
          <button
            style={{ marginLeft: 20 }}
            type="submit"
            className="btn-default medium btn-step"
            onClick={() => history.goBack()}>
            Cancelar
          </button>
        </div>
      </Container>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default BookingRequest;
