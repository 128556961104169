import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './cardImageSetting.scss'
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables'
// Utils
import convertBase64ToFile from 'utils/convertBase64ToFile'
import getFileName from 'utils/getFileName'
// Dependencies
import Resizer from 'react-image-file-resizer';
// Components
import Notification from 'components/notification';
// Material-UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

function CardImageSetting({ id }) {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [messageVariant, setMessageVariant] = useState('')
  const [messageState, setMessageState] = useState(false)
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState({
    user: {
      id: '',
      photo: '',
    }
  })
  const [image, setImage] = useState({
    file: '',
    imagePreviewUrl: '',
  })

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = e => {
    setMessage('')
    setMessageState(false)
  }

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      setImage({
        file: file,
        imagePreviewUrl: reader.result
      })
    }
    if(file && file.type.match('image.*')) {
      reader.readAsDataURL(file)
      handleOpen()
    }
  }

  useEffect(() => {
    if(!user.user.id) {
      fetchUserData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user.id])

  const fetchUserData = async () => {
    setMessage('')
    setMessageVariant('')
    setMessageState(false)
    let body = {
      id: id
    }
    sendData(SERVICES.USER_BY_ID, OPTIONS.POST(body), VARIABLES.STATE_INITIATE)
  }

  const handleSubmitImage = e => {
    e.preventDefault();
    if(image.file) {
      // console.log('handle uploading-', image.file);
      fetchImageUser()
    }
  }

  // Establecer los datos del Usuario a enviar
  const fetchImageUser = async () => {
    setLoading(true)
    setMessage('')
    setMessageState(false)
    Resizer.imageFileResizer(
      image.file,
      480,
      480,
      'JPEG',
      80,
      0,
      uri => {
        let imageFile = convertBase64ToFile(uri);
        let imageName = getFileName(id, imageFile.size, 'avatars')
        const formData = new FormData()
        formData.append('id', id)
        formData.append('photo', imageFile, imageName)
        let body = formData
        sendData(SERVICES.UPLOAD_PHOTO_USER, OPTIONS.POST_FORM_DATA(body), VARIABLES.STATE_UPDATE)
      },
      'base64'
    )
  }

  // Funcion para enviar los datos al servidor
  const sendData = async(url, options, state) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (state === VARIABLES.STATE_INITIATE) {
          setUser(data)
        } else if (state === VARIABLES.STATE_UPDATE) {
          setLoading(false)
          setMessage('Has actualizado la imagen de perfil, actualiza la pagina')
          setMessageVariant('success')
          setMessageState(true)
          handleClose()
        }
      } else {
        if (state === VARIABLES.STATE_INITIATE) {
          setMessage('Error al obtener la foto de perfil')
          setMessageVariant('error')
          setMessageState(true)
        } else if (state === VARIABLES.STATE_UPDATE) {
          setLoading(false)
          setMessage('Lo sentimos, no fue posible cargar su imagen.')
          setMessageVariant('error')
          setMessageState(true)
          handleClose()
        }
      }
    } catch (error) {
      setLoading(false)
      setMessage('Error: ' + error.message)
      setMessageVariant('error')
      setMessageState(true)
    }
  }

  return (
    <React.Fragment>
      <div className="card-image-setting dh-card-border">
        <div className="dh-flex-responsive items-center">
          <div className="dh-avatar">
            <img
              src={`${VARIABLES.IMAGE_URL}${user.user.photo}`} 
              alt={user.user.name}
            />
          </div>
          <div className="image-actions">
            <input className="hide" type='file' id="upload-photo" onChange={handleImageChange}/>
            <label htmlFor="upload-photo" className="btn-primary">
              Actualizar imagen de perfil
            </label>
            <p className="small">
              El formato debe ser JPEG, PNG o GIF y no puede superar los 10 MB.
            </p>
          </div>
        </div>
      </div>
      <Dialog className="profile-edit" fullWidth={true} maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
        <div className="profile-edit__header">
          <h3 className="marginx0">Actualizar imagen de perfil</h3>
        </div>
        <DialogContent className="profile-edit__body">
          {image.imagePreviewUrl ? (
            <React.Fragment>
              {loading ? (
                <div className="profile-edit__presentation image-progress">
                  <img
                    src={image.imagePreviewUrl} 
                    alt="usuario"
                  />
                  <CircularProgress className="progress-circle"/>
                </div>
              ) : (
                <div className="profile-edit__presentation">
                  <img
                    src={image.imagePreviewUrl} 
                    alt="usuario"
                  />
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment/>
          )}
        </DialogContent>
        <DialogActions className="profile-edit__actions">
          <button className="btn-default" onClick={handleClose} color="primary">
            Cancelar
          </button>
          <button className="btn-primary" onClick={handleSubmitImage} color="primary">
            Guardar
          </button>
        </DialogActions>
      </Dialog>
      {messageState &&
        <Notification
          message={message} 
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState} 
          onClose={handleCloseSnackbar}
        />
      }
    </React.Fragment>
  )
}

CardImageSetting.propTypes = {
  id: PropTypes.any.isRequired
}

export default CardImageSetting
