import React, { useState, useEffect } from 'react';
import './adminReservations.scss';
// Dependencies
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as ROUTES from 'constants/routes';
import * as VARIABLES from 'constants/variables';
// Utils
import replacePathParams from 'utils/replacePathParams';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
import useSearchReservations from 'hooks/useSearchReservations';
import useFilterReservation from 'hooks/useFilterReservation';
// Componets
import Notification from 'components/notification';
import SearchInput from 'components/SearchInput';
import { ReservationsTable, FilterSelection, Workbook } from './components';
// eslint-disable-next-line no-unused-vars
import { SERVICE_TYPES } from 'constants/services';
// Material-UI

function AdminReservations(props) {
  const [loading, setLoading] = useState(false);
  const notification = useNotificationValue();

  const [reservations, setData] = useState([]);
  const [events, setEvents] = useState([]);

  const { values, handleValuesChange, filteredReservations } =
    useFilterReservation(reservations);
  const { query, setQuery, searchReservations } = useSearchReservations(filteredReservations);

  const [btnLayout, setBtnLayout] = useState({
    btn1: 'active',
    btn2: '',
  });

  const handleLayoutState = (value) => {
    setBtnLayout({
      [value]: 'active',
    });
  };

  const comparar = (a, b) => {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    // a must be equal to b
    return 0;
  };

  const fetchReservations = async () => {
    try {
      const response = await fetch(SERVICES.ALL_BOOKING, OPTIONS.GET());
      const data = await response.json();
      // console.log(data);
      if (response.status === 200 && data.bookings) {
        setData(data.bookings.sort(comparar));
        setEvents(handleEventos(data.bookings));
        setLoading(false);
      } else {
        notification.handleSnackbar('No se encontraron reservas', 'error');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  const handleEventos = (events) => {
    const eventsCalendar = [];
    events.forEach((res) => {
      let rndNumber = Math.floor(Math.random() * 11);
      const item = {
        id: res.id,
        title:
          '#' +
          res.id +
          ' ' +
          res.service.name +
          ', ' +
          res.service.country +
          '-' +
          res.service.city,
        start: res.drop_date,
        end: res.pickup_date,
        allDay: false,
        backgroundColor: VARIABLES.RANDOM_COLOR[rndNumber],
        borderColor: VARIABLES.RANDOM_COLOR[rndNumber],
      };
      eventsCalendar.push(item);
    });
    return eventsCalendar;
  };

  const handleEventClick = (info) => {
    props.history.push(
      replacePathParams(ROUTES.ADMIN_RESERVATION_DETAIL, { id: info.event.id }),
    );
  };

  useEffect(() => {
    fetchReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dh-admin-reservations">
      <div className="dh-admin-reservations__section-header">
        <h2 className="admin-title">Reservas</h2>
        <div className="dh-admin-reservations__section-header--actions">
          <button
            className={`btn-primary border ${btnLayout.btn1}`}
            onClick={() => handleLayoutState('btn1')}>
            Lista
          </button>
          <button
            className={`btn-primary border ${btnLayout.btn2}`}
            onClick={() => handleLayoutState('btn2')}>
            Calendario
          </button>
        </div>
      </div>
      <Workbook data={searchReservations} />
      <div className="dh-admin-services__filter-services">
        <FilterSelection values={values} handleValuesChange={handleValuesChange} />
        <SearchInput
          text="Busca una reserva"
          query={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </div>
      {btnLayout.btn1 && (
        <div>
          <p className="dh-admin-services__table-services--count-services">
            {searchReservations.length} Reservas encontradas
          </p>
          {!loading && <ReservationsTable reservations={searchReservations} />}
        </div>
      )}
      {btnLayout.btn2 && (
        <FullCalendar
          locale="es"
          defaultView="dayGridMonth"
          plugins={[dayGridPlugin, interactionPlugin]}
          await
          events={events}
          eventClick={handleEventClick}
          header={{
            left: 'today',
            center: 'title',
            right: 'prev,next',
          }}
        />
      )}
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default AdminReservations;
