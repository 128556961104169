import { PetTypeType, PetTypeName } from '@root/models/PetTypeDTO';
import { IMAGE_URL } from '@root/constants/variables';
import NoPhoto from '@root/assets/images/ph_no_foto.png';
import getPetAge from '@root/utils/getPetAge';
import styles from './petSimpleCard.module.scss';

interface PetSimpleCardType {
  name: string;
  image?: string;
  birthDate?: Date | string;
  petType?: PetTypeType;
}

const PetSimpleCard = (props: PetSimpleCardType) => {
  const { name, image, birthDate, petType } = props;

  const petImage = image ? IMAGE_URL + image : NoPhoto;
  const petTypeName = petType ? PetTypeName[petType] : '---';
  const petAge = getPetAge(birthDate);

  return (
    <div className={styles.root} style={{ backgroundImage: `url(${petImage})` }}>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <h3 className={styles.name}>{name}</h3>
          <p className={styles.text}>{petTypeName}</p>
          <p className={styles.text}>{birthDate && petAge}</p>
        </div>
      </div>
    </div>
  );
};

export default PetSimpleCard;
