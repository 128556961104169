import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function Dog(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 512 512"
      style={{enableBackground: 'new 0 0 512 512'}}
      xmlSpace="preserve">
      <g>
        <path className="st0" d="M443.4,310.6c-1-4-28.9,3-57.8,3c-21.3,0-41.6-12.2-50.7-18.6c-6.9-13.7-19.2-24.8-41-26.2
          c-43.4-2.8-79.1-4.1-96.2-4.7l-0.8-0.1c17.3-10.3,8.8-63,8.8-63c-19.3-19.8-43.1-1.4-44.7-0.1c-5.6-1.4-20.3-4.3-35.2-1.7
          c-0.9-1.3-5.2-6.3-17.7-3.7c-14,2.9-17.7,11.9-15.6,28.2c-3.6,7.8-5.8,17.7-5.8,30.5c0,0-13-1.8-16.9,6.6c-0.4,0.4-0.7,0.7-0.8,1.1
          c-0.3,0.7-0.3,1.5-0.1,2.4c-0.1,0.6-0.1,1.2-0.1,1.9c0,14.1,10.5,48.2,74.2,26c0,0.1,0,0.1,0,0.2l-0.3,0.2l1.1,3.9c0,0,0.1,0,0.1,0
          c0.3,1.1,0.6,2.3,1,3.5c2.8,9.8,8.4,24.8,18.6,36.6l0.8,62.7c-4.5-0.1-15.3-0.1-18.4,1l-4,1.5c0,0-6.5,32.4,1,33.9
          c0.3,0.1,0.8,0.1,1.2,0.2c0,0.2,0,0.3,0,0.3h1.1c0,0,0-0.1,0-0.2c5.2,0.5,15,0.7,23.9,0.7c0,5.9,0.8,10.8,3.6,11.4
          c1.7,0.3,5.1,0.6,9.3,0.8c0,0.1,0,0.2,0,0.2h1.1c0,0,0-0.1,0-0.2c2.1,0.1,4.3,0.1,6.6,0.2c0,0,0,0,0,0h1.1c0,0,0,0,0,0
          c12.8,0.2,27.2,0,27.2,0s9.5-4.5,9.5-13.5v-79c9.6,0.3,19.9,0.3,30.2,0.2c2.5,6.4,5.7,11.9,9.7,13.8c11.5,5.5,24.9,13.4,24.9,13.4
          l-0.9,14.7c-13.1-4.5-17.5-0.1-17.5-0.1s-8,1.5-10.5,11c-2.5,9.5,1,26.4,1,26.4h4.2h1.1h4.9h1.1h19.9c0.5,6.7,1.7,12.5,1.7,12.5
          H308c0,0.3,0,0.5,0,0.5h1.1c0,0,0-0.2,0-0.5h6.6c0,0.3,0,0.5,0,0.5h1.1c0,0,0-0.2,0-0.5h22.4c0,0,0,0,0-0.1
          c3.1-0.4,8.3-2.4,11-11.5c4-13.5,13-57.3,8-62.3c-5-5-15.9-14.5-15.9-14.5s1.5-31.9,0.5-36.9c0-0.2-0.2-0.3-0.3-0.4
          c0-0.1,0-0.3,0-0.4C405.1,347.3,444.3,314.4,443.4,310.6 M323.7,395.8c1,0.6,1.6,0.9,1.6,0.9l-0.9,14.7c-1.3-0.4-2.4-0.8-3.5-1.1
          C321.9,405.8,322.8,400.8,323.7,395.8" fill={color}/>
      </g>
    </svg>
  )
}

export default Dog
