import { MouseEvent, ChangeEvent, Fragment, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import { Person as PersonIcon, Visibility as VisibilityIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import { ADMIN_CLIENTES_ID } from '@root/constants/routes';
import { IMAGE_URL } from '@root/constants/variables';
import { TablePaginationActions } from '@root/hooks';
import replacePathParams from '@root/utils/replacePathParams';
import BadgeStatusSimple from '@root/components/badgeStatusSimple';
import styles from '../clientList.module.scss';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1000,
  },
}));

const TableRowHead = (
  <TableRow>
    <TableCell>Nombre</TableCell>
    <TableCell>E-mail</TableCell>
    <TableCell>Teléfono</TableCell>
    <TableCell>Doc. ID</TableCell>
    <TableCell>Ubicación</TableCell>
    <TableCell align="center">Acciones</TableCell>
  </TableRow>
);

const SkeletonRow = (
  <TableRow>
    <TableCell scope="row">
      <Skeleton />
    </TableCell>
    <TableCell>
      <div className="user-name-content">
        <Skeleton variant="circle" width={40} height={40} className="user-name-content__img" />
        <Skeleton width="60%" className="user-name-content_name" />
      </div>
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
  </TableRow>
);

const TableClients = (props) => {
  const classes = useStyles();
  const { clients = [], loading } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="dh-simple-elevation" style={{ overflowX: 'auto' }}>
      <Table className={classes.table}>
        <TableHead className={styles.head}>{TableRowHead}</TableHead>
        {!loading ? (
          <TableBody className="table-body">
            {clients
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((client) => (
                <TableRow key={client.id}>
                  <TableCell>
                    <RouterLink to={replacePathParams(ADMIN_CLIENTES_ID, { id: client.id })}>
                      <div className={styles.user}>
                        {client.photo ? (
                          <Avatar
                            className={styles.avatar}
                            src={`${IMAGE_URL}${client.photo}`}
                            alt={client.name}
                            onError={(ev: any) =>
                              (ev.target.src = 'https://cdn2.thecatapi.com/images/888.jpg')
                            }
                          />
                        ) : (
                          <Avatar className={styles.avatar} alt={client.name}>
                            <PersonIcon />
                          </Avatar>
                        )}
                        <div>
                          <p className={styles.name}>{`${client.name} ${client.last_name}`}</p>
                          <span className={styles.id}>ID: {client.id}</span>
                        </div>
                      </div>
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    {client.email}
                    <BadgeStatusSimple status={client.email_confirmed} />
                  </TableCell>
                  <TableCell>
                    {client.phone}
                    <BadgeStatusSimple status={client.confirmation} />
                  </TableCell>
                  <TableCell>{client.doc_id}</TableCell>
                  <TableCell>{client.address}</TableCell>
                  <TableCell align="center" className="table-body__client-action">
                    <RouterLink to={replacePathParams(ADMIN_CLIENTES_ID, { id: client.id })}>
                      <IconButton aria-label="ver datos del producto">
                        <VisibilityIcon />
                      </IconButton>
                    </RouterLink>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        ) : (
          <TableBody className="table-body">
            {Array.from(new Array(8)).map((data, index) => {
              return <Fragment key={index}>{SkeletonRow}</Fragment>;
            })}
          </TableBody>
        )}
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
          count={clients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'filas por página' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Table>
    </div>
  );
};

export default TableClients;
