import React, { useState, useEffect } from 'react';
import './PetFriendlyDetail.scss';
// Services
import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
// Constants
import * as VARIABLES from '@root/constants/variables';
// Hooks
import useNotificationValue from '@root/hooks/useNotificationValue';
// Dependencies
import GoogleMapReact from 'google-map-react';
// Components
import Notification from '@root/components/notification';
import Marker from '@root/components/Marker';
// Material-UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
// Icon
import NoFoto from 'assets/icons/ic-no-foto@simple.svg';
import IcFacebook from 'assets/icons/facebook.svg';
import IcInstagram from 'assets/icons/instagram.svg';

function PetFriendlyDetail(props) {
  // Obteniendo id por parámetros de url
  const urlId = props.match.params.id;
  const urlCountry = props.match.params.country;
  const urlCity = props.match.params.city;
  const [loading, setLoading] = useState(true);

  // Variables de "Notification"
  const notification = useNotificationValue();

  // Constante de datos del lugar
  const [place, setPlace] = useState({
    id: null,
    title: '',
    adress: '',
    link_page: '',
    phone: '',
    pet_place_type_id: null,
    location_id: null,
    image_card: '',
    image_detail: '',
    description: '',
    price_range: '',
    schedule: '',
    food_type: '',
    location: {
      id: null,
      latitude: null,
      longitude: null,
      country: '',
      city: '',
      department: '',
      zipcode: '',
      province: '',
      district: '',
      abreviated: null,
      user_id: null,
    },
  });
  const zoom = 14;
  const [center, setCenter] = useState({
    lat: -12.083348,
    lng: -77.057595,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPlace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlId]);

  const fetchPlace = async () => {
    try {
      const response = await fetch(
        `${SERVICES.GET_PLACE}/${urlId}/${urlCountry}/${urlCity}`,
        OPTIONS.GET(),
      );
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setPlace(data.place);
        if (data.place.location.latitude && data.place.location.longitude) {
          var intLat = parseFloat(data.place.location.latitude);
          var intLng = parseFloat(data.place.location.longitude);
          setCenter({
            lat: intLat,
            lng: intLng,
          });
        }
        setLoading(false);
      } else {
        notification.handleSnackbar('No se encontró el lugar.', 'error');
        setLoading(false);
      }
    } catch (e) {
      notification.handleSnackbar(`Error: ${e.message}`, 'error');
      setLoading(false);
    }
  };

  return (
    <div className="dh-petFriendly-detail">
      <div className="dh-petFriendly-detail__landing-header">
        {!loading ? (
          <React.Fragment>
            {place.image_detail ? (
              <div className="dh-petFriendly-detail__landing-header__cover">
                <div
                  className="dh-petFriendly-detail__landing-header__cover--blur"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${
                      VARIABLES.IMAGE_URL + place.image_detail
                    }')`,
                  }}
                />
                <img src={`${VARIABLES.IMAGE_URL}${place.image_detail}`} alt={place.title} />
              </div>
            ) : (
              <div className="dh-petFriendly-detail__landing-header__not-fount">
                <img src={NoFoto} alt="No se encontro una foto" />
                <p>No se encontró ninguna imagen</p>
              </div>
            )}
          </React.Fragment>
        ) : (
          <Skeleton variant="rect" width="100%" height="100%" />
        )}
      </div>
      <div className="dh-petFriendly-detail__content">
        <Container maxWidth="lg" className="margin-bottom-x3">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={8}>
              <div className="dh-petFriendly-detail__content__info dh-card-border">
                {!loading ? (
                  <Grid item xs={12} md={8}>
                    <div className=" info-header ">
                      <h2>{place.title}</h2>
                      <p>{place.location.district}</p>
                    </div>
                  </Grid>
                ) : (
                  <div className="info-header">
                    <Skeleton
                      variant="rect"
                      width="80%"
                      height={26}
                      style={{ marginBottom: 8 }}></Skeleton>
                    <Skeleton variant="rect" width="50%" height={16}></Skeleton>
                  </div>
                )}
                {!loading ? (
                  <Grid item xs={12} md={8}>
                    <div className="info-description">
                      <h3>Descrición del lugar:</h3>
                      <p>{place.description}</p>
                    </div>
                  </Grid>
                ) : (
                  <div className="info-description">
                    <Skeleton
                      variant="rect"
                      width={180}
                      height={26}
                      style={{ marginBottom: 8 }}></Skeleton>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={16}
                      style={{ marginBottom: 8 }}></Skeleton>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={16}
                      style={{ marginBottom: 8 }}></Skeleton>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={16}
                      style={{ marginBottom: 8 }}></Skeleton>
                    <Skeleton variant="rect" width="60%" height={16}></Skeleton>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="dh-petFriendly-detail__content__details dh-card-border">
                <h3>Detalles</h3>
                <ul>
                  <li>
                    <h4>Tipos de comida</h4>
                    <p>{place.food_type === '-' ? 'Sin definir' : place.food_type}</p>
                  </li>
                  <li>
                    <h4>Horario</h4>
                    <p>{place.schedule === '-' ? 'Sin definir' : place.schedule}</p>
                  </li>
                  <li>
                    <h4>Rango de precio</h4>
                    <p>{place.price_range === '-' ? 'Sin definir' : place.price_range}</p>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="dh-petFriendly-detail__contact dh-card-border">
                <h3>Contacto</h3>
                <h4>Teléfono</h4>
                <p>{place.phone === '-' ? 'Sin definir' : place.phone}</p>
                <div className="dh-petFriendly-detail__contact__redes">
                  <h4>Redes</h4>
                  {place.link_page && (
                    <div>
                      <a href={place.link_page} target="_blank" rel="noopener noreferrer">
                        <img src={IcFacebook} alt="facebook" />
                      </a>
                    </div>
                  )}
                  {place.instagram && (
                    <div>
                      <a href={place.facebook} target="_blank" rel="noopener noreferrer">
                        <img src={IcInstagram} alt="instagram"></img>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              {place.location.longitude ? (
                <div className="dh-petFriendly-detail__section-location">
                  <div className="map-container ">
                    <div className="dh-petFriendly-detail__section-location__adress">
                      <h3>Ubicación</h3>
                      <h4>
                        {place.adress} , {place.location.city}
                      </h4>
                    </div>
                    {!loading && (
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: VARIABLES.GOOGLE_MAP_KEY }}
                        defaultZoom={zoom}
                        center={{
                          lat: center.lat,
                          lng: center.lng,
                        }}>
                        <Marker lat={center.lat} lng={center.lng} />
                      </GoogleMapReact>
                    )}
                  </div>
                </div>
              ) : (
                <h4>No hay Ubicación</h4>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default PetFriendlyDetail;
