import React from 'react'
import { Link as RouterLink } from "react-router-dom";
import './cardDelivery.scss'
// Material-UI
import Grid from '@material-ui/core/Grid';

function CardDelivery(props) {
  const { title, text, image, pathName, typeService, size, price } = props
  return (
    <Grid item xs={12} sm={6} md={size}>
      <RouterLink className="dh-no-decoration"
        to={{
          pathname: pathName,
          state: {
            typeService: typeService
          }
        }} >
        <div className="card-delivery-panel">
          <div className="card-delivery-panel__image">
            <img src={image} alt={title} />
          </div>
          <div className="card-delivery-panel__info">
      <h2 className="marginx05">{title}</h2><span>{price}</span>
      <p>{text}</p>
          </div>
        </div>
      </RouterLink>
    </Grid>
  )
}

export default CardDelivery
