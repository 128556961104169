import React, { useState } from 'react'
// Utils
import normalize from '../utils/normalize'

function useSearchReservations(reservations) {
  const [ query, setQuery ] = useState('');
  const [ searchReservations, setSearchReservations ] = useState(reservations);

  React.useMemo(() => {
    const result = reservations.filter(res => {
      return `${res.id} ${res.houser_id} ${res.user_id}`
        .toLowerCase()
        .includes(normalize(query.toLowerCase()));
    });
    setSearchReservations(result)
  }, [ reservations, query ]);

  return { query, setQuery, searchReservations }
}

export default useSearchReservations