export const PetType = {
  perro: 1,
  gato: 2,
  conejo: 3,
  exotico: 4,
} as const;

export const PetTypeName = {
  1: 'Perro',
  2: 'Gato',
  3: 'Conejo',
  4: 'Exotico',
} as const;

export type PetTypeType = typeof PetType[keyof typeof PetType];

export default interface PetTypeDTO {
  id: number;
  title: string;
  icon: string;
}
