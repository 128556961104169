import SnackbarType from '@root/interfaces/SnackbarType';
import { SNACKBAR_OPEN, SNACKBAR_CLOSED } from './actions';

const initialState: SnackbarType = {
  action: false,
  open: false,
  message: 'Note archived',
  icon: null,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'alert',
  severity: 'success',
  transition: 'SlideDown',
  duration: 4500,
  close: true,
  actionButton: false,
};

export interface SnackbarOpenAction extends SnackbarType {
  type: typeof SNACKBAR_OPEN;
}

export interface SnackbarClosedAction {
  type: typeof SNACKBAR_CLOSED;
}

export type ActionTypes = SnackbarOpenAction | SnackbarClosedAction;

type SnackbarReducerType = (state: SnackbarType, action: ActionTypes) => SnackbarType;

const snackbarReducer: SnackbarReducerType = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_OPEN:
      return {
        ...state,
        action: !state.action,
        open: action.open ? action.open : initialState.open,
        message: action.message ? action.message : initialState.message,
        icon: action.icon ? action.icon : initialState.icon,
        anchorOrigin: action.anchorOrigin ? action.anchorOrigin : initialState.anchorOrigin,
        variant: action.variant ? action.variant : initialState.variant,
        severity: action.severity ? action.severity : initialState.severity,
        transition: action.transition ? action.transition : initialState.transition,
        duration: action.duration ? action.duration : initialState.duration,
        close: action.close === false ? action.close : initialState.close,
        actionButton: action.actionButton ? action.actionButton : initialState.actionButton,
      };
    case SNACKBAR_CLOSED:
      return {
        ...state,
        action: false,
        open: false,
      };

    default:
      return state;
  }
};

export default snackbarReducer;
