import React, { useContext } from 'react';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { Context } from '@root/Context';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as ROUTES from 'constants/routes';
// Utils
import convertBase64ToFile from 'utils/convertBase64ToFile';
import getFileName from 'utils/getFileName';
// Componets
import Notification from 'components/notification';
// eslint-disable-next-line no-unused-vars
import BtnFacebook from 'components/btnFacebook';
// eslint-disable-next-line no-unused-vars
import BtnGoogle from 'components/btnGoogle';
// Hooks
import { useInputValue } from 'hooks/useInputValue';
import useNotificationValue from 'hooks/useNotificationValue';
// Material - UI
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    padding: '14px 24px',
    marginTop: '1.5rem',
    marginBottom: '1rem',
    fontSize: '1rem',
  },
  linkRed: {
    fontFamily: 'Montserrat-Semibold',
    color: '#f44336',
  },
}));

const variantRole = {
  0: ROUTES.CUIDADOR_DASHBOARD, // Cuidador
  1: ROUTES.LISTA_CUIDADORES, // Cliente
  2: ROUTES.CUIDADOR_DASHBOARD, // Cluidador - Cliente
};

function Login(props) {
  const {
    redirectTo,
    tempRequest,
    isAuth,
    client,
    houser,
    admin,
    activateAuth,
    setRedirect,
    setRole,
    setLocation,
    setUserData,
  } = useContext(Context);
  const classes = useStyles();
  const email = useInputValue('');
  const password = useInputValue('');

  // Variables de "Notification"
  const notification = useNotificationValue();

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchLogin();
  };

  const fetchLogin = async () => {
    notification.handleCloseSnackbar();
    let body = {
      email: email.value,
      password: password.value,
    };
    sentUserData(SERVICES.LOGIN_EMAIL, OPTIONS.POST(body));
  };

  // eslint-disable-next-line no-unused-vars
  const responseGoogle = (response) => {
    if (response) {
      // console.log(response);
      if (response.error) {
        notification.handleSnackbar(response.error, 'error');
      } else {
        let image = '';
        if (response.profileObj.imageUrl) {
          image = response.profileObj.imageUrl;
        }
        fetchLoginGoogle(
          response.profileObj.givenName, // name
          response.profileObj.familyName, // lastName
          response.profileObj.email, // email
          response.googleId, // socialId
          image, // picture
        );
      }
    } else {
      notification.handleSnackbar('El usuario cancelo el login', 'error');
    }
  };

  const fetchLoginGoogle = async (name, last_name, google_email, google_id, photo) => {
    notification.handleCloseSnackbar();
    let type = 'foto';
    if (photo === '') {
      type = 'No foto';
    }
    let body = {
      country: 'Perú',
      city: 'Lima',
      name: name,
      last_name: last_name,
      google_email: google_email,
      google_id: google_id,
      photo: photo,
      rol: '1',
      terms_conditions: '0',
    };
    sentUserData(SERVICES.LOGIN_AND_REGISTER_GOOGLE, OPTIONS.POST(body), type);
  };

  // eslint-disable-next-line no-unused-vars
  const responseFacebook = (response) => {
    if (response) {
      // console.log(response);
      if (response.status === 'unknown') {
        notification.handleSnackbar('Se cancelo el inicio de sesión', 'error');
      } else if (response.status === 'not_authorized') {
        notification.handleSnackbar('Se cancelo el inicio de sesión', 'error');
      } else {
        let email = '',
          image = '';
        if (response.email) {
          email = response.email;
        }
        if (response.picture.data.url) {
          image = response.picture.data.url;
        }
        fetchLoginFacebook(
          response.name, // name
          email, // email
          response.userID, // socialId
          image, // picture
        );
      }
    } else {
      notification.handleSnackbar('El usuario cancelo el login', 'error');
    }
  };

  const fetchLoginFacebook = async (name, facebook_email, facebook_id, photo) => {
    notification.handleCloseSnackbar();
    let type = 'foto';
    if (photo === '') {
      type = 'No foto';
    }
    let body = {
      country: 'Perú',
      city: 'Lima',
      name: name,
      last_name: '',
      facebook_email: facebook_email,
      facebook_id: facebook_id,
      photo: photo,
      rol: '1',
      terms_conditions: '0',
    };
    sentUserData(SERVICES.LOGIN_AND_REGISTER_FACEBOOK, OPTIONS.POST(body), type);
  };

  const sentUserData = async (url, options, type) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      let houserId = '';
      let sentTo = ROUTES.HOME;
      // console.log(data)
      if (data.status === 200) {
        if (data.houser) {
          houserId = data.houser.id;
        }
        if (data.message === 'Usuario Correcto') {
          // Gestión del usuario
          activateAuth(
            data.user.id, // token
            data.user.id, // id
            houserId, // houserId
            data.user.confirmation, // stClient
            data.user.terms_conditions, // stHouser
          );
          setUserData(data.user.name, data.user.photo);
          setLocation(data.location.country, data.location.city);
          setRole(data.user.rol);
          // Enviar al usuario a la vista correspondiente
          sentTo = variantRole[data.user.rol];
          console.log(data.user.rol);
          if (redirectTo) {
            props.history.push(redirectTo);
            setRedirect('');
          } else {
            console.log(sentTo);
            props.history.push(sentTo);
          }
        } else if (data.message === 'Usuario registrado') {
          if (type === 'foto') {
            // Upload Foto
            fetchImageUser(
              data.user.id,
              houserId,
              data.user.photo,
              data.user.confirmation,
              data.user.terms_conditions,
              data.user.rol,
            );
            setUserData(data.user.name, '');
            setLocation(data.location.country, data.location.city);
          } else {
            // Gestión del usuario
            activateAuth(
              data.user.id, // token
              data.user.id, // id
              houserId, // houserId
              data.user.confirmation, // stClient
              data.user.terms_conditions, // stHouser
            );
            setUserData(data.user.name, '');
            setLocation(data.location.country, data.location.city);
            setRole(data.user.rol);
            // Enviar al usuario a la vista correspondiente
            sentTo = variantRole[data.user.rol];
            props.history.push(sentTo);
          }
        }
      } else {
        notification.handleSnackbar(data.message, 'error');
      }
    } catch (error) {
      notification.handleSnackbar('Error:' + error.message, 'error');
    }
  };

  const fetchImageUser = async (id, houserId, image, confirmation, terms_conditions, rol) => {
    let blob = await fetch(image).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    // console.log(dataUrl)
    let imageFile = convertBase64ToFile(dataUrl);
    let imageName = getFileName(id, imageFile.size, 'avatars');
    const formData = new FormData();
    formData.append('id', id);
    formData.append('photo', imageFile, imageName);
    let body = formData;
    try {
      const response = await fetch(SERVICES.UPLOAD_PHOTO_USER, OPTIONS.POST_FORM_DATA(body));
      const data = await response.json();
      let sentTo = ROUTES.HOME;
      // console.log(data)
      if (response.message) {
        activateAuth(id, id, houserId, confirmation, terms_conditions);
        setRole(rol);
        // Enviar al usuario a la vista correspondiente
        sentTo = variantRole[data.user.rol];
        props.history.push(sentTo);
      } else {
        notification.handleSnackbar('Error al subir la Imagen', 'error');
      }
    } catch (error) {
      notification.handleSnackbar('Error:' + error.message, 'error');
    }
  };

  let fromCliente = ROUTES.CUIDADOR_DASHBOARD;
  let fromCuidador = ROUTES.CUIDADOR_DASHBOARD;

  if (props.location.state) {
    fromCliente = props.location.state || {
      from: { pathname: ROUTES.CUIDADOR_DASHBOARD },
    };
    fromCuidador = props.location.state || {
      from: { pathname: ROUTES.CUIDADOR_DASHBOARD },
    };
  }

  if (isAuth && houser) return <Redirect to={fromCuidador} />;
  if (isAuth && client) return <Redirect to={fromCliente} />;
  if (isAuth && admin) return <Redirect to={ROUTES.HOME} />;

  return (
    <React.Fragment>
      <div className={classes.paper}>
        <h1 className="dh-title">Iniciar Sesión</h1>
        {/*
        <BtnFacebook
          response={responseFacebook}
          text="Seguir con Facebook"
        />
        <BtnGoogle 
          response= {responseGoogle} 
          text="Seguir con Google"
        />
        <h3 className="divider">
          <span>o</span>
        </h3>
        */}
        {redirectTo && tempRequest && (
          <p className={classes.linkRed}>
            Asegúrese de completar el registro de mascotas después de iniciar sesión o
            registrarse.
          </p>
        )}
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            type="email"
            autoComplete="email"
            value={email.value}
            onChange={email.onChange}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password.value}
            onChange={password.onChange}
          />
          <button type="submit" className={`btn-primary btn-block ${classes.button}`}>
            Iniciar sesión
          </button>
          <Grid container alignItems="center">
            <Grid item xs>
              <RouterLink to={ROUTES.PASSWORD_FORGET} className="dh-no-decoration">
                <p className="login-link">{'¿Se te olvidó tu contraseña?'}</p>
              </RouterLink>
            </Grid>
            <Grid item>
              <RouterLink to={ROUTES.REGISTER_CLIENTE} className="dh-no-decoration">
                <p className="login-link">
                  {'¿Aún no tienes cuenta? '}
                  <span className={classes.linkRed}>¡Regístrate!</span>
                </p>
              </RouterLink>
            </Grid>
          </Grid>
        </form>
      </div>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </React.Fragment>
  );
}

export default Login;
