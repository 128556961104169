import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import PetDTO from '@root/models/PetDTO';
import { IMAGE_URL, PET_TYPE, PET_SIZE } from '@root/constants/variables';
import { EDIT_MASCOTA } from '@root/constants/routes';
import { useSnackbar } from '@root/hooks';
import { petService } from '@root/services';
import { convertBase64ToFile, getPetAge, replacePathParams, resizeImage } from '@root/utils';
import { DialogUploadImage } from '@root/ui-component/dialog';
import { HoverEffectImageUpdate } from '@root/ui-component/cards';
import styles from './petInfo.module.scss';

interface PetInfoType {
  loading?: boolean;
  pet?: PetDTO;
  breed?: any;
}

type ImageReaderType = {
  file: string;
  imagePreviewUrl: string | ArrayBuffer;
};

interface UploadPhotoType {
  pet_id: string | Blob;
  photo_old: string | Blob;
  photo: string | Blob;
}

const PetInfo = (props: PetInfoType) => {
  const { loading, pet, breed } = props;
  const snackbar = useSnackbar();
  const [image, setImage] = useState<ImageReaderType>({ file: '', imagePreviewUrl: '' });
  const [loadingImg, setLoadingImg] = useState(false);
  const [open, setOpen] = useState(false);
  const [newImage, setNewImage] = useState('');

  const petAge = getPetAge(pet?.birth_date);

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setImage({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setImage({ file: '', imagePreviewUrl: '' });
    setOpen(false);
  };

  const handleOk = () => {
    handleImage();
    setOpen(false);
  };

  const handleImage = async () => {
    setLoadingImg(true);
    snackbar.info('Subiendo imagen ...');
    try {
      const imageFile = await resizeImage(image.file, 'file', 580);
      const newFile = convertBase64ToFile(imageFile);
      fetchUploadImage(newFile);
    } catch (error) {
      snackbar.error('Error: ' + error.message);
      setLoadingImg(false);
    }
  };

  const fetchUploadImage = async (file) => {
    const body: UploadPhotoType = {
      pet_id: String(pet.id),
      photo_old: pet.photo,
      photo: file,
    };
    try {
      const res = await petService.uploadPhoto(body);
      if (res.status === 200) {
        snackbar.success(res.message);
        setLoadingImg(false);
        setNewImage(String(image.imagePreviewUrl));
        return;
      }
      snackbar.error('Error al subir la Imagen');
      setLoadingImg(false);
    } catch (error) {
      snackbar.error('Error: ' + error.message);
      setLoadingImg(false);
    }
  };

  if (loading) {
    return (
      <div className="dh-card-border">
        <div className={styles.name}>
          <Skeleton width="16rem" height="1.8rem" />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Skeleton variant="rect" height={260} width={260} className={styles.wrap} />
            <div className={styles.action}>
              <label htmlFor="input-pet-photo" role="button" className="btn-primary-outline">
                Actualizar foto
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <section className={styles.grid}>
              <div className={styles.item}>
                <p className={styles.title}>Tipo</p>
                <Skeleton width="50%" height="1.4rem" className={styles.detail} />
              </div>
              <div className={styles.item}>
                <p className={styles.title}>Raza</p>
                <Skeleton width="50%" height="1.4rem" className={styles.detail} />
              </div>
              <div className={styles.item}>
                <p className={styles.title}>Edad</p>
                <Skeleton width="50%" height="1.4rem" className={styles.detail} />
              </div>
              <div className={styles.item}>
                <p className={styles.title}>Tamaño</p>
                <Skeleton width="50%" height="1.4rem" className={styles.detail} />
              </div>
            </section>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className="dh-card-border">
      <RouterLink to={replacePathParams(EDIT_MASCOTA, { id: pet?.id })}>
        <button style={{ float: 'right' }} className="btn-primary medium btn-step">
          Editar mascota
        </button>
      </RouterLink>
      <h1 className={styles.name}>{pet?.name}</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <HoverEffectImageUpdate
            width={260}
            height={260}
            variant="rounded"
            src={newImage ? newImage : IMAGE_URL + pet?.photo}
            alt="mascota"
            htmlFor="input-pet-photo"
          />
          <div className={styles.action}>
            <input
              id="input-pet-photo"
              autoComplete="off"
              accept=".jpg,.jpeg,.png,.gif"
              type="file"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <label htmlFor="input-pet-photo" role="button" className="btn-primary-outline">
              Actualizar foto
            </label>
          </div>
        </Grid>
        <Grid item xs={12} sm={8}>
          <section className={styles.grid}>
            <div className={styles.item}>
              <p className={styles.title}>Tipo</p>
              <h5 className={styles.detail}>{PET_TYPE[pet?.pet_type]}</h5>
            </div>
            <div className={styles.item}>
              <p className={styles.title}>Raza</p>
              <h5 className={styles.detail}>{breed}</h5>
            </div>
            <div className={styles.item}>
              <p className={styles.title}>Edad</p>
              <h5 className={styles.detail}>{petAge}</h5>
            </div>
            <div className={styles.item}>
              <p className={styles.title}>Tamaño</p>
              <h5 className={styles.detail}>{PET_SIZE[pet?.pet_size]}</h5>
            </div>
          </section>
        </Grid>
      </Grid>
      <DialogUploadImage
        loading={loadingImg}
        imageUrl={String(image.imagePreviewUrl)}
        open={open}
        onClose={handleClose}
        onOk={handleOk}
      />
    </div>
  );
};

export default PetInfo;
