import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './gallerySection.scss'
// Dependencies
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
// Constants
import * as VARIABLES from 'constants/variables';
// Material-UI
import Skeleton from '@material-ui/lab/Skeleton'

const GallerySection = props => {
  const { gallery } = props
  const [open, setOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const images = []
  for (var i=0; i<gallery.length; i++) {
    const image = VARIABLES.IMAGE_URL + gallery[i].photo
    images.push(image)
  }

  const openLightbox = (index) => {
    setPhotoIndex(index)
    setOpen(true)
  }

  const closeLightbox = () => {
    setOpen(false)
  }

  const handlePhotoIndex = (e) => {
    setPhotoIndex(e)
  }

  return (
    <div className="section-gallery">
      <div className="gallery">
        {gallery.length > 1 ? (
          <React.Fragment>
            {gallery.slice(0,7).map((item, index) => (
              <figure
                key={index}
                className={`gallery__item gallery__item--${index} ${gallery.length > 7 && 'max'}`}
                onClick={
                  () => openLightbox(index)
                }
              >
                <img
                  src={`${VARIABLES.IMAGE_URL}${item.photo}`}
                  className="gallery__img"
                  alt="houser cover"
                />
                <div className="photo-overlay">
                  <div className="photo-overlay-content text-center">
                    <strong>{gallery.length - 7}+ Fotos</strong>
                    <p className="small">Ver todo</p>
                  </div>
                </div>
              </figure>
            ))}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {Array.from(new Array(7)).map((data, index) => (
              <figure className={`gallery__item gallery__item--${index}`} key={index}>
                <Skeleton variant="rect" className="gallery__img"/>
              </figure>
            ))}
          </React.Fragment>
        )}
      </div>
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            handlePhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            handlePhotoIndex((photoIndex + 1) % images.length)
          }
          enableZoom={false}
        />
      )}
    </div>
  )
};

GallerySection.propTypes = {
  gallery: PropTypes.array.isRequired,
};

export default GallerySection
