import React, { useState, useEffect } from 'react';
import './adminEditPlace.scss';

import * as SERVICES from 'services/config';
import * as OPTIONS from '@root/services/options';
import Resizer from 'react-image-file-resizer';
// Componentes
import Notification from 'components/notification';
import TitleArrowBack from '@root/components/TitleBack';
// Constants
import * as VARIABLES from 'constants/variables';
// Material-UI
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
// Images
import Gallery from 'assets/icons/Gallery';

function ImageProgress(props) {
  const { image } = props;
  return (
    <div className="image-progress">
      <img className="profile-img" src={image} alt="usuario" />
      <CircularProgress className="image-progress__circle" />
    </div>
  );
}

function AdminEditPlace(props) {
  const urlId = props.match.params.id;
  const urlCountry = props.match.params.country;
  const urlCity = props.match.params.city;
  const id = parseInt(urlId);

  // Variables de la imagen
  const [imageCard, setImageCard] = useState({
    file: '',
    imagePreviewUrl: '',
  });
  const [imageDetail, setImageDetail] = useState({
    file: '',
    imagePreviewUrl: '',
  });

  // Variantes del componente "Notification"
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [place, setPlace] = useState({
    id: null,
    title: '',
    adress: '',
    link_page: '',
    phone: '',
    pet_place_type_id: null,
    location_id: null,
    image_card: 'petFriendlyPictureCard-2-bodega_verde_87562.jpg',
    image_detail: 'petFriendlyPictureDetail-2-bodega_verde_header_dsc0025e.jpg',
    description: '',
    price_range: 'S/. 27 - S/. 51',
    schedule: 'lunes a domingo de 8:00 a.m. a 10:00 p.m.',
    food_type: 'Café, Delicatessen',
    location: {
      id: 757,
      latitude: '-12.148210',
      longitude: '-77.022489',
      country: 'Perú',
      city: 'Lima',
      department: 'Lima',
      zipcode: '',
      province: 'Lima',
      district: 'Barranco',
      abreviated: null,
      user_id: null,
    },
  });

  // Funcion para cerrar el Componente 'Notification'
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  // Funcion para volver a la pestaña anterior
  const handleBackClick = () => {
    props.history.goBack();
  };

  useEffect(() => {
    if (urlId && urlCountry && urlCity) {
      fetchPlace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlId]);

  const fetchPlace = async () => {
    try {
      const response = await fetch(
        `${SERVICES.GET_PLACE}/${urlId}/${urlCountry}/${urlCity}`,
        OPTIONS.GET(),
      );
      const data = await response.json();
      console.log(data);
      if (response.status === 200) {
        setPlace(data.place);
        setLoading(false);
      } else {
        setMessage('No se pudo obtener los datos');
        setMessageVariant('error');
        setMessageState(true);
        setLoading(false);
      }
    } catch (e) {
      setMessage('Error: ' + e.message);
      setMessageVariant('error');
      setMessageState(true);
      setLoading(false);
    }
  };

  // Controla si hay un cambio de imagén para la tarjeta
  const handleImageChangeCard = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setImageCard({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
    }
  };

  // Controla si hay un cambio de imagén para la vista detallada
  const handleImageChangeDetail = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setImageDetail({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
    }
  };

  // Función para enviar las imágenes al servidor
  const handleSubmitImageHeader = (e) => {
    e.preventDefault();
    if (imageDetail.file) {
      fetchImageDetail();
    } else {
      setMessage('Tienes que escoger una imagen.');
      setMessageVariant('warning');
      setMessageState(true);
    }
  };

  // Función para enviar las imágenes al servidor
  const handleSubmitImageCard = (e) => {
    e.preventDefault();
    if (imageCard.file) {
      fetchImageCard();
    } else {
      setMessage('Tienes que escoger una imagen.');
      setMessageVariant('warning');
      setMessageState(true);
    }
  };

  // Convertir la imagen de Base64 a archivo
  const convertBase64ToFile = function (image) {
    const byteString = atob(image.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    const newBlob = new Blob([ab], {
      type: 'image/jpeg',
    });
    return newBlob;
  };

  // Establecer los datos del Usuario a enviar la tarjeta
  const fetchImageCard = async () => {
    setLoading(false);
    setMessage('');
    setMessageState(false);
    Resizer.imageFileResizer(
      imageCard.file,
      580,
      580,
      'JPEG',
      80,
      0,
      (uri) => {
        let newFile = convertBase64ToFile(uri);
        let text = `pf${id}_${imageCard.file.name}_master`;
        const formData = new FormData();
        formData.append('id', id);
        formData.append('photo', newFile, text);
        sendVariableData(
          SERVICES.UPLOAD_PHOTO_PETFRIENDLY_CARD,
          OPTIONS.POST_FORM_DATA(formData),
          VARIABLES.STATE_UPDATE,
        );
      },
      'base64',
    );
  };

  // Establecer los datos del Usuario a enviar la imagen detallada
  const fetchImageDetail = async () => {
    setLoading(false);
    setMessage('');
    setMessageState(false);
    Resizer.imageFileResizer(
      imageDetail.file,
      1200,
      720,
      'JPEG',
      80,
      0,
      (uri) => {
        let newFile = convertBase64ToFile(uri);
        let text = `pf${id}_${imageDetail.file.name}`;
        const formData = new FormData();
        formData.append('id', id);
        formData.append('photo', newFile, text);
        sendVariableData(
          SERVICES.UPLOAD_PHOTO_PETFRIENDLY_DETAIL,
          OPTIONS.POST_FORM_DATA(formData),
          VARIABLES.STATE_UPDATE,
        );
      },
      'base64',
    );
  };

  const sendVariableData = async (url, options, state) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (state === VARIABLES.STATE_INITIATE) {
          setPlace(data);
        } else if (state === VARIABLES.STATE_UPDATE) {
          setLoading(false);
          setMessage('Has actualizado la imagen del lugar correctamente');
          setMessageVariant('success');
          setMessageState(true);
        }
      } else {
        if (state === VARIABLES.STATE_INITIATE) {
          setMessage('Error al obtener la foto del lugar');
          setMessageVariant('error');
          setMessageState(true);
        } else if (state === VARIABLES.STATE_UPDATE) {
          setLoading(false);
          setMessage('Lo sentimos, no fue posible cargar su imagen.');
          setMessageVariant('error');
          setMessageState(true);
        }
      }
    } catch (error) {
      setLoading(false);
      setMessage('Error en la Imagen: ' + error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <div>
      <TitleArrowBack handleClick={handleBackClick}>
        <h2 className="marginx05">{place.title}</h2>
      </TitleArrowBack>
      <div className="dh-card-elevation margin-top-x1">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h3>Imagen Principal</h3>
            <div className="dh-upload-image-detail">
              <div className="dh-upload-image-detail__header">
                <span id="switch-red"></span>
                <span id="switch-yellow"></span>
                <span id="switch-green"></span>
              </div>
              <div className="dh-upload-image-detail__image-container">
                {imageDetail.imagePreviewUrl ? (
                  <React.Fragment>
                    {loading ? (
                      <ImageProgress image={imageDetail.imagePreviewUrl} />
                    ) : (
                      <label htmlFor="upload-photo-detail">
                        <img
                          className="profile-image"
                          src={imageDetail.imagePreviewUrl}
                          alt="lugar"
                        />
                      </label>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {place.image_detail ? (
                      <label htmlFor="upload-photo-detail">
                        <img
                          className="profile-image"
                          src={`${VARIABLES.IMAGE_URL}${place.image_detail}`}
                          alt={place.title}
                        />
                      </label>
                    ) : (
                      <label htmlFor="upload-photo-detail">
                        <div className="drop-zone">
                          <div className="drop-zone__content">
                            <Gallery />
                          </div>
                        </div>
                      </label>
                    )}
                  </React.Fragment>
                )}
              </div>
              <div className="dh-upload-image-detail__info-container">
                <div className="dh-upload-image-detail__info-container__description">
                  <h4>Bodega Verde</h4>
                  <Skeleton variant="rect" width={64} height={8} style={{ marginTop: 4 }} />
                  <Skeleton
                    variant="rect"
                    width={96}
                    height={8}
                    style={{ marginTop: 10, marginBottom: 6 }}
                  />
                  <Skeleton variant="rect" width="70%" height={8} style={{ marginBottom: 4 }} />
                  <Skeleton variant="rect" width="70%" height={8} style={{ marginBottom: 4 }} />
                  <Skeleton variant="rect" width="50%" height={8} style={{ marginBottom: 4 }} />
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmitImageHeader} className="form-container">
              <label
                htmlFor="upload-photo-detail"
                className="btn-primary border btn-profile-border">
                Cargar foto
              </label>
              <input type="file" id="upload-photo-detail" onChange={handleImageChangeDetail} />
              <button className="btn-primary" type="submit">
                Guardar Imagen
              </button>
            </form>
          </Grid>
          <Grid item xs={6}>
            <div className="dh-upload-image-card">
              <h3>Imagen de la tarjeta</h3>
              <div className="dh-upload-image-card__container">
                {imageCard.imagePreviewUrl ? (
                  <React.Fragment>
                    {loading ? (
                      <ImageProgress image={imageCard.imagePreviewUrl} />
                    ) : (
                      <label htmlFor="upload-photo-card">
                        <img
                          className="profile-image"
                          src={imageCard.imagePreviewUrl}
                          alt="lugar"
                        />
                      </label>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {place.image_card ? (
                      <label htmlFor="upload-photo-card">
                        <img
                          className="profile-image"
                          src={`${VARIABLES.IMAGE_URL}${place.image_card}`}
                          alt={place.title}
                        />
                      </label>
                    ) : (
                      <label htmlFor="upload-photo-card">
                        <div className="drop-zone">
                          <div className="drop-zone__content">
                            <Gallery />
                          </div>
                        </div>
                      </label>
                    )}
                  </React.Fragment>
                )}
              </div>
              <form onSubmit={handleSubmitImageCard} className="form-container">
                <label
                  htmlFor="upload-photo-card"
                  className="btn-primary border btn-profile-border">
                  Cargar foto
                </label>
                <input type="file" id="upload-photo-card" onChange={handleImageChangeCard} />
                <button className="btn-primary" type="submit">
                  Guardar Imagen
                </button>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default AdminEditPlace;
