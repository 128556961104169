import React from 'react'
// Material-UI
import FormGroup from '@material-ui/core/FormGroup'
import Skeleton from '@material-ui/lab/Skeleton'

function LoadingIcon(props) {
  const { count } = props
  return (
    <FormGroup row >
      {Array.from(new Array(count)).map((data, index) => {
        return (
          <div className="item-loading" key={index}>
            <Skeleton className="item-loading__circle" variant="circle" width={64} height={64}/>
            <Skeleton className="item-loading__text" height={10}  width="60%"/>
          </div>
        )
      })}
    </FormGroup>
  )
}

export default LoadingIcon
