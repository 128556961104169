import React from 'react'

// Material-UI
import Grid from '@material-ui/core/Grid';

function AdminProviders() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h2 className="admin-title">Proveedores</h2>
      </Grid>
    </Grid>
  )
}

export default AdminProviders
