import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function CasaExclusiva(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 495.4 495.4"
      style={{enableBackground: 'new 0 0 495.4 495.4'}}
      xmlSpace="preserve">
      <path d="M248.1,9.5C116.2,9.5,9.3,116.4,9.3,248.3s106.9,238.8,238.8,238.8s238.8-106.9,238.8-238.8S380,9.5,248.1,9.5z
        M372.3,372.6c0,19.7-15.9,35.6-35.6,35.6h-56.9l0,0h-64.2l0,0h-56.9c-19.7,0-35.6-15.9-35.6-35.6v-83.8c0-2.6,1-5.1,2.8-6.9
        l114.9-114.8c3.8-3.8,9.9-3.8,13.7,0l114.8,114.8c1.8,1.8,2.8,4.3,2.8,6.9V372.6z M414.2,260.2c-3.9,3.9-8.9,5.8-14,5.8
        c-5,0-10.1-1.9-14-5.8L255.7,129.6c-4.2-4.2-11.7-4.2-16,0L109.1,260.2c-7.7,7.7-20.2,7.7-27.9,0c-7.7-7.7-7.7-20.2,0-27.9
        l130.6-130.6c19.2-19.2,52.6-19.3,71.8,0l38.9,38.9v-20.8c0-10.9,8.8-19.7,19.7-19.7c10.9,0,19.8,8.8,19.8,19.7V180l52.2,52.2
        C421.9,240,421.9,252.5,414.2,260.2z" fill={color}/>
      <g>
        <path d="M269.9,271.1h-21.5H248h-21.5c0,85-46.3,63.8-46.3,63.8s-11,75.1,67.2,76.2v0.1c0.3,0,0.5,0,0.8-0.1
          c0.3,0,0.5,0.1,0.8,0.1V411c78.2-1.1,67.2-76.2,67.2-76.2S269.9,356.1,269.9,271.1z M246.8,356.1v0.7c-0.3,0-0.8-0.3-1.2-0.3
          c-0.4,0.1-0.8,0.3-1.2,0.3v-0.7c-5.9-1.6-14.2-8.6-14.2-15c0-6.1,11.7-5.7,15.4-5.4c3.7-0.3,15.4-0.7,15.4,5.4
          C261,347.4,252.7,354.4,246.8,356.1z" fill={color}/>
        <path d="M325.6,291.7l-28.4-17.8c0,0-15.6-6.6-17.2,3.8c-1.6,10.3,11.3,43.5,11.3,43.5s4.7,11.6,17.5,3.1
          c12.8-8.4,20.6-18.1,20.6-18.1S339.6,300.5,325.6,291.7z" fill={color}/>
        <path d="M205.2,321.1c0,0,12.8-33.1,11.3-43.5c-1.6-10.3-17.2-3.8-17.2-3.8l-28.5,17.8c-14.1,8.8-3.7,14.4-3.7,14.4
          s7.8,9.7,20.6,18.1C200.5,332.7,205.2,321.1,205.2,321.1z" fill={color}/>
      </g>
    </svg>
  )
}

export default CasaExclusiva
