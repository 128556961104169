import axios from 'axios';

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiaWF0IjoxNjg5NzI4NzI5LCJqdGkiOiJmOTY1YmM2ZTE0OTA0YWVmOWE1ZDQ2M2FjNzk4YjFkNyIsInVzZXJfZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJpbml0aWFscyI6bnVsbCwicm9sZSI6IjY0IiwidXNlcm5hbWUiOiJhZG1pbiJ9.UE3vEQJTgLLWR7LGLI9H-GSKo1P5dSBb74wGyJfVXgo';

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_API_URL_V2,
  timeout: 5000,
  headers: {
    // 'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

// interceptor for http
axiosServices.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services'),
);

export default axiosServices;
