import { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { IconCirclePlus } from '@tabler/icons';
import Swal from 'sweetalert2';

import { useSnackbar } from '@root/hooks';
import { houserServicesService, servicesService } from '@root/services';
import HouserServicesDTO from '@root/models/HouserServicesDTO';
import ServiceDTO from '@root/models/ServiceDTO';
import HouserServiceCard from '@root/components/cards/HouserServiceCard';
import AddServicesDialog from '@root/components/dialog/AddServicesDialog';
import MainCard from '@root/ui-component/cards/MainCard';
import { Space } from '@root/ui-component';

interface ServicesHouserTypes {
  houserId: number;
  country: string;
  city: string;
}

const ServicesHouser = (props: ServicesHouserTypes) => {
  const { houserId, country, city } = props;
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [houserServices, setHouserServices] = useState<Array<HouserServicesDTO>>([]);
  const [servicesByLocation, setServicesByLocation] = useState<Array<ServiceDTO>>([]);
  const [errorText, setErrorText] = useState('');
  const [stateServices, setStateServices] = useState({});

  const handleOpenAddServices = () => {
    setOpenDialog(true);
  };

  const handleClose = (servicesId?: Array<number>) => {
    setOpenDialog(false);
    if (servicesId && servicesId?.length > 0) {
      fetchCreateHouserService(servicesId);
      return;
    }
  };

  const handleDelete = (id: number) => {
    Swal.fire({
      title: 'Estas seguro?',
      text: 'No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí, eliminarlo',
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteHouserService(id);
      }
    });
  };

  const fetchHouserServies = async () => {
    setLoading(true);
    try {
      const res = await houserServicesService.getServicesByHouser(houserId);
      if (res.status === 200) {
        setHouserServices(res.houserServices);
      }
      setLoading(false);
    } catch (error) {
      snackbar.error(error.message);
      setLoading(false);
    }
  };

  const fetchServicesByLocation = async (country: string, city: string) => {
    try {
      setErrorText('');
      const res = await servicesService.getServicesByLocation(country, city);
      if (res.status === 200) {
        const service = res.services;
        if (service.length > 0) {
          setServicesByLocation(service);
          const target = {};
          service.forEach((res) => {
            const source = { ['service' + res.id]: false };
            Object.assign(target, source);
          });
          setStateServices(target);
          return;
        }
        setErrorText('No se encontraros servicios para el cuidador.');
      }
    } catch (error) {
      // snackbar.error(error.message);
      setErrorText('No se encontraros servicios para el cuidador.');
    }
  };

  const fetchCreateHouserService = async (servicesId: Array<number>) => {
    const body = {
      houser_id: houserId,
      services_id: servicesId,
    };
    try {
      const res = await houserServicesService.create(body);
      if (res.status === 200) {
        snackbar.success(res.message);
        fetchHouserServies();
        return;
      }
      snackbar.error(res.message);
    } catch (error) {
      snackbar.error('Error: ' + error.message);
    }
  };

  const fetchDeleteHouserService = async (id: number) => {
    try {
      const res = await houserServicesService.destroy(id);
      if (res.status === 200) {
        snackbar.success(res.message);
        fetchHouserServies();
        return;
      }
      snackbar.error('Error al eliminar el servicio del houser');
    } catch (error) {
      snackbar.error('Error: ' + error.message);
    }
  };

  useEffect(() => {
    fetchHouserServies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houserId]);

  useEffect(() => {
    if (country && city) {
      fetchServicesByLocation(country, city);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, city]);

  return (
    <MainCard title="Servicios activos">
      <Space direction="vertical" size="middle">
        {loading ? (
          <>
            {Array.from(new Array(2)).map((data, index) => (
              <Skeleton variant="rect" key={index} height={135} />
            ))}
          </>
        ) : (
          <>
            {houserServices.map((service) => (
              <HouserServiceCard
                key={service.id}
                houserService={service}
                onDelete={handleDelete}
              />
            ))}
          </>
        )}
        <Button
          color="default"
          startIcon={<IconCirclePlus />}
          disabled={errorText ? true : false}
          onClick={handleOpenAddServices}>
          {errorText ? errorText : 'Añadir servicio'}
        </Button>
      </Space>
      <AddServicesDialog
        id="add-services"
        open={openDialog}
        onClose={handleClose}
        services={servicesByLocation}
        state={stateServices}
        setState={setStateServices}
      />
    </MainCard>
  );
};

export default ServicesHouser;
