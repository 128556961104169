import React from 'react'
import { Link as RouterLink } from "react-router-dom";
import './cardService.scss'
// Material-UI
import Grid from '@material-ui/core/Grid';

function CardService(props) {
  const { title, text, image, pathName, typeService, size, price} = props
  return (
    <Grid item xs={12} sm={6} md={size}>
      <RouterLink className="dh-no-decoration"
        to={{
          pathname: pathName,
          state: {
            typeService: typeService
          }
        }}>
        <div className="card-service-panel">
          <div className="card-service-panel__image">
            <img src={image} alt={title} />
          </div>
          <div className="card-service-panel__info">
            <div className="flex-text">
              <h2 className="marginx05">{title}</h2>
              {price ? (
                <span>{price}</span>
              ) : (
                <React.Fragment/>
              )}
            </div>
            <p>{text}</p>
          </div>
        </div>
      </RouterLink>
    </Grid>
  )
}

export default CardService
