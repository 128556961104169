import React from 'react'
// Material-UI
import Container from '@material-ui/core/Container';

function CuidadorAyuda() {
  return (
    <div className="dashboard-cuidador">
      <Container maxWidth="lg">
        <h1 className="marginx05">Ayuda</h1>
      </Container>
    </div>
  )
}

export default CuidadorAyuda
