import React, { useState, useContext, useEffect } from 'react';
import { Context } from '@root/Context';
import './cuidadorServicios.scss';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// Components
import TitleArrowBack from '@root/components/TitleBack';
import Notification from 'components/notification';
import LoadingText from 'components/loadingText';
import LoadingIcon from 'components/loadingIcon';
// DogHouser Icons
import CasaCompartida from 'icons/CasaCompartida';
import CasaExclusiva from 'icons/CasaExclusiva';
import PillIcon from 'icons/PillIcon';
import InjectableIcon from 'icons/InjectableIcon';
import CamaIcon from 'icons/CamaIcon';
import SofaIcon from 'icons/SofaIcon';
import HomeIcon from 'icons/HomeIcon';
import Dog from 'icons/Dog';
// Material-UI
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

function CuidadorServicios(props) {
  const { cookies } = useContext(Context);
  let userId;
  // Se detecta si se pasa un ID por la URL
  if (props.match.params.id) {
    userId = props.match.params.id;
  } else {
    // Si no se detecta se usa el ID guardado en las cookies
    userId = cookies.id;
  }
  const [loading, setLoading] = useState(false);
  const notification = useNotificationValue();

  // Datos del usuario
  const [user, setUser] = useState({
    houser: {
      houser_type: '',
      type_delivery: '',
      type_exclusive: '',
      type_share: '',
      inmediate_reservation: '',
      pills: '',
      injectables: '',
      allowed_house: '',
      allowed_sofa: '',
      allowed_bed: '',
      pet_size: '',
      restrictions: '',
    },
  });
  const [state, setState] = React.useState({
    inmediate_reservation: '0',
    type_share: false,
    type_exclusive: false,
    type_delivery: false,
    dog_small: false,
    dog_medium: false,
    dog_big: false,
    pills: false,
    injectables: false,
    allowed_house: false,
    allowed_sofa: false,
    allowed_bed: false,
  });

  const handleCheckboxChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handleRadioChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleInputChange = (e) => {
    setUser({
      houser: {
        ...user.houser,
        [e.target.name]: e.target.value,
      },
    });
  };

  const fetchCuidador = async () => {
    setLoading(true);
    sendUserData(
      SERVICES.GET_CUIDADOR_BY_ID,
      OPTIONS.POST({ id: userId }),
      VARIABLES.STATE_INITIATE,
    );
  };

  // Obtener los datos actulizados
  const handleSubmit = (e) => {
    e.preventDefault();
    let pet_size = 1;

    if (state.dog_big) {
      pet_size = 2;
    } else if (state.dog_medium) {
      pet_size = 1;
    } else {
      pet_size = 0;
    }

    fetchUpdateUser(pet_size);
  };

  // Preparar datos para enviarlos al servidor
  const fetchUpdateUser = async (pet_size) => {
    let body = {
      id: userId,
      houser_type: user.houser.houser_type,
      inmediate_reservation: VARIABLES.TEXT_TO_NUMBER[state.inmediate_reservation],
      pet_size: pet_size,
      pills: VARIABLES.BOOLEAN_TO_NUMBER[state.pills],
      injectables: VARIABLES.BOOLEAN_TO_NUMBER[state.injectables],
      allowed_house: VARIABLES.BOOLEAN_TO_NUMBER[state.allowed_house],
      allowed_sofa: VARIABLES.BOOLEAN_TO_NUMBER[state.allowed_sofa],
      allowed_bed: VARIABLES.BOOLEAN_TO_NUMBER[state.allowed_bed],
      restrictions: user.houser.restrictions,
      type_share: VARIABLES.BOOLEAN_TO_NUMBER[state.type_share],
      type_exclusive: VARIABLES.BOOLEAN_TO_NUMBER[state.type_exclusive],
      type_delivery: VARIABLES.BOOLEAN_TO_NUMBER[state.type_delivery],
    };
    sendUserData(SERVICES.UPDATE_HOUSER_STEP_4, OPTIONS.POST(body), VARIABLES.STATE_UPDATE);
  };

  // Enviar datos al servidor
  const sendUserData = async (url, options, state) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        if (state === VARIABLES.STATE_INITIATE) {
          manageData(data.user);
          setLoading(false);
        } else if (state === VARIABLES.STATE_UPDATE) {
          notification.handleSnackbar('Datos actualizados', 'success');
        }
      } else {
        notification.handleSnackbar(data.message, 'warning');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  // Establecer los datos en la vista
  const manageData = async (data) => {
    setUser(data);
    let varSmall = false;
    let varMedium = false;
    let varBig = false;
    if (data.houser.pet_size === 2) {
      varBig = true;
      varMedium = true;
      varSmall = true;
    } else if (data.houser.pet_size === 1) {
      varMedium = true;
      varSmall = true;
    } else {
      varSmall = true;
    }
    setState({
      inmediate_reservation: VARIABLES.NUMBER_TO_TEXT[data.houser.inmediate_reservation],
      type_share: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.type_share],
      type_exclusive: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.type_exclusive],
      type_delivery: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.type_delivery],
      dog_small: varSmall,
      dog_medium: varMedium,
      dog_big: varBig,
      pills: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.pills],
      injectables: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.injectables],
      allowed_house: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.allowed_house],
      allowed_sofa: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.allowed_sofa],
      allowed_bed: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.allowed_bed],
    });
  };

  useEffect(() => {
    fetchCuidador();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div className="cuidador-servicios">
      <Container maxWidth="lg">
        <TitleArrowBack handleClick={() => props.history.goBack()}>
          <h4 className="marginx05">{'Volver al anuncio'}</h4>
        </TitleArrowBack>
        <div className="cuidador-servicios__title">
          <h1>Servicios</h1>
        </div>
        <h3 className="cuidador-servicios__sub-title">¿Qué servicios ofreces?</h3>
        <div className="cuidador-servicios__servicos-list">
          {!loading ? (
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.type_share}
                    onChange={handleCheckboxChange('type_share')}
                    icon={<CasaCompartida />}
                    checkedIcon={<CasaCompartida setColor="accent" />}
                    name="type_share"
                    color="primary"
                  />
                }
                label="Hospedaje Compartido"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.type_exclusive}
                    onChange={handleCheckboxChange('type_exclusive')}
                    icon={<CasaExclusiva />}
                    checkedIcon={<CasaExclusiva setColor="accent" />}
                    name="type_exclusive"
                    color="primary"
                  />
                }
                label="Hospedaje Exclusivo"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.type_delivery}
                    onChange={handleCheckboxChange('type_delivery')}
                    icon={<CasaExclusiva />}
                    checkedIcon={<CasaExclusiva setColor="accent" />}
                    name="type_delivery"
                    color="primary"
                  />
                }
                label="Delivery Houser"
                labelPlacement="bottom"
              />
            </FormGroup>
          ) : (
            <LoadingIcon count={6} />
          )}
        </div>
        <div className="divider-line" />
        <h3 className="cuidador-servicios__sub-title">¿Podrías dar medicinas?</h3>
        <div className="cuidador-servicios__medicines-list">
          {!loading ? (
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.pills}
                    onChange={handleCheckboxChange('pills')}
                    icon={<PillIcon />}
                    checkedIcon={<PillIcon setColor="accent" />}
                    name="pills"
                    color="primary"
                  />
                }
                label="Pastillas"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.injectables}
                    onChange={handleCheckboxChange('injectables')}
                    icon={<InjectableIcon />}
                    checkedIcon={<InjectableIcon setColor="accent" />}
                    name="injectables"
                    color="primary"
                  />
                }
                label="Inyectables"
                labelPlacement="bottom"
              />
            </FormGroup>
          ) : (
            <LoadingIcon count={3} />
          )}
        </div>
        <div className="divider-line" />
        <h3 className="cuidador-servicios__sub-title">Zonas Permitidas</h3>
        <div className="cuidador-servicios__zones-list">
          {!loading ? (
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.allowed_house}
                    onChange={handleCheckboxChange('allowed_house')}
                    icon={<HomeIcon />}
                    checkedIcon={<HomeIcon setColor="accent" />}
                    name="allowed_house"
                    color="primary"
                  />
                }
                label="Toda la Casa"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.allowed_sofa}
                    onChange={handleCheckboxChange('allowed_sofa')}
                    icon={<SofaIcon />}
                    checkedIcon={<SofaIcon setColor="accent" />}
                    name="allowed_sofa"
                    color="primary"
                  />
                }
                label="Subir al sofá"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.allowed_bed}
                    onChange={handleCheckboxChange('allowed_bed')}
                    icon={<CamaIcon />}
                    checkedIcon={<CamaIcon setColor="accent" />}
                    name="allowed_bed"
                    color="primary"
                  />
                }
                label="Dormir en la cama"
                labelPlacement="bottom"
              />
            </FormGroup>
          ) : (
            <LoadingIcon count={3} />
          )}
        </div>
        <div className="divider-line" />
        <h3 className="cuidador-servicios__sub-title">Reserva Inmediata</h3>
        <div>
          {!loading ? (
            <ul className="step-cuidador-servicios__flex-outer">
              <li>
                <label htmlFor="inmediate_reservation">¿Activar reserva inmediata?</label>
                <RadioGroup
                  aria-label="inmediate_reservation"
                  name="inmediate_reservation"
                  value={state.inmediate_reservation}
                  onChange={handleRadioChange('inmediate_reservation')}
                  row>
                  <FormControlLabel value="1" control={<Radio />} label="Si" />
                  <FormControlLabel value="0" control={<Radio />} label="No" />
                </RadioGroup>
              </li>
            </ul>
          ) : (
            <LoadingText count={1} />
          )}
        </div>
        <div className="divider-line" />
        <h3 className="cuidador-servicios__sub-title">Preferencias de cuidado</h3>
        <h4 className="marginx05">Tamaño de perro permitido</h4>
        <div className="cuidador-servicios__size-list">
          {!loading ? (
            <FormGroup row>
              <FormControlLabel
                className="dog-small"
                control={
                  <Checkbox
                    checked={state.dog_small}
                    onChange={handleCheckboxChange('dog_small')}
                    icon={<Dog />}
                    checkedIcon={<Dog setColor="accent" />}
                    name="dog_small"
                    color="primary"
                  />
                }
                label="Pequeño"
                labelPlacement="bottom"
              />
              <FormControlLabel
                className="dog-medium"
                control={
                  <Checkbox
                    checked={state.dog_medium}
                    onChange={handleCheckboxChange('dog_medium')}
                    icon={<Dog />}
                    checkedIcon={<Dog setColor="accent" />}
                    name="dog_medium"
                    color="primary"
                  />
                }
                label="Mediano"
                labelPlacement="bottom"
              />
              <FormControlLabel
                className="dog-big"
                control={
                  <Checkbox
                    checked={state.dog_big}
                    onChange={handleCheckboxChange('dog_big')}
                    icon={<Dog />}
                    checkedIcon={<Dog setColor="accent" />}
                    name="dog_big"
                    color="primary"
                  />
                }
                label="Grande"
                labelPlacement="bottom"
              />
            </FormGroup>
          ) : (
            <LoadingIcon count={3} />
          )}
        </div>
        <h4 className="marginx05">Restricciones</h4>
        {!loading ? (
          <TextField
            id="restrictions"
            name="restrictions"
            required
            fullWidth
            variant="outlined"
            type="text"
            multiline
            rows="3"
            value={user.houser.restrictions}
            onChange={handleInputChange}
          />
        ) : (
          <LoadingText count={1} />
        )}
        <button className="btn-primary" onClick={handleSubmit}>
          Guardar cambios
        </button>
      </Container>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default CuidadorServicios;
