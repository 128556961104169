import React from 'react'
import './cuidadoresInfo.scss'
// Material UI
import { Grid, Container } from '@material-ui/core'

function CuidadoresInfo() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="dh-cuidador-info-image">
          <Container maxWidth="lg">
            <div className="dh-cuidador-info-image__container">
              <h1>Cómo escogemos a nuestros cuidadores</h1>
              <h3>Qué requisitos necesitamos de una persona para que pueda ser un cuidador.</h3>
            </div>
          </Container>
        </div>
        <Container maxWidth="md">
          <div className="dh-cuidador-info-content">
            <h3>Requisitos que buscamos</h3>
            <div className="divider-line"/>
            <ul>
              <li>
                <p>
                  Escogemos gente que trabaje desde casa (amas de casa preferentemente) y que tenga disponibilidad los siente días de la semana. No afiliamos a personas que tengan un horario de oficina.
                </p>
              </li>
              <li>
                <p>
                  Personas 100% Dog Lovers, que sean apasionadas por los animales y su bienestar.
                </p>
              </li>
              <li>
                <p>
                  Experiencia de 5 años teniendo o cuidando mascotas.
                </p>
              </li>
              <li>
                <p>
                  Que si tiene perros, no sean más de dos.
                </p>
              </li>
              <li>
                <p>
                  Debe haber siempre alguien en casa, no se puede dejar el perro sin supervisión.
                </p>
              </li>
            </ul>
            <div className="dh-cuidador-info-content__image"></div>
            <blockquote>
              <h3>¿Te has preguntado cómo sabemos si nuestros cuidadores son fiables?</h3>
            </blockquote>
            <h3>¿Como es el proceso?</h3>
            <div className="divider-line"/>
            <ul>
              <li>
                <p>
                  Se filtran los perfiles y se revisan las respuestas de sus motivaciones y descripción de la familia cuidadora. Se descartará todo aquel que tenga motivos económicos principalmente y no por amor a las mascotas.
                </p>
              </li>
              <li>
                <p>
                  Se les envia el Manual de Housers para que conoscan todos los terminos y condiciones de la empresa y posteriormente se les realiza una evaluación.
                </p>
              </li>
              <li>
                <p>
                  Pasan por una entrevista, se revisa su disponibilidad de tiempo y se les pide entrega de documentos para validar su identidad y domicilio.
                </p>
              </li>
              <li>
                <p>
                  Requerimos fotos y un video del domicilio y hacemos una evaluación de riesgos de la casa. Verificamos que no hayan riesgos para los perros (cables sueltos, balcones sin protección, rejas sin malla, etc)
                </p>
              </li>
              <li>
                <p>
                  Los que cumplen con el proceso, son subidos al sistema y se les proporciona capacitaciones de primeros auxilios y protocolos de emergencia.
                </p>
              </li>
            </ul>
            <h3>¿Cómo es el proceso?</h3>
            <div className="divider-line"/>
              <ul>
                <li>
                  <p>
                    Dog Houser es una intermediara entre el cuidador y el dueño, por nuestro amor a los animales queremos que todos los cuidadores brinden un servicio de calidad. Por ello, lo acompañamos antes y durante las reservas, para poder ayudarlo ante cualquier eventualidad. Facilitamos un seguro de emergencia, para que en caso de una puedan ser atendidos rápidamente. Tenemos una línea de soporte en caso complicaciones de comportamiento o salud.
                  </p>
                </li>
              </ul>
          </div>
        </Container>
      </Grid>
    </Grid>
  )
}

export default CuidadoresInfo
