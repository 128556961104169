import { useEffect, useState } from 'react';

import { useSnackbar } from '@root/hooks';
import { petService } from '@root/services';
import { DogLayout, DogPageHeader, DogSectionTitle } from '@root/ui-component';
import PetInfo from './PetInfo';
import './petDetail.scss';

const PetDetail = (props) => {
  const petId = props.match.params.id;
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [pet, setPet] = useState(null);
  const [breed, setBreed] = useState(null);

  const fetchPet = async () => {
    setLoading(true);
    try {
      const res = await petService.findById(petId);
      if (res.status === 200) {
        setPet(res?.pet);
        setBreed(res?.breed?.breed);
      }
      setLoading(false);
    } catch (error) {
      snackbar.error('Error: ' + error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (petId) {
      fetchPet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petId]);

  return (
    <DogLayout containerMaxWidth="md" className="pet-detail-container">
      <DogPageHeader subTitle="Mis mascotas" onBack={() => props.history.goBack()} />
      <PetInfo loading={loading} pet={pet} breed={breed} />
      <DogSectionTitle title={`Más sobre ${pet?.name}`} />
      <div className="dh-card-pet-extra dh-card-border">
        <div className="dh-card-pet-extra__detail dh-flex-responsive">
          <p className="detail-title">
            <strong>Descripción:</strong>
          </p>
          <p>{pet?.description}</p>
        </div>
        <div className="dh-card-pet-extra__detail dh-flex-responsive">
          <p className="detail-title">
            <strong>Rutina de paseo:</strong>
          </p>
          <p>{pet?.rutine}</p>
        </div>
        <div className="dh-card-pet-extra__detail dh-flex-responsive">
          <p className="detail-title">
            <strong>Antecedentes médicos:</strong>
          </p>
          <p>{pet?.medical_history}</p>
        </div>
        <div className="dh-card-pet-extra__detail dh-flex-responsive">
          <p className="detail-title">
            <strong>Medicinas:</strong>
          </p>
          <p>{pet?.medicine}</p>
        </div>
        <div className="dh-card-pet-extra__detail dh-flex-responsive">
          <p className="detail-title">
            <strong>Alimentación:</strong>
          </p>
          <p>{pet?.feeding}</p>
        </div>
        <div className="dh-card-pet-extra__detail dh-flex-responsive">
          <p className="detail-title">
            <strong>Adicional:</strong>
          </p>
          <p>{pet?.additional}</p>
        </div>
      </div>
    </DogLayout>
  );
};

export default PetDetail;
