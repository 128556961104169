import React, { useState } from 'react'

function useFilterClients(clients) {
  const [values, setValues] = useState({
    email_true: false,
    email_false: false,
    phone_true: false,
    phone_false: false
  })
  const [ filteredClients, setFilteredClients ] = useState(clients)

  const handleValuesChange = name => event => {
    setValues({ ...values, [name]: event.target.checked })
  }

  const filterEmailConfirmed = (clients) => {
    return (
      (
        values.email_true && `${clients.email_confirmed}`.includes(1)
      ) || (
        values.email_false && `${clients.email_confirmed}`.includes(0)
      ) || (
        (!values.email_true && !values.email_false) && clients
      )
    )
  }

  const filterPhoneConfirmed = (clients) => {
    return (
      (
        values.phone_true && `${clients.confirmation}`.includes(1)
      ) || (
        values.phone_false && `${clients.confirmation}`.includes(0)
      ) || (
        (!values.phone_true && !values.phone_false) && clients
      )
    )
  }

  React.useMemo(() => {
    const result = clients.filter(filterEmailConfirmed).filter(filterPhoneConfirmed)
    setFilteredClients(result)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ clients, values ])

  return { values, handleValuesChange, filteredClients }
}

export default useFilterClients
