import React, { useEffect, useState } from 'react';
import './adminServiceDetail.scss';

import * as SERVICES from 'services/config';
import * as OPTIONS from '@root/services/options';
import { variantCityByCountry } from 'utils/Resources';
// Components
import Notification from 'components/notification';
import BadgeLevel from 'components/badgeLevel/BadgeLevel';
import AreaServiceData from 'components/AreaServiceData';
import TitleArrowBack from '@root/components/TitleBack';

function AdminServiceDetail(props) {
  const id = props.match.params.id;
  // Variables del componente "Notification"
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  // Variables propias del componete
  const [loading, setLoading] = useState(false);
  // Variables de la data de "Servicio"
  const [service, setService] = useState({
    name: '',
    type_id: 1,
    houser_comission: '',
    gross_comission: '',
    money_id: 3,
    tax_id: 1,
    houser_level_id: 1,
    country: 'País',
    city: 'Ciudad',
  });
  const [money, setMoney] = useState('');
  const [price, setPrice] = useState({
    total: '',
    total_net: '',
  });
  // Array de las ciudades escogidas por el Usuario
  const [cityArray, setCityArray] = useState([]);

  const handleBackClick = () => {
    props.history.goBack();
  };

  // Funcion para cerrar el Componente 'Notification'
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  const handleServiceChange = (name) => (event) => {
    setService({
      ...service,
      [name]: event.target.value,
    });
    if (name === 'country') {
      setCityArray(variantCityByCountry[event.target.value]);
    }
  };

  useEffect(() => {
    fetchService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchService = async () => {
    setLoading(true);
    try {
      const response = await fetch(SERVICES.GET_SERVICE_BY_ID + id, OPTIONS.GET());
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setLoading(false);
        setService(data.service);
        setMoney(data.service.money.symbol);
        let priceTotal =
          parseInt(data.service.gross_comission, 10) +
          parseInt(data.service.houser_comission, 10);
        let priceTotalBrut = parseInt(data.service.gross_comission, 10);
        let priceTotalNet =
          priceTotalBrut - priceTotalBrut * (parseInt(data.service.tax.percentage, 10) / 100);
        if (priceTotal && priceTotalNet && priceTotalBrut) {
          setPrice({
            total: priceTotal,
            total_net: priceTotalNet,
          });
        }
        setCityArray(variantCityByCountry[data.service.country]);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      id: service.id,
      name: service.name,
      type_id: service.type_id,
      houser_comission: service.houser_comission,
      gross_comission: service.gross_comission,
      money_id: service.money_id,
      tax_id: service.tax_id,
      houser_type_id: 1,
      houser_level_id: service.houser_level_id,
      country: service.country,
      city: service.city,
    };
    sendData(SERVICES.UPDATE_SERVICE, OPTIONS.POST(body));
  };

  const sendData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      // eslint-disable-next-line no-unused-vars
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setMessage('Se Actualizo correctamente');
        setMessageVariant('success');
        setMessageState(true);
        fetchService();
      } else {
        setMessage('Error al actualizar');
        setMessageVariant('error');
        setMessageState(true);
      }
    } catch (error) {
      setMessage('Error en los datos: ' + error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <div className="dh-admin-service-detail">
      <div className="margin-bottom-x15">
        <TitleArrowBack handleClick={handleBackClick}>
          <h2 className="admin-title">{loading ? '...' : service.name}</h2>
        </TitleArrowBack>
      </div>
      <div className="card-service-sumary card-elevation">
        <section className="card-service-sumary__card-body">
          <div className="card-service-sumary__card-body__stats">
            <p>Tipo de servicio</p>
            {service.service_type && <h5>{service.service_type.name}</h5>}
          </div>
          <div className="card-service-sumary__card-body__stats">
            <p>Ubicación</p>
            <h5>
              {service.city}, {service.country}
            </h5>
          </div>
          <div className="card-service-sumary__card-body__stats">
            {service.money && (
              <React.Fragment>
                <p>Moneda ({service.money.abreviated})</p>
                <h5>{service.money.divisa}</h5>
              </React.Fragment>
            )}
          </div>
          <div className="card-service-sumary__card-body__stats">
            {service.tax && (
              <React.Fragment>
                <p>
                  {service.tax.type} ({service.tax.country_code})
                </p>
                <h5>{service.tax.percentage}%</h5>
              </React.Fragment>
            )}
          </div>
          <div className="card-service-sumary__card-body__stats">
            <p>Nivel Cuidador</p>
            <BadgeLevel level={service.houser_level_id} />
          </div>
          <div className="card-service-sumary__card-body__stats">
            <p>Ingreso Total</p>
            <h5>
              {money} {price.total}
            </h5>
          </div>
          <div className="card-service-sumary__card-body__stats">
            <p>Cuidador/Proveedor</p>
            <h5>
              {money} {service.houser_comission}
            </h5>
          </div>
          <div className="card-service-sumary__card-body__stats">
            <p>Comisión Bruta</p>
            <h5>
              {money} {service.gross_comission}
            </h5>
          </div>
          <div className="card-service-sumary__card-body__stats">
            <p>Comisión Neta</p>
            <h5>
              {money} {price.total_net}
            </h5>
          </div>
        </section>
      </div>
      <div style={{ paddingTop: '1.5rem' }}>
        <AreaServiceData
          handleSubmit={handleSubmit}
          cityArray={cityArray}
          service={service}
          handleServiceChange={handleServiceChange}
          title="Actualizar servicio"
          btnText="Actualizar"
        />
      </div>
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default AdminServiceDetail;
