import React from 'react'
// Constants
import * as VARIABLES from 'constants/variables'
// DogHouser Icons
import PillIcon from 'icons/PillIcon'
import InjectableIcon from 'icons/InjectableIcon'
import CamaIcon from 'icons/CamaIcon'
import SofaIcon from 'icons/SofaIcon'
import HomeIcon from 'icons/HomeIcon'
import JardinIcon from 'icons/JardinIcon'
import TerrazaIcon from 'icons/TerrazaIcon'
import PetSmall from 'assets/icons/pet-small.svg'
import PetMedium from 'assets/icons/pet-medium.svg'
import PetBig from 'assets/icons/pet-big.svg'

const ServicesSection = props => {
  const { houser } = props
  return (
    <div className="houser-detail__section-description">
      <h3 className="dh-text-section marginx05">Descripcion del lugar</h3>
      <p>{houser.publication.description_pub}</p>
      <div className="toolbar-group">
        <div className="description-group">
          {VARIABLES.NUMBER_TO_BOOLEAN[houser.has_garden] &&
            <div className="description-group__item">
              <JardinIcon setColor="primary"/>
              <p>Tiene jardín</p>
            </div>
          }
          {VARIABLES.NUMBER_TO_BOOLEAN[houser.has_terrace] &&
            <div className="description-group__item">
              <TerrazaIcon setColor="primary"/>
              <p>Tiene terraza</p>
            </div>
          }
          {VARIABLES.NUMBER_TO_BOOLEAN[houser.pills] &&
            <div className="description-group__item">
              <PillIcon setColor="primary"/>
              <p>Puede dar pastillas</p>
            </div>
          }
          {VARIABLES.NUMBER_TO_BOOLEAN[houser.injectables] &&
            <div className="description-group__item">
              <InjectableIcon setColor="primary"/>
              <p>Aplica inyecciones</p>
            </div>
          }
        </div>
      </div>
      <h3 className="dh-text-section marginx05">Condiciones del Propietario</h3>
      <p>{houser.restrictions}</p>
      <div className="toolbar-group">
        <div className="description-group">
          {VARIABLES.NUMBER_TO_BOOLEAN[houser.allowed_house] &&
            <div className="description-group__item">
              <HomeIcon setColor="accent" />
              <p>Permite toda la casa</p>
            </div>
          }
          {VARIABLES.NUMBER_TO_BOOLEAN[houser.allowed_sofa] &&
            <div className="description-group__item">
              <SofaIcon setColor="accent"/>
              <p>Permite subir al sofá</p>
            </div>
          }
          {VARIABLES.NUMBER_TO_BOOLEAN[houser.allowed_bed] &&
            <div className="description-group__item">
              <CamaIcon setColor="accent"/>
              <p>Permite subir a la cama</p>
            </div>
          }
        </div>
      </div>
      <h3 className="dh-text-section marginx05">Tamaño de perro permitido</h3>
      <div className="toolbar-group">
        <div className="description-group">
          {houser.pet_size <= 2 &&
            <div className="description-group__item dog-sm">
              <img src={PetSmall} alt="small dog"/>
              <p>Perros pequeños</p>
            </div>
          }
          {((houser.pet_size === 1) || (houser.pet_size === 2)) &&
            <div className="description-group__item dog-md">
              <img src={PetMedium} alt="medium dog"/>
              <p>Perros medianos</p>
            </div>
          }
          {houser.pet_size === 2 &&
            <div className="description-group__item dog-lg">
              <img src={PetBig} alt="big dog"/>
              <p>Perros grandes</p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ServicesSection
