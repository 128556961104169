import React, { useContext } from "react";
import { Context } from "@root/Context";
import "./serviceDelivery.scss";
//Constants
import * as ROUTES from "constants/routes";
//Components
import CardDelivery from "components/cardDelivery";
//Material-UI
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
//Images
import ImgGuarderia1 from "assets/images/delivery/guarderia1.jpg";
import ImgGuarderia2 from "assets/images/delivery/guarderia2.jpg";
import ImgGuarderia4 from "assets/images/delivery/guarderia4.jpg";

function ServiceDelivery() {
  const { country } = useContext(Context);
  let precio1 = "No se encontro";
  let precio2 = "No se encontro";
  let precio3 = "No se encontro";
  if (country === "Perú") {
    precio1 = "S/.";
    precio2 = "S/.";
    precio3 = "S/.";
  } else if (country === "Colombia") {
    precio1 = "$";
    precio2 = "$";
    precio3 = "$";
  } else if (country === "España") {
    precio1 = "€ 15";
    precio2 = "€ 20";
    precio3 = "€ 35 / € 50";
  }

  return (
    <React.Fragment>
      <div className="dh-main-delivery-image">
        <Container maxWidth="lg">
          <div className="dh-main-delivery-image__container">
            <h1>Un houser que cuide a tu engreido en su propio ambiente</h1>
            <h3>
              Este servicio es especial para aquellos perros que no se sienten
              cómodos en ambientes extraños. Incluye todos los servicios y
              seguros de emergencia.
            </h3>
          </div>
        </Container>
      </div>
      <Container maxWidth="lg" className="margin-bottom-x3">
        <h2 className="text-big margin-top-x1 margin-bottom-x1">
          Elige un plan de delivery
        </h2>
        <Grid container spacing={3}>
          <CardDelivery
            title="Por 4hrs"
            text="Las visitas de una hora son útiles para gatos y exóticos. Los alimentamos, jugamos y limpiamos su espacio."
            image={ImgGuarderia1}
            pathName={ROUTES.LISTA_CUIDADORES}
            typeService="delivery"
            price={precio1}
            size={3}
          />
          <CardDelivery
            title="Por 8hrs"
            text="Las visitas de dos horas nos permite ver a tu mascota en dos horarios."
            image={ImgGuarderia2}
            pathName={ROUTES.LISTA_CUIDADORES}
            typeService="delivery"
            price={precio2}
            size={3}
          />
          <CardDelivery
            title="Full day"
            text="¿Quieres que nos mudemos a tu casa para que tu mascota no tenga que ir a otra?"
            image={ImgGuarderia4}
            pathName={ROUTES.LISTA_CUIDADORES}
            typeService="delivery"
            price={precio3}
            size={3}
          />
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default ServiceDelivery;
