import React from 'react';
import PropTypes from 'prop-types';
// Dependencies
import Workbook from 'react-excel-workbook';
// Constants
import * as VARIABLES from 'constants/variables';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import VerticalAlignBottomRoundedIcon from '@material-ui/icons/VerticalAlignBottomRounded';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: '1rem',
  },
  button: {
    fontFamily: 'Montserrat-Semibold',
    fontSize: '15px',
  },
}));

const VARIANT_TEXT_BADGE = {
  '0': 'No Verificado',
  '1': 'Verificado',
  '2': 'Faltan Datos',
};

const WorkbookHousers = (props) => {
  const classes = useStyles();
  const { data } = props;

  const exportData = [];

  data.forEach((res) => {
    let state = '2';
    // TODO: Fix en el lado del backend para que no devuelva usuarios que no son cuidadores
    let houser_id = 'Error';
    if (res.houser) {
      houser_id = res.houser.id;
      if (res.houser.publication) {
        state = res.houser.publication.publication_state;
      }
    }

    const item = {
      id: res.id,
      houser_id: houser_id,
      name: res.name + res.last_name,
      email: res.email,
      phone: res.phone,
      confirmation: VARIABLES.NUMBER_TO_BOOLEAN[res.confirmation],
      phone_house: res.phone_house,
      doc_id: res.doc_id,
      country: res.location ? res.location.country : 'Error',
      city: res.location ? res.location.city : 'Error',
      state: VARIANT_TEXT_BADGE[state],
    };
    exportData.push(item);
  });

  return (
    <div className={classes.content}>
      <Workbook
        filename="housers_data.xlsx"
        element={
          <Button
            className={classes.button}
            startIcon={<VerticalAlignBottomRoundedIcon />}>
            Exportar
          </Button>
        }>
        <Workbook.Sheet data={exportData} name="Reservas">
          <Workbook.Column label="ID" value="id" />
          <Workbook.Column label="HouserId" value="houser_id" />
          <Workbook.Column label="Nombre" value="name" />
          <Workbook.Column label="Correo" value="email" />
          <Workbook.Column label="Teléfono" value="phone" />
          <Workbook.Column label="Teléfono verificado" value="confirmation" />
          <Workbook.Column label="Teléfono de casa" value="phone_house" />
          <Workbook.Column label="Doc. ID" value="doc_id" />
          <Workbook.Column label="País" value="country" />
          <Workbook.Column label="Ciudad" value="city" />
          <Workbook.Column label="Estado" value="state" />
        </Workbook.Sheet>
      </Workbook>
    </div>
  );
};

WorkbookHousers.propTypes = {
  data: PropTypes.array.isRequired,
};

export default WorkbookHousers;
