import React from 'react'
import { Link as RouterLink } from "react-router-dom";
import './cardUserSummary.scss'
// Constants
import * as VARIABLES from 'constants/variables'
// Components
import BadgeSimpleRole from '../badgeSimpleRole/BadgeSimpleRole';
import BadgeStatusSimple from '../badgeStatusSimple/BadgeStatusSimple';
// Material - UI
import Skeleton from '@material-ui/lab/Skeleton';
// Material - Icons
import CreateIcon from '@material-ui/icons/Create';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

function CardUserSummary(props) {
  const { loading, toRoute, user, total, location, fetchValidateNumber } = props

  return (
    <div className="card-user-sumary dh-card-elevation">
      {!loading ? (
        <React.Fragment>
          <div className="card-user-sumary__header">
            <div className="card-user-sumary__header__user">
              <div className="header-user-image">
                <img
                  src={VARIABLES.IMAGE_URL + user.photo}
                  alt={user.name}/>
              </div>
              <div className="header-user-title">
                <h3>{user.name} {user.last_name}</h3>
                <BadgeSimpleRole role={user.rol}/>
              </div>
            </div>
            <div className="card-user-sumary__header__actions">
              {user.confirmation ? (
                <button className="btn-danger" onClick={() => fetchValidateNumber(0)}>
                  <BlockRoundedIcon/>
                  Desactivar Numero
                </button>
              ) : (
                <button className="btn-success" onClick={() => fetchValidateNumber(1)}>
                  <CheckRoundedIcon/>
                  Verificar Numero
                </button>
              )}
              <RouterLink to={toRoute}>
                <button className="btn-primary border margin-left-x1">
                  <CreateIcon/>
                  Editar
                </button>
              </RouterLink>
            </div>
          </div>
          <section className="card-body">
            {total && (<div className="card-body__stats">
              <p>Ganancias Totales</p>
              <h5>
                {VARIABLES.CURRENCY_BY_COUNTRY[location.country]}
                {total.toLocaleString()}
              </h5>
            </div>)}
            <div className="card-body__stats">
              <p>Ubicación</p>
              <h5>{location.city}, {location.country}</h5>
            </div>
            <div className="card-body__stats">
              <p>Distrito</p>
              <h5>{location.district}</h5>
            </div>
            <div className="card-body__stats">
              <div className="stats-status">
                <p>Teléfono</p>
                <BadgeStatusSimple status={user.confirmation}/>
              </div>
              <h5>{user.phone}</h5>
            </div>
            <div className="card-body__stats">
              <p>Teléfono de casa</p>
              <h5>{user.phone_house}</h5>
            </div>
            <div className="card-body__stats">
              <p>Teléfono de emergencia</p>
              <h5>{user.emergency_contact_phone}</h5>
            </div>
            <div className="card-body__stats">
              <div className="stats-status">
                <p>Correo</p>
                <BadgeStatusSimple status={user.email_confirmed}/>
              </div>
              <h5>{user.email}</h5>
            </div>
            <div className="card-body__stats">
              <p>Doc. identidad</p>
              <h5>{user.doc_id}</h5>
            </div>
            <div className="card-body__stats">
              <p>Fecha de Nacimiento</p>
              <h5>{user.birth_date}</h5>
            </div>
          </section>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="card-user-sumary__header">
            <div className="card-user-sumary__header__user">
              <div className="header-user-image">
                <Skeleton variant="circle" width="100%" height="100%" />
              </div>
              <div className="header-user-title">
                <Skeleton height={10} width="88px" style={{ marginBottom: 6 }} />
                <Skeleton height={10} width="40%" />
              </div>
            </div>
          </div>
          <section className="card-body">
            {Array.from(new Array(7)).map((data, index) => {
              return (
                <div className="card-body__stats" key={index}>
                  <Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />
                  <Skeleton height={10} width="40%" />
                </div>
              )
            })}
          </section>
        </React.Fragment>
      )}
    </div>
  )
}

export default CardUserSummary
