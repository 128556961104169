import React from 'react'
// Components
import HeaderHouser from './components/headerHouser/HeaderHouser'
import Footer from 'components/footer/Footer';

function LayoutHouser(props) {
  const pathname = props.children.props.location.pathname
  return (
    <React.Fragment>
      <HeaderHouser location={pathname}/>
      <div className="layout-space">
        {props.children}
      </div>
      <Footer/>
    </React.Fragment>
  )
}

export default LayoutHouser
