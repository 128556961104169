import React from 'react'
import PropTypes from 'prop-types'
import './cardPets.scss'
// Constants
import * as VARIABLES from 'constants/variables';
// Material-UI
import Avatar from '@material-ui/core/Avatar'

const PetItem = props => {
  const { title, image } = props
  return (
    <div className="admin-grid-pets__body--item">
      <Avatar alt={title} src={`${VARIABLES.IMAGE_URL}${image}`}/>
      <div>
        <p>{title}</p>
      </div>
    </div>
  )
}

PetItem.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string
}

const CardPets = props => {
  const { pets } = props
  return (
    <div className="admin-grid-pets dh-card-elevation">
      <div className="admin-grid-pets__header">
        <span>Mascotas</span>
      </div>
      <div className="admin-grid-pets__toolbar">
        {(pets.length > 0) ? (
          <div className="admin-grid-pets__body">
            {pets.map(pet => (
              <PetItem
                key={pet.id}
                title={pet.name}
                image={pet.photo}
              />
            ))}
          </div>
        ) : (
          <div className="admin-grid-pets__body">
            <h4>No se encontraron mascotas</h4>
          </div>
        )}
      </div>
    </div>
  )
}

CardPets.propTypes = {
  loading: PropTypes.bool,
  pets: PropTypes.array.isRequired,
}

export default CardPets
