import React from 'react'
import { Link as RouterLink } from "react-router-dom";
import './construction.scss';
// Images
import DoghouserIco from 'assets/dg_logo_text.png'
import Dog from 'assets/images/dog2.png'
import IcPeru from 'assets/icons/ic-peru@color.png'
import IcColombia from 'assets/icons/ic-colombia@color.png'
import IcSpain from 'assets/icons/ic-spain@color.png'
// Material Iconos
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Material-UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

function Construction(props) {

  const handleBackClick = () => {
    props.history.goBack()
  }

  return (
    <Container maxWidth="lg" className="construction-container">
      <Grid container spacing={3} className="construction-grid">
        <Grid item xs={12} md={6} className="construction-body">
          <RouterLink to="/"><img className="logo" src={DoghouserIco} alt="Logo"/></RouterLink>
          <div className="construction-body__center">
            <h1>En reconstrucción</h1>
            <p>Nuestro sitio web se encuentra actualmente en mantenimiento. Deberíamos volver en breve. Gracias por su paciencia.</p>
            <p>Para más información, puede llamar a:</p>
            <div>
              <List>
                <ListItem className="country-item">
                  <ListItemAvatar className="country-avatar">
                    <img src={IcPeru} alt="Colombia" />
                  </ListItemAvatar>
                  <ListItemText className="country-text">
                    <p>995 759 034</p>
                    <p>985 371 105</p>
                  </ListItemText>
                </ListItem>
                <ListItem className="country-item">
                  <ListItemAvatar className="country-avatar">
                    <img src={IcColombia} alt="Colombia" />
                  </ListItemAvatar>
                  <ListItemText className="country-text">
                    <p>305 2302617</p>
                  </ListItemText>
                </ListItem>
                <ListItem className="country-item">
                  <ListItemAvatar className="country-avatar">
                    <img src={IcSpain} alt="Colombia" />
                  </ListItemAvatar>
                  <ListItemText className="country-text">
                    <p>633053593</p>
                  </ListItemText>
                </ListItem>
              </List>
            </div>
            <Button variant="contained" color="secondary" className="button" onClick={handleBackClick}>
              <ArrowBackIcon/>
              Volver
            </Button>
          </div>
        </Grid>
        <Grid item xs={false} md={6} className="construction-image">
          <img src={Dog} alt="Dog" />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Construction
