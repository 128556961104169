import React from 'react'
import PropTypes from 'prop-types'
import './reservationStatus.scss'
// Constants
import * as VARIABLES from 'constants/variables'

export const STYLE_STATUS = {
  1: 'status1',
  2: 'status2',
  3: 'status3',
  4: 'status4',
  5: 'status5',
  6: 'status6',
  7: 'status7',
}

const ReservationStatus = props => {
  const { status, endDate } = props

  const getStatus = (state, endDate) => {
    if (state === 3) {
      let fechaActual = new Date().getTime()
      let fechaRes = new Date(endDate).getTime()
      if((fechaActual + 1) > (fechaRes + 1)) {
        return 7;
      } else {
        return state;
      }
    }
    return state;
  }

  let relStatus = getStatus(status, endDate)

  return (
    <div className={`reservation-status ${STYLE_STATUS[relStatus]}`}>
      <span>{VARIABLES.RESERVATION_STATUS[relStatus]}</span>
    </div>
  )
}

ReservationStatus.propTypes = {
  status: PropTypes.number
}

export default ReservationStatus
