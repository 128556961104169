import { useEffect, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';

const TimePicker = (props) => {
  const { defaultValue, onChange } = props;
  const [timeOptions, setTimeOptions] = useState([]);
  const [defaultTime, setDefaultTime] = useState({
    value: '08:00 AM',
    label: '08:00 AM',
  });
  useEffect(() => {
    const isEarlierThanEndLimit = (timeValue, endLimit, lastValue) => {
      let timeValueIsEarlier =
        moment(timeValue, 'hh:mm A').diff(moment(endLimit, 'hh:mm A')) < 0;
      let timeValueIsLaterThanLastValue =
        lastValue === undefined
          ? true
          : moment(lastValue, 'hh:mm A').diff(moment(timeValue, 'hh:mm A')) < 0;
      return timeValueIsEarlier && timeValueIsLaterThanLastValue;
    };
    let timeValue = props.beginLimit || '07:00 AM';
    let lastValue;
    let endLimit = props.endLimit || '10:00 PM';
    let step = props.step || 30;

    let options = [];
    options.push({ value: timeValue, label: timeValue });
    while (isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
      lastValue = timeValue;
      timeValue = moment(timeValue, 'hh:mm A').add(step, 'minutes').format('hh:mm A');
      options.push({ value: timeValue, label: timeValue });
    }
    options.pop();
    setTimeOptions(options);

    if (defaultValue) {
      setDefaultTime({ value: defaultValue, label: defaultValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <Select
      onChange={(n) => onChange(n.value)}
      options={timeOptions}
      value={defaultTime}
      styles={{
        control: (provided, state) => ({
          ...provided,
          width: '100%',
          minWidth: '166px',
          height: 43,
          borderColor: '#5a5655',
        }),
      }}
    />
  );
};

export default TimePicker;
