import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';

import {
  SnackbarVariant,
  SnackbarSeverity,
  SnackbarTransition,
} from '@root/interfaces/SnackbarType';
import { SNACKBAR_OPEN } from '@root/store/actions';
import { SnackbarOpenAction } from '@root/store/snackbarReducer';

export interface ArgsProps {
  message: ReactNode | string;
  icon?: ReactNode;
  anchorOrigin?: SnackbarOrigin;
  variant?: SnackbarVariant;
  severity?: SnackbarSeverity;
  transition?: SnackbarTransition;
  duration?: number;
  close?: boolean;
  actionButton?: boolean;
}

type ConfigContent = ReactNode | string;
type ConfigDuration = number | (() => void);
type JointContent = ConfigContent | ArgsProps;

export interface MessageInstance {
  info(content: JointContent, variant?: SnackbarVariant, duration?: ConfigDuration): void;
  success(content: JointContent, variant?: SnackbarVariant, duration?: ConfigDuration): void;
  error(content: JointContent, variant?: SnackbarVariant, duration?: ConfigDuration): void;
  warning(content: JointContent, variant?: SnackbarVariant, duration?: ConfigDuration): void;
  // loading(content: JointContent, variant?: SnackbarVariant, duration?: ConfigDuration): void;
}

function isArgsProps(message: JointContent): message is ArgsProps {
  return (
    Object.prototype.toString.call(message) === '[object Object]' &&
    !!(message as ArgsProps).message
  );
}

const useSnackbar = () => {
  const dispatch = useDispatch();

  const setMessageConfig = (content: ArgsProps) => {
    return dispatch<SnackbarOpenAction>({
      type: SNACKBAR_OPEN,
      open: true,
      close: false,
      ...content,
    });
  };

  function attachTypeApi(
    content: JointContent,
    variant: SnackbarVariant,
    severity: SnackbarSeverity,
  ) {
    if (isArgsProps(content)) {
      return setMessageConfig({
        ...content,
        variant: variant,
      });
    }

    return setMessageConfig({
      message: content,
      variant: variant,
      severity: severity,
    });
  }

  const error = (content: JointContent, variant: SnackbarVariant) => {
    attachTypeApi(content, variant, 'error');
  };

  const info = (content: JointContent, variant: SnackbarVariant) => {
    attachTypeApi(content, variant, 'info');
  };

  const success = (content: JointContent, variant: SnackbarVariant) => {
    attachTypeApi(content, variant, 'success');
  };

  const warning = (content: JointContent, variant: SnackbarVariant) => {
    attachTypeApi(content, variant, 'warning');
  };

  const createMessage: MessageInstance = {
    error,
    info,
    success,
    warning,
  };

  return createMessage;
};

export default useSnackbar;
