import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import './userProfileSideList.scss';
// Constants
import * as ROUTES from '../../constants/routes';
import * as VARIABLES from '../../constants/variables';
// Material-UI
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import List from '@material-ui/core/List';

function UserProfileSideList(props) {
  const { userPhoto, userName, client, houser } = props
  let pathProfile = ROUTES.CLIENTE_PERFIL

  if (houser) {
    pathProfile = ROUTES.CUIDADOR_PERFIL
  } else if (client) {
    pathProfile = ROUTES.CLIENTE_PERFIL
  }

  return (
    <List>
      <RouterLink to={pathProfile} className="dh-no-decoration">
        <div className="dh-profile-summary">
          <div className="dh-profile-summary__ico">
            {userPhoto ? (
              <Avatar className="profile-image" alt={userName} src={`${VARIABLES.IMAGE_URL}${userPhoto}`} />
            ) : (
              <Skeleton className="profile-image" variant="circle" width={40} height={40} />
            )}
          </div>
          <div className="dh-profile-summary__name">
            {userName &&
              <p className="name">{userName}</p>
            }
            <p>Ver Perfil</p>
          </div>
        </div>
      </RouterLink>
    </List>
  )
}

export default UserProfileSideList
