import React, { useState, useContext, useEffect } from 'react';
import './servicePetFriendly.scss';
import { Context } from '@root/Context';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables';
// Utils
import { typesPlaces } from 'utils/Resources';
// Dependencies
import GoogleMapReact from 'google-map-react';
import Select from 'react-select';
// Hooks
import { useLocationManagement } from 'hooks/useLocationManagement';
import useNotificationValue from 'hooks/useNotificationValue';
// Components
import Notification from 'components/notification';
import ManageLocation from 'components/manageLocation';
import { CardPetFriendly, MarkerDesktop } from './components';
// Material UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

/* TODO: Modificar la respuesta del enpoint, para poder paginar los items, esto mejoraria el render de esta vista */

const listPlaces = (loading, places) => (
  <React.Fragment>
    {!loading ? (
      <Grid container spacing={0} style={{ marginBottom: '1.5rem' }}>
        {places.map((place, index) => (
          <Grid item xs={6} sm={3} md={2} key={index}>
            <CardPetFriendly
              title={place.title}
              text={`${place.location.district} - ${place.location.city}`}
              image={place.image_card}
              pathName={`petfriendly/${place.id}/${place.location.country}/${place.location.city}`}
            />
          </Grid>
        ))}
      </Grid>
    ) : (
      <Grid container spacing={3}>
        <React.Fragment>
          {Array.from(new Array(6)).map((place, index) => (
            <Grid item xs={6} sm={3} md={2} key={index}>
              <Skeleton variant="rect" height="230px" className="dh-card-elevation" />
              <Skeleton
                variant="rect"
                width="90%"
                height={14}
                style={{ marginTop: 10 }}></Skeleton>
              <Skeleton
                variant="rect"
                width="60%"
                height={14}
                style={{ marginTop: 8 }}></Skeleton>
            </Grid>
          ))}
        </React.Fragment>
      </Grid>
    )}
  </React.Fragment>
);

function ServicePetFriendly() {
  // Ciudad y País del local storage
  const { country, city } = useContext(Context);
  const [loading, setLoading] = useState(true);
  // Variables de "Notification"
  const notification = useNotificationValue();

  const [places, setPlaces] = useState([]);
  // Lugares - Centros Comerciales
  const [placesTypeComercial, setPlacesTypeComercial] = useState([]);
  // Lugares - Restaurantes
  const [placesTypeRestaurante, setPlacesTypeRestaurante] = useState([]);
  // Lugares - Cafeterias
  const [placesTypeCafeteria, setPlacesTypeCafeteria] = useState([]);

  const [typeView, setTypeView] = useState('listado');
  const center = useLocationManagement(country, city, {
    lat: -12.102292,
    lng: -77.035504,
  });
  const zoom = 14;
  const [values, setValues] = useState({
    type: 'Restaurantes',
  });

  const handleValueChange = (name) => (event) => {
    setValues({ ...values, [name]: event.value });
    if (event.value === 'Restaurantes') {
      setPlaces(placesTypeRestaurante);
    } else if (event.value === 'Cafeterías') {
      setPlaces(placesTypeCafeteria);
    } else if (event.value === 'Comercios') {
      setPlaces(placesTypeComercial);
    }
  };

  // Función que controla el tipo de vista de "listado" o "mapa"
  const handleTypeView = (e) => {
    setTypeView(e.target.value);
  };

  // Función que lista los lugares a través de petición al servidor
  const fetchPlaces = async (country, city) => {
    try {
      const response = await fetch(
        `${SERVICES.GET_PETFRIENDLY_PLACE}/${country}/${city}`,
        OPTIONS.GET(),
      );
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (data.pet_friendly_places.length > 0) {
          // Filtrando por Tipo de Lugar
          const placeComercial = data.pet_friendly_places.filter(
            (place) => place.pet_place_type_id === 1,
          );
          const placeRestaurante = data.pet_friendly_places.filter(
            (place) => place.pet_place_type_id === 2,
          );
          const placeCafeteria = data.pet_friendly_places.filter(
            (place) => place.pet_place_type_id === 3,
          );
          setPlacesTypeComercial(placeComercial);
          setPlacesTypeRestaurante(placeRestaurante);
          setPlacesTypeCafeteria(placeCafeteria);
          setPlaces(placeRestaurante);
          setLoading(false);
        } else {
          notification.handleSnackbar('No se encontró lugares.', 'error');
          setPlacesTypeComercial([]);
          setPlacesTypeRestaurante([]);
          setPlacesTypeCafeteria([]);
          setPlaces([]);
          setLoading(false);
        }
      } else {
        notification.handleSnackbar('No se encontró lugares.', 'error');
        setLoading(false);
      }
    } catch (e) {
      notification.handleSnackbar(`Error: ${e.message}`, 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (country && city) {
      fetchPlaces(country, city);
    } else {
      fetchPlaces('Perú', 'Lima');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  return (
    <div className="dh-petfriendly">
      <div className="dh-petfriendly__main-image">
        <Container maxWidth="lg">
          <div className="dh-petfriendly__main-image__container">
            <h1>Descubre los lugares petfriendly en tu país</h1>
            <h3>Experiencias y lugares para disfrutar junto a tu mascota</h3>
          </div>
        </Container>
      </div>
      <Container maxWidth="lg" className="margin-top-x1 margin-bottom-x3">
        <div className="dh-flex-responsive content-space items-center">
          <ManageLocation />
          <div className="toggle-buttons">
            <button
              value="listado"
              className="btn btn-toggle-list-left"
              onClick={handleTypeView}>
              Listado
            </button>
            <button value="mapa" className="btn btn-toggle-list-right" onClick={handleTypeView}>
              Mapa
            </button>
          </div>
        </div>
        {typeView === 'listado' && (
          <React.Fragment>
            <div className="dh-petfriendly__title-section">
              <h2 className="text-big">Restaurantes</h2>
            </div>
            {listPlaces(loading, placesTypeRestaurante)}
            <div className="dh-petfriendly__title-section">
              <h2 className="text-big">Cafeterías</h2>
            </div>
            {listPlaces(loading, placesTypeCafeteria)}
            <div className="dh-petfriendly__title-section">
              <h2 className="text-big">Comercios</h2>
            </div>
            {listPlaces(loading, placesTypeComercial)}
          </React.Fragment>
        )}
        {typeView === 'mapa' && (
          <React.Fragment>
            <div style={{ padding: '0.8rem 0', maxWidth: '240px' }}>
              <Select
                value={values.type}
                onChange={handleValueChange('type')}
                placeholder={values.type}
                options={typesPlaces}
              />
            </div>
            <div className="dh-petfriendly__map-container">
              <GoogleMapReact
                bootstrapURLKeys={{ key: VARIABLES.GOOGLE_MAP_KEY }}
                center={center.value}
                defaultZoom={zoom}>
                {places.map((place, index) => (
                  <MarkerDesktop
                    key={index}
                    lat={place.location.latitude}
                    lng={place.location.longitude}
                    id={place.id}
                    city={place.location.city}
                    country={place.location.country}
                    adress={place.adress}
                    price={place.price_range}
                    schedule={place.schedule}
                    title={place.title}
                    photo={place.image_detail}
                  />
                ))}
              </GoogleMapReact>
            </div>
          </React.Fragment>
        )}
      </Container>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default ServicePetFriendly;
