import React, { useState, useEffect } from 'react';
import './GridBankAccount.scss';
// Services
import * as OPTIONS from '@root/services/options';
import * as SERVICES from '@root/services/config';
// Components
import Notification from '@root/components/notification/Notification';
import CardMethodPayment from '@root/components/cardMethodPayment/CardMethodPayment';
// Material-UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Skeleton from '@material-ui/lab/Skeleton';
// DogHouser Icons
import AddIcon from '@root/assets/icons/AddIcon';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function GridBankAccount(props) {
  const { userId, houserId } = props;
  const [loading, setLoading] = useState(false);
  // Variantes del componente "Notification"
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  //Estado de los diálogos de pagos
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState({
    id: '',
    bank_name: '',
    bank_card_number: '',
    bank_cci: '',
    houser_id: '',
  });
  const [newPayment, setNewPayment] = useState({
    new_bank_name: '',
    new_bank_card_number: '',
    new_bank_cci: '',
  });

  // Funcion para cerrar el Componente 'Notification'
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  // Función que abre el diálogo editar cuenta bancaria
  const handleEditDialogOpen = (data) => {
    setSelectedBankAccount({
      id: data.id,
      bank_name: data.bank_name,
      bank_card_number: data.bank_card_number,
      bank_cci: data.bank_cci,
      houser_id: data.houser_id,
    });
    setOpenEditDialog(true);
  };

  // Función que abre el diálogo de creación de cuenta bancaria
  const handleCreateDialogOpen = () => {
    setOpenCreateDialog(true);
  };

  // Funcion para cerrar el diálogo editar cuenta bancaria
  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
  };

  // Función que cierra el diálogo para la creación de cuenta bancaria
  const handleCreateDialogClose = () => {
    setOpenCreateDialog(false);
  };

  const handleEditInputChange = (e) => {
    setSelectedBankAccount({
      ...selectedBankAccount,
      [e.target.name]: e.target.value,
    });
  };

  const handleDialogChangeOnCreate = (e) => {
    setNewPayment({
      ...newPayment,
      [e.target.name]: e.target.value,
    });
  };

  // Función que borra la cuenta bancaria
  const handleDeleteBankAccount = (data) => {
    // console.log(data)
    setMessageVariant('info');
    setMessage('Acción no disponible por el momento.');
    setMessageState(true);
  };

  useEffect(() => {
    setLoading(true);
    setMessage('');
    setMessageVariant('');
    setMessageState(false);
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houserId]);

  const fetchPayments = async () => {
    const body = {
      id: houserId,
    };

    try {
      const response = await fetch(SERVICES.GET_ALL_PAYMENT, OPTIONS.POST(body));
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setBankAccounts(data.payments);
        setLoading(false);
      } else {
        // setMessage('Error al obtener los métodos de pago')
        // setMessageVariant('warning')
        // setMessageState(true)
        setLoading(false);
      }
    } catch (error) {
      setMessage('Error: ' + error.message);
      setMessageVariant('error');
      setMessageState(true);
      setLoading(false);
    }
  };

  const handleCreatePayment = async () => {
    let errorMessage = 'Error al crear la cuenta bancaria';
    let successMessage = 'Datos creados';
    const body = {
      id: userId,
      bank_name: newPayment.new_bank_name,
      bank_card_number: newPayment.new_bank_card_number,
      bank_cci: newPayment.new_bank_cci,
    };
    sendData(
      SERVICES.CREATE_PAYMENT,
      OPTIONS.POST(body),
      successMessage,
      errorMessage,
      handleCreateDialogClose,
    );
  };

  const handleSubmitUpdatePayment = async () => {
    let errorMessage = 'Error al actualizar los datos';
    let successMessage = 'Datos actualizados';
    const body = {
      payment_id: selectedBankAccount.id,
      houser_id: houserId,
      bank_name: selectedBankAccount.bank_name,
      bank_card_number: selectedBankAccount.bank_card_number,
      bank_cci: selectedBankAccount.bank_cci,
    };

    sendData(
      SERVICES.UPDATE_PAYMENTS,
      OPTIONS.POST(body),
      successMessage,
      errorMessage,
      handleEditDialogClose,
    );
  };

  const sendData = async (url, options, successMessage, errorMessage, handleDialogClose) => {
    try {
      const response = await fetch(url, options);
      // eslint-disable-next-line no-unused-vars
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        handleDialogClose();
        fetchPayments();
        setMessage(successMessage);
        setMessageVariant('success');
        setMessageState(true);
      } else {
        handleDialogClose();
        setMessage(errorMessage);
        setMessageVariant('error');
        setMessageState(true);
      }
    } catch (error) {
      handleDialogClose();
      setMessage('Error: ' + error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <React.Fragment>
      <div className="dh-grid-bank-account">
        <Grid container spacing={3}>
          {!loading ? (
            <React.Fragment>
              {bankAccounts.map((payment, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                  <CardMethodPayment
                    bankName={payment.bank_name}
                    cardNumber={payment.bank_card_number}
                    bankCci={payment.bank_cci}
                    handleClickOpen={() => handleEditDialogOpen(payment)}
                    handleDelete={() => handleDeleteBankAccount(payment)}
                  />
                </Grid>
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {Array.from(new Array(3)).map((data, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                  <Skeleton variant="rect" height="205px" className="dh-card-border" />
                </Grid>
              ))}
            </React.Fragment>
          )}
          <Grid item xs={12} sm={6} md={6}>
            <div className="dh-grid-bank-account__new-payment" onClick={handleCreateDialogOpen}>
              <AddIcon color="#bdbdbd" />
            </div>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={openCreateDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCreateDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">{'Nuevo método de pago'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="new_bank_name"
            name="new_bank_name"
            label="Nombre del banco"
            onChange={handleDialogChangeOnCreate}
            type="text"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="new_bank_card_number"
            name="new_bank_card_number"
            label="Número de la tarjeta"
            onChange={handleDialogChangeOnCreate}
            type="text"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="new_bank_cci"
            name="new_bank_cci"
            label="Número CCI"
            onChange={handleDialogChangeOnCreate}
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleCreatePayment} color="primary">
            Crear
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={handleEditDialogClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Editar datos</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="bank_name"
            name="bank_name"
            label="Nombre del banco"
            value={selectedBankAccount.bank_name}
            onChange={handleEditInputChange}
            type="text"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="bank_card_number"
            name="bank_card_number"
            label="Número de la tarjeta"
            value={selectedBankAccount.bank_card_number}
            onChange={handleEditInputChange}
            type="text"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="bank_cci"
            name="bank_cci"
            label="Número CCI"
            value={selectedBankAccount.bank_cci}
            onChange={handleEditInputChange}
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmitUpdatePayment} color="primary">
            Editar
          </Button>
        </DialogActions>
      </Dialog>
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </React.Fragment>
  );
}

export default GridBankAccount;
