import { AxiosResponse } from 'axios';

import axios from '@root/utils/axios';

const responseBody = (response: AxiosResponse) => {
  return { status: response.status, ...response.data };
};

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  postForm: (url: string, body: {}) => axios.postForm(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
};

export default requests;
