import React, { useState, useEffect } from 'react';
import './adminClientDetail.scss';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Components
import TitleArrowBack from '@root/components/TitleBack';
import Notificacion from 'components/notification';
import BadgeStatus from 'components/badgeStatus';
import BadgeRole from 'components/badgeRole';
import CardUserSummary from 'components/cardUserSummary';
// Material-UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';

function AdminClientDetail(props) {
  const id = props.match.params.id;
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  const [data, setData] = useState({
    user: {
      id: '',
      name: '',
    },
    houser: {
      id: '',
      publication: {
        publication_state: '',
      },
    },
    location: {},
  });
  const [pets, setPets] = useState({
    status: '',
    message: '',
    pets: [],
  });

  const handleBackClick = () => {
    props.history.goBack();
  };

  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  useEffect(() => {
    setMessage('');
    setMessageState(false);
    fetchUser();
    fetchPets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Get user
  const fetchUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(SERVICES.USER_BY_ID, OPTIONS.POST({ id: id }));
      const data = await response.json();
      console.log(data);
      if (data.status === 200) {
        setLoading(false);
        setData(data);
      } else {
        setLoading(false);
        setMessage(data.message);
        setMessageVariant('error');
        setMessageState(true);
      }
    } catch (error) {
      setLoading(false);
      setMessage('Error:' + error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  const fetchPets = async () => {
    setLoading(true);
    try {
      const response = await fetch(SERVICES.PETS_BY_USER, OPTIONS.POST({ id: id }));
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        setLoading(false);
        setPets(data);
      } else {
        setLoading(false);
        // setMessage(data.message)
        // setMessageVariant('error')
        // setMessageState(true)
      }
    } catch (error) {
      setMessage(error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  const fetchValidateNumber = async (status) => {
    setMessage('');
    setMessageState(false);
    let body = {
      user_id: data.user.id,
      confirmation: status,
    };
    sendUpdateData(SERVICES.VALIDATE_PHONE_ADMIN, OPTIONS.POST(body));
  };

  const sendUpdateData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        setMessage('Datos actualizados, refresca la pagina');
        setMessageVariant('success');
        setMessageState(true);
      } else {
        setMessage(data.message);
        setMessageVariant('error');
        setMessageState(true);
      }
    } catch (error) {
      setMessage('Error:' + error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <TitleArrowBack handleClick={handleBackClick}>
          <h2 className="admin-title">{`${data.user.name} ${data.user.last_name}`}</h2>
          <BadgeRole role={data.user.rol} />
        </TitleArrowBack>
      </Grid>
      <Grid item xs={12}>
        <CardUserSummary
          loading={loading}
          toRoute={`/dh-admin/clientes/${id}/basic-info`}
          user={data.user}
          location={data.location}
          fetchValidateNumber={fetchValidateNumber}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Paper className="card-admin-user">
          <div className="card-admin-user__header">
            <span>Información de usuario</span>
          </div>
          <hr className="card-admin-user__divider" />
          <div className="card-admin-user__content">
            <table className="dh-table">
              <tbody>
                <tr>
                  <td>Correo</td>
                  <td>
                    {data.user.email}
                    <BadgeStatus status={data.user.email_confirmed} />
                  </td>
                </tr>
                <tr className="dh-selected">
                  <td>Teléfono</td>
                  <td>
                    {data.user.phone}
                    <BadgeStatus status={data.user.confirmation} />
                  </td>
                </tr>
                <tr>
                  <td>Teléfono de casa</td>
                  <td>{data.user.phone_house}</td>
                </tr>
                <tr className="dh-selected">
                  <td>Doc. identidad</td>
                  <td>{data.user.doc_id}</td>
                </tr>
                <tr>
                  <td>Dirección</td>
                  <td>{data.user.address}</td>
                </tr>
                <tr className="dh-selected">
                  <td>Cumpleaños</td>
                  <td>{data.user.birth_date}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Paper className="card-admin-user">
          <div className="card-admin-user__header">
            <span>Contacto de emergencia</span>
          </div>
          <hr className="card-admin-user__divider" />
          <div className="card-admin-user__content">
            <table className="dh-table">
              <tbody>
                <tr>
                  <td>Nombre</td>
                  <td>{data.user.emergency_contact_name}</td>
                </tr>
                <tr className="dh-selected">
                  <td>Teléfono</td>
                  <td>{data.user.emergency_contact_phone}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Paper>
        <Paper className="card-admin-user second">
          <div className="card-admin-user__header">
            <span>Cuentas asociadas</span>
          </div>
          <hr className="card-admin-user__divider" />
          <div className="card-admin-user__content">
            <table className="dh-table">
              <tbody>
                <tr>
                  <td>Facebook</td>
                  <td>{data.user.facebook_email}</td>
                </tr>
                <tr className="dh-selected">
                  <td>Google</td>
                  <td>{data.user.google_email}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Paper className="card-admin-user">
          <div className="card-admin-user__header">
            <span>Mascotas</span>
          </div>
          <hr className="card-admin-user__divider" />
          <div className="card-admin-user__content">
            <table className="dh-table">
              <tbody>
                {pets.pets.map((pet) => (
                  <tr key={pet.id}>
                    <td>
                      <div className="dh-pet-resume">
                        <Avatar
                          className="dh-pet-resume_img"
                          alt={pet.name}
                          src={`https://galery-doghouser.s3-us-west-2.amazonaws.com/${pet.photo}`}
                        />
                        <p>{pet.name}</p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Paper>
      </Grid>
      {messageState && (
        <Notificacion
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </Grid>
  );
}

export default AdminClientDetail;
