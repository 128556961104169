import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
// Constants
import * as ROUTES from 'constants/routes'
import * as VARIABLES from 'constants/variables'
// components
import BadgeTypeHouse from 'components/badgeTypeHouse'
// Images
import NoFoto from 'assets/images/ph_no_foto.png'
// Material-UI
import Skeleton from '@material-ui/lab/Skeleton'

const PublicationSummary = props => {
  const { user, loading } = props
  return (
    <div className="publication-summary">
      <div className="publication-summary__image">
        {!loading ? (
          <React.Fragment>
            {user.houser.galleries.length > 0 ? (
              <React.Fragment>
                {user.houser.galleries[0].photo ? (
                  <img src={VARIABLES.IMAGE_URL + user.houser.galleries[0].photo} alt={user.houser.publication.title} />
                ) : (
                  <img src={NoFoto} alt="Casa de cuidador"/>
                )}
              </React.Fragment>
            ) : (
              <img src={NoFoto} alt="Casa de cuidador" />   
            )}
          </React.Fragment>
        ) : (
          <Skeleton variant="rect" width="100%" height="100%" />
        )}
      </div>
      <div className="publication-summary__container">
        <div className="publication-summary__content">
          {!loading ? (
            <React.Fragment>
              {user.houser.publication ? (
                <p className="title truncate">{user.houser.publication.title}</p>
              ) : (
                <p className="title">No se Encontro</p>
              )}
              <BadgeTypeHouse 
                type_delivery={user.houser.type_delivery}
                type_exclusive={user.houser.type_exclusive}
                type_share={user.houser.type_share}
              />
              {user.location.district &&
                <p className="distric">{user.location.district}</p>
              }
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Skeleton height={8} width="80%" />
              <Skeleton height={8} width="40%" />
              <Skeleton height={8} width="40%" />
            </React.Fragment>
          )}
        </div>
        <div className="publication-summary__actions">
          <p>
            <RouterLink to={ROUTES.CUIDADOR_PUBLICACION} className="dh-no-decoration">Editar</RouterLink>
          </p>
        </div>
      </div>
    </div>
  )
}

PublicationSummary.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool
}

export default PublicationSummary
