import React from 'react'
import * as VARIABLES from 'constants/variables';
import PetsIcon from '@material-ui/icons/Pets';
import Rating from '@material-ui/lab/Rating';
import PersonIcon from '@material-ui/icons/Person'

import moment from 'moment'

import './commentItem.scss'

function CommentItem(props) {
  const { booking } = props;
  return (
    <div className="dh-card-comment">
      <div className="dh-card-comment__image">
        {booking.user.photo ? (
          <img 
            className="dh-card-comment__image__user-img"
            src={`${VARIABLES.IMAGE_URL}${booking.user.photo}`}
            alt={booking.name}
            onError={(ev) => ev.target.src = 'https://cdn2.thecatapi.com/images/888.jpg'}
          />
        ) : (
          <PersonIcon/>
        )}
      </div>
      <div className="dh-card-comment__content">
        <div className="dh-card-comment__content__header">
          <h3>{booking.user.name}</h3>
          <span>{moment(booking.feedback_at).format('MM/DD/YYYY')}</span>
        </div>
        <div className="dh-card-comment__content__body">
          <p>{booking.feedback}</p>
          <Rating
            name="customized-empty"
            value={booking.feedback_level}
            precision={0.5}
            icon={<PetsIcon fontSize="inherit" />}
            readOnly
          />
        </div>
      </div>
    </div>
  )
}

export default CommentItem