import React from 'react'
import PropTypes from 'prop-types'
// Hooks
import { usePoppoverValue } from 'hooks/usePoppoverValue';
// Material - UI
import Toolbar from '@material-ui/core/Toolbar';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// Material-Icons
import FilterListIcon from '@material-ui/icons/FilterList'

const FilterDesktop = props => {
  const { values, handleValuesChange, handleUpdateData } = props
  // Variables POPOVER para los tipo de hospedaje
  const type = usePoppoverValue('type-popover')
  // Variables POPOVER para los servicios adicionales
  const service = usePoppoverValue('service-popover')
  // Variables POPOVER para las condiciones de casa
  const condition = usePoppoverValue('condition-popover')
  // Variables POPOVER para el tamaño de los perros
  const size = usePoppoverValue('size-popover')
  // Variables POPOVER para la caracteristica de los servicios
  const feature = usePoppoverValue('feature-popover')

  return (
    <React.Fragment>
      <Toolbar className="houser-list__filter-container">
        <div className="dh-flex items-center margin-right-x1">
          <FilterListIcon/>
          <h4 style={{ margin: '0 0 0 0.5rem'}}>Filtrar por</h4>
        </div>
        <button className="btn-primary border margin-right-x1 no-hover" onClick={type.handlePopoverOpen}>
          Tipo de hospedaje
        </button>
        <button className="btn-primary border margin-right-x1 no-hover" onClick={service.handlePopoverOpen}>
          Servicios adicionales
        </button>
        <button className="btn-primary border margin-right-x1 no-hover" onClick={condition.handlePopoverOpen}>
          Condiciones de la casa
        </button>
        <button className="btn-primary border margin-right-x1 no-hover" onClick={size.handlePopoverOpen}>
          Tamaño de mascota
        </button>
        <button className="btn-primary border margin-right-x1 no-hover" onClick={feature.handlePopoverOpen}>
          Características
        </button>
      </Toolbar>
      {/* POPOVER para los tipo servicios */}
      <Popover
        id={type.id}
        open={type.open}
        className="houser-list__popover-filter"
        anchorEl={type.anchorEl}
        onClose={type.handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className="popover-title">
          <h2>Tipo de hospedaje</h2>
        </div>
        <FormControl component="fieldset" className="popover-formcontrol">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={values.type_share} onChange={handleValuesChange('type_share')} color="primary"/>}
              label="Hospedaje Compartido"
            />
            <FormControlLabel
              control={<Checkbox checked={values.type_exclusive} onChange={handleValuesChange('type_exclusive')} color="primary"/>}
              label="Hospedaje Exclusivo"
            />
            <FormControlLabel
              control={<Checkbox checked={values.type_delivery} onChange={handleValuesChange('type_delivery')} color="primary"/>}
              label="Delivery Houser"
            />
          </FormGroup>
        </FormControl>
        <div className="popover-actions">
          <button className="btn-default small no-hover">Borrar</button>
          <button className="btn-primary" onClick={handleUpdateData}>Guardar</button>
        </div>
      </Popover>
      {/* POPOVER para los servicios extra */}
      <Popover
        id={service.id}
        open={service.open}
        className="houser-list__popover-filter"
        anchorEl={service.anchorEl}
        onClose={service.handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className="popover-title">
          <h2>Servicios adicionales</h2>
        </div>
        <FormControl component="fieldset" className="popover-formcontrol">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={values.pills} onChange={handleValuesChange('pills')} color="primary"/>}
              label="Puede dar pastillas"
            />
            <FormControlLabel
              control={<Checkbox checked={values.injectables} onChange={handleValuesChange('injectables')} color="primary"/>}
              label="Puede aplicar inyectables"
            />
            <FormControlLabel
              control={<Checkbox checked={values.allowed_cats} onChange={handleValuesChange('allowed_cats')} color="primary"/>}
              label="Permite gatos"
            />
          </FormGroup>
        </FormControl>
        <div className="popover-actions">
          <button className="btn-default small no-hover">Borrar</button>
          <button className="btn-primary" onClick={handleUpdateData}>Guardar</button>
        </div>
      </Popover>
      {/* POPOVER para las condiciones de la casa */}
      <Popover
        id={condition.id}
        open={condition.open}
        className="houser-list__popover-filter"
        anchorEl={condition.anchorEl}
        onClose={condition.handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className="popover-title">
          <h2>Condiciones de la casa</h2>
        </div>
        <FormControl component="fieldset" className="popover-formcontrol">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={values.allowed_sofa} onChange={handleValuesChange('allowed_sofa')} color="primary"/>}
              label="Se admiten mascotas en los muebles"
            />
            <FormControlLabel
              control={<Checkbox checked={values.allowed_bed} onChange={handleValuesChange('allowed_sofa')} color="primary"/>}
              label="Se admiten mascotas en la cama"
            />
            <FormControlLabel
              control={<Checkbox checked={values.allowed_house} onChange={handleValuesChange('allowed_sofa')} color="primary"/>}
              label="Se permite a las mascotas toda la casa"
            />
          </FormGroup>
        </FormControl>
        <div className="popover-actions">
          <button className="btn-default small no-hover">Borrar</button>
          <button className="btn-primary" onClick={handleUpdateData}>Guardar</button>
        </div>
      </Popover>
      {/* POPOVER para el tamaño de los perros */}
      <Popover
        id={size.id}
        open={size.open}
        className="houser-list__popover-filter"
        anchorEl={size.anchorEl}
        onClose={size.handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className="popover-title">
          <h2>Tamaño de perro permitido</h2>
        </div>
        <FormControl component="fieldset" className="popover-formcontrol">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={values.dog_small} onChange={handleValuesChange('dog_small')} color="primary"/>}
              label="Pequeño"
            />
            <FormControlLabel
              control={<Checkbox checked={values.dog_medium} onChange={handleValuesChange('dog_medium')} color="primary"/>}
              label="Mediano"
            />
            <FormControlLabel
              control={<Checkbox checked={values.dog_big} onChange={handleValuesChange('dog_big')} color="primary"/>}
              label="Grande"
            />
          </FormGroup>
        </FormControl>
        <div className="popover-actions">
          <button className="btn-default small no-hover">Borrar</button>
          <button className="btn-primary" onClick={handleUpdateData}>Guardar</button>
        </div>
      </Popover>
      {/* POPOVER para los tipo de caracteristicas */}
      <Popover
        id={feature.id}
        open={feature.open}
        className="houser-list__popover-filter"
        anchorEl={feature.anchorEl}
        onClose={feature.handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className="popover-title">
          <h2>Características del servico</h2>
        </div>
        <FormControl component="fieldset" className="popover-formcontrol">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={values.has_terrace} onChange={handleValuesChange('has_terrace')} color="primary"/>}
              label="Tiene terraza"
            />
            <FormControlLabel
              control={<Checkbox checked={values.has_garden} onChange={handleValuesChange('has_garden')} color="primary"/>}
              label="Tiene jardín"
            />
          </FormGroup>
        </FormControl>
        <div className="popover-actions">
          <button className="btn-default small no-hover">Borrar</button>
          <button className="btn-primary" onClick={handleUpdateData}>Guardar</button>
        </div>
      </Popover>
    </React.Fragment>
  )
}

FilterDesktop.propTypes = {
  values: PropTypes.object.isRequired,
  handleValuesChange: PropTypes.func.isRequired,
  handleUpdateData: PropTypes.func
}

export default FilterDesktop
