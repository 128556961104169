import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

import './adminHouserDetail.scss';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
// Utils
import replacePathParams from 'utils/replacePathParams';
// Dependecies
import Iframe from 'react-iframe';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// Components
import TitleArrowBack from '@root/components/TitleBack';
import BadgeStatus from 'components/badgeStatus';
import YesOrNo from 'components/yesOrNo';
import Notification from 'components/notification';
import CardUserSummary from 'components/cardUserSummary';
import {
  CardUserLocation,
  CardEmergencyData,
  CardAssociatedAccounts,
  CardPets,
  CardHouserExam,
  GalleryPublication,
} from './components';
// DogHouser Icons
import JardinIcon from 'icons/JardinIcon';
import TerrazaIcon from 'icons/TerrazaIcon';
import DogIcon from 'icons/DogIcon';
import CatIcon from 'icons/CatIcon';
import RabbitIcon from 'icons/RabbitIcon';
import ExoIcon from 'icons/ExoIcon';
import CasaCompartida from 'icons/CasaCompartida';
import CasaExclusiva from 'icons/CasaExclusiva';
import PillIcon from 'icons/PillIcon';
import InjectableIcon from 'icons/InjectableIcon';
import CamaIcon from 'icons/CamaIcon';
import SofaIcon from 'icons/SofaIcon';
import HomeIcon from 'icons/HomeIcon';
import ServicesHouser from './ServicesHouser';

function AdminHouserDetail(props) {
  const id = props.match.params.id;
  const [loading, setLoading] = useState(false);
  // Variables de "Notification"
  const notification = useNotificationValue();

  // Variables del "Cuidador"
  const [houser, setHouser] = useState({
    id: null,
    name: '',
    houser: {
      id: null,
      publication: {
        publication_state: '',
      },
    },
    location: {},
  });
  const [sum, setSum] = useState('-');
  // Variables de Mascotas
  const [pets, setPets] = useState([]);
  // Variables de la Galeria del Usuario
  const [gallery, setGallery] = useState([]);
  // Estado de la Publicacion
  const [publicationState, setPublicationState] = useState(false);

  // Obtener datos del cuidador
  const fetchHouserData = async () => {
    let body = {
      id: id,
    };
    try {
      const response = await fetch(SERVICES.CUIDADOR_BY_ID, OPTIONS.POST(body));
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setHouser(data.user);
        setSum(data.sum);
        if (data.user.houser.publication) {
          setPublicationState(
            VARIABLES.TEXT_TO_BOOLEAN[data.user.houser.publication.publication_state],
          );
        }
        setGallery(data.user.houser.galleries);
        setLoading(false);
      } else {
        notification.handleSnackbar(data.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  // Obtener Macotas del cuidador
  const fetchPets = async () => {
    let body = {
      id: id,
    };
    try {
      const response = await fetch(SERVICES.PETS_BY_USER, OPTIONS.POST(body));
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setPets(data.pets);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar('Error:' + error.message, 'error');
      setLoading(false);
    }
  };

  // Validar publicacion del Cuidador
  const fetchValidateHouser = async (status) => {
    let body = {
      houser_id: houser.houser.id,
      aprobation: status,
    };
    sendUpdateData(SERVICES.UPDATE_PUBLICATION_STATUS, OPTIONS.POST(body));
  };

  // Validar numero del cuidador
  const fetchValidateNumber = async (status) => {
    let body = {
      user_id: houser.id,
      confirmation: status,
    };
    sendUpdateData(SERVICES.VALIDATE_PHONE_ADMIN, OPTIONS.POST(body));
  };

  const handleValidateExam = async (status) => {
    let body = {
      houser_id: houser.houser.id,
      exam_valid: status,
    };
    sendUpdateData(SERVICES.VALIDATE_TEST, OPTIONS.POST(body));
  };

  const sendUpdateData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        notification.handleSnackbar('Datos actualizados.', 'success');
        fetchHouserData();
      } else {
        notification.handleSnackbar(data.message, 'error');
      }
    } catch (error) {
      notification.handleSnackbar('Error:' + error.message, 'error');
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchHouserData();
    fetchPets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!houser.houser) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TitleArrowBack handleClick={() => props.history.goBack()}>
            <h2 className="admin-title">{`${houser.name} ${houser.last_name}`}</h2>
            {houser.houser ? (
              <BadgeStatus
                status={
                  houser.houser.publication ? houser.houser.publication.publication_state : '2'
                }
              />
            ) : (
              <BadgeStatus status="2" />
            )}
          </TitleArrowBack>
        </Grid>
        <Grid item xs={12}>
          <h3>Errores criticos en los datos del usuario</h3>
          <p style={{ paddingTop: '1rem' }}>
            El usuario no presenta datos de <strong>cuidador</strong>
          </p>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleArrowBack handleClick={() => props.history.goBack()}>
          <h2 className="admin-title">{`${houser.name} ${houser.last_name}`}</h2>
          <BadgeStatus
            status={
              houser.houser.publication ? houser.houser.publication.publication_state : '2'
            }
          />
        </TitleArrowBack>
      </Grid>
      <Grid item xs={12}>
        <CardUserSummary
          loading={loading}
          toRoute={replacePathParams(ROUTES.ADMIN_CUIDADORES_ID_BASIC_INFO, {
            id: id,
          })}
          user={houser}
          location={houser.location}
          total={sum}
          fetchValidateNumber={fetchValidateNumber}
        />
      </Grid>
      <Grid item xs={12}>
        <CardUserLocation
          loading={loading}
          isHouser={houser?.houser}
          address={houser.address}
          adress_detail={houser?.houser?.adress_detail}
          type_road={houser?.houser?.type_road}
          property_type={houser?.houser?.property_type}
          {...houser.location}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CardEmergencyData houser={houser} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CardAssociatedAccounts houser={houser} />
      </Grid>
      <Grid item xs={12}>
        <CardPets loading={loading} pets={pets} />
      </Grid>
      <Grid item xs={12}>
        <div className="admin-cuidador-header">
          <div className="admin-cuidador-header__container margin-top-x1">
            <div>
              <h2>Datos de cuidador #{houser.houser.id}</h2>
              <div className="admin-cuidador-header__container--border" />
            </div>
            <RouterLink to={replacePathParams(ROUTES.CUIDADOR_DETAIL, { id: houser.id })}>
              <button className="btn-primary">
                <PersonRoundedIcon />
                Ver Publicación
              </button>
            </RouterLink>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="card-cuidador-detail">
          <div className="card-cuidador-detail__body dh-card-elevation">
            <div className="dh-flex content-space items-center margin-bottom-x1">
              <span className="dh-title-section">Información básica</span>
              <RouterLink
                to={replacePathParams(ROUTES.ADMIN_CUIDADORES_ID_BASIC_INFO, {
                  id: id,
                })}>
                <button className="btn-primary border">
                  <CreateIcon />
                  Editar
                </button>
              </RouterLink>
            </div>
            <div className="card-cuidador-detail__body__item">
              <p>¿Con quién vive?</p>
              <h5>{houser.houser.who_live_you}</h5>
            </div>
            <div className="card-cuidador-detail__body__item">
              <p>¿Porqué quiere ser houser?</p>
              <h5>{houser.houser.why_want_houser}</h5>
            </div>
            <div className="card-cuidador-detail__body__item">
              <p>¿Años de Experiencia?</p>
              <h5>{houser.houser.years_experiencie} años</h5>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <CardHouserExam houser={houser.houser} handleUpdateExam={handleValidateExam} />
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="card-cuidador-detail">
          <div className="card-cuidador-detail__body dh-card-elevation">
            <div className="dh-flex content-space items-center margin-bottom-x1">
              <span className="dh-title-section">Detalles de la casa</span>
            </div>
            <div className="card-cuidador-detail__body__grid">
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.has_garden] && (
                <div className="grid-item">
                  <JardinIcon setColor="primary" />
                  <h5>Tiene jardín</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.has_terrace] && (
                <div className="grid-item">
                  <TerrazaIcon setColor="primary" />
                  <h5>Tiene terraza</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.has_dogs] && (
                <div className="grid-item">
                  <DogIcon setColor="primary" />
                  <h5>Tiene perro</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.has_cats] && (
                <div className="grid-item">
                  <CatIcon setColor="primary" />
                  <h5>Tiene gato</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.has_rabbits] && (
                <div className="grid-item">
                  <RabbitIcon setColor="primary" />
                  <h5>Tiene conejo</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.has_exo] && (
                <div className="grid-item">
                  <ExoIcon setColor="primary" />
                  <h5>Tiene exótico</h5>
                </div>
              )}
            </div>
            <div className="card-cuidador-detail__body__item">
              <p>Tipo de Propiedad</p>
              <h5>{houser.houser.property_type}</h5>
            </div>
            <div className="card-cuidador-detail__body__item">
              <p>Acepta Gatos</p>
              <YesOrNo status={houser.houser.allowed_cats} />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="card-cuidador-detail">
          <div className="card-cuidador-detail__body dh-card-elevation">
            <div className="dh-flex content-space items-center margin-bottom-x1">
              <span className="dh-title-section">Servicios</span>
              <RouterLink
                to={replacePathParams(ROUTES.ADMIN_CUIDADORES_ID_SERVICIOS, {
                  id: id,
                })}>
                <button className="btn-primary border">
                  <CreateIcon />
                  Editar
                </button>
              </RouterLink>
            </div>
            <div className="card-cuidador-detail__body__grid">
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.type_share] && (
                <div className="grid-item">
                  <CasaCompartida setColor="primary" />
                  <h5>Hospedaje Compartido</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.type_exclusive] && (
                <div className="grid-item">
                  <CasaExclusiva setColor="primary" />
                  <h5>Hospedaje Exclusivo</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.type_delivery] && (
                <div className="grid-item">
                  <CasaExclusiva setColor="primary" />
                  <h5>Delivery Houser</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.pills] && (
                <div className="grid-item">
                  <PillIcon setColor="primary" />
                  <h5>Pastillas</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.injectables] && (
                <div className="grid-item">
                  <InjectableIcon setColor="primary" />
                  <h5>Inyectables</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.allowed_house] && (
                <div className="grid-item">
                  <HomeIcon setColor="primary" />
                  <h5>Toda la Casa</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.allowed_sofa] && (
                <div className="grid-item">
                  <SofaIcon setColor="primary" />
                  <h5>Subir al sofá</h5>
                </div>
              )}
              {VARIABLES.NUMBER_TO_BOOLEAN[houser.houser.allowed_bed] && (
                <div className="grid-item">
                  <CamaIcon setColor="primary" />
                  <h5>Dormir en la cama</h5>
                </div>
              )}
            </div>
            <div className="card-cuidador-detail__body__item">
              <p>Reserva Inmediata</p>
              <YesOrNo status={houser.houser.inmediate_reservation} />
            </div>
            <div className="card-cuidador-detail__body__item">
              <p>Restricciones</p>
              <h5>{houser.houser.restrictions}</h5>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="card-cuidador-detail">
          <div className="card-cuidador-detail__body dh-card-elevation">
            <div className="dh-flex content-space items-center margin-bottom-x1">
              <span className="dh-title-section">Publicacion</span>
              <RouterLink
                to={replacePathParams(ROUTES.ADMIN_CUIDADORES_ID_PUBLICACION, {
                  id: id,
                })}>
                <button className="btn-primary border">
                  <CreateIcon />
                  Editar
                </button>
              </RouterLink>
            </div>
            {houser.houser.publication ? (
              <React.Fragment>
                <div className="card-cuidador-detail__body__item">
                  <p>Titulo</p>
                  <h5>{houser.houser.publication.title}</h5>
                </div>
                <div className="card-cuidador-detail__body__item">
                  <p>Descripcion</p>
                  <h5>{houser.houser.publication.description_pub}</h5>
                </div>
                {houser.houser.publication.video && (
                  <div className="video-container">
                    {!loading && (
                      <Iframe
                        url={`https://www.youtube.com/embed/${houser.houser.publication.video}`}
                        display="initial"
                        position="relative"
                      />
                    )}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <h4>El usuario no tiene publicación</h4>
            )}
            <div className="card-cuidador-detail__body__item">
              {houser.houser.publication && (
                <React.Fragment>
                  {publicationState ? (
                    <button className="btn-danger" onClick={() => fetchValidateHouser(0)}>
                      Desaprobar cuidador
                    </button>
                  ) : (
                    <button className="btn-success" onClick={() => fetchValidateHouser(1)}>
                      Aprobar cuidador
                    </button>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <ServicesHouser
          houserId={houser?.houser?.id}
          country={houser.location.country}
          city={houser.location.city}
        />
      </Grid>
      <Grid item xs={12}>
        <GalleryPublication id={id} gallery={gallery} />
      </Grid>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </Grid>
  );
}

export default AdminHouserDetail;
