import Radio, { RadioProps } from '@material-ui/core/Radio';

import Dog from '@root/assets/icons/Dog';

interface PetSizeRadioProps extends RadioProps {
  type?: 0 | 1 | 2;
}

const PetSizeRadio = (props: PetSizeRadioProps) => {
  const { type, ...other } = props;

  return (
    <Radio
      disableRipple
      color="default"
      icon={<Dog />}
      checkedIcon={<Dog setColor="accent" />}
      {...other}
    />
  );
};

export default PetSizeRadio;
