import React from 'react'
import './servicesWalks.scss'
// Constants
import * as ROUTES from 'constants/routes';
// Components
import CardService from 'components/cardService';
// Material-UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// Images
import ImgPaseo1 from 'assets/images/card_1_paseo.jpg';
import ImgPaseo2 from 'assets/images/card_2_paseo.jpg';
import ImgPaseo3 from 'assets/images/card_3_paseo.jpg';
import ImgPaseo4 from 'assets/images/card_4_paseo.jpg';

function ServicesWalks() {
  return (
    <React.Fragment>
      <div className="dh-main-image">
        <Container maxWidth="lg">
          <div className="dh-main-image__container">
            <h1>Agenda tu servicio y comienza la experiencia</h1>
            <h3>
              La primera red social amante de los perros, que busca de manera profesional, divertida y segura ofrecer
              servicios de alta calidad, como cuidadores y paseadores por medio de una aplicación.
            </h3>
          </div>
        </Container>
      </div>
      <Container maxWidth="lg" className="margin-bottom-x3">
        <h2 className="text-big margin-top-x1 margin-bottom-x1">Elige un plan de paseos</h2>
        <Grid container spacing={3}>
          <CardService
            title="1 paseo"
            text="¡A tu amigo le encantará!"
            image={ImgPaseo1}
            pathName={ROUTES.CONSTRUCTION}
            typeService=""
            size={3}
          />
          <CardService
            title="8 paseos"
            text="Pedigree te regala 4% de descuento"
            image={ImgPaseo2}
            pathName={ROUTES.CONSTRUCTION}
            typeService=""
            size={3}
          />
          <CardService
            title="12 paseos"
            text="Pedigree te regala 5% de descuento"
            image={ImgPaseo3}
            pathName={ROUTES.CONSTRUCTION}
            typeService=""
            size={3}
          />
          <CardService
            title="20 paseos"
            text="Pedigree te regala 13% de descuento"
            image={ImgPaseo4}
            pathName={ROUTES.CONSTRUCTION}
            typeService=""
            size={3}
          />
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default ServicesWalks
