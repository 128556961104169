import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import './dialogUserNotRegister.scss'
// Constants
import * as ROUTES from '../../constants/routes';
// Material-UI
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

function DialogUserNotRegister({ open, handleClose, text }) {
  const [redirect, setRedirect] = useState(false)

  const handleNext = () => {
    setRedirect(true)
  }

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={ROUTES.REGISTER_CLIENTE} />
    }
  }

  return (
    <React.Fragment>
      {renderRedirect()}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dh-dialog-user-not-register"
      >
        <div className="dh-dialog-houser-requirements__title">
          <h2>Regístrate para reservar</h2>
        </div>
        <DialogContent>
          <p>
            {text}
          </p>
        </DialogContent>
        <div className="dh-dialog-houser-requirements__actions">
          <button onClick={handleClose} className="btn-default">
            Atras
          </button>
          <button onClick={handleNext} className="btn-primary">
            Continuar
          </button>
        </div>
      </Dialog>
    </React.Fragment>
  )
}

export default DialogUserNotRegister
