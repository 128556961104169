import { Link as RouterLink } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';

import { useResponsive } from 'hooks';
import { Space } from 'ui-component';
import { IcoFacebook, IcoPeru, IcoInstagram } from 'assets/icons';
import * as ROUTES from 'constants/routes';
import styles from './footer.module.scss';

const FooterLink = (props) => {
  const { href, text, external, redirect } = props;

  const ItemLink = external ? 'a' : RouterLink;
  const routerProps = external ? { href: href } : { to: href };
  const redirectProps = redirect && {
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  return (
    <p className={styles.link}>
      <ItemLink {...routerProps} {...redirectProps} className="dh-no-decoration">
        {text}
      </ItemLink>
    </p>
  );
};

// eslint-disable-next-line no-unused-vars
const FooterNumber = (props) => {
  const { icon, country, numbers } = props;

  return (
    <div className={styles.phone}>
      <img src={icon} alt={`country ${country}`} />
      <div className={styles.phoneText}>
        {numbers.map((number, index) => (
          <p key={index}>{number}</p>
        ))}
      </div>
    </div>
  );
};

const Footer = () => {
  const { isDesktop } = useResponsive();
  return (
    <footer className={styles.root}>
      <Container maxWidth="lg" className={styles.container}>
        <Grid container spacing={isDesktop ? 0 : 3}>
          <Grid item xs={12} sm={4}>
            <Space direction="vertical" className={isDesktop && styles.linkWrap}>
              <FooterLink external href="http://blog.doghouser.com/" text="Nuestro blog" />
              <FooterLink href={ROUTES.LIBRO_RECLAMACIONES} text="Libro de reclamaciones" />
              <FooterLink href={ROUTES.FREQUENT_QUESTIONS} text="Preguntas Frecuentes" />
              <FooterLink href={ROUTES.EMERGENCY_INSURANCE} text="Seguro de emergencia" />
              <FooterLink href={ROUTES.TERMINOS} text="Términos y condiciones" />
            </Space>
          </Grid>
          <Grid item xs={12} sm={4} className="footer-row-2">
            <h4 className={styles.title}>Ayuda</h4>
            <Space direction="horizontal" size="middle">
              <img src={IcoPeru} alt={'country Perú'} style={{ width: 40, height: 40 }} />
              <FooterLink external href={ROUTES.WHATSAPP} text="WhatsApp" />
              {/* 
              <FooterNumber
                icon={IcoColombia}
                country="Colombia"
                numbers={['+57 42040697', '+57 3052302617']}
              />
              <FooterNumber
                icon={IcoPeru}
                country="Perú"
                numbers={['+51 6429162', '+51 995759034']}
              />
              <FooterNumber icon={IcoSpain} country="España" numbers={['+34 633053593']} /> 
              */}
            </Space>
          </Grid>
          <Grid item xs={12} sm={4} className="footer-row-3">
            <h4 className={styles.title}>Síguenos</h4>
            <div className={styles.mediaWrap}>
              <a
                href="https://www.facebook.com/dog.houser/"
                target="_blank"
                rel="noopener noreferrer">
                <img className={styles.media} src={IcoFacebook} alt="facebook" />
              </a>
              <a
                href="https://www.instagram.com/dog.houser/"
                target="_blank"
                rel="noopener noreferrer">
                <img className={styles.media} src={IcoInstagram} alt="instagram" />
              </a>
            </div>
            <Space direction="vertical">
              <div className={styles.legal}>
                <h6>Nombre legal del negocio</h6>
                <p>Corporación Dog Houser S.A.</p>
              </div>
              <div className={styles.legal}>
                <h6>Dirección</h6>
                <p>
                  Calle José Gonzales 342 dpto 104 Urbanización Cocharcas. Miraflores Lima, Lima
                  15704 Perú
                </p>
              </div>
              <div className={styles.legal}>
                <h6>Teléfono cuentas y cobranzas</h6>
                <p>(01) 4463975</p>
              </div>
            </Space>
          </Grid>
        </Grid>
      </Container>
      <div className={styles.copyright}>
        <Container maxWidth="lg">
          <p className={styles.text}>
            ©{new Date().getFullYear()} dog houser - Todos los derechos reservados
          </p>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
