import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
// Constants
import * as ROUTES from 'constants/routes'
import * as VARIABLES from 'constants/variables'
// Utils
import replacePathParams from 'utils/replacePathParams'
// Dependecies
import Gallery from 'react-grid-gallery'
// Material - Icons
import CreateIcon from '@material-ui/icons/Create';

const GalleryPublication = props => {
  const { id, gallery } = props

  const galleryData = []

  for (var i=0; i<gallery.length; i++) {
    const item = {
      src: VARIABLES.IMAGE_URL + gallery[i].photo,
      thumbnail: VARIABLES.IMAGE_URL + gallery[i].photo,
      thumbnailWidth: 310,
      thumbnailHeight: 180
    }
    galleryData.push(item)
  }

  return (
    <div className="gallery-container dh-card-elevation">
      <div className="dh-columns content-space items-center margin-bottom-x1">
        <h2 className="admin-title">Galería del cuidador</h2>
        <RouterLink to={replacePathParams(ROUTES.ADMIN_CUIDADORES_ID_FOTOS, { id: id })}>
          <button className="btn-primary border">
            <CreateIcon/>
            Editar
          </button>
        </RouterLink>
      </div>
      <Gallery images={galleryData} enableImageSelection={false}/>
    </div>
  )
}

GalleryPublication.propTypes = {
  id: PropTypes.string.isRequired,
  gallery: PropTypes.array.isRequired
}

export default GalleryPublication
