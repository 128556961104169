import React, { useState, useContext } from 'react';
import { Context } from '@root/Context';
import '../lostPassword/lostPassword.scss';
// Services
import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
// Constants
import * as ROUTES from '@root/constants/routes';
// Hooks
import useNotificationValue from '@root/hooks/useNotificationValue';
// Componets
import Notification from '@root/components/notification';
// Utils
import { codigos } from '@root/utils/Resources';
// Hooks
import { useInputValue } from '@root/hooks/useInputValue';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  menu: {
    width: 200,
  },
}));

function ValidatePhone(props) {
  const classes = useStyles();
  const { cookies } = useContext(Context);
  const notification = useNotificationValue();

  const phone = useInputValue('');
  const [values, setValues] = useState({
    codigos: '+51',
  });
  const token = useInputValue('');
  const [stateChange, setStateChange] = useState(false);

  const handleValueChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!stateChange) {
      fetchSentPhone();
    } else {
      fetchValidatePhone();
    }
  };

  const fetchSentPhone = async () => {
    let body = {
      phone: values.codigos + phone.value,
      id: cookies.id,
    };
    sentPhone(SERVICES.SEND_PHONE, OPTIONS.POST(body));
  };

  const fetchValidatePhone = async () => {
    let body = {
      token: token.value,
      id: cookies.id,
    };
    sentValidatePhone(SERVICES.VALIDATE_PHONE, OPTIONS.POST(body));
  };

  const sentPhone = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        notification.handleSnackbar('Mensaje de texto enviado', 'success');
        setStateChange(true);
      } else {
        notification.handleSnackbar(data.message, 'warning');
      }
    } catch (error) {
      notification.handleSnackbar('Error:' + error.message, 'error');
    }
  };

  const sentValidatePhone = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        notification.handleSnackbar('Numero validado', 'success');
        setTimeout(() => {
          props.history.push(ROUTES.HOME);
        }, 500);
      } else {
        notification.handleSnackbar(data.message, 'warning');
      }
    } catch (error) {
      notification.handleSnackbar('Error:' + error.message, 'error');
    }
  };

  return (
    <Container maxWidth="sm" className="lost-password">
      <div className="dh-content-block">
        {!stateChange ? (
          <React.Fragment>
            <div className="content-header">
              <h1 className="marginx05 text-center">Validar numero</h1>
            </div>
            <div className="content-body">
              <p className="regular justify">
                Para validar su número, escriba el número de teléfono asociado a su cuenta de
                DogHouser y le enviaremos un <strong>mensaje de texto</strong> con el código de
                confirmación.
              </p>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-select-cod"
                      select
                      required
                      fullWidth
                      label="Cod.País"
                      value={values.codigos}
                      onChange={handleValueChange('codigos')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      variant="outlined">
                      {codigos.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      autoFocus
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="phone"
                      label="Teléfono"
                      type="number"
                      name="phone"
                      value={phone.value}
                      onChange={phone.onChange}
                    />
                  </Grid>
                </Grid>
                <button type="submit" className="btn-primary btn-block medium">
                  Enviar
                </button>
              </form>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="content-header">
              <h1 className="marginx05 text-center">Mensaje enviado</h1>
            </div>
            <div className="content-body">
              <p className="regular justify">
                Se le envió un mensaje de texto con el código de verificación
              </p>
              <form onSubmit={handleSubmit}>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="token"
                  label="Codigo"
                  type="number"
                  name="token"
                  value={token.value}
                  onChange={token.onChange}
                />
                <button type="submit" className="btn-primary btn-block medium">
                  Enviar
                </button>
              </form>
            </div>
          </React.Fragment>
        )}
      </div>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </Container>
  );
}

export default ValidatePhone;
