import React from 'react';
import { Grid } from '@material-ui/core';

const Home = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h2 className="admin-title">Dashboard</h2>
      </Grid>
      {/* Chart */}
      <Grid item xs={12} md={8} lg={9}></Grid>
      {/* Recent Deposits */}
      <Grid item xs={12} md={4} lg={3}></Grid>
    </Grid>
  );
};

export default Home;
