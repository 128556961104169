import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function InjectableIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg 
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 512 512"
      style={{enableBackground: 'new 0 0 512 512'}}
      xmlSpace="preserve">
      <path
        d="M256,8C118.5,8,7,119.5,7,257s111.5,249,249,249s249-111.5,249-249S393.5,8,256,8z M201.5,350.1
          c-8.6,8.6-21.2,10.1-31.6,5.3l-57,57h0c-3.6,3.6-9.5,3.6-13.2,0c-3.6-3.6-3.6-9.5,0-13.2l57-57c-4.9-10.4-3.3-23,5.3-31.6l7.3-7.3
          c1.7,2.6,3.7,5,5.9,7.3l26.4,26.4c2.3,2.3,4.7,4.2,7.3,5.9L201.5,350.1z M293.7,284.2l-19.8-19.8c-3.6-3.6-9.5-3.6-13.2,0
          c-3.6,3.6-3.6,9.5,0,13.2l19.8,19.8l-13.2,13.2l-19.8-19.8c-3.6-3.6-9.5-3.6-13.2,0c-3.6,3.6-3.6,9.5,0,13.2l19.8,19.8
          c-10.9,10.9-28.6,10.9-39.5,0l-26.4-26.4c-10.9-10.9-10.9-28.6,0-39.5l59.3-59.3l65.9,65.9L293.7,284.2z M392.5,224.9
          c-3.6,3.6-9.5,3.6-13.2,0l-13.2-13.2L353,224.9l-19.8-19.8c-3.6-3.6-9.5-3.6-13.2,0c-3.6,3.6-3.6,9.5,0,13.2l19.8,19.8l-13.2,13.2
          l-65.9-65.9l39.5-39.5l-13.2-13.2c-3.6-3.6-3.6-9.5,0-13.2c3.6-3.6,9.5-3.6,13.2,0l92.2,92.2C396.1,215.4,396.1,221.3,392.5,224.9z
          M412.3,165.6c-3.6,3.6-9.5,3.6-13.2,0l-13.2-13.2l-13.2,13.2l-26.4-26.4l13.2-13.2l-13.2-13.2c-3.6-3.6-3.6-9.5,0-13.2
          c3.6-3.6,9.5-3.6,13.2,0l52.7,52.7C415.9,156.1,415.9,162,412.3,165.6z"
        fill={color}/>
    </svg>
  )
}

export default InjectableIcon
