import { CSSProperties } from 'react';

import AddIcon from '@root/assets/icons/AddIcon';
import styles from './addNewCard.module.scss';

interface AddNewCardType {
  height?: string | number;
  style?: CSSProperties;
}

const AddNewCard = (props: AddNewCardType) => {
  const { height = '100%', style } = props;
  return (
    <div className={styles.root} style={{ height: height, ...style }}>
      <AddIcon color="#bdbdbd" />
    </div>
  );
};

export default AddNewCard;
