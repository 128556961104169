import { combineReducers } from 'redux';

// reducer import
// import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';

const reducer = combineReducers({
  // customization: customizationReducer,
  snackbar: snackbarReducer,
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
