import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function TerrazaIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 512 512"
      style={{enableBackground: 'new 0 0 512 512'}} xmlSpace="preserve">
      <path
        d="M256,4.9C118.5,4.9,7,116.4,7,253.9s111.5,249,249,249s249-111.5,249-249S393.5,4.9,256,4.9z M177.6,372.6
        c0,6.7-2.6,12.1-8.8,15.1c-5.7,2.9-11.3,2.3-16.5-1.5c-4.4-3.3-6.2-7.8-6.2-13.2c0-14,0-28.1,0-42.1V327h-31.3v4
        c0,13.8,0,27.6,0,41.4c0,8.5-4.3,14.5-11.7,16.4c-7.5,2-15.2-1.7-18.3-8.8c-0.5-1.1-0.9-2.3-1.4-3.4V245.2c2.2-6.4,6-11.2,13.2-12.4
        c8.2-1.4,16.5,4.3,17.8,12.6c0.3,1.8,0.3,3.6,0.3,5.4c0,13.4,0,26.7,0,40.1v4.4h3.9c13.7,0,27.4,0,41.1,0c11.4,0,17.7,6.3,17.8,17.7
        C177.6,332.8,177.6,352.7,177.6,372.6z M307.7,274.3c-10.7,0.1-21.3,0-32,0h-4v4.3c0,31.3,0,62.7,0,94c0,9.2-5.8,15.9-14.2,16.7
        c-9.4,0.9-17-6.3-17-16.2c-0.1-27,0-53.9,0-80.9v-18c-6.5,0-12.4,0-18.4,0c-6.4,0-12.8,0.1-19.2-0.1c-8.4-0.3-14.9-7.4-14.8-15.7
        c0.1-8.4,6.8-15.3,15.2-15.4c11-0.1,22,0,33,0h3.9v-42.4h-3.9c-44.8,0-89.6,0-134.4,0c-10.5,0-17.7-7.1-16.9-16.9
        c0.2-2.5,1.1-5.1,2.4-7.2c30.8-50.8,75-82.9,133.5-93.6c58.7-10.7,111.8,3.4,158.5,40.5c18.4,14.6,33.3,32.3,45.2,52.6
        c7,11.9-0.3,24.6-14.2,24.6c-44.7,0-89.4,0-134.1,0h-4.3v42.4h3.7c10.7,0,21.3,0,32,0c9.4,0,16.4,6.7,16.4,15.5
        C324,267.5,317.1,274.2,307.7,274.3z M428.5,376.6c-2,6.3-5.6,10.9-12.4,12.4c-8.5,2-17.3-4-18.6-12.6c-0.3-1.9-0.3-3.8-0.3-5.7
        c0-14.5,0-28.9,0-43.7h-31.3v3.6c0,14.4,0.1,28.7,0,43.1c-0.1,8.1-5.7,14.5-13.6,15.6c-7.2,1.1-14.6-3.4-16.9-10.5
        c-0.7-2.1-1-4.4-1-6.6c-0.1-19.9-0.1-39.7,0-59.6c0-10.9,6.5-17.3,17.4-17.3c13.7,0,27.4,0,41.1,0h4.2v-4.1c0-13.8,0-27.6,0-41.4
        c0-5.3,1.5-9.9,5.7-13.5c8-6.8,20.3-3.8,24.5,5.9c0.4,1,0.8,2,1.3,3V376.6z"
        fill={color}/>
    </svg>
  )
}

export default TerrazaIcon
