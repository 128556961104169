import React from 'react'
// Material-UI
import Skeleton from '@material-ui/lab/Skeleton'

function LoadingText(props) {
  const { count } = props
  return (
    <div style={{ margin: '1rem 0'}}>
      <Skeleton height={10}/>
      {Array.from(new Array(count)).map((data, index) => {
        return (
          <Skeleton key={index} height={10} style={{ margin: '8px 0' }}/>
        )
      })}
      <Skeleton height={10} width="80%" style={{ margin: '8px 0' }}/>
    </div>
  )
}

export default LoadingText
