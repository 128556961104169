import { useState } from 'react';

export const useNotificationValue = () => {
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);

  const handleCloseSnackbar = e => {
    setMessage('')
    setMessageState(false)
  };

  const handleSnackbar = (text, variant) => {
    setMessage(text)
    setMessageVariant(variant)
    setMessageState(true)
  };

  return { message, messageVariant, messageState, handleSnackbar, handleCloseSnackbar };
}

export default useNotificationValue;
