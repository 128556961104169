import React from 'react'
import PropTypes from 'prop-types'
import './testSection.scss'
// Services
import * as SERVICES from 'services/config'
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables'
// Assets
import GuideColor from 'assets/icons/guide@color.svg'
import PertInsurance from 'assets/icons/pet-insurance@color.svg'
// Material-UI
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded'

const TestSection = props => {
  const { houserId, houserConfirmed, examValid } = props

  const handleEvaluation = () => {
    let body = {
      houser_id: houserId,
      examen_confirmed: 1,
    }
    sendUserData(SERVICES.CONFIRM_TEST, OPTIONS.POST(body))
  }

  const sendUserData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      // const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        //setValid(true)
      } else {
        console.log('Error al actualizar los datos')
      }
    } catch (error) {
      console.log('Error:' + error.message)
    }
  }

  return (
    <div className="test-section">
      <div className="card-cuidador-test dh-card-border" style={{ marginBottom: '2rem' }}>
        <div className="card-cuidador-test__icon">
          <img src={GuideColor} alt="Manual del Cuidador"/>
        </div>
        <div className="card-cuidador-test__content">
          <h3>Manual del Cuidador</h3>
          <a
            href={VARIABLES.IMAGE_URL + 'files/Manual_de_Houser_PERU.pdf'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn-accent border">
              <CloudDownloadIcon/>
              Descargar
            </button>
          </a>
        </div>
      </div>
      <div className="card-cuidador-test dh-card-border">
        <div className="card-cuidador-test__icon">
          <img src={PertInsurance} alt="Manual del Cuidador"/>
        </div>
        <div className="card-cuidador-test__content">
          <h3>Mapa de Riesgos</h3>
          <a
            href={VARIABLES.IMAGE_URL + 'files/MAPA+DE+RIESGOS+EN+EL+HOGAR.docx'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn-accent border">
              <CloudDownloadIcon/>
              Descargar
            </button>
          </a>
        </div>
      </div>
      {!VARIABLES.NUMBER_TO_BOOLEAN[examValid] &&
        <div className="test-section__exam-link">
          <p>Te pedimos contestar unas preguntas y validaremos las respuestas y nos contactaremos si aprobaste! <strong>TRATA DE EXPLAYARTE PARA CONOCERTE MEJOR.</strong></p>
          <div className="test-section__exam-link--end">
            <div>
              <a
                href="https://forms.gle/3MzwM2RHoAPrMDKB8"
                target="_blank"
                rel="noopener noreferrer">
                <button className="btn-accent" onClick={handleEvaluation}>
                  <ArrowForwardRoundedIcon/>
                  Ir a la evaluación
                </button>
              </a>
            </div>
            {VARIABLES.NUMBER_TO_BOOLEAN[houserConfirmed] ? (
              <div className="test-section__exam-link--text valid">
                <span>Ya has ingresado al examen</span>
              </div>
            ) : (
              <div className="test-section__exam-link--text no-valid">
                <span>Aun no das el examen</span>
              </div>
            )}
          </div>
        </div>
      }
    </div>
  )
}

TestSection.propTypes = {
  houserId: PropTypes.any.isRequired,
  houserConfirmed: PropTypes.number,
  examValid: PropTypes.number
}

export default TestSection
