import React from 'react'
// Hooks
import { usePoppoverValue } from 'hooks/usePoppoverValue'
// Material-UI
import Popover from '@material-ui/core/Popover'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
// Material Icons
import FilterListIcon from '@material-ui/icons/FilterList'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

function FilterAdminPopover(props) {
  const { id, open, anchorEl, handlePopoverClose, title } = props
  return (
    <Popover
      id={id}
      open={open}
      className="dh-filter-selection__popover-filter"
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <div className="dh-filter-selection__popover-filter--title">
        <h3>{title}</h3>
      </div>
      <FormControl component="fieldset" className="dh-filter-selection__popover-filter--formcontrol">
        <FormGroup>
          {props.children}
        </FormGroup>
      </FormControl>
      <div className="dh-filter-selection__popover-filter--actions">
        <button className="btn-primary" onClick={handlePopoverClose}>Guardar</button>
      </div>
    </Popover>
  )
}

function FilterSelection(props) {
  const { values, handleValuesChange } = props
  const country = usePoppoverValue('country-popover')
  const type = usePoppoverValue('type-popover')
  const level = usePoppoverValue('level-popover')

  return (
    <div className="dh-filter-selection">
      <div className="dh-filter-selection__item">
        <div className="dh-filter-selection__style">
          <FilterListIcon/>
        </div>
      </div>
      <div className="dh-filter-selection__item">
        <div className="dh-filter-selection__style">
          <span>Filtrar por</span>
        </div>
      </div>
      <div className="dh-filter-selection__item item-button" onClick={country.handlePopoverOpen}>
        <div className="dh-filter-selection__style">
          <span>País</span>
          <ExpandMoreIcon/>
        </div>
      </div>
      <div className="dh-filter-selection__item item-button" onClick={type.handlePopoverOpen}>
        <div className="dh-filter-selection__style">
          <span>Tipos</span>
          <ExpandMoreIcon/>
        </div>
      </div>
      <div className="dh-filter-selection__item item-button" onClick={level.handlePopoverOpen}>
        <div className="dh-filter-selection__style">
          <span>Nivel de cuidador</span>
          <ExpandMoreIcon/>
        </div>
      </div>
      {/* POPOVER para filtro de Ubicacion */}
      <FilterAdminPopover
        id={country.id}
        open={country.open}
        anchorEl={country.anchorEl}
        handlePopoverClose={country.handlePopoverClose}
        title="País de los Servicios">
        <FormControlLabel
          control={<Checkbox checked={values.country_peru} onChange={handleValuesChange('country_peru')} color="primary"/>}
          label="Perú"
        />
        <FormControlLabel
          control={<Checkbox checked={values.country_colombia} onChange={handleValuesChange('country_colombia')} color="primary"/>}
          label="Colombia"
        />
        <FormControlLabel
          control={<Checkbox checked={values.country_espana} onChange={handleValuesChange('country_espana')} color="primary"/>}
          label="España"
        />
      </FilterAdminPopover>
      {/* POPOVER para filtro de Tipos de Servicio*/}
      <FilterAdminPopover
        id={type.id}
        open={type.open}
        anchorEl={type.anchorEl}
        handlePopoverClose={type.handlePopoverClose}
        title="Tipos de Servicio">
        <FormControlLabel
          control={<Checkbox checked={values.type_id_1} onChange={handleValuesChange('type_id_1')} color="primary"/>}
          label="Hospedaje Compartido"
        />
        <FormControlLabel
          control={<Checkbox checked={values.type_id_2} onChange={handleValuesChange('type_id_2')} color="primary"/>}
          label="Hospedaje Exclusivo"
        />
        <FormControlLabel
          control={<Checkbox checked={values.type_id_3} onChange={handleValuesChange('type_id_3')} color="primary"/>}
          label="Guardería"
        />
        <FormControlLabel
          control={<Checkbox checked={values.type_id_4} onChange={handleValuesChange('type_id_4')} color="primary"/>}
          label="Delivery Houser"
        />
      </FilterAdminPopover>
      {/* POPOVER para filtro de Ubicacion */}
      <FilterAdminPopover
        id={level.id}
        open={level.open}
        anchorEl={level.anchorEl}
        handlePopoverClose={level.handlePopoverClose}
        title="Nivel de cuidador">
        <FormControlLabel
          control={<Checkbox checked={values.houser_junior} onChange={handleValuesChange('houser_junior')} color="primary"/>}
          label="Houser Junior"
        />
        <FormControlLabel
          control={<Checkbox checked={values.houser_senior} onChange={handleValuesChange('houser_senior')} color="primary"/>}
          label="Houser Senior"
        />
        <FormControlLabel
          control={<Checkbox checked={values.houser_super} onChange={handleValuesChange('houser_super')} color="primary"/>}
          label="Super Houser"
        />
      </FilterAdminPopover>
    </div>
  )
}

export default FilterSelection;
