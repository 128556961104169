import React from 'react'
import './serviceGroup.scss'
// DogHouser Icons
import CasaCompartida from '../../assets/icons/CasaCompartida'
import CasaExclusiva from '../../assets/icons/CasaExclusiva'
import PillIcon from '../../assets/icons/PillIcon'
import InjectableIcon from '../../assets/icons/InjectableIcon'
import CamaIcon from '../../assets/icons/CamaIcon'
import SofaIcon from '../../assets/icons/SofaIcon'
import HomeIcon from '../../assets/icons/HomeIcon'
// Material-UI
import FormGroup from '@material-ui/core/FormGroup'

function ServiceGroup(props) {
  const { data } = props
  return (
    <FormGroup row>
      {data.type_share ? (
        <div className="servicio-item">
          <CasaCompartida setColor="primary"/>
          <p>Hospedaje Compartido</p>
        </div>
      ) : (
        <React.Fragment/>
      )}
      {data.type_exclusive ? (
        <div className="servicio-item">
          <CasaExclusiva setColor="primary"/>
          <p>Hospedaje Exclusivo</p>
        </div>
      ) : (
        <React.Fragment/>
      )}
      {data.type_delivery ? (
        <div className="servicio-item">
          <CasaExclusiva setColor="primary"/>
          <p>Delivery Houser</p>
        </div>
      ) : (
        <React.Fragment/>
      )}
      {data.pills ? (
        <div className="servicio-item">
          <PillIcon setColor="primary"/>
          <p>Pastillas</p>
        </div>
      ) : (
        <React.Fragment/>
      )}
      {data.injectables ? (
      <div className="servicio-item">
        <InjectableIcon setColor="primary"/>
        <p>Inyectables</p>
      </div>
      ) : (
        <React.Fragment/>
      )}
      {data.allowed_house ? (
        <div className="servicio-item">
          <HomeIcon setColor="primary"/>
          <p>Toda la Casa</p>
        </div>
      ) : (
        <React.Fragment/>
      )}
      {data.allowed_sofa ? (
        <div className="servicio-item">
          <SofaIcon setColor="primary"/>
          <p>Subir al sofá</p>
        </div>
      ) : (
        <React.Fragment/>
      )}
      {data.allowed_bed ? (
        <div className="servicio-item">
          <CamaIcon setColor="primary"/>
          <p>Dormir en la cama</p>
        </div>
      ) : (
        <React.Fragment/>
      )}
    </FormGroup>
  )
}

export default ServiceGroup
