import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function CasaCompartida(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 495.4 495.4"
      style={{enableBackground: 'new 0 0 495.4 495.4'}}
      xmlSpace="preserve">
      <path d="M248.1,9.5C116.2,9.5,9.3,116.4,9.3,248.3s106.9,238.8,238.8,238.8s238.8-106.9,238.8-238.8S380,9.5,248.1,9.5z
        M372.3,372.6c0,19.7-15.9,35.6-35.6,35.6h-56.9l0,0h-64.2l0,0h-56.9c-19.7,0-35.6-15.9-35.6-35.6v-83.8c0-2.6,1-5.1,2.8-6.9
        l114.9-114.8c3.8-3.8,9.9-3.8,13.7,0l114.8,114.8c1.8,1.8,2.8,4.3,2.8,6.9v83.8H372.3z M414.2,260.2c-3.9,3.9-8.9,5.8-14,5.8
        c-5,0-10.1-1.9-14-5.8L255.7,129.6c-4.2-4.2-11.7-4.2-16,0L109.1,260.2c-7.7,7.7-20.2,7.7-27.9,0c-7.7-7.7-7.7-20.2,0-27.9
        l130.6-130.6c19.2-19.2,52.6-19.3,71.8,0l38.9,38.9v-20.8c0-10.9,8.8-19.7,19.7-19.7c10.9,0,19.8,8.8,19.8,19.7V180l52.2,52.2
        C421.9,240,421.9,252.5,414.2,260.2z" fill={color}/>
      <g>
        <g>
          <path d="M374.9,404.5c-0.2,0-0.4,0-0.5,0c-0.3,0-0.5,0-0.6,0h-3v-0.2c-16.1-0.8-28.5-6.1-36.8-15.8
            c-14.4-16.9-11.1-40.7-11-41.7l0.6-4l3.7,1.7c0,0,3.2,1.4,7.2,1.4c13.8,0,21.4-15.6,21.4-44v-3h36.9v3c0,28.3,7.6,43.9,21.3,43.9
            c4.1,0,7.3-1.4,7.3-1.4l3.7-1.6l0.6,4c0.1,1,3.4,24.8-11,41.7c-8.3,9.7-20.7,15.1-36.8,15.8v0.2H374.9z" fill={color}/>
          <path d="M389.7,301.8c0,40.3,14.6,47,24.4,47c4.9,0,8.6-1.7,8.6-1.7s7.8,53.4-47.8,54.2v0.1c-0.2,0-0.4-0.1-0.6-0.1
            c-0.2,0.1-0.4,0.1-0.6,0.1v-0.1c-55.6-0.8-47.8-54.2-47.8-54.2s3.6,1.7,8.5,1.7c9.8,0,24.4-6.7,24.4-47h15.3h0.3H389.7
            M395.8,295.8h-6.1h-15.3h-0.3h-15.3h-6.1v6.1c0,15.3-2.4,41-18.3,41c-3.3,0-6-1.1-6-1.1l-7.4-3.4l-1.2,8
            c-0.2,1.1-3.6,26.2,11.6,44.1c8.4,9.8,20.5,15.4,36.1,16.7v0.4h6.1c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.5,0h6.1v-0.4
            c15.6-1.3,27.7-6.9,36.1-16.7c15.3-17.9,11.8-43,11.6-44.1l-1.2-8.2l-7.3,3.5c-0.3,0.1-2.8,1.1-6,1.1c-15.9,0-18.3-25.6-18.3-40.9
            V295.8L395.8,295.8z" fill="#FFFFFF"/>
        </g>
        <path d="M375.1,362.3v0.5c-0.2,0-0.6,0.2-0.9,0.2c-0.3,0.1-0.6-0.2-0.9-0.2v-0.5c-4.2-1.1-10.1-6.1-10.1-10.7
          c0-4.3,8.3-4.1,10.9-3.8c2.6-0.2,10.9-0.5,10.9,3.8C385.2,356.1,379.3,361.1,375.1,362.3z" fill="#FFFFFF"/>
        <g>
          <path d="M411.2,344.9c-5.2,0-8.2-4-9.1-6.3c-1-2.5-9.4-24.6-8.2-32.5c0.6-4.2,3.8-6.7,8.5-6.7c3.8,0,7.5,1.5,7.9,1.7
            l0.2,0.1l20.4,12.8c4.5,2.8,6.8,5.9,6.7,9.2c-0.1,3.2-2.4,5.2-3.5,6c-1.4,1.6-6.8,7.7-15,13.1C416.3,344,413.7,344.9,411.2,344.9
            C411.2,344.9,411.2,344.9,411.2,344.9z" fill={color}/>
          <path d="M402.4,302.4c3.3,0,6.7,1.5,6.7,1.5l20.2,12.7c10,6.3,2.7,10.3,2.7,10.3s-5.5,6.9-14.6,12.9
            c-2.4,1.6-4.5,2.2-6.1,2.2c-4.5,0-6.3-4.4-6.3-4.4s-9.2-23.6-8-30.9C397.4,303.3,399.8,302.4,402.4,302.4 M402.4,296.3
            c-6.2,0-10.6,3.6-11.5,9.3c-1.3,8.3,6.1,28.1,8.4,34l0,0l0,0c1.2,3,5.1,8.2,11.9,8.2c3.1,0,6.3-1.1,9.5-3.2
            c8.1-5.3,13.6-11.3,15.4-13.4c2.2-1.6,4.4-4.5,4.5-8.2c0.1-4.4-2.6-8.4-8.1-11.8l-20.2-12.7l-0.4-0.3l-0.4-0.2
            C411,298.1,406.8,296.3,402.4,296.3L402.4,296.3z" fill="#FFFFFF"/>
        </g>
        <g>
          <path d="M337.4,344.8c-2.5,0-5.1-0.9-7.9-2.8c-8.2-5.4-13.6-11.4-15-13.1c-1.2-0.8-3.5-2.8-3.6-6
            c-0.1-3.2,2.1-6.3,6.7-9.1l20.5-12.8l0.2-0.1c0.4-0.2,4.1-1.7,7.9-1.7c4.7,0,7.9,2.5,8.5,6.7c1.2,8-7.2,30-8.2,32.5
            C345.6,340.9,342.6,344.8,337.4,344.8C337.5,344.8,337.5,344.8,337.4,344.8z" fill={color}/>
          <path d="M346.2,302.3c2.6,0,5,0.9,5.5,4.1c1.1,7.4-8,30.9-8,30.9s-1.8,4.4-6.3,4.4c-1.7,0-3.7-0.6-6.2-2.3
            c-9.1-6-14.6-12.9-14.6-12.9s-7.4-4,2.6-10.2l20.3-12.7C339.5,303.8,343,302.3,346.2,302.3 M346.2,296.3c-4.3,0-8.6,1.7-9,1.9
            l-0.5,0.2l-0.4,0.3l-20.3,12.7c-5.5,3.4-8.2,7.4-8.1,11.8c0.1,3.7,2.4,6.6,4.6,8.2c1.8,2.1,7.3,8,15.3,13.3
            c3.2,2.2,6.4,3.3,9.5,3.3c6.8,0,10.7-5.1,11.9-8.2l0,0l0,0c2.3-5.9,9.6-25.7,8.4-34l0,0l0,0C356.9,299.8,352.4,296.3,346.2,296.3
            L346.2,296.3z" fill="#FFFFFF"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M121.7,404.5c-0.2,0-0.4,0-0.5,0c-0.3,0-0.5,0-0.6,0h-3v-0.2c-16.1-0.8-28.5-6.1-36.8-15.8
            c-14.4-16.9-11.1-40.7-11-41.7l0.6-4l3.7,1.7c0,0,3.2,1.4,7.2,1.4c13.8,0,21.4-15.6,21.4-44v-3h36.9v3c0,28.3,7.6,43.9,21.3,43.9
            c4.1,0,7.3-1.4,7.3-1.4l3.7-1.6l0.6,4c0.1,1,3.4,24.8-11,41.7c-8.3,9.7-20.7,15.1-36.8,15.8v0.2H121.7z" fill={color}/>
          <path d="M136.5,301.8c0,40.3,14.6,47,24.4,47c4.9,0,8.6-1.7,8.6-1.7s7.8,53.4-47.8,54.2v0.1c-0.2,0-0.4-0.1-0.6-0.1
            c-0.2,0.1-0.4,0.1-0.6,0.1v-0.1c-55.6-0.8-47.8-54.2-47.8-54.2s3.6,1.7,8.5,1.7c9.8,0,24.4-6.7,24.4-47H121h0.3H136.5
            M142.6,295.8h-6.1h-15.3H121h-15.3h-6.1v6.1c0,15.3-2.4,41-18.3,41c-3.3,0-6-1.1-6-1.1l-7.4-3.4l-1.2,8
            c-0.2,1.1-3.6,26.2,11.6,44.1c8.4,9.8,20.5,15.4,36.1,16.7v0.4h6.1c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.5,0h6.1v-0.4
            c15.6-1.3,27.7-6.9,36.1-16.7c15.3-17.9,11.8-43,11.6-44.1l-1.2-8.2l-7.3,3.5c-0.3,0.1-2.8,1.1-6,1.1c-15.9,0-18.3-25.6-18.3-40.9
            V295.8L142.6,295.8z" fill="#FFFFFF"/>
        </g>
        <path d="M122,362.3v0.5c-0.2,0-0.6,0.2-0.9,0.2c-0.3,0.1-0.6-0.2-0.9-0.2v-0.5c-4.2-1.1-10.1-6.1-10.1-10.7
          c0-4.3,8.3-4.1,10.9-3.8c2.6-0.2,10.9-0.5,10.9,3.8C132.1,356.1,126.2,361.1,122,362.3z" fill="#FFFFFF"/>
        <g>
          <path d="M158.1,344.9c-5.2,0-8.2-4-9.1-6.3c-1-2.5-9.4-24.6-8.2-32.5c0.6-4.2,3.8-6.7,8.5-6.7c3.8,0,7.5,1.5,7.9,1.7
            l0.2,0.1l20.4,12.8c4.5,2.8,6.8,5.9,6.7,9.2c-0.1,3.2-2.4,5.2-3.5,6c-1.4,1.6-6.8,7.7-15,13.1C163.2,344,160.5,344.9,158.1,344.9
            L158.1,344.9z" fill={color}/>
          <path d="M149.2,302.4c3.3,0,6.7,1.5,6.7,1.5l20.2,12.7c10,6.3,2.7,10.3,2.7,10.3s-5.5,6.9-14.6,12.9
            c-2.4,1.6-4.5,2.2-6.1,2.2c-4.5,0-6.3-4.4-6.3-4.4s-9.2-23.6-8-30.9C144.2,303.3,146.7,302.4,149.2,302.4 M149.2,296.3
            c-6.2,0-10.6,3.6-11.5,9.3c-1.3,8.3,6.1,28.1,8.4,34l0,0l0,0c1.2,3,5.1,8.2,11.9,8.2c3.1,0,6.3-1.1,9.5-3.2
            c8.1-5.3,13.6-11.3,15.4-13.4c2.2-1.6,4.4-4.5,4.5-8.2c0.1-4.4-2.6-8.4-8.1-11.8l-20.2-12.7l-0.4-0.3l-0.4-0.2
            C157.8,298.1,153.6,296.3,149.2,296.3L149.2,296.3z" fill="#FFFFFF"/>
        </g>
        <g>
          <path d="M84.3,344.8c-2.5,0-5.1-0.9-7.9-2.8c-8.2-5.4-13.6-11.4-15-13.1c-1.2-0.8-3.5-2.8-3.6-6
            c-0.1-3.2,2.1-6.3,6.7-9.1L85,301.1l0.2-0.1c0.4-0.2,4.1-1.7,7.9-1.7c4.7,0,7.9,2.5,8.5,6.7c1.2,8-7.2,30-8.2,32.5
            C92.4,340.9,89.4,344.8,84.3,344.8C84.3,344.8,84.3,344.8,84.3,344.8z" fill={color}/>
          <path d="M93.1,302.3c2.6,0,5,0.9,5.5,4.1c1.1,7.4-8,30.9-8,30.9s-1.8,4.4-6.3,4.4c-1.7,0-3.7-0.6-6.2-2.3
            c-9.1-6-14.6-12.9-14.6-12.9s-7.4-4,2.6-10.2l20.3-12.7C86.4,303.8,89.8,302.3,93.1,302.3 M93.1,296.3c-4.3,0-8.6,1.7-9,1.9
            l-0.5,0.2l-0.4,0.3l-20.3,12.7c-5.5,3.4-8.2,7.4-8.1,11.8c0.1,3.7,2.4,6.6,4.6,8.2c1.8,2.1,7.3,8,15.3,13.3
            c3.2,2.2,6.4,3.3,9.5,3.3c6.8,0,10.7-5.1,11.9-8.2l0,0l0,0c2.3-5.9,9.6-25.7,8.4-34l0,0l0,0C103.7,299.8,99.3,296.3,93.1,296.3
            L93.1,296.3z" fill="#FFFFFF"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M248.3,404.5c-0.2,0-0.4,0-0.5,0c-0.3,0-0.5,0-0.6,0h-3v-0.2c-16.1-0.8-28.5-6.1-36.8-15.8
            c-14.4-16.9-11.1-40.7-11-41.7l0.6-4l3.7,1.7c0,0,3.2,1.4,7.2,1.4c13.8,0,21.4-15.6,21.4-44v-3h36.9v3c0,28.3,7.6,43.9,21.3,43.9
            c4.1,0,7.3-1.4,7.3-1.4l3.7-1.6l0.6,4c0.1,1,3.4,24.8-11,41.7c-8.3,9.7-20.7,15.1-36.8,15.8v0.2H248.3z" fill={color}/>
          <path d="M263.1,301.8c0,40.3,14.6,47,24.4,47c4.9,0,8.6-1.7,8.6-1.7s7.8,53.4-47.8,54.2v0.1c-0.2,0-0.4-0.1-0.6-0.1
            c-0.2,0.1-0.4,0.1-0.6,0.1v-0.1c-55.6-0.8-47.8-54.2-47.8-54.2s3.6,1.7,8.5,1.7c9.8,0,24.4-6.7,24.4-47h15.3h0.3H263.1
            M269.2,295.8h-6.1h-15.3h-0.3h-15.3h-6.1v6.1c0,15.3-2.4,41-18.3,41c-3.3,0-6-1.1-6-1.1l-7.4-3.4l-1.2,8
            c-0.2,1.1-3.6,26.2,11.6,44.1c8.4,9.8,20.5,15.4,36.1,16.7v0.4h6.1c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.5,0h6.1v-0.4
            c15.6-1.3,27.7-6.9,36.1-16.7c15.3-17.9,11.8-43,11.6-44.1l-1.2-8.2l-7.3,3.5c-0.3,0.1-2.8,1.1-6,1.1c-15.9,0-18.3-25.6-18.3-40.9
            V295.8L269.2,295.8z" fill="#FFFFFF"/>
        </g>
        <path d="M248.6,362.3v0.5c-0.2,0-0.6,0.2-0.9,0.2c-0.3,0.1-0.6-0.2-0.9-0.2v-0.5c-4.2-1.1-10.1-6.1-10.1-10.7
          c0-4.3,8.3-4.1,10.9-3.8c2.6-0.2,10.9-0.5,10.9,3.8C258.7,356.1,252.7,361.1,248.6,362.3z" fill="#FFFFFF"/>
        <g>
          <path d="M284.7,344.9c-5.2,0-8.2-4-9.1-6.3c-1-2.5-9.4-24.6-8.2-32.5c0.6-4.2,3.8-6.7,8.5-6.7c3.8,0,7.5,1.5,7.9,1.7
            l0.2,0.1l20.4,12.8c4.5,2.8,6.8,5.9,6.7,9.2c-0.1,3.2-2.4,5.2-3.5,6c-1.4,1.6-6.8,7.7-15,13.1C289.7,344,287.1,344.9,284.7,344.9
            L284.7,344.9z" fill={color}/>
          <path d="M275.8,302.4c3.3,0,6.7,1.5,6.7,1.5l20.2,12.7c10,6.3,2.7,10.3,2.7,10.3s-5.5,6.9-14.6,12.9
            c-2.4,1.6-4.5,2.2-6.1,2.2c-4.5,0-6.3-4.4-6.3-4.4s-9.2-23.6-8-30.9C270.8,303.3,273.2,302.4,275.8,302.4 M275.8,296.3
            c-6.2,0-10.6,3.6-11.5,9.3c-1.3,8.3,6.1,28.1,8.4,34l0,0l0,0c1.2,3,5.1,8.2,11.9,8.2c3.1,0,6.3-1.1,9.5-3.2
            c8.1-5.3,13.6-11.3,15.4-13.4c2.2-1.6,4.4-4.5,4.5-8.2c0.1-4.4-2.6-8.4-8.1-11.8l-20.2-12.7l-0.4-0.3l-0.4-0.2
            C284.4,298.1,280.2,296.3,275.8,296.3L275.8,296.3z" fill="#FFFFFF"/>
        </g>
        <g>
          <path d="M210.9,344.8c-2.5,0-5.1-0.9-7.9-2.8c-8.2-5.4-13.6-11.4-15-13.1c-1.2-0.8-3.5-2.8-3.6-6
            c-0.1-3.2,2.1-6.3,6.7-9.1l20.5-12.8l0.2-0.1c0.4-0.2,4.1-1.7,7.9-1.7c4.7,0,7.9,2.5,8.5,6.7c1.2,8-7.2,30-8.2,32.5
            C219,340.9,216,344.8,210.9,344.8C210.9,344.8,210.9,344.8,210.9,344.8z" fill={color}/>
          <path d="M219.6,302.3c2.6,0,5,0.9,5.5,4.1c1.1,7.4-8,30.9-8,30.9s-1.8,4.4-6.3,4.4c-1.7,0-3.7-0.6-6.2-2.3
            c-9.1-6-14.6-12.9-14.6-12.9s-7.4-4,2.6-10.2l20.3-12.7C212.9,303.8,216.4,302.3,219.6,302.3 M219.6,296.3c-4.3,0-8.6,1.7-9,1.9
            l-0.5,0.2l-0.4,0.3l-20.3,12.7c-5.5,3.4-8.2,7.4-8.1,11.8c0.1,3.7,2.4,6.6,4.6,8.2c1.8,2.1,7.3,8,15.3,13.3
            c3.2,2.2,6.4,3.3,9.5,3.3c6.8,0,10.7-5.1,11.9-8.2l0,0l0,0c2.3-5.9,9.6-25.7,8.4-34l0,0l0,0C230.3,299.8,225.9,296.3,219.6,296.3
            L219.6,296.3z" fill="#FFFFFF"/>
        </g>
      </g>
    </svg>
  )
}

export default CasaCompartida
