import React from 'react';
import './notFound.scss';
// Images
import Dog from 'assets/images/dog2.png';
// Material-Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function NoMatch (props) {
  const handleBackClick = () => {
    props.history.goBack();
  };
  return (
    <main className="page-404-container">
      <section className="page-404">
        <h1>404</h1><img src={Dog} alt="Dog" />
        <h3>¡Oh no, parece que estás perdido!</h3>
        <button className="btn-primary" onClick={handleBackClick}>
          <ArrowBackIcon/>
          Volver
        </button>
      </section>
    </main>
  );
}

export default NoMatch;
