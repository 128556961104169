import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../Context';
import { Redirect } from 'react-router-dom';
import './newPetSteps.scss';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as ROUTES from '../../constants/routes';
// Components
import StepNewPetInfo from '../../components/stepNewPetInfo/StepNewPetInfo';
import StepNewPetFoto from '../../components/stepNewPetFoto/StepNewPetFoto';
import StepNewPetDetalle from '../../components/stepNewPetDetalle/StepNewPetDetalle';
// Material-UI
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

import PetsIcon from '@material-ui/icons/Pets';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import AssignmentIcon from '@material-ui/icons/Assignment';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: 'rgb(128, 205, 197)',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: 'rgb(128, 205, 197)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 48,
    height: 48,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: 'rgb(128, 205, 197)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: 'rgb(128, 205, 197)',
  },
});

function getSteps() {
  return ['Información', 'Sube una foto de tu mascota', 'Instrucciones de cuidado'];
}

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PetsIcon />,
    2: <InsertPhotoIcon />,
    3: <AssignmentIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

function NewPetSteps(props) {
  // edit id
  const id = props.match.params.id;
  // cookie store
  const { isAuth, cookies, client, houser } = useContext(Context);
  // Variables del Stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  // ID de la Mascota
  const [petId, setPetId] = useState('');

  const [editPet, setEditPet] = useState(undefined);

  const fetchPet = async () => {
    let body = {
      petid: id,
    };
    try {
      const response = await fetch(SERVICES.GET_PET, OPTIONS.POST(body));
      const data = await response.json();
      console.log(data);
      if (data.status === 200) {
        setEditPet(data.pet);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchPet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = (id) => {
    if (id) {
      setPetId(id);
    }
    // setPetId(20)
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  let from = ROUTES.HOME;
  if (isAuth && houser) {
    from = ROUTES.CUIDADOR_PERFIL;
  }
  if (isAuth && client) {
    from = ROUTES.CLIENTE_PERFIL;
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <StepNewPetInfo
            editPet={editPet}
            id={cookies.id}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 1:
        return (
          <StepNewPetFoto
            editPet={editPet}
            petId={petId}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <StepNewPetDetalle
            editPet={editPet}
            id={cookies.id}
            petId={petId}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      default:
        return <Redirect to={from} />;
    }
  }

  return (
    <React.Fragment>
      <div className="new-pet-container-header">
        <Container maxWidth="lg">
          <Stepper
            className="new-pet-stepper"
            activeStep={activeStep}
            alternativeLabel
            connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  className="new-pet-stepper__text"
                  StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Container>
      </div>
      <div className="new-pet-content">
        <Container maxWidth="md">
          <div>
            <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
            {/*
              <div style={{marginTop: '1rem'}}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}>
                  Back
                </Button>
                <Button variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
              */}
          </div>
          <div>
            {activeStep === steps.length && (
              <div>
                <h2>Mascota Creada con éxito</h2>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default NewPetSteps;
