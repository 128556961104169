import React from 'react'
import './products.scss'
// Dependencies
import Iframe from 'react-iframe';

function Products() {
  return (
    <div className="dh-products">
      <Iframe
        className="dh-products__iframe"
        url={`https://yomellamolizzi.wixsite.com/productos`}
        display="initial"
        position="relative"
      />
    </div>
  )
}

export default Products
