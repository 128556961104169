import { useState } from 'react'

export const usePoppoverValue = (idName) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const id = open ? {idName} : undefined;

  const handlePopoverOpen = event => setAnchorEl(event.currentTarget)

  const handlePopoverClose = () => setAnchorEl(null)

  return { id, open, anchorEl, handlePopoverOpen, handlePopoverClose }
}
