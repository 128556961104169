import React from 'react';

function Marker(props) {
  const { id } = props;
  return (
    <React.Fragment>
      <div className="pin" key={id}/>
      <div className='pulse'/>
    </React.Fragment>
  );
}

export default Marker;
