import React from 'react';
import Paper from '@material-ui/core/Paper';

import { Chat, Channel, MessageInput, VirtualizedMessageList } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';

// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';

import './StreamMessage.scss';

function StreamMessage(props) {
  const { chatClient, channel, booking } = props;

  if (channel && booking) {
    channel.on('message.new', (event) => {
      const msgText = event.message.text;
      if (event.user.id === `ms-${booking.user_id}`) {
        channel.queryMembers({ user_id: `ms-${booking.houser.user.id}` }).then((res) => {
          if (!res.members[0].user.online) {
            fetch(
              SERVICES.NOTIFY_NEW_MESSAGE,
              OPTIONS.POST({
                booking_id: booking.id,
                sender: 'client',
                message: msgText,
              }),
            )
              .then((res) => res.json())
              .then((res) => console.log(res));
          }
        });
      } else if (event.user.id === `ms-${booking.houser.user.id}`) {
        channel.queryMembers({ user_id: `ms-${booking.user.id}` }).then((res) => {
          if (!res.members[0].user.online) {
            fetch(
              SERVICES.NOTIFY_NEW_MESSAGE,
              OPTIONS.POST({
                booking_id: booking.id,
                sender: 'houser',
                message: msgText,
              }),
            )
              .then((res) => res.json())
              .then((res) => console.log(res));
          }
        });
      }
    });
  }
  return (
    <Paper className="message-wrapper">
      <div className="section-title">
        <h3>Chat</h3>
        <div className="border-space" />
      </div>
      <Chat client={chatClient}>
        <Channel channel={channel}>
          <VirtualizedMessageList />
          <MessageInput />
        </Channel>
      </Chat>
    </Paper>
  );
}

export default StreamMessage;
