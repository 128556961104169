import React from 'react';
import PropTypes from 'prop-types';
// Dependencies
import Workbook from 'react-excel-workbook';
// Constants
import * as VARIABLES from 'constants/variables';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import VerticalAlignBottomRoundedIcon from '@material-ui/icons/VerticalAlignBottomRounded';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: '0px',
  },
  button: {
    fontFamily: 'Montserrat-Semibold',
    fontSize: '15px',
  },
}));

const WorkbookClients = (props) => {
  const classes = useStyles();
  const { data } = props;

  const exportData = [];

  data.forEach((res) => {
    const item = {
      id: res.id,
      name: res.name + res.last_name,
      email: res.email,
      phone: res.phone,
      confirmation: VARIABLES.NUMBER_TO_BOOLEAN[res.confirmation],
      phone_house: res.phone_house,
      doc_id: res.doc_id,
      location: res.address,
    };
    exportData.push(item);
  });

  return (
    <div className={classes.content}>
      <Workbook
        filename="clients_data.xlsx"
        element={
          <Button className={classes.button} startIcon={<VerticalAlignBottomRoundedIcon />}>
            Exportar
          </Button>
        }>
        <Workbook.Sheet data={exportData} name="Reservas">
          <Workbook.Column label="ID" value="id" />
          <Workbook.Column label="Nombre" value="name" />
          <Workbook.Column label="E-mail" value="email" />
          <Workbook.Column label="Teléfono" value="phone" />
          <Workbook.Column label="Teléfono verificado" value="confirmation" />
          <Workbook.Column label="Teléfono de casa" value="phone_house" />
          <Workbook.Column label="Doc. ID" value="doc_id" />
          <Workbook.Column label="Ubicación" value="location" />
        </Workbook.Sheet>
      </Workbook>
    </div>
  );
};

WorkbookClients.propTypes = {
  data: PropTypes.array.isRequired,
};

export default WorkbookClients;
