import React, { useContext, useEffect, useState } from 'react';
import { Context } from '@root/Context';
import './cuidadorReservas.scss';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// components
import Notification from 'components/notification';
import { ReservationsTable } from './components';
// Material-UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

function CuidadorReservas() {
  const { cookies } = useContext(Context);
  const [loading, setLoading] = useState(true);
  // Variables de "Notification"
  const notification = useNotificationValue();

  const [btnState, setBtnState] = useState({
    btn1: 'active',
    btn2: '',
    btn3: '',
    btn4: '',
  });

  // Array de reservas
  const [bookings, setBookings] = useState([]);
  // const [filtered, setFiltered] = useState([]);
  // const [filter, setFilter] = useState(1);
  const [resState1, setResState1] = useState([]);
  const [resState2, setResState2] = useState([]);
  const [resState3, setResState3] = useState([]);
  const [resState4, setResState4] = useState([]);

  const handleClick = (value) => {
    setBtnState({
      [value]: 'active',
    });

    if (value === 'btn1') {
      setBookings(resState1);
    } else if (value === 'btn2') {
      setBookings(resState2);
    } else if (value === 'btn3') {
      setBookings(resState3);
    } else if (value === 'btn4') {
      setBookings(resState4);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    let houserId = cookies.houserId;
    // await sendData(SERVICES.ALL_RESERVATIONS_BY_HOUSER + houserId, OPTIONS.GET)
    try {
      const response = await fetch(SERVICES.ALL_BOOKINGS_BY_HOUSER + houserId, OPTIONS.GET());
      const data = await response.json();
      if (response.status === 200 && data.bookings) {
        console.log(data.bookings);
        const state1 = data.bookings.filter((booking) => booking.state === 1);
        // const state2 = data.reservations.filter(reservation => reservation.state === 2)
        const state3 = data.bookings.filter((booking) => booking.state === 3);
        const state4 = data.bookings.filter((booking) => booking.state === 4);
        setResState1(data.bookings.sort(comparar));
        setResState2(state3.sort(comparar));
        setResState3(state1.sort(comparar));
        setResState4(state4.sort(comparar));
        setBookings(data.bookings.sort(comparar));

        function comparar(a, b) {
          if (a.id > b.id) {
            return -1;
          }
          if (a.id < b.id) {
            return 1;
          }
          // a must be equal to b
          return 0;
        }
      }
      setLoading(false);
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  // const sendData = async (url, options) => {
  //   try {
  //     const response = await fetch(url, options);
  //     const data = await response.json();
  //     // console.log(data)
  //     if(response.status === 200) {
  //       if(data.reservations) {
  //         const state1 = data.reservations.filter(reservation => reservation.state === 1)
  //         // const state2 = data.reservations.filter(reservation => reservation.state === 2)
  //         const state3 = data.reservations.filter(reservation => reservation.state === 3)
  //         const state4 = data.reservations.filter(reservation => reservation.state === 4)
  //         setResState1(data.reservations.sort(comparar))
  //         setResState2(state3.sort(comparar))
  //         setResState3(state1.sort(comparar))
  //         setResState4(state4.sort(comparar))
  //         setReservations(data.reservations.sort(comparar))

  //         function comparar (a, b) {
  //           if (a.id > b.id) {
  //             return -1;
  //           }
  //           if (a.id < b.id) {
  //             return 1;
  //           }
  //           // a must be equal to b
  //           return 0;
  //         };

  //       }
  //       setLoading(false)
  //     } else {
  //       notification.handleSnackbar(data.message, 'error')
  //       setLoading(false)
  //     }
  //   } catch(error) {
  //     notification.handleSnackbar(error.message, 'error')
  //     setLoading(false)
  //   }
  // }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cuidador-reservas">
      <Container maxWidth="lg">
        <div className="cuidador-reservas__title">
          <h1>Reservas - Cuidador</h1>
        </div>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12}>
            <div className="cuidador-reservas__toolbar">
              <div className="cuidador-reservas__actions">
                <button
                  className={`btn-primary border ${btnState.btn1}`}
                  onClick={() => handleClick('btn1')}>
                  Todas
                </button>
                <button
                  className={`btn-primary border ${btnState.btn2}`}
                  onClick={() => handleClick('btn2')}>
                  Próximas
                </button>
                <button
                  className={`btn-primary border ${btnState.btn3}`}
                  onClick={() => handleClick('btn3')}>
                  Por confirmar
                </button>
                <button
                  className={`btn-primary border ${btnState.btn4}`}
                  onClick={() => handleClick('btn4')}>
                  Rechazadas
                </button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            {!loading && <ReservationsTable reservations={bookings} />}
          </Grid>
        </Grid>
      </Container>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default CuidadorReservas;
