import React, { CSSProperties, ReactNode } from 'react';

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
  style?: CSSProperties;
  className?: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, style, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-doghouser-tabpanel-${index}`}
      aria-labelledby={`scrollable-doghouser-tab-${index}`}
      className={className}
      style={style}
      {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

export default TabPanel;
