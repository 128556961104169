import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './stepCuidadorServicios.scss'
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// Components
import Notification from 'components/notification/Notification';
// DogHouser Icons
import CasaCompartida from 'icons/CasaCompartida'
import CasaExclusiva from 'icons/CasaExclusiva'
import PillIcon from 'icons/PillIcon'
import InjectableIcon from 'icons/InjectableIcon'
import CamaIcon from 'icons/CamaIcon'
import SofaIcon from 'icons/SofaIcon'
import HomeIcon from 'icons/HomeIcon'
import Dog from 'icons/Dog'
// Material-UI
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

function StepCuidadorServicios(props) {
  const { id, handleNext, handleBack, activeStep } = props
  // Variables de "Notification"
  const notification = useNotificationValue()
  const [state, setState] = useState({
    inmediate_reservation: '0',
    type_share: false,
    type_exclusive: false,
    type_delivery: false,
    dog_small: false,
    dog_medium: false,
    dog_big: false,
    pills: false,
    injectables: false,
    allowed_house: false,
    allowed_sofa: false,
    allowed_bed: false,
    restrictions: '',
  })

  const handleInputChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleRadioChange = name => event => {
    setState({ ...state, [name]: event.target.value })
  }

  const handleCheckboxChange = name => event => {
    setState({ ...state, [name]: event.target.checked })}

  const handleSubmit = e => {
    e.preventDefault();
    let petSize = 1
    if(state.dog_big) {
      petSize = 2
    } else if(state.dog_medium) {
      petSize = 1
    } else if(state.dog_small) {
      petSize = 0
    }
    fetchUpdateUser(petSize)
  }

  useEffect(() => {
    fetchGetUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const fetchGetUser = async () => {
    let body = {
      id: id
    }
    sendUserData(SERVICES.USER_BY_ID, OPTIONS.POST(body), VARIABLES.STATE_INITIATE)
  }

  const fetchUpdateUser = async (petSize) => {
    let body = {
      id: id,
      houser_type: '',
      inmediate_reservation: VARIABLES.TEXT_TO_NUMBER[state.inmediate_reservation],
      pet_size: petSize,
      pills: VARIABLES.BOOLEAN_TO_NUMBER[state.pills],
      injectables: VARIABLES.BOOLEAN_TO_NUMBER[state.injectables],
      allowed_house: VARIABLES.BOOLEAN_TO_NUMBER[state.allowed_house],
      allowed_sofa: VARIABLES.BOOLEAN_TO_NUMBER[state.allowed_sofa],
      allowed_bed: VARIABLES.BOOLEAN_TO_NUMBER[state.allowed_bed],
      restrictions: state.restrictions,
      type_share: VARIABLES.BOOLEAN_TO_NUMBER[state.type_share],
      type_exclusive: VARIABLES.BOOLEAN_TO_NUMBER[state.type_exclusive],
      type_delivery: VARIABLES.BOOLEAN_TO_NUMBER[state.type_delivery]
    }
    sendUserData(SERVICES.UPDATE_HOUSER_STEP_4, OPTIONS.POST(body), VARIABLES.STATE_UPDATE)
  }

  const sendUserData = async (url, options, state) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if(response.status === 200) {
        if (state === VARIABLES.STATE_INITIATE) {
          let varSmall = false
          let varMedium = false
          let varBig = false
          if (data.houser.pet_size === 2) {
            varBig = true
            varMedium = true
            varSmall = true
          } else if (data.houser.pet_size === 1) {
            varMedium = true
            varSmall = true
          } else {
            varSmall = true
          }
          setState({
            inmediate_reservation: VARIABLES.NUMBER_TO_TEXT[data.houser.inmediate_reservation],
            type_share: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.type_share],
            type_exclusive: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.type_exclusive],
            type_delivery: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.type_delivery],
            dog_small: varSmall,
            dog_medium: varMedium,
            dog_big: varBig,
            pills: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.pills],
            injectables: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.injectables],
            allowed_house: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.allowed_house],
            allowed_sofa: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.allowed_sofa],
            allowed_bed: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.allowed_bed],
            restrictions: data.houser.restrictions,
          })
        } else if (state === VARIABLES.STATE_UPDATE) {
          notification.handleSnackbar('Datos actualizados', 'success')
          handleNext()
        }
      } else {
        if (state === VARIABLES.STATE_INITIATE) {
          notification.handleSnackbar("Error al obtener los datos.", 'error')
        } else if (state === VARIABLES.STATE_UPDATE) {
          notification.handleSnackbar("Error al actualizar los datos", 'error')
        }
      }
    } catch (e) {
      notification.handleSnackbar('Error:' + e.message, 'error')
    }
  }

  return (
    <div className="step-cuidador-servicios">
      <form onSubmit={handleSubmit}>
        <div className="step-cuidador-servicios__section-title">
          <h3>Tipo de cuidado</h3>
        </div>
        <FormGroup row className="step-cuidador-servicios__form-group">
          <FormControlLabel
            control={
              <Checkbox 
                checked={state.type_share}
                onChange={handleCheckboxChange('type_share')}
                icon={<CasaCompartida/>}
                checkedIcon={<CasaCompartida setColor="accent"/>}
                name="type_share"
                value="type_share"
                color="primary"
              />
            }
            label="Hospedaje Compartido"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.type_exclusive}
                onChange={handleCheckboxChange('type_exclusive')}
                icon={<CasaExclusiva />} 
                checkedIcon={<CasaExclusiva setColor="accent"/>}
                name="type_exclusive"
                value="type_exclusive"
                color="primary"
              />
            }
            label="Hospedaje Exclusivo"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.type_delivery}
                onChange={handleCheckboxChange('type_delivery')}
                icon={<CasaExclusiva />} 
                checkedIcon={<CasaExclusiva setColor="accent"/>}
                name="type_delivery"
                value="type_delivery"
                color="primary" 
              />
            }
            label="Delivery Houser"
            labelPlacement="bottom"
          />
        </FormGroup>
        <ul className="step-cuidador-servicios__flex-outer">
          <li>
            <label htmlFor="inmediate_reservation">Activar reserva inmediata</label>
            <RadioGroup aria-label="inmediate_reservation" name="inmediate_reservation" value={state.inmediate_reservation} onChange={handleRadioChange('inmediate_reservation')} row>
              <FormControlLabel value="1" control={<Radio />} label="Si" />
              <FormControlLabel value="0" control={<Radio />} label="No" />
            </RadioGroup>
          </li>
        </ul>
        <div className="step-cuidador-servicios__section-title">
          <h3>Tamaño de perro permitido</h3>
        </div>
        <FormGroup row className="step-cuidador-servicios__form-pet-size">
          <FormControlLabel
            className="dog-small"
            control={
              <Checkbox 
                checked={state.dog_small}
                onChange={handleCheckboxChange('dog_small')}
                icon={<Dog/>}
                checkedIcon={<Dog setColor="accent"/>}
                value="small"
                color="primary"
              />
            }
            label="Pequeño"
            labelPlacement="bottom"
          />
          <FormControlLabel
            className="dog-medium"
            control={
              <Checkbox
                checked={state.dog_medium}
                onChange={handleCheckboxChange('dog_medium')}
                icon={<Dog/>}
                checkedIcon={<Dog setColor="accent"/>}
                value="medium"
                color="primary"
              />
            }
            label="Mediano"
            labelPlacement="bottom"
          />
          <FormControlLabel
            className="dog-big"
            control={
              <Checkbox
                checked={state.dog_big}
                onChange={handleCheckboxChange('dog_big')}
                icon={<Dog/>}
                checkedIcon={<Dog setColor="accent"/>}
                value="big"
                color="primary"
              />
            }
            label="Grande"
            labelPlacement="bottom"
          />
        </FormGroup>
        <div className="step-cuidador-servicios__section-title">
          <h3>¿Podrias dar medicinas?</h3>
        </div>
        <FormGroup row className="step-cuidador-servicios__form-group">
          <FormControlLabel
            control={
              <Checkbox 
                checked={state.pills}
                onChange={handleCheckboxChange('pills')}
                icon={<PillIcon/>}
                checkedIcon={<PillIcon setColor="accent"/>}
                value="pills"
                color="primary"
              />
            }
            label="Pastillas"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.injectables}
                onChange={handleCheckboxChange('injectables')}
                icon={<InjectableIcon/>}
                checkedIcon={<InjectableIcon setColor="accent"/>}
                value="injectables"
                color="primary"
              />
            }
            label="Inyectables"
            labelPlacement="bottom"
          />
        </FormGroup>
        <div className="step-cuidador-servicios__section-title">
          <h3 className="sub-text-form">Zonas permitidas</h3>
        </div>
        <FormGroup row className="step-cuidador-servicios__form-group">
          <FormControlLabel
            control={
              <Checkbox 
                checked={state.allowed_house}
                onChange={handleCheckboxChange('allowed_house')}
                icon={<HomeIcon/>}
                checkedIcon={<HomeIcon setColor="accent"/>}
                value="allowed_house"
                color="primary"
              />
            }
            label="Toda la casa"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.allowed_sofa}
                onChange={handleCheckboxChange('allowed_sofa')}
                icon={<SofaIcon/>}
                checkedIcon={<SofaIcon setColor="accent"/>}
                value="allowed_sofa"
                color="primary"
              />
            }
            label="Subir al sofá"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.allowed_bed}
                onChange={handleCheckboxChange('allowed_bed')}
                icon={<CamaIcon/>}
                checkedIcon={<CamaIcon setColor="accent"/>}
                value="allowed_bed"
                color="primary"
              />
            }
            label="Dormir en cama"
            labelPlacement="bottom"
          />
        </FormGroup>
        <ul className="step-cuidador-servicios__flex-outer">
          <li>
            <label htmlFor="restrictions">Restricciones:</label>
            <TextField
              variant="outlined"
              margin="normal"
              required
              id="restrictions"
              name="restrictions"
              type="text"
              multiline
              rows="3"
              value={state.restrictions}
              onChange={handleInputChange}
            />
          </li>
        </ul>
        <div className="btn-container">
          {handleBack &&
            <button
              onClick={handleBack}
              className={`btn-primary medium border ${activeStep === 0 ? "disabled" : null }`}
              style={{marginRight: 16}}>
              Atras
            </button>
          }
          <button type="submit" className="btn-primary medium btn-step">
            Guardar y Continuar
          </button>
        </div>
      </form>
      {notification.messageState &&
        <Notification 
          message={notification.message} 
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState} 
          onClose={notification.handleCloseSnackbar}
        />
      }
    </div>
  )
}

StepCuidadorServicios.propTypes = {
  id: PropTypes.string.isRequired,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  activeStep: PropTypes.number
}

export default StepCuidadorServicios
