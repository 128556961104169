import { Button } from '@material-ui/core';
import { IconTrash } from '@tabler/icons';

import HouserServicesDTO from '@root/models/HouserServicesDTO';
import BadgeLevel from '@root/components/badgeLevel/BadgeLevel';
import styles from './houserServiceCard.module.scss';

interface HouserServiceCardType {
  houserService: HouserServicesDTO;
  onDelete?: (houserServiceId: number) => void;
}

const HouserServiceCard = (props: HouserServiceCardType) => {
  const { houserService, onDelete } = props;

  const location = `${houserService?.service?.city}, ${houserService?.service?.country}`;
  const houserPrice = parseInt(houserService.service?.houser_comission, 10);
  const grossComission = parseInt(houserService.service?.gross_comission, 10);
  const price = houserPrice + grossComission;
  const money = houserService?.service?.money?.symbol;

  const handleDelete = () => {
    onDelete(houserService.id);
  };

  return (
    <div key={houserService.id} className={styles.root}>
      <div className={styles.header}>
        <h5 className={styles.title}>
          {houserService.service?.name} #{houserService.service?.id}
        </h5>
        <BadgeLevel level={houserService.service?.houser_level_id} />
      </div>
      <div className={styles.content}>
        <p className={styles.item}>
          <span className={styles.label}>Ubicación: </span>
          {location}
        </p>
        <p className={styles.item}>
          <span className={styles.label}>Cuidador/Proveedor : </span>
          {money + houserPrice}
        </p>
        <p className={styles.item}>
          <span className={styles.label}>Comisión Bruta : </span>
          {money + grossComission}
        </p>
        <p className={styles.item}>
          <span className={styles.label}>Precio Total: </span>
          {money + price}
        </p>
      </div>
      <div className={styles.actions}>
        <Button
          className={styles.delete}
          size="small"
          color="inherit"
          startIcon={<IconTrash size={18} />}
          onClick={handleDelete}>
          Eliminar
        </Button>
      </div>
    </div>
  );
};

export default HouserServiceCard;
