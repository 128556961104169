import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CameraPlusSvg = () => (
  <>
    <path
      fill="currentColor"
      d="M3 8c0 .55.45 1 1 1s1-.45 1-1V6h2c.55 0 1-.45 1-1s-.45-1-1-1H5V2c0-.55-.45-1-1-1s-1 .45-1 1v2H1c-.55 0-1 .45-1 1s.45 1 1 1h2v2z"></path>
    <circle cx="13" cy="14" r="3" fill="currentColor"></circle>
    <path
      fill="currentColor"
      d="M21 6h-3.17l-1.24-1.35A1.99 1.99 0 0015.12 4h-6.4c.17.3.28.63.28 1 0 1.1-.9 2-2 2H6v1c0 1.1-.9 2-2 2-.37 0-.7-.11-1-.28V20c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"></path>
  </>
);

const CameraPlus = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      aria-hidden={true}
      viewBox="0 0 24 24"
      {...props}>
      <CameraPlusSvg />
    </SvgIcon>
  );
};

export default CameraPlus;
