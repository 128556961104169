import React from 'react'
import PropTypes from 'prop-types'
import * as VARIABLES from 'constants/variables'
// Components
import ReservationStatus from 'components/ReservationStatus'

const ReservationInfo = props => {
  const { booking } = props

  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: "numeric", hour12: true, minute: "2-digit", timeZone: 'UTC' }

  return (
    <div className="dh-card-border res-detail">
      <div className="dh-flex content-space">
        <div className="client-reservation-detail__section-title">
          <h3>Detalles de la Reserva</h3>
          <div className="border-space"/>
        </div>
        <ReservationStatus
          status={booking.state}
          endDate={booking.pickup_date}
        />
      </div>
      <div className="res-detail__body">
        <div className="res-detail__body--grid-1">
          <div className="res-detail__body--item">
            <p>Codigo de la reserva</p>
            <h4>#{booking.id}</h4>
          </div>
          <div className="res-detail__body--item">
            <p>Fecha de entrada</p>
            <h4>{new Date(booking.drop_date).toLocaleString('es', dateOptions)}</h4>
          </div>
          <div className="res-detail__body--item">
            <p>Fecha de salida</p>
            <h4>{new Date(booking.pickup_date).toLocaleString('es', dateOptions)}</h4>
          </div>
        </div>
        <div className="res-detail__body--grid-1">
          {booking.booking_has_pets.map((data, index) => (
            <div className="res-detail__body--item" key={index}>
              <p>Mascota {index + 1}</p>
              <h4>{data.pet.name}</h4>
            </div>
          ))}
        </div>
        <div className="res-detail__body--grid-1">
          <div className="res-detail__body--item">
            <p>Servicio</p>
            <h4>{booking.service.name}</h4>
          </div>
        </div>
        {/* <div className="res-detail__body--grid-3">
          <div className="res-detail__body--item">
            <p>Mascotas descripción</p>
            <h4>{booking.pets_desc}</h4>
          </div>  
        </div> */}
        <div className="divider-line" style={{ marginTop: '8px' }}/>
        <div className="res-detail__body--item-price">
          <div>
          <p>Total:</p>
            <h3>
              {VARIABLES.CURRENCY_BY_COUNTRY[booking.service.country]}
              {(booking.price_houser - booking.price_discount).toFixed(2)}
            </h3>
          </div>
          <div>
          <p>Descuento:</p>
            <h3>
              {VARIABLES.CURRENCY_BY_COUNTRY[booking.service.country]}
              {(booking.price_discount).toFixed(2)}
            </h3>
          </div>
        </div>
      </div>
    </div>
  )
}

ReservationInfo.propTypes = {
  reservation: PropTypes.object.isRequired,
  services: PropTypes.array
}

export default ReservationInfo
