import { ReactNode, CSSProperties } from 'react';
import clsx from 'clsx';

import styles from './sectionTitle.module.scss';

interface SectionTitleType {
  title: ReactNode | string;
  text?: string;
  titleComponent?: ReactNode | any;
  disablePadding?: boolean;
  className?: string;
  style?: CSSProperties;
}

const SectionTitle = (props: SectionTitleType) => {
  const {
    title,
    text,
    titleComponent: TitleComponent = 'h3',
    disablePadding = false,
    className,
    ...other
  } = props;

  const cn = clsx(styles.root, { [styles.padding]: !disablePadding }, className);

  return (
    <div className={cn} {...other}>
      <TitleComponent className={styles.title}>{title}</TitleComponent>
      {text && <p className={styles.small}>{text}</p>}
    </div>
  );
};

export default SectionTitle;
