import requests from './requestsOld';
import fetchApi from './fetchApi';

const loginUser = (body: any) => {
  const url = '/login';

  return fetchApi(requests.post(url, body));
};

const registerUser = (body: any) => {
  const url = '/register';

  return fetchApi(requests.post(url, body));
};

const authServices = { loginUser, registerUser };

export default authServices;
