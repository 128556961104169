import React, { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import * as ROLES from './constants/roles';
export const Context = createContext();
/* 
  TODO: Implementar JsonWebToken para validar la sesion del Usuario
  Implementarlo en "isAuth"
*/
const Provider = ({ children }) => {
  // Cookies
  const [cookies, setCookies, removeCookies] = useCookies([
    'id',
    'houserId',
    'token',
    'role',
    'confirmation',
    'terms_conditions',
  ]);

  // unread Notification count
  const [notificationCt, setNotificationCt] = useState(() => {
    return window.localStorage.getItem('notifyCt');
  });

  // redirect after login or register
  const [redirectTo, setRedirectTo] = useState(() => {
    return window.localStorage.getItem('redirectTo');
  });
  // store booking request info before redirect
  const [tempRequest, setTempRequest] = useState(() => {
    return window.localStorage.getItem('tempRequest');
  });
  // Indicador de Usuario - ¿JsonWebToken?
  const [isAuth, setAuth] = useState(() => {
    return cookies.token;
  });
  // Usuario Tipo Cliente
  const [client, setClient] = useState(() => {
    if (
      cookies.role === ROLES.CLIENTE_STR ||
      cookies.role === ROLES.CLIENTE_INT ||
      cookies.role === ROLES.CLIENTE_CUIDADOR_STR ||
      cookies.role === ROLES.CLIENTE_CUIDADOR_INT
    ) {
      return true;
    } else {
      return false;
    }
  });
  // Usuario Tipo Cuidador o Houser
  const [houser, setHouser] = useState(() => {
    if (
      cookies.role === ROLES.CUIDADOR_STR ||
      cookies.role === ROLES.CUIDADOR_INT ||
      cookies.role === ROLES.CLIENTE_CUIDADOR_STR ||
      cookies.role === ROLES.CLIENTE_CUIDADOR_INT
    ) {
      return true;
    } else {
      return false;
    }
  });
  // Usuario Tipo Admin
  const [admin, setAdmin] = useState(() => {
    if (cookies.role === ROLES.ADMIN_STR || cookies.role === ROLES.ADMIN_INT) {
      return true;
    } else {
      return false;
    }
  });
  // Indicador si el usuario a validado su numero de telefono
  const [statusClient, setStatusClient] = useState(() => {
    if (cookies.confirmation === '1' || cookies.confirmation === 1) {
      return true;
    } else {
      return false;
    }
  });
  // Indicador si el cuidador a validado sus datos
  const [statusHouser, setStatusHouser] = useState(() => {
    if (cookies.terms_conditions === '1' || cookies.terms_conditions === 1) {
      return true;
    } else {
      return false;
    }
  });

  const [country, setCountry] = useState(() => {
    return window.localStorage.getItem('country');
  });

  const [city, setCity] = useState(() => {
    return window.localStorage.getItem('city');
  });

  const [userName, setUserName] = useState(() => {
    return window.localStorage.getItem('userName');
  });
  const [userImage, setUserImage] = useState(() => {
    return window.localStorage.getItem('userImage');
  });

  const value = {
    notificationCt,
    tempRequest,
    redirectTo,
    isAuth,
    statusClient,
    statusHouser,
    cookies,
    admin,
    client,
    houser,
    country,
    city,
    userName,
    userImage,
    activateAuth: (token, id, houserId, stClient, stHouser) => {
      setAuth(true);
      setCookies('token', token, { path: '/' });
      setCookies('id', id, { path: '/' });
      setCookies('houserId', houserId, { path: '/' });
      setCookies('confirmation', stClient, { path: '/' });
      setCookies('terms_conditions', stHouser, { path: '/' });
      if (stClient === '1' || stClient === 1) {
        setStatusClient(true);
      }
      if (stHouser === '1' || stHouser === 1) {
        setStatusHouser(true);
      }
    },
    removeAuth: () => {
      setAuth(false);
      removeCookies('token', { path: '/' });
      removeCookies('id', { path: '/' });
      removeCookies('houserId', { path: '/' });
      setClient(false);
      setHouser(false);
      setAdmin(false);
      setStatusClient(false);
      setStatusHouser(false);
      removeCookies('confirmation', { path: '/' });
      removeCookies('terms_conditions', { path: '/' });
      removeCookies('role', { path: '/' });
    },
    setRole: (role) => {
      if (role === ROLES.ADMIN_STR || role === ROLES.ADMIN_INT) {
        setAdmin(true);
      } else if (role === ROLES.CUIDADOR_STR || role === ROLES.CUIDADOR_INT) {
        setHouser(true);
      } else if (role === ROLES.CLIENTE_STR || role === ROLES.CLIENTE_INT) {
        setClient(true);
      } else if (role === ROLES.CLIENTE_CUIDADOR_STR || role === ROLES.CLIENTE_CUIDADOR_INT) {
        setClient(true);
        setHouser(true);
      }
      setCookies('role', role, { path: '/' });
    },
    removeRole: () => {
      setClient(false);
      setHouser(false);
      setAdmin(false);
      removeCookies('role', { path: '/' });
    },
    setLocation: (country, city) => {
      setCountry(country);
      setCity(city);
      window.localStorage.setItem('country', country);
      window.localStorage.setItem('city', city);
    },
    setCountry: (country) => {
      setCountry(country);
      window.localStorage.setItem('country', country);
    },
    setCity: (city) => {
      setCity(city);
      window.localStorage.setItem('city', city);
    },
    removeLocation: () => {
      setCountry('');
      setCity('');
      window.localStorage.removeItem('country');
      window.localStorage.removeItem('city');
    },
    setUserData: (name, image) => {
      setUserName(name);
      setUserImage(image);
      window.localStorage.setItem('userName', name);
      window.localStorage.setItem('userImage', image);
    },
    removeUserData: () => {
      setUserName('');
      setUserImage('');
      window.localStorage.removeItem('userName');
      window.localStorage.removeItem('userImage');
    },
    setRedirect: (path) => {
      setRedirectTo(path);
      window.localStorage.setItem('redirectTo', path);
    },
    storeTempRequest: (data) => {
      setTempRequest(data);
      window.localStorage.setItem('tempRequest', JSON.stringify(data));
    },
    setNotificationCount: (count) => {
      setNotificationCt(count);
      window.localStorage.setItem('notifyCt', count);
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Provider,
  Consumer: Context.Consumer,
};
