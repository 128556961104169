import React, { useState, useContext, useEffect } from 'react';
import { Context } from '@root/Context';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import './cuidadorNotificaciones.scss';
// Services
import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
// Constants
// eslint-disable-next-line no-unused-vars
import useNotificationValue from 'hooks/useNotificationValue';
import { useResponsive } from '@root/hooks';
// Componentes
import CommentArea from 'components/commentArea/CommentArea';
import Notification from 'components/notification';
// Material-UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Skeleton from '@material-ui/lab/Skeleton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';

import EmailIcon from 'icons/EmailIcon';
import CellPhoneIcon from 'icons/CellPhoneIcon';
import CheckIcon from 'icons/CheckIcon';
import DeleteIcon from 'icons/DeleteIcon';

// Material ICONS
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import moment from 'moment';

const variantIcon = {
  email: EmailIcon,
  phone: CellPhoneIcon,
  success: CheckIcon,
  error: DeleteIcon,
};

const Notificacion = (props) => {
  const { notification, onRemove } = props;
  const Icon = variantIcon[notification.type];
  const { isDesktop } = useResponsive();

  return (
    <React.Fragment>
      {isDesktop ? (
        <Grid container spacing={3}>
          <Grid item xs={2} sm={2} md={2}>
            <div className="dh-cuidador-notificacion__icon">
              {/* <NotificationsNoneIcon style={{fontSize: 40, color:"#5a5655"}} /> */}
              <Avatar className={`persistent-notification__image ${notification.type}`}>
                <Icon />
              </Avatar>
            </div>
          </Grid>
          <Grid item xs={8} smd={8} md={8} style={{ alignSelf: 'center' }}>
            <div className="dh-cuidador-notificacion__header">
              <div className="dh-cuidador-notificacion__header__hora">
                <h4>{moment(notification.created_date).format('YYYY-MM-DD')}</h4>
                <p> {moment(notification.created_date).format('HH:mm')}</p>
              </div>
            </div>
            <div className="dh-cuidador-notificacion__body">
              <p dangerouslySetInnerHTML={{ __html: notification.message }}></p>
            </div>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <div
              className="dh-cuidador-notificacion__action"
              onClick={(e) => {
                e.stopPropagation();
                onRemove(notification);
              }}>
              <DeleteOutlineIcon style={{ fontSize: 40, color: '#5a5655' }} />
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="dh-cuidador-notificacion__header">
              <div className="dh-cuidador-notificacion__header__hora">
                <h4>{moment(notification.created_date).format('YYYY-MM-DD')}</h4>
                <p> {moment(notification.created_date).format('HH:mm')}</p>
              </div>
            </div>
            <div className="dh-cuidador-notificacion__body">
              <p dangerouslySetInnerHTML={{ __html: notification.message }}></p>
            </div>
            <div className="dh-cuidador-notificacion__action">
              <h3>Aceptar</h3>
            </div>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const comparar = (a, b) => {
  if (a.id > b.id) {
    return -1;
  }
  if (a.id < b.id) {
    return 1;
  }
  // a must be equal to b
  return 0;
};

function CuidadorNotificaciones(props) {
  // Context
  const { cookies, notificationCt, setNotificationCount } = useContext(Context);
  const [loading, setLoading] = useState(false);
  // Variables de "Notification"
  const notification = useNotificationValue();
  // Array de notifications
  const [notifications, setNotifications] = useState([]);

  const [value, setValue] = React.useState(0);
  const { isDesktop } = useResponsive();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Constantes de las tabs de responsive
  const AntTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: 'var(--primaryColorDark)',
    },
  })(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontSize: '0.9rem',
      fontFamily: 'Montserrat-Semibold',
      '&:hover': {
        color: 'var(--primaryColor)',
        opacity: 1,
      },
      '&$selected': {
        color: 'var(--primaryColor)',
      },
      '&:focus': {
        color: 'var(--primaryColor)',
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  const fetchNotificaciones = async () => {
    try {
      const response = await fetch(
        SERVICES.ALL_NOTIFICATIONS_BY_HOUSER + cookies.houserId,
        OPTIONS.GET(),
      );
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (data.notificaciones) {
          setNotifications(data.notificaciones.sort(comparar));
          setLoading(false);
        }
      } else {
        notification.handleSnackbar(data.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cookies.id) {
      setLoading(true);
      fetchNotificaciones();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.id]);

  useEffect(() => {
    if (notificationCt > 0) fetchReadAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationCt]);

  const fetchReadAll = async () => {
    const response = await fetch(
      `${SERVICES.READ_ALL_NOTIFICATION}${cookies.houserId}`,
      OPTIONS.GET(),
    );
    const data = await response.json();
    if (data) {
      setNotificationCount(0);
    }
  };

  const redirectToBooking = (notification) => {
    props.history.push(`/cuenta/reservas/${notification.booking_id}`);
  };

  const removeNotification = async (notification) => {
    try {
      const response = await fetch(
        SERVICES.REMOVE_NOTIFICATION + notification.id,
        OPTIONS.GET(),
      );
      const data = await response.json();
      if (response.status === 200) {
        fetchNotificaciones();
      } else {
        notification.handleSnackbar(data.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {isDesktop ? (
        <div className="cuidador-notification-layout">
          <Container maxWidth="lg">
            <h1 style={{ paddingBottom: '0.5rem' }}>Notificaciones y comentarios</h1>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <div className="dh-cuidador-notificaciones">
                  {!loading ? (
                    <React.Fragment>
                      {notifications &&
                        notifications.map((notificacion, index) => (
                          <div
                            className="dh-cuidador-notificacion dh-card-elevation"
                            key={index}
                            onClick={() => redirectToBooking(notificacion)}>
                            <Grid container spacing={2}>
                              <React.Fragment>
                                <Notificacion
                                  notification={notificacion}
                                  onRemove={removeNotification}
                                />
                              </React.Fragment>
                            </Grid>
                          </div>
                        ))}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Skeleton height={100} width="100%"></Skeleton>
                      <Skeleton height={100} width="100%"></Skeleton>
                      <Skeleton height={100} width="100%"></Skeleton>
                    </React.Fragment>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="dh-listComment__contents">
                  <CommentArea
                    idCuidador={cookies.houserId}
                    type="cuidador"
                    commentIsDisable={true}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <React.Fragment>
          <div className="cuidador-notificaciones-mobile__appbar">
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label="ant example"
              centered
              variant="fullWidth">
              <AntTab label="Notificaciones" {...a11yProps(0)} />
              <AntTab label="Comentarios" {...a11yProps(1)} />
            </AntTabs>
          </div>
          <div className="cuidador-notificaciones-mobile__contain">
            <TabPanel value={value} index={0}>
              <Grid item xs={12} md={6}>
                <div className="dh-cuidador-notificaciones">
                  {!loading ? (
                    <React.Fragment>
                      {notifications &&
                        notifications.map((notificacion, index) => (
                          <div
                            className="dh-cuidador-notificacion dh-card-elevation"
                            key={index}
                            onClick={() => redirectToBooking(notificacion)}>
                            <Grid container spacing={2}>
                              <React.Fragment>
                                <Notificacion
                                  notification={notificacion}
                                  onRemove={removeNotification}
                                />
                              </React.Fragment>
                            </Grid>
                          </div>
                        ))}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Skeleton height={100} width="100%"></Skeleton>
                      <Skeleton height={100} width="100%"></Skeleton>
                      <Skeleton height={100} width="100%"></Skeleton>
                    </React.Fragment>
                  )}
                </div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid item xs={12} md={6}>
                <div className="dh-listComment__contents">
                  <CommentArea
                    idCuidador={cookies.houserId}
                    type="cuidador"
                    commentIsDisable={true}
                  />
                </div>
              </Grid>
            </TabPanel>
          </div>
        </React.Fragment>
      )}
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </React.Fragment>
  );
}

export default CuidadorNotificaciones;
