import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
  default: 'var(--iconColor)',
  white: 'var(--iconColorWhite)'
}

function PencilIcon(props) {
  const { setColor } = props
  let color = "var(--iconColorBlack)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 488.471 488.471"
      height={512}
      viewBox="0 0 488.471 488.471"
      width={512}
      xmlns="http://www.w3.org/2000/svg">
        <path d="m166.093 240.214h88.205v194.288h-88.205z" transform="matrix(.707 -.707 .707 .707 1.415 9.959)" fill={color} />
        <path d="m245.547 105.541-245.547 245.547v137.382h137.382l245.548-245.547z" fill={color} />
    </svg>
  )
}

export default PencilIcon
