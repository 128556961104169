import { ResponseServicesType } from '@root/interfaces/Response';
import requests from './requests';

const getServicesByLocation = (
  country: string,
  city: string,
): Promise<ResponseServicesType> => {
  const url = `/services/filter/${country}/${city}`;

  return requests.get(url);
};

const services = {
  getServicesByLocation,
};

export default services;
