import React from 'react'
import PropTypes from 'prop-types';

const CardAssociatedAccounts = props => {
  const { houser } = props
  return (
    <div className="card-admin-list dh-card-elevation">
      <div className="card-admin-list__header">
        <span>Cuentas asociadas</span>
      </div>
      <div className="card-admin-list__content">
        <table className="dh-table">
          <tbody>
            <tr>
              <td className="td-title facebook">Facebook</td>
              <td className="td-text">{houser.facebook_email}</td>
            </tr>
            <tr className="dh-selected">
              <td className="td-title google">Google</td>
              <td className="td-text">{houser.google_email}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

CardAssociatedAccounts.propTypes = {
  loading: PropTypes.bool,
  houser: PropTypes.object.isRequired,
}

export default CardAssociatedAccounts
