import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function ExoIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 512 512"
      style={{enableBackground: 'new 0 0 512 512'}}
      xmlSpace="preserve">
      <g>
        <g>
          <path d="M256,7C118.5,7,7,118.5,7,256s111.5,249,249,249s249-111.5,249-249S393.5,7,256,7z M398.9,156.6v11.1
            c0,5.7-4.6,10.3-10.3,10.3h-35.3V90.3c0-1.3-0.1-2.6-0.3-3.9C380,98.2,398.9,125.1,398.9,156.6z M167.6,80
            c5.7,0,10.3,4.6,10.3,10.3c0,6.5,5.3,11.8,11.8,11.8h22.4c18.4-13.9,41.2-22.1,66-22.1h44.2c5.7,0,10.3,4.6,10.3,10.3v154.6
            c0,17.1-4.9,33-13.3,46.4h-50.1c4.3-11,6.7-22.9,6.7-35.3v-77.3c0-17.1-13.9-30.9-31-30.9c-26.8,0-52.4,7.4-74.6,21.2
            c3.3-17.2,10.6-32.9,20.9-46.2h-1.6c-17.9,0-32.4-14.5-32.4-32.4C157.3,84.6,161.9,80,167.6,80z M90.3,332.6
            c-5.7,0-10.3-4.6-10.3-10.3c0-5.7,4.6-10.3,10.3-10.3h13.2v10.3c0,3.6,0.6,7.1,1.8,10.3H90.3z M124.2,322.3v-33.2
            c0-66.7,54-120.8,120.8-120.8c5.7,0,10.3,4.6,10.3,10.3V256c0,42.3-34.2,76.6-76.6,76.6h-44.2C128.8,332.6,124.2,328,124.2,322.3z
              M244.2,366.5c0,36.1-29.4,65.6-65.6,65.6c-5.7,0-10.3-4.6-10.3-10.3v-68.5h75.9V366.5z M421.7,332.6H288.4v89.1
            c0,5.7-4.6,10.3-10.3,10.3s-10.3-4.6-10.3-10.3v-89.1h-29.3c7.5-5.9,14.1-12.8,19.6-20.6h163.6c5.7,0,10.3,4.6,10.3,10.3
            C432,328,427.4,332.6,421.7,332.6z" fill={color}/>
          <circle cx="288.4" cy="124.2" r="10.3" fill={color}/>
        </g>
      </g>
    </svg>
  )
}

export default ExoIcon
