import requests from './requestsOld';
import fetchApi from './fetchApi';

const getAll = () => {
  const url = '/clients';

  return fetchApi(requests.get(url));
};

const getById = (user_id) => {
  const url = `/users/${user_id}/`;

  return fetchApi(requests.get(url));
};

const users = {
  getAll,
  getById,
};

export default users;
