/**
 * Roles del Usuario
 * Cuidador => 0
 * Cliente => 1
 * Cliente/Cuidador => 2
 * Proveedor => 3
 * Admin => 64
 * Cualquier otro numero es tomado como ERROR
**/
import React from 'react'
import './badgRole.scss'

function BadgeRole(props) {
  const role = props.role;
  let badge;

  if (role === 0 || role === '0') {
    badge = (
      <div className="dh-badge-role cuidador">Cuidador</div>
    )
  } else if (role === 1 || role === '1') {
    badge = (
      <div className="dh-badge-role cliente">Cliente</div>
    )
  } else if (role === 2 || role === '2') {
    badge = (
      <div className="dh-badge-role cliente-cuidador">Cliente/Cuidador</div>
    )
  } else if (role === 3 || role === '3') {
    badge = (
      <div className="dh-badge-role proveedor">Proveedor</div>
    )
  } else if (role === 64 || role === '64') {
    badge = (
      <div className="dh-badge-role admin">Administrador</div>
    )
  } else {
    badge = (
      <div className="dh-badge-role error">...</div>
    )
  }
  
  return (
    <React.Fragment>
      {badge}
    </React.Fragment>
  )
}

export default BadgeRole
