import { CSSProperties, useState, useMemo } from 'react';
import clsx from 'clsx';

import CameraPlusIcon from '@root/assets/icons/CameraPlusIcon';
import NoPhoto from '@root/assets/images/ph_no_foto.png';
import styles from './styles.module.scss';

interface HoverEffectImageUpdateType {
  src?: string;
  alt?: string;
  height?: string | number;
  width?: string | number;
  style?: CSSProperties;
  className?: string;
  variant?: 'circular' | 'rounded' | 'square';
  htmlFor?: string;
}

const HoverEffectImageUpdate = (props: HoverEffectImageUpdateType) => {
  const { src, alt, height, width, style, className, variant = 'circular', htmlFor } = props;
  const [image, setImage] = useState(src);

  useMemo(() => setImage(src), [src]);

  const cn = clsx(
    styles.root,
    {
      [styles.circular]: variant === 'circular',
      [styles.rounded]: variant === 'rounded',
      [styles.square]: variant === 'square',
    },
    className,
  );

  const sl = { height: height, width: width, ...style };

  const onError = () => {
    setImage(NoPhoto);
  };

  return (
    <div className={cn} style={sl}>
      <label htmlFor={htmlFor} role="button" className={styles.button}>
        {image && (
          <span className={styles.imgWrap}>
            <img src={image} alt={alt} onError={onError} />
          </span>
        )}
        <div className={clsx(styles.placeholder, { [styles.full]: image })}>
          <CameraPlusIcon width={24} height={24} />
          <span>Actualizar foto</span>
        </div>
      </label>
    </div>
  );
};

export default HoverEffectImageUpdate;
