import React from 'react'
import { Link as RouterLink } from "react-router-dom";
// Material - UI
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// Images
import dhLogo from '../../assets/dg_logo_text.png';

const useStyles = makeStyles(theme => ({
  simpleHeader: {
    width: '100%',
    backgroundColor: 'var(--white)',
    boxShadow: '0 2px 8px -2px rgba(51, 51, 51, 0.2)',
    paddingTop: '4px',
    position: 'fixed',
    zIndex: '999'
  },
  imgLogo: {
    width: '160px',
    height: '40px',
    paddingBottom: '0.6rem',
    paddingTop: '0.5rem',
  },
}));

function SimpleAppBar() {
  const classes = useStyles();

  return (
    <header className={classes.simpleHeader}>
      <Container maxWidth="lg">
        <RouterLink to="/">
          <img className={classes.imgLogo} src={dhLogo} alt="Logo de la web"></img>
        </RouterLink>
      </Container>
    </header>
  )
}

export default SimpleAppBar
