import React, { useState } from 'react'

function useSearchServices(servicios) {
  const [ query, setQuery ] = useState('');
  const [ searchServices, setSearchServices ] = useState(servicios);

  React.useMemo(() => {
    const result = servicios.filter(servicio => {
      return `${servicio.name} ${servicio.houser_level_id}`
        .toLowerCase()
        .includes(query.toLowerCase());
    });
    setSearchServices(result)
  }, [ servicios, query ]);

  return { query, setQuery, searchServices }
}

export default useSearchServices
