import React, { useState, useContext, useEffect } from "react";
import { Context } from "@root/Context";
import { Link as RouterLink } from "react-router-dom";
import "./cuidadorPublicacion.scss";
// Constants
import * as ROUTES from "constants/routes";
// Services
import * as SERVICES from "services/config";
import * as OPTIONS from "services/options";
// Utils
import replacePathParams from "utils/replacePathParams";
// Hooks
import useNotificationValue from "hooks/useNotificationValue";
// components
import Notification from "components/notification";
import BadgePublication from "components/badgePublication";
import ServiceGroup from "components/serviceGroup/ServiceGroup";
import LoadingIcon from "components/loadingIcon";
import LoadingText from "components/loadingText";
import { GalleryPublication, PublicationLocation } from "./components";
// Material-UI
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// Material Icon
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";

function CuidadorPublicacion() {
  const { cookies } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const notification = useNotificationValue();

  const [data, setData] = useState({
    name: "",
    email_confirmed: 0,
    confirmation: 0,
    houser: {
      type_delivery: "",
      type_exclusive: "",
      type_share: "",
      pills: "",
      injectables: "",
      allowed_house: "",
      allowed_sofa: "",
      allowed_bed: "",
      galleries: [],
      publication: {
        description_pub: "",
        id: null,
        title: "",
      },
    },
    location: {
      district: "",
    },
  });

  const fetchCuidador = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        SERVICES.GET_CUIDADOR_BY_ID,
        OPTIONS.POST({ id: cookies.id })
      );
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        setData(data.user);
        setLoading(false);
      } else {
        notification.handleSnackbar(data.message, "error");
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCuidador();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.id]);

  return (
    <div className="cuidador-publication">
      <Container maxWidth="lg">
        <div className="cuidador-publication__title dh-space-between">
          <h1>Anuncio</h1>
          <RouterLink
            to={replacePathParams(ROUTES.CUIDADOR_DETAIL, { id: cookies.id })}
          >
            <button className="btn-primary">
              <PersonRoundedIcon />
              Vista Previa
            </button>
          </RouterLink>
        </div>
        <GalleryPublication
          loading={loading}
          galleries={data.houser.galleries}
        />
        <div className="divider-line" />
        <div className="publication-card cuidador-publication__servicios">
          <div className="cuidador-publication__sub-title">
            <h3>Servicio</h3>
          </div>
          <Grid container spacing={1} direction="row" alignItems="center">
            {!loading ? (
              <Grid item xs={12} sm={10} className="servicio-container">
                <ServiceGroup data={data.houser} />
              </Grid>
            ) : (
              <Grid item xs={12} sm={10} className="servicio-container">
                <LoadingIcon count={6} />
              </Grid>
            )}
            <Grid item xs={12} sm={2} className="btn-container">
              <RouterLink
                to={ROUTES.CUIDADOR_PUBLICACION_SERVICIOS}
                className="dh-no-decoration"
              >
                <button className="btn-primary border">Editar</button>
              </RouterLink>
            </Grid>
          </Grid>
        </div>
        <div className="divider-line" />
        <div className="publication-card cuidador-publication__publicacion">
          <div className="cuidador-publication__sub-title">
            <h3>Título, descripción y Vídeo</h3>
          </div>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              {!loading ? (
                <React.Fragment>
                  {data.houser.publication ? (
                    <React.Fragment>
                      <h4 className="sub-title">
                        {data.houser.publication.title}
                      </h4>
                      <p className="text-primary">
                        {data.houser.publication.description_pub}
                      </p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <h4 className="sub-title">No se encontró el titulo</h4>
                      <p className="text-primary">
                        No se encontró la descripción
                      </p>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <LoadingText count={1} />
              )}
            </Grid>
            <Grid item xs={12} sm={2} className="btn-container">
              <RouterLink
                to={ROUTES.CUIDADOR_PUBLICACION_INFO}
                className="dh-no-decoration"
              >
                <button className="btn-primary border">Editar</button>
              </RouterLink>
            </Grid>
          </Grid>
        </div>
        <div className="divider-line" />
        <PublicationLocation loading={loading} user={data} />
        <div className="divider-line" />
        <div className="publication-card cuidador-publication__status">
          <div className="cuidador-publication__sub-title">
            <h3>Estado del anuncio</h3>
          </div>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <BadgePublication
                status={data.houser.publication.publication_state}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default CuidadorPublicacion;
