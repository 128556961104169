import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Context } from '@root/Context';
import './clientReservations.scss';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Utils
import replacePathParams from 'utils/replacePathParams';
// Constants
import * as ROUTES from 'constants/routes';
import * as VARIABLES from 'constants/variables';
// Hooks
import { useSnackbar } from '@root/hooks';

import { ReservationState } from './components';
// Material UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
// Material Icons
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';

function ClientReservations(props) {
  const { cookies } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const snackbar = useSnackbar();

  const [btnState, setBtnState] = useState({
    btn1: '',
    btn2: 'active',
    btn3: '',
    btn4: '',
  });

  // Array de reservas
  const [bookings, setBookings] = useState([]);
  const [resState1, setResState1] = useState([]);
  const [resState2, setResState2] = useState([]);
  const [resState3, setResState3] = useState([]);
  const [resState4, setResState4] = useState([]);

  const handleClick = (value) => {
    setBtnState({
      [value]: 'active',
    });

    if (value === 'btn1') {
      setBookings(resState1);
    } else if (value === 'btn2') {
      setBookings(resState2);
    } else if (value === 'btn3') {
      setBookings(resState3);
    } else if (value === 'btn4') {
      setBookings(resState4);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    let userId = cookies.id;
    await sendData(SERVICES.ALL_BOOKINGS_BY_CLIENT + userId, OPTIONS.GET());
  };

  const sendData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log(data);
      if (response.status === 200) {
        if (data.bookings) {
          const state1 = data.bookings.filter((booking) => booking.state === 1);
          const state2 = data.bookings.filter((booking) => booking.state === 2);
          const state3 = data.bookings.filter(
            (booking) => booking.state === 3 || booking.state === 5 || booking.state === 6,
          );
          const state4 = data.bookings.filter((booking) => booking.state === 4);
          setResState1(state1);
          setResState2(state2);
          setResState3(state3);
          setResState4(state4);
          setBookings(state2);
        }
        setLoading(false);
      } else {
        snackbar.error(data.message);
        setLoading(false);
      }
    } catch (error) {
      snackbar.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="client-reservation-list">
      <Container maxWidth="lg">
        <div className="client-reservation-list__title">
          <h1 className="dh-title-section">Mis Reservas - Cliente</h1>
        </div>
        <div className="client-reservation-list__toolbar">
          <div className="client-reservation-list__actions">
            <button
              className={`btn-primary border ${btnState.btn1}`}
              onClick={() => handleClick('btn1')}>
              Por confirmar
            </button>
            <button
              className={`btn-primary border ${btnState.btn2}`}
              onClick={() => handleClick('btn2')}>
              Pendiente de pago
            </button>
            <button
              className={`btn-primary border ${btnState.btn3}`}
              onClick={() => handleClick('btn3')}>
              Confirmadas
            </button>
            <button
              className={`btn-primary border ${btnState.btn4}`}
              onClick={() => handleClick('btn4')}>
              Rechazadas
            </button>
          </div>
        </div>
        <div className="client-reservation-list__content">
          {!loading ? (
            <React.Fragment>
              {bookings.length > 0 ? (
                <Grid container spacing={3}>
                  {bookings.map((booking, index) => {
                    let relStartYear = new Date(booking.drop_date).getFullYear();
                    let relStartMonth = new Date(booking.drop_date).getMonth() + 1;
                    let relStartDay = new Date(booking.drop_date).getDate() + 1;

                    let relEndYear = new Date(booking.pickup_date).getFullYear();
                    let relEndMonth = new Date(booking.pickup_date).getMonth() + 1;
                    let relEndDay = new Date(booking.pickup_date).getDate() + 1;

                    return (
                      <Grid item xs={12} sm={6} md={6} key={index}>
                        <RouterLink
                          to={replacePathParams(ROUTES.CLIENTE_RESERVATIONS_DETAIL, {
                            id: booking.id,
                          })}>
                          <div className="dh-card-border reservation-card">
                            <div className="reservation-card__head">
                              <p>#{booking.id}</p>
                              <ReservationState
                                status={booking.state}
                                endDate={booking.pickup_date}
                              />
                            </div>
                            <div className="reservation-card__user-name">
                              <h3>
                                {booking.houser.user.name} {booking.houser.user.last_name}
                              </h3>
                            </div>
                            <div className="reservation-card__item">
                              <RoomRoundedIcon />
                              <span>{booking.houser.user.address}</span>
                            </div>
                            <div className="reservation-card__item">
                              <EventRoundedIcon />
                              <span>
                                {relStartDay} {VARIABLES.VARIANT_MONTH[relStartMonth]}{' '}
                                {relStartYear} - {relEndDay}{' '}
                                {VARIABLES.VARIANT_MONTH[relEndMonth]} {relEndYear}
                              </span>
                            </div>
                          </div>
                        </RouterLink>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <div style={{ padding: '3.5rem 0 3rem 0' }}>
                  <h2>No tienes Reservas</h2>
                </div>
              )}
            </React.Fragment>
          ) : (
            <Grid container spacing={3}>
              {Array.from(new Array(6)).map((data, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                  <Skeleton width="100%" height="150px" />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </Container>
    </div>
  );
}

export default ClientReservations;
