import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context } from '@root/Context';
import './houserList.scss';
// Dependencies
import GoogleMapReact from 'google-map-react';
// Hooks
import { useResponsive } from '@root/hooks';
import { useLocationManagement } from 'hooks/useLocationManagement';
import useSearchUsers from 'hooks/useSearchUsers';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables';
// Components
import SearchInput from 'components/SearchInput/SearchInput';
import { FilterDesktop, FilterPortrait, HouserGrid, HousersNotFound } from './components';
// Material - UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Material-Icons
import RoomIcon from '@material-ui/icons/Room';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'var(--white)',
    color: 'var(--textColor)',
    maxWidth: 220,
    fontFamily: 'Montserrat-Medium',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: 'box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
  },
}))(Tooltip);

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: 'var(--primaryColorDark)',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontSize: '0.9rem',
    fontFamily: 'Montserrat-Semibold',
    '&:hover': {
      color: 'var(--primaryColor)',
      opacity: 1,
    },
    '&$selected': {
      color: 'var(--primaryColor)',
    },
    '&:focus': {
      color: 'var(--primaryColor)',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}>
      {children}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

function CardHouserMovil({ user }) {
  return (
    <RouterLink to={`/cuidador/${user.id}`}>
      <div className="dh-card-houser-movil">
        <div className="dh-card-houser-movil__card-desing">
          <div className="dh-card-houser-movil__cover-image">
            <img
              src={`${VARIABLES.IMAGE_URL}${user.houser.galleries[0].photo}`}
              alt={user.name}
            />
          </div>
          <div className="dh-card-houser-movil__content">
            <div>
              <h3>{user.name}</h3>
              <div className="dh-card-houser-movil__content__location">
                <RoomIcon />
                <span>{user.location.district}</span>
              </div>
            </div>
            <div>
              <p className="dh-card-houser-movil__content__title">
                {user.houser.publication.title}
              </p>
              {user.houser.type_exclusive ? (
                <p>Casa Exclusiva</p>
              ) : (
                <React.Fragment>
                  {VARIABLES.BOOLEAN_VALUES[user.houser.type_share] && <p>Casa Compartida</p>}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </RouterLink>
  );
}

function Marker(props) {
  const { id, name, photo, title, gallerie, type_exclusive, type_share, active, services } =
    props;
  return (
    <RouterLink className="dh-no-decoration" to={`/cuidador/${id}`}>
      <HtmlTooltip
        title={
          <>
            <div className="dh-card-houser-map">
              <div className="dh-card-houser-map__cover-image">
                <img
                  alt={name}
                  src={
                    gallerie
                      ? `${VARIABLES.IMAGE_URL}${gallerie[0]?.photo}`
                      : 'https://cdn2.thecatapi.com/images/888.jpg'
                  }
                />
              </div>
              <div className="dh-card-houser-map__detail">
                <h3>{title}</h3>
                <p style={{ fontSize: '0.875rem' }}>
                  {type_exclusive
                    ? 'Casa Exclusiva'
                    : VARIABLES.BOOLEAN_VALUES[type_share] && 'Casa Compartida'}
                </p>
                {services.map((hs, index) => (
                  <p
                    className="card-pricer"
                    key={`houser-price-${index}`}
                    style={{ fontSize: '0.875rem' }}>
                    {hs.service.name}:{' '}
                    <strong>
                      {hs.service?.money?.symbol}{' '}
                      {Number(hs.service.gross_comission) + Number(hs.service.houser_comission)}
                    </strong>{' '}
                    por noche
                  </p>
                ))}
                <div className="dh-flex items-center">
                  <div className="user-image-container">
                    <img
                      src={
                        photo
                          ? `${VARIABLES.IMAGE_URL}${photo}`
                          : 'https://cdn2.thecatapi.com/images/888.jpg'
                      }
                      alt={name}
                    />
                  </div>
                  <span>{name}</span>
                </div>
              </div>
            </div>
          </>
        }>
        <div
          className="pin"
          key={id}
          style={
            active
              ? {
                  background: 'var(--accentColor)',
                  boxShadow: '0px 0px 20px 2px rgba(249,166,74,0.8)',
                }
              : {
                  background: 'var(--primaryColor)',
                }
          }
        />
      </HtmlTooltip>
      <div className="pulse" />
    </RouterLink>
  );
}

function MarkerMovil(props) {
  const { user, handleClick } = props;
  return (
    <React.Fragment>
      <div className="pin" onClick={() => handleClick(user)} />
      <div className="pulse" />
    </React.Fragment>
  );
}

function MapManagement(props) {
  const { users, country, city, zoom, activeId, layout } = props;
  const center = useLocationManagement(country, city, {
    lat: -12.102292,
    lng: -77.035504,
  });
  const [cardState, setCardState] = useState(false);
  const [cardData, setCardData] = useState({});

  const handleClick = (data) => {
    // console.log(data)
    setCardState(true);
    setCardData(data);
  };

  if (layout === 'all') {
    return (
      <React.Fragment>
        <div className="houser-list__map-layout-all">
          <GoogleMapReact
            bootstrapURLKeys={{ key: VARIABLES.GOOGLE_MAP_KEY }}
            center={center.value}
            defaultZoom={zoom}>
            {users.map((user, index) => (
              <MarkerMovil
                key={index}
                lat={user.location.latitude}
                lng={user.location.longitude}
                user={user}
                handleClick={handleClick}
              />
            ))}
          </GoogleMapReact>
        </div>
        {cardState ? <CardHouserMovil user={cardData} /> : null}
      </React.Fragment>
    );
  }

  return (
    <div className="houser-list__map-container">
      <GoogleMapReact
        bootstrapURLKeys={{ key: VARIABLES.GOOGLE_MAP_KEY }}
        center={center.value}
        defaultZoom={zoom}>
        {users.map((user, index) => (
          <Marker
            key={index}
            lat={user.location.latitude}
            lng={user.location.longitude}
            id={user.id}
            name={user.name}
            photo={user.photo}
            title={user.houser.publication.title}
            gallerie={user.houser.galleries}
            type_exclusive={user.houser.type_exclusive}
            type_share={user.houser.type_share}
            active={activeId === user.id ? true : false}
            services={user.houser.houser_services}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}

function HouserList(props) {
  const { country, city } = useContext(Context);
  // Estado inicial del 'Tab'
  const [value, setValue] = useState(1);
  // Variables de la Vista
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // Variables de los Usuarios
  const [users, setUsers] = useState([]);
  const { query, setQuery, filteredUsers } = useSearchUsers(users);
  const [activeId, setActiveId] = useState(0);
  const [values, setValues] = useState({
    type_share: true,
    type_exclusive: true,
    type_delivery: false,
    pills: false,
    injectables: false,
    allowed_cats: false,
    allowed_sofa: false,
    allowed_house: false,
    allowed_bed: false,
    dog_small: false,
    dog_medium: false,
    dog_big: false,
    has_terrace: false,
    has_garden: false,
    has_dogs: false,
    has_cats: false,
    has_rabbits: false,
    has_exo: false,
  });
  const { isDesktop } = useResponsive();

  const handleValuesChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUpdateData = async () => {
    setLoading(true);
    setError(null);
    let pet_size = 4;

    if (values.dog_big) {
      pet_size = 2;
    } else if (values.dog_medium) {
      pet_size = 1;
    } else if (values.dog_small) {
      pet_size = 0;
    }

    let body = {
      country: country,
      city: city,
      type_share: VARIABLES.BOOLEAN_TO_NUMBER[values.type_share],
      type_exclusive: VARIABLES.BOOLEAN_TO_NUMBER[values.type_exclusive],
      type_delivery: VARIABLES.BOOLEAN_TO_NUMBER[values.type_delivery],
      has_terrace: VARIABLES.BOOLEAN_TO_NUMBER[values.has_terrace],
      has_garden: VARIABLES.BOOLEAN_TO_NUMBER[values.has_garden],
      allowed_bed: VARIABLES.BOOLEAN_TO_NUMBER[values.allowed_bed],
      pills: VARIABLES.BOOLEAN_TO_NUMBER[values.pills],
      injectables: VARIABLES.BOOLEAN_TO_NUMBER[values.injectables],
      allowed_cats: VARIABLES.BOOLEAN_TO_NUMBER[values.allowed_cats],
      pet_size: pet_size,
    };
    sendData(SERVICES.LIST_FILTER_CUIDADORES, OPTIONS.POST(body), VARIABLES.STATE_UPDATE);
  };

  useEffect(() => {
    if (country && city) {
      manageData(country, city);
    } else {
      setError('No sé a seleccionado ninguna ubicación');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, city]);

  const manageData = async (country, city) => {
    setLoading(true);
    setError(null);
    if (props.location.state) {
      const { typeService } = props.location.state;
      if (typeService === 'compartida') {
        fetchData(country, city, 1, 0, 0);
        setValues({ ...values, type_share: true });
      } else if (typeService === 'exclusiva') {
        fetchData(country, city, 0, 1, 0);
        setValues({ ...values, type_exclusive: true });
      } else if (typeService === 'delivery') {
        fetchData(country, city, 0, 0, 1);
        setValues({ ...values, type_delivery: true });
      } else {
        fetchData(country, city, 0, 0, 0);
      }
    } else {
      fetchData(country, city, 0, 0, 0);
    }
  };

  const fetchData = async (country, city, type_share, type_exclusive, type_delivery) => {
    let body = {
      country: country,
      city: city,
      type_share: type_share,
      type_exclusive: type_exclusive,
      type_delivery: type_delivery,
    };
    sendData(SERVICES.LIST_FILTER_CUIDADORES, OPTIONS.POST(body), VARIABLES.STATE_INITIATE);
  };

  const sendData = async (url, options, state) => {
    // console.log(options.body)
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.status === 200) {
        if (data.users) {
          setUsers(data.users);
        } else {
          setError('No se encontraron cuidadores');
        }
        setLoading(false);
        // setError(null)
      } else {
        setLoading(false);
        setError(data.message);
      }
    } catch (error) {
      setLoading(false);
      setError('Error -> ' + error.message);
    }
  };

  const handleHover = (id) => {
    setActiveId(id);
  };

  return (
    <div className="houser-list">
      {isDesktop && (
        <FilterDesktop
          values={values}
          handleValuesChange={handleValuesChange}
          handleUpdateData={handleUpdateData}
        />
      )}
      {!isDesktop && (
        <div className="houser-list__appbar">
          <AntTabs
            value={value}
            onChange={handleTabChange}
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="scrollable force tabs example">
            <AntTab label="Filtro" {...a11yProps(0)} />
            <AntTab label="Cuidadores" {...a11yProps(1)} />
            <AntTab label="Mapa" {...a11yProps(2)} />
          </AntTabs>
        </div>
      )}
      {/* Contenido principal */}
      <div className="houser-list__main-content">
        {!isDesktop && (
          <TabPanel value={value} index={0}>
            <FilterPortrait
              values={values}
              handleValuesChange={handleValuesChange}
              handleUpdateData={handleUpdateData}
            />
          </TabPanel>
        )}
        <TabPanel value={value} index={1}>
          <Grid container component="main" spacing={0}>
            {!error ? (
              <React.Fragment>
                <Grid item xs={12} md={6} className="houser-list__housers">
                  <div style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                    <SearchInput
                      text="Busca a tu cuidador"
                      query={query}
                      onChange={(e) => {
                        setQuery(e.target.value);
                      }}
                    />
                    <div style={{ marginTop: '0.5rem' }}>
                      <p className="small">Cuidadores encontrados ({filteredUsers.length})</p>
                    </div>
                  </div>
                  <HouserGrid
                    loading={loading}
                    users={filteredUsers}
                    handleHover={handleHover}
                  />
                </Grid>
                {isDesktop && (
                  <Grid item xs={false} md={6}>
                    <MapManagement
                      users={filteredUsers}
                      country={country}
                      city={city}
                      zoom={13}
                      activeId={activeId}
                      layout="right"
                    />
                  </Grid>
                )}
              </React.Fragment>
            ) : (
              <Grid item xs={12} className="houser-list__housers">
                <HousersNotFound errorText={error} />
              </Grid>
            )}
          </Grid>
        </TabPanel>
        {!isDesktop && (
          <TabPanel value={value} index={2}>
            {!error ? (
              <MapManagement
                users={users}
                country={country}
                city={city}
                zoom={14}
                layout="all"
              />
            ) : (
              <h3>{error}</h3>
            )}
          </TabPanel>
        )}
      </div>
    </div>
  );
}

export default HouserList;
