import React from 'react'
import { Link as RouterLink } from "react-router-dom"
import PropTypes from 'prop-types'
// Utils
import replacePathParams from 'utils/replacePathParams'
// Constants
import * as VARIABLES from 'constants/variables'
import * as ROUTES from 'constants/routes'
// Material UI
import Skeleton from '@material-ui/lab/Skeleton'

const HouserInfo = props => {
  const { loading, reservation, housePhoto } = props
  return (
    <div className="stay-detail dh-card-elevation">
      <div className="stay-detail__flex">
        <div className="stay-detail__image">
          {!loading ? (
            <img src={VARIABLES.IMAGE_URL + housePhoto} alt="Casa del cuidador"/>
          ) : (
            <Skeleton width="100%" height="100%"/>
          )}
        </div>
        <div className="stay-detail__content">
          <div className="admin-res-detail__section-title">
            <h3>Información del cuidador</h3>
            <div className="border-space"/>
          </div>
          <div className="stay-detail__content--grid">
            <div className="stay-detail__content--item">
              <p>Nombre del Cuidador</p>
              <h4>{reservation.houser.user.name} {reservation.houser.user.last_name}</h4>
            </div>
            <div className="stay-detail__content--item">
              <p>ID de usuario</p>
              <h4>#{reservation.houser.user.id}</h4>
            </div>
            <div className="stay-detail__content--item">
              <p>ID de houser</p>
              <h4>#{reservation.houser.id}</h4>
            </div>
            <div className="stay-detail__content--item">
              <p>Teléfono</p>
              <h4>{reservation.houser.user.phone}</h4>
            </div>
            <div className="stay-detail__content--item">
              <p>Teléfono de casa</p>
              <h4>{reservation.houser.user.phone_house}</h4>
            </div>
          </div>
          <div className="stay-detail__content--item">
            <p>Dirección</p>
            <h4>{reservation.houser.user.address}</h4>
          </div>
          <div className="dh-flex content-end">
            <RouterLink to={replacePathParams(ROUTES.ADMIN_CUIDADORES_ID, { id: reservation.houser.user.id})}>
              <button className="btn-primary border">Detalle del cuidador</button>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  )
}

HouserInfo.propTypes = {
  loading: PropTypes.bool,
  reservation: PropTypes.object.isRequired,
  housePhoto: PropTypes.string
}

export default HouserInfo
