import { withStyles, Theme } from '@material-ui/core/styles';
import { Tabs as MuiTabs } from '@material-ui/core';

const Tabs = withStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: 'var(--primaryColorDark)',
  },
}))(MuiTabs);

export default Tabs;
