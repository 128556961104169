import React, { useState } from 'react';
import './dialogSendMessage.scss';
// Material-UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
import useNotificationValue from 'hooks/useNotificationValue';
import Notification from 'components/notification';

function DialogSendMessage({ users, open, handleClose, cookies }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const notification = useNotificationValue();
  const [message, setMessage] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isSendSms, setIsSendSms] = useState(true);
  const [isSendEmail, setIsSendEmail] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSendMessage = () => {
    if (!message.trim() || (!isSendEmail && !isSendSms)) {
      return;
    }

    setOpenConfirm(true);
  };

  const onChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const onCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleConfirmSendMessage = async () => {
    onCloseConfirm();
    handleClose();
    await fetchSentMessage();
  };

  const fetchSentMessage = () => {
    let body = {
      message: message,
      user_ids: users.map((user) => user.id),
      is_sms: isSendSms,
      is_email: isSendEmail,
    };
    setLoading(true);
    return sentMessage(SERVICES.SEND_MESSAGE_EMAIL_SMS, OPTIONS.POST(body));
  };

  const sentMessage = async (url, options) => {
    try {
      const response = await fetch(url, options);
      // eslint-disable-next-line no-unused-vars
      const data = await response.json();
      if (response.status !== 200) {
        // Will change later
        notification.handleSnackbar('Successed', 'success');
      } else {
        notification.handleSnackbar('Successed', 'success');
      }
    } catch (error) {
      // Will change later
      notification.handleSnackbar('Successed', 'success');
    }

    setLoading(false);
  };

  const handleSms = (event) => {
    setIsSendSms(event.target.checked);
  };

  const handleEmail = (event) => {
    setIsSendEmail(event.target.checked);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dh-dialog-send-message">
        <div className="dh-dialog-send-message__title">
          <h2>Envoyer le message</h2>
        </div>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            variant="outlined"
            placeholder="Message"
            multiline
            rows={5}
            style={{ width: '100%' }}
            onChange={onChangeMessage}
          />
          <FormGroup
            style={{
              flexDirection: 'unset',
              justifyContent: 'space-around',
              marginTop: '16px',
            }}>
            <FormControlLabel
              control={<Checkbox checked={isSendSms} onChange={handleSms} />}
              label="Sms"
            />
            <FormControlLabel
              control={<Checkbox checked={isSendEmail} onChange={handleEmail} />}
              label="Email"
            />
          </FormGroup>
        </DialogContent>
        <div className="dh-dialog-send-message__actions">
          <button onClick={handleClose} className="btn-default">
            Atras
          </button>
          <button onClick={handleSendMessage} className="btn-primary">
            Envoyer
          </button>
        </div>
      </Dialog>

      {/* Confirm send message */}
      <Dialog
        open={openConfirm}
        fullScreen={fullScreen}
        onClose={onCloseConfirm}
        onBackdropClick={onCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dh-dialog-send-message">
        <div className="dh-dialog-send-message__title">
          <h2>Confirmer l'envoi du message</h2>
        </div>
        <DialogContent style={{ display: 'flex' }}>
          Êtes-vous sûr d'envoyer un e-mail ?
        </DialogContent>
        <div className="dh-dialog-send-message__actions">
          <button onClick={onCloseConfirm} className="btn-default">
            Atras
          </button>
          <button onClick={handleConfirmSendMessage} className="btn-primary">
            D'accord
          </button>
        </div>
      </Dialog>

      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}

      <Dialog className="dh-dialog-send-message__loading" fullScreen open={loading}>
        <DialogContent className="dh-dialog-send-message__content">
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default DialogSendMessage;
