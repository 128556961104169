// Roles de Usuario
// Cuidador
export const CUIDADOR_INT = 0;
export const CUIDADOR_STR = "0";
// Cliente
export const CLIENTE_INT = 1;
export const CLIENTE_STR = "1";
// Cliente - Cuidador
export const CLIENTE_CUIDADOR_INT = 2;
export const CLIENTE_CUIDADOR_STR = "2";
// Admin
export const ADMIN_INT = 64;
export const ADMIN_STR = "64";