
/**
 * Status del Usuario
 * Si => 1
 * No => 0
 * Cualquier otro numero es tomado como ERROR
**/
import React from 'react'
import '../../components/badgeStatus/badgeStatus.scss'

function YesOrNo(props) {
  const status = props.status;
  let badge;

  if (status === 1) {
    badge = <div className="dh-badge-status" style={{background: 'rgb(67, 160, 71)'}}>Si</div>;
  } else if (status === 0) {
    badge = <div className="dh-badge-status" style={{background: 'rgb(238, 168, 0)'}}>No</div>;
  } else {
    badge = <div className="dh-badge-status" style={{background: 'rgb(229, 57, 53)'}}>Error</div>;
  }

  return (
    <React.Fragment>
      {badge}
    </React.Fragment>
  )
}

export default YesOrNo
