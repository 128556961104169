import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
// Hooks
import TablePaginationActions from 'hooks/TablePaginationActions'
// Utils
import replacePathParams from 'utils/replacePathParams'
// Constants
import * as ROUTES from 'constants/routes'
// Components
import BadgeLevel from 'components/badgeLevel/BadgeLevel'
// Material-UI
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton'
import Skeleton from '@material-ui/lab/Skeleton'
// Material Icons
import VisibilityIcon from '@material-ui/icons/Visibility'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 1000,
  },
}));

const TableRowHead = (
  <TableRow>
    <TableCell>ID</TableCell>
    <TableCell>Nombre del producto</TableCell>
    <TableCell>Cuidador/Proveedor</TableCell>
    <TableCell>Comisión Bruta</TableCell>
    <TableCell>Total</TableCell>
    <TableCell>Ubicación</TableCell>
    <TableCell>Nivel de Cuidador</TableCell>
    <TableCell align="center">Acciones</TableCell>
  </TableRow>
)

const SkeletonRow = (
  <TableRow>
    <TableCell scope="row"><Skeleton/></TableCell>
    <TableCell><Skeleton/></TableCell>
    <TableCell><Skeleton/></TableCell>
    <TableCell><Skeleton/></TableCell>
    <TableCell><Skeleton/></TableCell>
    <TableCell><Skeleton/></TableCell>
    <TableCell><Skeleton/></TableCell>
    <TableCell><Skeleton/></TableCell>
  </TableRow>
)

function TableService(props) {
  const classes = useStyles()
  const { services, loading } = props

  const [page, setPage] = useState(0)
  const [resPerPage, setResPerPage] = useState(25)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeResPerPage = event => {
    setResPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div className="dh-admin-services__table-services--layout dh-simple-elevation">
      <Table className={classes.table}>
        <TableHead className="table-head">
          {TableRowHead}
        </TableHead>
        {!loading ? (
          <TableBody className="table-body">
            {services.slice(page * resPerPage, page * resPerPage + resPerPage).map(service => {
              return (
                <TableRow key={service.id}>
                  <TableCell>{service.id}</TableCell>
                  <TableCell>{service.name}</TableCell>
                  <TableCell>{service.money.symbol} {service.houser_comission}</TableCell>
                  <TableCell>{service.money.symbol} {service.gross_comission}</TableCell>
                  <TableCell>{service.money.symbol} {parseInt(service.gross_comission, 10) + parseInt(service.houser_comission, 10)}</TableCell>
                  <TableCell>{service.city}, {service.country}</TableCell>
                  <TableCell>
                    <BadgeLevel level={service.houser_level_id}/>
                  </TableCell>
                  <TableCell align="center" className="table-body__service-action">
                    <RouterLink to={replacePathParams(ROUTES.ADMIN_SERVICE_DETAIL, { id: service.id })} className="dh-router">
                      <IconButton aria-label="ver datos del producto">
                        <VisibilityIcon/>
                      </IconButton>
                    </RouterLink>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        ) : (
          <TableBody className="table-body">
            {Array.from(new Array(6)).map((data, index) => (
              <React.Fragment key={index}>
                {SkeletonRow}
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={services.length}
        rowsPerPage={resPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'filas por página' },
          native: true,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeResPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  )
}

TableService.propTypes = {
  services: PropTypes.array.isRequired,
  loading: PropTypes.bool
}

export default TableService
