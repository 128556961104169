import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
// Utils
import replacePathParams from 'utils/replacePathParams'
// Constants
import * as ROUTES from 'constants/routes'
import * as VARIABLES from 'constants/variables';
// Material-UI
import Avatar from '@material-ui/core/Avatar'

const PetItem = props => {
  const { title, image, id } = props
  return (
    <RouterLink to={replacePathParams(ROUTES.MASCOTA_DETAIL, { id: id })}>
      <div className="info-detail__grid-pets__body--item">
        <Avatar alt={title} src={image && VARIABLES.IMAGE_URL + image}/>
        <div>
          <p>{title}</p>
        </div>
      </div>
    </RouterLink>
  )
}

PetItem.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string
}

const ClientInfo = props => {
  const { reservation, pets, showContact } = props
  return (
    <div className="dh-card-border info-detail">
      <div className="client-reservation-detail__section-title">
        <h3>Detalles del Cliente</h3>
        <div className="border-space"/>
      </div>
      <div className="info-detail__body--grid-1">
        <div className="info-detail__body--item">
          <p>Nombre del Cliente</p>
          <h4>{reservation.user.name}</h4>
        </div>
        <div className="info-detail__body--item">
          <p>Apellido del Cliente</p>
          <h4>{reservation.user.last_name}</h4>
        </div>
        <div className="info-detail__body--item">
          <p>Numero del Cliente</p>
          <h4>{showContact ? reservation.user.phone : '-'}</h4>
        </div>
        <div className="info-detail__body--item">
          <p>Numero de emergencia</p>
          <h4>{showContact ? reservation.user.emergency_contact_phone : '-'}</h4>
        </div>
      </div>
      <div className="info-detail__title-section">
        <h4>Mascotas</h4>
      </div>
      <div className="info-detail__grid-pets__toolbar">
        {(pets.length > 0) ? (
          <div className="info-detail__grid-pets__body">
            {pets.map((data, index) => (
              <PetItem
                key={index}
                id={data.pet.id}
                title={data.pet.name}
                image={data.pet.photo}
              />
            ))}
          </div>
        ) : (
          <div className="admin-grid-pets__body">
            <h4>No se encontraron mascotas</h4>
          </div>
        )}
      </div>
    </div>
  )
}

ClientInfo.propTypes = {
  reservation: PropTypes.object.isRequired,
  pets: PropTypes.array.isRequired
}

ClientInfo.defaultProps = {
  showContact: true,
}

export default ClientInfo
