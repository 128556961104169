import React from 'react'
// Components
import Footer from 'components/footer';
import { Header } from './components';

function LayoutClient(props) {
  const pathname = props.children.props.location.pathname

  return (
    <React.Fragment>
      <Header props={props}/>
      <div className="layout-space">
        {props.children}
      </div>
      {!(pathname === "/buscar") && 
        <Footer/>
      }
    </React.Fragment>
  )
}

export default LayoutClient
