import React from 'react';
import './titleArrowBack.scss';
// Material-UI
import Button from '@material-ui/core/Button';
// Material Icons
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

const TitleArrowBack = (props) => {
  return (
    <div className="dh-title-back">
      <div className="arrow-back">
        <Button onClick={props.handleClick}>
          <ArrowBackRoundedIcon />
        </Button>
      </div>
      {props.children}
    </div>
  );
};

export default TitleArrowBack;
