/**
 * Status del Usuario
 * Verificado => 1
 * No Verificado => 0
 * Cualquier otro numero es tomado como ERROR
**/
import React from 'react'
import './badgeStatusSimple.scss'

function BadgeStatusSimple(props) {
  const status = props.status;
  let badge;

  if (status === 1 || status === '1') {
    badge = (
      <div className="dh-badge-status-simple verificado">Verificado</div>
    )
  } else if (status === 0 || status === '0') {
    badge = (
      <div className="dh-badge-status-simple no-verificado">No Verificado</div>
    )
  } else {
    badge = (
      <div className="dh-badge-status-simple error">Error</div>
    )
  }

  return (
    <React.Fragment>
      {badge}
    </React.Fragment>
  )
}

export default BadgeStatusSimple
