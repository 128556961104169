import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
// Constants
import * as ROUTES from 'constants/routes'
import * as VARIABLES from 'constants/variables'
// components
import PersistentNotification from 'components/persistentNotification'
// Material-UI
import Skeleton from '@material-ui/lab/Skeleton'

const ListNotification = props => {
  const { user, notifications, loading } = props

  return (
    <React.Fragment>
      {!loading ? (
        <React.Fragment>
          {/*
          {!VARIABLES.NUMBER_TO_BOOLEAN[user.email_confirmed] &&
            <PersistentNotification variant="email" message="Confirma tu dirección de correo electrónico"/>
          }
          */}
          {!VARIABLES.NUMBER_TO_BOOLEAN[user.confirmation] &&
            <RouterLink to={ROUTES.VALIDATE_PHONE} className="dh-no-decoration">
              <PersistentNotification variant="phone" message="Necesitas confirmar tu numero de teléfono *"/>
            </RouterLink>
          }
          {notifications.slice(0,3).map((noti, index) => {
            return (
              <React.Fragment key={index}>
                <PersistentNotification variant={noti.type} message={noti.message} data={noti}/>
              </React.Fragment>
            )
          })}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {Array.from(new Array(3)).map((data, index) => {
            return (
              <div className="persistent-notification" key={index}>
                <Skeleton className="persistent-notification__image" variant="circle"/>
                <div className="persistent-notification__text">
                  <Skeleton height={8} width="90%" />
                  <Skeleton height={8} width="40%" />
                </div>
              </div>
            )
          })}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

ListNotification.propTypes = {
  user: PropTypes.object,
  notification: PropTypes.array
}

export default ListNotification
