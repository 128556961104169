import React from 'react';
// Utils
import { country as countryArray } from '../../utils/Resources';
// Material-UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

export const type_services = [
  {
    value: 1,
    label: 'Hospedaje Compartido',
  },
  {
    value: 2,
    label: 'Hospedaje Exclusivo',
  },
  {
    value: 3,
    label: 'Guardería',
  },
  {
    value: 4,
    label: 'Delivery Houser',
  },
  {
    value: 22,
    label: 'Hotel',
  },
];

// Tipo de Lugares
export const type_money = [
  {
    value: 1,
    label: 'Chilean peso',
  },
  {
    value: 2,
    label: 'Colombian peso',
  },
  {
    value: 3,
    label: 'Peruvian nuevo sol',
  },
  {
    value: 4,
    label: 'Brazilian real',
  },
  {
    value: 5,
    label: 'United States dollar',
  },
  {
    value: 6,
    label: 'Euro',
  },
];

// Tipo de Lugares
export const type_tax = [
  {
    value: 1,
    label: 'IGV(PE) - 18%',
  },
  {
    value: 2,
    label: 'IVA(ES) - 21%',
  },
  {
    value: 3,
    label: 'IVA(CO) - 19%',
  },
];

export const type_level = [
  {
    value: 1,
    label: 'Houser Junior',
  },
  {
    value: 2,
    label: 'Houser Senior',
  },
  {
    value: 3,
    label: 'Super Houser',
  },
];

function AreaServiceData(props) {
  const { handleSubmit, service, cityArray, handleServiceChange, title, btnText } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="dh-card-elevation">
        <h2>{title}</h2>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="country"
              name="country"
              select
              required
              fullWidth
              label="País"
              value={service.country}
              onChange={handleServiceChange('country')}
              SelectProps={{
                MenuProps: {
                  className: 'dh-select-menu',
                },
              }}
              margin="normal"
              variant="outlined">
              {countryArray.map((country) => (
                <MenuItem key={country.value} value={country.value}>
                  {country.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="city"
              name="city"
              className="dh-city-selector"
              select
              required
              fullWidth
              label="Ciudad"
              value={service.city}
              onChange={handleServiceChange('city')}
              SelectProps={{
                MenuProps: {
                  className: 'dh-select-menu',
                },
              }}
              margin="normal"
              variant="outlined">
              {cityArray.map((city) => (
                <MenuItem key={city.value} value={city.value}>
                  {city.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <TextField
          id="name"
          name="name"
          required
          fullWidth
          label="Nombre del servicio"
          value={service.name}
          onChange={handleServiceChange('name')}
          margin="normal"
          variant="outlined"
        />
        <TextField
          id="type_id"
          name="type_id"
          select
          required
          fullWidth
          label="Tipo de Servicio"
          value={service.type_id}
          onChange={handleServiceChange('type_id')}
          SelectProps={{
            MenuProps: {
              className: 'dh-select-menu',
            },
          }}
          margin="normal"
          variant="outlined">
          {type_services.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </TextField>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id="money_id"
              name="money_id"
              select
              required
              fullWidth
              label="Tipo de Moneda"
              value={service.money_id}
              onChange={handleServiceChange('money_id')}
              SelectProps={{
                MenuProps: {
                  className: 'dh-select-menu',
                },
              }}
              margin="normal"
              variant="outlined">
              {type_money.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="tax_id"
              name="tax_id"
              select
              required
              fullWidth
              label="Impuesto"
              value={service.tax_id}
              onChange={handleServiceChange('tax_id')}
              SelectProps={{
                MenuProps: {
                  className: 'dh-select-menu',
                },
              }}
              margin="normal"
              variant="outlined">
              {type_tax.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="houser_level_id"
              name="houser_level_id"
              select
              required
              fullWidth
              label="Nivel de Cuidador"
              value={service.houser_level_id}
              onChange={handleServiceChange('houser_level_id')}
              SelectProps={{
                MenuProps: {
                  className: 'dh-select-menu',
                },
              }}
              margin="normal"
              variant="outlined">
              {type_level.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="gross_comission"
              name="gross_comission"
              type="number"
              required
              fullWidth
              label="Comisión Bruta"
              value={service.gross_comission}
              onChange={handleServiceChange('gross_comission')}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="houser_comission"
              name="houser_comission"
              type="number"
              required
              fullWidth
              label="Comisión Cuidador/Proveedor"
              value={service.houser_comission}
              onChange={handleServiceChange('houser_comission')}
              margin="normal"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </div>
      <button type="submit" className="btn-primary medium margin-top-x1">
        {btnText}
      </button>
    </form>
  );
}

export default AreaServiceData;
