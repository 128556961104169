// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const THEME_TYPE = '@customization/THEME_TYPE';
export const THEME_LOCALE = '@customization/THEME_LOCALE';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';
export const SNACKBAR_CLOSED = '@snackbar/SNACKBAR_CLOSED';
