import { toast } from 'react-toastify';
// import Swal from 'sweetalert2';

// import { getErrorMessage, getFooterError } from '@root/utils/fetch-utils';

export const getRequest = async (request) => {
  return new Promise((resolve, reject) => {
    request
      .then((res) => resolve(res?.data))
      .catch((e) => reject((e.response && e.response.data) || 'Wrong Services'));
  });
};

const fetchApi = async (request) => {
  return new Promise((resolve, reject) => {
    request
      .then((res: any) => {
        const data = res?.data;
        // console.log('[RES SUCCESS]', res);
        if (res.status !== 200) {
          const message = data?.message ? data.message : '-';
          toast.error(`Error: ${message}`);
          reject(message);
        } else {
          resolve(res);
        }
      })
      .catch((e: any) => {
        // console.log('[RES ERROR]', e);
        const resData = e?.message ? e?.message : e;
        toast.error(resData);
        reject(e);
      });
  });
};

export default fetchApi;
