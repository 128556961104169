import React, {useState} from 'react'
import './cuidadorCalendario.scss'
// Constants
import * as VARIABLES from 'constants/variables'
// Dependencies
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
// Material-UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

function CuidadorCalendario() {
  const [dia, setDia]= useState('');
  const [mes, setMes] = useState('');
  const [fecha, setFecha] = useState('');
  const [fechaId, setFechaId] = useState('');
  const [dateEvent, setDateEvent] = useState([
    {
      events: [
        {
          title: '',
          id: '',
          start: '',
          type: '',
          color: ''
        }
      ]
    }
  ])
  const [radioValue, setRadioValue] = useState('bloqueado');

  const handleDateClick = (e) => {
    // console.log(e)
    setFecha(e.dateStr);
    setDia(e.date.getDate())
    setMes(e.date.getMonth())
    setFechaId(e.dateStr)
  }

  const handleEventClick = (info) => {
    // console.log(info)
  }
  
  const handleChange = e => {
    setRadioValue(e.target.value)
  }

  const handleSubmitForm = e => {
    let color
    if (radioValue === 'disponible') {
      color = '#80cdc5'
    } else {
      color = '#f9a64a;'
    }
    setDateEvent([
      ...dateEvent,
      {
        title: radioValue,
        id:fechaId,
        start: fecha,
        type: radioValue,
        color: color
      }
    ])
  }

  return (
    <div className="dashboard-cuidador">
      <Container maxWidth="lg">
        <h1 className="marginx05">Calendario</h1>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={8}>
            <FullCalendar 
              locale="es"
              defaultView="dayGridMonth"
              plugins={[dayGridPlugin, interactionPlugin]}
              // await events={reservation}
              eventClick={handleEventClick}
              events={dateEvent}
              eventResizableFromStart={true}
              selectable={true}
              droppable ={true}
              dateClick={handleDateClick}
              color= {dateEvent.color}
              header={{
                left: 'today',
                center: 'title',
                right: 'prev,next'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} align="center">
            <div className="dh-calendar-detail">
              <h3>Fechas seleccionadas</h3>
              <div className="dh-calendar-detail__dates">
                {dia ? (
                  <p>{dia} de {VARIABLES.VARIANT_MONTH[mes]} </p>
                ) : (
                  <p>Selecciona una fecha</p>
                )}
              </div>
              <div className="dh-calendar-form">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Disponibilidad</FormLabel>
                  <div className="dh-calendar-form__options">
                    <RadioGroup aria-label="position" name="position" value={radioValue} onChange={handleChange} row>
                      <FormControlLabel
                        value="disponible"
                        control={<Radio color="primary" />}
                        label="Disponible"
                        labelPlacement="start"
                        onChange= {handleChange}
                        />
                      <div className="divider"/>
                      <FormControlLabel
                        value="bloqueado"
                        control={<Radio color="primary" />}
                        label="Bloqueado"
                        labelPlacement="start"
                        onChange= {handleChange}
                      />
                    </RadioGroup>
                  </div>
                </FormControl>
                <div className="dh-calendar-actions">
                <button className="btn-primary" onClick= {handleSubmitForm}>
                  Guardar
                </button>
                <button className="btn-primary-outline">
                  Cancelar
                </button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default CuidadorCalendario
