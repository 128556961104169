import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import '../lostPassword/lostPassword.scss';

import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
import * as ROUTES from '@root/constants/routes';
import Notification from '@root/components/notification/Notification';
// Hooks
import { useInputValue } from '../../hooks/useInputValue';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  linkRed: {
    fontFamily: 'Montserrat-Semibold',
    color: '#f44336',
  },
}));

function NewPassword(props) {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  const [email, setEmail] = useState('');
  const [emailState, setEmailState] = useState(false);
  const code = useInputValue('');
  const password = useInputValue('');
  const confirmPassword = useInputValue('');
  const [passwordChange, setPasswordChange] = useState(false);

  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchUpdateUser();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (props.location.email) {
      // console.log("Correo --> " + props.location.email)
      setEmail(props.location.email);
      setEmailState(true);
    } else {
      // console.log(" --> No Correo")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUpdateUser = async () => {
    setMessage('');
    setMessageState(false);
    var body = {
      email: email,
      code: code.value,
      pwd: password.value,
      confirm_pwd: confirmPassword.value,
    };
    sentUserData(SERVICES.NEW_PASSWORD, OPTIONS.POST(body));
  };

  const sentUserData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        setMessage('Contraseña cambiada correctamente');
        setMessageVariant('info');
        setMessageState(true);
        setPasswordChange(true);
      } else {
        setMessage(data.message);
        setMessageVariant('error');
        setMessageState(true);
      }
    } catch (error) {
      setMessage('Error:' + error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <Container maxWidth="sm" className="lost-password">
      {!passwordChange ? (
        <div className="dh-content-block">
          <div className="content-header">
            <h1 className="marginx05 text-center">Mensaje enviado</h1>
          </div>
          <div className="content-body">
            <p className="regular justify">
              Se le envió un mensaje de texto con el código de cambio de contraseña al número de
              teléfono asociado con su cuenta de DogHouser
            </p>
            <form onSubmit={handleSubmit}>
              {emailState ? (
                <React.Fragment />
              ) : (
                <TextField
                  autoFocus
                  required
                  variant="outlined"
                  margin="normal"
                  id="email"
                  label="Correo"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  fullWidth
                />
              )}
              <TextField
                autoFocus
                required
                variant="outlined"
                margin="normal"
                id="code"
                label="Codigo"
                type="text"
                value={code.value}
                onChange={code.onChange}
                fullWidth
              />
              <TextField
                required
                variant="outlined"
                margin="normal"
                id="password"
                label="Nueva Contraseña"
                type="password"
                value={password.value}
                onChange={password.onChange}
                fullWidth
              />
              <TextField
                required
                variant="outlined"
                margin="normal"
                id="confirmPassword"
                label="Confirmar Nueva Contraseña"
                type="password"
                value={confirmPassword.value}
                onChange={confirmPassword.onChange}
                fullWidth
              />
              <button type="submit" className="btn-primary btn-block medium">
                Enviar
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="dh-content-block">
          <div className="content-header">
            <h1 className="marginx05 text-center">Tu contraseña ha sido cambiada con éxito</h1>
          </div>
          <div className="content-body">
            <RouterLink to={ROUTES.LOGIN} className="dh-no-decoration">
              <p className="login-link center margin-top-x1">
                {'Ahora puedes volver a '}
                <span className={classes.linkRed}>Iniciar Sesíon</span>
              </p>
            </RouterLink>
          </div>
        </div>
      )}
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="left"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </Container>
  );
}

export default NewPassword;
