import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './stepCuidadorUbicacion.scss'
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables';
// Dependencies
import GoogleMapReact from 'google-map-react';
// Hooks
import { locationManagement } from  'hooks/useLocationManagement';
import useNotificationValue from 'hooks/useNotificationValue';
// Utils
import { country as countryArray, variantCityByCountry, vias } from 'utils/Resources'
// Components
import Notification from 'components/notification';
import Marker from 'components/Marker';
// Material-UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';


function StepCuidadorUbicacion(props) {
  // Se obtine el ID de la vista padre y la funcion correspondiente
  const { id, handleNext, handleBack, activeStep } = props
  const [loading, setLoading] = useState(false)
  // Variables de "Notification"
  const notification = useNotificationValue()
  // Datos del Usuario
  const [user, setUser] = useState({
    id: null,
    name: '',
    address: '',
    houser: {
      type_road: '',
      adress_detail: ''
    },
    location : {
      latitude: '',
      longitude: '',
      country: '',
      city: '',
      district: '',
    }
  })
  // Array de las ciudades escogidas por el Usuario
  const [cityArray, setCityArray] = useState([])
  const zoom = 14
  const [center, setCenter] = useState({
    lat: -12.083348,
    lng: -77.057595
  })
  // Funcion para manejar el cambio de los INPUT con datos de "user"
  const handleInputAdressChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  // Funcion para manejar el cambio de los INPUT con datos de "user.location"
  const handleInputLocationChange = e => {
    setUser({
      ...user,
      houser: {
        ...user.houser
      },
      location: {
        ...user.location,
        [e.target.name]: e.target.value
      }
    })
  }

  // Funcion para manejar el cambio de los INPUT con datos de "user.houser"
  const handleInputHouserChange = e => {
    setUser({
      ...user,
      houser: {
        ...user.houser,
        [e.target.name]: e.target.value
      },
      location: {
        ...user.location,
      }
    })
  }

  // Funcion para manejar el cambio de los SELECT con datos de "user.houser"
  const handleSelectHouserChange = name => event => {
    setUser({
      ...user,
      houser: {
        ...user.houser,
        [name]: event.target.value
      },
      location: {
        ...user.location,
      }
    })
  }

  // Funcion para manejar el cambio de los SELECT con datos de "user.location"
  const handleSelectLocationChange = name => event => {
    setUser({
      ...user,
      houser: {
        ...user.houser,
      },
      location: {
        ...user.location,
        [name]: event.target.value
      }
    })
    if (name === "country") {
      setCityArray(variantCityByCountry[event.target.value])
      locationManagement (
        event.target.value,
        user.location.city,
        setCenter
      )
    } else if (name === "city") {
      locationManagement (
        user.location.country,
        event.target.value,
        setCenter
      )
    }
  }

  // Funcion para detectar un click en el mapa
  const onMarkerClick = (props, marker, e) => {
    setCenter({
      lat: props.lat,
      lng: props.lng
    })
  }

  useEffect(() => {
    fetchCuidador()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  // Preparar el ID del usuario para solicitar la informacion al servidor
  const fetchCuidador = async() => {
    setLoading(true)
    let body = {
      id: id
    }
    sendUserData(SERVICES.GET_CUIDADOR_BY_ID, OPTIONS.POST(body), VARIABLES.STATE_INITIATE)
  }

  const handleSubmit = e => {
    e.preventDefault()
    let country = user.location.country
    let city = user.location.city
    let type_road = user.houser.type_road
    let address = user.address
    let adress_detail = user.houser.adress_detail
    let latitude = center.lat
    let longitude = center.lng
    let district = user.location.district
    fetchUpdateUser(country, city, type_road, address, adress_detail, latitude, longitude, district)
  }

  // Preparar los datos del usuario para enviarlos al servidor
  const fetchUpdateUser = async(country, city, type_road, address, adress_detail, latitude, longitude, district) => {
    let body = {
      id: id,
      country: country,
      city: city,
      type_road: type_road,
      address: address,
      adress_detail: adress_detail,
      latitude: latitude,
      longitude: longitude,
      district: district
    }
    sendUserData(SERVICES.UPDATE_HOUSER_STEP_2, OPTIONS.POST(body), VARIABLES.STATE_UPDATE)
  }

  // Enviar datos al servidor
  const sendUserData = async(url, options, state) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (state === VARIABLES.STATE_INITIATE) {
          setUser(data.user)
          if (data.user.location) {
            if (data.user.location.country) {
              setCityArray(variantCityByCountry[data.user.location.country])
            }
            if(data.user.location.latitude && data.user.location.longitude) {
              var intLat = parseFloat(data.user.location.latitude)
              var intLng = parseFloat(data.user.location.longitude)
              setCenter({
                lat: intLat,
                lng: intLng
              })
            } else {
              locationManagement (
                data.user.location.country,
                data.user.location.city,
                setCenter
              )
            }
          }
          setLoading(false)
        } else if (state === VARIABLES.STATE_UPDATE) {
          notification.handleSnackbar('Datos actualizados', 'success')
          handleNext()
        }
      } else {
        notification.handleSnackbar(data.message, 'warning')
        setLoading(false)
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error')
      setLoading(false)
    }
  }

  return (
    <div className="step-cuidador-location">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="country"
              select
              required
              fullWidth
              label="País"
              value={user.location.country}
              onChange={handleSelectLocationChange('country')}
              margin="normal"
              variant="outlined" >
              {countryArray.map(country => (
                <MenuItem key={country.value} value={country.value}>
                  {country.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="city"
              className="dh-city-selector"
              select
              required
              fullWidth
              label="Ciudad"
              value={user.location.city}
              onChange={handleSelectLocationChange('city')}
              margin="normal"
              variant="outlined" >
              {cityArray.map(city => (
                <MenuItem key={city.value} value={city.value}>
                  {city.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <TextField
          id="type_road"
          select
          required
          fullWidth
          label="Tipo de vía"
          value={user.houser.type_road}
          onChange={handleSelectHouserChange('type_road')}
          margin="normal"
          variant="outlined" >
          {vias.map(via => (
            <MenuItem key={via.value} value={via.value}>
              {via.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="address"
          label="Dirección"
          name="address"
          type="text"
          value={user.address}
          onChange={handleInputAdressChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="district"
          label="Distrito"
          name="district"
          type="text"
          value={user.location.district}
          onChange={handleInputLocationChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="adress_detail"
          label="Piso / Oficina / Apto / Depto"
          name="adress_detail"
          type="text"
          value={user.houser.adress_detail}
          onChange={handleInputHouserChange}
        />
        <div className="step-cuidador-location__section-title">
          <h3>Recuerda que tienes que ubicar tu hogar en el mapa, encuentra tu casa y haz clic en ella.</h3>
        </div>
        <div className="step-cuidador-location__map-container">
          {!loading &&
            <GoogleMapReact
              bootstrapURLKeys={{ key: VARIABLES.GOOGLE_MAP_KEY }}
              center={center}
              defaultZoom={zoom}
              onClick={onMarkerClick}>
                <Marker
                  id={id}
                  lat={center.lat}
                  lng={center.lng}
                />
            </GoogleMapReact>
          }
        </div>
        <div className="btn-container">
          {handleBack &&
            <button
              onClick={handleBack}
              className={`btn-primary medium border ${activeStep === 0 ? "disabled" : null }`}
              style={{marginRight: 16}}>
              Atras
            </button>
          }
          <button type="submit" className="btn-primary medium btn-step" >
            Guardar y Continuar
          </button>
        </div>
      </form>
      {notification.messageState &&
        <Notification 
          message={notification.message} 
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState} 
          onClose={notification.handleCloseSnackbar}
        />
      }
    </div>
  )
}

StepCuidadorUbicacion.propTypes = {
  id: PropTypes.string.isRequired,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  activeStep: PropTypes.number
}

export default StepCuidadorUbicacion
