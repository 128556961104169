import React from 'react'
import { Link as RouterLink } from "react-router-dom";
import ReactDOM from 'react-dom';
// Constants
import * as ROUTES from '../../constants/routes';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
// Material Icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import PetsIcon from '@material-ui/icons/Pets';
import RoomServiceRoundedIcon from '@material-ui/icons/RoomServiceRounded';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles({
  dropdowmMenu: {
    zIndex: 999,
  },
  menuDropdownIcon: {
    marginRight: '0.5rem'
  },
  menuItem: {
    padding: '0.6rem 3rem 0.6rem 1rem'
  }
});

function UserDropdownMenu(props) {
  const classes = useStyles();
  const { open, anchorRef, handleClose, logout, client, houser } = props
  let pathProfile = ROUTES.HOME
  let pathMascotas = ROUTES.HOME

  if (houser) {
    pathProfile = ROUTES.CUIDADOR_PERFIL
    pathMascotas = ROUTES.CUIDADOR_MASCOTAS
  } else if (client) {
    pathProfile = ROUTES.CLIENTE_PERFIL
    pathMascotas = ROUTES.CLIENTE_MASCOTAS
  }

  return ReactDOM.createPortal (
    <Popper open={open} anchorEl={anchorRef.current} transition className={classes.dropdowmMenu}>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
          <Paper id="menu-list-grow">
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>
                <RouterLink className="dh-no-decoration" to={pathProfile}>
                  <MenuItem onClick={handleClose} className={classes.menuItem}>
                    <AccountCircleIcon className={classes.menuDropdownIcon}/>
                    Perfil
                  </MenuItem>
                </RouterLink>
                <RouterLink className="dh-no-decoration" to={pathMascotas}>
                  <MenuItem onClick={handleClose} className={classes.menuItem}>
                    <PetsIcon className={classes.menuDropdownIcon}/>
                    Mascotas
                  </MenuItem>
                </RouterLink>
                {client &&
                  <RouterLink className="dh-no-decoration" to={ROUTES.CLIENTE_RESERVATIONS}>
                    <MenuItem onClick={handleClose} className={classes.menuItem}>
                      <RoomServiceRoundedIcon className={classes.menuDropdownIcon}/>
                      Reservas como cliente
                    </MenuItem>
                  </RouterLink>
                }
                <RouterLink className="dh-no-decoration" to={ROUTES.HOME}>
                  <MenuItem onClick={handleClose} className={classes.menuItem}>
                    <HomeRoundedIcon className={classes.menuDropdownIcon}/>
                    Volver al Inicio
                  </MenuItem>
                </RouterLink>
                <MenuItem onClick={logout} className={classes.menuItem}>
                  <ExitToAppIcon className={classes.menuDropdownIcon}/>
                  Cerrar sesión
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>,
    document.getElementById('nav-item')
  )
}

export default UserDropdownMenu
