import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';
import 'react-alice-carousel/lib/alice-carousel.css';

import { Context } from '@root/Context';
import * as ROUTES from '@root/constants/routes';
import useNotificationValue from '@root/hooks/useNotificationValue';
import { country as countryList, variantCityByCountry } from '@root/utils/Resources';
import { whyPanel, howDog, medios, aliados, ratingUsers } from '@root/utils/data.json';
import Notification from '@root/components/notification';
import DialogHouserRequirements from '@root/components/dialogHouserRequirements';
import { Gallery, RatingCard, FloatingButton } from './components';
import dhLogoWhite from '@root/assets/dg_logo_white.png';
import './home.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  heroButtons: {
    marginTop: theme.spacing(2),
  },
  aboutContent: {
    padding: theme.spacing(0, 0, 5),
    textAlign: 'center',
  },
  housersContent: {
    padding: theme.spacing(4, 0, 4),
    textAlign: 'center',
  },
}));

const panel = (data) => (
  <div className="panel">
    <div className="panel__image">
      <img src={data.image} alt={data.title} />
    </div>
    <div className="panel__info">
      <h2>{data.title}</h2>
      <p>{data.text}</p>
    </div>
  </div>
);

const dogCard = (data) => (
  <React.Fragment>
    <img src={data.image} alt={data.title} className={`perro-about-${data.number}`}></img>
    <div className="dog-card__number">
      <p>{data.number}</p>
    </div>
    <h3 className="dog-card__title">{data.title}</h3>
    <p className="dog-card__body">{data.text}</p>
  </React.Fragment>
);

const dashedLine = () => (
  <div className="dh-dashed-line">
    {Array.from(new Array(16)).map((data, index) => {
      return <div className="line" key={index} />;
    })}
  </div>
);

function Home(props) {
  const classes = useStyles();
  const { isAuth, country, city, setCountry, setCity } = useContext(Context);
  // Variables del componente "Notification"
  const notification = useNotificationValue();
  // Variables de Ubicacion del Usuario
  const [values, setValues] = useState({
    country: 'País',
    city: 'Ciudad',
  });
  const [cityArray, setCityArray] = useState([]);
  // Variables del Componente "DialogHouserRequirements"
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Funciona para menejar el Pais y Ciudad del Usuairo
  const handleChange = (name) => (event) => {
    if (name === 'country') {
      setValues({
        country: event.value,
        city: variantCityByCountry[event.value][0].label,
      });
      setCountry(event.value);
      setCity(variantCityByCountry[event.value][0].label);
      setCityArray(variantCityByCountry[event.value]);
    } else {
      setValues({ ...values, [name]: event.value });
      setCity(event.value);
    }
  };

  const handleGoToRegisterCuidador = () => {
    props.history.push(ROUTES.REGISTER_CUIDADOR);
  };

  // eslint-disable-next-line no-unused-vars
  const goToOnboarding = () => {
    if (country && city) {
      props.history.push(ROUTES.ONBOARDING);
    } else {
      notification.handleSnackbar('Seleccionar Pais y ciudad', 'warning');
    }
  };

  const goToHouserList = () => {
    if (country && city) {
      props.history.push(ROUTES.LISTA_CUIDADORES);
    } else {
      notification.handleSnackbar('Seleccionar Pais y ciudad', 'warning');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (country && city) {
      setValues({
        country: country,
        city: city,
      });
      setCityArray(variantCityByCountry[country]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dh-home">
      {/* Hero unit */}
      <div className="dh-home__main">
        <Container maxWidth="lg">
          <div className="heroContent">
            <div className={classes.root}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start">
                <Grid item style={{ marginBottom: 16 }}>
                  <img src={dhLogoWhite} alt="Logo de la web" className="dh-img-white"></img>
                </Grid>
                <Grid item style={{ marginBottom: 12 }}>
                  <h1 className="main-text" style={{ marginBottom: 0, lineHeight: 1 }}>
                    Descubre los mejores
                  </h1>
                  <h1 className="main-text" style={{ lineHeight: 1 }}>
                    cuidadores para tu mascota
                  </h1>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: '0.8rem' }}>
                  <Grid item>
                    <Select
                      className="select-country"
                      value={values.country}
                      onChange={handleChange('country')}
                      placeholder={values.country}
                      options={countryList}
                    />
                  </Grid>
                  <Grid item>
                    <Select
                      className="select-country"
                      value={values.city}
                      onChange={handleChange('city')}
                      placeholder={values.city}
                      options={cityArray}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <div className={classes.heroButtons}>
                    <div>
                      <button onClick={goToHouserList} className="btn-default medium right">
                        <span>Ver Cuidadores</span>
                      </button>
                      {!isAuth && (
                        <button className="btn-default medium" onClick={handleOpenDialog}>
                          <span>Ser Cuidador</span>
                        </button>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Container>
      </div>
      <FloatingButton />
      {/* Why use Dog Houser */}
      <div className="why-use">
        <Container maxWidth="lg" className="why-use__container">
          <h2 className="dh-home__title">¿Por qué somos la mejor opción?</h2>
          <div className={classes.root}>
            <Grid container spacing={3}>
              {whyPanel.map((data, index) => {
                return (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    {panel(data)}
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Container>
      </div>
      {/* Divider */}
      <div className="home-divider" />
      {/* How Works Dog Houser */}
      <div className="how-work">
        <Container maxWidth="lg" className="how-work__container">
          <h2 className="dh-home__title">¿Cómo funciona?</h2>
          {dashedLine()}
          <Grid container spacing={4} className={classes.aboutContent}>
            {howDog.map((data, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  align="center"
                  className="dog-card"
                  key={index}>
                  {dogCard(data)}
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
      <div className="home-divider" />
      <div className="cuidadores-rating">
        <Container maxWidth="lg">
          <Grid
            container
            spacing={4}
            className={classes.housersContent}
            justifyContent="center">
            {ratingUsers.map((data, index) => {
              return (
                <Grid container item xs={12} sm={6} md={4} justifyContent="center" key={index}>
                  <RatingCard
                    img={data.image}
                    title={data.title}
                    text={data.text}
                    user={data.user}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
      <div className="home-divider" />
      <div className="media-trust">
        <Container maxWidth="lg">
          <h2 className="dh-home__title">Medios que confían en nosotros</h2>
          <Gallery data={medios} />
        </Container>
      </div>
      <div className="home-divider" />
      <div className="alliest">
        <Container maxWidth="lg">
          <h2 className="dh-home__title">Nuestros Aliados</h2>
          <Gallery data={aliados} />
        </Container>
      </div>
      <div className="home-divider" />
      <DialogHouserRequirements
        open={openDialog}
        handleClose={handleCloseDialog}
        handleNext={handleGoToRegisterCuidador}
      />
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default Home;
