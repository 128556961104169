import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export default function themePalette(theme) {
  return {
    type: 'light',
    primary: {
      contrastText: '#fff',
      light: theme.primaryLight,
      main: theme.primaryMain,
      dark: theme.primaryDark,
      200: theme.primary200,
      800: theme.primary800,
    },
    secondary: {
      light: theme.secondaryLight,
      main: theme.secondaryMain,
      dark: theme.secondaryDark,
      200: theme.secondary200,
      800: theme.secondary800,
    },
    grey: {
      50: theme.grey50,
      100: theme.grey100,
      300: theme.grey300,
      500: theme.grey500,
      600: theme.grey600,
      700: theme.grey700,
      900: theme.grey900,
    },
    text: {
      primary: theme.textColor,
      secondary: theme.textColorLigth,
      dark: theme.textColor,
      hint: theme.grey100,
    },
    // divider: theme.grey300,
    divider: 'rgba(0, 0, 0, 0.1)',
    background: {
      paper: theme.paper,
      default: theme.background,
    },
  } as PaletteOptions;
}
