import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../Context';
import './commentArea.scss';

import * as OPTIONS from '@root/services/options';
import * as SERVICES from '@root/services/config';
import * as VARIABLES from '@root/constants/variables';
// Components
import Notification from '../notification/Notification';
import DialogUserNotRegister from '../dialogUserNotRegister/DialogUserNotRegister';
// Material UI
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
// Material Iconos
import PersonIcon from '@material-ui/icons/Person';

function Comment({ comment }) {
  let fechaComentario = new Date(comment.created_at);
  let commentMonth = VARIABLES.VARIANT_MONTH[fechaComentario.getMonth() + 1];
  let commentYear = fechaComentario.getFullYear();
  let textCommentTime = commentMonth + ' de ' + commentYear;

  return (
    <div className="dh-card-comment">
      <div className="dh-card-comment__image">
        {comment.user.photo ? (
          <img
            className="dh-card-comment__image__user-img"
            src={`${VARIABLES.IMAGE_URL}${comment.user.photo}`}
            alt={comment.name}
            onError={(ev) => (ev.target.src = 'https://cdn2.thecatapi.com/images/888.jpg')}
          />
        ) : (
          <PersonIcon />
        )}
      </div>
      <div className="dh-card-comment__content">
        <div className="dh-card-comment__content__header">
          <h3>{comment.user.name}</h3>
          <span>{textCommentTime}</span>
        </div>
        <div className="dh-card-comment__content__body">
          <p>{comment.comments}</p>
        </div>
      </div>
    </div>
  );
}

function CommentArea(props) {
  // Variables propias del componente
  const { idCuidador, type, commentIsDisable } = props;
  const { cookies, isAuth, client, houser } = useContext(Context);
  const [inputDisable, setInputDisable] = useState(commentIsDisable);
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [btnState, setBtnState] = useState(false);
  // Variables del Componente "DialogHouserRequirements"
  const [openDialog, setOpenDialog] = useState(false);
  // Variables del componeten "Notification"
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  // Variables de la data de comentarios
  const [userComment, setUserComment] = useState('');
  const [listComments, setListComments] = useState([]);
  const [canComment, setCanComment] = useState(false);

  // Funcion para cerrar el componente "Notification"
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  // Abrir el Dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Cerrar el Dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Cammbios en el input de comentario
  const handleInputChange = (e) => {
    setUserComment(e.target.value);
    setBtnState(true);
  };

  // Limpiar el input de comentario
  const handleOnClickCancel = (e) => {
    setUserComment('');
    setBtnState(false);
  };

  // Controla el submit del form
  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (userComment.trim() === '') {
      setMessage('No puedes enviar un comentario vacío');
      setMessageVariant('error');
      setMessageState(true);
      return null;
    }
    if ((isAuth && client) || (isAuth && houser)) {
      fetchCreateComment(userComment);
    } else {
      handleOpenDialog();
    }
  };

  useEffect(() => {
    if (!isAuth) {
      setInputDisable(true);
    }
    if (cookies.houserId === idCuidador) {
      setInputDisable(true);
    }
    setIsMounted(true);
    fetchData();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCuidador, type]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${SERVICES.GET_COMMENTS}${idCuidador}?user_id=${cookies.id}`,
        OPTIONS.GET(),
      );
      const data = await response.json();
      // console.log(data)
      if (isMounted) {
        if (response.status === 200) {
          setListComments(data.data);
          setCanComment(data.can_comment);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchCreateComment = async (comment) => {
    setMessage('');
    setMessageState(false);
    const body = {
      houser_id: idCuidador,
      user_id: cookies.id,
      comments: comment,
    };
    try {
      const response = await fetch(SERVICES.CREATE_COMMENT, OPTIONS.POST(body));
      // eslint-disable-next-line no-unused-vars
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setMessage('Comentario enviado.');
        setMessageVariant('success');
        setMessageState(true);
        fetchData();
      } else {
        setMessage('Se produjo un error al enviar el comentario.');
        setMessageVariant('error');
        setMessageState(true);
      }
    } catch (error) {
      setMessage('No se puedo enviar el comentario: ' + error);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <React.Fragment>
      <div className="dh-listComment">
        {!inputDisable && canComment && (
          <form className="dh-listComment__form" onSubmit={handleSubmitForm}>
            <TextField
              id="outlined-textarea"
              label="Escribe tu reseña"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
              value={userComment}
              name="comentario"
            />
            {btnState && (
              <div className="dh-listComment__btn-submit">
                <button className="btn btn-default" onClick={handleOnClickCancel}>
                  Cancelar
                </button>
                <button type="submit" className="btn btn-primary">
                  Comentar
                </button>
              </div>
            )}
          </form>
        )}
        <div className="dh-listComment__contents">
          {!loading ? (
            <React.Fragment>
              {listComments[0] &&
                listComments.map((comment) => <Comment key={comment.id} comment={comment} />)}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {Array.from(new Array(3)).map((data, index) => (
                <div className="dh-card-comment" key={index}>
                  <div className="dh-card-comment__image">
                    <Skeleton variant="circle" height={80} width="100%" />
                  </div>
                  <div className="dh-card-comment__content">
                    <div className="dh-card-comment__content__header">
                      <Skeleton height={14} width="40%" style={{ margin: '0 0 10px 0' }} />
                    </div>
                    <div className="dh-card-comment__content__body">
                      <Skeleton height={10} width="80%" style={{ margin: '5px 0' }} />
                      <Skeleton height={10} width="80%" style={{ margin: '5px 0' }} />
                      <Skeleton height={10} width="80%" style={{ margin: '5px 0' }} />
                    </div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          )}
        </div>
      </div>
      <DialogUserNotRegister
        open={openDialog}
        handleClose={handleCloseDialog}
        text="Para poder enviar un comentario es necesario que te registres."
      />
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="left"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </React.Fragment>
  );
}

export default CommentArea;
