import React from 'react';
import './cardUserBasicInfo.scss';
// Material-UI
import TextField from '@material-ui/core/TextField';

const InfoRow = ({ id, text, type, value, onChange, multiline, rows, disabled }) => (
  <li>
    <label htmlFor={id}>{text}</label>
    <TextField
      required
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      variant="outlined"
      id={id}
      name={id}
      type={type}
      value={value}
      onChange={onChange}
    />
  </li>
);

function CardUserBasicInfo(props) {
  const { data, handleSubmit, handleInputChange, btnState } = props;
  return (
    <div className="dh-card-user-info dh-card-border">
      <form className="info-form" onSubmit={handleSubmit}>
        <ul className="flex-outer">
          <InfoRow
            id="name"
            text="Nombre*:"
            type="text"
            value={data.user.name}
            onChange={handleInputChange}
          />
          <InfoRow
            id="last_name"
            text="Apellido*:"
            type="text"
            value={data.user.last_name}
            onChange={handleInputChange}
          />
          <InfoRow
            id="doc_id"
            text="Doc. Identidad*:"
            type="number"
            value={data.user.doc_id}
            onChange={handleInputChange}
          />
          <InfoRow
            id="phone"
            text="Teléfono*:"
            type="number"
            value={data.user.phone}
            onChange={handleInputChange}
          />
          <InfoRow
            id="phone_house"
            text="Teléf. Casa*:"
            type="number"
            value={data.user.phone_house}
            onChange={handleInputChange}
          />
          <InfoRow
            disabled
            id="email"
            text="E-mail*:"
            type="email"
            value={data.user.email}
            onChange={handleInputChange}
          />
          <InfoRow
            multiline
            rows="2"
            id="address"
            text="Dirección*:"
            type="text"
            value={data.user.address}
            onChange={handleInputChange}
          />
          <InfoRow
            id="birth_date"
            text="Cumpleaños*:"
            type="date"
            value={data.user.birth_date}
            onChange={handleInputChange}
          />
          <li className="last-row">
            <button className={`btn-primary ${btnState}`} type="submit">
              Guardar Cambios
            </button>
          </li>
        </ul>
      </form>
    </div>
  );
}

export default CardUserBasicInfo;
