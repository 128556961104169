import { useState, useEffect } from 'react'
// Utils
import {
  centerDefault, 
  centerPeru,
  centerPeruArequipa,
  centerPeruCallao,
  centerPeruCusco,
  centerPeruLima,
  centerPeruTrujillo,
  centerPeruPiura,
  centerColombia,
  centerColombiaBarranquilla,
  centerColombiaBello,
  centerColombiaBogota,
  centerColombiaCali,
  centerColombiaCartagena,
  centerColombiaEnvigado,
  centerColombiaItagui,
  centerColombiaMedellín,
  centerColombiaSantaMartha,
  centerEspana, 
  centerEspanaBarcelona, 
  centerEspanaMadrid, 
  centerChile, 
  centerChileSantiago 
} from '../utils/Resources'

export const locationManagement = (country, city, setCenter) => {
  if (country === "Colombia") {
    if (city === "Barranquilla") {
      setCenter(centerColombiaBarranquilla)
    } else if(city === "Bello") {
      setCenter(centerColombiaBello)
    } else if(city === "Bogota") {
      setCenter(centerColombiaBogota)
    } else if(city === "Cali") {
      setCenter(centerColombiaCali)
    } else if(city === "Cartagena") {
      setCenter(centerColombiaCartagena)
    } else if(city === "Envigado") {
      setCenter(centerColombiaEnvigado)
    } else if(city === "Itaguí") {
      setCenter(centerColombiaItagui)
    } else if(city === "Medellín") {
      setCenter(centerColombiaMedellín)
    } else if(city === "Santa Martha") {
      setCenter(centerColombiaSantaMartha)
    } else {
      setCenter(centerColombia)
    }
  } else if (country === "Perú") {
    if (city === "Arequipa") {
      setCenter(centerPeruArequipa)
    } else if (city === "Callao") {
      setCenter(centerPeruCallao)
    } else if (city === "Cusco") {
      setCenter(centerPeruCusco)
    } else if (city === "Lima") {
      setCenter(centerPeruLima)
    } else if (city === "Trujillo") {
      setCenter(centerPeruTrujillo)
    } else if (city === "Piura") {
      setCenter(centerPeruPiura)
    } else {
      setCenter(centerPeru)
    }
  } else if (country === "España") {
    if (city === "Barcelona") {
      setCenter(centerEspanaBarcelona)
    } else if (city === "Madrid") {
      setCenter(centerEspanaMadrid)
    } else {
      setCenter(centerEspana)
    }
  } else if (country === "Chile") {
    if (city === "Santiago") {
      setCenter(centerChileSantiago)
    } else {
      setCenter(centerChile)
    }
  } else {
    setCenter(centerDefault)
  }
}

export const useLocationManagement = (country, city, initialValue) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    locationManagement(country, city, setValue)
  },[city, country])

  return { value }
}
