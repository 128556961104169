import React, { useEffect, useState, useContext } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { Container, Grid, TextField } from '@material-ui/core';
import { Skeleton, Rating } from '@material-ui/lab';
import PetsIcon from '@material-ui/icons/Pets';
import { StreamChat } from 'stream-chat';

import { Context } from '@root/Context';
import { useInputValue } from '@root/hooks/useInputValue';
import { useSnackbar } from '@root/hooks';
import './clientReservationDetail.scss';
// Services
import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
// Constants
import * as VARIABLES from '@root/constants/variables';
import * as ROUTES from '@root/constants/routes';
import { DogPageHeader } from '@root/ui-component';
import StreamMessage from '@root/components/StreamMessage';
import { ReservationState } from './components';
// Material UI

// Material Icons

import moment from 'moment';

const apiKey = VARIABLES.GET_STREAM_KEY;

function ClientReservationDetail(props) {
  // ID de la Reserva
  const id = props.match.params.id;
  const snackbar = useSnackbar();

  const history = useHistory();

  moment.locale('es');

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  const [booking, setBooking] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [services, setServices] = useState([
    {
      service: {
        id: null,
        name: '',
        image: null,
        money: {
          id: null,
          abreviated: '',
        },
      },
    },
  ]);
  const [housePhoto, setHousePhoto] = useState('');

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
    timeZone: 'UTC',
  };

  // eslint-disable-next-line no-unused-vars
  const [money, setMoney] = useState('');

  const { cookies, userName, userImage } = useContext(Context);
  const mUserObj = {
    id: `ms-${cookies.id}`,
    name: userName,
    image: `${VARIABLES.IMAGE_URL}${userImage}`,
  };
  const [chatClient] = useState(new StreamChat(apiKey));
  const [channel, setChannel] = useState();

  const comment = useInputValue('');
  const [rating, setRating] = useState(0);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let collection_id = params.get('collection_id');
  let collection_status = params.get('collection_status');

  const fetchReservationData = async () => {
    setLoading(true);
    try {
      const response = await fetch(SERVICES.GET_BOOKING_BY_ID + id, OPTIONS.GET());
      const data = await response.json();
      if (response.status === 200) {
        setBooking(data.booking);
        await getGallery(data.booking.houser.user.id);
      } else {
        snackbar.error('Error al obtener los datos');
      }

      const resToken = await fetch(
        SERVICES.GET_MESSENGER_TOKEN,
        OPTIONS.POST({ me: cookies.id, target: data.booking.houser.user.id }),
      );
      const dataToken = await resToken.json();
      await chatClient.connectUser(mUserObj, dataToken.token);
      // create grouped channel
      const members = [
        mUserObj.id,
        `ms-${data.booking.houser.user.id}`,
        VARIABLES.GET_STREAM_ADMIN,
      ];
      const newChannel = chatClient.channel('messaging', {
        members: members,
      });
      await newChannel.create();
      setChannel(newChannel);
      if (!data.booking.channel_id) {
        await fetch(
          SERVICES.SET_CHANNEL_ID,
          OPTIONS.POST({
            booking_id: data.booking.id,
            channel_id: newChannel.id,
          }),
        );
        await newChannel.sendMessage({
          text: data.booking.message,
        });
      }

      setLoading(false);
    } catch (error) {
      snackbar.error(error.message);
      setLoading(false);
    }
  };

  const getGallery = async (user_id) => {
    try {
      const response = await fetch(SERVICES.GET_GALLERY_BY_USER, OPTIONS.POST({ id: user_id }));
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setHousePhoto(data.gallery[0].photo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const fetchMercadoPagoData = async (reservationId, reservationMoney, reservationTitle) => {
    try {
      const response = await fetch(
        `https://api.mercadopago.com/v1/payments/search?id=${collection_id}&access_token=${VARIABLES.MERCADO_PAGO_ACCESS_TOKEN}`,
        OPTIONS.GET(),
      );
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (data.results) {
          if (
            data.results[0].status === collection_status &&
            data.results[0].currency_id === reservationMoney &&
            data.results[0].description === reservationTitle
          ) {
            snackbar.success('Validando la reserva...');
            validateReservation(reservationId);
          }
        }
      }
    } catch (error) {
      console.log('Mercado pago error -> ' + error);
    }
  };

  const validateReservation = async (reservationId) => {
    try {
      const response = await fetch(
        SERVICES.UPDATE_RESERVATION_TO_STATE_3,
        OPTIONS.POST({ reservation_id: reservationId }),
      );
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        snackbar.success('Reserva validada...');
        setReload(true);
      } else {
        console.log('Actualizar Error -> ' + data);
      }
    } catch (error) {
      console.log('Actualizar Error -> ' + error);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const createReservationPay = async () => {
    try {
      const response = await fetch(
        SERVICES.CREATE_RESERVATION_METHOD_PAY,
        OPTIONS.POST({ reservation_id: booking.id, moneda: money }),
      );
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (data.pay) {
          snackbar.success('Se creo la reserva. Redireccionando a Mercado Pago...');
          setTimeout(() => {
            window.location = data.pay.response.init_point;
          }, 500);
        } else {
          snackbar.error('Hubo un error al obtener los datos de Mercado Pago');
          setTimeout(() => {
            window.location = ROUTES.CLIENTE_RESERVATIONS;
            // history.push(ROUTES.CLIENTE_RESERVATIONS)
          }, 500);
        }
      } else {
        snackbar.error('No se pudo crear la reserva.');
      }
    } catch (error) {
      console.log('Crear Method Error -> ' + error);
    }
  };

  const updateBooking = async (status) => {
    let body = {
      booking_id: id,
      status: status,
    };
    try {
      const response = await fetch(SERVICES.UPDATE_BOOKING_STATUS, OPTIONS.POST(body));
      const data = await response.json();
      if (response.status === 200) {
        if (data.error) {
          snackbar.error(data.error);
        } else {
          snackbar.success('Validando...');
        }
        setReload(true);
      } else {
        console.log('Actualizar Error -> ' + data);
      }
    } catch (error) {
      console.log('Actualizar Error -> ' + error);
    }
  };

  const addComment = async () => {
    try {
      const response = await fetch(
        SERVICES.ADD_COMMENT,
        OPTIONS.POST({
          booking_id: booking.id,
          feedback: comment.value,
          feedback_level: rating,
        }),
      );
      const data = await response.json();
      if (data.booking) {
        setBooking(data.booking);
      }
    } catch (error) {
      console.log('Error on Comment');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchReservationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <div className="client-reservation-detail">
      <Container maxWidth="lg">
        <DogPageHeader subTitle="Volver" onBack={() => props.history.goBack()} />
        {booking && (
          <Grid container spacing={3}>
            {booking.state === 1 && (
              <Grid item xs={12}>
                <div className="dh-card-border">
                  <div className="client-reservation-detail__section-title">
                    <h3>Acciones</h3>
                    <div className="border-space" />
                  </div>
                  <h4>Necesitas confirmar la reserva</h4>
                  <div className="dh-flex dh-button-container">
                    <div style={{ paddingRight: '0.5rem' }}>
                      <div className="client-reservation-detail__btn-action">
                        <button
                          className="btn-success"
                          onClick={() =>
                            history.push(`/cuidador/request/${booking.id}/modify`)
                          }>
                          Modificar Reserva
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
            {booking.state === 5 && (
              <Grid item xs={12}>
                <div className="dh-card-border">
                  <div className="dh-flex content-space">
                    <div className="client-reservation-detail__section-title">
                      <h3>Comentario</h3>
                      <div className="border-space" />
                    </div>
                  </div>
                  <div className="res-detail__body">
                    <div className="res-detail__body--grid-1">
                      <div className="res-detail__body--item">
                        <p>tasa de servicio</p>
                        <Rating
                          name="customized-empty"
                          value={rating}
                          onChange={(e, v) => setRating(v)}
                          precision={0.5}
                          icon={<PetsIcon fontSize="inherit" />}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="res-detail__body--item">
                        <p>reacción</p>
                        <TextField
                          id="comment"
                          name="comment"
                          required
                          fullWidth
                          variant="outlined"
                          type="text"
                          multiline
                          rows="5"
                          value={comment.value}
                          onChange={comment.onChange}
                        />
                      </div>
                    </div>
                    <button
                      style={{ marginTop: 20 }}
                      onClick={() => addComment()}
                      className="btn-primary medium btn-step">
                      Agregar comentario
                    </button>
                  </div>
                </div>
              </Grid>
            )}
            {booking.state === 6 && (
              <Grid item xs={12}>
                <div className="dh-card-border">
                  <div className="dh-flex content-space">
                    <div className="client-reservation-detail__section-title">
                      <h3>Comentario</h3>
                      <div className="border-space" />
                    </div>
                  </div>
                  <div className="res-detail__body">
                    <div className="res-detail__body--grid-1">
                      <div className="res-detail__body--item">
                        <p>tasa de servicio</p>
                        <Rating
                          name="customized-empty"
                          value={booking.feedback_level}
                          precision={0.5}
                          icon={<PetsIcon fontSize="inherit" />}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="res-detail__body--grid-1">
                      <div className="res-detail__body--item">
                        <p>reacción</p>
                        <h4>{booking.feedback}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <div className="dh-card-border res-detail">
                <div className="dh-flex content-space">
                  <div className="client-reservation-detail__section-title">
                    <h3>Detalles de la Reserva</h3>
                    <div className="border-space" />
                  </div>
                  <ReservationState status={booking.state} endDate={booking.pickup_date} />
                </div>
                <div className="res-detail__body">
                  <div className="res-detail__body--grid-1">
                    <div className="res-detail__body--item">
                      <p>Codigo de la reserva</p>
                      <h4>#{booking.id}</h4>
                    </div>
                    <div className="res-detail__body--item">
                      <p>Fecha de entrada</p>
                      <h4>{new Date(booking.drop_date).toLocaleString('es', dateOptions)}</h4>
                    </div>
                    <div className="res-detail__body--item">
                      <p>Fecha de salida</p>
                      <h4>{new Date(booking.pickup_date).toLocaleString('es', dateOptions)}</h4>
                    </div>
                  </div>
                  <div className="res-detail__body--grid-1">
                    {booking.booking_has_pets.map((data, index) => (
                      <div className="res-detail__body--item" key={index}>
                        <p>Mascota {index + 1}</p>
                        <h4>{data.pet.name}</h4>
                      </div>
                    ))}
                  </div>
                  <div className="res-detail__body--grid-1">
                    {/* {services.map((data, index) => (
                      <div className="res-detail__body--item" key={index}>
                        <p>Servicio {index + 1}</p>
                        <h4>{data.service.name} - {data.service.country}, {data.service.city}</h4>
                      </div>
                    ))} */}
                    <div className="res-detail__body--item">
                      <p>Servicio</p>
                      <h4>{booking.service.name}</h4>
                    </div>
                  </div>
                  {/* <div className="res-detail__body--grid-3">
                    <div className="res-detail__body--item">
                      <p>Mascotas descripción</p>
                      <h4>{booking.pets_desc}</h4>
                    </div>  
                  </div> */}
                  <div className="divider-line" style={{ marginTop: '8px' }} />
                  <div className="res-detail__body--item-price">
                    <p>Total:</p>
                    <h3>
                      {VARIABLES.CURRENCY_BY_COUNTRY[booking.service.country]}
                      {booking.price_total.toFixed(2)}
                    </h3>
                  </div>
                </div>
              </div>
            </Grid>
            {booking.state === 1 && (
              <Grid item xs={12}>
                <div className="dh-card-border">
                  <div>
                    <h4>El cuidador aun no confirma la reserva</h4>
                    <div className="client-reservation-detail__btn-action">
                      <div className="wait-holder">
                        <p>
                          ¡Muchas gracias por su solicitud! La hemos enviado al cuidador que
                          tiene un plazo de 6 horas para contestar. Puedes ir contactando otros
                          cuidadores si deseas. Una vez que el cuidador confirme, tienes hasta
                          12 horas para hacer el pago indicando el número de reserva que
                          tomarás. 🙂
                        </p>
                        <p>
                          Los datos de esta reserva son: <br />
                          Número: #{booking.id} <br />
                          Cuidador: {booking.houser.user.name}
                          <br />
                          Tiempo desde:{' '}
                          {new Date(booking.drop_date).toLocaleString('es', dateOptions)}
                          <br />
                          Hora de:{' '}
                          {new Date(booking.pickup_date).toLocaleString('es', dateOptions)}
                          <br />
                          Perro: {booking.booking_has_pets.map((p) => p.pet.name).join(', ')}
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
            {booking.state === 2 && (
              <Grid item xs={12}>
                <div className="dh-card-border">
                  <h4>Esta reserva aún no se ha pagado</h4>
                  <RouterLink className="dh-no-decoration" to={`/cuidador/${id}/checkout`}>
                    <button
                      style={{ marginTop: 20 }}
                      type="submit"
                      className="btn-primary medium btn-step">
                      Pagar ahora
                    </button>
                  </RouterLink>
                </div>
              </Grid>
            )}
            {booking.state === 3 && (
              <Grid item xs={12}>
                <div className="dh-card-border">
                  <h4>Esta reserva está lista para completar</h4>
                  <button
                    style={{ marginTop: 20 }}
                    onClick={() => updateBooking(5)}
                    className="btn-primary medium btn-step">
                    Reserva completa
                  </button>
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className="dh-card-border stay-detail">
                <div className="stay-detail__flex">
                  <div className="stay-detail__image">
                    {!loading ? (
                      <img src={VARIABLES.IMAGE_URL + housePhoto} alt="Casa del cuidador" />
                    ) : (
                      <Skeleton width="100%" height="100%" />
                    )}
                  </div>
                  <div className="stay-detail__content">
                    <div className="client-reservation-detail__section-title">
                      <h3>Información del cuidador</h3>
                      <div className="border-space" />
                    </div>
                    <div className="stay-detail__content--grid">
                      <div className="stay-detail__content--item">
                        <p>Nombre del Cuidador</p>
                        <h4>
                          {booking.houser.user.name} {booking.houser.user.last_name}
                        </h4>
                      </div>
                      {booking.state === 3 && (
                        <React.Fragment>
                          <div className="stay-detail__content--item">
                            <p>Teléfono</p>
                            <h4>{booking.houser.user.phone}</h4>
                          </div>
                          <div className="stay-detail__content--item">
                            <p>Teléfono de casa</p>
                            <h4>{booking.houser.user.phone_house}</h4>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    {booking.state === 3 && (
                      <React.Fragment>
                        <div className="stay-detail__content--item">
                          <p>Dirección</p>
                          <h4>{booking.houser.user.address}</h4>
                        </div>
                        <div className="stay-detail__content--item">
                          <p>Detalle de la dirección</p>
                          <h4>{booking.houser.adress_detail}</h4>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className="container">
              {!loading && booking.state < 4 && (
                <StreamMessage chatClient={chatClient} channel={channel} booking={booking} />
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
}

export default ClientReservationDetail;
