import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
// Constants
import * as ROUTES from 'constants/routes'
// components
import LoadingText from 'components/loadingText'
// Material-UI
import Grid from '@material-ui/core/Grid'

const PublicationLocation = props => {
  const { loading, user } = props

  return (
    <div className="publication-card">
      <div className="cuidador-publication__sub-title">
        <h3>Ubicación</h3>
      </div>
      <Grid container spacing={2} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12} sm={6}>
          {!loading ? (
            <React.Fragment>
              {user.address ? (
                <p className="text-primary">{user.address}</p>
              ) : (
                <p className="text-primary">No se encontró la dirección</p>
              )}
              {user.location.district ? (
                <p className="text-primary">{user.location.district}</p>
              ) : (
                <p className="text-primary">No se encontró el distrito</p>
              )}
              {user.location.country ? (
                <p className="text-primary">{user.location.city}{', '}{user.location.country}</p>
              ) : (
                <p className="text-primary">No se encontró el país</p>
              )}
            </React.Fragment>
          ) : (
            <LoadingText count={1}/>
          )}
        </Grid>
        <Grid item xs={12} sm={2} className="btn-container">
          <RouterLink to={ROUTES.CUIDADOR_PUBLICACION_UBICACION} className="dh-no-decoration">
            <button className="btn-primary border">
              Editar
            </button>
          </RouterLink>
        </Grid>
      </Grid>
    </div>
  )
}

PublicationLocation.propTypes = {
  loading: PropTypes.bool,
  galleries: PropTypes.array.isRequired
}

export default PublicationLocation
