import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import './cardAdminPlaces.scss'
// Constants
import * as VARIABLES from '../../constants/variables'
// Icon
import NoFoto from '../../assets/images/ico/ic_no_foto.svg';

function CardAdminPlaces(props) {
  const { place, pathName } = props;
  return (
    <div className="dh-card-admin-places">
      <div className="dh-card-admin-places__image">
        {place.image_card ? (
          <img src={`${VARIABLES.IMAGE_URL}${place.image_card}`} alt="PetFriendly"/>
        ) : (
          <div className="dh-card-admin-places__image__not-fount">
            <img src={NoFoto} alt="No se encontro una foto"/>
            <p>No se encontró ninguna imagen</p>
          </div>
        )}
      </div>
      <div className="dh-card-admin-places__content">
        <h3>{place.title}</h3>
        <hr></hr>
        <h4>Ciudad : <span>{place.location.city}, {place.location.country}</span></h4>
        <h4>Dirección : <span>{place.adress}</span></h4>
        <RouterLink to={pathName}>
          <div className="dh-card-admin-places__content__btn">
            <button  className="btn btn-primary">Editar</button>
          </div>
        </RouterLink>
      </div>
    </div>
  )
}

export default CardAdminPlaces
