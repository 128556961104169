import React, { ReactNode } from 'react';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import styles from './pageHeader.module.scss';

interface PageHeaderProps {
  title?: ReactNode | string;
  subTitle?: ReactNode | string;
  text?: ReactNode | string;
  extra?: ReactNode;
  onBack?: () => void;
}

const PageHeader = (props: PageHeaderProps) => {
  const { title, subTitle, text, extra, onBack } = props;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        {onBack && (
          <div className={styles.back} style={{ padding: title ? '0.5rem 0' : 0 }}>
            <IconButton color="inherit" size="small" onClick={onBack}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        )}
        <div className={styles.box}>
          {title && <h2 className={styles.title}>{title}</h2>}
          {subTitle && <h3 className={styles.subTitle}>{subTitle}</h3>}
          {text && <span className={styles.text}>{text}</span>}
        </div>
        {extra && <div className={styles.action}>{extra}</div>}
      </div>
    </div>
  );
};

export default PageHeader;
