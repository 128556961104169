import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Context } from '@root/Context';
import './headerHouser.scss';

import * as SERVICES from 'services/config';
import * as OPTIONS from '@root/services/options';
import * as ROUTES from 'constants/routes';
import * as VARIABLES from 'constants/variables';
// Images
import dhLogo from 'assets/dh_logo_mini.png';
// Componets
import Notification from 'components/notification/Notification';
import UserDropdownMenu from 'components/userDropdownMenu/UserDropdownMenu';
import UserProfileSideList from 'components/userProfileSideList/UserProfileSideList';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Material Icons
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from '@material-ui/icons/Work';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Badge from '@material-ui/core/Badge';
import Pusher from 'pusher-js';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  menuDropdownIcon: {
    marginRight: '0.5rem',
  },
});

function HeaderHouser(props) {
  const classes = useStyles();
  const {
    notificationCt,
    isAuth,
    cookies,
    houser,
    userName,
    userImage,
    activateAuth,
    removeAuth,
    setUserData,
    setNotificationCount,
  } = useContext(Context);
  const { location } = props;
  const [class0, setClass0] = useState('');
  const [class1, setClass1] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [class2, setClass2] = useState('');
  const [class3, setClass3] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [class4, setClass4] = useState('');
  const [class5, setClass5] = useState('');
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [state, setState] = useState({
    left: false,
  });
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState(false);

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  // Funcion para cerrar el componete Notification
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  useEffect(() => {
    const pusher = new Pusher('11d3baa9a512d36fb109', {
      cluster: 'mt1',
      encrypted: true,
    });
    const channel = pusher.subscribe('booking');
    channel.bind('notification', (data) => {
      fetchUnreadCount();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location) {
      handleState(location);
    }
    if (isAuth && houser) {
      if (cookies.id) {
        fetchUser();
      }
      if (cookies.houserId) {
        fetchUnreadCount();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, houser, cookies.id]);

  const fetchUnreadCount = async () => {
    const response = await fetch(
      `${SERVICES.UNREAD_COUNT_BY_HOUSER}${cookies.houserId}`,
      OPTIONS.GET(),
    );
    const data = await response.json();
    if (data) {
      setNotificationCount(data.count);
    }
  };

  // Se obtiene el ID del Usuario y se prepara para enviarlo
  const fetchUser = async () => {
    setMessageState(false);
    const body = {
      id: cookies.id,
    };
    sendUserData(SERVICES.USER_BY_ID, OPTIONS.POST(body));
  };

  // Se envia los datos del Usuario al API y se espera la respuesta
  const sendUserData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      let houserId = '';
      // console.log(data)
      if (data.status === 200) {
        setMessageState(false);
        if (data.houser) {
          houserId = data.houser.id;
        }
        // Gestión del usuario
        activateAuth(
          data.user.id, // Token
          data.user.id, // Id del Usuario
          houserId, // Id del Perfil Cuidador - Houser
          data.user.confirmation, // Estado del Cliente
          data.user.terms_conditions, // Estado del Cuidador
        );
        setUserData(data.user.name, data.user.photo);
        // setLocation(data.location.country, data.location.city)
      } else {
        setMessageState(true);
        setMessage(data.message);
      }
    } catch (error) {
      setMessageState(true);
      setMessage(error.message);
    }
  };

  const removeState = () => {
    setClass0('');
    setClass1('');
    setClass2('');
    setClass3('');
    setClass4('');
    setClass5('');
  };

  const handleState = (e) => {
    if (e === ROUTES.CUIDADOR_DASHBOARD) {
      removeState();
      setClass0('active');
    } else if (e === ROUTES.CUIDADOR_RESERVAS) {
      removeState();
      setClass1('active');
    } else if (e === ROUTES.CUIDADOR_CALENDARIO) {
      removeState();
      setClass2('active');
    } else if (e === ROUTES.CUIDADOR_PUBLICACION) {
      removeState();
      setClass3('active');
    } else if (e === ROUTES.CUIDADOR_NOTIFICACION) {
      removeState();
      setClass4('active');
    } else if (e === ROUTES.CUIDADOR_AYUDA) {
      removeState();
      setClass5('active');
    }
  };

  const toggleDrawer = (side, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Cerrar el Drop-down Menu
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // Cerrar la sesion del Usuario
  const logout = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    removeAuth();
  };

  // Componente principal del SideList
  const sideListItem = (path, icon, text) => (
    <RouterLink to={path} className="cuidador-list-item">
      <ListItem button>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </RouterLink>
  );

  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}>
      <UserProfileSideList
        userPhoto={userImage}
        userName={userName}
        // client={client}
        houser={houser}
      />
      <Divider />
      <List>
        {sideListItem(ROUTES.CUIDADOR_DASHBOARD, <ViewModuleIcon />, 'Panel')}
        {sideListItem(ROUTES.CUIDADOR_RESERVAS, <WorkIcon />, 'Reservas')}
        {/* {sideListItem(ROUTES.CUIDADOR_CALENDARIO, <CalendarTodayIcon/>, 'Calendario')} */}
        {sideListItem(ROUTES.CUIDADOR_PUBLICACION, <InboxIcon />, 'Anuncio')}
        {sideListItem(ROUTES.CUIDADOR_NOTIFICACION, <InboxIcon />, 'Notificaciones')}
        {sideListItem(ROUTES.CUIDADOR_AYUDA, <HelpIcon />, 'Ayuda')}
      </List>
      <Divider />
      <List>
        {sideListItem(ROUTES.HOME, <HomeIcon />, 'Volver al Inicio')}
        <ListItem button onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Cerrar Sesión" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <header className="houser-header">
        <Container maxWidth="lg">
          <div className="menu-icon">
            <div className="text-lines" onClick={toggleDrawer('left', true)}>
              <span></span>
            </div>
            <SwipeableDrawer
              open={state.left}
              onClose={toggleDrawer('left', false)}
              onOpen={toggleDrawer('left', true)}
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}>
              {sideList('left')}
            </SwipeableDrawer>
          </div>
          <RouterLink to={ROUTES.HOME} className="menu-logo">
            <img className="menu-logo__image" src={dhLogo} alt="Logo de la web" />
          </RouterLink>
          <nav className="menu-left">
            <ul>
              <li>
                <RouterLink
                  to={ROUTES.CUIDADOR_DASHBOARD}
                  onClick={() => handleState(ROUTES.CUIDADOR_DASHBOARD)}
                  className={class0}>
                  Inicio
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to={ROUTES.CUIDADOR_RESERVAS}
                  onClick={() => handleState(ROUTES.CUIDADOR_RESERVAS)}
                  className={class1}>
                  Reservas como cuidador
                </RouterLink>
              </li>
              {/*
              <li>
                <RouterLink to={ROUTES.CUIDADOR_CALENDARIO} onClick={() => handleState(ROUTES.CUIDADOR_CALENDARIO)} className={class2}>Calendario</RouterLink>
              </li>
              */}
              <li>
                <RouterLink
                  to={ROUTES.CUIDADOR_PUBLICACION}
                  onClick={() => handleState(ROUTES.CUIDADOR_PUBLICACION)}
                  className={class3}>
                  Anuncio
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to={ROUTES.CUIDADOR_NOTIFICACION}
                  onClick={() => handleState(ROUTES.CUIDADOR_NOTIFICACION)}
                  className={class4}>
                  Notificaciones
                  {notificationCt > 0 && (
                    <Badge
                      badgeContent={notificationCt}
                      color="secondary"
                      style={{ marginTop: -10, marginLeft: 10 }}></Badge>
                  )}
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to={ROUTES.CUIDADOR_AYUDA}
                  onClick={() => handleState(ROUTES.CUIDADOR_AYUDA)}
                  className={class5}>
                  Ayuda
                </RouterLink>
              </li>
            </ul>
          </nav>
          <ul className="menu-right">
            <li>
              <div
                className="dh-user-logged"
                ref={anchorRef}
                aria-controls="menu-list-grow"
                aria-haspopup="true"
                onClick={handleToggle}>
                <div className="dh-user-logged__name">
                  <p>¡Hola {userName}!</p>
                </div>
                <div className="dh-user-logged__ico">
                  {!userImage ? (
                    <Skeleton
                      className="profile-image"
                      variant="circle"
                      width={40}
                      height={40}
                    />
                  ) : (
                    <Avatar
                      className="profile-image"
                      alt="Usuario"
                      src={`${VARIABLES.IMAGE_URL}${userImage}`}
                    />
                  )}
                </div>
              </div>
            </li>
          </ul>
        </Container>
      </header>
      <UserDropdownMenu
        open={open}
        anchorRef={anchorRef}
        handleClose={handleClose}
        logout={logout}
        // client={client}
        houser={houser}
      />
      {messageState && (
        <Notification
          message={message}
          variant="error"
          vertical="bottom"
          horizontal="left"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </React.Fragment>
  );
}

export default HeaderHouser;
