import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
// Constants
import * as ROUTES from 'constants/routes'
import * as VARIABLES from 'constants/variables'
// Images Dog Houser
import NoFoto from 'assets/images/ph_no_foto.png'
// Material-UI
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

const GalleryPublication = props => {
  const { loading, galleries } = props

  return (
    <div className="publication-card cuidador-publication__photos">
      <div className="cuidador-publication__sub-title">
        <h3>Fotos</h3>
      </div>
      <div className="gallery">
        <Grid container spacing={2} direction="row" alignItems="center" justify="space-between">
          {!loading ? (
            <React.Fragment>
              {(galleries.length > 0) ? (
                <React.Fragment>
                  {galleries.slice(0,5).map((gallerie, index) => (
                    <Grid item xs={12} sm={2} key={index} className="gallery-grid">
                      <div className={`gallery__item ${galleries.length > 5 && 'max'}`}>
                        <img
                          src={VARIABLES.IMAGE_URL + gallerie.photo}
                          className="gallery__img"
                          alt="casa del cuidador"
                        />
                        <RouterLink to={ROUTES.CUIDADOR_PUBLICACION_FOTOS}>
                          <div className="photo-overlay">
                            <div className="photo-overlay-content text-center">
                              <strong>{galleries.length - 5}+ Fotos</strong>
                              <p className="small">Ver todo</p>
                            </div>
                          </div>
                        </RouterLink>
                      </div>
                    </Grid>
                  ))}
                </React.Fragment>
              ) : (
                <Grid item xs={12} sm={2}>
                  <div className="gallery__item">
                    <img
                      src={NoFoto}
                      className="gallery__img"
                      alt="gallery"/>
                  </div>
                </Grid>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {Array.from(new Array(5)).map((data, index) => {
                return (
                  <Grid item xs={12} sm={2} key={index}>
                    <div className="gallery__item">
                      <Skeleton variant="rect" className="gallery__img"/>
                    </div>
                  </Grid>
                )
              })}
            </React.Fragment>
          )}
          <Grid item xs={12} sm={2} className="btn-container gallery-grid">
            <RouterLink to={ROUTES.CUIDADOR_PUBLICACION_FOTOS} className="dh-no-decoration">
              <button className="btn-primary border">
                Editar
              </button>
            </RouterLink>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

GalleryPublication.propTypes = {
  loading: PropTypes.bool,
  galleries: PropTypes.array.isRequired
}

export default GalleryPublication
