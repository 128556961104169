import React from 'react'
import PropTypes from 'prop-types'
// Dependencies
import Workbook from 'react-excel-workbook'
// Constants
import * as VARIABLES from 'constants/variables'
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import VerticalAlignBottomRoundedIcon from '@material-ui/icons/VerticalAlignBottomRounded'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: '1rem',
  },
  button: {
    fontFamily: 'Montserrat-Semibold',
    fontSize: '15px',
  },
}));

const WorkbookServices = props => {
  const classes = useStyles();
  const { data } = props

  const exportData = []

  data.forEach(res => {
    const item = {
      id: res.id,
      name: res.name,
      price_cuidador: res.money.symbol + res.houser_comission,
      price_doghouser: res.money.symbol + res.gross_comission,
      total: res.money.symbol + (parseInt(res.gross_comission, 10) + parseInt(res.houser_comission, 10)),
      level: VARIABLES.LEVEL_NAME[res.houser_level_id],
      country: res.country,
      city: res.city
    }
    exportData.push(item)
  })

  return (
    <div className={classes.content}>
      <Workbook
        filename="services_data.xlsx" 
        element={
          <Button
            className={classes.button}
            startIcon={<VerticalAlignBottomRoundedIcon/>}
          >
            Exportar
          </Button>
        }>
        <Workbook.Sheet data={exportData} name="Reservas">
          <Workbook.Column label="ID" value="id"/>
          <Workbook.Column label="Nombre del producto" value="name"/>
          <Workbook.Column label="Comisión Cuidador/Proveedor" value="price_cuidador"/>
          <Workbook.Column label="Comisión Bruta" value="price_doghouser"/>
          <Workbook.Column label="Total" value="total"/>
          <Workbook.Column label="Nivel de Cuidador" value="level"/>
          <Workbook.Column label="País" value="country"/>
          <Workbook.Column label="Ciudad" value="city"/>
        </Workbook.Sheet>
      </Workbook>
    </div>
  )
}

WorkbookServices.propTypes = {
  data: PropTypes.array.isRequired
}

export default WorkbookServices
