import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { loadCSS } from 'fg-loadcss';
import './btnCorreo.scss';
// Material-UI
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';

function BtnCorreo(props) {
  const { to, text } = props

  useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
  },[])

  return (
    <RouterLink to={to} className="social-content dh-no-decoration">
      <button className="btn-correo">
        <Icon className={clsx('fas fa-envelope')} />
        {text}
      </button>
    </RouterLink>
  )
}

export default BtnCorreo
