import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export default function themeTypography(theme) {
  return {
    fontFamily: theme.fontFamily,
    h6: {
      fontWeight: 500,
      color: theme.textColor,
      fontSize: '0.75rem',
    },
    h5: {
      fontSize: '0.875rem',
      color: theme.textColor,
      fontWeight: 500,
    },
    h4: {
      fontSize: '1rem',
      color: theme.textColor,
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.25rem',
      color: theme.textColor,
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.5rem',
      color: theme.textColor,
      fontWeight: 700,
    },
    h1: {
      fontSize: '2.125rem',
      color: theme.textColor,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '0.875rem',
      color: theme.textColor,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.75rem',
      color: '#8a8989',
      fontWeight: 400,
    },
    caption: {
      fontSize: '0.75rem',
      color: '#8a8989',
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.334em',
    },
    body2: {
      letterSpacing: '0em',
      fontWeight: 400,
      lineHeight: '1.5em',
      color: theme.textColor,
    },
  } as TypographyOptions;
}
