import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './stepCuidadorTest.scss'
// Services
import * as SERVICES from 'services/config'
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables'
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// Components
import Notification from 'components/notification';
// Assets
import GuideColor from 'assets/icons/guide@color.svg'
import PertInsurance from 'assets/icons/pet-insurance@color.svg'
// Material-UI
import Grid from '@material-ui/core/Grid';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const StepCuidadorTest = props => {
  // eslint-disable-next-line no-unused-vars
  const { id, houserId, handleNext, handleBack, activeStep } = props
  // Variables de "Notification"
  const notification = useNotificationValue()
  const [valid, setValid] = useState(false)

  // Se confirma el estado del examen a true(1)
  const updateUserData = () => {
    let body = {
      houser_id: houserId,
      examen_confirmed: 1,
    }
    sendUserData(SERVICES.CONFIRM_TEST, OPTIONS.POST(body))
  }

  const sendUserData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      // const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        notification.handleSnackbar('Datos actualizados', 'success')
        handleNext()
      } else {
        notification.handleSnackbar("Error al actualizar los datos", 'error')
      }
    } catch (error) {
      notification.handleSnackbar('Error:' + error.message, 'error')
    }
  }

  return (
    <div className="step-cuidador-test">
      <div className="register-steps__title-content">
        <h2>Evaluación</h2>
        <p>¡Felicidades! Has pasado a la última etapa de afiliación de houser. El último paso para que te valides e ingreses a la plataforma como cuidador es que demuestres que cumples con el perfil y que conoces perfectamente el programa.</p>
      </div>
      <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
        <Grid item>
          <div className="card-cuidador-test dh-card-elevation">
            <div className="card-cuidador-test__content">
              <h3>Manual del Cuidador</h3>
              <div className="card-cuidador-test__content--icon">
                <img src={GuideColor} alt="Manual del Cuidador"/>
              </div>
            </div>
            <div className="card-cuidador-test__actions">
              <a
                href={VARIABLES.IMAGE_URL + 'files/Manual+de+Houser+-+PERU_COLOMBIA.pdf'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn-accent border">
                  <CloudDownloadIcon/>
                  Descargar
                </button>
              </a>
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className="card-cuidador-test dh-card-elevation">
            <div className="card-cuidador-test__content">
              <h3>Mapa de Riesgos</h3>
              <div className="card-cuidador-test__content--icon">
                <img src={PertInsurance} alt="Manual del Cuidador"/>
              </div>
            </div>
            <div className="card-cuidador-test__actions">
              <a
                href={VARIABLES.IMAGE_URL + 'files/MAPA+DE+RIESGOS+EN+EL+HOGAR.docx'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn-accent border">
                  <CloudDownloadIcon/>
                  Descargar
                </button>
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="step-cuidador-test__exam-link">
        <p>Te pedimos contestar unas preguntas y validaremos las respuestas y nos contactaremos si aprobaste! <strong>TRATA DE EXPLAYARTE PARA CONOCERTE MEJOR.</strong></p>
        <div>
          <a 
            href="https://forms.gle/3MzwM2RHoAPrMDKB8"
            target="_blank"
            rel="noopener noreferrer">
            <button className="btn-accent" onClick={() => setValid(true)}>
              <ArrowForwardRoundedIcon/>
              Ir a la evaluación
            </button>
          </a>
        </div>
      </div>
      <div className="btn-container">
        {handleBack &&
          <button
            onClick={handleBack}
            className={`btn-primary medium border ${activeStep === 0 ? "disabled" : null }`}
            style={{marginRight: 16}}>
            Atras
          </button>
        }
        <button type="submit" className={`btn-primary medium ${!valid && "disabled"}`} onClick={updateUserData}>
          Guardar y Continuar
        </button>
      </div>
      {notification.messageState &&
        <Notification 
          message={notification.message} 
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState} 
          onClose={notification.handleCloseSnackbar}
        />
      }
    </div>
  )
}

StepCuidadorTest.propTypes = {
  id: PropTypes.string.isRequired,
  houserId: PropTypes.string.isRequired,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  activeStep: PropTypes.number
}

export default StepCuidadorTest
