/* eslint-disable eqeqeq */
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Context } from '@root/Context';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import { StreamChat } from 'stream-chat';
/*
import {
  Chat,
  Channel,
  MessageInput,
  ChannelHeader,
  ChannelList,
  VirtualizedMessageList,
} from "stream-chat-react";
*/
import 'stream-chat-react/dist/css/index.css';

import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
import * as VARIABLES from 'constants/variables';
import { SERVICE_TYPES } from 'constants/services';
import './bookingMessage.scss';

import useNotificationValue from 'hooks/useNotificationValue';
import Notification from 'components/notification';
import StreamMessage from 'components/StreamMessage';

const apiKey = VARIABLES.GET_STREAM_KEY;

function BookingMessage(props) {
  const id = props.match.params.id; // booking id
  const { cookies, userName, userImage } = useContext(Context);
  const cUser = cookies.id;

  const mUserObj = {
    id: `ms-${cUser}`,
    name: userName,
    image: `${VARIABLES.IMAGE_URL}${userImage}`,
  };

  const [chatClient] = useState(new StreamChat(apiKey));
  const [channel, setChannel] = useState();
  const [booking, setBooking] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const notification = useNotificationValue();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBooking(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBooking = async (id) => {
    const response = await fetch(SERVICES.GET_BOOKING_BY_ID + id, OPTIONS.GET());
    const data = await response.json();
    if (data.booking) {
      setBooking(data.booking);
      let idTarget = '';
      // eslint-disable-next-line eqeqeq
      if (data.booking.user_id == cUser) {
        //client side
        idTarget = data.booking.houser.user.id;
        // eslint-disable-next-line eqeqeq
      } else if (data.booking.houser.user.id == cUser) {
        //houser side
        idTarget = data.booking.user_id;
      }
      // get stream token to login
      const resToken = await fetch(
        SERVICES.GET_MESSENGER_TOKEN,
        OPTIONS.POST({ me: cUser, target: idTarget }),
      );
      const dataToken = await resToken.json();
      await chatClient.connectUser(mUserObj, dataToken.token);
      // create grouped channel
      const members = [mUserObj.id, `ms-${idTarget}`];

      const newChannel = chatClient.channel('messaging', {
        members: members,
      });
      await newChannel.create();
      setChannel(newChannel);
      // sync with back-end
      if (!data.booking.channel_id) {
        await fetch(
          SERVICES.SET_CHANNEL_ID,
          OPTIONS.POST({
            booking_id: data.booking.id,
            channel_id: newChannel.id,
          }),
        );
        // eslint-disable-next-line no-unused-vars
        const message = await newChannel.sendMessage({
          text: data.booking.message,
        });
      }
      // listen for new message
      newChannel.on('message.new', (event) => {
        // eslint-disable-next-line eqeqeq
        if (event.user.id == `m${data.booking.user_id}`) {
          const msgText = event.message.text;
          newChannel
            .queryMembers({ user_id: `m${data.booking.houser.user.id}` })
            .then((res) => {
              if (!res.members[0].user.online) {
                fetch(
                  SERVICES.NOTIFY_NEW_MESSAGE,
                  OPTIONS.POST({
                    booking_id: data.booking.id,
                    message: msgText,
                  }),
                )
                  .then((res) => res.json())
                  .then((res) => console.log(res));
              }
            });
        }
      });
    }
    setLoading(false);
  };

  const onModify = async () => {
    const response = await fetch(SERVICES.GET_BOOKING_BY_ID + id, OPTIONS.GET());
    const data = await response.json();
    if (data.booking) {
      // eslint-disable-next-line eqeqeq
      if (data.booking.state != 1) {
        notification.handleSnackbar(
          'This reservation is confirmed already. Please pay for it !',
          'error',
        );
      } else {
        window.location.href = `/cuidador/request/${id}/modify`;
      }
      setBooking(data.booking);
    }
  };

  const onPay = async () => {
    const response = await fetch(SERVICES.GET_BOOKING_BY_ID + id, OPTIONS.GET());
    const data = await response.json();
    if (data.booking) {
      // eslint-disable-next-line eqeqeq
      if (data.booking.state != 2) {
        notification.handleSnackbar('This reservation is already processed.', 'error');
      } else {
        window.location.href = `/cuidador/${id}/checkout`;
      }
      setBooking(data.booking);
    }
  };

  const serviceDays = useMemo(() => {
    if (booking) {
      const pickupDate = new Date(booking.pickup_date);
      const dropDate = new Date(booking.drop_date);
      return Math.floor((pickupDate - dropDate) / (1000 * 64 * 60 * 24)) + 1;
    } else {
      return '-';
    }
  }, [booking]);

  return (
    <div className="message-window">
      <Container maxWidth="lg" className="container">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            {booking && (
              <Paper className="summary-grid">
                <h3 className="summery-title">Resumen de reserva</h3>
                <h4 className="summary-section-title">{SERVICE_TYPES[booking.service_type]}</h4>
                <Divider />
                <div className="summary-text">
                  {/* In their home <br/> */}
                  {moment(booking.drop_date).format('DD MMM hh:mm A')} ~{' '}
                  {moment(booking.pickup_date).format('DD MMM hh:mm A')}
                  <br />
                  {booking.booking_has_pets.length} mascotas <br />
                </div>
                <h4 className="summary-section-title">Cargos &amp; Servicios</h4>
                <Divider />
                <div className="invoice-text">
                  {booking.booking_has_pets.map((p, i) => (
                    <div key={`invoice-wrapper-${i}`}>
                      <div className="invoice-row">
                        <span>{p.pet.name}</span>
                        <span>${(30 * serviceDays).toFixed(2)}</span>
                      </div>
                      <div className="row-description">
                        <span>Tarifa estándar</span>
                        <br />
                        <span>{serviceDays} días @ $30 / por día</span>
                      </div>
                    </div>
                  ))}
                </div>
                <h4 className="summary-section-title">Complementos &amp; Ajustes</h4>
                <Divider />
                <div className="invoice-text">
                  <div className="invoice-row">
                    <span>Tarifa de servicio</span>
                    <span>${booking.price_tax.toFixed(2)}</span>
                  </div>
                  <div className="row-description">
                    <span>1 @ ${booking.price_tax.toFixed(2)}</span>
                    <br />
                  </div>
                </div>
                <Divider style={{ marginTop: 10 }} />
                <div className="subtotal-text">
                  <span>Total: ${(booking.price_tax + booking.price_total).toFixed(2)}</span>
                </div>
                <div className="button-container">
                  {booking.user_id == cUser && booking.state === 1 && (
                    <button
                      style={{ marginTop: 20, display: 'block', width: '100%' }}
                      type="submit"
                      className="btn-primary medium btn-step"
                      onClick={() => onModify()}>
                      Modify Request
                    </button>
                  )}

                  {booking.user_id == cUser && booking.state === 2 && (
                    <RouterLink className="dh-no-decoration" to={`/cuidador/${id}/checkout`}>
                      <button
                        style={{
                          marginTop: 20,
                          display: 'block',
                          width: '100%',
                        }}
                        type="submit"
                        className="btn-primary medium btn-step"
                        onClick={() => onPay()}>
                        Pay
                      </button>
                    </RouterLink>
                  )}
                </div>
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} lg={8}>
            {!loading && <StreamMessage chatClient={chatClient} channel={channel} />}
          </Grid>
        </Grid>
      </Container>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default BookingMessage;
