import React from 'react'
import './cardMethodPayment.scss'
// DogHouser Icons
import TrashIcon from '../../assets/icons/TrashIcon';
import PencilIcon from '../../assets/icons/PencilIcon';
//Images

function CardMethodPayment(props) {
    const {bankName, cardNumber, bankCci, handleClickOpen, handleDelete} = props
    return (
        <div className="dh-payment-card dh-card-border">
            <div className="payment-bank-name">
                <div className='payment-bank-name__info'>
                    <h3>Nombre del banco</h3>
                    <p>{bankName}</p>
                </div>
                <div className="payment-bank-name__icons">
                    <div className="payment-bank-name__editIcon" onClick={handleClickOpen}>
                        <PencilIcon setColor="white"/>
                    </div>
                    <div className="payment-bank-name__trashIcon" onClick={handleDelete}>
                        <TrashIcon setColor="white"/>
                    </div>
                </div>
            </div>
            <div className="payment-bank-card-number">
                 <h3>Número de la tarjeta</h3>
                <p>{cardNumber}</p>
            </div>
            <div className="payment-bank-cci">
                <h3>Número CCI</h3>
                <p>{bankCci}</p>
            </div>
        </div>
    )
}

export default CardMethodPayment
