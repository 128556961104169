import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './AdminPlaces.scss';
import { Context } from '@root/Context';

import * as SERVICES from 'services/config';
import * as OPTIONS from '@root/services/options';
import * as ROUTES from 'constants/routes';

// Hooks
import useSearchPlaces from 'hooks/useSearchPlaces';
// Components
import Notification from 'components/notification';
import SearchInput from 'components/SearchInput';
import CardAdminPlaces from 'components/cardAdminPlaces/CardAdminPlaces';
import ManageLocation from 'components/manageLocation';
// Material-UI
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

function AdminPlaces() {
  //Ciudad y País del local storage
  const { country, city } = useContext(Context);
  const [loading, setLoding] = useState(true);
  // Variables del componente "Notification"
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  // Variables de los Lugares
  const [places, setPlaces] = useState([]);
  const { query, setQuery, filteredPlaces } = useSearchPlaces(places);

  // Funcion para cerrar el Componente 'Notification'
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  useEffect(() => {
    if (country && city) {
      fetchPlaces(country, city);
    } else {
      fetchPlaces('Perú', 'Lima');
    }
  }, [country, city]);

  // Función que llama a la petición para listar los lugares
  const fetchPlaces = async (country, city) => {
    try {
      const response = await fetch(
        `${SERVICES.GET_PETFRIENDLY_PLACE}/${country}/${city}`,
        OPTIONS.GET(),
      );
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setPlaces(data.pet_friendly_places);
        setLoding(false);
      } else {
        setMessage('Error al cargar el contenido');
        setMessageVariant('error');
        setMessageState(true);
      }
    } catch (e) {
      setMessage('Error: ' + e.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h2 className="admin-title">Lugares</h2>
      </Grid>
      <Grid item xs={12}>
        <div className="dh-admin-places__header">
          <SearchInput
            text="Busca Lugar"
            query={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <ManageLocation />
          <div className="dh-admin-places__header__new-place">
            <RouterLink to={ROUTES.ADMIN_PLACES_CREATE}>
              <button className="btn-primary">Añadir Lugar</button>
            </RouterLink>
          </div>
        </div>
      </Grid>
      {!loading ? (
        <React.Fragment>
          {filteredPlaces.map((place, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <CardAdminPlaces
                place={place}
                pathName={`/dh-admin/lugares/editar/${place.id}/${place.location.country}/${place.location.city}`}
              />
            </Grid>
          ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {Array.from(new Array(6)).map((data, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Skeleton variant="rect" height="240px" className="dh-card-elevation" />
            </Grid>
          ))}
        </React.Fragment>
      )}
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </Grid>
  );
}

export default AdminPlaces;
