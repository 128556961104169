import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}


function CamaIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 390.6 390.6"
      style={{enableBackground: 'new 0 0 390.6 390.6'}}
      xmlSpace="preserve">
      <g>
        <path
          d="M109.5,169.2c16.6-4.3,45.3-9.6,85.8-9.6s69.1,5.3,85.8,9.6v-64.4H109.5V169.2z M205.8,131.2
            c0-8.9,13-16.1,28.9-16.1c15.9,0,28.9,7.2,28.9,16.1v22.5c0,0-17-4.3-28.7-5.4c-10.5-1-29-2.1-29-2.1V131.2z M127.1,131.2
            c0-8.9,13-16.1,28.9-16.1c15.9,0,28.9,7.2,28.9,16.1v15c0,0-18.5,1.1-29,2.1c-11.7,1.1-28.7,5.4-28.7,5.4V131.2z"
          fill={color}/>
        <path 
          d="M196.1,4C90.5,4,4.8,89.7,4.8,195.3s85.6,191.3,191.3,191.3s191.3-85.6,191.3-191.3S301.7,4,196.1,4z
          M83.8,184.2V92l12.9-12.9H294L306.8,92v92.2l31.7,50.1H52.1L83.8,184.2z M309.1,275.9v35.5h-25.8v-35.5h-176v35.5H81.5v-35.5h-30
          v-25.8h287.6v25.8H309.1z"
          fill={color}/>
      </g>
      </svg>
  )
}

export default CamaIcon
