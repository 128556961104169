import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function DogIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 512 512"
      style={{enableBackground: 'new 0 0 512 512'}}
      xmlSpace="preserve">
      <path d="M256,8C118.5,8,7,119.5,7,257s111.5,249,249,249s249-111.5,249-249S393.5,8,256,8z M179.4,80h32.4
        c23.4,0,42.4,19,42.4,42.4v29.2c0,20.6-16.8,37.4-37.4,37.4c-20.6,0-37.4-16.8-37.4-37.4V80z M432,421.7c0,5.7-4.6,10.3-10.3,10.3
        H233.2v-10.3c0-18.7,15.1-33.9,33.9-33.9h11.1c5.7,0,10.3-4.6,10.3-10.3v-11c0-24.8,20.1-44.9,44.9-44.9c5.7,0,10.3-4.6,10.3-10.3
        c0-5.7-4.6-10.3-10.3-10.3c-36.1,0-65.5,29.4-65.5,65.5v0.7H267c-30.1,0-54.5,24.3-54.5,54.5V432h-33.9c-5.7,0-10.3-4.6-10.3-10.3
        v-9.5c0-9.8,2.3-19.7,6.7-28.5l4.3-8.7v-59.7c-14.3-15.8-22.1-36-22.1-57.4v-24c0-6.5-5.3-11.8-11.8-11.8H129c-27,0-49-22-49-49
        v-16.6c0-5.7,4.6-10.3,10.3-10.3h22.1c12.6,0,22.8-10.2,22.8-22.8c0-16.8,9.6-31.4,23.6-38.6v66.7c0,32,26,58,58,58
        c20.8,0,39-11,49.3-27.4c11.5,22.8,30.6,41.4,55,52l28.4,12.3c50.2,21.8,82.6,71.3,82.6,125.9V421.7z"
        fill={color}
      />
    </svg>
  )
}

export default DogIcon
