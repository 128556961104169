import React, { useEffect, useState, useContext } from 'react';
import { Context } from '@root/Context';
import { Container, Grid } from '@material-ui/core';

import { StreamChat } from 'stream-chat';
import 'stream-chat-react/dist/css/index.css';

import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
import * as VARIABLES from 'constants/variables';
import './SingleChat.scss';

import useNotificationValue from 'hooks/useNotificationValue';
import Notification from 'components/notification';
import StreamMessage from 'components/StreamMessage';

const apiKey = VARIABLES.GET_STREAM_KEY;

function BookingMessage(props) {
  const id = props.match.params.id; // booking id
  const { cookies, userName, userImage } = useContext(Context);
  const cUser = cookies.id;

  const mUserObj = {
    id: `ms-${cUser}`,
    name: userName,
    image: `${VARIABLES.IMAGE_URL}${userImage}`,
  };

  const [chatClient] = useState(new StreamChat(apiKey));
  const [channel, setChannel] = useState();
  const [loading, setLoading] = useState(true);

  const notification = useNotificationValue();

  useEffect(() => {
    window.scrollTo(0, 0);
    createChannel(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createChannel = async (id) => {
    if (id) {
      // get stream token to login
      const resToken = await fetch(
        SERVICES.GET_MESSENGER_TOKEN,
        OPTIONS.POST({ me: cUser, target: id }),
      );
      const dataToken = await resToken.json();
      await chatClient.connectUser(mUserObj, dataToken.token);
      // create grouped channel
      const members = [mUserObj.id, `ms-${id}`];
      const newChannel = chatClient.channel('messaging', {
        members: members,
      });
      await newChannel.create();
      setChannel(newChannel);
      // listen for new message
      // newChannel.on('message.new', event => {
      //   if (event.user.id == mUserObj.id) {
      //     const msgText = event.message.text;
      //     newChannel.queryMembers({user_id:`m${id}`}).then(res => {
      //       if (!res.members[0].user.online) {
      //         fetch(SERVICES.NOTIFY_NEW_MESSAGE, OPTIONS.POST({
      //           booking_id: data.booking.id,
      //           message: msgText
      //         })).then(res => res.json()).then(res => console.log(res))
      //       }
      //     })
      //   }
      // })
    }
    setLoading(false);
  };

  return (
    <div className="message-window-single">
      <Container maxWidth="lg" className="container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {!loading && <StreamMessage chatClient={chatClient} channel={channel} />}
          </Grid>
        </Grid>
      </Container>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default BookingMessage;
