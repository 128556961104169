import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './adminServices.scss';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as ROUTES from 'constants/routes';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
import useSearchServicios from 'hooks/useSearchServicios';
import useFilterServices from 'hooks/useFilterServices';
// Components
import Notification from 'components/notification';
import SearchInput from 'components/SearchInput';
import { FilterSelection, TableService, Workbook } from './components';
// DogHouser Icons
import AddIcon from 'icons/AddIcon';

function AdminService() {
  // Variables propias del componete
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // Variables del componente "Notification"
  const notification = useNotificationValue();

  // Variables de la data de "Servicios"
  const [services, setServices] = useState([]);
  const { values, handleValuesChange, filteredServices } = useFilterServices(services);
  const { query, setQuery, searchServices } = useSearchServicios(filteredServices);

  // Obtener la data de los Servicios de DogHouser
  const fetchServices = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(SERVICES.GET_ALL_SERVICES, OPTIONS.GET());
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setServices(data.services);
        setLoading(false);
      } else {
        notification.handleSnackbar('Error de conexión con el servidor', 'error');
        setError('No se pudo obtener los datos');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <div className="dh-admin-services">
        <div className="dh-admin-services__section-header">
          <h2 className="admin-title">Servicios</h2>
        </div>
        <div className="margin-bottom-x1">
          <h4 className="admin-title">Error: {error}</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="dh-admin-services">
      <div className="dh-admin-services__section-header">
        <h2 className="admin-title">Servicios</h2>
        <RouterLink to={ROUTES.ADMIN_SERVICES_NEW}>
          <button className="btn-primary icon">
            <AddIcon color="#fff" />
            Crear servicio
          </button>
        </RouterLink>
      </div>
      <Workbook data={searchServices} />
      <div className="dh-admin-services__filter-services">
        <FilterSelection values={values} handleValuesChange={handleValuesChange} />
        <SearchInput
          text="Busca un Servicio"
          query={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </div>
      <div className="dh-admin-services__table-services">
        <p className="dh-admin-services__table-services--count-services">
          {searchServices.length} Servicios encontrados
        </p>
        <TableService services={searchServices} loading={loading} />
      </div>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default AdminService;
