import React from 'react'
import PropTypes from 'prop-types'
import './houserError.scss'
// DogHouser Images
import ErrorCat from 'assets/images/error-cat@2x.png'
// Material-UI
import Container from '@material-ui/core/Container';
// Material-Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const HouserError = props => {
  const { goBack } = props
  return (
    <div className="houser-detail">
      <Container maxWidth="lg">
        <div className="houser-detail__container-error">
          <div className="houser-detail__container-error--title">
            <h1 className="dh-title-section">Meow, no se pudo encontrar al cuidador</h1>
          </div>
          <div className="houser-detail__container-error--image">
            <img src={ErrorCat} alt="Error cat"/>
          </div>
          <button className="btn-accent" onClick={goBack}>
            <ArrowBackIcon/>
            Regresar
          </button>
        </div>
        <a className="dh-no-decoratio" href="http://www.freepik.com" target="_blank" rel="noopener noreferrer">
          <p style={{textAlign: 'right'}}>Designed by Freepik</p>
        </a>
      </Container>
    </div>
  )
};

HouserError.propTypes = {
  goBack: PropTypes.func
};

export default HouserError;
