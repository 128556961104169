import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function JardinIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 548.4 548.4"
      style={{enableBackground: 'new 0 0 548.4 548.4'}}
      xmlSpace="preserve">
      <g>
        <path
          d="M261.8,358.9c0,0,0.4-0.8,1.1-1.9l-1.4,1.4C261.7,358.7,261.8,358.9,261.8,358.9z"
          fill={color}/>
        <path
          d="M274.1,10.9C128.3,10.9,10,129.2,10,275s118.2,264.1,264.1,264.1S538.2,420.8,538.2,275S419.9,10.9,274.1,10.9
          z M403.8,233.4c-33.4,13.9-56,5.9-68.9-4.4c-0.3,0.3-0.6,0.7-1,0.9c-41.7,24.3-56,73.5-65.3,120.6c4.7-4,12.2-8,23.1-8
          c22.2,0,26,10,28.9,23.3c37-17.1,39.5,25.5,39.4,26.4c1.8-0.4,3.6-0.7,5.5-0.7c13.8,0,25.1,11.2,25.1,25.1
          c0,13.2-22.5,25.4-22.5,25.4l-211.4-0.4c-13.4-0.6-24-11.5-24-25c0-13.7,17.3-29.4,31-24.3c1.7-19.1,20.7-41.9,49.5-32.1
          c4.6-10.5,14.1-17.8,27.1-17.8c4.6,0,8.6,2.1,11.9,4.8c0-45.3-5-92.6-37.3-123.6c-15.9,4.4-39.4,2.8-64.6-22.4
          c-37.7-37.7-33.4-60.6-55.8-94.9c22.2,10.7,75.3-2.3,113.4,26.6c35,26.5,34.7,65.9,26.5,79.1c-0.5-0.7-1.1-1.4-1.6-2.2
          c-2.6-3.5-5.5-7.1-8.5-10.8c-3-3.7-6.4-7.3-9.8-11c-1.7-1.8-3.5-3.6-5.4-5.4l-2.8-2.7c-1.2-1.2-2-1.7-3-2.6l-5.7-4.9
          c-2-1.6-4-3.2-6-4.8c-4-3.1-8-6.1-11.9-8.8c-7.8-5.5-15.3-10.3-21.8-14.2c-13-7.9-22.3-12.2-22.3-12.2s1.8,1.8,4.9,5
          c3.1,3.2,7.7,7.5,13.3,12.7c2.8,2.6,5.8,5.3,9,8.3c3.2,2.9,6.6,5.9,10.1,9.1c3.5,3.1,7,6.3,10.6,9.6c3.6,3.1,7.2,6.6,10.9,10.1
          c3.5,3.2,6.8,7,10.3,10.3c3.4,3.6,6.8,6.9,10.1,10.3c4,4,7.8,7.8,11.6,11.2c0,0-0.1,0-0.1,0c27.4,27.2,35.4,64.1,37.2,102.1
          c10-40.5,26.4-78.7,63.4-100.3c3.3-1.8,6.6-2.9,11.8-5.1c4.3-1.8,11.9-4.5,16.4-6.5c4.5-1.8,9-4,13.4-5.6c4.7-1.8,9.4-3.6,13.9-5.1
          c4.6-1.6,9.1-3.3,13.5-4.8c4.4-1.6,8.7-3.1,12.8-4.5c4.1-1.5,7.9-2.9,11.5-4.2c7.1-2.7,13-5,17.1-6.7c4.1-1.8,6.5-2.8,6.5-2.8
          s-10.3,0.4-25.3,2.7c-7.5,1.2-16.3,2.7-25.5,4.8c-4.7,1-9.4,2.2-14.4,3.5c-2.4,0.7-4.9,1.4-7.4,2.1L364,191
          c-1.3,0.4-2.2,0.7-3.8,1.3l-3.6,1.5c-2.4,1-4.8,1.9-7,3c-4.5,2.1-9,4.2-13.2,6.4c-4.2,2.2-8.3,4.4-12,6.7c-0.8,0.5-1.5,0.9-2.3,1.4
          c-2.5-15.3,12.3-51.8,54.7-62.9c46.2-12.1,90.3,20.1,114.9,18.7C457.8,190.2,453.1,213,403.8,233.4z"
          fill={color}/>
      </g>
    </svg>
  )
}

export default JardinIcon
