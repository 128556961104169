import { useContext, useEffect, useState } from 'react';

import { Context } from '@root/Context';
import { useSnackbar } from '@root/hooks';
import * as OPTIONS from '@root/services/options';
import { USER_BY_ID, UPDATE_BASIC_USER } from '@root/services/config';
import { STATE_INITIATE, STATE_UPDATE } from '@root/constants/variables';
import SectionTitle from '@root/components/sectionTitle/SectionTitle';
import CardImageSetting from '@root/components/cardImageSetting/CardImageSetting';
import CardUserBasicInfo from '@root/components/cardUserBasicInfo/CardUserBasicInfo';
import CardUserEmergencyInfo from '@root/components/cardUserEmergencyInfo/CardUserEmergencyInfo';
import GridBankAccount from '@root/components/gridBankAccount/GridBankAccount';
import { DogLayout, DogPageHeader, DogTab, DogTabPanel, DogTabs } from '@root/ui-component';
import PetsSection from './Pets';
import styles from './accountSettings.module.scss';

const a11yProps = (index) => {
  return {
    id: `scrollable-doghouser-tabpanel-${index}`,
    'aria-controls': `scrollable-doghouser-tabpanel-${index}`,
  };
};

// Variante de la rutas que puede tomar el Usuairo para entrar a esta vista
const variantRoute = {
  '/perfil': 0,
  '/cuenta/perfil': 0,
  '/mascotas': 1,
  '/cuenta/mascotas': 1,
  '/cuenta-pago': 2,
  '/cuenta/cuenta-pago': 2,
};

function AccountSettings(props) {
  const snackbar = useSnackbar();
  const { cookies, houser } = useContext(Context);
  // Estado inicial del 'Tab'
  const [value, setValue] = useState(variantRoute[props.location.pathname]);
  // Estado del boton "Guardar Cambios"
  const [btnState, setBtnState] = useState('disabled');

  const [data, setData] = useState({
    status: '',
    message: '',
    user: {
      id: '',
      name: '',
      last_name: '',
      doc_id: '',
      email: '',
      phone: '',
      address: '',
      birth_date: '',
      phone_house: '',
      photo: '',
      emergency_contact_name: '',
      emergency_contact_phone: '',
    },
    houser: {},
    location: {
      country: '',
      city: '',
    },
  });

  // Funicion para manejar el cambio de estado del "Tabs"
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Manejar el cambio de los Inputs - Para datos del Usuario
  const handleUserInputChange = (e) => {
    setData({
      ...data,
      user: {
        ...data.user,
        [e.target.name]: e.target.value,
      },
      houser: {
        ...data.houser,
      },
      location: {
        ...data.location,
      },
    });
    setBtnState('');
  };

  useEffect(() => {
    if (!data.user.id) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.id, data.user.id]);

  const convertDateToValidateFormat = function (date) {
    const mydate = new Date(date);
    const year = mydate.getFullYear();
    const month = mydate.getMonth();
    const day = mydate.getDate();
    const customDate = month + 1 + '-' + (day + 1) + '-' + year;
    return customDate;
  };

  // Funcion que detecta el efecto onClick del boton actualizar datos
  const handleSubmitUpdateUser = (e) => {
    e.preventDefault();
    let birthDate = convertDateToValidateFormat(data.user.birth_date);
    fetchUpdateUser(birthDate);
  };

  // Se obtiene el ID del Usuario, para poder pedir su informacion al API
  const fetchUser = async () => {
    let errorMessage = 'Error al obtener los datos';
    const body = {
      id: cookies.id,
    };
    sendUserData(USER_BY_ID, OPTIONS.POST(body), STATE_INITIATE, errorMessage);
  };

  // Se obtiene los datos del usuario y se prepara para enviarlos al API
  const fetchUpdateUser = async (customDate) => {
    let errorMessage = 'Error al actualizar los datos';
    const body = {
      id: cookies.id,
      name: data.user.name,
      last_name: data.user.last_name,
      doc_id: data.user.doc_id,
      email: data.user.email,
      phone: data.user.phone,
      address: data.user.address,
      country: data.location.country,
      city: data.location.city,
      birth_date: customDate,
      phone_house: data.user.phone_house,
      emergency_contact_name: data.user.emergency_contact_name,
      emergency_contact_phone: data.user.emergency_contact_phone,
    };
    sendUserData(UPDATE_BASIC_USER, OPTIONS.POST(body), STATE_UPDATE, errorMessage);
  };

  // Se envia una peticion al API con los datos del Usuario
  const sendUserData = async (url, options, state, errorMessage) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        if (state === STATE_INITIATE) {
          setData(data);
        } else if (state === STATE_UPDATE) {
          snackbar.success('Datos actualizados');
        }
      } else {
        snackbar.error(errorMessage);
      }
    } catch (error) {
      snackbar.error('Error: ' + error.message);
    }
  };

  return (
    <DogLayout containerMaxWidth="md">
      <DogPageHeader title="Configuración" />
      <div>
        <DogTabs value={value} onChange={handleChange} variant="scrollable" aria-label="tab">
          <DogTab label="Perfil" {...a11yProps(0)} />
          <DogTab label="Mascotas" {...a11yProps(1)} />
          {houser && <DogTab label="Cuenta de pago" {...a11yProps(2)} />}
        </DogTabs>
        <DogTabPanel value={value} index={0} className={styles.container}>
          <SectionTitle
            title="Imagen de perfil"
            text="Para que los huéspedes y anfitrones se conozcan, lo mejor es añadir fotos de la cara que sean nítidas y esten sacadas de frente. !Hospedar un paisaje no tiene mucha gracia¡ Asegúrate de utilizar una foto en la que se te vea bien la cara y que no incluya información personal o sensible que preferirías que los huéspedes o anfitriones no vieran."
          />
          <CardImageSetting id={cookies.id} />
          <SectionTitle
            title="Información básica"
            text="Cambiar los datos de identificación de tu cuenta"
          />
          <CardUserBasicInfo
            data={data}
            handleSubmit={handleSubmitUpdateUser}
            handleInputChange={handleUserInputChange}
            btnState={btnState}
          />
          <SectionTitle
            title="Contacto de Emergencia"
            text="Cambiar los datos de tu contacto de emergencia"
          />
          <CardUserEmergencyInfo
            data={data}
            handleSubmit={handleSubmitUpdateUser}
            handleInputChange={handleUserInputChange}
            btnState={btnState}
          />
        </DogTabPanel>
        <DogTabPanel value={value} index={1} className={styles.container}>
          <PetsSection userId={cookies.id} />
        </DogTabPanel>
        {houser && (
          <DogTabPanel value={value} index={2} className={styles.container}>
            <SectionTitle
              title="Cuenta de pago"
              text="Los pagos se realizaran máximo a la semana de finalizado el servicio."
            />
            <GridBankAccount userId={cookies.id} houserId={cookies.houserId} />
          </DogTabPanel>
        )}
      </div>
    </DogLayout>
  );
}

export default AccountSettings;
