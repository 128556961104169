import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Fade,
  Slide,
  Grow,
  IconButton,
  Snackbar as MuiSnackbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { RootState } from '@root/store/reducer';
import SnackbarContentWrapper from './SnackbarContentWrapper';

// animation function
function TransitionSlideLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

function FadTransition(props) {
  return <Fade {...props} />;
}

// animation options
const transition = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade: FadTransition,
};

const Snackbar = () => {
  const snackbarInitial = useSelector((state: RootState) => state.snackbar);
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(snackbarInitial.open);
  }, [snackbarInitial.action, snackbarInitial.open]);

  return (
    <>
      {/* default snackbar */}
      {snackbarInitial.variant === 'default' && (
        <MuiSnackbar
          anchorOrigin={snackbarInitial.anchorOrigin}
          open={open}
          onClose={handleClose}
          message={snackbarInitial.message}
          autoHideDuration={snackbarInitial.duration}
          TransitionComponent={transition[snackbarInitial.transition]}
          action={
            <>
              <Button color="secondary" size="small" onClick={() => setOpen(false)}>
                UNDO
              </Button>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setOpen(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      )}
      {/* alert snackbar */}
      {snackbarInitial.variant === 'alert' && (
        <MuiSnackbar
          anchorOrigin={snackbarInitial.anchorOrigin}
          open={open}
          onClose={handleClose}
          autoHideDuration={snackbarInitial.duration}
          TransitionComponent={transition[snackbarInitial.transition]}>
          <SnackbarContentWrapper
            onClose={() => setOpen(false)}
            variant={snackbarInitial.severity}
            message={snackbarInitial.message}
            icon={snackbarInitial.icon}
          />
        </MuiSnackbar>
      )}
    </>
  );
};

export default Snackbar;
