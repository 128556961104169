import { useState, useEffect, useContext } from 'react';
import { Divider, Grid } from '@material-ui/core';
import Swal from 'sweetalert2';

import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
import { Context } from '@root/Context';
import { useSnackbar } from '@root/hooks';
import { convertBase64ToFile, resizeImage } from '@root/utils';
import { DogLayout, DogPageHeader, DogSectionTitle } from '@root/ui-component';
import { DialogUploadImage } from '@root/ui-component/dialog';
import { AddNewCard } from '@root/ui-component/cards';
import GalleryGrid from './GalleryGrid';

type ImageReaderType = {
  file: string;
  imagePreviewUrl: string | ArrayBuffer;
};

function CuidadorFotos(props) {
  const { cookies } = useContext(Context);
  // Se detecta si se pasa un ID por la URL
  const userId = props.match.params.id ? props.match.params.id : cookies.id;
  const snackbar = useSnackbar();
  const [loadingImg, setLoadingImg] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [houserId, setHouserId] = useState('');
  const [gallery, setGallery] = useState([]);
  const [image, setImage] = useState<ImageReaderType>({ file: '', imagePreviewUrl: '' });

  const handleClose = () => {
    setImage({ file: '', imagePreviewUrl: '' });
    setOpen(false);
  };

  const handleOk = () => {
    handleUploadImage();
    setOpen(false);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImage({ file: file, imagePreviewUrl: reader.result });
    };
    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
      setOpen(true);
    }
  };

  const fetchCuidador = async () => {
    setLoading(true);
    try {
      const response = await fetch(SERVICES.CUIDADOR_BY_ID, OPTIONS.POST({ id: userId }));
      const data = await response.json();
      if (response.status === 200) {
        setGallery(data?.user?.houser?.galleries);
        setHouserId(data?.user?.houser?.id);
      } else {
        snackbar.warning(data.message);
      }
      setLoading(false);
    } catch (error) {
      snackbar.error(error.message);
      setLoading(false);
    }
  };

  const handleUploadImage = async () => {
    setLoadingImg(true);
    snackbar.info('Subiendo imagen ...');
    try {
      const imageFile = await resizeImage(image.file, 'base64', 580);
      const imageBase64 = convertBase64ToFile(imageFile);
      const formData = new FormData();
      formData.append('id', userId);
      formData.append('photo', imageBase64);
      fetchUploadImage(SERVICES.UPLOAD_PHOTO_PUBLICATION, OPTIONS.POST_FORM_DATA(formData));
    } catch (error) {
      snackbar.error('Error: ' + error.message);
      setLoadingImg(false);
    }
  };

  const fetchUploadImage = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.status === 200) {
        const galleryItem = data.gallery;
        const newGallery = [...gallery, galleryItem];
        setGallery(newGallery);
        setImage({ file: '', imagePreviewUrl: '' });
        snackbar.success(data.message);
      } else {
        snackbar.warning('Error al subir la Imagen');
      }
      setLoadingImg(false);
    } catch (error) {
      snackbar.error('Error: ' + error);
      setLoadingImg(false);
    }
  };

  const handleDeleteImage = (photoId: number) => {
    Swal.fire({
      title: 'Estas seguro?',
      text: 'No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí, eliminarlo',
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteImage(photoId);
      }
    });
  };

  const fetchDeleteImage = async (photoId: number) => {
    let body = { houserid: houserId, photoid: photoId };
    try {
      const response = await fetch(SERVICES.DELETE_PHOTO_GALLERY, OPTIONS.POST(body));
      const data = await response.json();
      if (response.status === 200) {
        const deletedSet = new Set([photoId]);
        const newGallery = gallery.filter(({ id }) => !deletedSet.has(id));
        setGallery(newGallery);
        snackbar.success(data.message);
        return;
      }
      snackbar.warning(data.message);
    } catch (error) {
      snackbar.error('Error: ' + error.message);
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0)
    fetchCuidador();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <DogLayout>
      <DogPageHeader subTitle="Volver al anuncio" onBack={() => props.history.goBack()} />
      <DogSectionTitle
        disablePadding
        titleComponent="h1"
        title="Fotos"
        text="Añade fotos para que los clientes vean cómo es el lugar donde se quedará su mascota.
          Recomendamos como mínimo 7 fotos para obtener los mejores resultados. No se aceptarán
          fotos con derechos de autor."
      />
      <Divider style={{ margin: '1rem 0' }} />
      <Grid container spacing={2} direction="row" alignItems="center" className="gallery">
        <GalleryGrid loading={loading} gallery={gallery} fetchDeleteImage={handleDeleteImage} />
        {!loading && (
          <Grid item xs={12} sm={4} md={3}>
            <label htmlFor="upload-photo">
              <AddNewCard height="228px" />
            </label>
            <input
              className="hide"
              type="file"
              id="upload-photo"
              onChange={handleImageChange}
            />
          </Grid>
        )}
      </Grid>
      <DialogUploadImage
        loading={loadingImg}
        imageUrl={String(image.imagePreviewUrl)}
        open={open}
        onClose={handleClose}
        onOk={handleOk}
        height={420}
        width={420}
      />
    </DogLayout>
  );
}

export default CuidadorFotos;
