import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function HomeIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      width="44.776px"
      height="44.776px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 495.4 495.4">
      <circle cx="247.7" cy="247.7" r="247.7" fill={color}/>
      <path 
        d="M425.66,224.12,368.43,166.9V100.8a21.64,21.64,0,1,0-43.28,0v22.82L282.54,81c-21.07-21.06-57.69-21-78.71,0L60.75,224.12a21.65,21.65,0,1,0,30.62,30.61L234.43,111.65a12.78,12.78,0,0,1,17.52,0L395,254.73a21.65,21.65,0,1,0,30.62-30.61Z" 
        fill="#fff"/>
      <path 
        d="M250.72,152.73a10.61,10.61,0,0,0-15,0L109.83,278.54a10.69,10.69,0,0,0-3.11,7.53v91.77a39,39,0,0,0,39,39H208v-96.5h70.35v96.5h62.31a39,39,0,0,0,39-39V286.07a10.61,10.61,0,0,0-3.11-7.53Z" 
        fill="#fff"/>
    </svg>
  )
}

export default HomeIcon
