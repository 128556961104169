import { useState } from 'react';

export const useInputValue = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const onChange = (e) => setValue(e.target.value);
  const update = (v) => setValue(v);

  return { value, onChange, update };
};

export default useInputValue;
