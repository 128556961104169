import React from 'react';
import './copyright.scss';
// Material-UI
import { Link as RouterLink } from 'react-router-dom';

function Copyright() {
  return (
    <div className="copyright-content">
      <p className="copyright-text">
        {'Copyright © '}
        <RouterLink className="copyright-main" to="/">
          DogHouser
        </RouterLink>{' '}
        {new Date().getFullYear()}
        {'.'}
      </p>
    </div>
  );
}

export default Copyright;
