import { ChangeEvent, useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './services.module.scss';

const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    flexDirection: 'row-reverse',
    padding: '0',
    marginBottom: -1,
    minHeight: 42,
    '&$expanded': {
      minHeight: 42,
    },
  },
  content: {
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    flexDirection: 'column',
    padding: '8px 16px',
    backgroundColor: 'rgba(0, 0, 0, .03)',
  },
}))(MuiAccordionDetails);

const SubServices = (props) => {
  const { serviceOptions, optionKey, serviceActive, handleServiceActive } = props;
  const [expanded, setExpanded] = useState<string | false>(false);

  const options1 = serviceOptions[optionKey].services.filter(({ id }) => id === 20);
  const options2 = serviceOptions[optionKey].services.filter(({ id }) => id !== 20);

  const handleChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={styles.subServices}>
      <RadioGroup
        aria-label="service-option-type"
        name="service-option-type"
        value={serviceActive}
        onChange={handleServiceActive}>
        {options1.map((item) => (
          <FormControlLabel
            value={item.id}
            control={<Radio />}
            label={item.name}
            key={`service-option-type-${item.id}`}
          />
        ))}
        <Accordion
          square
          expanded={expanded === 'panelser'}
          onChange={handleChange('panelser')}>
          <AccordionSummary
            aria-controls="panelser-content"
            id="panelser-header"
            expandIcon={<ExpandMoreIcon />}
            IconButtonProps={{
              style: {
                marginRight: 0,
                marginLeft: -16,
              },
            }}>
            <h6>Guardería recurrente</h6>
          </AccordionSummary>
          <AccordionDetails>
            {options2.map((item) => (
              <FormControlLabel
                value={item.id}
                control={<Radio />}
                label={item.name}
                key={`service-option-type-${item.id}`}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </RadioGroup>
    </div>
  );
};

export default SubServices;
