// Rutas Generales
export const HOME = '/';
export const CONSTRUCTION = '/coming-soon';
export const TERMINOS = '/terminos';
export const FREQUENT_QUESTIONS = '/preguntas-frecuentes';
export const EMERGENCY_INSURANCE = '/seguro-de-emergencia';
export const LIBRO_RECLAMACIONES = '/libro-de-reclamaciones';
export const PRODUCTOS = '/tienda';

//Rutas Blog
export const BLOG_WORDPRESS = 'http://blog.doghouser.com/';
export const BLOG = '/blog';
export const BLOG_POST_ID = '/blog/:id';
export const WHATSAPP = 'https://wa.me/51995759034';

// Rutas Servicios
export const LISTA_SERVICIOS = '/servicios';
export const LISTA_SERVICIOS_PASEOS = '/servicios/paseos';
export const LISTA_SERVICIOS_DELIVERY = '/servicios/delivery';
export const SERVICIOS_CUIDADORES = '/servicios/cuidadores';
export const LISTA_CUIDADORES = '/buscar';
export const CUIDADOR_DETAIL = '/cuidador/:id';
export const ONBOARDING = '/onboarding';

// Route for Booking
export const BOOKING_REQUEST = '/cuidador/:id/request';
export const BOOKING_MODIFY = '/cuidador/request/:rid/modify';
export const BOOKING_MESSAGE = '/cuidador/:id/message';
export const BOOKING_CHECKOUT = '/cuidador/:id/checkout';
export const BOOKING_CHECKOUT_CONFIRM = '/cuidador/:id/checkout-confirm';

export const MESSAGE_SINGLE = '/messages/:id';

// Rutas PetFriendly
export const SERVICIOS_PETFRIENDLY = '/servicios/petfriendly';
export const PETFRIENDLY_DETAIL = '/servicios/petfriendly/:id/:country/:city';

// Rutas Inicio de Sesión
export const LOGIN = '/login';
export const PASSWORD_FORGET = '/lostpassword';
export const NEW_PASSWORD = '/lostpassword/done';
export const VALIDATE_PHONE = '/validate-phone';

// Rutas Registro
export const REGISTER_CLIENTE = '/join/cliente';
export const REGISTER_CLIENTE_CORREO = '/join/cliente/correo';
export const REGISTER_CUIDADOR = '/join/cuidador';
export const REGISTER_CUIDADOR_CORREO = '/join/cuidador/correo';
export const REGISTER_CUIDADOR_STEP = '/cuidador-pasos';

// Rutas Cliente
export const CLIENTE_PERFIL = '/perfil';
export const CLIENTE_MASCOTAS = '/mascotas';
export const CLIENTE_RESERVATIONS = '/reserva';
export const CLIENTE_RESERVATIONS_DETAIL = '/reserva/:id';

// Rutas Mascota
export const CREAR_MASCOTA = '/nueva-mascota';
export const EDIT_MASCOTA = '/edit-mascota/:id';
export const MASCOTA_DETAIL = '/mascota/:id';

// Rutas Cuidador
export const CUIDADOR_DASHBOARD = '/cuenta';
export const CUIDADOR_PERFIL = '/cuenta/perfil';
export const CUIDADOR_MASCOTAS = '/cuenta/mascotas';
export const CUIDADOR_RESERVAS = '/cuenta/reservas';
export const CUIDADOR_RESERVA_DETALLE = '/cuenta/reservas/:id';
export const CUIDADOR_CALENDARIO = '/cuenta/calendario';
export const CUIDADOR_PUBLICACION = '/cuenta/publicacion';
export const CUIDADOR_PUBLICACION_FOTOS = '/cuenta/publicacion/fotos';
export const CUIDADOR_PUBLICACION_SERVICIOS = '/cuenta/publicacion/servicios';
export const CUIDADOR_PUBLICACION_INFO = '/cuenta/publicacion/info';
export const CUIDADOR_PUBLICACION_UBICACION = '/cuenta/publicacion/ubicacion';
export const CUIDADOR_NOTIFICACION = '/cuenta/notificaciones';
export const CUIDADOR_PROGRESO = '/cuenta/progreso';
export const CUIDADOR_AYUDA = '/cuenta/ayuda';

// Rutas Admin
export const ADMIN_LOGIN = '/dh-admin/login';
export const ADMIN_DASHBOAR = '/dh-admin';
export const ADMIN_CLIENTES = '/dh-admin/clientes';
export const ADMIN_CLIENTES_ID = '/dh-admin/clientes/:id';
export const ADMIN_CLIENTES_ID_BASIC_INFO = '/dh-admin/cuidadores/:id/basic-info';
export const ADMIN_CUIDADORES = '/dh-admin/cuidadores';
export const ADMIN_CUIDADORES_ID = '/dh-admin/cuidadores/:id';
export const ADMIN_CUIDADORES_ID_BASIC_INFO = '/dh-admin/cuidadores/:id/basic-info';
export const ADMIN_CUIDADORES_ID_UBICACION = '/dh-admin/cuidadores/:id/ubicacion';
export const ADMIN_CUIDADORES_ID_SERVICIOS = '/dh-admin/cuidadores/:id/servicios';
export const ADMIN_CUIDADORES_ID_PUBLICACION = '/dh-admin/cuidadores/:id/publicacion';
export const ADMIN_CUIDADORES_ID_FOTOS = '/dh-admin/cuidadores/:id/fotos';
export const ADMIN_PLACES = '/dh-admin/lugares';
export const ADMIN_PLACES_CREATE = '/dh-admin/lugares/nuevo';
export const ADMIN_PLACES_EDIT = '/dh-admin/lugares/editar/:id/:country/:city';
export const ADMIN_RESERVATIONS = '/dh-admin/reservas';
export const ADMIN_RESERVATION_DETAIL = '/dh-admin/reserva/:id';
export const ADMIN_SERVICES = '/dh-admin/servicios';
export const ADMIN_SERVICE_DETAIL = '/dh-admin/servicio/:id';
export const ADMIN_SERVICES_NEW = '/dh-admin/servicios/nuevo';
export const ADMIN_PROVIDERS = '/dh-admin/proveedores';
export const ADMIN_PROMOTIONS = '/dh-admin/promotions';
export const ADMIN_PROMOTIONS_NEW = '/dh-admin/promotions/nuevo';
