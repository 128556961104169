import React from "react";
import { Route, Redirect } from "react-router-dom";

import Context from "@root/Context";
import LayoutClient from "layout/layoutClient";
import * as ROUTES from "constants/routes";

const ClientPrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Context.Consumer>
      {({ isAuth, client, houser }) => {
        if ((isAuth && client) || (isAuth && houser)) {
          return (
            <Route
              {...rest}
              render={(props) => (
                <LayoutClient>
                  <Component {...props} />
                </LayoutClient>
              )}
            />
          );
        } else {
          return (
            <Route
              {...rest}
              render={(props) => (
                <Redirect
                  to={{
                    pathname: ROUTES.LOGIN,
                    state: { from: props.location },
                  }}
                />
              )}
            />
          );
        }
      }}
    </Context.Consumer>
  );
};

export default ClientPrivateRoute;
