import ResponseType from '@root/interfaces/Response';
import requests from './requests';

interface UploadPhotoType {
  pet_id: string | Blob;
  photo_old: string | Blob;
  photo: string | Blob;
}

const findById = (pet_id: number): Promise<ResponseType> => {
  const url = `/pets/show/${pet_id}`;

  return requests.get(url);
};

const listByUser = (user_id: number): Promise<ResponseType> => {
  const url = `/pets/user/${user_id}`;

  return requests.get(url);
};

const uploadPhoto = (body: UploadPhotoType): Promise<ResponseType> => {
  const url = `/pets/upload-image`;
  const formData = new FormData();
  formData.append('pet_id', body.pet_id);
  formData.append('photo_old', body.photo_old);
  formData.append('photo', body.photo);

  return requests.postForm(url, formData);
};

const destroy = (pet_id: number): Promise<ResponseType> => {
  const url = `/pets/destroy/${pet_id}`;

  return requests.delete(url);
};

const pet = {
  findById,
  listByUser,
  uploadPhoto,
  destroy,
};

export default pet;
