import React, { useContext } from 'react';
import { Context } from '@root/Context';
// Components
import StepCuidadorBasicInfo from 'components/stepCuidadorBasicInfo';
import TitleArrowBack from '@root/components/TitleBack';
import CardImageSetting from 'components/cardImageSetting';

function AdminHouserDetailBasicInfo(props) {
  const { cookies } = useContext(Context);
  let userId;
  // Se detecta si se pasa un ID por la URL
  if (props.match.params.id) {
    userId = props.match.params.id;
  } else {
    // Si no se detecta se usa el ID guardado en las cookies
    userId = cookies.id;
  }

  // Funcion para volver a la pestaña anterior
  const handleBackClick = () => {
    props.history.goBack();
  };

  const handleNext = () => {
    // Hacer algo cuando el componente envíe los datos
  };

  return (
    <div className="dh-card-elevation">
      <TitleArrowBack handleClick={handleBackClick}>
        <h4 className="marginx05">{'Volver al cuidador'}</h4>
      </TitleArrowBack>
      <h1 className="marginx1">Información Basica</h1>
      <div className="margin-bottom-x1">
        <CardImageSetting id={userId} />
      </div>
      <StepCuidadorBasicInfo id={userId} handleNext={handleNext} />
    </div>
  );
}

export default AdminHouserDetailBasicInfo;
