import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function CatIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      version="1.1" 
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 512 512"
      style={{enableBackground: 'new 0 0 512 512'}}
      xmlSpace="preserve">
      <g>
        <path d="M275,307.1h-13.6c-39.6,0-71.6,32.1-71.6,71.6v32.9c0,0.4,0.3,0.7,0.7,0.7h19.2c0.4,0,0.7-0.3,0.7-0.7v-32.9
          c0-28.1,22.8-51,51-51H275c0.4,0,0.7-0.3,0.7-0.7v-19.2C275.8,307.4,275.4,307.1,275,307.1z" fill={color}/>
        <path d="M256,8C118.5,8,7,119.5,7,257s111.5,249,249,249s249-111.5,249-249S393.5,8,256,8z M431.9,388.4
          c0,24.1-19.6,43.6-43.6,43.6l-271.1,0c-0.4,0-0.7-0.3-0.7-0.7l0-19.2c0-0.4,0.3-0.7,0.7-0.7h33c0.4,0,0.7-0.3,0.7-0.7v-92.2
          c0-0.1,0-0.2,0-0.2l-16.1-48.2c-3-9.1-4.6-18.6-4.6-28.2v-17.9c0-0.4-0.3-0.7-0.7-0.7h-0.7c-14.2,0-20.3-6.1-27.4-13.3
          c-0.5-0.5-1-1-1.6-1.6l-15.1-15.1c-2.9-2.9-3.8-7.3-2.3-11.2v0c0.2-0.4,4.4-10.8,10-22.1c10-20.1,16.3-27,20.3-30.1
          c0,0,0.1,0,0.1-0.1l8-8.6c0.1-0.1,0.2-0.4,0.2-0.6L118.7,89c0-0.1,0-0.1,0-0.2c-0.7-2.2,0.5-4.5,2.6-5.3l4.1-1.6
          c0.2-0.1,0.3-0.1,0.6-0.2l0.4-0.2l0,0c0.8-0.3,1.8-0.6,2.9-0.7c-0.4,0-0.7,0.1-1.1,0.2l0.1,0c16.8-3.5,39.2,1.6,47,27.6
          c0.1,0.4,0.3,0.9,0.4,1.3c0.1,0.3,0.3,0.4,0.5,0.5c13.2,2.8,25.4,10,34.8,20.7c11.2,12.7,17.4,28.8,17.4,45.5v19
          c0,0.2,0.1,0.4,0.2,0.5l58,62.4c31,33.4,48.1,76.8,48.1,122.4v29.8c0,0.4,0.3,0.7,0.7,0.7h52.1c13.1,0,23.7-10.6,23.7-23.7v-2
          c0-12.5-10-22.6-22.3-23c-24.5-0.7-44.3-19.9-44.3-44.4V317c0-24.5,19.9-44.4,44.4-44.4H419c0.4,0,0.7,0.3,0.7,0.7v19.2
          c0,0.4-0.3,0.7-0.7,0.7H389c-13.1,0-23.7,10.6-23.7,23.7v1.3c0,13,10.5,23.6,23.5,23.8c23.8,0.3,43.2,19.7,43.2,43.6V388.4z" fill={color}/>
      </g>
    </svg>
  )
}

export default CatIcon
