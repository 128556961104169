import React, { useContext, useState, useEffect } from 'react';
import { Context } from '@root/Context';
import { Redirect } from 'react-router-dom';
import './registerSteps.scss';
// Services
import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
// Constants
import * as ROUTES from '@root/constants/routes';
import * as VARIABLES from '@root/constants/variables';
// Dependencies
import Resizer from 'react-image-file-resizer';
// Components
import SimpleAppBar from 'components/simpleAppBar/SimpleAppBar';
import Notification from 'components/notification';
import StepCuidadorBasicInfo from 'components/stepCuidadorBasicInfo';
import StepCuidadorPicture from 'components/stepCuidadorPicture';
import StepCuidadorPlaceDetail from 'components/stepCuidadorPlaceDetail';
import StepCuidadorServicios from 'components/stepCuidadorServicios';
import StepCuidadorUbicacion from 'components/stepCuidadorUbicacion';
import StepCuidadorTest from 'components/stepCuidadorTest';
// Utils
import { TermsConditions } from 'utils/data.json.js';
import convertBase64ToFile from 'utils/convertBase64ToFile';
import getFileName from 'utils/getFileName';
// Hooks
import { useInputValue } from 'hooks/useInputValue';
import useNotificationValue from 'hooks/useNotificationValue';
// Images - DogHouser
import ImagePlaceholder from 'assets/images/ico/gallery.svg';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import MobileStepper from '@material-ui/core/MobileStepper';
// Material-Images
import ImageIcon from '@material-ui/icons/Image';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  menu: {
    width: 200,
  },
}));

// Se declaran las vistas que se van a utilizar
function getSteps() {
  return [
    'Información Personal',
    'Foto de Perfil',
    'Dónde vives',
    'Detalles del lugar',
    'Preferencias de servicio',
    'Crea tu publicación',
    'Evaluación',
    'Terminos y condiciones',
  ];
}

function RegisterSteps(props) {
  const { isAuth, cookies, admin, houser, statusHouser, activateAuth, setLocation, setRole } =
    useContext(Context);

  const classes = useStyles();
  // Variables de "Stepper"
  const [activeStep, setActiveStep] = useState(5);
  const steps = getSteps();
  // Variables de "Notification"
  const notification = useNotificationValue();

  // Funcion para avanzar al siguiente paso
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Funcion para regresar al siguente paso
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Funcion para motrar mensaje de "Salir de Pestaña"
  window.addEventListener('beforeunload', function (e) {
    // eslint-disable-next-line no-useless-escape
    var confirmationMessage = 'o/';
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Webkit, Safari, Chrome
  });

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <div className="register-steps__title-content">
              <h2 className="center">Información Personal</h2>
            </div>
            <StepCuidadorBasicInfo
              id={cookies.id}
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={step}
            />
          </React.Fragment>
        );
      case 1:
        return (
          <StepCuidadorPicture
            id={cookies.id}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <React.Fragment>
            <div className="register-steps__title-content">
              <h2 className="center">Dónde vives</h2>
            </div>
            <StepCuidadorUbicacion
              id={cookies.id}
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={step}
            />
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <div className="register-steps__title-content">
              <h2 className="center">Detalles del lugar</h2>
            </div>
            <StepCuidadorPlaceDetail
              id={cookies.id}
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={step}
            />
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <div className="register-steps__title-content">
              <h2 className="center">Preferencias de servicio</h2>
            </div>
            <StepCuidadorServicios
              id={cookies.id}
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={step}
            />
          </React.Fragment>
        );
      case 5:
        return <Step5 />;
      case 6:
        return (
          <StepCuidadorTest
            id={cookies.id}
            houserId={cookies.houserId}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={step}
          />
        );
      case 7:
        return <Step6 />;
      default:
        return <Redirect to={ROUTES.CUIDADOR_DASHBOARD} />;
    }
  }

  function Step5() {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageVariant, setMessageVariant] = useState('');
    const [messageState, setMessageState] = useState(false);
    const title = useInputValue('');
    const description = useInputValue('');
    const [image, setImage] = useState({
      file: '',
      imagePreviewUrl: '',
    });
    const [yourPhoto1, setYourPhoto1] = useState('');
    const [yourPhoto2, setYourPhoto2] = useState('');
    const [yourPhoto3, setYourPhoto3] = useState('');
    const [yourPhoto4, setYourPhoto4] = useState('');
    const [yourPhoto5, setYourPhoto5] = useState('');
    const [yourPhoto6, setYourPhoto6] = useState('');
    const [yourPhoto7, setYourPhoto7] = useState('');
    const [yourPhoto8, setYourPhoto8] = useState('');
    const [yourPhoto9, setYourPhoto9] = useState('');
    const [yourPhoto10, setYourPhoto10] = useState('');

    const userId = cookies.id;

    const handleCloseSnackbar = (e) => {
      setMessage('');
      setMessageState(false);
    };

    const handleSnackbar = (text, variant) => {
      setMessage(text);
      setMessageVariant(variant);
      setMessageState(true);
    };

    useEffect(() => {
      fetchCuidador();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const fetchCuidador = async () => {
      sendData(SERVICES.GET_GALLERY_BY_USER, OPTIONS.POST({ id: userId }));
    };

    const sendData = async (url, options) => {
      try {
        const response = await fetch(url, options);
        const data = await response.json();
        // console.log(data);
        if (response.status === 200) {
          if (data.gallery) {
            if (data.gallery[0]) {
              setYourPhoto1(VARIABLES.IMAGE_URL + data.gallery[0].photo);
            }
            if (data.gallery[1]) {
              setYourPhoto2(VARIABLES.IMAGE_URL + data.gallery[1].photo);
            }
            if (data.gallery[2]) {
              setYourPhoto3(VARIABLES.IMAGE_URL + data.gallery[2].photo);
            }
            if (data.gallery[3]) {
              setYourPhoto4(VARIABLES.IMAGE_URL + data.gallery[3].photo);
            }
            if (data.gallery[4]) {
              setYourPhoto5(VARIABLES.IMAGE_URL + data.gallery[4].photo);
            }
            if (data.gallery[5]) {
              setYourPhoto6(VARIABLES.IMAGE_URL + data.gallery[5].photo);
            }
            if (data.gallery[6]) {
              setYourPhoto7(VARIABLES.IMAGE_URL + data.gallery[6].photo);
            }
          }
        }
      } catch (error) {
        handleSnackbar(error.message, 'warning');
        setLoading(false);
      }
    };

    const handleSubmit5 = (e) => {
      e.preventDefault();
      if (yourPhoto1 && yourPhoto2 && yourPhoto3 && yourPhoto4) {
        fetchUpdateUser5();
      } else {
        handleSnackbar('Sube como minimo 7 fotos.', 'warning');
      }
    };

    const handleSubmitImage = (e) => {
      e.preventDefault();
      if (image.file) {
        // console.log('handle uploading-', image.file);
        fetchImageUser();
      } else {
        handleSnackbar('No se ha seleccionado ninguna imagen.', 'warning');
      }
    };

    const handleImageChange = (e) => {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
        setImage({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file);
      }
    };

    const fetchUpdateUser5 = async () => {
      let body = {
        id: cookies.id,
        title: title.value,
        description: description.value,
        video: '',
      };
      sendUserData(SERVICES.UPDATE_HOUSER_STEP_5, OPTIONS.POST(body));
    };

    const fetchImageUser = async () => {
      Resizer.imageFileResizer(
        image.file,
        580,
        580,
        'JPEG',
        80,
        0,
        (uri) => {
          // console.log(uri)
          let imageFile = convertBase64ToFile(uri);
          let imageName = getFileName(cookies.id, imageFile.size, 'p0_master');

          const formData = new FormData();
          formData.append('id', cookies.id);
          formData.append('photo', imageFile, imageName);

          sendImage(SERVICES.UPLOAD_PHOTO_PUBLICATION, OPTIONS.POST_FORM_DATA(formData));
        },
        'base64',
      );
    };

    const sendImage = async (url, options) => {
      try {
        setLoading(true);
        const response = await fetch(url, options);
        const data = await response.json();
        // console.log(data);
        if (data.message) {
          handleSnackbar(data.message, 'success');
          setLoading(false);
          if (!yourPhoto1) {
            setYourPhoto1(image.imagePreviewUrl);
          } else if (!yourPhoto2) {
            setYourPhoto2(image.imagePreviewUrl);
          } else if (!yourPhoto3) {
            setYourPhoto3(image.imagePreviewUrl);
          } else if (!yourPhoto4) {
            setYourPhoto4(image.imagePreviewUrl);
          } else if (!yourPhoto5) {
            setYourPhoto5(image.imagePreviewUrl);
          } else if (!yourPhoto6) {
            setYourPhoto6(image.imagePreviewUrl);
          } else if (!yourPhoto7) {
            setYourPhoto7(image.imagePreviewUrl);
          } else if (!yourPhoto8) {
            setYourPhoto8(image.imagePreviewUrl);
          } else if (!yourPhoto9) {
            setYourPhoto9(image.imagePreviewUrl);
          } else if (!yourPhoto10) {
            setYourPhoto10(image.imagePreviewUrl);
          }
          setImage({
            file: '',
            imagePreviewUrl: '',
          });
        } else {
          handleSnackbar('Error al subir la Imagen', 'error');
          setLoading(false);
        }
      } catch (error) {
        handleSnackbar('Error: ' + error.message, 'error');
        setLoading(false);
      }
    };

    const ImageHolder = (props) => {
      const { image } = props;
      return (
        <Grid item xs={6} sm={3}>
          {image ? (
            <img className="your-photo" src={image} alt="your-publication" />
          ) : (
            <img
              className="your-photo"
              src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              alt="your-publication"
            />
          )}
        </Grid>
      );
    };

    const ImageHolderExtra = (props) => {
      const { image } = props;
      return (
        <React.Fragment>
          {image && (
            <Grid item xs={6} sm={3}>
              <img className="your-photo" src={image} alt="your-publication" />
            </Grid>
          )}
        </React.Fragment>
      );
    };

    function ImageProgress(props) {
      const { image } = props;
      return (
        <div className="image-progress">
          <img className="publication-img" src={image} alt="publication" />
          <CircularProgress className="image-progress__circle" />
        </div>
      );
    }

    return (
      <div>
        <h2 className="dh-title-section">Crea tu publicación</h2>
        <Grid container spacing={0} className="photo-publication-container">
          <Grid item xs={12} sm={6} md={8}>
            <div className="img-container">
              {image.imagePreviewUrl ? (
                <React.Fragment>
                  {loading ? (
                    <ImageProgress image={image.imagePreviewUrl} />
                  ) : (
                    <label htmlFor="upload-photo-publi">
                      <img
                        className="publication-img"
                        src={image.imagePreviewUrl}
                        alt="publication"
                      />
                    </label>
                  )}
                </React.Fragment>
              ) : (
                <label htmlFor="upload-photo-publi">
                  <div className="drop-zone">
                    <div className="drop-zone__content">
                      <p>
                        <img src={ImagePlaceholder} alt="placeholder" />
                        Sube 7 fotos (incluye a la familia cuidadora y los espacios de tu casa
                        donde estará la mascota)0
                      </p>
                    </div>
                  </div>
                </label>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="form-grid">
            <form onSubmit={handleSubmitImage} className="form-container">
              {/*
              <label htmlFor="upload-photo-publi" style={{ display: 'block' }}>
                <button className="btn-primary border btn-block" type="button">
                  Cargar foto
                </button>
              </label>
              */}
              <input type="file" id="upload-photo-publi" onChange={handleImageChange} />
              <button className="btn-primary btn-block" type="submit">
                <ImageIcon />
                Guardar Imagen
              </button>
            </form>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="your-photo-container">
          <ImageHolder image={yourPhoto1} />
          <ImageHolder image={yourPhoto2} />
          <ImageHolder image={yourPhoto3} />
          <ImageHolder image={yourPhoto4} />
          <ImageHolderExtra image={yourPhoto5} />
          <ImageHolderExtra image={yourPhoto6} />
          <ImageHolderExtra image={yourPhoto7} />
          <ImageHolderExtra image={yourPhoto8} />
          <ImageHolderExtra image={yourPhoto9} />
          <ImageHolderExtra image={yourPhoto10} />
        </Grid>
        <form onSubmit={handleSubmit5} className="info-form">
          <ul className="flex-outer">
            <li className="more-margin">
              <label htmlFor="title">Titulo*:</label>
              <TextField
                required
                variant="outlined"
                id="title"
                name="title"
                type="text"
                value={title.value}
                onChange={title.onChange}
              />
            </li>
            <li className="more-margin">
              <label htmlFor="description">Descripción*:</label>
              <TextField
                required
                variant="outlined"
                id="description"
                name="description"
                type="text"
                multiline
                rows="4"
                value={description.value}
                onChange={description.onChange}
              />
            </li>
            <li>
              <div className="btn-container less-margin">
                <button type="submit" className="btn-primary medium btn-step">
                  Guardar y Continuar
                </button>
              </div>
            </li>
          </ul>
        </form>
        {messageState && (
          <Notification
            message={message}
            variant={messageVariant}
            vertical="top"
            horizontal="center"
            state={messageState}
            onClose={handleCloseSnackbar}
          />
        )}
      </div>
    );
  }

  function Step6() {
    const handleSubmit6 = (e) => {
      e.preventDefault();
      fetchUpdateUser6();
    };

    const fetchUpdateUser6 = async () => {
      let body = {
        id: cookies.id,
        terms_conditions: 1,
      };
      try {
        const response = await fetch(SERVICES.UPDATE_HOUSER_STEP_6, OPTIONS.POST(body));
        const data = await response.json();
        let houserId = '';
        // console.log(data)
        if (response.status === 200) {
          notification.handleSnackbar('Datos guardados', 'success');
          if (data.houser) {
            houserId = data.houser.id;
          }
          // Gestión del usuario
          // token, id, houserId, stClient, stHouser
          activateAuth(
            data.user.id,
            data.user.id,
            houserId,
            data.user.confirmation,
            data.user.terms_conditions,
          );
          setLocation(data.user.location.country, data.user.location.city);
          setRole(data.user.rol);
          handleNext();
        } else {
          notification.handleSnackbar(data.message, 'error');
        }
      } catch (error) {
        notification.handleSnackbar('Error:' + error.message, 'error');
      }
    };

    return (
      <React.Fragment>
        <h2 className="dh-title-section">Terminos y condiciones</h2>
        <form onSubmit={handleSubmit6}>
          <div className="terms-condition">
            {TermsConditions.map((data, index) => (
              <p key={index}>
                <strong>{data.title}</strong> {data.text}
              </p>
            ))}
            <div className="btn-container less-margin">
              <button type="submit" className="btn-primary medium btn-step last">
                Acepto y continuar
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }

  const sendUserData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        notification.handleSnackbar('Datos guardados', 'success');
        handleNext();
      } else {
        notification.handleSnackbar(data.message, 'error');
      }
    } catch (error) {
      notification.handleSnackbar('Error:' + error.message, 'error');
    }
  };

  let height = 722;
  // Obtener tamaño de la pantalla
  if (window.innerHeight) {
    height = window.innerHeight;
  }
  let heightView = height - 64;
  let heightSteps = height - 64;

  let fromCuidador = ROUTES.CUIDADOR_DASHBOARD;

  if (props.location.state) {
    fromCuidador = props.location.state || {
      from: { pathname: ROUTES.CUIDADOR_DASHBOARD },
    };
  }

  if (isAuth && houser && statusHouser) return <Redirect to={fromCuidador} />;
  // if (isAuth && client) return <Redirect to={ROUTES.HOME} />
  if (isAuth && admin) return <Redirect to={ROUTES.HOME} />;
  if (!isAuth) return <Redirect to={ROUTES.HOME} />;

  return (
    <React.Fragment>
      <SimpleAppBar />
      <div className="register-steps" style={{ minHeight: heightView + 'px' }}>
        <MobileStepper variant="progress" steps={8} position="static" activeStep={activeStep} />
        <Grid
          container
          spacing={0}
          className="dh-multistep"
          style={{ minHeight: heightSteps + 'px' }}>
          <Grid item xs={false} md={3} className="dh-multistep-list">
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel className="step-content">{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} md={9} className="dh-multistep-form">
            <Container maxWidth="md">
              <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
              {/*
              <div className={classes.actionsContainer}>
                <div className="margin-bottom-x2">
                  <button
                    onClick={handleBack}
                    className="btn-primary medium border"
                    style={{marginRight: 16}}>
                    Atras
                  </button>
                  <button
                    color="primary"
                    onClick={handleNext}
                    className="btn-primary medium" >
                    {activeStep === steps.length - 1 ? 'Terminar' : 'Siguiente'}
                  </button>
                </div>
              </div>
              */}
            </Container>
          </Grid>
        </Grid>
        <div className={classes.root}>
          {activeStep === steps.length && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <Typography>Todos los pasos completados: has terminado</Typography>
            </Paper>
          )}
        </div>
      </div>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </React.Fragment>
  );
}

export default RegisterSteps;
