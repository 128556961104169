import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './stepProfilePicture.scss'
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables'
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// Utils
import convertBase64ToFile from 'utils/convertBase64ToFile'
import getFileName from 'utils/getFileName'
// Dependencies
import Resizer from 'react-image-file-resizer';
// Components
import Notification from 'components/notification/Notification';
// Images - DogHouser
import Gallery from 'icons/Gallery';
// Material-UI
import CircularProgress from '@material-ui/core/CircularProgress';
// Material-Images
import ImageIcon from '@material-ui/icons/Image';
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';

function ImageProgress(props) {
  const { image } = props
  return (
    <div className="image-progress">
      <img
        className="profile-img"
        src={image} 
        alt="usuario"
      />
      <CircularProgress className="image-progress__circle"/>
    </div>
  )
}

function StepProfilePicture(props) {
  const { id, handleNext } = props
  const [loading, setLoading] = useState(false)
  // Variables de "Notification"
  const notification = useNotificationValue()
  const [user, setUser] = useState({
    user: {
      id: '',
      photo: '',
    }
  })
  const [image, setImage] = useState({
    file: '',
    imagePreviewUrl: '',
  })

  useEffect(() => {
    if(!user.user.id) {
      fetchUserData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user.id])

  // Obtener la data del Usuario
  const fetchUserData = async () => {
    let body = {
      id: id
    }
    sendData(SERVICES.USER_BY_ID, OPTIONS.POST(body), VARIABLES.STATE_INITIATE)
  }

  // Detectar cambios en la imagen
  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      setImage({
        file: file,
        imagePreviewUrl: reader.result
      })
    }
    if(file && file.type.match('image.*')) {
      reader.readAsDataURL(file)
    }
  }

  // Manejo de la imagen del Usuario
  const handleSubmitImage = e => {
    e.preventDefault();
    if(image.file) {
      // console.log('handle uploading-', image.file);
      fetchImageUser()
    } else {
      if(user.user.photo) {
        handleNext()
      } else {
        notification.handleSnackbar("Tienes que escoger una imagen de perfil.", 'warning')
      }
    }
  }

  // Establecer los datos del Usuario a enviar
  const fetchImageUser = async () => {
    setLoading(true)
    Resizer.imageFileResizer(
      image.file,
      480,
      480,
      'JPEG',
      80,
      0,
      uri => {
        let imageFile = convertBase64ToFile(uri);
        let imageName = getFileName(id, imageFile.size, 'avatars')
        const formData = new FormData()
        formData.append('id', id)
        formData.append('photo', imageFile, imageName)

        sendData(SERVICES.UPLOAD_PHOTO_USER, OPTIONS.POST_FORM_DATA(formData), VARIABLES.STATE_UPDATE)
      },
      'base64'
    )
  }

  // Funcion para enviar los datos al servidor
  const sendData = async(url, options, state) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (state === VARIABLES.STATE_INITIATE) {
          setUser(data)
        } else if (state === VARIABLES.STATE_UPDATE) {
          notification.handleSnackbar("Has actualizado la imagen de perfil correctamente", 'success')
          setLoading(false)
          handleNext()
        }
      } else {
        if (state === VARIABLES.STATE_INITIATE) {
          notification.handleSnackbar("Error al obtener la foto de perfil", 'error')
        } else if (state === VARIABLES.STATE_UPDATE) {
          notification.handleSnackbar("Lo sentimos, no fue posible cargar su imagen.", 'error')
          setLoading(false)
        }
      }
    } catch (error) {
      notification.handleSnackbar('Error: ' + error.message, 'error')
      setLoading(false)
    }
  }

  return (
    <div className="register-step-2">
      <h2 className="dh-title-section">Foto de Perfil</h2>
      <div className="profile-img-container">
        {image.imagePreviewUrl ? (
          <React.Fragment>
            {loading ? (
              <ImageProgress 
                image={image.imagePreviewUrl} />
            ) : (
              <label htmlFor="upload-photo">
                <img
                  className="profile-img"
                  src={image.imagePreviewUrl} 
                  alt="usuario"
                />
              </label>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {user.user.photo ? (
              <label htmlFor="upload-photo">
                <img 
                  className="profile-img"
                  src={`${VARIABLES.IMAGE_URL}${user.user.photo}`} 
                  alt={user.user.name}
                />
              </label>
            ) : (
              <label htmlFor="upload-photo">
                <div className="drop-zone">
                  <div className="drop-zone__content">
                    <Gallery/>
                  </div>
                </div>
              </label>
            )}
          </React.Fragment>
        )}
      </div>
      <p className="small">
        Para que los huéspedes y anfitrones se conozcan, lo mejor es añadir fotos de la cara que sean nítidas
        y esten sacadas de frente. !Hospedar un paisaje no tiene mucha gracia¡ Asegúrate de utilizar una foto
        en la que se te vea bien la cara y que no incluya información personal o sensible que preferirías que
        los huéspedes o anfitriones no vieran.
      </p>
      <form onSubmit={handleSubmitImage} className="form-container">
        <label htmlFor="upload-photo" className="btn-primary border btn-profile-border">
          <CloudUploadRoundedIcon/>
          Cargar foto
        </label>
        <input type='file' id="upload-photo" onChange={handleImageChange}/>
        <button className="btn-primary" type="submit" >
          <ImageIcon/>
          Guardar Imagen y Continuar
        </button>
      </form>
      {notification.messageState &&
        <Notification 
          message={notification.message} 
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState} 
          onClose={notification.handleCloseSnackbar}
        />
      }
    </div>
  )
}

StepProfilePicture.propTypes = {
  id: PropTypes.string.isRequired,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
}

export default StepProfilePicture
