import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { IconTrash } from '@tabler/icons';

import * as VARIABLES from '@root/constants/variables';
import { MainCard } from '@root/ui-component/cards';
import NoFoto from '@root/assets/images/ph_no_foto.png';
import styles from './galleryGrid.module.scss';

interface GalleryGridType {
  loading?: boolean;
  gallery?: any;
  fetchDeleteImage?: (galleryId: number) => void;
}

const GalleryGrid = (props: GalleryGridType) => {
  const { loading, gallery, fetchDeleteImage } = props;

  if (loading) {
    return (
      <>
        {Array.from(new Array(8)).map((data, index) => (
          <Grid item xs={12} sm={4} md={3} key={index}>
            <MainCard content={false} className={styles.item}>
              <Skeleton variant="rect" className={styles.image} />
            </MainCard>
          </Grid>
        ))}
      </>
    );
  }

  if (gallery.length <= 0) {
    return (
      <Grid item xs={12} sm={4} md={3}>
        <MainCard content={false} className={styles.item}>
          <img src={NoFoto} className={styles.image} alt="gallery" />
        </MainCard>
      </Grid>
    );
  }

  return (
    <>
      {gallery.map((gallery, index) => (
        <Grid item xs={12} sm={4} md={3} key={index}>
          <MainCard content={false} className={styles.item}>
            <div className={styles.deleteBtn} onClick={() => fetchDeleteImage(gallery.id)}>
              <IconTrash />
            </div>
            <img
              src={VARIABLES.IMAGE_URL + gallery.photo}
              className={styles.image}
              alt={gallery.photo}
            />
          </MainCard>
        </Grid>
      ))}
    </>
  );
};

export default GalleryGrid;
