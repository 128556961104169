import React from 'react'
import PropTypes from 'prop-types'
// Dependencies
import AliceCarousel from 'react-alice-carousel'

const Gallery = props => {
  const { data } = props
  const handleOnDragStart = (e) => e.preventDefault()

  return (
    <AliceCarousel
      mouseTrackingEnabled
      buttonsDisabled={true}
      dotsDisabled={true}
      infinite={true}
      responsive={{
        0: {
          items: 1,
        },
        1024: {
          items: 4
        }
      }}
      autoPlay={true}
      autoPlayInterval={1200}>
      {data.map((data, index) => {
        return (
          <a key={index} href={data.link} target="_blank" rel="noopener noreferrer" className="dh-link">
            <img src={data.image} alt="Gallery" onDragStart={handleOnDragStart} className="slide-image" />
          </a>
        )
      })}
    </AliceCarousel>
  )
}

Gallery.propTypes = {
  data: PropTypes.array.isRequired
}

export default Gallery
