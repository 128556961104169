import React from 'react'
import './lostPassword.scss'
// Services
import * as SERVICES from 'services/config'
import * as OPTIONS from 'services/options'
// Constants
import * as ROUTES from 'constants/routes'
// Componets
import Notification from 'components/notification/Notification'
// Hooks
import { useInputValue } from  'hooks/useInputValue';
import useNotificationValue from 'hooks/useNotificationValue'
// Material-UI
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'

function LostPassword(props) {
  const email = useInputValue('')
  // Variables de "Notification"
  const notification = useNotificationValue()

  const handleSubmit = e => {
    e.preventDefault();
    fetchUpdateUser()
  }

  const fetchUpdateUser = async () => {
    let body = {
      email: email.value
    }
    sentUserData(SERVICES.LOST_PASSWORD, OPTIONS.POST(body))
  }

  const sentUserData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if(response.status === 200) {
        notification.handleSnackbar('Mensaje de texto enviado', 'info')
        props.history.push({
          pathname: ROUTES.NEW_PASSWORD,
          email: email.value,
        })
      } else {
        notification.handleSnackbar(data.message, 'error')
      }
    } catch (error) {
      notification.handleSnackbar('Error:' + error.message, 'error')
    }
  }

  return (
    <Container maxWidth="sm" className="lost-password">
      <div className="dh-content-block">
        <div className="content-header">
          <h1 className="marginx05 text-center">Restablecer contraseña</h1>
        </div>
        <div className="content-body">
          <p className="regular justify">
            Para restablecer su contraseña, escriba la dirección de correo electrónico completa que utilizó
            para registrarse en DogHouser y le enviaremos un <strong>mensaje de texto</strong> al número
            teléfono asociado a su cuenta, para guiarlo en el restablecimiento de su contraseña.
          </p>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              required
              variant="outlined"
              margin="normal"
              id="email"
              label="Correo"
              type="email"
              value={email.value}
              onChange={email.onChange}
              fullWidth
            />
            <button type="submit" className="btn-primary btn-block medium">
              Enviar
            </button>
          </form>
        </div>
      </div>
      {notification.messageState &&
        <Notification 
          message={notification.message} 
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState} 
          onClose={notification.handleCloseSnackbar}
        />
      }
    </Container>
  )
}

export default LostPassword
