import { ChangeEvent } from 'react';
import { Grid } from '@material-ui/core';

import { TypeServiceType } from '@root/models/ServiceDTO';
import { ServiceOptionType } from '@root/utils/mutateHouserServices';
import ServiceType from './ServiceType';
import SubServices from './SubServices';

interface ServicesProps {
  serviceOptions?: ServiceOptionType[];
  typeServiceActive?: TypeServiceType;
  setTypeServiceActive?: (typeService: TypeServiceType) => void;
  serviceActive?: number;
  setServiceActive?: (serviceActive: number) => void;
  setDropDate?: (dropDate: Date) => void;
  setPickupDate?: (pickupDate: Date) => void;
}

const Services = (props: ServicesProps) => {
  const {
    serviceOptions,
    typeServiceActive,
    setTypeServiceActive,
    serviceActive,
    setServiceActive,
    setDropDate,
    setPickupDate,
  } = props;

  const optionKey = serviceOptions.findIndex((service) => service.id === typeServiceActive);

  const handleServiceActive = (e: ChangeEvent<HTMLInputElement>) => {
    setServiceActive(Number(e.target.value));
  };

  return (
    <>
      <Grid container spacing={3}>
        {serviceOptions.map((serviceOption) => (
          <ServiceType
            key={serviceOption.id}
            serviceOption={serviceOption}
            typeServiceActive={typeServiceActive}
            setTypeServiceActive={setTypeServiceActive}
            setDropDate={setDropDate}
            setPickupDate={setPickupDate}
          />
        ))}
      </Grid>
      {typeServiceActive === 3 && (
        <SubServices
          serviceOptions={serviceOptions}
          optionKey={optionKey}
          serviceActive={serviceActive}
          handleServiceActive={handleServiceActive}
        />
      )}
    </>
  );
};

export default Services;
