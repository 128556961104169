const replacePathParams = (path, pathParams) => {
  // console.log("path        -> "  + path)
  // console.log("pathParams  -> " + pathParams)
  let pathToGo = path;
  if (pathParams) {
      Object.keys(pathParams).forEach(param => {
        // console.log("param  -> " + param)
        pathToGo = pathToGo.replace(`:${param}`, pathParams[param]);
      });
  }
  return pathToGo;
};

export default replacePathParams
