import React, { useState, useContext, useEffect } from 'react';
import { Context } from '@root/Context';
import './cuidadorPublicacionInfo.scss';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
// Dependencies
import Iframe from 'react-iframe';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// Components
import TitleArrowBack from '@root/components/TitleBack';
import Notification from 'components/notification';
import LoadingText from 'components/loadingText';
// Material-UI
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

function CuidadorPublicacionInfo(props) {
  const { cookies } = useContext(Context);
  let userId;
  // Se detecta si se pasa un ID por la URL
  if (props.match.params.id) {
    userId = props.match.params.id;
  } else {
    // Si no se detecta se usa el ID guardado en las cookies
    userId = cookies.id;
  }
  const [loading, setLoading] = useState(false);
  const notification = useNotificationValue();

  const [user, setUser] = useState({
    houser: {
      publication: {
        description_pub: '',
        houser_id: '',
        id: '',
        title: '',
        video: '',
      },
    },
  });
  const [video, setVideo] = useState({
    id: '',
    link: '',
  });
  let urlYoutube = 'https://www.youtube.com/watch?v=';

  // Funcion para volver a la pestaña anterior
  const handleBackClick = () => {
    // Se detecta si se pasa un ID por la URL
    if (props.match.params.id) {
      // props.history.push(`/dh-admin/cuidadores/${userId}`)
      props.history.goBack();
    } else {
      // Si no se detecta se usa el ID guardado en las cookies
      props.history.push(ROUTES.CUIDADOR_PUBLICACION);
    }
  };

  const handleInputChange = (e) => {
    setUser({
      houser: {
        publication: {
          ...user.houser.publication,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  // Funcion para dividir un objeto tipo String
  function dividirCadena(cadenaADividir, separador) {
    var arrayDeCadenas = cadenaADividir.split(separador);
    for (var i = 0; i < arrayDeCadenas.length; i++) {
      console.log(arrayDeCadenas[i] + '/');
    }
    return arrayDeCadenas[1];
  }

  const handleVideoChange = (e) => {
    var igual = '=';
    var id = dividirCadena(e.target.value, igual);
    // console.log("Id --> " + id)
    setVideo({
      id: id,
      link: e.target.value,
    });
  };

  // Preparar los datos y el ID del usuario para solicitar la informacion al servidor
  const fetchCuidador = async () => {
    setLoading(true);
    sendUserData(
      SERVICES.GET_CUIDADOR_BY_ID,
      OPTIONS.POST({ id: userId }),
      VARIABLES.STATE_INITIATE,
    );
  };

  // Obtener los datos actulizados
  const handleSubmit = (e) => {
    e.preventDefault();
    let title = user.houser.publication.title;
    let description = user.houser.publication.description_pub;
    let videoId = '';
    if (video.id) {
      videoId = video.id;
    }
    fetchUpdateUser(title, description, videoId);
  };

  const fetchUpdateUser = async (title, description, videoId) => {
    let body = {
      id: userId,
      title: title,
      description: description,
      video: videoId,
    };
    sendUserData(SERVICES.UPDATE_HOUSER_STEP_5, OPTIONS.POST(body), VARIABLES.STATE_UPDATE);
  };

  // Enviar datos al servidor
  const sendUserData = async (url, options, state) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (state === VARIABLES.STATE_INITIATE) {
          setLoading(false);
          if (data.user.houser.publication) {
            setUser(data.user);
            setVideo({
              id: data.user.houser.publication.video,
              link: urlYoutube + data.user.houser.publication.video,
            });
          }
        } else if (state === VARIABLES.STATE_UPDATE) {
          notification.handleSnackbar('Datos actualizados', 'success');
        }
      } else {
        notification.handleSnackbar(data.message, 'warning');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCuidador();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div className="cuidador-publication-info">
      <Container maxWidth="lg">
        <TitleArrowBack handleClick={handleBackClick}>
          <h4 className="marginx05">{'Volver al anuncio'}</h4>
        </TitleArrowBack>
        <div className="cuidador-publication-info__title">
          <h1>Título, descripción y Vídeo</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <h3 className="cuidador-publication-info__sub-title">Título del anuncio*</h3>
          <p className="marginx05">
            El título de tu anuncio debe destacar lo más especial de tu alojamiento.
          </p>
          {!loading ? (
            <TextField
              id="title"
              name="title"
              required
              fullWidth
              variant="outlined"
              type="text"
              value={user.houser.publication.title}
              onChange={handleInputChange}
            />
          ) : (
            <LoadingText count={0} />
          )}
          <h3 className="cuidador-publication-info__sub-title">Descripción del anuncio*</h3>
          <p className="marginx05">
            La descripción de tu anuncio debe ayudar a los clientes a hacerse una idea de comó
            será dejarte a su mascota.
          </p>
          {!loading ? (
            <TextField
              id="description_pub"
              name="description_pub"
              required
              fullWidth
              variant="outlined"
              type="text"
              multiline
              rows="6"
              value={user.houser.publication.description_pub}
              onChange={handleInputChange}
            />
          ) : (
            <LoadingText count={3} />
          )}
          <h3 className="cuidador-publication-info__sub-title">Video</h3>
          <p className="marginx05">
            Sube un breve video presentandote, mostrando los ambientes de la casa y la familia.
          </p>
          {!loading ? (
            <TextField
              id="video_link"
              name="video_link"
              fullWidth
              variant="outlined"
              type="text"
              label="Link"
              value={video.link}
              onChange={handleVideoChange}
            />
          ) : (
            <LoadingText count={0} />
          )}
          <div className="video-container">
            {!loading && (
              <Iframe
                url={`https://www.youtube.com/embed/${video.id}`}
                width="50%"
                height="400px"
                className="myClassname"
                display="initial"
                position="relative"
              />
            )}
          </div>
          <button className="btn-primary" type="submit">
            Guardar cambios
          </button>
        </form>
      </Container>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default CuidadorPublicacionInfo;
