import React from 'react'
import { useHistory } from 'react-router-dom'
import './persistentNotification.scss'
// Materil-UI
import Avatar from '@material-ui/core/Avatar';
// DogHouser Icons
import EmailIcon from 'icons/EmailIcon';
import CellPhoneIcon from 'icons/CellPhoneIcon';
import CheckIcon from 'icons/CheckIcon';
import DeleteIcon from 'icons/DeleteIcon';

const variantIcon = {
  email: EmailIcon,
  phone: CellPhoneIcon,
  success: CheckIcon,
  error: DeleteIcon,
};

function PersistentNotification(props) {
  const { variant, message, data } = props
  const Icon = variantIcon[variant]
  const history = useHistory()
  
  const handleClick = () => {
    if (data) {
      history.push(`/cuenta/reservas/${data.booking_id}`)
    }
  }

  return (
    <div className="persistent-notification" onClick={() => handleClick()}>
      <Avatar className={`persistent-notification__image ${variant}`}>
        <Icon/>
      </Avatar>
      <div className="persistent-notification__text">
        <p dangerouslySetInnerHTML={ { __html: message}}></p>
      </div>
    </div>
  )
}

export default PersistentNotification
