import React from 'react'
import PropTypes from 'prop-types';

const CardEmergencyData = props => {
  const { houser } = props
  return (
    <div className="card-admin-list dh-card-elevation">
      <div className="card-admin-list__header">
        <span>Contacto de emergencia</span>
      </div>
      <div className="card-admin-list__content">
        <table className="dh-table">
          <tbody>
            <tr>
              <td className="td-title">Nombre</td>
              <td className="td-text">{houser.emergency_contact_name}</td>
            </tr>
            <tr>
              <td className="td-title">Teléfono</td>
              <td className="td-text">{houser.emergency_contact_phone}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

CardEmergencyData.propTypes = {
  loading: PropTypes.bool,
  houser: PropTypes.object.isRequired,
}

export default CardEmergencyData
