import Radio, { RadioProps } from '@material-ui/core/Radio';

import DogIcon from '@root/assets/icons/DogIcon';
import CatIcon from '@root/assets/icons/CatIcon';
import RabbitIcon from '@root/assets/icons/RabbitIcon';
import ExoIcon from '@root/assets/icons/ExoIcon';

interface PetTypeRadioProps extends RadioProps {
  type?: 1 | 2 | 3 | 4;
}

const variantIcon = {
  1: DogIcon,
  2: CatIcon,
  3: RabbitIcon,
  4: ExoIcon,
};

const variantIconChecked = {
  1: DogIcon,
  2: CatIcon,
  3: RabbitIcon,
  4: ExoIcon,
};

const PetTypeRadio = (props: PetTypeRadioProps) => {
  const { type, ...other } = props;
  const Icon = variantIcon[type];
  const IconChecked = variantIconChecked[type];

  return (
    <Radio
      disableRipple
      color="default"
      icon={<Icon />}
      checkedIcon={<IconChecked setColor="accent" />}
      {...other}
    />
  );
};

export default PetTypeRadio;
