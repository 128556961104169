import React from "react";
import { Link as RouterLink } from "react-router-dom";
import "./layoutIngresoCliente.scss";
// Constants
import * as ROUTES from "constants/routes";
// Images
import BackGround from "assets/images/fondo_login.jpg";
import dhLogoWhite from "assets/dg_logo_white.png";
// Material - UI
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${BackGround})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

function LayoutIngresoCliente(props) {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={5} md={7} className={classes.image}>
        <RouterLink to={ROUTES.HOME}>
          <img
            className="layout-img-logo"
            src={dhLogoWhite}
            alt="Logo de la web"
          ></img>
        </RouterLink>
      </Grid>
      <Grid item xs={12} sm={7} md={5} component={Paper} elevation={6} square>
        {props.children}
      </Grid>
    </Grid>
  );
}

export default LayoutIngresoCliente;
