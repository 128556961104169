import React from 'react'

function BadgeTypeHouse(props) {
  const { type_delivery, type_exclusive, type_share } = props
  let typeHouse
  
  if (type_exclusive === 1) {
    typeHouse = <div className="dh-type-house" style={{color: 'rgb(102, 103, 175)'}}>Casa Exclusiva</div>;
  } else if (type_share === 1) {
    typeHouse = <div className="dh-type-house" style={{color: 'rgb(126, 205, 197)'}}>Casa Compartida</div>;
  } else if (type_delivery === 1) {
    typeHouse = <div className="dh-type-house" style={{color: 'rgb(67, 160, 71)'}}>Delivery</div>;
  } else {
    typeHouse = <div className="dh-type-house" style={{color: 'rgb(229, 57, 53)'}}></div>
  }

  return (
    <React.Fragment>
      {typeHouse}
    </React.Fragment>
  )
}

export default BadgeTypeHouse
