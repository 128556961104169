import React, { useContext, useState } from "react";
import { Context } from "@root/Context";
import { Redirect, Link as RouterLink } from "react-router-dom";
// Services
import * as SERVICES from "services/config";
import * as OPTIONS from "services/options";
// Utils
import convertBase64ToFile from "utils/convertBase64ToFile";
import getFileName from "utils/getFileName";
// Componets
import Notification from "components/notification";
// import BtnFacebook from 'components/btnFacebook';
// import BtnGoogle from 'components/btnGoogle';
import BtnCorreo from "components/btnCorreo";
// Images
import dhLogo from "assets/dg_logo_white.png";
import BackGround from "assets/images/fondo_login_2.jpg";
// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${BackGround})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  termsText: {
    fontSize: "0.85rem",
    textAlign: "center",
  },
  termsLink: {
    textDecoration: "none",
    color: "#80cdc5",
  },
  linkRed: {
    fontFamily: "Montserrat-Semibold",
    color: "#f44336",
  },
}));

function RegisterSocialHouser(props) {
  const classes = useStyles();
  const { isAuth, admin, client, houser, activateAuth, setRole, setLocation } =
    useContext(Context);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState(false);

  const handleCloseSnackbar = (e) => {
    setMessage("");
    setMessageState(false);
  };

  // eslint-disable-next-line no-unused-vars
  const responseFacebook = (response) => {
    if (response) {
      // console.log(response);
      if (response.status === "unknown") {
        setMessageState(true);
        setMessage("El usuario cancelo el login");
      } else if (!response.email) {
        setMessageState(true);
        setMessage("No cuenta con un correo público");
      } else {
        let image = "";
        if (response.picture.data.url) {
          image = response.picture.data.url;
        }
        fetchLoginFacebook(
          response.name, // name
          response.email, // email
          response.userID, // socialId
          image // picture
        );
      }
    } else {
      // console.log("El usuario cancelo el login, o no autorizo completamente");
    }
  };

  // eslint-disable-next-line no-unused-vars
  const responseGoogle = (response) => {
    if (response) {
      // console.log(response);
      if (response.error) {
        setMessageState(true);
        setMessage(response.error);
      } else {
        let image = "";
        if (response.profileObj.imageUrl) {
          image = response.profileObj.imageUrl;
        }
        fetchLoginGoogle(
          response.profileObj.givenName, // name
          response.profileObj.familyName, // lastName
          response.profileObj.email, // email
          response.googleId, // socialId
          image // picture
        );
      }
    } else {
      // console.log("El usuario cancelo el login, o no autorizo completamente");
    }
  };

  const fetchLoginFacebook = (name, facebook_email, facebook_id, photo) => {
    setMessage("");
    setMessageState(false);
    let type = "foto";
    if (photo === "") {
      type = "No foto";
    }
    let body = {
      country: "Perú",
      city: "Lima",
      name: name,
      last_name: "",
      facebook_email: facebook_email,
      facebook_id: facebook_id,
      photo: photo,
      rol: "0", // Houser
      terms_conditions: "0",
    };
    sentUserData(
      SERVICES.LOGIN_AND_REGISTER_FACEBOOK,
      OPTIONS.POST(body),
      type
    );
  };

  const fetchLoginGoogle = (
    name,
    last_name,
    google_email,
    google_id,
    photo
  ) => {
    setMessage("");
    setMessageState(false);
    let type = "foto";
    if (photo === "") {
      type = "No foto";
    }
    let body = {
      country: "Perú",
      city: "Lima",
      name: name,
      last_name: last_name,
      google_email: google_email,
      google_id: google_id,
      photo: photo,
      rol: "0", // Houser
      terms_conditions: "0",
    };
    sentUserData(SERVICES.LOGIN_AND_REGISTER_GOOGLE, OPTIONS.POST(body), type);
  };

  const sentUserData = async (url, options, type) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      let houserId = "";
      // console.log(data)
      if (data.status === 200) {
        setMessage("");
        setMessageState(false);
        if (data.houser) {
          houserId = data.houser.id;
        }
        if (type === "foto") {
          // Upload Foto
          fetchImageUser(
            data.user.id,
            houserId,
            data.user.photo,
            data.user.confirmation,
            data.user.terms_conditions,
            data.user.rol
          );
          setLocation(data.location.country, data.location.city);
        } else {
          // Gestión del usuario
          activateAuth(
            data.user.id,
            data.user.id,
            houserId,
            data.user.confirmation,
            data.user.terms_conditions
          );
          setLocation(data.location.country, data.location.city);
          setRole(data.user.rol);
        }
      } else {
        setMessage(data.message);
        setMessageState(true);
      }
    } catch (error) {
      setMessage("Error:" + error.message);
      setMessageState(true);
    }
  };

  const fetchImageUser = async (
    id,
    houserId,
    image,
    confirmation,
    terms_conditions,
    rol
  ) => {
    let blob = await fetch(image).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    // console.log(dataUrl)
    let imageFile = convertBase64ToFile(dataUrl);
    let imageName = getFileName(id, imageFile.size, "avatars");
    const formData = new FormData();
    formData.append("id", id);
    formData.append("photo", imageFile, imageName);
    try {
      const response = await fetch(
        SERVICES.UPLOAD_PHOTO_USER,
        OPTIONS.POST_FORM_DATA(formData)
      );
      const data = await response.json();
      // console.log(data)
      if (data.message) {
        setMessage("");
        setMessageState(false);
        activateAuth(id, id, houserId, confirmation, terms_conditions);
        setRole(rol);
      } else {
        setMessage("Error al subir la Imagen");
        setMessageState(true);
      }
    } catch (error) {
      setMessage("Error: " + error.message);
      setMessageState(true);
    }
  };

  let { from } = props.location.state || { from: { pathname: "/cuenta" } };

  if (isAuth && client) return <Redirect to="/" />;
  if (isAuth && houser) return <Redirect to={from} />;
  if (isAuth && admin) return <Redirect to="/" />;

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image}>
          <RouterLink to="/" className="logo">
            <img className="join-logo" src={dhLogo} alt="Logo de la web"></img>
          </RouterLink>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <h1 className="dh-title">Regístrate - Cuidador</h1>
            {/*
              <BtnFacebook
              response={responseFacebook}
              text="Seguir con Facebook"
            />
            <BtnGoogle
              response= {responseGoogle}
              text="Seguir con Google"
            />
            */}
            <BtnCorreo to="/join/cuidador/correo" text="Seguir con Email" />
            <p className={classes.termsText}>
              Al iniciar sesión estas aceptando los
              <RouterLink to="/terminos" className={classes.termsLink}>
                {" "}
                términos y condiciones{" "}
              </RouterLink>
              y la
              <RouterLink to="/join/cuidador" className={classes.termsLink}>
                {" "}
                politica de privacidad{" "}
              </RouterLink>
              de Dog Houser
            </p>
            <h3 className="divider">
              <span>o</span>
            </h3>
            <RouterLink to="/login" className="dh-no-decoration">
              <p className="login-link">
                ¿Ya tienes una cuenta?
                <span className={classes.linkRed}> ¡Inicia sesión!</span>
              </p>
            </RouterLink>
          </div>
          {messageState && (
            <Notification
              message={message}
              variant="error"
              vertical="top"
              horizontal="center"
              state={messageState}
              onClose={handleCloseSnackbar}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default RegisterSocialHouser;
