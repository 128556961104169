import { CardHeaderClassKey, StyleRules } from '@material-ui/core';

const MuiCardHeader: Partial<StyleRules<CardHeaderClassKey, {}>> = {
  root: {
    padding: '16px 24px',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  action: {
    alignSelf: 'initial',
    marginTop: 0,
    marginRight: 0,
  },
};

export default MuiCardHeader;
