import React from 'react';
import PropTypes from 'prop-types';
// Dependencies
import Workbook from 'react-excel-workbook';
// Constants
import * as VARIABLES from 'constants/variables';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import VerticalAlignBottomRoundedIcon from '@material-ui/icons/VerticalAlignBottomRounded';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: '1rem',
  },
  button: {
    fontFamily: 'Montserrat-Semibold',
    fontSize: '15px',
  },
}));

const WorkbookReservations = (props) => {
  const classes = useStyles();
  const { data } = props;

  const getStatus = (state, endDate) => {
    if (state === 3) {
      let fechaActual = new Date().getTime();
      let fechaRes = new Date(endDate).getTime();
      if (fechaActual + 1 > fechaRes + 1) {
        return 5;
      } else {
        return state;
      }
    }
    return state;
  };

  const exportData = [];

  data.forEach((res) => {
    let relStatus = getStatus(res.state, res.pickup_date);
    const item = {
      id: res.id,
      houser_id: `${res.houser.user.name} (#${res.houser_id})`,
      client_id: `${res.user.name} (#${res.user_id})`,
      title: res.service.name,
      start: res.drop_date,
      end: res.pickup_date,
      state: VARIABLES.RESERVATION_STATUS[relStatus],
      country: res.service.country,
      city: res.service.city,
      price_total: res.price_total,
      price_houser: res.price_total - res.price_tax,
      price_tax: res.price_tax,
    };
    exportData.push(item);
  });

  return (
    <div className={classes.content}>
      <Workbook
        filename="reservations_data.xlsx"
        element={
          <Button className={classes.button} startIcon={<VerticalAlignBottomRoundedIcon />}>
            Exportar
          </Button>
        }>
        <Workbook.Sheet data={exportData} name="Reservas">
          <Workbook.Column label="ID de reserva" value="id" />
          <Workbook.Column label="Titulo" value="title" />
          <Workbook.Column label="Nombre de Houser" value="houser_id" />
          <Workbook.Column label="Nombre de Cliente" value="client_id" />
          <Workbook.Column label="Día Inicio" value="start" />
          <Workbook.Column label="Día Final" value="end" />
          <Workbook.Column label="País" value="country" />
          <Workbook.Column label="Ciudad" value="city" />
          <Workbook.Column label="Precio total" value="price_total" />
          <Workbook.Column label="Houser" value="price_houser" />
          <Workbook.Column label="Tarifa de servicio" value="price_tax" />
          <Workbook.Column label="Estado" value="state" />
        </Workbook.Sheet>
      </Workbook>
    </div>
  );
};

WorkbookReservations.propTypes = {
  data: PropTypes.array.isRequired,
};

export default WorkbookReservations;
