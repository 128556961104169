import React from 'react';
// Material-UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export default function PromotionData(props) {
  const { promotion, title, handlePromotionChange, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="dh-card-elevation">
        <h2>{title}</h2>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="cod"
              name="cod"
              required
              fullWidth
              label="Cod"
              value={promotion.cod}
              onChange={(e) => handlePromotionChange('cod', e)}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="value"
              name="value"
              required
              fullWidth
              label="Porcentaje"
              value={promotion.value}
              onChange={(e) => handlePromotionChange('value', e)}
              margin="normal"
              variant="outlined"
              type="number"
            />
          </Grid>
        </Grid>
        <TextField
          id="description"
          name="description"
          required
          fullWidth
          label="Descripción"
          value={promotion.description}
          onChange={(e) => handlePromotionChange('description', e)}
          margin="normal"
          variant="outlined"
        />
      </div>
      <button type="submit" className="btn-primary medium margin-top-x1">
        {title}
      </button>
    </form>
  );
}
