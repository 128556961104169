import React, { useState, useEffect } from 'react';
import './stepNewPetInfo.scss';

import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
import * as VARIABLES from '@root/constants/variables';
// Components
import Notification from '../notification/Notification';
// Hooks
import { useInputValue } from '../../hooks/useInputValue';
// DogHouser Icons
import Dog from '../../assets/icons/Dog';
import DogIcon from '../../assets/icons/DogIcon';
import CatIcon from '../../assets/icons/CatIcon';
import RabbitIcon from '../../assets/icons/RabbitIcon';
import ExoIcon from '../../assets/icons/ExoIcon';
// Material-UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function StepNewPetInfo(props) {
  const { id, editPet, handleNext } = props;
  // Variables del componente "Notification"
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  // Variables de la vista
  const name = useInputValue('');
  const breed = useInputValue('');
  const birthdate = useInputValue('');
  const description = useInputValue('');
  const [size, setSize] = React.useState({
    dog_small: false,
    dog_medium: false,
    dog_big: false,
  });
  const [typePet, setTypePet] = React.useState({
    has_dogs: false,
    has_cats: false,
    has_rabbits: false,
    has_exo: false,
  });
  const [breeds, setBreeds] = useState([]);
  const [editId, setEditId] = useState(undefined);

  useEffect(() => {
    if (editPet) {
      setEditId(editPet.id);
      name.update(editPet.name);
      const petTypes = ['has_dogs', 'has_cats', 'has_rabbits', 'has_exo'];
      setTypePet({
        has_dogs: false,
        has_cats: false,
        has_rabbits: false,
        has_exo: false,
        [petTypes[editPet.pet_type - 1]]: true,
      });
      fetchBreeds(VARIABLES.PET_TYPE_VALUE[petTypes[editPet.pet_type - 1]]);
      breed.update(editPet.breed_id);
      birthdate.update(editPet.birth_date);
      const petSizes = ['dog_small', 'dog_medium', 'dog_big'];
      setSize({
        dog_small: false,
        dog_medium: false,
        dog_big: false,
        [petSizes[editPet.pet_size]]: true,
      });
      description.update(editPet.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPet]);

  // Funcion para cerrar el componente Notification
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  // Funcion para detectar un cambio en el CheckBox
  const handleCheckboxChange = (name) => (event) => {
    setSize({
      dog_small: false,
      dog_medium: false,
      dog_big: false,
      [name]: event.target.checked,
    });
  };

  // Detectar un cambios en el tipo de mascota
  const handleTypePetChange = (name) => (event) => {
    setTypePet({
      has_dogs: false,
      has_cats: false,
      has_rabbits: false,
      has_exo: false,
      [name]: event.target.checked,
    });
    fetchBreeds(VARIABLES.PET_TYPE_VALUE[name]);
  };

  // Obtener las razas de perros del servidor
  const fetchBreeds = async (petTypeId) => {
    setMessage('');
    setMessageVariant('');
    setMessageState(false);
    try {
      const response = await fetch(SERVICES.GET_BREEDS + petTypeId, OPTIONS.GET());
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        setBreeds(data.breeds);
      } else {
        setMessage('Error al obtener las razas de las mascotas');
        setMessageVariant('error');
        setMessageState(true);
      }
    } catch (error) {
      setMessage('Error: ' + error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  // Manejo de datos para enviar
  const handleSubmit = (e) => {
    e.preventDefault();
    var mydate = new Date(birthdate.value);
    var year = mydate.getFullYear();
    var month = mydate.getMonth();
    var day = mydate.getDate();
    var customDate = year + '-' + (month + 1) + '-' + (day + 1);

    let pet_size = 1;
    if (size.dog_big) {
      pet_size = 2;
    } else if (size.dog_medium) {
      pet_size = 1;
    } else {
      pet_size = 0;
    }
    let type_pet = 1;
    if (typePet.has_dogs) {
      type_pet = 1;
    } else if (typePet.has_cats) {
      type_pet = 2;
    } else if (typePet.has_rabbits) {
      type_pet = 3;
    } else if (typePet.has_exo) {
      type_pet = 4;
    }
    fetchCreatePet(customDate, pet_size, type_pet);
  };

  const fetchCreatePet = (customDate, pet_size, type_pet) => {
    setMessage('');
    setMessageState(false);
    const body = {
      id: id,
      pet_id: editId,
      name: name.value,
      breed_id: breed.value,
      pet_type: type_pet,
      birth_date: customDate,
      pet_size: pet_size,
      description: description.value,
    };
    sendUserData(SERVICES.REGISTER_PET, OPTIONS.POST(body));
  };

  // Enviar datos de la mascota al servidor
  const sendUserData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        setMessage('Mascota Creada');
        setMessageVariant('success');
        setMessageState(true);
        handleNext(data.pet.id);
      } else {
        setMessage(data.message);
        setMessageVariant('warning');
        setMessageState(true);
      }
    } catch (error) {
      setMessage(error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <div className="step-new-pet-info">
      <h1>Cuéntanos un poco sobre tu mascota</h1>
      <div className="new-pet-info-form">
        <form onSubmit={handleSubmit}>
          <h3>Nombre</h3>
          <TextField
            id="name"
            name="name"
            required
            fullWidth
            variant="outlined"
            type="text"
            value={name.value}
            onChange={name.onChange}
          />
          <h3>¿Qué tipo de mascota?</h3>
          <div className="new-pet-info-form__type-pet">
            <FormGroup row className="form-has-pets">
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<DogIcon />}
                    checkedIcon={<DogIcon setColor="accent" />}
                    checked={typePet.has_dogs}
                    onChange={handleTypePetChange('has_dogs')}
                    value="has_dogs"
                    color="primary"
                  />
                }
                label="Perros"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CatIcon />}
                    checkedIcon={<CatIcon setColor="accent" />}
                    checked={typePet.has_cats}
                    onChange={handleTypePetChange('has_cats')}
                    value="has_cats"
                    color="primary"
                  />
                }
                label="Gatos"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<RabbitIcon />}
                    checkedIcon={<RabbitIcon setColor="accent" />}
                    checked={typePet.has_rabbits}
                    onChange={handleTypePetChange('has_rabbits')}
                    value="has_rabbits"
                    color="primary"
                  />
                }
                label="Conejos"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<ExoIcon />}
                    checkedIcon={<ExoIcon setColor="accent" />}
                    checked={typePet.has_exo}
                    onChange={handleTypePetChange('has_exo')}
                    value="has_exo"
                    color="primary"
                  />
                }
                label="Exóticos"
                labelPlacement="bottom"
              />
            </FormGroup>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h3>Raza</h3>
              <TextField
                select
                required
                fullWidth
                variant="outlined"
                id="breed"
                value={breed.value}
                onChange={breed.onChange}>
                {breeds.map((breed) => (
                  <MenuItem key={breed.id} value={breed.id}>
                    {breed.breed}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <h3 className="cortar">Fecha de nacimiento</h3>
              <TextField
                required
                fullWidth
                variant="outlined"
                id="birth_date"
                name="birth_date"
                type="date"
                value={birthdate.value}
                onChange={birthdate.onChange}
              />
            </Grid>
          </Grid>
          <h3>Tamaño</h3>
          <div className="new-pet-info-form__size-list">
            <FormGroup row className="form-size-pet">
              <FormControlLabel
                className="dog-small"
                control={
                  <Checkbox
                    checked={size.dog_small}
                    onChange={handleCheckboxChange('dog_small')}
                    icon={<Dog />}
                    checkedIcon={<Dog setColor="accent" />}
                    name="dog_small"
                    color="primary"
                  />
                }
                label="Pequeño"
                labelPlacement="bottom"
              />
              <FormControlLabel
                className="dog-medium"
                control={
                  <Checkbox
                    checked={size.dog_medium}
                    onChange={handleCheckboxChange('dog_medium')}
                    icon={<Dog />}
                    checkedIcon={<Dog setColor="accent" />}
                    name="dog_medium"
                    color="primary"
                  />
                }
                label="Mediano"
                labelPlacement="bottom"
              />
              <FormControlLabel
                className="dog-big"
                control={
                  <Checkbox
                    checked={size.dog_big}
                    onChange={handleCheckboxChange('dog_big')}
                    icon={<Dog />}
                    checkedIcon={<Dog setColor="accent" />}
                    name="dog_big"
                    color="primary"
                  />
                }
                label="Grande"
                labelPlacement="bottom"
              />
            </FormGroup>
          </div>
          <h3>Descripción</h3>
          <TextField
            id="description"
            name="description"
            required
            fullWidth
            variant="outlined"
            type="text"
            multiline
            rows="3"
            value={description.value}
            onChange={description.onChange}
          />
          <button type="submit" className="btn-primary medium btn-step">
            Guardar y Continuar
          </button>
        </form>
      </div>
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default StepNewPetInfo;
