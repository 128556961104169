import React, { useState, useEffect, useContext } from 'react';
import './adminHousers.scss';
// Services
import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
// Components
import Notification from '@root/components/notification/Notification';
import SearchInput from '@root/components/SearchInput/SearchInput';
import { FilterSelection, TableHousers, Workbook } from './components';
// Hooks
import useNotificationValue from '@root/hooks/useNotificationValue';
import useSearchUsers from '@root/hooks/useSearchUsers';
import useFilterHouser from '@root/hooks/useFilterHouser';
import { Context } from '@root/Context';
import { Button } from '@material-ui/core';
import DialogSendMessage from './components/DialogSendMessage';

function AdminHousers() {
  const { cookies } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  // Variables del componente 'Notification'
  const notification = useNotificationValue();

  // Variables de los Usuarios
  const [users, setUsers] = useState([]);
  const { values, handleValuesChange, filteredHousers } = useFilterHouser(users);
  const { query, setQuery, filteredUsers } = useSearchUsers(filteredHousers);

  const fetchCuidadores = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(SERVICES.GET_ALL_HOUSERS, OPTIONS.GET());
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setLoading(false);
        setUsers(data.users.rows);
      } else {
        notification.handleSnackbar('Error de conexión con el servidor', 'error');
        setError(data?.message);
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
      setError(error.message);
    }
  };

  const handleButtonSendMessage = () => {
    setOpenDialog(true);
  };

  const handleCloseDialogSendMessage = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchCuidadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <div className="dh-admin-housers">
        <div className="dh-admin-housers__section-header">
          <h2 className="admin-title">Cuidadores</h2>
        </div>
        <div className="margin-bottom-x1">
          <h4 className="admin-title">Error: {error}</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="dh-admin-housers">
      <div className="dh-admin-housers__section-header">
        <h2 className="admin-title">Cuidadores</h2>
      </div>
      <Workbook data={filteredUsers} />
      <div className="dh-admin-housers__options-housers">
        <FilterSelection values={values} handleValuesChange={handleValuesChange} />
        <div className="dh-admin-housers__options-housers__right">
          <SearchInput
            text="Busca un Cuidador"
            query={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <Button
            className="dh-admin-housers__options-housers__right__button"
            size="large"
            color="primary"
            variant="contained"
            onClick={handleButtonSendMessage}>
            Envoyer le message
          </Button>
        </div>
      </div>
      <div className="dh-admin-housers__table-housers">
        <p className="dh-admin-housers__table-housers--count-housers">
          {filteredUsers.length} Usuarios encontrados
        </p>
        <TableHousers users={filteredUsers} loading={loading} />
      </div>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
      <DialogSendMessage
        cookies={cookies}
        users={filteredUsers}
        open={openDialog}
        handleClose={handleCloseDialogSendMessage}
      />
    </div>
  );
}

export default AdminHousers;
