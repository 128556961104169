import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import './cardServiceExtra.scss'
// Constants
import * as ROUTES from 'constants/routes'
// DogHouser Icons
import ArrowIcon from 'assets/icons/ArrowIcon'

const CardServiceExtra = props => {
  const { text, subText, image, pathName } = props

  return (  
    <RouterLink className="dh-no-decoration" to={pathName}>
      <div className="dh-card-service-extra" style={{ backgroundImage: `linear-gradient(rgba(63, 63, 63, 0.6), rgba(0, 0, 0, 0.6)),url(${image})`}}>
        <div className="dh-card-service-extra__info">
          <h3>{text}</h3>
        </div>
        <div className="dh-card-service-extra__action">
          <div className="dh-card-service-extra__action__name">
            <h3>Ver más</h3>
            <p>{subText}</p>
          </div>
          <div className="dh-card-service-extra__action__arrow">
            <ArrowIcon setColor="white"/>
          </div>
        </div>
      </div>
    </RouterLink>
  )
}

CardServiceExtra.defaultProps = {
  text: '',
  subText: '',
  image: '',
  pathName: ROUTES.HOME
};

CardServiceExtra.propTypes = {
  text: PropTypes.string.isRequired,
  subText: PropTypes.string,
  image: PropTypes.string.isRequired,
  pathName: PropTypes.string
}

export default CardServiceExtra
