import React from 'react'
import { Link as RouterLink } from "react-router-dom";
import './allServices.scss'
// Constants
import * as ROUTES from 'constants/routes';
// Components
import ManageLocation from 'components/manageLocation';
import CardService from 'components/cardService';
import CardServiceExtra from 'components/cardServiceExtra';
// Material-UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// Images
import ImgCompartido from 'assets/images/servicios/servicio-hospedaje-compartido.jpg';
import ImgExclusivo from 'assets/images/servicios/servicio-hospedaje-exclusivo.jpg';
import ImgDelivery from 'assets/images/servicios/servicio-delivery-houser.jpg';
import ImgPaseo from 'assets/images/servicios/servicio-paseo.jpg';
import BannerPetFriendly from 'assets/images/banner-petfriendly.jpeg';

function AllServices() {
  return (
    <div className="dh-all-services">
      <div className="dh-divider"/>
      <Container maxWidth="lg" className="list-services">
        <ManageLocation/>
        <h2 className="list-services__title">¿Qué tipo de cuidado te gustaría para tu mascota?</h2>
        <Grid container spacing={3}>
          <CardService
            title="Hospedaje Compartido"
            text="Hay más perritos en casa para jugar"
            image={ImgCompartido}
            pathName={ROUTES.LISTA_CUIDADORES}
            typeService="compartida"
            size={3}
          />
          <CardService
            title="Hospedaje Exclusivo"
            text="Personalizado solo para tu mascota."
            image={ImgExclusivo}
            pathName={ROUTES.LISTA_CUIDADORES}
            typeService="exclusiva"
            size={3}
          />
          <CardService
            title="Delivery Houser"
            text="Un Cuidador va a cuidarlo a tu casa."
            image={ImgDelivery}
            pathName={ROUTES.LISTA_SERVICIOS_DELIVERY}
            typeService=""
            size={3}
          />
          <CardService
            title="Paseos y Pet Parking"
            text="Un paseador cuando lo necesites."
            image={ImgPaseo}
            pathName={ROUTES.LISTA_SERVICIOS_PASEOS}
            typeService=""
            size={3}
          />
        </Grid>
        <div className="dh-section-more margin-top-x2 margin-bottom-x1">
          <div className="dh-blue-dog"/>
          <h3 className="margin-left-x1">Visita tus lugares petFriendly</h3>
        </div>
        <CardServiceExtra
          text="Encuentra una lista de sitios que acepten a tus mascotas"
          subText="Estancias y experencias"
          image={BannerPetFriendly}
          pathName={ROUTES.SERVICIOS_PETFRIENDLY}
        />
      </Container>
      <div className="dh-odi-hero">
        <div className="dh-odi-field">
          <h2 className="dh-text-odi">Descubre como escogemos a nuestros cuidadores</h2>
          <RouterLink to={ROUTES.SERVICIOS_CUIDADORES}>
            <button className="btn-default medium">Más información</button>
          </RouterLink>
        </div>
      </div>
    </div>
  )
}

export default AllServices
