import React, { useState, useEffect } from 'react';
import './AdminPromotionNew.scss';
//Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Components
import TitleArrowBack from '@root/components/TitleBack';
import Notification from 'components/notification';

import PromotionData from 'components/PromotionData';

function AdminPromotionNew(props) {
  // Variables del componente "Notification"
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  // Funcion para cerrar el Componente 'Notification'
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  const [promotion, setPromotion] = useState({
    cod: '',
    description: '',
    type: 0,
    value: 5,
    used: 0,
  });

  function makeid(length) {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    setPromotion((prev) => ({
      ...prev,
      cod: makeid(7),
    }));
  }, []);

  const handleBackClick = () => {
    props.history.goBack();
  };
  const handlePromotionChange = (name, event) => {
    const { target } = event;
    setPromotion((prev) => ({
      ...prev,
      [name]: target.value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchCreatePromotion();
  };
  const fetchCreatePromotion = async () => {
    let successMessage = 'Promocion creado correactamente';
    try {
      const response = await fetch(
        SERVICES.CREATE_PROMOS,
        OPTIONS.POST({
          cod: promotion.cod,
          value: promotion.value,
          description: promotion.description,
        }),
      );
      // eslint-disable-next-line no-unused-vars
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        setMessage(successMessage);
        setMessageVariant('success');
        setMessageState(true);
      } else {
        setMessage(data.message);
        setMessageVariant('error');
        setMessageState(true);
      }
    } catch (error) {
      setMessage('Error en los datos: ' + error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };
  return (
    <div>
      <div className="margin-bottom-x15">
        <TitleArrowBack handleClick={handleBackClick}>
          <h2 className="admin-title">Volver</h2>
        </TitleArrowBack>
      </div>
      <PromotionData
        handleSubmit={handleSubmit}
        promotion={promotion}
        handlePromotionChange={handlePromotionChange}
        title={'Crear Promocion'}
      />
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default AdminPromotionNew;
