import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Context from '@root/Context';
import ClientRoute from './ClientRoute';
import ClientPrivateRoute from './ClientPrivateRoute';
// Constans
import * as ROUTES from '@root/constants/routes';
// Layouts
import LayoutIngresoCliente from '@root/layout/layoutIngresoCliente';
import LayoutAdmin from '@root/layout/layoutAdmin';
import LayoutHouser from '@root/layout/layoutHouser';
// Generic Views
import NoMatch from '@root/views/NotFound';
import Construction from '@root/views/construction';
import BookReclamations from '@root/views/bookReclamations';
import Terminos from '@root/views/terminos';
import Products from '@root/views/products';
import Faqs from '@root/views/pages/Faqs';
// Inicios de Sesion View
import Login from '@root/views/login';
import LostPassword from '@root/views/lostPassword';
import NewPassword from '@root/views/newPassword';
// Registro de Usuarios View
import RegisterClient from '@root/views/registerClient';
import RegisterSocialClient from '@root/views/registerSocialClient';
import RegisterHouser from '@root/views/registerHouser';
import RegisterSocialHouser from '@root/views/registerSocialHouser';
import RegisterSteps from '@root/views/registerSteps';
import ValidatePhone from '@root/views/validatePhone';

// Mascotas View
import NewPetSteps from '@root/views/newPetSteps';
import PetDetail from '@root/views/pet/PetDetail';

//Client Views
import Home from '@root/views/home';
import AccountSettings from '@root/views/user/AccountSettings';
import ClientReservations from '@root/views/ClientReservations';
import ClientReservationsDetail from '@root/views/ClientReservationsDetail';

import AllServices from '@root/views/allServices';
import HouserList from '@root/views/houserList';
import HouserDetail from '@root/views/houserDetail';
import ServicesWalks from '@root/views/servicesWalks';
import ServiceDelivery from '@root/views/serviceDelivery';
import ServicePetFriendly from '@root/views/servicePetFriendly';
import PetFriendlyDetail from '@root/views/PetFriendlyDetail';
import Onboarding from '@root/views/onboarding';

// Houser Views
import DashBoard from '@root/views/dashboard';
import CuidadorReservas from '@root/views/cuidadorReservas';
import CuidadorReservasDetalle from '@root/views/CuidadorReservasDetalle';
import CuidadorCalendario from '@root/views/cuidadorCalendario';
import CuidadorPublicacion from '@root/views/cuidadorPublicacion';
import CuidadorNotificaciones from '@root/views/cuidadorNotificaciones';
import CuidadorAyuda from '@root/views/cuidadorAyuda';
import CuidadorFotos from '@root/views/cuidadorFotos';
import CuidadorServicios from '@root/views/cuidadorServicios';
import CuidadorPublicacionInfo from '@root/views/cuidadorPublicacionInfo';
import CuidadorUbicacion from '@root/views/cuidadorUbicacion';
import CuidadoresInfo from '@root/views/cuidadoresInfo';

// Booking Views
import BookingRequest from '@root/views/booking/Request';
import BookingMessage from '@root/views/booking/Message';
import BookingCheckout from '@root/views/booking/Checkout';
import CheckoutRequest from '@root/views/booking/Checkout/CheckoutConfirm';
import SingleChat from '@root/views/messages/SingleChat';

// Admin Views
import { AdminClientList, AdminHome, AdminLogin } from '@root/views/admin';
import AdminClientDetail from '@root/views/adminClientDetail';
import AdminHouserList from '@root/views/AdminHouserList';
import AdminHouserDetail from '@root/views/adminHouserDetail';
import AdminHouserDetailBasicInfo from '@root/views/adminHouserDetailBasicInfo';
import AdminProviders from '@root/views/adminProviders';
import AdminServices from '@root/views/adminServices';
import AdminServiceNew from '@root/views/adminServiceNew';
import AdminServiceDetail from '@root/views/adminServiceDetail';
import AdminReservations from '@root/views/adminReservations';
import AdminReservationDetail from '@root/views/adminReservationDetail';
import AdminPlaces from '@root/views/adminPlaces';
import AdminNewPlace from '@root/views/adminNewPlace';
import AdminEditPlace from '@root/views/adminEditPlace';
import AdminPromotion from '@root/views/adminPromotion';
import AdminPromotionNew from '@root/views/adminPromotionNew';

function router() {
  return (
    <BrowserRouter>
      <Switch>
        {/* Vistas Generales */}
        <ClientRoute exact path={ROUTES.HOME} component={Home} />
        <Route exact path={ROUTES.CONSTRUCTION} component={Construction} />
        <ClientRoute exact path={ROUTES.LIBRO_RECLAMACIONES} component={BookReclamations} />
        <ClientRoute exact path={ROUTES.TERMINOS} component={Terminos} />
        <ClientRoute exact path={ROUTES.FREQUENT_QUESTIONS} component={Faqs} />
        <Route exact path={ROUTES.PRODUCTOS} component={Products} />
        {/* Vistas Inicio de Sesión */}
        <IngresoCliente exact path={ROUTES.LOGIN} component={Login} />
        <ClientRoute exact path={ROUTES.PASSWORD_FORGET} component={LostPassword} />
        <ClientRoute exact path={ROUTES.NEW_PASSWORD} component={NewPassword} />
        {/* Vistas Mascotas */}
        <ClientPrivateRoute exact path={ROUTES.CREAR_MASCOTA} component={NewPetSteps} />
        <ClientPrivateRoute exact path={ROUTES.EDIT_MASCOTA} component={NewPetSteps} />
        <ClientRoute exact path={ROUTES.MASCOTA_DETAIL} component={PetDetail} />
        {/* Vistas Registro */}
        <IngresoCliente
          exact
          path={ROUTES.REGISTER_CLIENTE_CORREO}
          component={RegisterClient}
        />
        <IngresoCliente exact path={ROUTES.REGISTER_CLIENTE} component={RegisterSocialClient} />
        <Route exact path={ROUTES.REGISTER_CUIDADOR} component={RegisterSocialHouser} />
        <Route exact path={ROUTES.REGISTER_CUIDADOR_CORREO} component={RegisterHouser} />
        <Route exact path={ROUTES.REGISTER_CUIDADOR_STEP} component={RegisterSteps} />
        <ClientPrivateRoute exact path={ROUTES.VALIDATE_PHONE} component={ValidatePhone} />
        {/* Vistas Publicas para Clientes*/}
        <ClientRoute exact path={ROUTES.LISTA_SERVICIOS} component={AllServices} />
        <ClientRoute exact path={ROUTES.LISTA_SERVICIOS_PASEOS} component={ServicesWalks} />
        <ClientRoute exact path={ROUTES.LISTA_SERVICIOS_DELIVERY} component={ServiceDelivery} />
        <ClientRoute exact path={ROUTES.SERVICIOS_PETFRIENDLY} component={ServicePetFriendly} />
        <ClientRoute exact path={ROUTES.PETFRIENDLY_DETAIL} component={PetFriendlyDetail} />
        <ClientRoute exact path={ROUTES.SERVICIOS_CUIDADORES} component={CuidadoresInfo} />
        <ClientRoute exact path={ROUTES.LISTA_CUIDADORES} component={HouserList} />
        <ClientRoute exact path={ROUTES.CUIDADOR_DETAIL} component={HouserDetail} />
        <ClientRoute exact path={ROUTES.ONBOARDING} component={Onboarding} />
        {/* Booking */}
        <ClientRoute exact path={ROUTES.BOOKING_REQUEST} component={BookingRequest} />
        <ClientRoute exact path={ROUTES.BOOKING_MODIFY} component={BookingRequest} />
        <ClientRoute exact path={ROUTES.BOOKING_MESSAGE} component={BookingMessage} />
        <ClientRoute exact path={ROUTES.BOOKING_CHECKOUT} component={BookingCheckout} />
        <ClientRoute exact path={ROUTES.BOOKING_CHECKOUT_CONFIRM} component={CheckoutRequest} />
        <ClientRoute exact path={ROUTES.MESSAGE_SINGLE} component={SingleChat} />
        {/* Vistas Cliente */}
        <ClientPrivateRoute exact path={ROUTES.CLIENTE_PERFIL} component={AccountSettings} />
        <ClientPrivateRoute exact path={ROUTES.CLIENTE_MASCOTAS} component={AccountSettings} />
        <ClientPrivateRoute
          exact
          path={ROUTES.CLIENTE_RESERVATIONS}
          component={ClientReservations}
        />
        <ClientPrivateRoute
          exact
          path={ROUTES.CLIENTE_RESERVATIONS_DETAIL}
          component={ClientReservationsDetail}
        />
        {/* Vistas Cuidador */}
        <HouserPrivateRoute exact path={ROUTES.CUIDADOR_DASHBOARD} component={DashBoard} />
        <HouserPrivateRoute exact path={ROUTES.CUIDADOR_PERFIL} component={AccountSettings} />
        <HouserPrivateRoute exact path={ROUTES.CUIDADOR_MASCOTAS} component={AccountSettings} />
        <HouserPrivateRoute
          exact
          path={ROUTES.CUIDADOR_RESERVAS}
          component={CuidadorReservas}
        />
        <HouserPrivateRoute
          exact
          path={ROUTES.CUIDADOR_RESERVA_DETALLE}
          component={CuidadorReservasDetalle}
        />
        <HouserPrivateRoute
          exact
          path={ROUTES.CUIDADOR_CALENDARIO}
          component={CuidadorCalendario}
        />
        <HouserPrivateRoute
          exact
          path={ROUTES.CUIDADOR_PUBLICACION}
          component={CuidadorPublicacion}
        />
        <HouserPrivateRoute
          exact
          path={ROUTES.CUIDADOR_PUBLICACION_FOTOS}
          component={CuidadorFotos}
        />
        <HouserPrivateRoute
          exact
          path={ROUTES.CUIDADOR_PUBLICACION_SERVICIOS}
          component={CuidadorServicios}
        />
        <HouserPrivateRoute
          exact
          path={ROUTES.CUIDADOR_PUBLICACION_INFO}
          component={CuidadorPublicacionInfo}
        />
        <HouserPrivateRoute
          exact
          path={ROUTES.CUIDADOR_PUBLICACION_UBICACION}
          component={CuidadorUbicacion}
        />
        <HouserPrivateRoute
          exact
          path={ROUTES.CUIDADOR_NOTIFICACION}
          component={CuidadorNotificaciones}
        />
        <HouserPrivateRoute exact path={ROUTES.CUIDADOR_AYUDA} component={CuidadorAyuda} />
        {/* Vistas del Admin */}
        <Route exact path={ROUTES.ADMIN_LOGIN} component={AdminLogin} />
        <AdminPrivateRoute exact path={ROUTES.ADMIN_DASHBOAR} component={AdminHome} />
        <AdminPrivateRoute exact path={ROUTES.ADMIN_CLIENTES} component={AdminClientList} />
        <AdminPrivateRoute
          exact
          path={ROUTES.ADMIN_CLIENTES_ID}
          component={AdminClientDetail}
        />
        <AdminPrivateRoute exact path={ROUTES.ADMIN_CUIDADORES} component={AdminHouserList} />
        <AdminPrivateRoute
          exact
          path={ROUTES.ADMIN_CUIDADORES_ID}
          component={AdminHouserDetail}
        />
        <AdminPrivateRoute
          exact
          path={ROUTES.ADMIN_CUIDADORES_ID_UBICACION}
          component={CuidadorUbicacion}
        />
        <AdminPrivateRoute
          exact
          path={ROUTES.ADMIN_CUIDADORES_ID_BASIC_INFO}
          component={AdminHouserDetailBasicInfo}
        />
        <AdminPrivateRoute
          exact
          path={ROUTES.ADMIN_CUIDADORES_ID_SERVICIOS}
          component={CuidadorServicios}
        />
        <AdminPrivateRoute
          exact
          path={ROUTES.ADMIN_CUIDADORES_ID_PUBLICACION}
          component={CuidadorPublicacionInfo}
        />
        <AdminPrivateRoute
          exact
          path={ROUTES.ADMIN_CUIDADORES_ID_FOTOS}
          component={CuidadorFotos}
        />
        <AdminPrivateRoute exact path={ROUTES.ADMIN_PROVIDERS} component={AdminProviders} />
        <AdminPrivateRoute exact path={ROUTES.ADMIN_SERVICES} component={AdminServices} />
        <AdminPrivateRoute exact path={ROUTES.ADMIN_SERVICES_NEW} component={AdminServiceNew} />
        <AdminPrivateRoute
          exact
          path={ROUTES.ADMIN_SERVICE_DETAIL}
          component={AdminServiceDetail}
        />
        <AdminPrivateRoute
          exact
          path={ROUTES.ADMIN_RESERVATIONS}
          component={AdminReservations}
        />
        <AdminPrivateRoute
          exact
          path={ROUTES.ADMIN_RESERVATION_DETAIL}
          component={AdminReservationDetail}
        />
        <AdminPrivateRoute exact path={ROUTES.ADMIN_PLACES} component={AdminPlaces} />
        <AdminPrivateRoute exact path={ROUTES.ADMIN_PLACES_CREATE} component={AdminNewPlace} />
        <AdminPrivateRoute exact path={ROUTES.ADMIN_PLACES_EDIT} component={AdminEditPlace} />
        <AdminPrivateRoute exact path={ROUTES.ADMIN_PROMOTIONS} component={AdminPromotion} />
        <AdminPrivateRoute
          exact
          path={ROUTES.ADMIN_PROMOTIONS_NEW}
          component={AdminPromotionNew}
        />
        <Redirect from="/admin" to={ROUTES.ADMIN_LOGIN} />
        {/* when none of the above match, <NoMatch> will be rendered */}
        <Route component={NoMatch} />
      </Switch>
    </BrowserRouter>
  );
}

function IngresoCliente({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <LayoutIngresoCliente>
          <Component {...props} />
        </LayoutIngresoCliente>
      )}
    />
  );
}

function HouserPrivateRoute({ component: Component, ...rest }) {
  return (
    <Context.Consumer>
      {({ isAuth, houser, statusHouser }) => {
        if (isAuth && houser) {
          if (statusHouser) {
            return (
              <Route
                {...rest}
                render={(props) => (
                  <LayoutHouser>
                    <Component {...props} />
                  </LayoutHouser>
                )}
              />
            );
          } else {
            return (
              <Route
                {...rest}
                render={(props) => (
                  <Redirect
                    to={{
                      pathname: ROUTES.REGISTER_CUIDADOR_STEP,
                      state: { from: props.location },
                    }}
                  />
                )}
              />
            );
          }
        } else {
          return (
            <Route
              {...rest}
              render={(props) => (
                <Redirect
                  to={{
                    pathname: ROUTES.LOGIN,
                    state: { from: props.location },
                  }}
                />
              )}
            />
          );
        }
      }}
    </Context.Consumer>
  );
}

function AdminPrivateRoute({ component: Component, ...rest }) {
  return (
    <Context.Consumer>
      {({ isAuth, admin }) => {
        if (isAuth && admin) {
          return (
            <Route
              {...rest}
              render={(props) => (
                <LayoutAdmin>
                  <Component {...props} />
                </LayoutAdmin>
              )}
            />
          );
        } else {
          return (
            <Route
              {...rest}
              render={(props) => (
                <Redirect
                  to={{
                    pathname: ROUTES.ADMIN_LOGIN,
                    state: { from: props.location },
                  }}
                />
              )}
            />
          );
        }
      }}
    </Context.Consumer>
  );
}

export default router;
