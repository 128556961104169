import React from 'react';
import { Route } from 'react-router-dom';

import LayoutClient from 'layout/layoutClient';

const ClientRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <LayoutClient>
          <Component {...props} />
        </LayoutClient>
      )}
    />
  );
};

export default ClientRoute;
