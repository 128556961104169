import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from "react-router-dom"
// Constants
import * as VARIABLES from 'constants/variables';
// Material UI
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'var(--white)',
    color: 'var(--textColor)',
    maxWidth: 220,
    fontFamily: 'Montserrat-Medium',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: 'box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
  },
}))(Tooltip)

const MarkerDesktop = props => {
  const { id, city, country, adress, schedule, title, photo, price } = props

  return (
    <RouterLink className="dh-no-decoration" to={`/servicios/petfriendly/${id}/${country}/${city}`}>
      <HtmlTooltip title={
        <React.Fragment>
          <div className="dh-card-petfriendly-map">
            <div className="dh-card-petfriendly-map__cover-image">
              <img
                alt={title} 
                src={photo ? `${VARIABLES.IMAGE_URL}${photo}` : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
              />
            </div>
            <div className="dh-card-petfriendly-map__detail">
              <h3>{title}</h3>
                <span>{adress}</span>
                <div className="dh-card-petfriendly-map__detail__contain">
                  <p>Precio : <span>{price==='-' ? 'Sin definir' : price}</span></p>
                  <p>Horario: <span>{schedule=== '-' ? 'Sin definir' : schedule}</span></p>
              </div>
            </div>
          </div>
        </React.Fragment>
      }>
        <div className="pin" key={id}/>
      </HtmlTooltip>
      <div className='pulse'/>
    </RouterLink>
  )
}

MarkerDesktop.propTypes = {
  id: PropTypes.number.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  adress: PropTypes.string,
  schedule: PropTypes.string,
  title: PropTypes.string,
  photo: PropTypes.string,
  price: PropTypes.string
}

export default MarkerDesktop
