import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

import { TypeServiceType } from '@root/models/ServiceDTO';
import TimePicker from '@root/components/TimePicker';
import styles from './options.module.scss';

interface OptionsType {
  serviceActive?: any;
  typeServiceActive?: TypeServiceType;
  dropDate: Date;
  setDropDate: (dropDate: Date) => void;
  pickupDate: Date;
  setPickupDate: (pickupDate: Date) => void;
}

const Options = (props: OptionsType) => {
  const { serviceActive, typeServiceActive, dropDate, setDropDate, pickupDate, setPickupDate } =
    props;

  const handleDropChange = (date: Date) => {
    setDropDate(date);
    typeServiceActive === 3 && setPickupDate(date);
  };

  const handleDropTime = (time: string) => {
    const dateInp = moment(dropDate).format('MM/DD/YYYY') + ' ' + time;
    const newDate = moment(dateInp, 'MM/DD/YYYY hh:mm A').toDate();
    setDropDate(newDate);
  };

  const handlePickupChange = (date: Date) => {
    setPickupDate(date);
  };

  const handlePickupTime = (time: string) => {
    const dateInp = moment(pickupDate).format('MM/DD/YYYY') + ' ' + time;
    const newDate = moment(dateInp, 'MM/DD/YYYY hh:mm A').toDate();
    setPickupDate(newDate);
  };

  return (
    <div className={styles.root}>
      {(typeServiceActive === 1 || typeServiceActive === 2) && (
        <>
          <div>
            <div className={styles.label}>Fecha de entrada</div>
            <div className={styles.space}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                id="date-picker-inline"
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={dropDate}
                onChange={handleDropChange}
                minDate={new Date()}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <TimePicker
                defaultValue={moment(dropDate).format('hh:mm A')}
                onChange={handleDropTime}
              />
            </div>
          </div>
          <div>
            <div className={styles.label}>Fecha de salida</div>
            <div className={styles.space}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                id="date-picker-inline"
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={pickupDate}
                onChange={handlePickupChange}
                minDate={dropDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <TimePicker
                defaultValue={moment(pickupDate).format('hh:mm A')}
                onChange={handlePickupTime}
              />
            </div>
          </div>
        </>
      )}
      {typeServiceActive === 3 && (
        <>
          {serviceActive === 20 && (
            <div>
              <div className={styles.label}>Fecha</div>
              <div className={styles.space}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  id="date-picker-inline"
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  value={dropDate}
                  onChange={handleDropChange}
                  minDate={new Date()}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </div>
            </div>
          )}
          <div>
            <div className={styles.label}>Horario (Como máximo 10 horas diurnas)</div>
            <div className={styles.space}>
              <TimePicker
                defaultValue={moment(dropDate).format('hh:mm A')}
                onChange={handleDropTime}
              />
              <TimePicker
                defaultValue={moment(pickupDate).format('hh:mm A')}
                onChange={handlePickupTime}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Options;
