import { Grid } from '@material-ui/core';
import { IconMoon, IconSun } from '@tabler/icons';
import clsx from 'clsx';

import { TypeServiceType } from '@root/models/ServiceDTO';
import { ServiceOptionType } from '@root/utils/mutateHouserServices';
import styles from './services.module.scss';

const variantIcon = {
  1: IconMoon,
  2: IconMoon,
  3: IconSun,
};

interface ServiceTypeProps {
  serviceOption: ServiceOptionType;
  typeServiceActive: TypeServiceType;
  setTypeServiceActive: (typeService: TypeServiceType) => void;
  setDropDate?: (dropDate: Date) => void;
  setPickupDate?: (pickupDate: Date) => void;
}

const ServiceType = (props: ServiceTypeProps) => {
  const { serviceOption, typeServiceActive, setTypeServiceActive, setDropDate, setPickupDate } =
    props;
  const isActive = typeServiceActive === serviceOption.id;

  const cl = clsx(styles.item, { [styles.active]: isActive });
  const Icon = variantIcon[serviceOption.id];

  const handleClick = () => {
    if (serviceOption.id === 3) {
      setDropDate(new Date());
      setPickupDate(new Date());
    }
    setTypeServiceActive(serviceOption.id);
  };

  return (
    <Grid item xs>
      <div className={cl} onClick={handleClick}>
        <div className={styles.wrap}>
          <Icon />
          <span className={styles.label}>{serviceOption.name}</span>
        </div>
        {isActive && <span className={styles.helper}>{serviceOption.helper}</span>}
      </div>
    </Grid>
  );
};

export default ServiceType;
