// Paises
export const country = [
  {
    value: 'Colombia',
    label: 'Colombia',
  },
  // {
  //   value: 'Chile',
  //   label: 'Chile',
  // },
  {
    value: 'España',
    label: 'España',
  },
  {
    value: 'Perú',
    label: 'Perú',
  },
];
// Ciudades de Peru
export const cityPeru = [
  // {
  //   value: 'Arequipa',
  //   label: 'Arequipa',
  // },
  // {
  //   value: 'Callao',
  //   label: 'Callao',
  // },
  // {
  //   value: 'Cusco',
  //   label: 'Cusco',
  // },
  {
    value: 'Lima',
    label: 'Lima',
  },
  // {
  //   value: 'Trujillo',
  //   label: 'Trujillo',
  // },
  // {
  //   value: 'Piura',
  //   label: 'Piura',
  // },
];
// Ciudades de Colombia
export const cityColombia = [
  {
    value: 'Barranquilla',
    label: 'Barranquilla',
  },
  {
    value: 'Bello',
    label: 'Bello',
  },
  {
    value: 'Bogota',
    label: 'Bogota',
  },
  {
    value: 'Cali',
    label: 'Cali',
  },
  {
    value: 'Cartagena',
    label: 'Cartagena',
  },
  {
    value: 'Envigado',
    label: 'Envigado',
  },
  {
    value: 'Itaguí',
    label: 'Itaguí',
  },
  {
    value: 'Medellín',
    label: 'Medellín',
  },
  {
    value: 'Santa Martha',
    label: 'Santa Martha',
  },
];
// Ciudades de España
export const cityEspana = [
  {
    value: 'Barcelona',
    label: 'Barcelona',
  },
  {
    value: 'Madrid',
    label: 'Madrid',
  },
]
// Ciudades de Chile
export const cityChile = [
  {
    value: 'Santiago',
    label: 'Santiago',
  },
]

// Establece una cuidad mediante el pais que se envie
export const variantCityByCountry = {
  'Perú': cityPeru,
  'Peru': cityPeru,
  'Colombia': cityColombia,
  'España': cityEspana,
  // 'Chile': cityChile,
}

// Codigos de los Paises
export const codigos = [
  {
    value: '+57',
    label: 'COL +57',
  },
  {
    value: '+56',
    label: 'CHI +56',
  },
  {
    value: '+34',
    label: 'ESP +34',
  },
  {
    value: '+51',
    label: 'PER +51',
  },
];

// Tipo de vía
export const vias = [
  {
    value: 'Jirón',
    label: 'Jirón',
  },
  {
    value: 'Calle',
    label: 'Calle',
  },
  {
    value: 'Avenida',
    label: 'Avenida',
  },
  {
    value: 'Pasaje',
    label: 'Pasaje',
  },
  {
    value: 'Carrera',
    label: 'Carrera',
  },
  {
    value: 'Paseo',
    label: 'Paseo',
  },
  {
    value: 'Otro',
    label: 'Otro',
  },
];

// Tipo de propiedad
export const tipoPropiedad = [
  {
    value: 'Casa',
    label: 'Casa',
  },
  {
    value: 'Departamento',
    label: 'Departamento',
  },
  {
    value: 'Condominio',
    label: 'Condominio',
  },
  {
    value: 'Finca/ Casa de campo',
    label: 'Finca/ Casa de campo',
  },
];

export const centerDefault = {
  lat: -12.083348,
  lng: -77.057595
}
export const centerPeru = {
  lat: -12.083348,
  lng: -77.057595
}
export const centerPeruArequipa = {
  lat: -16.404579,
  lng: -71.531026
}
export const centerPeruCallao = {
  lat: -12.058906,
  lng: -77.137262
}
export const centerPeruCusco = {
  lat: -13.526917,
  lng: -71.968641
}
export const centerPeruLima = {
  lat: -12.091824,
  lng: -77.030004
}
export const centerPeruTrujillo = {
  lat: -8.111884,
  lng: -79.029254
}
export const centerPeruPiura = {
  lat: -5.180388,
  lng: -80.667404
}
// Ubicacion Colombia
export const centerColombia = {
  lat: 4.709334,
  lng: -74.071422
};
export const centerColombiaBarranquilla = {
  lat: 10.993887,
  lng: -74.806920
};
export const centerColombiaBello = {
  lat: 6.335688,
  lng: -75.555183
};
export const centerColombiaBogota = {
  lat: 4.708262,
  lng: -74.071531
};
export const centerColombiaCali = {
  lat: 3.435432,
  lng: -76.519696
};
export const centerColombiaCartagena = {
  lat: 10.392878,
  lng: -75.489408
};
export const centerColombiaEnvigado = {
  lat: 6.166585,
  lng: -75.584271
};
export const centerColombiaItagui = {
  lat: 6.167234,
  lng: -75.617307
};
export const centerColombiaMedellín = {
  lat: 6.245752,
  lng: -75.572733
};
export const centerColombiaSantaMartha = {
  lat: 11.240020,
  lng: -74.195449
};
// Ubicacion España
export const centerEspana = {
  lat: 40.416569,
  lng: -3.704423
};
export const centerEspanaBarcelona = {
  lat: 41.397836,
  lng: 2.163534
};
export const centerEspanaMadrid = {
  lat: 40.415473,
  lng: -3.703945
}
// Ubicacion Chile
export const centerChile = {
  lat: -33.448381,
  lng: -70.668679
}
export const centerChileSantiago = {
  lat: -33.448381,
  lng: -70.668679
}

export const variantBoolean = {
  false: 0,
  true: 1,
}

// Ciudades de Peru
export const typesPlaces = [
  {
    value: 'Restaurantes',
    label: 'Restaurantes',
  },
  {
    value: 'Cafeterías',
    label: 'Cafeterías',
  },
  {
    value: 'Comercios',
    label: 'Comercios',
  }
];
