import React from 'react'
import PropTypes from 'prop-types'
import './badgeStatus.scss'
// Constants
import * as VARIABLES from 'constants/variables'

export const STYLE_STATUS = {
  1: 'state1',
  2: 'state2',
  3: 'state3',
  4: 'state4',
  5: 'state5'
}

const BadgeStatus = props => {
  const { status, endDate } = props
  let relStatus = status

  if (status === 3) {
    let fechaActual = new Date().getTime()
    let fechaRes = new Date(endDate).getTime()
    if((fechaActual + 1) > (fechaRes + 1)) {
      relStatus = 5
    }
  }

  return (
    <div className={`reservation-state ${STYLE_STATUS[relStatus]}`}>
      <span>{VARIABLES.RESERVATION_STATUS[relStatus]}</span>
    </div>
  )
}

BadgeStatus.propTypes = {
  status: PropTypes.number
}

export default BadgeStatus
