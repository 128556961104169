import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { IconX } from '@tabler/icons';
import clsx from 'clsx';

import styles from './dialogUploadImage.module.scss';

interface DialogUploadImageType {
  loading?: boolean;
  open?: boolean;
  onClose?: () => void;
  onOk?: () => void;
  imageUrl?: string;
  height?: string | number;
  width?: string | number;
}

const DialogUploadImage = (props: DialogUploadImageType) => {
  const { loading, open, onClose, onOk, imageUrl, height = 276, width = 276 } = props;

  const sl = { height: height, width: width };

  return (
    <Dialog
      fullWidth={true}
      id="modal-upload-image"
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      onClose={onClose}
      open={open}>
      <div className={styles.title} id="modal-upload-image-title">
        <h4>Actualizar foto</h4>
      </div>
      <DialogContent dividers className={styles.content}>
        <div className={clsx(styles.image, { [styles.loading]: loading })} style={sl}>
          <img src={imageUrl} alt="mascota" />
          {loading && <CircularProgress className={styles.progress} />}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={onOk}>
          {loading ? 'Subiendo Imagen...' : 'Guardar'}
        </Button>
      </DialogActions>
      <div className={styles.closeBtn}>
        <IconButton size="small" onClick={onClose}>
          <IconX size={20} />
        </IconButton>
      </div>
    </Dialog>
  );
};

export default DialogUploadImage;
