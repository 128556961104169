import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Router from '@root/router';
import theme from '@root/theme';
import { DogSnackbar } from '@root/ui-component/extended';
import '@root/styles/index.scss';

const localeMap = { es: esLocale };

const App = () => {
  // loadReCaptcha('6LcKIcIUAAAAACK8BUeBRyxy2nrontaMUsypoXk9');

  return (
    <ThemeProvider theme={theme()}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['es']}>
        <Router />
        <DogSnackbar />
      </MuiPickersUtilsProvider>
      <ToastContainer position="top-right" autoClose={4000} closeOnClick theme="colored" />
    </ThemeProvider>
  );
};

export default App;
