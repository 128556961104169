import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables';
// Hooks
import { useInputValue } from  'hooks/useInputValue';
import useNotificationValue from 'hooks/useNotificationValue';
// Components
import Notification from 'components/notification/Notification';
// Utils
import { codigos } from 'utils/Resources'
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(theme => ({
  menu: {
    width: 200,
  },
}));

function StepBasicInfo(props) {
  // Se obtine el ID de la vista padre y la funcion correspondiente
  const { id, handleNext, handleBack, activeStep } = props
  const classes = useStyles();
  // Variables de "Notification"
  const notification = useNotificationValue()
  const [user, setUser] = useState({
    user: {
      id: '',
      name: '',
      last_name: '',
      doc_id: '',
      email: '',
      phone: '',
      address: '',
      birth_date: '',
      phone_house: '',
      photo: '',
      emergency_contact_name: '',
      emergency_contact_phone: '',
    },
    houser: {
      who_live_you: '',
      why_want_houser: '',
      years_experiencie: ''
    }
  })
  const question_4 = useInputValue('')
  const [values, setValues] = useState({
    codigos: '+51',
    codigos_emegency: '+51',
  })

  const handleValueChange = name => event => {
    setValues({
      ...values, [name]: event.target.value 
    })
  }

  const handleUserInputChange = e => {
    setUser({
      user: {
        ...user.user,
        [e.target.name]: e.target.value
      },
      houser: {
        ...user.houser
      }
    })
  }

  const handleHouserInputChange = e => {
    setUser({
      user: {
        ...user.user,
      },
      houser: {
        ...user.houser,
        [e.target.name]: e.target.value
      }
    })
  }

  useEffect(() => {
    if(!user.user.id) {
      fetchUserData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user.id])

  const fetchUserData = async () => {
    let body = {
      id: id
    }
    sendUserData(SERVICES.USER_BY_ID, OPTIONS.POST(body), VARIABLES.STATE_INITIATE)
  }

  const handleSubmit = e => {
    e.preventDefault();
    var mydate = new Date(user.user.birth_date);
    var year = mydate.getFullYear()
    var month = mydate.getMonth()
    var day = mydate.getDate()
    var customDate = (month + 1) + "-" + (day + 1) + "-" + year
    // console.log(customDate)
    fetchUpdateUser(customDate)
  }

  const fetchUpdateUser = async (customDate) => {
    let body = {
      id: user.user.id,
      name: user.user.name,
      last_name: user.user.last_name,
      doc_id: user.user.doc_id.toUpperCase(),
      email: user.user.email,
      phone: user.user.phone,
      address: user.user.address,
      birth_date: customDate,
      phone_house: user.user.phone_house,
      emergency_contact_name: user.user.emergency_contact_name,
      emergency_contact_phone: user.user.emergency_contact_phone,
      who_live_you: user.houser.who_live_you,
      why_want_houser: user.houser.why_want_houser,
      years_experiencie: user.houser.years_experiencie,
    }
    sendUserData(SERVICES.UPDATE_HOUSER_STEP_1, OPTIONS.POST(body), VARIABLES.STATE_UPDATE)
  }

  const sendUserData = async (url, options, state) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (state === VARIABLES.STATE_INITIATE) {
          setUser(data)
        } else if (state === VARIABLES.STATE_UPDATE) {
          notification.handleSnackbar('Datos actualizados', 'success')
          handleNext()
        }
      } else {
        if (state === VARIABLES.STATE_INITIATE) {
          notification.handleSnackbar("Error al obtener los datos.", 'error')
        } else if (state === VARIABLES.STATE_UPDATE) {
          notification.handleSnackbar("Error al actualizar los datos", 'error')
        }
      }
    } catch (error) {
      notification.handleSnackbar('Error:' + error.message, 'error')
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nombres"
              name="name"
              autoComplete="name"
              value={user.user.name}
              onChange={handleUserInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="last_name"
              label="Apellidos"
              name="last_name"
              autoComplete="name"
              value={user.user.last_name}
              onChange={handleUserInputChange}
            />
          </Grid>
        </Grid>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          type="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={user.user.email}
          onChange={handleUserInputChange}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="doc_id"
              label="Doc. Identidad"
              type="text"
              name="doc_id"
              value={user.user.doc_id}
              onChange={handleUserInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="birth_date"
              name="birth_date"
              label="Birthday"
              type="date"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={user.user.birth_date}
              onChange={handleUserInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              id="codigos"
              select
              required
              fullWidth
              label="Cod.País"
              value={values.codigos}
              onChange={handleValueChange('codigos')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined" >
              {codigos.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Teléfono"
              type="number"
              name="phone"
              value={user.user.phone}
              onChange={handleUserInputChange}
            />
          </Grid>
        </Grid>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="text"
          multiline
          rows="3"
          label="¿Quiénes viven en tu casa?"
          id="who_live_you"
          name="who_live_you"
          autoComplete="text"
          value={user.houser.who_live_you}
          onChange={handleHouserInputChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="text"
          multiline
          rows="3"
          label="¿Por qué quieres hospedar mascotas?"
          id="why_want_houser"
          name="why_want_houser"
          value={user.houser.why_want_houser}
          onChange={handleHouserInputChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="number"
          label="Años de experiencia cuidando mascotas"
          id="years_experiencie"
          name="years_experiencie"
          value={user.houser.years_experiencie}
          onChange={handleHouserInputChange}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment position="end">Años</InputAdornment>
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="question_4"
          type="text"
          label="¿Cuál es tu disponibilidad?"
          name="question_4"
          autoComplete="text"
          value={question_4.value}
          onChange={question_4.onChange}
        />
        <h2 className="dh-title-sub-section margin-top-x2">Contacto de Emergencia</h2>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="emergency_contact_name"
          label="Nombre y Apellido"
          name="emergency_contact_name"
          autoComplete="name"
          value={user.user.emergency_contact_name}
          onChange={handleUserInputChange}
        />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              id="codigos_emegency"
              select
              fullWidth
              label="Cod.País"
              value={values.codigos_emegency}
              onChange={handleValueChange('codigos_emegency')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined" >
              {codigos.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="emergency_contact_phone"
              label="Teléfono"
              type="number"
              name="emergency_contact_phone"
              value={user.user.emergency_contact_phone}
              onChange={handleUserInputChange}
            />
          </Grid>
        </Grid>
        <div className="btn-container">
          {handleBack &&
            <button
              onClick={handleBack}
              className={`btn-primary medium border ${activeStep === 0 ? "disabled" : null }`}
              style={{marginRight: 16}}>
              Atras
            </button>
          }
          <button type="submit" className="btn-primary medium">
            Guardar y Continuar
          </button>
        </div>
      </form>
      {notification.messageState &&
        <Notification 
          message={notification.message} 
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState} 
          onClose={notification.handleCloseSnackbar}
        />
      }
    </React.Fragment>
  )
}

StepBasicInfo.propTypes = {
  id: PropTypes.string.isRequired,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  activeStep: PropTypes.number
}

export default StepBasicInfo
