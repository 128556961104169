import React from 'react';
import PropTypes from 'prop-types';
// Constants
// Material - UI
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import HouserCard from '@root/components/cards/HouserCard';

const houserCardSkeleton = () => (
  <div className="dh-card-houser-list-detail">
    <div className="dh-card-houser-list-detail__style">
      <Grid container spacing={0} direction="row">
        <Grid item xs={12} sm={6}>
          <Skeleton className="dh-card-houser-list-detail__cover-image" variant="rect" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <Skeleton width="40%" />
            <Skeleton width="90%" />
            <Skeleton width="50%" />
            <Skeleton width="60%" />
            <Skeleton width="60%" />
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

const HouserGrid = (props) => {
  const { loading, users, handleHover } = props;
  return (
    <>
      {!loading ? (
        <Grid container spacing={3}>
          {users.map((user, index) => (
            <Grid item xs={12} sm={6} md={12} key={index}>
              <HouserCard user={user} handleHover={handleHover} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <React.Fragment>
          {Array.from(new Array(5)).map((data, index) => (
            <div key={index}>{houserCardSkeleton()}</div>
          ))}
        </React.Fragment>
      )}
    </>
  );
};

HouserGrid.propTypes = {
  loading: PropTypes.bool,
  users: PropTypes.array.isRequired,
  handleHover: PropTypes.func.isRequired,
};

export default HouserGrid;
