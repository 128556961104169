import React, { useState } from 'react';
import './adminNewPlace.scss';

import * as SERVICES from 'services/config';
import * as OPTIONS from '@root/services/options';
import * as VARIABLES from 'constants/variables';
import { country as countryArray, variantCityByCountry } from 'utils/Resources';
// Dependencies
import GoogleMapReact from 'google-map-react';
import Resizer from 'react-image-file-resizer';
// Hooks
import { locationManagement } from 'hooks/useLocationManagement';
// Material-UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
// Images
import Gallery from 'assets/icons/Gallery';
//Componentes
import Notification from 'components/notification';
import TitleArrowBack from '@root/components/TitleBack';

// Tipo de Lugares
export const type_places = [
  {
    value: 1,
    label: 'Comercio',
  },
  {
    value: 2,
    label: 'Restaurante',
  },
  {
    value: 3,
    label: 'Cafetería',
  },
  {
    value: 4,
    label: 'Hotel',
  },
];

function ImageProgress(props) {
  const { image } = props;
  return (
    <div className="image-progress">
      <img className="profile-img" src={image} alt="petFriendly place" />
      <CircularProgress className="image-progress__circle" />
    </div>
  );
}

function Marker() {
  return (
    <React.Fragment>
      <div className="pin" />
      <div className="pulse" />
    </React.Fragment>
  );
}

function AdminNewPlace(props) {
  const [loading, setLoading] = useState(false);
  // Variables del componente "Notification"
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  // Ubicacion del lugar
  const [location, setLocation] = useState({
    country: 'País',
    city: 'Ciudad',
  });
  // Array de las ciudades escogidas por el Usuario
  const [cityArray, setCityArray] = useState([]);
  // Tipo de lugare
  const [typePlace, setTypePlace] = useState(2);
  const [placeData, setPlaceData] = useState({
    city: '',
    district: '',
    title: '',
    adress: '',
    link_page: '',
    phone: '',
    image_card: '',
    image_detail: '',
    description: '',
    pet_place_type_id: 2,
    schedule: '',
    price_range: '',
    food_type: '',
    departmen: '',
    zipcode: '',
    province: '',
  });
  const zoom = 14;
  const [center, setCenter] = useState({
    lat: -12.083348,
    lng: -77.057595,
  });
  // Variables de la imagen
  const [imageCard, setImageCard] = useState({
    file: '',
    imagePreviewUrl: '',
  });
  const [imageDetail, setImageDetail] = useState({
    file: '',
    imagePreviewUrl: '',
  });

  // Funcion para cerrar el Componente 'Notification'
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  // Funcion para volver a la pestaña anterior
  const handleBackClick = () => {
    props.history.goBack();
  };

  // Funcion para manejar el cambio de los SELECT con datos de "user.location"
  const handleLocationChange = (name) => (event) => {
    setLocation({
      ...location,
      [name]: event.target.value,
    });
    if (name === 'country') {
      setCityArray(variantCityByCountry[event.target.value]);
      locationManagement(event.target.value, location.city, setCenter);
    } else if (name === 'city') {
      locationManagement(location.country, event.target.value, setCenter);
    }
  };

  // Funcion para detectar un click en el mapa
  const onMarkerClick = (props, marker, e) => {
    setCenter({
      lat: props.lat,
      lng: props.lng,
    });
  };

  // Funcion para manejar el cambio del tipo de lugar
  const handleTypePlacenChange = (name) => (event) => {
    // console.log(event.target.value)
    setTypePlace(event.target.value);
    setPlaceData({
      ...placeData,
      [name]: event.target.value,
    });
  };

  // Función para controlar los cambios de los input de datos del lugar
  const handlePlaceDataChange = (data) => {
    setPlaceData({
      ...placeData,
      [data.target.name]: data.target.value,
    });
  };

  // Convertir la imagen de Base64 a archivo
  const convertBase64ToFile = function (image) {
    const byteString = atob(image.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    const newBlob = new Blob([ab], {
      type: 'image/jpeg',
    });
    return newBlob;
  };

  // Controla si hay un cambio de imagén para la tarjeta
  const handleImageCardChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setImageCard({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
    }
  };

  // Controla si hay un cambio de imagén para la vista detallada
  const handleImageDetailChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setImageDetail({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchCreateLocation();
  };

  const fetchCreateLocation = async () => {
    let errorMessage = 'Error al introducir los datos';
    let successMessage = 'Creado Correctamente';
    var body = {
      country: location.country,
      city: location.city,
      district: placeData.district,
      title: placeData.title,
      adress: placeData.adress,
      link_page: placeData.link_page,
      phone: placeData.phone,
      description: placeData.description,
      pet_place_type_id: placeData.pet_place_type_id,
      price_range: placeData.price_range,
      schedule: placeData.schedule,
      food_type: placeData.food_type,
      latitude: center.lat,
      longitude: center.lng,
      department: '',
      zipcode: '',
      province: '',
    };
    sendData(
      SERVICES.CREATE_PETFRIENDLY_PLACE,
      OPTIONS.POST(body),
      successMessage,
      errorMessage,
    );
  };

  const sendData = async (url, options, successMessage, errorMessage) => {
    try {
      const response = await fetch(url, options);
      // eslint-disable-next-line no-unused-vars
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        setMessage(successMessage);
        setMessageVariant('success');
        setMessageState(true);
        if (imageCard.file) {
          fetchImageCard(data.place.id);
        }
        if (imageDetail.file) {
          fetchImageDetail(data.place.id);
        }
      } else {
        setMessage(errorMessage);
        setMessageVariant('error');
        setMessageState(true);
      }
    } catch (error) {
      setMessage('Error en los datos: ' + error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  // Establecer los datos del Usuario a enviar la tarjeta
  const fetchImageCard = async (id) => {
    setLoading(false);
    setMessage('');
    setMessageState(false);
    Resizer.imageFileResizer(
      imageCard.file,
      580,
      580,
      'JPEG',
      80,
      0,
      (uri) => {
        let newFile = convertBase64ToFile(uri);
        let text = `pf${id}_${imageCard.file.name}_master`;
        const formData = new FormData();
        formData.append('id', id);
        formData.append('photo', newFile, text);
        sendVariableData(
          SERVICES.UPLOAD_PHOTO_PETFRIENDLY_CARD,
          OPTIONS.POST_FORM_DATA(formData),
          VARIABLES.STATE_UPDATE,
        );
      },
      'base64',
    );
  };

  // Establecer los datos del Usuario a enviar la imagen detallada
  const fetchImageDetail = async (id) => {
    setLoading(false);
    setMessage('');
    setMessageState(false);
    Resizer.imageFileResizer(
      imageDetail.file,
      1200,
      720,
      'JPEG',
      80,
      0,
      (uri) => {
        let newFile = convertBase64ToFile(uri);
        let text = `pf${id}_${imageDetail.file.name}`;
        const formData = new FormData();
        formData.append('id', id);
        formData.append('photo', newFile, text);
        sendVariableData(
          SERVICES.UPLOAD_PHOTO_PETFRIENDLY_DETAIL,
          OPTIONS.POST_FORM_DATA(formData),
          VARIABLES.STATE_UPDATE,
        );
      },
      'base64',
    );
  };

  const sendVariableData = async (url, options, state) => {
    try {
      const response = await fetch(url, options);
      // eslint-disable-next-line no-unused-vars
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (state === VARIABLES.STATE_INITIATE) {
          // setPlace(data)
        } else if (state === VARIABLES.STATE_UPDATE) {
          setLoading(false);
          setMessage('Creado Correctamente');
          setMessageVariant('success');
          setMessageState(true);
        }
      } else {
        if (state === VARIABLES.STATE_INITIATE) {
          setMessage('Error al obtener la foto del lugar');
          setMessageVariant('error');
          setMessageState(true);
        } else if (state === VARIABLES.STATE_UPDATE) {
          setLoading(false);
          setMessage('Lo sentimos, no fue posible cargar su imagen.');
          setMessageVariant('error');
          setMessageState(true);
        }
      }
    } catch (error) {
      setLoading(false);
      setMessage('Error en la Imagen: ' + error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <React.Fragment>
      <TitleArrowBack handleClick={handleBackClick}>
        <h2 className="marginx05">{'Crear Lugar'}</h2>
      </TitleArrowBack>
      <form onSubmit={handleSubmit}>
        <div className="dh-card-elevation margin-top-x1">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="country"
                name="country"
                select
                required
                fullWidth
                label="País"
                value={location.country}
                onChange={handleLocationChange('country')}
                SelectProps={{
                  MenuProps: {
                    className: 'dh-select-menu',
                  },
                }}
                margin="normal"
                variant="outlined">
                {countryArray.map((country) => (
                  <MenuItem key={country.value} value={country.value}>
                    {country.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="city"
                name="city"
                className="dh-city-selector"
                select
                required
                fullWidth
                label="Ciudad"
                value={location.city}
                onChange={handleLocationChange('city')}
                SelectProps={{
                  MenuProps: {
                    className: 'dh-select-menu',
                  },
                }}
                margin="normal"
                variant="outlined">
                {cityArray.map((city) => (
                  <MenuItem key={city.value} value={city.value}>
                    {city.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                id="district"
                name="district"
                required
                fullWidth
                label="Distrito"
                onChange={handlePlaceDataChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="adress"
                name="adress"
                required
                fullWidth
                label="Dirección"
                onChange={handlePlaceDataChange}
                margin="normal"
                variant="outlined"></TextField>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="title"
                name="title"
                required
                fullWidth
                label="Nombre del lugar"
                onChange={handlePlaceDataChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="phone"
                name="phone"
                type="tel"
                required
                fullWidth
                label="Teléfono"
                onChange={handlePlaceDataChange}
                margin="normal"
                variant="outlined"></TextField>
            </Grid>
          </Grid>
          <TextField
            id="link_page"
            name="link_page"
            required
            fullWidth
            type="url"
            label="Url del sitio"
            onChange={handlePlaceDataChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="pet_place_type_id"
            name="pet_place_type_id"
            select
            required
            fullWidth
            label="Tipo de Lugar"
            value={typePlace}
            onChange={handleTypePlacenChange('pet_place_type_id')}
            SelectProps={{
              MenuProps: {
                className: 'dh-select-menu',
              },
            }}
            margin="normal"
            variant="outlined">
            {type_places.map((place) => (
              <MenuItem key={place.value} value={place.value}>
                {place.label}
              </MenuItem>
            ))}
          </TextField>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                id="food_type"
                name="food_type"
                required
                fullWidth
                label="Tipo de comida"
                onChange={handlePlaceDataChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="price_range"
                name="price_range"
                required
                fullWidth
                label="Precio medio"
                onChange={handlePlaceDataChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="schedule"
                name="schedule"
                required
                fullWidth
                label="Horario"
                onChange={handlePlaceDataChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <TextField
            id="description"
            name="description"
            required
            fullWidth
            label="Descripción del lugar"
            onChange={handlePlaceDataChange}
            margin="normal"
            variant="outlined"
            multiline
            rows="4"
          />
          <h3>
            Recuerda que tienes que ubicar el establecimiento en el mapa, encuentra el
            establecimeinto y haz clic en el.
          </h3>
          <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: VARIABLES.GOOGLE_MAP_KEY }}
              center={center}
              defaultZoom={zoom}
              onClick={onMarkerClick}>
              <Marker lat={center.lat} lng={center.lng} />
            </GoogleMapReact>
          </div>
        </div>
        <div className="dh-card-elevation margin-top-x1">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h3>Imagen Principal</h3>
              <div className="dh-upload-image-detail">
                <div className="dh-upload-image-detail__header">
                  <span id="switch-red"></span>
                  <span id="switch-yellow"></span>
                  <span id="switch-green"></span>
                </div>
                <div className="dh-upload-image-detail__image-container">
                  {imageDetail.imagePreviewUrl ? (
                    <React.Fragment>
                      {loading ? (
                        <ImageProgress image={imageDetail.imagePreviewUrl} />
                      ) : (
                        <label htmlFor="upload-photo-detail">
                          <img
                            className="profile-image"
                            src={imageDetail.imagePreviewUrl}
                            alt="petFriendly place"
                          />
                        </label>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {placeData.image_detail ? (
                        <label htmlFor="upload-photo-detail">
                          <img
                            className="profile-image"
                            src={`${VARIABLES.IMAGE_URL}${placeData.image_detail}`}
                            alt="petFriendly place"
                          />
                        </label>
                      ) : (
                        <label htmlFor="upload-photo-detail">
                          <div className="drop-zone">
                            <div className="drop-zone__content">
                              <Gallery />
                            </div>
                          </div>
                        </label>
                      )}
                    </React.Fragment>
                  )}
                </div>
                <div className="dh-upload-image-detail__info-container">
                  <div className="dh-upload-image-detail__info-container__description">
                    <h4>Titulo</h4>
                    <Skeleton variant="rect" width={64} height={8} style={{ marginTop: 4 }} />
                    <Skeleton
                      variant="rect"
                      width={96}
                      height={8}
                      style={{ marginTop: 10, marginBottom: 6 }}
                    />
                    <Skeleton
                      variant="rect"
                      width="70%"
                      height={8}
                      style={{ marginBottom: 4 }}
                    />
                    <Skeleton
                      variant="rect"
                      width="70%"
                      height={8}
                      style={{ marginBottom: 4 }}
                    />
                    <Skeleton
                      variant="rect"
                      width="50%"
                      height={8}
                      style={{ marginBottom: 4 }}
                    />
                  </div>
                </div>
              </div>
              <label
                htmlFor="upload-photo-detail"
                className="btn-primary border btn-profile-border">
                Cargar foto
              </label>
              <input type="file" id="upload-photo-detail" onChange={handleImageDetailChange} />
            </Grid>
            <Grid item xs={6}>
              <div className="dh-upload-image-card">
                <h3>Imagen de la tarjeta</h3>
                <div className="dh-upload-image-card__container">
                  {imageCard.imagePreviewUrl ? (
                    <React.Fragment>
                      {loading ? (
                        <ImageProgress image={imageCard.imagePreviewUrl} />
                      ) : (
                        <label htmlFor="upload-photo-card">
                          <img
                            className="profile-image"
                            src={imageCard.imagePreviewUrl}
                            alt="petFriendly place"
                          />
                        </label>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {placeData.image_card ? (
                        <label htmlFor="upload-photo-card">
                          <img
                            className="profile-image"
                            src={`${VARIABLES.IMAGE_URL}${placeData.image_card}`}
                            alt="petFriendly place"
                          />
                        </label>
                      ) : (
                        <label htmlFor="upload-photo-card">
                          <div className="drop-zone">
                            <div className="drop-zone__content">
                              <Gallery />
                            </div>
                          </div>
                        </label>
                      )}
                    </React.Fragment>
                  )}
                </div>
                <label
                  htmlFor="upload-photo-card"
                  className="btn-primary border btn-profile-border">
                  Cargar foto
                </label>
                <input type="file" id="upload-photo-card" onChange={handleImageCardChange} />
              </div>
            </Grid>
          </Grid>
        </div>
        <button type="submit" className="btn-primary medium margin-top-x1">
          Crear Lugar
        </button>
      </form>
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </React.Fragment>
  );
}

export default AdminNewPlace;
