import { useMediaQuery, useTheme } from '@material-ui/core';

const useResponsive = () => {
  const theme = useTheme();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

  const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));

  const isMobile = useMediaQuery('(max-width: 768px)');

  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');

  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return {
    matchDownSM,
    matchDownMD,
    matchDownLG,
    matchDownXL,
    isDesktop,
    isTablet,
    isMobile,
  };
};

export default useResponsive;
