import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './reservationsTable.scss';
// Hooks
import TablePaginationActions from 'hooks/TablePaginationActions';
// Utils
import replacePathParams from 'utils/replacePathParams';
// Constants
import * as ROUTES from 'constants/routes';
// components
import ReservationStatus from 'components/ReservationStatus';
// Material-UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
// Material Icons
import VisibilityIcon from '@material-ui/icons/Visibility';

const ReservationsTable = (props) => {
  const { reservations } = props;

  const [page, setPage] = useState(0);
  const [resPerPage, setResPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeResPerPage = (event) => {
    setResPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
  };

  return (
    <div className="houser-reservation-table dh-simple-elevation">
      <div className="res-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Houser Nombre</TableCell>
              <TableCell>Cliente Nombre</TableCell>
              <TableCell>Creado en</TableCell>
              <TableCell>Día Inicio</TableCell>
              <TableCell>Día Final</TableCell>
              {/* <TableCell>País</TableCell> */}
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {reservations
              .slice(page * resPerPage, page * resPerPage + resPerPage)
              .map((res) => (
                <TableRow hover key={res.id}>
                  <TableCell>#{res.id}</TableCell>
                  <TableCell>{`${res.houser.user.name} ${res.houser.user.last_name}`}</TableCell>
                  <TableCell>{`${res.user.name} ${res.user.last_name}`}</TableCell>
                  <TableCell>
                    {new Date(res.created_at).toLocaleString('es', dateOptions)}
                  </TableCell>
                  <TableCell>
                    {new Date(res.drop_date).toLocaleString('es', {
                      ...dateOptions,
                      timeZone: 'UTC',
                    })}
                  </TableCell>
                  <TableCell>
                    {new Date(res.pickup_date).toLocaleString('es', {
                      ...dateOptions,
                      timeZone: 'UTC',
                    })}
                  </TableCell>
                  {/* <TableCell>{res.service.country}</TableCell> */}
                  <TableCell align="center">
                    <div className="status-container">
                      <ReservationStatus status={res.state} endDate={res.pickup_date} />
                    </div>
                  </TableCell>
                  <TableCell align="center" className="table-body__client-action">
                    <RouterLink
                      to={replacePathParams(ROUTES.ADMIN_RESERVATION_DETAIL, { id: res.id })}
                      className="dh-router">
                      <IconButton aria-label="ver datos del producto">
                        <VisibilityIcon />
                      </IconButton>
                    </RouterLink>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={reservations.length}
          rowsPerPage={resPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'filas por página' },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeResPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </div>
    </div>
  );
};

ReservationsTable.propTypes = {
  reservations: PropTypes.array.isRequired,
};

export default ReservationsTable;
