import React from 'react'
import PropTypes from 'prop-types'
import './cardHouserExam.scss'
// Doghouser icons
import TestIcon from 'icons/TestIcon';
// Material-Icon
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import PriorityHighRoundedIcon from '@material-ui/icons/PriorityHighRounded';

const VARIANT_EXAM = {
  0: false,
  1: true,
}

const badgeExam = (state) => (
  <div className="badge-exam">
    {VARIANT_EXAM[state] ? (
      <div className="badge-exam__success">
        <span>Aprobada</span>
      </div>
    ) : (
      <div className="badge-exam__error">
        <span>No Aprobada</span>
      </div>
    )}
  </div>
)

const CardHouserExam = props => {
  const { houser, handleUpdateExam } = props
  return (
    <div className="card-cuidador-exam dh-card-elevation">
      <div className="dh-flex items-center margin-bottom-x1">
        <span className="card-cuidador-exam__title">
          Evaluación
        </span>
        {badgeExam(houser.exam_valid)}
      </div>
      <div className="card-cuidador-exam__body">
        <TestIcon setColor="default"/>
        {VARIANT_EXAM[houser.exam_confirmed] ? (
          <p>El Cuidador a confirmado que a realizado la evaluación.</p>
        ) : (
          <p>El Cuidador <span>NO</span> a confirmado que a realizado la evaluación.</p>
        )}
      </div>
      <div className="card-cuidador-exam__link">
        <a
          href={`https://docs.google.com/forms/d/1kp5bXHugYBkVs9B71vnKgPViqX_eqLgQLxpjT3eaA_Y/edit`}
          target="_blank"
          rel="noopener noreferrer"
          className="dh-no-decoration">
          Link de Evaluacion
        </a>
      </div>
      <div className="card-cuidador-exam__actions dh-flex content-end">
        {VARIANT_EXAM[houser.exam_valid] ? (
          <button className="btn-danger" onClick={() => handleUpdateExam(0)}>
            <PriorityHighRoundedIcon/>
            No aprobar
          </button>
        ) : (
          <button className="btn-success" onClick={() => handleUpdateExam(1)}>
            <CheckRoundedIcon/>
            Aprobar
          </button>
        )}
      </div>
    </div>
  )
}

CardHouserExam.propTypes = {
  houser: PropTypes.object.isRequired,
  handleUpdateExam: PropTypes.func
}

export default CardHouserExam
