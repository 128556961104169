/**
 * Status del Usuario
 * Verificado => 1
 * No Verificado => 0
 * Cualquier otro numero es tomado como ERROR
 **/
import React from 'react';
import PropTypes from 'prop-types';
import './badgeStatus.scss';

const VARIANT_BADGE = {
  '0': 'no-verificado',
  '1': 'verificado',
  '2': 'error',
};

const VARIANT_TEXT_BADGE = {
  '0': 'No Verificado',
  '1': 'Verificado',
  '2': 'Faltan Datos',
};

function BadgeStatus(props) {
  const { status } = props;
  let variant = VARIANT_BADGE[status];
  let variantText = VARIANT_TEXT_BADGE[status];

  return <div className={`dh-badge-status ${variant}`}>{variantText}</div>;
}

BadgeStatus.defaultProps = {
  status: '2',
};

BadgeStatus.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default BadgeStatus;
