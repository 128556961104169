import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import PetDTO from '@root/models/PetDTO';
import replacePathParams from '@root/utils/replacePathParams';
import { CREAR_MASCOTA, MASCOTA_DETAIL } from '@root/constants/routes';
import { useSnackbar } from '@root/hooks';
import { petService } from '@root/services';
import { DogSectionTitle } from '@root/ui-component';
import { AddNewCard, PetSimpleCard } from '@root/ui-component/cards';

interface PetsTypes {
  userId: number;
}

const Pets = (props: PetsTypes) => {
  const { userId } = props;
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [pets, setPets] = useState<Array<PetDTO>>([]);

  const fetchPets = async () => {
    setLoading(true);
    try {
      const res = await petService.listByUser(userId);
      if (res.status === 200) {
        setPets(res.pets);
      }
      setLoading(false);
    } catch (error) {
      snackbar.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <>
      <DogSectionTitle title="Mis mascotas" />
      <Grid container spacing={3}>
        {!loading ? (
          <>
            {pets.map((pet) => (
              <Grid item xs={12} sm={6} md={4} key={pet.id}>
                <RouterLink to={replacePathParams(MASCOTA_DETAIL, { id: pet.id })}>
                  <PetSimpleCard
                    name={pet.name}
                    image={pet.photo}
                    birthDate={pet.birth_date}
                    petType={pet.pet_type}
                  />
                </RouterLink>
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4}>
              <RouterLink to={CREAR_MASCOTA}>
                <AddNewCard height="260px" />
              </RouterLink>
            </Grid>
          </>
        ) : (
          <>
            {Array.from(new Array(6)).map((data, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton
                  variant="rect"
                  height="260px"
                  style={{ borderRadius: 'var(--borderRadiusMedium)' }}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};

export default Pets;
