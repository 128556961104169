import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './stepCuidadorPlaceDetail.scss'
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';
// Utils
import { tipoPropiedad } from 'utils/Resources'
// Components
import Notification from 'components/notification/Notification';
// Material-UI
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Images - DogHouser
import JardinIcon from 'icons/JardinIcon';
import TerrazaIcon from 'icons/TerrazaIcon';
import DogIcon from 'icons/DogIcon';
import CatIcon from 'icons/CatIcon';
import RabbitIcon from 'icons/RabbitIcon';
import ExoIcon from 'icons/ExoIcon';

const StepCuidadorPlaceDetail = props => {
  const { id, handleNext, handleBack, activeStep } = props
  // Variables de "Notification"
  const notification = useNotificationValue()
  // Datos del usuario
  const [user, setUser] = useState({
    houser: {
      id: null,
      property_type: '',
      allowed_cats: 0,
      pet_detail: '',
    },
  })
  const [state, setState] = useState({
    has_garden: false,
    has_terrace: false,
    has_dogs: false,
    has_cats: false,
    has_rabbits: false,
    has_exo: false,
    allowed_cats: '0',
  })

  const handleHouserInputChange = e => {
    setUser({ houser: { ...user.houser, [e.target.name]: e.target.value } })
  }

  const handleCheckboxChange = name => event => {
    setState({ ...state, [name]: event.target.checked })
  }

  const handleRadioChange = name => event => {
    setState({ ...state, [name]: event.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault();
    fetchUpdateUser()
  }

  useEffect(() => {
    fetchGetUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const fetchGetUser = async () => {
    let body = {
      id: id
    }
    sendUserData(SERVICES.USER_BY_ID, OPTIONS.POST(body), VARIABLES.STATE_INITIATE)
  }

  const fetchUpdateUser = async () => {
    let body = {
      id: id,
      property_type: user.houser.property_type,
      has_garden: VARIABLES.BOOLEAN_TO_NUMBER[state.has_garden],
      has_terrace: VARIABLES.BOOLEAN_TO_NUMBER[state.has_terrace],
      has_dogs: VARIABLES.BOOLEAN_TO_NUMBER[state.has_dogs],
      has_cats: VARIABLES.BOOLEAN_TO_NUMBER[state.has_cats],
      has_rabbits: VARIABLES.BOOLEAN_TO_NUMBER[state.has_rabbits],
      has_exo: VARIABLES.BOOLEAN_TO_NUMBER[state.has_exo],
      allowed_cats: VARIABLES.TEXT_TO_NUMBER[state.allowed_cats],
      pet_detail: user.houser.pet_detail,
    }
    sendUserData(SERVICES.UPDATE_HOUSER_STEP_3, OPTIONS.POST(body), VARIABLES.STATE_UPDATE)
  }

  const sendUserData = async (url, options, state) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        if (state === VARIABLES.STATE_INITIATE) {
          setUser(data)
          setState({
            has_garden: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.has_garden],
            has_terrace: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.has_terrace],
            has_dogs: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.has_dogs],
            has_cats: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.has_cats],
            has_rabbits: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.has_rabbits],
            has_exo: VARIABLES.NUMBER_TO_BOOLEAN[data.houser.has_exo],
            allowed_cats: VARIABLES.NUMBER_TO_TEXT[data.houser.allowed_cats]
          })
        } else if (state === VARIABLES.STATE_UPDATE) {
          notification.handleSnackbar('Datos actualizados', 'success')
          handleNext()
        }
      } else {
        if (state === VARIABLES.STATE_INITIATE) {
          notification.handleSnackbar("Error al obtener los datos.", 'error')
        } else if (state === VARIABLES.STATE_UPDATE) {
          notification.handleSnackbar("Error al actualizar los datos", 'error')
        }
      }
    } catch (error) {
      notification.handleSnackbar('Error:' + error.message, 'error')
    }
  }

  return (
    <div className="step-cuidador-place-detail">
      <form onSubmit={handleSubmit}>
        <ul className="step-cuidador-place-detail__flex-outer">
          <li>
            <label htmlFor="property_type">Tipo de propiedad:</label>
            <TextField
              id="property_type"
              name="property_type"
              select
              required
              value={user.houser.property_type}
              onChange={handleHouserInputChange}
              margin="normal"
              variant="outlined">
              {tipoPropiedad.map(tipo => (
                <MenuItem key={tipo.value} value={tipo.value}>
                  {tipo.label}
                </MenuItem>
              ))}
            </TextField>
          </li>
        </ul>
        <div className="step-cuidador-place-detail__section-title">
          <h3>Características de la propiedad</h3>
        </div>
        <FormGroup row className="step-cuidador-place-detail__form-group">
          <FormControlLabel
            control={
              <Checkbox
                icon={<JardinIcon/>}
                checkedIcon={<JardinIcon setColor="accent"/>}
                checked={state.has_garden}
                onChange={handleCheckboxChange('has_garden')}
                value="has_garden"
                color="primary"
              />
            }
            label="Jardín"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<TerrazaIcon/>}
                checkedIcon={<TerrazaIcon setColor="accent"/>}
                checked={state.has_terrace}
                onChange={handleCheckboxChange('has_terrace')}
                value="has_terrace"
                color="primary"
              />
            }
            label="Terraza"
            labelPlacement="bottom"
          />
        </FormGroup>
        <div className="step-cuidador-place-detail__section-title">
          <h3>¿Tienes mascotas en casa?</h3>
        </div>
        <FormGroup row className="step-cuidador-place-detail__form-group">
          <FormControlLabel
            control={
              <Checkbox
                icon={<DogIcon/>}
                checkedIcon={<DogIcon setColor="accent"/>}
                checked={state.has_dogs}
                onChange={handleCheckboxChange('has_dogs')}
                value="has_dogs"
                color="primary"
              />
            }
            label="Perros"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<CatIcon/>}
                checkedIcon={<CatIcon setColor="accent"/>}
                checked={state.has_cats}
                onChange={handleCheckboxChange('has_cats')}
                value="has_cats"
                color="primary"
              />
            }
            label="Gatos"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RabbitIcon/>}
                checkedIcon={<RabbitIcon setColor="accent"/>}
                checked={state.has_rabbits}
                onChange={handleCheckboxChange('has_rabbits')}
                value="has_rabbits"
                color="primary"
              />
            }
            label="Conejos"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<ExoIcon/>}
                checkedIcon={<ExoIcon setColor="accent"/>}
                checked={state.has_exo}
                onChange={handleCheckboxChange('has_exo')}
                value="has_exo"
                color="primary"
              />
            }
            label="Exóticos"
            labelPlacement="bottom"
          />
        </FormGroup>
        <ul className="step-cuidador-place-detail__flex-outer">
          <li>
            <label htmlFor="pet_detail">¿Cuantas mascotas tienes?</label>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="pet_detail"
              name="pet_detail"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 10 } }}
              value={user.houser.pet_detail}
              onChange={handleHouserInputChange}
            />
          </li>
          <li>
            <label htmlFor="allowed_cats">¿Podrías alojar gatos?</label>
            <RadioGroup aria-label="allowed_cats" name="allowed_cats" value={state.allowed_cats} onChange={handleRadioChange('allowed_cats')} row>
              <FormControlLabel value={'1'} control={<Radio />} label="Si" />
              <FormControlLabel value={'0'} control={<Radio />} label="No" />
            </RadioGroup>
          </li>
        </ul>
        <div className="btn-container">
          {handleBack &&
            <button
              onClick={handleBack}
              className={`btn-primary medium border ${activeStep === 0 ? "disabled" : null }`}
              style={{marginRight: 16}}>
              Atras
            </button>
          }
          <button type="submit" className="btn-primary medium btn-step">
            Guardar y Continuar
          </button>
        </div>
      </form>
      {notification.messageState &&
        <Notification 
          message={notification.message} 
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState} 
          onClose={notification.handleCloseSnackbar}
        />
      }
    </div>
  )
}

StepCuidadorPlaceDetail.propTypes = {
  id: PropTypes.string.isRequired,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  activeStep: PropTypes.number
}

export default StepCuidadorPlaceDetail
