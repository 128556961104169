import React from 'react'

const variant = {
  primary: 'var(--primaryColor)',
  accent: 'var(--accentColor)',
}

function RabbitIcon(props) {
  const { setColor } = props
  let color = "var(--iconColor)"

  if (setColor) {
    color = variant[setColor]
  }

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="44.776px"
      height="44.776px"
      viewBox="0 0 512 512"
      style={{enableBackground: 'new 0 0 512 512'}}
      xmlSpace="preserve">
      <g>
        <path d="M257.3,388.3v24.6h20.9v-24.6c0-18.8,15.3-34.1,34.1-34.1h11.1v-20.9h-11.1C282,333.3,257.3,358,257.3,388.3z" fill={color}/>
        <path d="M256,8C118.5,8,7,119.5,7,257s111.5,249,249,249s249-111.5,249-249S393.5,8,256,8z M92.1,92.2l4.3-14.3
          l11.2,9.1c1.2,1,29.8,24.7,41,62.5c2.6,8.8,4,17.5,4.6,25.7c0.6,7.2,0.5,14,0.2,20.2c1.3-0.1,2.5-0.2,3.8-0.2c0.8,0,1.5,0,2.3,0.1
          c-0.4-6.2-0.4-13,0.2-20.2c0.6-8.2,2.1-16.9,4.7-25.6c11.3-37.8,40.1-61.5,41.3-62.5l11.3-9.1l4.3,14.3c0.5,1.5,11.3,38.3,0,76.1
          c-3.5,11.7-8.6,22-14.2,30.7c-4.1,6.5-8.5,12.2-12.6,17c3.4,5.5,5.7,11.8,6.4,18.6l5.6,52.7c1.3,12.4-2.7,24.8-11,34
          c-7.6,8.4-18.1,13.6-29.4,14.4v-41h-20.9v40.6c-10.1-1.5-19.5-6.4-26.4-14.1c-8.3-9.3-12.3-21.7-11-34l5.7-52.7
          c0.7-6.4,2.7-12.4,5.9-17.7c-4.1-4.8-8.5-10.5-12.7-17.1c-5.7-9-11-19.5-14.5-31.5C80.9,130.5,91.7,93.7,92.1,92.2z M391.8,433.9
          h-6.4v0.2l-284.7,0v-20.9h30.9c8,0,14.6-6.5,14.6-14.6v-16.4l-3.6-4.6c-5.9-7.7-10.5-16.4-13.7-25.5c7.2,2.7,14.8,4.1,22.6,4.1
          h11.3c18.2,0,35.5-7.7,47.7-21.3c11.2-12.5,17.1-29,16.4-45.6h74.4c44.1,0,80.4,34.1,83.9,77.4h6.7c18.5,0,33.6,15.1,33.6,33.6
          C425.4,418.8,410.3,433.9,391.8,433.9z" fill={color} />
      </g>
    </svg>
  )
}

export default RabbitIcon
