import React, { useState } from 'react';

function useFilterHouser(housers) {
  const [values, setValues] = useState({
    country_peru: false,
    country_colombia: false,
    country_espana: false,
    email_true: false,
    email_false: false,
    phone_true: false,
    phone_false: false,
    terms_conditions_true: false,
    terms_conditions_false: false,
    houser_active: false,
    postulant_complete: false,
    postulant_no_complete: false,
  });
  const [filteredHousers, setFilteredHousers] = useState(housers);

  const handleValuesChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const filterCountry = (housers) => {
    return housers.location
      ? (values.country_peru &&
          `${housers.location.country}`.includes('Perú')) ||
          (values.country_colombia &&
            `${housers.location.country}`.includes('Colombia')) ||
          (values.country_espana &&
            `${housers.location.country}`.includes('España')) ||
          (!values.country_peru &&
            !values.country_colombia &&
            !values.country_espana &&
            housers)
      : null;
  };

  const filterEmailConfirmed = (housers) => {
    return (
      (values.email_true && `${housers.email_confirmed}`.includes(1)) ||
      (values.email_false && `${housers.email_confirmed}`.includes(0)) ||
      (!values.email_true && !values.email_false && housers)
    );
  };

  const filterPhoneConfirmed = (housers) => {
    return (
      (values.phone_true && `${housers.confirmation}`.includes(1)) ||
      (values.phone_false && `${housers.confirmation}`.includes(0)) ||
      (!values.phone_true && !values.phone_false && housers)
    );
  };

  // eslint-disable-next-line no-unused-vars
  const filterTermsConditions = (housers) => {
    return (
      (values.terms_conditions_true &&
        `${housers.terms_conditions}`.includes(1)) ||
      (values.terms_conditions_false &&
        `${housers.terms_conditions}`.includes(0)) ||
      (!values.terms_conditions_true &&
        !values.terms_conditions_false &&
        housers)
    );
  };

  const filterTags = (houser) => {
    return (
      (values.houser_active &&
        `${houser.terms_conditions}`.includes(1) &&
        `${houser.confirmation}`.includes(1) &&
        houser.houser.publication &&
        `${houser.houser.publication.publication_state}`.includes('1')) ||
      (values.postulant_complete &&
        `${houser.terms_conditions}`.includes(1) &&
        houser.houser.publication &&
        `${houser.houser.publication.publication_state}`.includes('0')) ||
      (values.postulant_no_complete &&
        `${houser.terms_conditions}`.includes(0)) ||
      (!values.houser_active &&
        !values.postulant_complete &&
        !values.postulant_no_complete &&
        houser)
    );
  };

  React.useMemo(() => {
    const result = housers
      .filter(filterCountry)
      .filter(filterTags)
      .filter(filterEmailConfirmed)
      .filter(filterPhoneConfirmed);
    setFilteredHousers(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [housers, values]);

  return { values, handleValuesChange, filteredHousers };
}

export default useFilterHouser;
