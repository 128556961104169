import React, { useContext, useState } from "react";
import { Context } from "@root/Context";
import { Redirect, Link as RouterLink } from "react-router-dom";
import "./registerClient.scss";
// Services
import * as SERVICES from "services/config";
import * as OPTIONS from "services/options";
// Constants
import * as ROUTES from "constants/routes";
// Componets
import Notification from "components/notification";
// Utils
import {
  country,
  cityPeru,
  cityColombia,
  cityEspana,
  cityChile,
  codigos,
} from "utils/Resources";
// Hooks
import { useInputValue } from "hooks/useInputValue";
// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(4, 5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  linkRed: {
    fontFamily: "Montserrat-Semibold",
    color: "#f44336",
  },
  termsText: {
    fontSize: "0.85rem",
    textAlign: "center",
  },
  termsLink: {
    textDecoration: "none",
    color: "#80cdc5",
  },
}));

function RegisterClient(props) {
  const classes = useStyles();
  const {
    redirectTo,
    tempRequest,
    isAuth,
    admin,
    client,
    houser,
    activateAuth,
    setRole,
    setLocation,
    setUserData,
    setRedirect,
  } = useContext(Context);
  const name = useInputValue("");
  const last_name = useInputValue("");
  const email = useInputValue("");
  const phone = useInputValue("");
  const password = useInputValue("");
  const password_2 = useInputValue("");
  const [values, setValues] = useState({
    country: "Perú",
    city: "Lima",
    codigos: "+51",
  });
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState(false);
  let citySelectorContainer;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleCloseSnackbar = (e) => {
    setMessage("");
    setMessageState(false);
  };

  const CitySelector = (props) => {
    const { city } = props;
    return (
      <TextField
        id="outlined-select-city"
        className="dh-city-selector"
        select
        required
        fullWidth
        label="Ciudad"
        value={values.city}
        onChange={handleChange("city")}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="outlined"
      >
        {city.map((city) => (
          <MenuItem key={city.value} value={city.value}>
            {city.label}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  if (values.country === "Perú") {
    citySelectorContainer = <CitySelector city={cityPeru} />;
  } else if (values.country === "Colombia") {
    citySelectorContainer = <CitySelector city={cityColombia} />;
  } else if (values.country === "España") {
    citySelectorContainer = <CitySelector city={cityEspana} />;
  } else if (values.country === "Chile") {
    citySelectorContainer = <CitySelector city={cityChile} />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.value === password_2.value) {
      fetchRegister();
    } else {
      setMessage("Las contraseñas no coinciden");
      setMessageState(true);
    }
  };

  const fetchRegister = () => {
    setMessage("");
    setMessageState(false);
    let body = {
      country: values.country,
      city: values.city,
      name: name.value,
      last_name: last_name.value,
      email: email.value,
      phone: phone.value,
      password: password.value,
      rol: "1",
    };
    sentUserData(SERVICES.REGISTER_EMAIL, OPTIONS.POST(body));
  };

  const sentUserData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      let houserId = "";
      // console.log(data)
      if (data.status === 200) {
        setMessage("");
        setMessageState(false);
        if (data.houser) {
          houserId = data.houser.id;
        }
        // Gestión del usuario
        activateAuth(
          data.user.id, // token
          data.user.id, // id
          houserId, // houserId
          data.user.confirmation, // stClient
          data.user.terms_conditions // stHouser
        );
        setUserData(data.user.name, data.user.photo);
        setLocation(data.location.country, data.location.city);
        setRole(data.user.rol);
        if (redirectTo !== "") {
          props.history.push(redirectTo);
          setRedirect("");
        }
      } else {
        setMessage(data.message);
        setMessageState(true);
      }
    } catch (error) {
      setMessage("Error:" + error.message);
      setMessageState(true);
    }
  };

  let fromCliente = ROUTES.LISTA_SERVICIOS;

  if (props.location.state) {
    fromCliente = props.location.state || {
      from: { pathname: ROUTES.LISTA_SERVICIOS },
    };
  }

  if (isAuth && redirectTo !== "") {
    setRedirect("");
    return <Redirect to={redirectTo} />;
  }
  if (isAuth && client) return <Redirect to={fromCliente} />;
  if (isAuth && houser) return <Redirect to={ROUTES.HOME} />;
  if (isAuth && admin) return <Redirect to={ROUTES.HOME} />;

  return (
    <div className="dh-register">
      <div className={classes.paper}>
        <h1 className="dh-title">Regístrate - Cliente</h1>
        {redirectTo && tempRequest && (
          <p className={classes.linkRed}>
            Asegúrese de completar el registro de mascotas después de iniciar
            sesión o registrarse.
          </p>
        )}
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Nombres"
                name="name"
                autoComplete="name"
                autoFocus
                value={name.value}
                onChange={name.onChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="last_name"
                label="Apellidos"
                name="last_name"
                autoComplete="name"
                value={last_name.value}
                onChange={last_name.onChange}
              />
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            type="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={email.value}
            onChange={email.onChange}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="outlined-select-country"
                select
                required
                fullWidth
                label="País"
                value={values.country}
                onChange={handleChange("country")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
              >
                {country.map((country) => (
                  <MenuItem key={country.value} value={country.value}>
                    {country.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              {citySelectorContainer}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                id="outlined-select-cod"
                select
                required
                fullWidth
                label="Cod.País"
                value={values.codigos}
                onChange={handleChange("codigos")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
              >
                {codigos.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Teléfono"
                type="number"
                name="phone"
                value={phone.value}
                onChange={phone.onChange}
              />
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password.value}
            onChange={password.onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password_2"
            label="Contraseña"
            type="password"
            id="password_2"
            autoComplete="current-password"
            value={password_2.value}
            onChange={password_2.onChange}
          />
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <button type="submit" className="btn-primary medium btn-register">
                Crear cuenta
              </button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RouterLink to={ROUTES.LOGIN} className="dh-no-decoration">
                <p className="login-link">
                  ¿Ya tienes una cuenta?
                  <span className={classes.linkRed}> ¡Inicia sesión!</span>
                </p>
              </RouterLink>
            </Grid>
          </Grid>
        </form>
        <p className={classes.termsText}>
          Al registrarte estas aceptando los
          <RouterLink to={ROUTES.TERMINOS} className={classes.termsLink}>
            {" "}
            términos y condiciones{" "}
          </RouterLink>
          y la
          <RouterLink
            to={ROUTES.REGISTER_CLIENTE_CORREO}
            className={classes.termsLink}
          >
            {" "}
            politica de privacidad{" "}
          </RouterLink>
          de Dog Houser
        </p>
      </div>
      {messageState && (
        <Notification
          message={message}
          variant="error"
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default RegisterClient;
