import React, { useState } from 'react'

function useFilterReservations(reservations) {
  const [values, setValues] = useState({
    state_1: false,
    state_2: false,
    state_3: false,
    state_4: false
  })
  const [ filteredReservations, setFilteredReservations ] = useState(reservations)

  const handleValuesChange = name => event => {
    setValues({ ...values, [name]: event.target.checked })
  }

  const filterState = (res) => {
    return (
      (
        values.state_1 && `${res.reservation.state}`.includes(1)
      ) || (
        values.state_2 && `${res.reservation.state}`.includes(2)
      ) || (
        values.state_3 && `${res.reservation.state}`.includes(3)
      ) || (
        values.state_4 && `${res.reservation.state}`.includes(4)
      ) || (
        (!values.state_1 && !values.state_2 && !values.state_3 && !values.state_4) && res
      )
    )
  }

  React.useMemo(() => {
    const result = reservations.filter(filterState)
    setFilteredReservations(result)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ reservations, values ])

  return { values, handleValuesChange, filteredReservations }
}

export default useFilterReservations
