import React, { useState } from 'react'
// Utils
import normalize from '../utils/normalize'

function useSearchPlaces(places) {
  const [ query, setQuery ] = useState('');
  const [ filteredPlaces, setFilteredPlaces ] = useState(places);

  React.useMemo(() => {
    const result = places.filter(place => {
      return `${normalize(place.title)}`
        .toLowerCase()
        .includes(normalize(query.toLowerCase()));
    });
    setFilteredPlaces(result)
  }, [ places, query ]);

  return { query, setQuery, filteredPlaces }
}

export default useSearchPlaces