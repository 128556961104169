import React, { useState, useEffect } from 'react';
import './stepNewPetFoto.scss';

import * as SERVICES from '../../services/config';
import * as OPTIONS from '@root/services/options';
import * as VARIABLES from 'constants/variables';
// Dependencies
import Resizer from 'react-image-file-resizer';
// Components
import Notification from '../notification/Notification';
// DogHouser Icons
import AddIcon from '../../assets/icons/AddIcon';
import EditIcon from '@material-ui/icons/Edit';
// Material-UI
import CircularProgress from '@material-ui/core/CircularProgress';
// Material-Images
import ImageIcon from '@material-ui/icons/Image';

function StepNewPetFoto(props) {
  const { petId, editPet, handleNext } = props;
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  const [image, setImage] = useState({
    file: '',
    imagePreviewUrl: '',
  });

  useEffect(() => {
    if (editPet) {
      setImage({
        file: '',
        imagePreviewUrl: `${VARIABLES.IMAGE_URL}${editPet.photo}`,
      });
    }
  }, [editPet]);

  // Funcion para cerrar el componente Notification
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImage({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
    }
  };

  const convertBase64ToFile = function (image) {
    const byteString = atob(image.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    const newBlob = new Blob([ab], {
      type: 'image/jpeg',
    });
    return newBlob;
  };

  const handleSubmitImage = (e) => {
    e.preventDefault();
    if (image.file) {
      fetchImageUser();
    } else {
      if (editPet) {
        handleNext();
      }
    }
  };

  const fetchImageUser = async () => {
    setLoadingPhoto(true);
    setMessage('');
    setMessageState(false);
    Resizer.imageFileResizer(
      image.file,
      580,
      580,
      'JPEG',
      80,
      0,
      (uri) => {
        // console.log(uri)
        let newFile = convertBase64ToFile(uri);
        let text = 'pet-' + petId + '-' + image.file.name;
        const formData = new FormData();
        formData.append('pet_id', petId);
        formData.append('photo', newFile, text);
        sendImage(SERVICES.UPLOAD_PHOTO_PET, OPTIONS.POST_FORM_DATA(formData));
      },
      'base64',
    );
  };

  // Enviar Imagen de la Mascota al servidor
  const sendImage = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (data.message) {
        setLoadingPhoto(false);
        setMessage(data.message);
        setMessageVariant('success');
        setMessageState(true);
        handleNext();
      } else {
        setLoadingPhoto(false);
        setMessage('Error al subir la Imagen');
        setMessageVariant('warning');
        setMessageState(true);
      }
    } catch (error) {
      setLoadingPhoto(false);
      setMessage('Error: ' + error);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <React.Fragment>
      <h1 className="marginx05">Foto de mascota requerida</h1>
      <div className="new-pet-photo-container">
        <div className="photo-container">
          {image.imagePreviewUrl ? (
            <React.Fragment>
              {!loadingPhoto ? (
                <label htmlFor="upload-photo">
                  <div className="photo-label">
                    <img src={image.imagePreviewUrl} alt="mascota" />
                    <EditIcon className="edit-icon" />
                  </div>
                </label>
              ) : (
                <div className="photo-label image-progress">
                  <img className="gallery__img" src={image.imagePreviewUrl} alt="mascota" />
                  <CircularProgress className="image-progress__circle" />
                </div>
              )}
            </React.Fragment>
          ) : (
            <label htmlFor="upload-photo">
              <div className="photo-label">
                <AddIcon />
              </div>
            </label>
          )}
        </div>
        <input className="hide" type="file" id="upload-photo" onChange={handleImageChange} />
        <div className="text-center">
          <button className="btn-primary" onClick={handleSubmitImage}>
            <ImageIcon />
            Guardar Imagen y Continuar
          </button>
        </div>
      </div>
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </React.Fragment>
  );
}

export default StepNewPetFoto;
