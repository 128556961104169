import React, { useState, useContext, useEffect } from 'react';
import { Context } from '@root/Context';
import './cuidadorUbicacion.scss';
// Services
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';
// Constants
import * as VARIABLES from 'constants/variables';
// Dependencies
import GoogleMapReact from 'google-map-react';
// Utils
import { vias } from 'utils/Resources';
// Components
import TitleArrowBack from '@root/components/TitleBack';
import Notification from 'components/notification';
import LoadingText from 'components/loadingText';
// Material-UI
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

function Marker(props) {
  const { id } = props;
  return (
    <React.Fragment>
      <div className="pin" key={id} />
      <div className="pulse" />
    </React.Fragment>
  );
}

function CuidadorUbicacion(props) {
  const { cookies } = useContext(Context);
  let userId;
  // Se detecta si se pasa un ID por la URL
  if (props.match.params.id) {
    userId = props.match.params.id;
  } else {
    // Si no se detecta se usa el ID guardado en las cookies
    userId = cookies.id;
  }
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  const [user, setUser] = useState({
    name: '',
    address: '',
    houser: {
      type_road: '',
      adress_detail: '',
    },
    location: {
      latitude: '',
      longitude: '',
      country: '',
      city: '',
      department: null,
      zipcode: null,
      province: null,
      district: '',
      abreviated: null,
    },
  });
  const zoom = 14;
  const [center, setCenter] = useState({
    lat: -12.083348,
    lng: -77.057595,
  });

  // Funcion para volver a la pestaña anterior
  const handleBackClick = () => {
    props.history.goBack();
  };

  // Funcion para cerrar el componente Notification
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  // Funcion para manejar el cambio de los INPUT con datos de "user"
  const handleAdressChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  // Funcion para manejar el cambio de los INPUT con datos datos de "user.location"
  const handleInputLocationChange = (e) => {
    setUser({
      ...user,
      houser: {
        ...user.houser,
      },
      location: {
        ...user.location,
        [e.target.name]: e.target.value,
      },
    });
  };

  // Funcion para manejar el cambio de los INPUT con datos datos de "user.houser"
  const handleInputHouserChange = (e) => {
    setUser({
      ...user,
      houser: {
        ...user.houser,
        [e.target.name]: e.target.value,
      },
      location: {
        ...user.location,
      },
    });
  };

  // Funcion para manejar el cambio de los SELECT con datos datos de "user.houser"
  const handleHouserChange = (name) => (event) => {
    setUser({
      ...user,
      houser: {
        ...user.houser,
        [name]: event.target.value,
      },
      location: {
        ...user.location,
      },
    });
  };

  // Funcion para detectar un click en el mapa
  const onMarkerClick = (props, marker, e) => {
    setCenter({
      lat: props.lat,
      lng: props.lng,
    });
  };

  const onChildMouseEnter = (key, childProps) => {
    // console.log(childProps)
  };

  useEffect(() => {
    fetchCuidador();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // Preparar los datos y el ID del usuario para solicitar la informacion al servidor
  const fetchCuidador = async () => {
    setLoading(true);
    setMessage('');
    setMessageVariant('');
    setMessageState(false);
    sendUserData(
      SERVICES.GET_CUIDADOR_BY_ID,
      OPTIONS.POST({ id: userId }),
      VARIABLES.STATE_INITIATE,
    );
  };

  // Obtener los datos actulizados
  const handleSubmit = (e) => {
    e.preventDefault();
    let country = user.location.country;
    let city = user.location.city;
    let type_road = user.houser.type_road;
    let address = user.address;
    let adress_detail = user.houser.adress_detail;
    let latitude = center.lat;
    let longitude = center.lng;
    let district = user.location.district;
    fetchUpdateUser(
      country,
      city,
      type_road,
      address,
      adress_detail,
      latitude,
      longitude,
      district,
    );
  };

  const fetchUpdateUser = async (
    country,
    city,
    type_road,
    address,
    adress_detail,
    latitude,
    longitude,
    district,
  ) => {
    setMessage('');
    setMessageVariant('');
    setMessageState(false);
    let body = {
      id: userId,
      country: country,
      city: city,
      type_road: type_road,
      address: address,
      adress_detail: adress_detail,
      latitude: latitude,
      longitude: longitude,
      district: district,
    };
    sendUserData(SERVICES.UPDATE_HOUSER_STEP_2, OPTIONS.POST(body), VARIABLES.STATE_UPDATE);
  };

  // Enviar datos al servidor
  const sendUserData = async (url, options, state) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        if (state === VARIABLES.STATE_INITIATE) {
          setUser(data.user);
          if (data.user.location.latitude && data.user.location.longitude) {
            var intLat = parseFloat(data.user.location.latitude);
            var intLng = parseFloat(data.user.location.longitude);
            setCenter({
              lat: intLat,
              lng: intLng,
            });
          }
          setLoading(false);
        } else if (state === VARIABLES.STATE_UPDATE) {
          setMessage('Datos actualizados');
          setMessageVariant('success');
          setMessageState(true);
        }
      } else {
        setLoading(false);
        setMessage(data.message);
        setMessageVariant('warning');
        setMessageState(true);
      }
    } catch (error) {
      setLoading(false);
      setMessage(error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <div className="dashboard-cuidador">
      <Container maxWidth="lg">
        <TitleArrowBack handleClick={handleBackClick}>
          <h4 className="marginx05">{'Volver al anuncio'}</h4>
        </TitleArrowBack>
        <h1 className="marginx05">Ubicación</h1>
        <div className="cuidador-ubicacion">
          <h3 className="margin-bottom">Tipo de vía</h3>
          {!loading ? (
            <TextField
              id="type_road"
              select
              required
              fullWidth
              variant="outlined"
              label="Tipo de vía"
              value={user.houser.type_road}
              onChange={handleHouserChange('type_road')}
              SelectProps={{
                MenuProps: {
                  className: 'menu',
                },
              }}>
              {vias.map((via) => (
                <MenuItem key={via.value} value={via.value}>
                  {via.label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <LoadingText count={0} />
          )}
          <h3>Dirección</h3>
          <p className="marginx05">
            Esta dirección solo se muestra a los clientes que tengan una reserva
          </p>
          {!loading ? (
            <TextField
              id="address"
              name="address"
              required
              fullWidth
              variant="outlined"
              type="text"
              value={user.address}
              onChange={handleAdressChange}
            />
          ) : (
            <LoadingText count={0} />
          )}
          <h3 className="margin-bottom">Distrito</h3>
          {!loading ? (
            <TextField
              variant="outlined"
              required
              fullWidth
              id="district"
              label="Distrito"
              name="district"
              type="text"
              value={user.location.district}
              onChange={handleInputLocationChange}
            />
          ) : (
            <LoadingText count={0} />
          )}
          <h3 className="margin-bottom">Detalle</h3>
          {!loading ? (
            <TextField
              variant="outlined"
              required
              fullWidth
              id="adress_detail"
              label="Piso / Oficina / Apto / Depto"
              name="adress_detail"
              type="text"
              value={user.houser.adress_detail}
              onChange={handleInputHouserChange}
            />
          ) : (
            <LoadingText count={0} />
          )}
          <h3>Marcador de mapa</h3>
          <p>
            Si es necesario, puede usar el marcador y hacer click en la ubicación donde se
            encuentre su casa.
          </p>
          {!loading && (
            <div className="map-container">
              <GoogleMapReact
                bootstrapURLKeys={{ key: VARIABLES.GOOGLE_MAP_KEY }}
                center={{
                  lat: center.lat,
                  lng: center.lng,
                }}
                defaultZoom={zoom}
                onClick={onMarkerClick}
                onChildMouseEnter={onChildMouseEnter}>
                <Marker lat={center.lat} lng={center.lng} id={user.id} />
              </GoogleMapReact>
            </div>
          )}
          <button className="btn-primary" onClick={handleSubmit}>
            Guardar cambios
          </button>
        </div>
      </Container>
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default CuidadorUbicacion;
