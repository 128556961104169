import { useEffect } from 'react';

import { DogLayout, DogPageHeader } from '@root/ui-component';

const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DogLayout>
      <div>
        <DogPageHeader title="Preguntas Frecuentes" />
        <div>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">¿Qué es DogHouser?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              Es una plataforma virtual de familias que cuidan mascotas, las cuales han sido
              verificadas y evaluadas por el equipo de DogHouser. Contamos además con convenidos
              con veterinarias que nos dan soporte y un seguro médico mientras la mascota está
              con nosotros.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">¿Cómo funciona DogHouser?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              En la página web&nbsp;
              <span style={{ textDecoration: 'underline' }}>
                <a href="http://www.doghouser.com/" target="_blank" rel="noreferrer">
                  www.doghouser.com
                </a>
              </span>
              &nbsp;puedes ver las casas afiliadas. Primero te registras y haces una pre-reserva
              incluyendo la fecha para la que necesitarías el servicio. Debes esperar que el
              cuidador la confirme y una vez que recibas el mail de confirmación tienes hasta 24
              horas para hacer el pago y te enviamos la reserva.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">¿Cuánto tiempo antes debo hacer la reserva?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              Lo que recomendamos siempre es lo antes posibles para que separes la casa que te
              gusta y no te arriesgues a que alguien más la haya reservado. Sin embargo, si
              tienes una urgencia, es posible hacer reservas el mismo día siempre que encuentres
              disponibilidad y tengas todos los documentos de la mascota a la mano como el
              carnet de vacunación.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">¿Hacen movilidad?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              ¡Sí! Muchos cuidadores pueden recoger a la mascota por un costo adicional y si los
              cuidadores no tienen esta opción activada, también contamos con una empresa de
              taxi especializada en perros que es de confianza. Estos servicios se deben
              coordinar al menos 48 horas antes.&nbsp;&nbsp;Los costos van desde 20 soles cada
              trayecto.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">¿Ustedes incluyen comida?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              La comida no está incluida en el precio pues si le cambiamos la dieta a tu mascota
              nos arriesgamos a que le den problemas estomacales. Lo ideal es que tú la mandes
              con su comida aunque si deseas puedes comprar en nuestra tienda virtual la marca
              que siempre come o puedes solicitar la preparación de comida casera por 12 soles
              diarios.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">¿Cómo saben que son familias de confianza?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              Porque todas las familias son evaluadas y entrevistadas por el equipo de
              DogHouser. Cada afiliado nos ha entregado documentos y perfiles profesionales y
              sociales para poder recabar la mayor cantidad de información de ellos. Nuestro
              compromiso es mantener un servicio de primera calidad y solo las casas que nos den
              absoluta confianza como para dejar a nuestras propias mascotas pueden entrar al
              sistema. Del 100% de casas que postulan solo ingresan un 15% al sistema.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">¿Cuál es el precio?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">Los precios varían según el servicio:</span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              45 soles - Servicio Guardería (hasta 12 horas siempre que la entrada y salida sea
              entre 7am y 9pm)
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              60 soles – Servicio Hospedaje en casa compartida (hasta 24 horas siempre que la
              entrada y salida sea entre 7am y 9pm)
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              70 soles - Servicio Hospedaje en casa exclusiva (hasta 24 horas siempre que la
              entrada y salida sea entre 7am y 9pm)
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              *Si el hospedaje supera las 24 horas desde que la mascota ingresó, se considera
              mediodía más en el cobro
            </span>
            <br />
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">
                ¿En qué se diferencia de otros hoteles u hospedajes de mascotas?
              </span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              ¡Los hoteles también son buenos! Pero nuestro servicio es totalmente diferente.
              Nuestra propuesta no es ‘un lugar’ donde van decenas de mascotas sino darle a tu
              mascota una familia temporal. Esto es darle libertad para estar dentro de casa,
              mucho cariño de parte de la familia, cuidados personalizados y por supuesto
              comunicación contigo todo el día para que sepas cómo la está pasando tu engreído.
              Así reducimos el estrés de la mascota y el tuyo propio. Además, tenemos una gran
              variedad de casas para que escojas la que más te convenga.&nbsp;
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">&nbsp;&nbsp;&nbsp;</span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">
                ¿Hay límites en los horarios de entrada y salida?
              </span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              Nuestros cuidadores son familias que también descansan. Por ello, los horarios son
              de 7am a 9pm para poder recibir y entregar a las mascotas.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">¿Es posible conocer a los cuidadores antes?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              ¡Sí! La mayoría de las casas tiene la opción activada para que puedas hacer una
              cita previa. Para ello deberás estar registrado y hacer la pre-reserva. Si no
              tiene la opción activada de ‘cita previa’ quiere decir que la familia no está muy
              cómoda con humanos tanto como con perritos, pero son poquitos casos.&nbsp;&nbsp;
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">¿Cómo es el pago?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              El pago es por transferencia bancaria a nuestra cuenta BCP o tarjeta de crédito a
              través de la plataforma. En ningún caso se le entrega directamente el dinero al
              cuidador o a la movilidad.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span className="color_15">
                ¿Tengo comunicación directa con el cuidador una vez que he hecho la reserva?
              </span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span className="color_15">
              Sí! Ellos estarán constantemente mandándote fotos y videos, y te contarán de
              primera mano cómo está tu mascota.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>
              <span className="wixGuard">​</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span style={{ color: '#2F2E2E' }}>¿Qué cubre el seguro?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>
              El seguro cubre cualquier emergencia médica que le ocurra a tu mascota durante su
              estadía con nosotros. Si esto llegara a pasar puede ir a cualquiera de nuestras
              clínicas afiliadas y se le cubre como máximo la suma de 600 soles en nuestras
              clínicas afiliadas de las cadenas Groomers, Hurón Azul y Puppyland.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span style={{ color: '#2F2E2E' }}>¿Si el seguro pasa el monto asegurado?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>
              El monto asegurado gratuito es por 600 soles, si el seguro sobrepasa ese monto,
              debes cancelarlo en la veterinaria para poder dar el alta a tu mascota.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span style={{ color: '#2F2E2E' }}>¿Hasta cuando puedo utilizar el seguro?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>
              Hasta el último día de hospedaje de la mascota o un día más en caso de que los
              síntomas se hayan presentado durante el tiempo de hospedaje.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span style={{ color: '#2F2E2E' }}>¿Y si quiero cancelar mi reserva?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>
              Si tus planes han cambiado, puedes hacer una postergación de tu reserva con el fin
              de no perder el dinero. Sin embargo, si optas por tener el dinero de vuelta y
              siempre que canceles hasta un día antes podemos hacer la devolución con una
              retención de 6 soles por día. Y si la cancelación es el mismo día, se cobrará el
              día entero en cuestión.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span style={{ color: '#2F2E2E' }}>¿Cómo modifico una reserva?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>
              Ingresas a tu usuario. Validas con tu Houser su disponibilidad. Y de ser positivo
              ingresas a ‘Modificar Reserva’. Para mayor consulta puedes escribir a&nbsp;
              <span style={{ textDecoration: 'underline' }}>
                <a href="mailto:liz@lifecomunicaciones.com" target="_self">
                  liz@lifecomunicaciones.com
                </a>
              </span>
              &nbsp;
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span style={{ color: '#2F2E2E' }}>
                ¿Cómo puedo ser parte de la red de HOUSERS?
              </span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>
              Si tienes mucho tiempo en casa y te gustan los perritos, aprovecha y postula.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>Requisitos:</span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>- Ser un doglover 100%&nbsp;</span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>
              - Que siempre haya alguien en casa. (No consideramos por el momento personas que
              trabajen fuera de casa)&nbsp;
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>- No tener más de una mascota en casa</span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>
              - Que los postulantes pasen por una entrevista personal y revisión de ambientes.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            &nbsp;
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>
              <span style={{ color: '#2F2E2E' }}>¿Qué pasa si le pasa algo a mi perro?</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>
              En el improbable caso de cualquier incidente, se contactará con el dueño por
              celular o WhatsApp. De estar inubicable se dejará un mensaje y llamaremos al
              contacto de emergencia. De no tener respuesta, nosotros actuaremos según lo que
              creamos es mejor para el bienestar de la mascota.
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ color: '#2F2E2E' }}>
              <span className="wixGuard">​</span>
            </span>
          </p>
          <p className="font_8" style={{ fontSize: '15px' }}>
            <span style={{ fontStyle: 'italic' }}>
              <span style={{ color: 'rgb(47, 46, 46)' }}>
                * Si no hemos contestado tu pregunta, manda un&nbsp;
              </span>
              <span style={{ color: 'rgb(47, 46, 46)' }}>WhatsApp</span>
              <span style={{ color: 'rgb(47, 46, 46)' }}>
                &nbsp;al 51-995759034
                {/*
                  &nbsp;al 51-995759034 o un correo a{' '}
                  <a data-auto-recognition="true" href="mailto:liz@lifecomunicaciones.com">
                    liz@lifecomunicaciones.com
                  </a>
              */}
              </span>
            </span>
          </p>
        </div>
      </div>
    </DogLayout>
  );
};

export default Faqs;
