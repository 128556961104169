import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from "react-router-dom";
import './cardPetFriendly.scss'
// Constants
import * as VARIABLES from 'constants/variables'
// Icon
import NoFoto from 'assets/images/ico/ic_no_foto.svg';

function CardPetFriendly(props) {
  const { title, text, image, pathName } = props;
  return (
    <RouterLink className="dh-no-decoration" to={pathName}>
      <div className="card-pet-friendly">
        <div className="card-pet-friendly__elevation">
          {image ? (
            <div className="card-pet-friendly__image">
              <img src={`${VARIABLES.IMAGE_URL}${image}`} alt="Lugar PetFriendly"/>
            </div>
          ) : (
            <div className="card-pet-friendly__image">
              <div className="card-pet-friendly__image__not-fount">
                <img src={NoFoto} alt="No se encontro una foto"/>
                <p>No se encontró ninguna imagen</p>
              </div>
            </div>
          )}
        </div>
        <div className="card-pet-friendly__name">
          <h3 className="truncate">{title}</h3>
          <p>{text}</p>
        </div>
      </div>
    </RouterLink>
  )
}

CardPetFriendly.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  pathName: PropTypes.string.isRequired
}

export default CardPetFriendly
