import React from 'react';
import './searchInput.scss';
// Material-UI
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
// Material Icons
import SearchIcon from '@material-ui/icons/Search';

function SearchInput(props) {
  const { text = 'Buscar', query, onChange } = props;
  return (
    <div className="search-container">
      <Paper className="search-paper">
        <SearchIcon className="search-icon" />
        <InputBase
          className="search-input"
          placeholder={text}
          value={query}
          onChange={onChange}
        />
      </Paper>
    </div>
  );
}

export default SearchInput;
