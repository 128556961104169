import React from 'react'
import './dialogHouserRequirements.scss'
// Material-UI
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

function DialogHouserRequirements({open, handleClose, handleNext}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dh-dialog-houser-requirements"
    >
      <div className="dh-dialog-houser-requirements__title">
        <h2>¡Gracias por tu interés en ser cuidador!</h2>
      </div>
      <DialogContent>
        <p>
          Antes de comenzar a llenar tu perfil, recuerda que necesitarás fotos de tu documento de identidad, 
          recibo de servicios y 7 fotos de tu casa. Con la info a la mano, no demorarás más de 10 minutos. 
          :) Recuerda que solo se evaluarán los perfiles de quienes hayan completado toda la información y las 
          preguntas de forma más detallada. Considera que nuestros requisitos de afiliación son:
        </p>
        <p>
          - Ser mayor de 25 años y menor de 85.
        </p>
        <p>
          - Tener disponibilidad completa de tiempo (no personas que trabajan fuera del hogar o estudiantes).
        </p>
        <p>
          - Tener experiencia con mascotas (más de 5 años viviendo o cuidado perritos).
        </p>
      </DialogContent>
      <div className="dh-dialog-houser-requirements__actions">
        <button onClick={handleClose} className="btn-default">
          Atras
        </button>
        <button onClick={handleNext} className="btn-primary">
          Continuar
        </button>
      </div>
    </Dialog>
  )
}

export default DialogHouserRequirements
