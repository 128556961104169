import { useContext, useState } from 'react';
import {
  Avatar,
  Box,
  CardMedia,
  LinearProgress,
  Card,
  CardContent,
  Grid,
  Stepper,
  Step,
  StepButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Room as RoomIcon } from '@material-ui/icons';

import * as VARIABLES from 'constants/variables';
import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
import * as ROUTES from '@root/constants/routes';
import { Context } from '@root/Context';
import './onboarding.scss';

import DogImage from 'assets/images/onboarding/dog.png';
import CatImage from 'assets/images/onboarding/cat.png';
import ExoticImage from 'assets/images/onboarding/turtle.png';
import SelectRight from 'assets/images/onboarding/select-right.png';
import SelectLeft from 'assets/images/onboarding/select-left.png';
import EdadImage from 'assets/images/onboarding/edad.png';
import TamanoImage from 'assets/images/onboarding/tamano.png';
import JardinImage from 'assets/images/onboarding/jardin.png';
import SofaImage from 'assets/images/onboarding/sofa.png';
import CamaImage from 'assets/images/onboarding/cama.png';
import AmigueroImage1 from 'assets/images/onboarding/amiguero-1.png';
import AmigueroImage2 from 'assets/images/onboarding/amiguero-2.png';
import AmigueroImage3 from 'assets/images/onboarding/amiguero-3.png';
import GuarderiaImage from 'assets/images/onboarding/guarderia.png';
import HospedajeImage from 'assets/images/onboarding/hospedaje.png';
import ADomicilioImage from 'assets/images/onboarding/a-domicilio.png';
import PaseosImage from 'assets/images/onboarding/paseos.png';
import ResultSticker from 'assets/images/onboarding/result-sticker.png';
import GuarderiaMadrid from 'assets/images/onboarding/dog-houser-guarderia.jpeg';
import DHLogo from 'assets/dh_logo_mini.png';

function Home(props) {
  const { country, city } = useContext(Context);
  const [currentMascotas, setCurrentMascotas] = useState([
    {
      nombre: '',
      especie: '',
      tamano: 0,
      index: 0,
      comodidades: [],
      amiguero: 1,
      enfermo: 0,
      cachorro: 0,
      sex: '',
      edad: 0,
    },
  ]);
  const [currentStep, setCurrentStep] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [houseType, setHouseType] = useState(null);
  const [results, setResults] = useState([]);
  const [name, setName] = useState(null);
  const [especie, setEspecie] = useState('dog');
  const [nameError, setNameError] = useState(false);
  const [edad, setEdad] = useState(0);
  const [tamano, setTamano] = useState(0);
  const [servicio, setServicio] = useState(0);

  const availableEdades = ['Cachorro', 'Adulto', 'Senior'];
  const availableTamanos = ['Pequeño', 'Mediano', 'Grande'];

  const [mascotasDisponibles, setMascotasDisponibles] = useState([
    {
      especie: 'cat',
      image: CatImage,
      name: 'Gato',
    },
    {
      especie: 'dog',
      image: DogImage,
      name: 'Perro',
    },
    {
      especie: 'exotic',
      image: ExoticImage,
      name: 'Exótico',
    },
  ]);

  const previousStep = () => {
    if (currentStep === 1) {
      props.history.push(ROUTES.HOME);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const skip = () => {
    props.history.push(ROUTES.LISTA_CUIDADORES);
  };

  const endQuestionarie = async () => {
    setLoading(true);
    let body = {
      country: country,
      city: city,
      type_share: 0,
      type_exclusive: 0,
      type_delivery: 0,
      has_terrace: 0,
      has_garden: 0,
      allowed_bed: 0,
      allowed_sofa: 0,
      allowed_cats: 0,
      pet_size: 4,
    };
    let url = SERVICES.LIST_FILTER_CUIDADORES;

    let mascota_peligrosa = 0;
    let mascota_especial = 0;
    let biggest_size = 0;
    currentMascotas.forEach((mascota) => {
      if (mascota.comodidades.includes('jardin')) {
        body.has_garden = 1;
        //body.has_terrace = 1;
      }
      if (mascota.comodidades.includes('cama')) {
        body.allowed_bed = 1;
      }
      if (mascota.comodidades.includes('sofa')) {
        body.allowed_sofa = 1;
      }
      if (mascota.amiguero === 0) {
        mascota_peligrosa = 1;
      }
      if (mascota.enfermo || mascota.cachorro) {
        mascota_especial = 1;
      }
      if (mascota.especie !== 'dog') {
        body.allowed_cats = 1;
      }
      if (mascota.tamano > biggest_size) {
        biggest_size = mascota.tamano;
      }
    });
    body.pet_size = biggest_size;

    //mirar tipo de casa
    let type = houseType; //puede ser shared o delivery aquí.

    //si es peligroso y no se ha marcado ya como delivery, marcamos exclusive
    if (mascota_peligrosa && type !== 'delivery') {
      type = 'exclusive';
    }
    if (mascota_especial) {
      type = 'exclusive';
    }

    if (type === 'exclusive') {
      body.type_exclusive = 1;
    } else if (type === 'shared') {
      body.type_share = 1;
      body.type_exclusive = 1;
    } else {
      body.type_delivery = 1;
    }

    try {
      const response = await fetch(url, OPTIONS.POST(body));
      const data = await response.json();
      const guarderiaMadrid = {
        id: 9999,
        guarderiaMadrid: true,
        name: 'Dog Houser Madrid',
        address: 'General Aranaz, 25',
        photo: 'dh_logo_mini.png', //photo avatar
        houser: {
          publication: {
            title: 'Dog Houser Madrid',
          },
          galleries: [
            {
              photo: 'dog-houser-guarderia.jpeg', //photo grande
            },
          ],
        },
        location: {
          district: 'Madrid',
        },
      };

      if (response.status === 200) {
        if (data.users) {
          let finalResults = data.users;
          if (finalResults.length > 6) {
            //si ha seleccionado guardería en Madrid, meter la ficha al principio.
            if (servicio === 'guarderia' && city === 'Madrid' && country === 'España') {
              finalResults.splice(0, 0, guarderiaMadrid);
            }
            setResults(finalResults.slice(0, 6));
          } else {
            //si ha seleccionado guardería en Madrid, meter la ficha al principio.
            if (servicio === 'guarderia' && city === 'Madrid' && country === 'España') {
              finalResults.splice(0, 0, guarderiaMadrid);
            }
            setResults(finalResults);
          }
          nextStep();
        } else {
          //si ha seleccionado guardería en Madrid, meter la ficha al principio.
          if (servicio === 'guarderia' && city === 'Madrid' && country === 'España') {
            setResults([guarderiaMadrid]);
            nextStep();
          } else {
            props.history.push(ROUTES.LISTA_CUIDADORES);
          }
        }
      } else {
        props.history.push(ROUTES.LISTA_CUIDADORES);
        //error de red o servidor
      }
    } catch (err) {
      props.history.push(ROUTES.LISTA_CUIDADORES);
      //error de red o servidor
    }
  };

  const changeSelectedMascota = (direction) => {
    let mascotas = [...mascotasDisponibles];
    let newMascotas = [];
    if (direction === 'left') {
      newMascotas.push(mascotas[1]);
      newMascotas.push(mascotas[0]);
      newMascotas.push(mascotas[2]);
      setEspecie(mascotas[0].especie);
    } else {
      newMascotas.push(mascotas[0]);
      newMascotas.push(mascotas[2]);
      newMascotas.push(mascotas[1]);
      setEspecie(mascotas[2].especie);
    }
    setMascotasDisponibles(newMascotas);
  };

  const renderMascotasSlider = () => {
    return (
      <>
        <div className="mascotasSlider">
          <div className="mascota-first">
            <img src={`${mascotasDisponibles[0].image}`} alt="mascot first" />
          </div>
          <div
            className="select-mascota"
            onClick={() => {
              changeSelectedMascota('left');
            }}>
            <img src={SelectLeft} alt="mascot left" />
          </div>
          <div className="mascota">
            <img src={`${mascotasDisponibles[1].image}`} alt="mascot stock" />
            {mascotasDisponibles[1].name}
          </div>
          <div
            className="select-mascota"
            onClick={() => {
              changeSelectedMascota('right');
            }}>
            <img src={SelectRight} alt="mascot right" />
          </div>
          <div className="mascota-last">
            <img src={`${mascotasDisponibles[2].image}`} alt="" />
          </div>
        </div>
      </>
    );
  };

  const addNewMascota = (sex) => {
    if (!name || name.trim() < 3) {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }
    let mascotas = [...currentMascotas];
    let mascota = mascotas[0];
    mascota.nombre = name;
    mascota.especie = especie;
    mascota.tamano = 0;
    mascota.index = mascotas.length;
    mascota.comodidades = [];
    mascota.amiguero = 1;
    mascota.enfermo = 0;
    mascota.cachorro = 0;
    mascota.sex = sex;
    mascota.edad = 0;
    setCurrentMascotas(mascotas);
    nextStep();
  };

  const handleAddNewMascota = () => {
    let mascotas = [...currentMascotas];
    let emptyMascota = {
      nombre: '',
      especie: '',
      tamano: 0,
      index: 0,
      comodidades: [],
      amiguero: 1,
      enfermo: 0,
      cachorro: 0,
      sex: '',
      edad: 0,
    };

    mascotas.splice(0, 0, emptyMascota);

    setCurrentMascotas(mascotas);
    setName(null);
    setEdad(null);
    setTamano(null);
    setEspecie(null);
    setCurrentStep(1);
  };

  const handleEdad = (index) => {
    let mascotas = [...currentMascotas];
    let currentMascota = mascotas[0];
    currentMascota.edad = index;
    setCurrentMascotas(mascotas);
    setEdad(index);
  };

  const handleTamano = (index) => {
    let mascotas = [...currentMascotas];
    let currentMascota = mascotas[0];
    currentMascota.tamano = index;
    setCurrentMascotas(mascotas);
    setTamano(index);
  };

  const handleComodidad = (comodidad) => {
    let mascotas = [...currentMascotas];
    let currentMascota = mascotas[0];
    let comodidades = currentMascota.comodidades;
    if (comodidades.includes(comodidad)) {
      comodidades = comodidades.filter((i) => {
        return i !== comodidad;
      });
      currentMascota.comodidades = comodidades;
    } else {
      comodidades.push(comodidad);
    }
    setCurrentMascotas(mascotas);
  };

  const handleAmiguero = (value) => {
    let mascotas = [...currentMascotas];
    let currentMascota = mascotas[0];
    currentMascota.amiguero = value;
    setCurrentMascotas(mascotas);
  };

  const generateDescription = (mascota) => {
    // let edad = availableEdades[mascota.edad];
    let tamano = availableTamanos[mascota.tamano];
    let comodidades = mascota.comodidades;
    let amiguero = mascota.amiguero;
    // let service = servicio;

    let tamanoString = '';
    if (tamano === 'Pequeño' || tamano === 'Mediano') {
      tamanoString = `${mascota.nombre} es excepcional. Estamos seguros de que no tendría problemas para hacer match con un cuidador.`;
    } else {
      tamanoString = `${mascota.nombre} es excepcional. Si es tranquilo, estamos seguros de que habrá opciones encantadas de tenerlos en su hogar. Ten en cuenta que si es muy activo, quizá necesite más espacio que una casa familiar y sería mejor ver un hotel.`;
    }

    let comodidadesString = '';
    if (comodidades.length > 0) {
      if (comodidades.includes('jardin')) {
        comodidadesString =
          'Por otro lado, tenemos muy pocos afiliados con patio o jardín. Acá te presentamos algunas opciones, ¡ojalá alguno de ellos esté disponible! Te recomendamos que evalúes si puede quedarse en un espacio más pequeño para tener más opciones.';
      }
    }

    let amigueroString = '';
    if (amiguero === 1) {
      //nada.
    } else if (amiguero === 2) {
      amigueroString =
        'Es importante que cuando le escribas a un cuidador, especifiques con qué tipo de perros no se lleva bien o de frente solicites una casa exclusiva.';
    } else {
      amigueroString =
        'Como tu perrito no se lleva bien con otro, mejor solicita una casa exclusiva. Ten en cuenta que hay muy pocas por lo que tendrás que agendar tu servicio con antelación.';
    }

    return (
      <>
        {tamanoString}
        <br />
        {comodidadesString}
        <br />
        {amigueroString}
      </>
    );
  };

  const getProgress = () => {
    return currentStep * 20;
  };

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return (
          <>
            <h1 style={{ textAlign: 'center' }}>¿CÓMO SE LLAMA TU MASCOTA?</h1>
            <div className="spacer"></div>
            <input
              value={name}
              onChange={(t) => {
                setName(t.target.value);
              }}
              placeholder="Se llama..."
              className={`input ${nameError ? 'error' : ''}`}
            />
            {nameError && <div className="error-message">Introduce un nombre válido</div>}
            {renderMascotasSlider()}
            <div className="sex-buttons-container">
              <button
                onClick={() => {
                  addNewMascota('male');
                }}
                className="sex-button male">
                Es macho
              </button>
              <button
                onClick={() => {
                  addNewMascota('female');
                }}
                className="sex-button female">
                Es hembra
              </button>
            </div>
          </>
        );

      case 2:
        return (
          <>
            <h1 style={{ textAlign: 'center' }}>CUÉNTANOS MÁS</h1>

            <div className="spacer"></div>
            <div className="tamano-selector">
              <div className="tamano-card">
                <img src={EdadImage} alt="extra 1" />
                <h3>¿Qué edad tiene?</h3>
                <Stepper nonLinear activeStep={edad} alternativeLabel>
                  {availableEdades.map((label, index) => (
                    <Step key={label}>
                      <StepButton
                        color="inherit"
                        onClick={() => {
                          handleEdad(index);
                        }}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </div>
              <div className="tamano-card">
                <img className="small" src={TamanoImage} alt="extra 2" />
                <h3>¿De qué tamaño es?</h3>
                <Stepper nonLinear activeStep={tamano} alternativeLabel>
                  {availableTamanos.map((label, index) => (
                    <Step key={label}>
                      <StepButton
                        color="inherit"
                        onClick={() => {
                          handleTamano(index);
                        }}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>
            <button
              onClick={() => {
                nextStep();
              }}
              style={{ marginTop: 48 }}
              className="btn-primary medium btn-step">
              Siguiente
            </button>
          </>
        );
      case 3:
        return (
          <>
            <h1 style={{ textAlign: 'center' }}>¿A QUÉ ESTÁ ACOSTUMBRADO?</h1>
            <h4>Puedes seleccionar varias opciones o ninguna</h4>

            <div className="spacer"></div>
            <div className="comodidades-selector">
              <div
                className={`comodidades-card ${
                  currentMascotas[0].comodidades.includes('jardin') ? 'card-selected' : ''
                }`}
                onClick={() => {
                  handleComodidad('jardin');
                }}>
                <img src={JardinImage} alt="place 1" />
                PATIO/JARDÍN
              </div>
              <div
                className={`comodidades-card ${
                  currentMascotas[0].comodidades.includes('sofa') ? 'card-selected' : ''
                }`}
                onClick={() => {
                  handleComodidad('sofa');
                }}>
                <img src={SofaImage} alt="place 2" />
                SUBIR AL SOFÁ
              </div>
              <div
                className={`comodidades-card ${
                  currentMascotas[0].comodidades.includes('cama') ? 'card-selected' : ''
                }`}
                onClick={() => {
                  handleComodidad('cama');
                }}>
                <img src={CamaImage} alt="place 3" />
                SUBIR A LA CAMA
              </div>
            </div>
            <button
              onClick={() => {
                nextStep();
              }}
              style={{ marginTop: 48 }}
              className="btn-primary medium btn-step">
              Siguiente
            </button>
          </>
        );
      case 4:
        return (
          <>
            <h1 style={{ textAlign: 'center' }}>¿ES AMIGABLE?</h1>
            <div className="spacer"></div>
            <div className="amiguero-selector">
              <div
                className={`amiguero-card ${
                  currentMascotas[0].amiguero === 1 ? 'card-selected' : ''
                }`}
                onClick={() => {
                  handleAmiguero(1);
                }}>
                <img src={AmigueroImage1} alt="friendly 1" />
                SE LLEVA BIEN CON TODOS
              </div>
              <div
                className={`amiguero-card ${
                  currentMascotas[0].amiguero === 2 ? 'card-selected' : ''
                }`}
                onClick={() => {
                  handleAmiguero(2);
                }}>
                <img src={AmigueroImage2} alt="friendly 2222" />
                DEPENDE SI LE CAE BIEN
              </div>
              <div
                className={`amiguero-card ${
                  currentMascotas[0].amiguero === 0 ? 'card-selected' : ''
                }`}
                onClick={() => {
                  handleAmiguero(0);
                }}>
                <img src={AmigueroImage3} alt="friendly 3" />
                PREFIERE A LAS PERSONAS
              </div>
            </div>
            <div className="next-add-container">
              {currentMascotas.length < 2 && (
                <button
                  onClick={() => {
                    handleAddNewMascota();
                  }}
                  style={{ marginTop: 48, marginRight: 8, marginLeft: 8 }}
                  className="btn-primary medium btn-step btn-step-large">
                  Agregar otra mascota
                </button>
              )}
              <button
                onClick={() => {
                  nextStep();
                }}
                style={{ marginTop: 48, marginLeft: 8, marginRight: 8 }}
                className="btn-primary medium btn-step btn-step-large">
                Ver las opciones más adecuadas
              </button>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <h1 style={{ textAlign: 'center' }}>
              ¿QUÉ SERVICIO PREFIERE {currentMascotas[0].nombre.toUpperCase()}?
            </h1>
            <div className="spacer"></div>
            <div className="servicio-selector">
              <div
                className={`servicio-card ${servicio === 'hospedaje' ? 'card-selected' : ''}`}
                onClick={() => {
                  setServicio('hospedaje');
                }}>
                <img src={HospedajeImage} alt="service 1" />
                HOSPEDAJE
                <p className="">(Pasar la noche)</p>
              </div>

              <div
                className={`servicio-card ${servicio === 'guarderia' ? 'card-selected' : ''}`}
                onClick={() => {
                  setServicio('guarderia');
                }}>
                <img src={GuarderiaImage} alt="service 2" />
                GUARDERÍA
                <p className="">(Pasar el día)</p>
              </div>

              <div
                className={`servicio-card ${servicio === 'delivery' ? 'card-selected' : ''}`}
                onClick={() => {
                  setServicio('delivery');
                }}>
                <img src={ADomicilioImage} alt="service 3" />A DOMICILIO
                <p className="">(Cuidados en tu casa)</p>
              </div>

              <div
                className={`servicio-card ${servicio === 'paseos' ? 'card-selected' : ''}`}
                onClick={() => {
                  setServicio('paseos');
                }}>
                <img src={PaseosImage} alt="service 4" />
                PASEOS
                <p className="">(Hasta de 1 hora)</p>
              </div>
            </div>
            <button
              onClick={() => {
                endQuestionarie();
              }}
              style={{ marginTop: 48 }}
              className="btn-primary medium btn-step">
              Siguiente
            </button>
          </>
        );
      case 6:
        return (
          <>
            <div className="results-container">
              <div className="results-summary">
                {currentMascotas.map((mascota) => {
                  return (
                    <div className="result-mascota">
                      <div className="title-container">
                        <img src={ResultSticker} alt="current 1" />
                        <h3 className="title">¡{mascota.nombre}!</h3>
                      </div>
                      <h3 className="subtitle">estos son tus housers ideales</h3>
                      <div className="mascota-description">
                        {generateDescription(mascota)}
                        Puedes escribirle a los que te sugerimos o escoger algún otro del mapa.
                        Te recomendamos contactar al menos tres para que tengas más opciones.
                        ¡Mucha suerte!
                      </div>
                    </div>
                  );
                })}
                <div className="map-button-container">
                  <button
                    onClick={() => {
                      skip();
                    }}
                    className="btn-primary">
                    Ir directamente al mapa
                  </button>
                </div>
              </div>
              <div className="houses-container">
                {results.map((result) => {
                  console.log(result);
                  return (
                    <div className="result-card">
                      <Card variant="outlined" key={result.id} className="result-card">
                        <CardMedia
                          component="img"
                          alt="gallery"
                          height="160"
                          image={
                            result.guarderiaMadrid
                              ? GuarderiaMadrid
                              : VARIABLES.IMAGE_URL + result.houser.galleries[0].photo
                          }
                        />
                        <CardContent>
                          <a
                            className="link-cuidador"
                            href={
                              result.guarderiaMadrid
                                ? 'https://www.doghouser.es/'
                                : `cuidador/${result.id}`
                            }
                            target="_blank"
                            rel="noreferrer">
                            <h4 class="truncate">{result.houser.publication.title}</h4>
                          </a>
                          <Grid direction="row">
                            <Box
                              sx={{ mt: 2 }}
                              style={{ flexDirection: 'row', display: 'flex' }}>
                              {!result.guarderiaMadrid && (
                                <Avatar src={`${VARIABLES.IMAGE_URL}${result.photo}`} />
                              )}
                              {result.guarderiaMadrid && <Avatar src={DHLogo} />}
                              <Box sx={{ ml: 1 }}>
                                <h5>{result.name}</h5>
                                <div
                                  className="location"
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}>
                                  <RoomIcon />
                                  <span>{result.location.district}</span>
                                </div>
                              </Box>
                            </Box>
                            <div>
                              <button
                                onClick={() => {
                                  window.open(
                                    result.guarderiaMadrid
                                      ? 'https://www.doghouser.es/'
                                      : `cuidador/${result.id}`,
                                    '_blank',
                                  );
                                }}
                                style={{ marginTop: 12 }}
                                className="btn-primary medium btn-step">
                                Seleccionar
                              </button>
                            </div>
                          </Grid>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };

  return (
    <div className="dh-onboarding">
      {currentStep !== 6 && <LinearProgress variant="determinate" value={getProgress()} />}
      <div className="background"></div>
      <div className="container">
        <div class="btn-volver-skip-container">
          <div
            onClick={() => {
              previousStep();
            }}
            className="btn-volver">
            <FontAwesomeIcon
              style={{ marginRight: 6 }}
              icon={faChevronLeft}
              color="#80CDC5"
              size="xs"
            />
            Volver
          </div>
          <div
            onClick={() => {
              skip();
            }}
            className="btn-skip">
            Ir directamente al mapa{' '}
            <FontAwesomeIcon
              style={{ marginRight: 6 }}
              icon={faChevronRight}
              color="#80CDC5"
              size="xs"
            />
          </div>
        </div>
        {renderStep(currentStep)}
      </div>
      <div className="background"></div>
    </div>
  );
}

export default Home;
