import React, { useState, useEffect } from 'react';

import * as OPTIONS from '@root/services/options';
import * as SERVICES from '@root/services/config';
// Components
import Notification from '../notification/Notification';
// Hooks
import { useInputValue } from '@root/hooks/useInputValue';
// Material-UI
import TextField from '@material-ui/core/TextField';

function StepNewPetDetalle(props) {
  const { id, petId, editPet, handleNext } = props;
  // Variantes del componente "Notification"
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  const rutine = useInputValue('');
  const medical_history = useInputValue('');
  const medicine = useInputValue('');
  const feeding = useInputValue('');
  const additional = useInputValue('');

  useEffect(() => {
    if (editPet) {
      rutine.update(editPet.rutine);
      medical_history.update(editPet.medical_history);
      medicine.update(editPet.medicine);
      feeding.update(editPet.feeding);
      additional.update(editPet.additional);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPet]);

  // Funcion para cerrar el componente Notification
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchUpdatePet();
  };

  const fetchUpdatePet = () => {
    setMessage('');
    setMessageState(false);
    const body = {
      id: id,
      pet_id: petId,
      rutine: rutine.value,
      medical_history: medical_history.value,
      medicine: medicine.value,
      feeding: feeding.value,
      additional: additional.value,
    };
    sendUserData(SERVICES.UPDATE_PET_INSTRUCTIONS, OPTIONS.POST(body));
  };

  // Enviar datos de la mascota al servidor
  const sendUserData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        setMessage('Datos actualizados');
        setMessageVariant('success');
        setMessageState(true);
        handleNext();
      } else {
        setMessage(data.message);
        setMessageVariant('warning');
        setMessageState(true);
        handleNext();
      }
    } catch (error) {
      setMessage(error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <React.Fragment>
      <h1 className="marginx05">Instrucciones de cuidado</h1>
      <div className="new-pet-detalle-container">
        <form onSubmit={handleSubmit}>
          <h3>¿Cuál es su rutina de paseos?</h3>
          <TextField
            id="rutine"
            name="rutine"
            required
            fullWidth
            variant="outlined"
            type="text"
            value={rutine.value}
            onChange={rutine.onChange}
          />
          <h3>¿Cuáles son sus antecedentes médicos?</h3>
          <TextField
            id="medical_history"
            name="medical_history"
            required
            fullWidth
            variant="outlined"
            type="text"
            value={medical_history.value}
            onChange={medical_history.onChange}
          />
          <h3>¿Actualmente toma medicinas?</h3>
          <TextField
            id="medicine"
            name="medicine"
            required
            fullWidth
            variant="outlined"
            type="text"
            value={medicine.value}
            onChange={medicine.onChange}
          />
          <h3>¿Como es su alimentación?</h3>
          <TextField
            id="feeding"
            name="feeding"
            required
            fullWidth
            variant="outlined"
            type="text"
            value={feeding.value}
            onChange={feeding.onChange}
          />
          <h3>Recomendaciones adicionales</h3>
          <TextField
            id="additional"
            name="additional"
            required
            fullWidth
            variant="outlined"
            type="text"
            value={additional.value}
            onChange={additional.onChange}
          />
          <button type="submit" className="btn-primary medium btn-step">
            Guardar y Continuar
          </button>
        </form>
      </div>
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </React.Fragment>
  );
}

export default StepNewPetDetalle;
