import { useState } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';

import ServiceDTO from '@root/models/ServiceDTO';
import { LEVEL_VARIANT } from '@root/constants/variables';
import styles from './addServicesDialog.module.scss';

interface AddServicesDialogType extends DialogProps {
  onClose: (servicesId?: Array<number>) => void;
  open: boolean;
  services: Array<ServiceDTO>;
  state: any;
  setState: (obj) => void;
}

const AddServicesDialog = (props: AddServicesDialogType) => {
  const { onClose, open, services, state, setState, ...other } = props;
  const [servicesId, setServicesId] = useState<Array<number>>([]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(servicesId);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = { ...state, [event.target.name]: event.target.checked };
    setState(newState);
    const serviceArray = [];
    services.forEach((res) => {
      if (newState['service' + res.id]) {
        serviceArray.push(res.id);
      }
    });
    setServicesId(serviceArray);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={handleCancel}
      PaperProps={{
        style: {
          maxHeight: 560,
        },
      }}
      {...other}>
      <DialogTitle disableTypography id="service-dialog-title">
        <h4>Añadir servicios</h4>
      </DialogTitle>
      <DialogContent dividers style={{ padding: '8px 16px' }}>
        <FormGroup className={styles.formGroup}>
          {services.map((service, index) => {
            const houserPrice = parseInt(service?.houser_comission, 10);
            const grossComission = parseInt(service?.gross_comission, 10);
            const price = houserPrice + grossComission;
            const money = service?.money?.symbol;
            return (
              <FormControlLabel
                key={index}
                className={styles.formLabel}
                control={
                  <Checkbox
                    checked={state['service' + service.id]}
                    onChange={handleChange}
                    name={'service' + service.id}
                    color="primary"
                  />
                }
                label={
                  <span className={styles.item}>
                    <span className={styles.title}>{service.name + ' #' + service.id}</span>
                    <span className={styles.content}>
                      <span>
                        {service.city}, {service.country} - Houser{' '}
                        {LEVEL_VARIANT[service.houser_level_id]}
                      </span>
                      <span>
                        <strong>{money + price}</strong>
                        {' = '}
                        <span className={styles.hint}>
                          ({money + houserPrice} houser + {money + grossComission} comisión)
                        </span>
                      </span>
                    </span>
                  </span>
                }
              />
            );
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="default">
          Cancelar
        </Button>
        <Button onClick={handleOk} disableElevation color="primary" variant="contained">
          Añadir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddServicesDialog;
