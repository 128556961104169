const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiaWF0IjoxNjg5NzI4NzI5LCJqdGkiOiJmOTY1YmM2ZTE0OTA0YWVmOWE1ZDQ2M2FjNzk4YjFkNyIsInVzZXJfZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJpbml0aWFscyI6bnVsbCwicm9sZSI6IjY0IiwidXNlcm5hbWUiOiJhZG1pbiJ9.UE3vEQJTgLLWR7LGLI9H-GSKo1P5dSBb74wGyJfVXgo';

export const GET = () => {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};

export const POST = (body) => {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
};

export const POST_FORM_DATA = (body) => {
  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: body,
  };
};
