import React, { useState } from 'react';
import moment from 'moment';
// Hooks
import TablePaginationActions from 'hooks/TablePaginationActions';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
// Material Iconos
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 950,
  },
}));

const TableRowHead = (
  <TableRow>
    <TableCell>Id</TableCell>
    <TableCell>Cod</TableCell>
    <TableCell>Porcentaje</TableCell>
    <TableCell>Descripción</TableCell>
    <TableCell>Usado</TableCell>
    <TableCell align="center">Acciones</TableCell>
  </TableRow>
);

const SkeletonRow = (
  <TableRow>
    <TableCell scope="row">
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
  </TableRow>
);

function TablePromotions(props) {
  const { promotions, loading, handleDelete } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [clientsPerPage, setClientsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeClientsPerPage = (event) => {
    setClientsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="dh-admin-housers__table-housers--layout dh-simple-elevation">
      <Table className={classes.table}>
        <TableHead className="table-head">{TableRowHead}</TableHead>
        {!loading ? (
          <React.Fragment>
            <TableBody className="table-body">
              {promotions
                .slice(page * clientsPerPage, page * clientsPerPage + clientsPerPage)
                .map((promo) => (
                  <TableRow key={promo.id}>
                    <TableCell scope="row">#{promo.id}</TableCell>
                    <TableCell scope="row">{promo.cod}</TableCell>
                    <TableCell scope="row">{promo.value}%</TableCell>
                    <TableCell scope="row">{promo.description}</TableCell>
                    <TableCell scope="row">
                      {promo.used_at ? moment(promo.used_at).format('MM/DD/YYYY HH:mm:ss') : ''}
                    </TableCell>
                    <TableCell align="center" className="table-body__client-action">
                      <IconButton
                        aria-label="ver datos del producto"
                        onClick={() => handleDelete(promo.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </React.Fragment>
        ) : (
          <TableBody className="table-body">
            {Array.from(new Array(10)).map((data, index) => (
              <React.Fragment key={index}>{SkeletonRow}</React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      <TablePagination
        rowsPerPageOptions={[50, 100, 150, 200]}
        component="div"
        count={promotions.length}
        rowsPerPage={clientsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'filas por página' },
          native: true,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeClientsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}

export default TablePromotions;
