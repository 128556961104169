import React from 'react'
import PropTypes from 'prop-types'
// Hooks
import { usePoppoverValue } from 'hooks/usePoppoverValue'
// Material-UI
import Popover from '@material-ui/core/Popover'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
// Material Icons
import FilterListIcon from '@material-ui/icons/FilterList'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

function FilterAdminPopover(props) {
  const { id, open, anchorEl, handlePopoverClose, title } = props
  return (
    <Popover
      id={id}
      open={open}
      className="dh-filter-selection__popover-filter"
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <div className="dh-filter-selection__popover-filter--title">
        <h3>{title}</h3>
      </div>
      {props.children}
      <div className="dh-filter-selection__popover-filter--actions">
        <button className="btn-primary" onClick={handlePopoverClose}>Guardar</button>
      </div>
    </Popover>
  )
}

const FilterSelection = props => {
  const { values, handleValuesChange } = props
  const extra = usePoppoverValue('extra-popover')

  return (
    <div className="dh-filter-selection">
      <div className="dh-filter-selection__item">
        <div className="dh-filter-selection__style">
          <FilterListIcon/>
        </div>
      </div>
      <div className="dh-filter-selection__item">
        <div className="dh-filter-selection__style">
          <span>Filtrar por</span>
        </div>
      </div>
      <div className="dh-filter-selection__item item-button" onClick={extra.handlePopoverOpen}>
        <div className="dh-filter-selection__style">
          <span>Extra</span>
          <ExpandMoreIcon/>
        </div>
      </div>
      {/* POPOVER para filtro de Ubicacion */}
      <FilterAdminPopover
        id={extra.id}
        open={extra.open}
        anchorEl={extra.anchorEl}
        handlePopoverClose={extra.handlePopoverClose}
        title="Extra">
        <FormControl component="fieldset" className="dh-filter-selection__popover-filter--formcontrol">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={values.email_true} onChange={handleValuesChange('email_true')} color="primary"/>}
              label="Email confirmado"
            />
            <FormControlLabel
              control={<Checkbox checked={values.email_false} onChange={handleValuesChange('email_false')} color="primary"/>}
              label="Email No confirmado"
            />
            <FormControlLabel
              control={<Checkbox checked={values.phone_true} onChange={handleValuesChange('phone_true')} color="primary"/>}
              label="Teléfono verificado"
            />
            <FormControlLabel
              control={<Checkbox checked={values.phone_false} onChange={handleValuesChange('phone_false')} color="primary"/>}
              label="Teléfono No verificado"
            />
          </FormGroup>
        </FormControl>
      </FilterAdminPopover>
    </div>
  )
}

FilterSelection.propTypes = {
  values: PropTypes.object,
  handleValuesChange: PropTypes.func
}

export default FilterSelection
