import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './AdminPromotion.scss';

import * as SERVICES from 'services/config';
import * as OPTIONS from '@root/services/options';
import * as ROUTES from 'constants/routes';
import Notification from 'components/notification/Notification';
import TablePromotions from './components/TablePromotions';
// DogHouser Icons
import AddIcon from 'icons/AddIcon';
// Hooks
import useNotificationValue from 'hooks/useNotificationValue';

function AdminPromotion() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const notification = useNotificationValue();

  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    fetchPromotions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPromotions = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(SERVICES.GET_PROMOS, OPTIONS.GET());
      const data = await response.json();
      if (response.status === 200) {
        setLoading(false);
        setPromotions(data.promotions.rows);
      } else {
        notification.handleSnackbar('Error de conexión con el servidor', 'error');
        setError('Error de conexión con el servidor');
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
      setError(error.message);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(SERVICES.DELETE_PROMO + id, OPTIONS.GET());
      // eslint-disable-next-line no-unused-vars
      const data = await response.json();
      if (response.status === 200) {
        setLoading(false);
        setPromotions((prev) => prev.filter((promo) => promo.id !== id));
      } else {
        notification.handleSnackbar('Error de conexión con el servidor', 'error');
        setError('Error de conexión con el servidor');
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      notification.handleSnackbar(error.message, 'error');
      setLoading(false);
      setError(error.message);
    }
  };

  if (error) {
    return (
      <div className="dh-admin-promotions">
        <div className="dh-admin-promotions__section-header">
          <h2 className="admin-title">Promociones</h2>
        </div>
        <div className="margin-bottom-x1">
          <h4 className="admin-title">Error: {error}</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="dh-admin-promotions">
      <div className="dh-admin-promotions__section-header">
        <h2 className="admin-title">Promociones</h2>
        <RouterLink to={ROUTES.ADMIN_PROMOTIONS_NEW}>
          <button className="btn-primary icon">
            <AddIcon color="#fff" />
            Crear Promocion
          </button>
        </RouterLink>
      </div>
      <div className="dh-admin-promotions__table-promotions">
        <p className="dh-admin-promotions__table-promotions--count-promotions">
          {promotions.length} entradas
        </p>
        <TablePromotions
          promotions={promotions}
          loading={loading}
          handleDelete={handleDelete}
        />
      </div>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default AdminPromotion;
