import React, { useContext, useState } from "react";
import { Context } from "@root/Context";
import "./manageLocation.scss";
// Dependencies
import Select from "react-select";
// Utils
import { country as countryList, variantCityByCountry } from "utils/Resources";

function ManageLocation() {
  const { country, city, setCountry, setCity } = useContext(Context);
  const [locationState, setLocationState] = useState(false);
  const [values, setValues] = useState({
    country: "País",
    city: "Ciudad",
  });
  const [cityArray, setCityArray] = useState([]);

  const manageLocationState = (state) => {
    setLocationState(state);
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.value });
    if (name === "country") {
      setCountry(event.value);
      setCityArray(variantCityByCountry[event.value]);
    } else {
      setCity(event.value);
    }
  };

  return (
    <div className="manage-location__container dh-flex items-end">
      {!locationState ? (
        <React.Fragment>
          <h2 className="manage-location__info-location">
            {city}, {country}
          </h2>
          <p
            className="manage-location__btn-new-location"
            onClick={() => manageLocationState(true)}
          >
            Cambiar de ubicación
          </p>
        </React.Fragment>
      ) : (
        <div className="manage-location__select-location">
          <div className="select-container">
            <Select
              className="select-country"
              value={values.country}
              onChange={handleChange("country")}
              placeholder={values.country}
              options={countryList}
            />
            <Select
              className="select-country"
              value={values.city}
              onChange={handleChange("city")}
              placeholder={values.city}
              options={cityArray}
            />
          </div>
          <div
            className="dh-flex items-center"
            onClick={() => manageLocationState(false)}
          >
            <button className="btn-primary">Guardar</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageLocation;
