import ResponseType, { ResponseHouserServicesType } from '@root/interfaces/Response';
import requests from './requests';

const getServicesByHouser = (houser_id: number): Promise<ResponseHouserServicesType> => {
  const url = `/houser-services/by-houser/${houser_id}`;

  return requests.get(url);
};

const getHousersByService = (service_id: number): Promise<ResponseType> => {
  const url = `/houser-services/by-service/${service_id}`;

  return requests.get(url);
};

const create = (body): Promise<ResponseHouserServicesType> => {
  const url = `/houser-services/create`;

  return requests.post(url, body);
};

const destroy = (houser_services_id: number): Promise<ResponseType> => {
  const url = `/houser-services/destroy/${houser_services_id}`;

  return requests.delete(url);
};

const houserServices = {
  getServicesByHouser,
  getHousersByService,
  create,
  destroy,
};

export default houserServices;
