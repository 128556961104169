import { ChangeEvent, useState } from 'react';
import { Grid, TextField, MenuItem, FormControlLabel, RadioGroup } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';

import { useSnackbar } from '@root/hooks';
import * as OPTIONS from '@root/services/options';
import * as SERVICES from '@root/services/config';
import MainDialog from '@root/ui-component/dialog/MainDialog';
import PetTypeRadio from '@root/ui-component/inputs/PetTypeRadio';
import PetSizeRadio from '@root/ui-component/inputs/PetSizeRadio';
import styles from './createPetDialog.module.scss';

interface CreatePetDialogProps extends DialogProps {
  onClose: () => void;
  open: boolean;
  handleCreatePet: (pet) => void;
}

const CreatePetDialog = (props: CreatePetDialogProps) => {
  const { open, onClose, handleCreatePet } = props;
  const snackbar = useSnackbar();
  const [breeds, setBreeds] = useState([]);
  const [pet, setPet] = useState({
    name: '',
    pet_type: null,
    breed_id: '',
    birth_date: '',
    pet_size: null,
    description: '',
  });

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    if (!pet.name) {
      snackbar.warning('El nombre de la mascota es ogligatorio.');
      return;
    }
    handleCreatePet(pet);
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number((event.target as HTMLInputElement).value);
    const name = (event.target as HTMLInputElement).name;
    setPet({ ...pet, [name]: value });
    name === 'pet_type' && fetchBreeds(value);
  };

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPet({ ...pet, [event.target.name]: event.target.value });
  };

  const fetchBreeds = async (petTypeId: number) => {
    try {
      const response = await fetch(SERVICES.GET_BREEDS + petTypeId, OPTIONS.GET());
      const data = await response.json();
      if (data.status === 200) {
        setBreeds(data.breeds);
      }
    } catch (error) {
      snackbar.error(error.message);
    }
  };

  return (
    <MainDialog
      fullWidth
      maxWidth="sm"
      id="create-pet-dialog"
      open={open}
      onClose={handleCancel}
      title="Añadir mascota"
      onOk={handleOk}
      onCancel={handleCancel}>
      <div className={styles.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={styles.field}>
            <span className={styles.label}>Nombre:</span>
            <TextField
              required
              fullWidth
              id="name"
              name="name"
              variant="outlined"
              size="small"
              type="text"
              value={pet.name}
              onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid item xs={12} className={styles.field}>
            <span className={styles.label}>Tipo de mascota:</span>
            <RadioGroup
              className={styles.typeWrap}
              aria-label="type"
              name="pet_type"
              value={pet.pet_type}
              onChange={handleRadioChange}>
              <FormControlLabel
                value={1}
                control={<PetTypeRadio type={1} />}
                label="Perro"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value={2}
                control={<PetTypeRadio type={2} />}
                label="Gato"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value={3}
                control={<PetTypeRadio type={3} />}
                label="Conejo"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value={4}
                control={<PetTypeRadio type={4} />}
                label="Exótico"
                labelPlacement="bottom"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} md={6} className={styles.field}>
            <span className={styles.label}>Raza:</span>
            <TextField
              select
              required
              fullWidth
              name="breed_id"
              variant="outlined"
              size="small"
              value={pet.breed_id}
              onChange={handleTextFieldChange}>
              {breeds.map((breed) => (
                <MenuItem key={breed.id} value={breed.id}>
                  {breed.breed}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} className={styles.field}>
            <span className={styles.label}>Fecha de nacimiento:</span>
            <TextField
              required
              fullWidth
              id="birth_date"
              name="birth_date"
              type="date"
              variant="outlined"
              size="small"
              value={pet.birth_date}
              onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid item xs={12} className={styles.field}>
            <span className={styles.label}>Tamaño:</span>
            <RadioGroup
              className={styles.sizeWrap}
              aria-label="type"
              name="pet_size"
              value={pet.pet_size}
              onChange={handleRadioChange}>
              <FormControlLabel
                className={styles.small}
                value={0}
                control={<PetSizeRadio type={0} />}
                label="Pequeño"
                labelPlacement="bottom"
              />
              <FormControlLabel
                className={styles.medium}
                value={1}
                control={<PetSizeRadio type={1} />}
                label="Mediano"
                labelPlacement="bottom"
              />
              <FormControlLabel
                className={styles.big}
                value={2}
                control={<PetSizeRadio type={2} />}
                label="Grande"
                labelPlacement="bottom"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} className={styles.field}>
            <span className={styles.label}>Descripción:</span>
            <TextField
              required
              fullWidth
              id="description"
              name="description"
              type="text"
              variant="outlined"
              multiline
              rows="3"
              value={pet.description}
              onChange={handleTextFieldChange}
            />
          </Grid>
        </Grid>
      </div>
    </MainDialog>
  );
};

export default CreatePetDialog;
