import { createTheme } from '@material-ui/core';

import themeVars from '@root/styles/_themes-vars.module.scss';
import themePalette from './palette';
import themeTypography from './typography';
import themeShadows from './shadows';
import componentStyleOverrides from './overrides';

export const theme = () => {
  const themeOption = {
    ...themeVars,
  };

  return createTheme({
    direction: 'ltr',
    palette: themePalette(themeOption),
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: themeTypography(themeOption),
    overrides: componentStyleOverrides(themeOption),
    shadows: themeShadows(themeOption),
  });
};

export default theme;
