import React from 'react';

import './cardUserEmergencyInfo.scss';
// Material-UI
import TextField from '@material-ui/core/TextField';

const InfoRow = ({ id, text, type, value, onChange, disabled }) => (
  <li>
    <label htmlFor={id}>{text}</label>
    <TextField
      required
      disabled={disabled}
      variant="outlined"
      id={id}
      name={id}
      type={type}
      value={value}
      onChange={onChange}
    />
  </li>
);

function CardUserEmergencyInfo(props) {
  const { data, handleSubmit, handleInputChange, btnState } = props;

  return (
    <div className="dh-card-user-info dh-card-border">
      <form className="info-form" onSubmit={handleSubmit}>
        <ul className="flex-outer">
          <InfoRow
            id="emergency_contact_name"
            text="Nombre:"
            type="text"
            value={data.user.emergency_contact_name}
            onChange={handleInputChange}
          />
          <InfoRow
            id="emergency_contact_phone"
            text="Teléfono:"
            type="text"
            value={data.user.emergency_contact_phone}
            onChange={handleInputChange}
          />
          <li className="last-row">
            <button className={`btn-primary ${btnState}`} type="submit">
              Guardar Cambios
            </button>
          </li>
        </ul>
      </form>
    </div>
  );
}

export default CardUserEmergencyInfo;
