const API_URL = process.env.REACT_APP_API;

/* ----------- Iniciar Sesión / Registrarse ---------- */

// POST - Login con email y contraseña
export const LOGIN_EMAIL = API_URL + '/login';
// POST - Registro con email y contraseña
export const REGISTER_EMAIL = API_URL + '/register';
// POST - Login y Registro con Google
export const LOGIN_AND_REGISTER_GOOGLE = API_URL + '/google';
// POST - Login y Registro con Facebook
export const LOGIN_AND_REGISTER_FACEBOOK = API_URL + '/facebook';

/* ---------------- Usuario General -------------- */

// Obtener usuario por Id - POST
export const USER_BY_ID = API_URL + '/userById';

// POST - Subir foto de usuario al servidor
export const UPLOAD_PHOTO_USER = API_URL + '/uploadPhoto';

/* ---------------- Usuario Cliente -------------- */

// GET - Get all the clients
export const GET_ALL_CLIENTS = API_URL + '/clients';
// POST - Actualizar datos basicos de un usuarios
export const UPDATE_BASIC_USER = API_URL + '/updateDataProfile';

/* ---------------- Usuario Cuidador ------------- */

// All - Get all the housers
export const GET_ALL_HOUSERS = API_URL + '/housers';

// POST - Actualizar datos del usuario - cuidador
export const UPDATE_HOUSER_STEP_1 = API_URL + '/registerStepOne';
export const UPDATE_HOUSER_STEP_2 = API_URL + '/registerStepTwo';
export const UPDATE_HOUSER_STEP_3 = API_URL + '/registerStepThree';
export const UPDATE_HOUSER_STEP_4 = API_URL + '/registerStepFour';
export const UPDATE_HOUSER_STEP_5 = API_URL + '/registerStepFive';
export const UPDATE_HOUSER_STEP_6 = API_URL + '/registerStepSix';

export const updateHouserStep2 = API_URL + '/registerStepTwo';
export const updateHouserStep5 = API_URL + '/registerStepFive';

// Obtener Cuidador por ID - POST
export const getCuidador = API_URL + '/houserById';
export const CUIDADOR_BY_ID = API_URL + '/houserById';
export const GET_CUIDADOR_BY_ID = API_URL + '/houserById';

// Obtener Lista de cuidadores mediante un flitro
export const LIST_FILTER_CUIDADORES = API_URL + '/filterHouser';

/* ----------------- Publicacion ------------------ */

// Subir foto a Galeria - POST
export const UPLOAD_PHOTO_PUBLICATION = API_URL + '/uploadPhotoPublication';
export const uploadPhotoPublication = API_URL + '/uploadPhotoPublication';

// Obtener Galeria por ID de usuario - POST
export const GET_GALLERY_BY_USER = API_URL + '/getGallerybyUser';
export const getGalleryByUser = API_URL + '/getGallerybyUser';

export const DELETE_PHOTO_GALLERY = API_URL + '/deletePhotoGalery';
export const UPDATE_PUBLICATION_STATUS = API_URL + '/updatePublication';

/* ---------------- Mascotas --------------- */

// GET - Obtener los tipos de mascota
export const GET_TYPE_PETS = API_URL + '/getTypePets';
// GET - Obtener las razas de un tipo de mascota en especifico
export const GET_BREEDS = API_URL + '/breeds/'; // :pet_type_id
// POST - Get all the pets for an specific user
export const PETS_BY_USER = API_URL + '/petsByUser';
// POST -  Find a pet by Id
export const GET_PET = API_URL + '/petsById';
// POST - Create a Pet
export const REGISTER_PET = API_URL + '/registerPet';
// POST - Update photo of a pet
export const UPLOAD_PHOTO_PET = API_URL + '/uploadPhotoPet';
// POST - Update extra data of a pet (Instructions)
export const UPDATE_PET_INSTRUCTIONS = API_URL + '/instructionCare';

/* ---------- Servicios ------------ */

export const GET_ALL_SERVICES = API_URL + '/services';
export const GET_ALL_SERVICES_BY_LOCATION = API_URL + '/services/filter/'; // :country/:city
export const GET_SERVICE_BY_ID = API_URL + '/services/find/'; // :id
export const CREATE_SERVICE = API_URL + '/services/create';
export const UPDATE_SERVICE = API_URL + '/services/update';
export const GET_SERVICE_BY_HOUSER = API_URL + '/services/find/byhouser/'; // :houser_id
export const CREATE_HOUSER_SERVICE = API_URL + '/services/create/houser_service';

/* ---------- Booking ------------ */
export const ALL_BOOKING = API_URL + '/booking';
export const CREATE_BOOKING = API_URL + '/booking/create';
export const UPDATE_BOOKING = API_URL + '/booking/update';
export const GET_BOOKING_BY_ID = API_URL + '/booking/getbyid/';
export const GET_PAYMENT_BY_ID = API_URL + '/booking/getpayment/';
export const ALL_BOOKINGS_BY_CLIENT = API_URL + '/booking/client/'; // :user_id
export const ALL_BOOKINGS_BY_HOUSER = API_URL + '/booking/'; // :houser_id
export const UPDATE_BOOKING_STATUS = API_URL + '/booking/status/update/';
export const ADD_COMMENT = API_URL + '/booking/add-comment/';
export const GET_BOOKING_COMMENTS = API_URL + '/booking/get-comments/';
export const USE_PROMO = API_URL + '/booking/checkout/promotion/';
export const GET_PROMOS = API_URL + '/promotions/';
export const CREATE_PROMOS = API_URL + '/promotions/create';
export const DELETE_PROMO = API_URL + '/promotions/delete/';

export const SNS_TEST = API_URL + '/notify-test';
/* ---------- Message ------------ */
export const GET_MESSENGER_TOKEN = API_URL + '/booking/msn-token';
export const SET_CHANNEL_ID = API_URL + '/booking/store-channel';
export const NOTIFY_NEW_MESSAGE = API_URL + '/booking/notify-message';

/* ---------- Reservation ------------ */

export const CREATE_RESERVATION = API_URL + '/reservation';
export const ALL_RESERVATIONS = API_URL + '/reservation';
export const ALL_RESERVATIONS_BY_CLIENT = API_URL + '/reservation/client/'; // :user_id
export const ALL_RESERVATIONS_BY_HOUSER = API_URL + '/reservation/'; // :houser_id
export const GET_RESERVATION_BY_ID = API_URL + '/reservation/getbyid/'; // :id
export const UPDATE_RESERVATION_TO_STATE_3 = API_URL + '/reservation/update/pay';
export const UPDATE_RESERVATION_STATUS = API_URL + '/reservation/status/update';
export const CREATE_RESERVATION_METHOD_PAY = API_URL + '/reservation/pay/update';

/* ---------- Recuperar contraseña ------------ */

// POST - Obtener codigo de recuperar contraseña
export const LOST_PASSWORD = API_URL + '/resetpwd';
// POST - Generar nueva contraseña
export const NEW_PASSWORD = API_URL + '/finalresetpwd';

/* ---------- Validar Telefono ------------ */

// POST - Enviar Codigo de validacion al telefono del usuario
export const SEND_PHONE = API_URL + '/sendMessage';
export const SEND_MESSAGE_EMAIL_SMS = API_URL + '/sendMessageEmailSms';
// POST - Validar el telefono mediante un codigo
export const VALIDATE_PHONE = API_URL + '/validateToken';
// POST - Validar el telefono de un usuario, servicio solo para admin
export const VALIDATE_PHONE_ADMIN = API_URL + '/activateNumber';

/* ----------- Comentarios ------------- */
export const GET_COMMENTS = API_URL + '/comments/'; // :houser_id
export const CREATE_COMMENT = API_URL + '/comment/create';

/* ---------- Lugares Petfriendly ------------ */
export const CREATE_PETFRIENDLY_PLACE = API_URL + '/petfriendly/create';
export const GET_PETFRIENDLY_PLACE = API_URL + '/petfriendly/list';
export const GET_PLACE_DETAIL = API_URL + '/petfriendly/detail/:id/:country/:city';
export const GET_PLACE = API_URL + '/petfriendly/detail';
export const UPLOAD_PHOTO_PETFRIENDLY_CARD = API_URL + '/uploadPhotoImageCard';
export const UPLOAD_PHOTO_PETFRIENDLY_DETAIL = API_URL + '/uploadPhotoImageDetail';

/* ---------- Notifications ------------ */
export const ALL_NOTIFICATIONS_BY_HOUSER = API_URL + '/notifications/'; // :houser_id
export const UNREAD_COUNT_BY_HOUSER = API_URL + '/notifications/unreadCount/'; // :houser_id
export const READ_ALL_NOTIFICATION = API_URL + '/notifications/readAll/'; // :houser_id
export const REMOVE_NOTIFICATION = API_URL + '/notifications/remove/'; // :not_id

/* --------- Medium ------------ */
export const GET_BLOG_MEDIUM =
  'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@doghouser';

/* --------- Wordpress ------------ */
export const GET_BLOG_WORDPRESS = 'http://blog.doghouser.com//wp-json/wp/v2/posts';
export const GET_POST_BY_ID = 'http://blog.doghouser.com/wp-json/wp/v2/posts/';
export const GET_POST_AUTHOR = 'http://blog.doghouser.com/wp-json/wp/v2/users/';

/* --------- Payment Accounts ------------ */
export const GET_ALL_PAYMENT = API_URL + '/getMethodPayments';
export const UPDATE_PAYMENTS = API_URL + '/updatePaymentAccount';
export const CREATE_PAYMENT = API_URL + '/registerPaymentAccount';

/* --------- Validation Test ------------ */
export const CONFIRM_TEST = API_URL + '/houser/confirm/examen';
export const VALIDATE_TEST = API_URL + '/houser/validate/examen';

/* --------- Reclamation ------------ */
export const CREATE_RECLAMATION = API_URL + '/reclamation';
