import React, { useEffect } from 'react'
// Services
import * as SERVICES from 'services/config'
import * as OPTIONS from 'services/options'
// Hooks
import { useInputValue } from  'hooks/useInputValue';
import useNotificationValue from 'hooks/useNotificationValue'
// Componets
import Notification from 'components/notification';
// Material-UI
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  text: {
    fontFamily: 'Montserrat-Regular',
    color: 'var(--textColor)',
    textAlign: 'center',
  },
  button: {
    marginTop: '1.5rem',
    textAlign: 'center'
  }
}));

function BookReclamation() {
  const classes = useStyles()
  
  // Variables de "Notification"
  const notification = useNotificationValue()

  const name = useInputValue('')
  const last_name = useInputValue('')
  const dni = useInputValue('')
  const domicilio = useInputValue('')
  const distrito = useInputValue('')
  const ciudad = useInputValue('')
  const celular = useInputValue('')
  const email = useInputValue('')
  const reclamo = useInputValue('')

  const handleSubmit = e => {
    e.preventDefault();
    fetchReclamation()
  }

  const fetchReclamation = () => {
    let body = {
      name: name.value,
      last_name: last_name.value,
      dni: dni.value,
      domicilio: domicilio.value,
      distrito: distrito.value,
      ciudad: ciudad.value,
      celular: celular.value,
      email: email.value,
      reclamo: reclamo.value
    }
    sentUserData(SERVICES.CREATE_RECLAMATION, OPTIONS.POST(body))
  }

  const sentUserData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      if(data.status === 200) {
        notification.handleSnackbar('Mensaje Enviado', 'success')
      } else {
        notification.handleSnackbar(data.message, 'error')
      }
    } catch(error) {
      notification.handleSnackbar('Error:' + error.message, 'error')
    }
  }

  useEffect(()=> {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className="margin-bottom-x3 margin-top-x2">
        <Container maxWidth="md">
          <h1 className="dh-title">Libro de Reclamaciones</h1>
          <h5 className={classes.text}>
            Conforme a lo establecido en el Código de Protección y Defensa del Consumidor, esta empresa cuenta con un Libro de Reclamaciones a tu disposición.
            DogHouser SA. - RUC: 20604497401 - Fecha: {new Date().toDateString()}
          </h5>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs>
                <TextField
                  autoFocus
                  variant="outlined" 
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Nombres"
                  name="name"
                  autoComplete="name"
                  value={name.value}
                  onChange={name.onChange}/>
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="last_name"
                  label="Apellidos"
                  name="last_name"
                  autoComplete="name"
                  value={last_name.value}
                  onChange={last_name.onChange}/>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="dni"
                  label="DNI"
                  name="dni"
                  autoComplete="name"
                  value={dni.value}
                  onChange={dni.onChange}/>
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="domicilio"
                  label="Domicilo"
                  name="domicilio"
                  autoComplete="name"
                  value={domicilio.value}
                  onChange={domicilio.onChange}/>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="distrito"
                  label="Distrito"
                  name="distrito"
                  autoComplete="name"
                  value={distrito.value}
                  onChange={distrito.onChange}/>
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="ciudad"
                  label="Ciudad o Provincio"
                  name="ciudad"
                  autoComplete="name"
                  value={ciudad.value}
                  onChange={ciudad.onChange}/>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="normal" 
                  required
                  fullWidth
                  id="celular"
                  label="Celular"
                  name="celular"
                  autoComplete="phone"
                  value={celular.value}
                  onChange={celular.onChange}/>
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email" 
                  name="email"
                  autoComplete="email"
                  value={email.value}
                  onChange={email.onChange}/>
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="reclamo"
              label="Cuentanos tu queja o reclamo"
              name="reclamo"
              autoComplete="text"
              multiline={true}
              rows={4}
              rowsMax={4}
              value={reclamo.value}
              onChange={reclamo.onChange}/>
            <button type="submit" className={`btn-primary medium ${classes.button}`}>
              Enviar Reclamo
            </button>
          </form>
        </Container>
      </div>
      {notification.messageState &&
        <Notification 
          message={notification.message} 
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState} 
          onClose={notification.handleCloseSnackbar}
        />
      }
    </React.Fragment>
    )
}

export default BookReclamation
