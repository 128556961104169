import React from 'react'
// Hooks
import { usePoppoverValue } from 'hooks/usePoppoverValue'
// Material-UI
import Popover from '@material-ui/core/Popover'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
// Material Icons
import FilterListIcon from '@material-ui/icons/FilterList'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

function FilterAdminPopover(props) {
  const { id, open, anchorEl, handlePopoverClose, title } = props
  return (
    <Popover
      id={id}
      open={open}
      className="dh-filter-selection__popover-filter"
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <div className="dh-filter-selection__popover-filter--title">
        <h3>{title}</h3>
      </div>
      <FormControl component="fieldset" className="dh-filter-selection__popover-filter--formcontrol">
        <FormGroup>
          {props.children}
        </FormGroup>
      </FormControl>
      <div className="dh-filter-selection__popover-filter--actions">
        <button className="btn-primary" onClick={handlePopoverClose}>Guardar</button>
      </div>
    </Popover>
  )
}

function FilterSelection(props) {
  const { values, handleValuesChange } = props
  const state = usePoppoverValue('state-popover')

  return (
    <div className="dh-filter-selection">
      <div className="dh-filter-selection__item">
        <div className="dh-filter-selection__style">
          <FilterListIcon/>
        </div>
      </div>
      <div className="dh-filter-selection__item">
        <div className="dh-filter-selection__style">
          <span>Filtrar por</span>
        </div>
      </div>
      <div className="dh-filter-selection__item item-button" onClick={state.handlePopoverOpen}>
        <div className="dh-filter-selection__style">
          <span>Estado</span>
          <ExpandMoreIcon/>
        </div>
      </div>
      {/* POPOVER para filtro de estado de la reserva */}
      <FilterAdminPopover
        id={state.id}
        open={state.open}
        anchorEl={state.anchorEl}
        handlePopoverClose={state.handlePopoverClose}
        title="Estados de la reserva">
        <FormControlLabel
          control={<Checkbox checked={values.state_1} onChange={handleValuesChange('state_1')} color="primary"/>}
          label="Pendientes"
        />
        <FormControlLabel
          control={<Checkbox checked={values.state_2} onChange={handleValuesChange('state_2')} color="primary"/>}
          label="Pendientes de pago"
        />
        <FormControlLabel
          control={<Checkbox checked={values.state_3} onChange={handleValuesChange('state_3')} color="primary"/>}
          label="Confirmadas"
        />
        <FormControlLabel
          control={<Checkbox checked={values.state_4} onChange={handleValuesChange('state_4')} color="primary"/>}
          label="Rechazadas"
        />
      </FilterAdminPopover>
    </div>
  )
}

export default FilterSelection
