import { ReactNode } from 'react';
import { Button, DialogContent, DialogActions, Dialog, IconButton } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { IconX } from '@tabler/icons';

import { DogLoadingOutlined } from '@root/ui-component/extended';
import styles from './mainDialog.module.scss';

interface MainDialogProps extends DialogProps {
  title?: string;
  children: ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
  cancelText?: string;
  okText?: string;
  confirmLoading?: boolean;
  footer?: ReactNode;
}

const MainDialog = (props: MainDialogProps) => {
  const {
    onClose,
    title,
    children,
    onOk,
    onCancel,
    cancelText = 'Cancelar',
    okText = 'Guardar',
    confirmLoading = false,
    footer,
    ...other
  } = props;

  const footerRoot = (
    <>
      <Button onClick={onCancel}>{cancelText}</Button>
      <Button
        disableElevation
        variant="contained"
        color="primary"
        onClick={onOk}
        disabled={confirmLoading}
        startIcon={confirmLoading && <DogLoadingOutlined />}>
        {okText}
      </Button>
    </>
  );

  return (
    <Dialog onClose={onClose} {...other}>
      <div className={styles.header} id="dialog-title">
        <h4>{title}</h4>
      </div>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions className={styles.actions}>{footer ? footer : footerRoot}</DialogActions>
      <div className={styles.close}>
        <IconButton size="small" color="inherit" onClick={(e) => onClose(e, 'escapeKeyDown')}>
          <IconX size={20} />
        </IconButton>
      </div>
    </Dialog>
  );
};

export default MainDialog;
