// Panel Images
import why1 from '../assets/images/why/escoge_cuidador.jpg';
import why2 from '../assets/images/why/atencion_personalizada.jpg';
import why3 from '../assets/images/why/seguro_emergencias.jpg';
import why4 from '../assets/images/why/menos_estres.jpg';
// Dog Images
import perro1 from '../assets/images/dog/perro1.png';
import perro2 from '../assets/images/dog/perro2.png';
import perro3 from '../assets/images/dog/perro3.png';
import perro4 from '../assets/images/dog/perro4.png';

export const whyPanel = [
  {
    title: 'Escoge a tu cuidador',
    text: 'La opción que mejor se adapte a tu mascota.',
    image: why1,
  },
  {
    title: 'Atención personalizada',
    text: 'Van libres por toda la casa (y camas).',
    image: why2,
  },
  {
    title: 'Seguro de emergencias',
    text: 'Alianzas con principales cadenas que nos tercerizan su hospedaje.',
    image: why3,
  },
  {
    title: 'Menos estrés para la mascota',
    text: 'El trato familiar reduce el estrés y el de su dueño.',
    image: why4,
  },
];

export const howDog = [
  {
    title: 'Busca y elige',
    text: 'Busca la familia perfecta para cuidar a tu mascota. Puedes realizar vistas previas para que conoscas el lugar y la familia que cuidará a tu mascota por ti.',
    number: '1',
    image: perro1,
  },
  {
    title: 'Reserva',
    text: 'Cuando haces una reserva, la propiedad queda bloqueada hasta que el propietario responda a tu solicitud con un máximo de 24 horas.',
    number: '2',
    image: perro2,
  },
  {
    title: 'Confirmación',
    text: 'Tan pronto el cuidador acepte la reserva, cargaremos el importe corespondiente en tu metodo de pago.',
    number: '3',
    image: perro3,
  },
  {
    title: 'Fecha de entrada',
    text: 'El siguiente paso es dejar a tu mascota con la familia elegida.',
    number: '4',
    image: perro4,
  },
];

const urlMedios = 'https://galery-doghouser.s3-us-west-2.amazonaws.com/assets/medios';

export const medios = [
  {
    image: urlMedios + '/blu.png',
    link: 'https://www.bluradio.com/sociedad/vacaciones-y-no-sabe-que-hacer-con-su-perro-conozca-sobre-hospedaje-de-mascotas-211590-ie435',
  },
  {
    image: urlMedios + '/caracol.png',
    link: '',
  },
  {
    image: urlMedios + '/confiep.png',
    link: 'https://www.youtube.com/watch?v=H2DEq6jilOw',
  },
  {
    image: urlMedios + '/el-comercio.png',
    link: 'https://elcomercio.pe/somos/historias/dia-mujer-ceo-airbnb-mascotas-creado-peru-opera-europa-noticia-613409-noticia/',
  },
  {
    image: urlMedios + '/espectador.png',
    link: 'https://www.elespectador.com/economia/llega-colombia-un-airbnb-para-el-cuidado-de-mascotas-articulo-849435',
  },
  {
    image: urlMedios + '/innovate.png',
  },
  {
    image: urlMedios + '/kienyke.png',
    link: 'https://www.kienyke.com/tendencias/maskotas/la-aplicacion-para-buscar-hospedaje-para-mascotas',
  },
  {
    image: urlMedios + '/latina.png',
  },
  {
    image: urlMedios + '/peru21.png',
    link: 'https://peru21.pe/emprendimiento/emprendedor21-dog-houser-carino-cuidado-hogar-469095-noticia/',
  },
  {
    image: urlMedios + '/republica.png',
    link: '',
  },
  {
    image: urlMedios + '/rpp.png',
    link: 'https://rpp.pe/viajes/mas-viajes/te-vas-de-viaje-y-no-tienes-con-quien-dejar-a-tu-mascota-esta-aplicacion-podria-ser-la-solucion-noticia-1187510',
  },
  {
    image: urlMedios + '/publimetro.png',
    link: 'https://www.publimetro.co/co/estilo-vida/2019/04/11/lo-sabia-llego-colombia-airbnb-cuidado-mascota.html',
  },
  {
    image: urlMedios + '/somos.png',
    link: 'https://elcomercio.pe/somos/historias/dia-mujer-ceo-airbnb-mascotas-creado-peru-opera-europa-noticia-613409-noticia/',
  },
  {
    image: urlMedios + '/startupbogota.png',
    link: 'https://pixelcoblog.com/media-startups-llega-a-bogota/',
  },
  {
    image: urlMedios + '/telemedellin.png',
    link: 'https://telemedellin.tv/medellin-alternativa-hospedaje-de-su-mascota/337103/',
  },
  {
    image: urlMedios + '/trome.png',
    link: '',
  },
  {
    image: urlMedios + '/w-radio.png',
    link: 'https://www.wradio.com.co/noticias/tecnologia/la-aplicacion-para-encontrar-hospedaje-a-sus-mascotas/20190317/nota/3878125.aspx',
  },
];

const urlAliados = 'https://galery-doghouser.s3-us-west-2.amazonaws.com/assets/aliados/';

export const aliados = [
  {
    image: urlAliados + 'WUF.png',
  },
  {
    image: urlAliados + 'bambinos.png',
  },
  {
    image: urlAliados + 'cespet.png',
  },
  {
    image: urlAliados + 'clubgolden.png',
  },
  {
    image: urlAliados + 'docloulittle.png',
  },
  {
    image: urlAliados + 'Groomers.png',
  },
  {
    image: urlAliados + 'doger.png',
  },
  {
    image: urlAliados + 'huronazul.png',
  },
  {
    image: urlAliados + 'labrador.png',
  },
  {
    image: urlAliados + 'maglucks.png',
  },
  {
    image: urlAliados + 'matchcota.png',
  },
  {
    image: urlAliados + 'medivets.png',
  },
  {
    image: urlAliados + 'miocane.png',
  },
  {
    image: urlAliados + 'monteverde.png',
  },
  {
    image: urlAliados + 'petground.png',
  },
  {
    image: urlAliados + 'petsvets.png',
  },
  {
    image: urlAliados + 'salvar.png',
  },
  {
    image: urlAliados + 'casacoral.jpg',
  },
  {
    image: urlAliados + 'clubcriollo.jpg',
  },
];

const urlUsuarios = 'https://galery-doghouser.s3-us-west-2.amazonaws.com/assets/usuarios/';

export const ratingUsers = [
  {
    title: 'Excelente Servicio',
    text: 'Una excelente idea, buena iniciativa para trabajar a los que les gusta a los que aman los animales y para los usuarios el tener un sitio confiable donde dejar a tu querida mascota',
    image: urlUsuarios + 'user1.jpg',
    user: 'Ana Cecilia Fuentes',
  },
  {
    title: 'Excelente Servicio',
    text: 'Han sido super responsables para orientarnos en el servicio que prestarían a nuestro engreído... Surgió un inconveniente de nuestra parte , y nos han sido el doble de amables y comprensivos ... No dudare en seguirles contratando y recomendando ... DogHouser son lo maximo ...',
    image: urlUsuarios + 'user2.jpg',
    user: 'Maria Elizabeth Rubio M.',
  },
  {
    title: 'Excelente Servicio',
    text: 'Dejamos a nuestra chanchita por 4 días y la señora que la cuidó nos enviaba fotos todo el tiempo. Estuvo muy bien cuidada y engreída. Tomamos el servicio de recojo ida y vuelta y también fue excelente. Muy recomendado!!',
    image: urlUsuarios + 'user3.jpg',
    user: 'César Gonzales',
  },
];

export const TermsConditions = [
  {
    title: '1. SERVICIOS.',
    text: 'Nos comprometemos a brindarle a su Mascota el servicio de alojamiento a través de nuestras familias afiliadas. LA EMPRESA no presta directamente el servicio de cuidado de las mascotas; su misión es conseguir personas que aman las mascotas que han demostrado su idoneidad en el cuidado de las mismas.',
  },
  {
    title: '2. PAGO.',
    text: 'Usted se compromete a pagar a DogHouser por adelantado los Servicios que se le brindarán a su(s) Mascota(s) según las tarifas establecidas (en adelante denominados en su conjunto “Cargos”). Los precios pueden variar según la temporada. En caso de que el cliente deseara servicios adicionales o extender su estadía, deberá solicitarlos con antelación y pagarlos directamente a DogHouser antes de recoger a su mascota.',
  },
  {
    title: '3. RESERVAS.',
    text: 'Para garantizar su confirmación se requiere la verificación del pago por adelantado y la copia del carnet de vacunación. Caso contrario, el cuidador puede reservarse el derecho de no recibir a la mascota.',
  },
  {
    title: '4. CANCELACIONES.',
    text: 'Si usted necesita cancelar su reservación, se cobrará una penalidad administrativa. Hasta un día antes se retendrá 6 soles por cada noche o día agendado; si la cancelación es el mismo día se retendrá el 50% del pago. El dinero se devolverá el lunes siguiente a la fecha de cancelación a la cuenta indicada por el cliente. El cliente siempre tiene la opción de postergarlo y utilizar el dinero como saldo a favor sin estar afecto a penalidades.',
  },
  {
    title: '5. CONTACTO DE EMERGENCIA.',
    text: 'Usted entiende y acepta que ante el evento poco probable de que su Mascota se enferme, se lastime, o tuviera una condición preexistente que se agrave durante su estadía con nosotros, intentaremos notificarle a usted o su Representante a los números indicados. Si no lográsemos contactarle a usted o a su Representante, usted autoriza que el cuidador afiliado a DogHouser asista a la veterinaria asignada por el seguro, administre medicamentos o brinde otra atención necesaria a su Mascota. Si no podemos ubicarlo a usted o a su Representante, tomaremos las decisiones del cuidado de la salud de su Mascota en base a las recomendaciones de los profesionales disponibles.',
  },
  {
    title: '6. SEGURO.',
    text: 'El seguro aplica únicamente para la mascota registrada como huésped y durante el tiempo de alojamiento. Usted autoriza a DogHouser a activar el Seguro Veterinario de Emergencias en la clínica afiliada que se encuentre más cerca. El seguro está incluido en la tarifa y cubre hasta una suma de S/.600.00 soles. En caso de que los gastos por la atención de su mascota por emergencia sobrepasen esta cantidad, usted se compromete a asumirlos asegurando la correcta atención de su mascota. Si usted desea atender a su mascota en otra veterinaria que no es de las afiliadas, puede hacerlo consciente de que el usuario asumirá todos los gastos correspondientes y no aplicará el seguro veterinario de emergencias.',
  },
  {
    title: '7. HORARIOS (“Check-In” y “Check-Out”).',
    text: 'La entrada debe acordarse con cada hospedaje afiliado a DogHouser, se darán las facilidades para adaptarse a sus necesidades siempre que estén entre las 7am y las 9pm. Si el servicio de hospedaje sobrepasara las 24 horas, se cobrará mediodía más según el precio de lista. Asimismo, si se demoran en recoger a la mascota, se puede aplicar mediodía de recargo sobre el precio de lista.',
  },
  {
    title: '8. ARTÍCULOS PERSONALES.',
    text: 'Las mascotas deben ser entregadas al hospedaje con su respectiva comida, cama y platos (los juguetes sólo se permiten en casas exclusivas). Todas las mascotas que se alojen en nuestros hospedajes deben contar con su placa de identificación. No traiga artículos de valor o irreemplazables con su Mascota. DogHouser no es responsable por la pérdida o daño de cualquier artículo personal o juguete dejado con su Mascota. Asimismo, si la mascota daña la propiedad del cuidador es exclusivamente responsabilidad del cuidador.',
  },
  {
    title: '9. SALUD Y COMPORTAMIENTO.',
    text: 'Usted afirma que su Mascota no está enferma, herida ni tiene problemas de comportamiento (incluyendo comportamiento agresivo o que muerdan) que no ha sido registrado en esta ficha. Usted afirma que, a su leal saber y entender, su Mascota no ha sido expuesta a rabia, distémper ni parvovirus en los 30 días al comienzo de su estadía con nosotros. Usted es consciente que este programa no acepta mascotas que puedan representar un peligro para los cuidadores por lo que, si su mascota llega a morder y causar un daño en ellos, podríamos contactar a las autoridades correspondientes.',
  },
  {
    title: '10. ACCIDENTES ENTRE MASCOTAS.',
    text: 'Si ha solicitado Alojamiento Compartido, entiende que su mascota estará en contacto y socializando con otras Mascotas. Por ello, al solicitar este servicio usted asegura que su mascota no presenta comportamientos agrevisos ni antecedentes que puedan significar un peligro. Usted reconoce y acepta que ante el evento improbable de que su Mascota resulte herida por esta interacción, USTED LIBERA A DOGHOUSER Y A SUS REPRESENTANTES DE TODA RESPONSABILIDAD RELACIONADA A TAL HERIDA. Si su Mascota hiere a otra(s) Mascota(s) o alguna otra persona, usted será el único responsable de cualquier herida infligida a la(s) otra(s) Mascota(s) / o personas y a la suya, y USTED LIBERA A DOGHOUSER Y A SUS REPRESENTANTES DE TODA RESPONSABILIDAD RELACIONADA A TAL HERIDA. Si, a causa de algún accidente, su mascota falleciera, se realizará una autopsia para determinar los motivos y responsabilidades. LA EMPRESA SE HACE RESPONSABLE DE SEGUIR LOS PROTOCOLOS DE EMERGENCIA PARA SALVAR LA VIDA DE SU MASCOTA Y GARANTIZAR SU BIENESTAR, MÁS NO DE LAS CONSECUENCIAS A CAUSA DE ACCIDENTES INVOLUNTARIOS.',
  },
  {
    title: '11. MASCOTAS ABANDONADAS.',
    text: 'Si Usted no recoge a su mascota el día y hora pactado, y perdemos comunicación con usted o su contacto de emergencia, la mascota será declarada abandonada después de 24hrs y se la entregaremos a un tercero para que se encargue de su adopción. Asimismo, reportaremos el hecho ante las autoridades. Usted comprende que podría perder la propiedad de su Mascota bajo estas circunstancias. USTED LIBERA A DOGHOUSER DE TODA RESPONSABILIDAD POSTERIOR SOBRE SU MASCOTA. Usted seguirá siendo responsable ante nosotros por todos los Cargos impagos, incluyendo de forma enunciativa más no limitativa, los costos judiciales y honorarios de abogados en los que hayamos incurrido para la gestión de cobro.',
  },
  {
    title: '12. PÉRDIDA O ROBO.',
    text: 'Es obligatorio que todas las mascotas cuenten con su placa de identificación. Los paseos se realizarán obligatoriamente con correa (y bozal de ser solicitado por el propietario) a menos que el Cliente solicite que la mascota no salga de casa del cuidador. En caso de pérdida o robo de la mascota, LA EMPRESA se comunicará inmediatamente con el propietario. LA EMPRESA hará todo lo posible para ayudar a solucionar la situación activando el protocolo de búsqueda. LA EMPRESA, no se hace cargo de ningún costo que implique la búsqueda y/o rescate de la mascota. Todos los costos van por cuenta del propietario de la mascota o el Cuidador si fuera fruto de una negligencia.',
  },
  {
    title: '13. PROPIETARIO ÚNICO. ',
    text: 'Usted declara ser el dueño de la(s) Mascota(s) y estar plenamente autorizado para aceptar este Contrato. Toda la información acerca de usted y de su Mascota declarada en el presente Contrato es verdadera, precisa y completa. Usted se compromete a indemnizarnos y eximirnos de responsabilidad, por y contra cualquier pérdida, daño o gasto, incluyendo honorarios de abogados, que resulten por, declaraciones falsas realizadas por usted o por sus representantes o por la estadía de su mascota aquí, incluyendo de forma enunciativa más no limitativa, cualquier reclamo de propiedad de su Mascota y cualquier reclamo por daños o lesiones ocasionados por su Mascota.',
  },
  {
    title: '14. OTROS.',
    text: 'Este Contrato escrito constituye nuestro acuerdo total y único y no existen acuerdos o entendimientos orales a excepción de lo establecido en este Contrato. Este Contrato nos vincula a nosotros y a nuestros cesionarios con usted, sus herederos y cesionarios. Podríamos tomar una foto de su mascota mientras esté recibiendo nuestros Servicios o esté bajo nuestro cuidado (“Foto”). Usted por la presente nos concede los derechos de autor/regalías de forma perpetua e irrevocable y la licencia a publicar, distribuir, adaptar, modificar, de lo contrario usar la foto, o cualquier porción del mismo, en cualquier manera, para cualquier propósito comercial o no comercial sin notificación, revisión y aprobación.',
  },
];
