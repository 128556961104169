import { Overrides } from '@material-ui/core/styles/overrides';

import MuiCardHeader from './MuiCardHeader';

export default function componentStyleOverrides(theme) {
  return {
    MuiButton: {
      root: {
        fontWeight: 600,
        textTransform: 'none',
        borderRadius: theme.borderRadius1,
        color: theme.textColor,
      },
    },
    MuiPaper: {
      root: {
        backgroundImage: 'none',
      },
      rounded: {
        borderRadius: theme.borderRadius1,
      },
      outlined: {
        border: `1px solid ${theme.borderColor}`,
      },
    },
    MuiCardHeader,
    MuiCardContent: {
      root: {
        padding: '24px',
      },
    },
    MuiCardActions: {
      root: {
        padding: '16px 24px',
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: 500,
      },
      input: {
        color: theme.textColor,
        '&::placeholder': {
          color: theme.textColorLigth,
          fontSize: '0.875rem',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: 500,
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        '& > *': {
          fontWeight: 600,
          fontSize: '1rem',
        },
      },
      dayLabel: {
        color: theme.grey700,
      },
    },
    MuiPickersDay: {
      day: {
        color: theme.grey700,
      },
    },
    MuiSkeleton: {
      root: {
        borderRadius: theme.borderRadius1,
      },
    },
  } as Overrides;
}
