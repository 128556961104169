import React, { useState } from 'react';
//Services
import * as SERVICES from 'services/config';
import * as OPTIONS from '@root/services/options';
// Utils
import { variantCityByCountry } from 'utils/Resources';
// Components
import TitleArrowBack from '@root/components/TitleBack';
import Notification from 'components/notification';
import AreaServiceData from 'components/AreaServiceData';

// Tipo de Lugares
export const type_services = [
  {
    value: 1,
    label: 'Hospedaje Compartido',
  },
  {
    value: 2,
    label: 'Hospedaje Exclusivo',
  },
  {
    value: 3,
    label: 'Guardería',
  },
  {
    value: 4,
    label: 'Delivery Houser',
  },
  {
    value: 22,
    label: 'Hotel',
  },
];

// Tipo de Lugares
export const type_money = [
  {
    value: 1,
    label: 'Chilean peso',
  },
  {
    value: 2,
    label: 'Colombian peso',
  },
  {
    value: 3,
    label: 'Peruvian nuevo sol',
  },
  {
    value: 4,
    label: 'Brazilian real',
  },
  {
    value: 5,
    label: 'United States dollar',
  },
  {
    value: 6,
    label: 'Euro',
  },
];

// Tipo de Lugares
export const type_tax = [
  {
    value: 1,
    label: 'IGV(PE) - 18%',
  },
  {
    value: 2,
    label: 'IVA(ES) - 21%',
  },
  {
    value: 3,
    label: 'IVA(CO) - 19%',
  },
];

export const type_level = [
  {
    value: 1,
    label: 'Houser Junior',
  },
  {
    value: 2,
    label: 'Houser Senior',
  },
  {
    value: 3,
    label: 'Super Houser',
  },
];

function AdminServiceNew(props) {
  // Variables del componente "Notification"
  const [message, setMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [messageState, setMessageState] = useState(false);
  // Tipo de Servicio
  const [service, setService] = useState({
    name: '',
    type_id: 1,
    houser_comission: '',
    gross_comission: '',
    money_id: 3,
    tax_id: 1,
    houser_level_id: 1,
    country: 'País',
    city: 'Ciudad',
  });
  // Array de las ciudades escogidas por el Usuario
  const [cityArray, setCityArray] = useState([]);

  const handleBackClick = () => {
    props.history.goBack();
  };

  // Funcion para cerrar el Componente 'Notification'
  const handleCloseSnackbar = (e) => {
    setMessage('');
    setMessageState(false);
  };

  const handleServiceChange = (name) => (event) => {
    setService({
      ...service,
      [name]: event.target.value,
    });
    if (name === 'country') {
      setCityArray(variantCityByCountry[event.target.value]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchCreateService();
  };

  const fetchCreateService = async () => {
    let errorMessage = 'Error al crear el servicio';
    let successMessage = 'Servicio creado correactamente';
    const body = {
      name: service.name,
      type_id: service.type_id,
      houser_comission: service.houser_comission,
      gross_comission: service.gross_comission,
      money_id: service.money_id,
      tax_id: service.tax_id,
      houser_type_id: 1,
      houser_level_id: service.houser_level_id,
      country: service.country,
      city: service.city,
    };
    sendData(SERVICES.CREATE_SERVICE, OPTIONS.POST(body), successMessage, errorMessage);
  };

  const sendData = async (url, options, successMessage, errorMessage) => {
    try {
      const response = await fetch(url, options);
      // eslint-disable-next-line no-unused-vars
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        setMessage(successMessage);
        setMessageVariant('success');
        setMessageState(true);
      } else {
        setMessage(data.message);
        setMessageVariant('error');
        setMessageState(true);
      }
    } catch (error) {
      setMessage('Error en los datos: ' + error.message);
      setMessageVariant('error');
      setMessageState(true);
    }
  };

  return (
    <div>
      <div className="margin-bottom-x15">
        <TitleArrowBack handleClick={handleBackClick}>
          <h2 className="admin-title">Volver</h2>
        </TitleArrowBack>
      </div>
      <AreaServiceData
        handleSubmit={handleSubmit}
        cityArray={cityArray}
        service={service}
        handleServiceChange={handleServiceChange}
        title="Crear Servicio"
        btnText="Crear Servicio"
      />
      {messageState && (
        <Notification
          message={message}
          variant={messageVariant}
          vertical="top"
          horizontal="center"
          state={messageState}
          onClose={handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default AdminServiceNew;
