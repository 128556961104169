import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Hooks
import TablePaginationActions from 'hooks/TablePaginationActions';
// Utils
import replacePathParams from 'utils/replacePathParams';
// Constants
import * as ROUTES from 'constants/routes';
// Constants
import * as VARIABLES from 'constants/variables';
// Components
import BadgeStatus from 'components/badgeStatus';
import BadgeStatusSimple from 'components/badgeStatusSimple';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
// Material Iconos
import PersonIcon from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 950,
  },
}));

const TableRowHead = (
  <TableRow>
    <TableCell>Nombre</TableCell>
    <TableCell>Correo</TableCell>
    <TableCell>Teléfono</TableCell>
    <TableCell>Ubicación</TableCell>
    <TableCell>Estado</TableCell>
    <TableCell align="center">Acciones</TableCell>
  </TableRow>
);

const SkeletonRow = (
  <TableRow>
    <TableCell scope="row">
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <div className="user-name-content">
        <Skeleton variant="circle" width={40} height={40} className="user-name-content__img" />
        <Skeleton width="60%" />
      </div>
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
  </TableRow>
);

function TableHousers(props) {
  const { users, loading } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [clientsPerPage, setClientsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeClientsPerPage = (event) => {
    setClientsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="dh-admin-housers__table-housers--layout dh-simple-elevation">
      <Table className={classes.table}>
        <TableHead className="table-head">{TableRowHead}</TableHead>
        {!loading ? (
          <React.Fragment>
            <TableBody className="table-body">
              {users
                .slice(page * clientsPerPage, page * clientsPerPage + clientsPerPage)
                .map((client) => (
                  <TableRow key={client.id}>
                    <TableCell scope="row">
                      <RouterLink
                        to={`/dh-admin/cuidadores/${client.id}`}
                        className="dh-router">
                        <div className="user-name-content">
                          {client.photo ? (
                            <Avatar
                              className="user-name-content__img"
                              alt={client.name}
                              src={`${VARIABLES.IMAGE_URL}${client.photo}`}
                            />
                          ) : (
                            <Avatar className="user-name-content__img" alt={client.name}>
                              <PersonIcon />
                            </Avatar>
                          )}
                          <div>
                            <p>{`${client.name} ${client?.last_name}`}</p>
                            <span>
                              ID: {client?.id} Houser_ID: {client?.houser.id}
                            </span>
                          </div>
                        </div>
                      </RouterLink>
                    </TableCell>
                    <TableCell>
                      {client.email}
                      <BadgeStatusSimple status={client.email_confirmed} />
                    </TableCell>
                    <TableCell>
                      {client.phone}
                      <BadgeStatusSimple status={client.confirmation} />
                    </TableCell>
                    {client.location ? (
                      <React.Fragment>
                        {client.location.country ? (
                          <TableCell>
                            {client.location.city}, {client.location.country}
                          </TableCell>
                        ) : (
                          <TableCell>No se encontro el Pais</TableCell>
                        )}
                      </React.Fragment>
                    ) : (
                      <TableCell>No se encontro</TableCell>
                    )}
                    <TableCell>
                      {client.houser ? (
                        <React.Fragment>
                          {client.houser.publication ? (
                            <BadgeStatus status={client.houser.publication.publication_state} />
                          ) : (
                            <BadgeStatus status="2" />
                          )}
                        </React.Fragment>
                      ) : (
                        <p>Houser NULL</p>
                      )}
                    </TableCell>
                    <TableCell align="center" className="table-body__client-action">
                      <RouterLink
                        to={replacePathParams(ROUTES.ADMIN_CUIDADORES_ID, {
                          id: client.id,
                        })}
                        className="dh-router">
                        <IconButton aria-label="ver datos del producto">
                          <VisibilityIcon />
                        </IconButton>
                      </RouterLink>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </React.Fragment>
        ) : (
          <TableBody className="table-body">
            {Array.from(new Array(10)).map((data, index) => (
              <React.Fragment key={index}>{SkeletonRow}</React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      <TablePagination
        rowsPerPageOptions={[50, 100, 150, 200]}
        component="div"
        count={users.length}
        rowsPerPage={clientsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'filas por página' },
          native: true,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeClientsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}

export default TableHousers;
