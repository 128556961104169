import React, { useMemo } from 'react';
import clsx from 'clsx';
import './space.scss';

const prefixCls = 'dh-space';

const spaceSize = {
  small: 8,
  middle: 16,
  large: 24,
};

const getNumberSize = (size) => {
  return typeof size === 'string' ? spaceSize[size] : size || 0;
};

const Space = (props) => {
  const {
    size = 'small',
    align,
    className,
    children,
    direction = 'horizontal',
    style,
    wrap = false,
  } = props;

  const [horizontalSize, verticalSize] = useMemo(
    () => (Array.isArray(size) ? size : [size, size]).map((item) => getNumberSize(item)),
    [size],
  );

  const mergedAlign = align === undefined && direction === 'horizontal' ? 'center' : align;
  const cn = clsx(
    prefixCls,
    `${prefixCls}-${direction}`,
    {
      [`${prefixCls}-align-${mergedAlign}`]: mergedAlign,
    },
    className,
  );

  const gapStyle = {};

  if (wrap) {
    gapStyle.flexWrap = 'wrap';
  }

  gapStyle.columnGap = horizontalSize;
  gapStyle.rowGap = verticalSize;

  return (
    <div
      className={cn}
      style={{
        ...gapStyle,
        ...style,
      }}>
      {children}
    </div>
  );
};

export default Space;
