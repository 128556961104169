import React, { useEffect } from 'react';
import * as SERVICES from 'services/config';
import * as OPTIONS from 'services/options';

export default function CheckoutConfirm(props) {
  const id = props.match.params.id;

  useEffect(() => {
    updateBooking(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateBooking = async (status) => {
    let body = {
      booking_id: id,
      status: status,
    };
    try {
      const response = await fetch(SERVICES.UPDATE_BOOKING_STATUS, OPTIONS.POST(body));
      const data = await response.json();
      // console.log(data)
      if (response.status === 200) {
        console.log('update');
        window.location.href = '/reserva';
      } else {
        console.log('Actualizar Error -> ' + data);
      }
    } catch (error) {
      console.log('Actualizar Error -> ' + error);
    }
  };

  return <div></div>;
}
