import React from 'react'
import PropTypes from 'prop-types'
// Images
import patita from 'assets/icons/patita.png'
// Material-UI
import Avatar from '@material-ui/core/Avatar'

const RatingCard = props => {
  const { img, text, user } = props

  return (
    <a href="https://www.facebook.com/pg/dog.houser/reviews/" target="_blank" rel="noopener noreferrer">
        <div className="rating-card">
          <div>
            <div className="rating-card__patitas">
              <img className="fav" src={patita} alt="patitas"/>
              <img className="fav" src={patita} alt="patitas"/>
              <img className="fav" src={patita} alt="patitas"/>
              <img className="fav" src={patita} alt="patitas"/>
              <img className="fav" src={patita} alt="patitas"/>
            </div>
            {/*<h3 className="rating-card__title">{title}</h3>*/}
            <p className="rating-card__text">{text}</p>
          </div>
          <div className="rating-card__user">
            <Avatar alt="user" src={img} className="rating-card__user__img" />
            <h2 className="rating-card__user__name">{user}</h2>
          </div>
        </div>
      </a>
  )
}

RatingCard.propTypes = {
  img: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired
}

export default RatingCard
