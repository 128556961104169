import React from 'react'
import './houserNotFound.scss'
// DogHouser Images
import ErrorCat from 'assets/images/error-cat@2x.png'

export const HousersNotFound = props => {
  const { errorText } = props
  return (
    <React.Fragment>
      <div className="houser-list-404">
        <div className="houser-list-404__image">
          <img src={ErrorCat} alt="Error cat"/>
        </div>
        <h3>{errorText}</h3>
      </div>
      <a className="dh-no-decoratio" href="http://www.freepik.com" target="_blank" rel="noopener noreferrer">
        <p style={{textAlign: 'right'}}>Designed by Freepik</p>
      </a>
    </React.Fragment>
  )
}

export default HousersNotFound
