/**
 * Niveles del Usuario
 * Houser Junior => 1
 * Houser Senior => 2
 * Super Houser => 3
 * Cualquier otro numero es tomado como ERROR
**/
import React from 'react'
import PropTypes from 'prop-types'
import './badgeLevel.scss'
// Constants
import * as VARIABLES from 'constants/variables'
// Material Icons
import OfflineBoltRoundedIcon from '@material-ui/icons/OfflineBoltRounded';

function BadgeLevel(props) {
  const { level } = props

  return (
    <div className={`dh-badge-level ${VARIABLES.LEVEL_VARIANT[level]}`}>
      <OfflineBoltRoundedIcon/>
      <div>{VARIABLES.LEVEL_NAME[level]}</div>
    </div>
  )
}

BadgeLevel.defaultProps = {
  level: 1
}

BadgeLevel.propTypes = {
  level: PropTypes.number.isRequired
}

export default BadgeLevel
