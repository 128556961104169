import { useEffect, useState, useContext } from 'react';
import { Context } from '@root/Context';
import './houserDetail.scss';
// Services
import * as SERVICES from '@root/services/config';
import * as OPTIONS from '@root/services/options';
// Constants
import * as VARIABLES from '@root/constants/variables';
// Dependencies
import GoogleMapReact from 'google-map-react';
import Iframe from 'react-iframe';
// Hooks
import useNotificationValue from '@root/hooks/useNotificationValue';
// Components
import Notification from '@root/components/notification';
import Marker from '@root/components/Marker';
// import CardPriceCalculator from 'components/CardPriceCalculator/CardPriceCalculator';
import CommentArea from '@root/components/commentArea/CommentArea';
import { GallerySection, ServicesSection, HouserError } from './components';
// DogHouser Icons
/*
import CasaCompartida from 'icons/CasaCompartida'
import CasaExclusiva from 'icons/CasaExclusiva'
import IcPeru from 'assets/images/ico/ic_peru.png';
import IcColombia from 'assets/images/ico/ic_colombia.png';
import IcSpain from 'assets/images/ico/ic_spain.png';
*/
// Material-UI
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import BookingRequest from '@root/components/BookingRequest';
import CommentItem from './components/CommentItem';

function HouserDetail(props) {
  const id = props.match.params.id;
  const { cookies, client, setRedirect } = useContext(Context);
  const [loading, setLoading] = useState(false);
  // Variables de "Notification"
  const notification = useNotificationValue();
  // Variables del Cuidador
  const [user, setUser] = useState({
    id: null,
    name: '',
    photo: '',
    email_confirmed: 0,
    confirmation: 0,
    houser: {
      type_delivery: 0,
      type_exclusive: 0,
      type_share: 0,
      has_garden: 0,
      has_terrace: 0,
      pet_size: 1,
      pills: 0,
      injectables: 0,
      allowed_house: 0,
      allowed_sofa: 0,
      allowed_bed: 0,
      galleries: [],
      publication: {
        description_pub: '',
        houser_id: null,
        id: null,
        title: '',
        video: '',
      },
    },
    location: {
      country: '',
      district: '',
      longitude: '',
    },
  });
  const zoom = 15;
  const [center, setCenter] = useState({
    lat: -12.083348,
    lng: -77.057595,
  });
  // const init_point = "https://www.mercadopago.com/mpe/checkout/start?pref_id=446971035-dff52953-fbc4-4e56-840a-19436290795f"

  const [fBookings, setFBookings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      fetchCuidador();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (user.houser.id) {
      const fetchFinishedBookings = async () => {
        const response = await fetch(
          SERVICES.GET_BOOKING_COMMENTS + user.houser.id,
          OPTIONS.GET(),
        );
        const data = await response.json();
        // console.log(data);
        setFBookings(data.bookings);
      };
      fetchFinishedBookings();
    }
  }, [user.houser.id]);

  const fetchCuidador = async () => {
    setLoading(true);
    let body = {
      id: id,
    };
    sendData(SERVICES.CUIDADOR_BY_ID, OPTIONS.POST(body));
  };

  const sendData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.status === 200) {
        setUser(data.user);
        setLoading(false);
        if (data.user.location.latitude && data.user.location.longitude) {
          var intLat = parseFloat(data.user.location.latitude);
          var intLng = parseFloat(data.user.location.longitude);
          setCenter({
            lat: intLat,
            lng: intLng,
          });
        }
      } else {
        notification.handleSnackbar(data.message, 'warning');
        setLoading(false);
      }
    } catch (error) {
      notification.handleSnackbar(error.message, 'error');
    }
  };

  if (!user || !user.houser || !user.houser.publication) {
    return <HouserError goBack={() => props.history.goBack()} />;
  }

  // eslint-disable-next-line no-unused-vars
  const handleRequest = () => {
    if (client) {
      props.history.push(`/cuidador/${user.id}/request`);
    } else {
      setRedirect(`/cuidador/${user.id}/request`);
      props.history.push('/login');
    }
  };

  // eslint-disable-next-line no-unused-vars
  const callCreateTopic = async () => {
    try {
      const response = await fetch(SERVICES.SNS_TEST, OPTIONS.POST());
      const data = await response.json();
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="houser-detail">
      <GallerySection gallery={user.houser.galleries} />
      <Container maxWidth="lg" className="houser-detail__container">
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} md={7}>
            <div className="houser-detail__section-title">
              <h1 className="dh-title-section">{user.houser.publication.title}</h1>
              <p className="medium">
                {user.location.district}, {user.location.country}
              </p>
            </div>
            <ServicesSection houser={user.houser} />
            {user.houser.publication.video && (
              <>
                <div className="divider-line" />
                <div className="houser-detail__section-video">
                  <h3 className="dh-text-section marginx0">Video</h3>
                  <div className="video-container">
                    {!loading && (
                      <Iframe
                        className="video-container__iframe"
                        url={`https://www.youtube.com/embed/${user.houser.publication.video}`}
                        display="initial"
                        position="relative"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="divider-line" />
            {user.location.longitude && user.location.latitude ? (
              <div className="houser-detail__section-location">
                <h3 className="dh-text-section marginx0">Ubicación</h3>
                <div className="map-container">
                  {!loading && (
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: VARIABLES.GOOGLE_MAP_KEY }}
                      defaultZoom={zoom}
                      center={{
                        lat: center.lat,
                        lng: center.lng,
                      }}>
                      <Marker lat={center.lat} lng={center.lng} id={user.id} />
                    </GoogleMapReact>
                  )}
                </div>
              </div>
            ) : (
              <h4>No hay Ubicación</h4>
            )}
            <div className="divider-line" />
            <div className="houser-detail__section-comments">
              <h3 className="dh-text-section marginx0">Comentarios</h3>
              <CommentArea
                idCuidador={user.houser.id}
                type="cuidador"
                commentIsDisable={false}
              />
              <div className="dh-listComment">
                {fBookings.map((booking, index) => (
                  <CommentItem key={index} booking={booking} />
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="houser-detail__section-user">
              <img
                className="cuidador-img"
                alt={user.name}
                src={user.photo && `${VARIABLES.IMAGE_URL}${user.photo}`}
                onError={(ev) =>
                  (ev.target.src =
                    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                }
              />
              <h2 className="marginx05">{user.name}</h2>
            </div>
            {!loading && id !== cookies.id && (
              // <div style={{ marginTop: 10, marginBottom: 10 }}>
              //   <button className="btn-primary btn-block margin-top-x1" onClick={() => handleRequest()}>
              //     Solicitar Reserva
              //   </button>
              // </div>
              <BookingRequest id={id} history={props.history} houser={user} />
            )}
            {/* {infoState ? (
              <div className="section-info-call">
                <p>Si tienes alguna consulta puedes llamar al equipo de Doghouser con los siguientes números.</p>
                <List>
                  <ListItem className="country-item">
                    <ListItemAvatar className="country-avatar">
                      <img src={IcPeru} alt="Colombia" />
                    </ListItemAvatar>
                    <ListItemText className="country-text">
                      <p>995 759 034</p>
                      <p>985 371 105</p>
                    </ListItemText>
                  </ListItem>
                  <ListItem className="country-item">
                    <ListItemAvatar className="country-avatar">
                      <img src={IcColombia} alt="Colombia" />
                    </ListItemAvatar>
                    <ListItemText className="country-text">
                      <p>305 2302617</p>
                    </ListItemText>
                  </ListItem>
                  <ListItem className="country-item">
                    <ListItemAvatar className="country-avatar">
                      <img src={IcSpain} alt="Colombia" />
                    </ListItemAvatar>
                    <ListItemText className="country-text">
                      <p>633053593</p>
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
            ) : (
              <button className="btn-primary" onClick={handleInfoState}>Visita previa</button>
            )} */}
          </Grid>
        </Grid>
      </Container>
      {notification.messageState && (
        <Notification
          message={notification.message}
          variant={notification.messageVariant}
          vertical="top"
          horizontal="center"
          state={notification.messageState}
          onClose={notification.handleCloseSnackbar}
        />
      )}
    </div>
  );
}

export default HouserDetail;
